import Button from '../common/Button/Button'
import FilledTabGroup from '../common/TabGroup/FilledTabGroup'
import Filter from '../Filter/Filter'
import React from 'react'
import { TypographyDDS } from '../common/Typography/TypographyDDS'
import breakpoints from '../../global/breakpoints'
import messageIcon from '../../assets/icons/message.svg'
import palette from '../../global/pallete'
import styled from '@emotion/styled'

interface NotificationHeaderProps {
  currentTab: number
  tabLabels: any[]
  onChange?: (newValue: number) => void
  setCurrentTab: (tab: number) => void
  options?: []
  disabled?: boolean
  markAllAsRead: () => void
  buttonDisabled?: boolean
}

const Container = styled.div`
  display: flex;

  flex-wrap: nowrap;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  & > * {
    flex: 1 100%;
  }
  .Tab {
    order: -1;
  }
  .Button {
    flex: 0 11.25em;
  }
  .Title {
    flex: 1;
  }

  grid-gap: 1.25em;
  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.85rem;
  }
  @media (max-width: ${breakpoints.md}) {
    font-size: 0.7rem;
  }
`

const TabWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const AddFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 20px;
  padding: 8px 0px;
`

const NotificationHeader: React.FC<NotificationHeaderProps> = ({
  currentTab,
  tabLabels,
  setCurrentTab,
  options,
  disabled,
  markAllAsRead,
  buttonDisabled
}) => {
  return (
    <div>
      <Container>
        <div className='Title'>
          <TypographyDDS.Title
            type='h4'
            variant='bold'
            style={{
              paddingRight: '20px'
            }}
          >
            Your Notifications
          </TypographyDDS.Title>
        </div>
      </Container>
      <Container>
        <TabWrapper style={{ position: 'relative' }} className='Tab'>
          <FilledTabGroup
            tabLabels={tabLabels}
            currentTab={currentTab}
            onChange={setCurrentTab}
            tabWidth='7.8125em'
            disabled={disabled}
          />
          {currentTab != 1 && (
            <div
              style={{
                position: 'absolute',
                right: '0'
              }}
            >
              <Button
                variant='outlined'
                startIcon={
                  <img
                    src={messageIcon}
                    height='16px'
                    width='16px'
                    style={{ filter: palette.colors.primarySvgFilter }}
                  />
                }
                textTransform='none'
                textColor='#474E5D'
                onClick={markAllAsRead}
                background='#FFFFFF'
                borderColor='#E0EDFF'
                fontWeight={palette.fontWeight.lighter}
                fontSize='0.875em'
                disabled={buttonDisabled}
              >
                Mark all as read
              </Button>
            </div>
          )}
        </TabWrapper>
      </Container>
      <AddFilterWrapper>
        <Filter dropdownLabel='Filters' options={options} />
      </AddFilterWrapper>
    </div>
  )
}

export default NotificationHeader
