import * as UserInvitesTypes from './UserInvites.types'

import ApiManager from '../Api.manager'
import { FilterTypes } from '../../enum/Filter.enum'
import HttpConstants from '../../constants/HttpConstants'
import StringConstants from '../../constants/StringConstants'
import UrlConstants from '../../constants/UrlConstants'
import { getFiltersForApi } from '../../utils/FilterUtils'

export const createUserInvite = (payload: {
  invitee: string
  role: string
}) => {
  const usecase = UrlConstants.CREATE_USER_INVITE.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const createUserProjectInvite = (payload: {
  invitee: string
  role: string
}) => {
  const usecase = UrlConstants.CREATE_USER_PROJECT_INVITE.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const getUserInviteFilters = () => {
  const usecase = UrlConstants.GET_USER_INVITES_FILTERS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      {}
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else throw response
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const findUserInvites = (
  payload: UserInvitesTypes.FindUserInvitesPayload
) => {
  const usecase = UrlConstants.GET_USER_INVITES.USECASE
  const filters = getFiltersForApi(FilterTypes.INVITED_MEMBERS, payload)
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      filters
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}

export const findUserInvite = (payload: { $id: string }) => {
  const usecase = UrlConstants.GET_USER_INVITE.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}

export const acceptUserInvite = (payload: { $token: string }) => {
  const usecase = UrlConstants.ACCEPT_USER_INVITE.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}
export const revokeUserInvite = (payload: { $token: string }) => {
  const usecase = UrlConstants.REVOKE_USER_INVITE.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}

export const rejectUserInvite = (payload: { $token: string }) => {
  const usecase = UrlConstants.REJECT_USER_INVITE.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}

export const resendUserInvite = (payload: { $id: string }) => {
  const usecase = UrlConstants.RESEND_USER_INVITE.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}

export const resendUserProjectInvite = (payload: { $id: string }) => {
  const usecase = UrlConstants.RESEND_USER_PROJECT_INVITE.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}

export const validateUserInvite = (payload: { $token: string }) => {
  const usecase = UrlConstants.VALIDATE_USER_INVITE.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCall(usecase, HttpConstants.GET_METHOD, payload)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const findAllMyUserInvites = (page = 1) => {
  const usecase = UrlConstants.GET_ALL_MY_USER_INVITES.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      {
        page,
        size: StringConstants.DEFAULT_TABLE_SIZE
      }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}
