import React, { useState } from 'react'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'
import { isEmpty, isUndefined } from 'lodash'

import Button from '../../common/Button/Button'
import GoBack from '../../AHCPage/AddCheckPopupFlow/GoBack'
import { IoIosArrowForward } from 'react-icons/io'
import MultiSelect from '../../common/MultiSelect/MultiSelect'
import { TypographyDDS } from '../../common/Typography/TypographyDDS'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'
import transferOwnershipIcon from '../../../assets/icons/transferOwnership.svg'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.875em;
  align-items: center;
  gap: 1.25em;
  width: 70%;
  text-align: center;
  margin: 80px;
`

const ColorText = styled.div`
  color: ${palette.colors.primary};
  display: inline;
  font-weight: 600;
`

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`

const SelectMemberStep: React.FC<{
  handleNext: () => void
  setData: (data: any) => void
  selectedUser: any
  handleSearch: (data: any) => void
  userOptions: any[]
  setSelectedUser: (data: any) => void
  memberSelectInternalStep: number
  openMultiSelect: boolean
  setOpenMultiSelect: (data: any) => void
  loadOptions: boolean
}> = ({
  handleNext,
  setData,
  selectedUser,
  userOptions,
  handleSearch,
  setSelectedUser,
  memberSelectInternalStep,
  openMultiSelect,
  setOpenMultiSelect,
  loadOptions
}) => {
  const [internalStep, setInternalStep] = useState<number>(
    memberSelectInternalStep
  )
  const [error, setError] = useState<boolean>(false)
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.TRANSFER_OWNERSHIP
  )

  return (
    <Container>
      {internalStep === 0 ? (
        <>
          <TypographyDDS.Title type='h3' variant='medium'>
            Select new owner for your <ColorText>organization</ColorText>
          </TypographyDDS.Title>
          <MultiSelect
            options={userOptions}
            value={selectedUser}
            optionsLabelAccessor={(user: any) => user.name}
            onChange={(user) => {
              setSelectedUser(user)
              setData(user)
              setError(false)
              setOpenMultiSelect(false)
            }}
            label='Transfer ownership to'
            placeholder="Enter member's name/email from your organization"
            onTextChange={handleSearch}
            singleSelect
            disableClearable
            openDropdown={openMultiSelect}
            setOpenDropdown={setOpenMultiSelect}
            loadingOption={loadOptions}
            validationError={error && 'Select a member to transfer ownership'}
          />
          <Button
            onClick={() => {
              if (!isEmpty(selectedUser) && !isUndefined(selectedUser)) {
                setInternalStep(1)
                gaEventTracker(gaEventConstants.SELECT_MEMBER_TO_TRANSFER)
              } else setError(true)
            }}
            variant='contained'
            endIcon={<IoIosArrowForward />}
            fullWidth
            height='3.5em'
          >
            Next
          </Button>
        </>
      ) : (
        <>
          <TypographyDDS.Title type='h3' variant='medium'>
            Ownership will be transferred to{' '}
            <ColorText>{selectedUser.name}</ColorText> and{' '}
            <ColorText style={{ color: palette.colors.error }}>
              you will no longer be the owner
            </ColorText>
          </TypographyDDS.Title>
          <Button
            onClick={() => {
              setData({ retainMembership: true })
              handleNext()
            }}
            variant='outlined'
            iconSize='16px'
            endIcon={
              <img
                src={transferOwnershipIcon}
                alt='transferOwnership'
                style={{
                  height: '16px',
                  width: '16px',
                  filter: palette.colors.primarySvgFilter
                }}
              />
            }
            fullWidth
            height='3.5em'
            $setIconToEnd={true}
            textTransform='none'
          >
            Transfer Ownership, and keep me as a member
          </Button>
          <Button
            onClick={() => {
              handleNext()
              setData({ retainMembership: false })
            }}
            iconSize='16px'
            variant='outlined'
            color='error'
            borderColor='red'
            endIcon={
              <img
                src={transferOwnershipIcon}
                alt='tranferOwnership'
                style={{
                  height: '16px',
                  width: '16px',
                  filter: palette.colors.errorSvgFilter
                }}
              />
            }
            fullWidth
            height='3.5em'
            $setIconToEnd={true}
            textTransform='none'
          >
            Transfer Ownership, and remove me from the organization
          </Button>
          <ActionWrapper onClick={() => setInternalStep(0)}>
            <GoBack />
          </ActionWrapper>
        </>
      )}
    </Container>
  )
}

export default SelectMemberStep
