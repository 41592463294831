import { AtoB, replaceUrl } from '../../utils/UrlUtils'
import { Box, BreadcrumbsProps, CardContent, Grid } from '@mui/material'
import { InputAdornment, TextField, Tooltip } from '@mui/material'
import Joi, { ValidationErrorItem } from 'joi'
import {
  deleteAnIncident,
  getAnIncident,
  updateAnIncident
} from '../../api/incident/incident.service'
import { isEmpty, isNull, isUndefined } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'

import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import Button from '../../components/common/Button/Button'
import Chip from '../../components/common/Chip/Chip'
import { ClickAwayListener } from '@material-ui/core'
import CopyOnClick from '../../components/common/CopyOnClick/CopyOnClick'
import DashboardSectionContainer from '../../components/common/DashboardSectionContainer/DashboardSectionContainer'
import DashboardTopNavbar from '../../components/common/DashboardTopNavbar/DashboardTopNavbar'
import DeletePopup from '../../components/JobMonitoringPage/DeleteLogPopup/DeletePopup'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import { DropdownChip } from '../../components/ErrorMonitoring/ErrorSeverity'
import ExceptionContextPopup from '../../components/ErrorMonitoring/Subcomponents/ExceptionContextPopup'
import { ExceptionIncidentPriority } from '../../enum/exception-incident-priority.enum'
import FilterChip from '../../components/common/FilterChip/FilterChip'
import { HeaderConstants } from '../../constants/HeaderConstants'
import IncidentActivitySection from '../../components/ExceptionsIncidentManagement/IncidentActivitySection'
import InputField from '../../components/common/InputField/InputField'
import { IoIosArrowForward } from 'react-icons/io'
import LinearProgressBar from '../../components/common/ProgressBar/ProgressBar'
import LinkIcon from '../../../src/assets/icons/link.svg'
import { ParaProps } from '../../components/ErrorMonitoring/ErrorDescription'
import PlusIcon from '../../../src/assets/icons/plus.svg'
import { ProjectType } from '../../enum/ProjectType.enum'
import React from 'react'
import { RefObject } from '../../components/common/InputField/Interface/Interface'
import SecureComponent from '../../components/Layout/SecureComponent'
import { SeverityTypes } from '../../api/exception/Exception.types'
import Spinner from '../../components/common/Spinner/Spinner'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../../components/common/DDS/Toast/Toast'
import ToggleButton from '../../components/common/ToggleButtons/ToggleButtons'
import TrashIcon from '../../../src/assets/icons/trash.svg'
import { TypographyDDS } from '../../components/common/Typography/TypographyDDS'
import breakpoints from '../../global/breakpoints'
import { css } from '@emotion/react'
import { getValidationStatus } from '../../utils/ValidationUtils'
import { observer } from 'mobx-react-lite'
import palette from '../../global/pallete'
import routeConstants from '../../constants/RouteConstants'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import { useStoreContext } from '../../store/StoreContext'

const Divider = styled.div<{ vertical?: boolean }>`
  ${({ vertical }) =>
    vertical
      ? css`
          border-right: 1px solid ${palette.colors.borderColor};
          height: 100%;
        `
      : `border-bottom: 1px solid ${palette.colors.borderColor};`}
`

const DescriptionSection = styled.div`
  background-color: ${palette.colors.lightBack};
  border: 1px solid ${palette.colors.borderColor};
  border-radius: 8px;
  padding: 8px 8px;
  textarea {
    outline: none;
    width: 100%;
    font-size: 16px;
    border: none;
    resize: none;
    padding: 8px 8px;
    background-color: ${palette.colors.lightBack};
  }
`

const ViewExpandedContainer = styled.span`
  display: flex;
  align-items: center;
  margin: -12px 0 0 0;
  cursor: pointer;
`

const ErrorActivity = styled.div`
  width: 100%;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  gap: 10px;
  justify-content: center;
  flex-grow: 1;
`

const ViewAnIncidentPage = () => {
  const [loading, setLoading] = React.useState(false)
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false)
  const referenceForCopyLink = React.useRef<RefObject>(null)
  const store = useStoreContext()
  const navigate = useNavigate()
  const [exceptionContextPopupOpen, setExceptionContextPopupOpen] =
    React.useState<boolean>(false)

  const { pathIds, exceptionLogId, incidentId } = useParams()
  const [displayAddSavedLinkInput, setDisplayAddSavedLinkInput] =
    React.useState(false)
  const [incidentDescription, setIncidentDescription] = React.useState('')
  const [savedUrl, setSavedUrl] = React.useState('')
  const [deleteIncidentPopupOpen, setDeleteIncidentPopupOpen] =
    React.useState<boolean>(false)
  const [validationErrorItems, setValidationErrorItems] = React.useState<
    ValidationErrorItem[]
  >([])

  const schema = Joi.object({
    url: Joi.string()
      .uri({
        scheme: ['https', 'http']
      })
      .required()
  })
  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY

  const findAnIncident_ = () => {
    setLoading(true)
    store.exceptionIncidentStore.setLoading(true)

    getAnIncident({
      $exceptionLogId: exceptionLogId,
      $incidentId: incidentId
    })
      .then((incident: any) => {
        if (!isUndefined(incident.data)) {
          store.exceptionIncidentStore.setIncidentDetails(incident.data)
          setIncidentDescription(incident.data['description'])
        }
      })
      .catch((err) => {
        if (
          err.exceptionName === 'RecordNotFoundException' ||
          err.exceptionName === 'GuardValidationFailedException'
        ) {
          navigate(routeConstants.NO_RECORD)
        }
      })
      .finally(() => {
        setLoading(false)
        store.exceptionIncidentStore.setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const updateAnIncident_ = (payload: any) => {
    setLoading(true)

    updateAnIncident({
      $exceptionLogId: exceptionLogId,
      $incidentId: incidentId,
      ...payload
    })
      .then((incident: any) => {
        if (!isUndefined(incident.data)) {
          // store.exceptionIncidentStore.setIncidentDetails(incident.data)
          // resetFields()
          findAnIncident_()
          // setIncidentDescription(incident.data['description'])
          ToastNotification({
            type: 'success',
            message: ToastMessageConstants.EXCEPTION_INCIDENT.UPDATE.SUCCESS
          })
        }
      })
      .catch((err: any) => {
        setLoading(false)
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.EXCEPTION_INCIDENT.UPDATE.ERROR
        })
      })
  }

  const handleCopyLink = (isPublic: boolean) => {
    referenceForCopyLink.current?.startLoading()
    setLoading(true)
    updateAnIncident({
      $exceptionLogId: exceptionLogId,
      $incidentId: incidentId,
      isPublic
    })
      .then((response: any) => {
        if (!isUndefined(response.data)) {
          store.exceptionIncidentStore.setIncidentDetails(response.data)
          setLoading(false)
        }
      })
      .catch(() => {
        setLoading(false)
      })
      .finally(() => {
        referenceForCopyLink.current?.stopLoading()
      })
  }

  const handleClickAway = () => {
    setIsTooltipOpen(false)
  }

  const deleteAnIncident_ = () => {
    setLoading(true)
    store.exceptionIncidentStore.setLoading(true)
    deleteAnIncident({
      $exceptionLogId: exceptionLogId,
      $incidentId: incidentId
    })
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.EXCEPTION_INCIDENT.DELETE.SUCCESS
        })
        const route =
          replaceUrl(
            routeConstants.ERROR_MONITORING,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) +
          '/' +
          exceptionLogId
        navigate(route)
      })
      .catch((err: any) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.EXCEPTION_INCIDENT.DELETE.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
        store.exceptionIncidentStore.setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  React.useEffect(() => {
    if (
      !isEmpty(AtoB(pathIds as string).split('/')[1]) &&
      !isEmpty(AtoB(pathIds as string).split('/')[2]) &&
      !isEmpty(AtoB(pathIds as string).split('/')[0])
    ) {
      store.scopeStore.setOrganizationId(
        AtoB(pathIds as string).split('/')[0] as string
      )
      store.scopeStore.setProjectId(
        AtoB(pathIds as string).split('/')[1] as string
      )
      store.scopeStore.setEnvironmentId(
        AtoB(pathIds as string).split('/')[2] as string
      )
    }
    findAnIncident_()
  }, [])

  const renderSeverity = (severity: SeverityTypes) => {
    switch (severity) {
      case SeverityTypes.CRITICAL:
        return (
          <DropdownChip size='para' variant='semiBold' color='red'>
            {SeverityTypes.CRITICAL}
          </DropdownChip>
        )
      case SeverityTypes.MAJOR:
        return (
          <DropdownChip size='para' variant='semiBold' color='orange'>
            {SeverityTypes.MAJOR}
          </DropdownChip>
        )
      case SeverityTypes.MODERATE:
        return (
          <DropdownChip size='para' variant='semiBold' color='blue'>
            {SeverityTypes.MODERATE}
          </DropdownChip>
        )
      case SeverityTypes.MINOR:
        return (
          <DropdownChip size='para' variant='semiBold'>
            {SeverityTypes.MINOR}
          </DropdownChip>
        )
    }
  }

  const onDeleteSavedLink = (linkToBeDeleted: string) => {
    const savedLinks = store.exceptionIncidentStore
      .getSavedLinks()
      .filter((savedLink) => savedLink != linkToBeDeleted)

    updateAnIncident_({
      savedLinks
    })
  }

  return (
    <>
      <DeletePopup
        handleClose={() => {
          setDeleteIncidentPopupOpen(false)
        }}
        onDelete={deleteAnIncident_}
        open={deleteIncidentPopupOpen}
        variant='single'
        type={'Incident'}
        loading={loading}
      />
      {/* <DashboardTopNavbar
        variant='exceptionsIncident'
        showBottomBar={true}
        showTopDropdowns={true}
        breadcrumbs={!isEmpty(store.exceptionIncidentStore.getIncidentNumber())}
        breadcrumbsProps={
          {
            noGutter: true,
            segmentLabels: [store.exceptionIncidentStore.getIncidentNumber()]
          } as BreadcrumbsProps
        }
      /> */}
      <DocumentHeader header={HeaderConstants.EXCEPTION_INCIDENTS} />

      <div>
        {exceptionContextPopupOpen && (
          <ExceptionContextPopup
            context={
              !isNull(
                store.exceptionIncidentStore.getIncidentDetails().exceptionLog
                  .context
              )
                ? store.exceptionIncidentStore.getIncidentDetails().exceptionLog
                    .context
                : ''
            }
            destroyOnClose
            open={exceptionContextPopupOpen}
            handleClose={() => setExceptionContextPopupOpen(false)}
          />
        )}
        <DashboardSectionContainer headerText='Incident Summary' paddingBottom>
          {!loading &&
          !isEmpty(store.exceptionIncidentStore.getIncidentDetails()) &&
          !isEmpty(
            store.exceptionIncidentStore.getIncidentDetails().exceptionLog
          ) ? (
            <>
              <Grid container direction={'row'}>
                <Grid
                  margin={'1em 0 1em 0'}
                  container
                  md={6}
                  xs={12}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: palette.colors.primary,
                        borderRadius: '10px',
                        justifyContent: 'space-between'
                      }}
                    >
                      <CardContent>
                        <TypographyDDS.Title
                          type='h4'
                          variant='semiBold'
                          color='white'
                        >
                          Incident Number
                        </TypographyDDS.Title>

                        <TypographyDDS.Paragraph
                          size='para'
                          variant='medium'
                          color='white'
                        >
                          {store.exceptionIncidentStore.getIncidentNumber()}
                        </TypographyDDS.Paragraph>
                      </CardContent>
                      <CardContent>
                        <TypographyDDS.Title
                          type='h4'
                          variant='semiBold'
                          color='white'
                        >
                          Created on
                        </TypographyDDS.Title>
                        <TypographyDDS.Paragraph
                          size='para'
                          variant='medium'
                          color='white'
                        >
                          {store.exceptionIncidentStore.getCreatedAt()}
                        </TypographyDDS.Paragraph>
                      </CardContent>
                      <CardContent>
                        <TypographyDDS.Title
                          color='white'
                          type='h4'
                          variant='semiBold'
                        >
                          Assigned to
                        </TypographyDDS.Title>
                        <TypographyDDS.Paragraph
                          size='para'
                          variant='medium'
                          color='white'
                        >
                          {store.exceptionIncidentStore.getAssigneeName()
                            ? store.exceptionIncidentStore.getAssigneeName()
                            : '-'}
                        </TypographyDDS.Paragraph>
                      </CardContent>
                    </Box>
                  </Grid>
                  <Grid item xs={12} marginTop={'1em'}>
                    <TypographyDDS.Title type='h4' variant='semiBold'>
                      Description
                    </TypographyDDS.Title>
                    <DescriptionSection>
                      <textarea
                        style={{ fontSize: '16px' }}
                        placeholder='Describe this incident in few words.'
                        rows={9}
                        onChange={(e: any) => {
                          setIncidentDescription(e.target.value)
                        }}
                        onBlur={(e: any) => {
                          if (
                            incidentDescription !==
                            store.exceptionIncidentStore.getDescription()
                          )
                            updateAnIncident_({
                              description: incidentDescription
                            })
                        }}
                        value={incidentDescription}
                      />
                    </DescriptionSection>
                  </Grid>
                </Grid>
                <Grid
                  margin={'1em 0 1em 0'}
                  container
                  md={6}
                  xs={12}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        border: 1,
                        borderColor: 'primary.main',
                        borderRadius: '10px'
                      }}
                    >
                      <CardContent>
                        <TypographyDDS.Title type='h4' variant='semiBold'>
                          Associated Exception
                        </TypographyDDS.Title>
                      </CardContent>
                      <CardContent>
                        <TypographyDDS.Title type='h4' variant='semiBold'>
                          {isFullStackProject ? 'Exception Type' : 'Error Type'}
                        </TypographyDDS.Title>
                        <TypographyDDS.Title
                          type='h1'
                          variant='bold'
                          color='primary'
                          style={{ fontSize: 32, lineHeight: '38px' }}
                        >
                          {
                            store.exceptionIncidentStore.getIncidentDetails()
                              .exceptionLog.exceptionType
                          }
                        </TypographyDDS.Title>
                        <br />
                        <ViewExpandedContainer
                          onClick={() => {
                            const route =
                              replaceUrl(
                                routeConstants.ERROR_MONITORING,
                                store.scopeStore.getScope()[
                                  'organization-id'
                                ] as string,
                                store.scopeStore.getScope()[
                                  'project-id'
                                ] as string,
                                store.scopeStore.getScope()[
                                  'environment-id'
                                ] as string
                              ) +
                              '/' +
                              exceptionLogId
                            navigate(route)
                          }}
                        >
                          <TypographyDDS.Title
                            variant='medium'
                            type='h5'
                            style={{ padding: '0 4px 0 0' }}
                          >
                            {isFullStackProject
                              ? 'View Exception'
                              : 'View Error'}
                          </TypographyDDS.Title>
                          <IoIosArrowForward color={palette.colors.primary} />
                        </ViewExpandedContainer>
                      </CardContent>
                      <CardContent>
                        <TypographyDDS.Title type='h4' variant='semiBold'>
                          {isFullStackProject
                            ? 'Exception Message'
                            : 'Error Message'}
                        </TypographyDDS.Title>
                        <TypographyDDS.Paragraph
                          size='para'
                          variant='medium'
                          color='black'
                        >
                          {
                            store.exceptionIncidentStore.getIncidentDetails()
                              .exceptionLog.exceptionMessage
                          }
                        </TypographyDDS.Paragraph>
                      </CardContent>
                      <Grid
                        item
                        display={{ xs: 'none', lg: 'block' }}
                        textAlign='center'
                      >
                        <Divider />
                      </Grid>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row'
                        }}
                      >
                        <CardContent>
                          <TypographyDDS.Title type='h4' variant='semiBold'>
                            Severity
                          </TypographyDDS.Title>
                          <TypographyDDS.Paragraph
                            color='black'
                            size='para'
                            variant='medium'
                          >
                            {renderSeverity(
                              store.exceptionIncidentStore.getIncidentDetails()
                                .exceptionLog.severity
                            )}
                          </TypographyDDS.Paragraph>
                        </CardContent>
                        <CardContent>
                          <TypographyDDS.Title type='h4' variant='semiBold'>
                            Last Reported
                          </TypographyDDS.Title>
                          <TypographyDDS.Paragraph
                            color='black'
                            size='para'
                            variant='medium'
                          >
                            {
                              store.exceptionIncidentStore.getIncidentDetails()
                                .exceptionLog.reportedAt
                            }
                          </TypographyDDS.Paragraph>
                        </CardContent>
                        <CardContent>
                          <TypographyDDS.Title type='h4' variant='semiBold'>
                            View Context
                          </TypographyDDS.Title>
                          <br />
                          <ViewExpandedContainer
                            onClick={() => {
                              setExceptionContextPopupOpen(true)
                            }}
                          >
                            <TypographyDDS.Title
                              variant='medium'
                              type='h4'
                              style={{ padding: '0 4px 0 0' }}
                            >
                              {isFullStackProject
                                ? 'View Exception Context'
                                : 'View Error Context'}{' '}
                            </TypographyDDS.Title>
                            <IoIosArrowForward color={palette.colors.primary} />
                          </ViewExpandedContainer>
                        </CardContent>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction={'row'}>
                <Grid
                  margin={'1em 0 1em 0'}
                  container
                  md={6}
                  xs={6}
                  spacing={2}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <CardContent
                      style={{
                        display: 'flex',
                        alignContent: 'flex-end'
                      }}
                    >
                      <TypographyDDS.Title type='h4' variant='semiBold'>
                        Priority
                        {Object.keys(ExceptionIncidentPriority).map(
                          (priority, index) => (
                            <Chip
                              style={{ cursor: 'pointer' }}
                              margin={'0 1em 0 1em'}
                              key={index}
                              label={priority}
                              highlighted={
                                priority ===
                                store.exceptionIncidentStore.getPriority()
                              }
                              color={palette.colors.textDark}
                              clickable={true}
                              onClick={() => {
                                setDisplayAddSavedLinkInput(false)
                                setIsTooltipOpen(false)
                                updateAnIncident_({ priority })
                              }}
                            />
                          )
                        )}
                      </TypographyDDS.Title>
                    </CardContent>
                  </Box>
                </Grid>
                <Grid
                  margin={'1em 0 1em 0'}
                  container
                  md={6}
                  xs={6}
                  spacing={2}
                >
                  <Grid item xs={5} />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <CardContent
                      style={{
                        display: 'flex',
                        alignContent: 'flex-end',
                        cursor: 'pointer'
                      }}
                    >
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '5px'
                            }}
                          >
                            <Tooltip
                              open={isTooltipOpen}
                              onClick={() => {
                                setIsTooltipOpen(!isTooltipOpen)
                              }}
                              title={
                                <div
                                  style={{
                                    display: 'flex',
                                    width: 'fit-content',
                                    flexDirection: 'column',
                                    gap: '5px',
                                    padding: '2px',
                                    fontSize: '12px',
                                    alignItems: 'flex-start'
                                  }}
                                >
                                  <Row
                                    style={{
                                      width: '100%'
                                    }}
                                  >
                                    <TypographyDDS.Title
                                      type='h4'
                                      variant='semiBold'
                                      style={{
                                        margin: 'auto',
                                        textAlign: 'left'
                                      }}
                                    >
                                      Enable public sharing link
                                    </TypographyDDS.Title>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                      }}
                                    >
                                      <ToggleButton
                                        onChange={(
                                          e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          handleCopyLink(e.target.checked)
                                        }}
                                        checked={
                                          store.exceptionIncidentStore.getIncidentDetails()
                                            .isPublic
                                        }
                                        disabled={loading}
                                      />
                                    </div>
                                  </Row>
                                  {store.exceptionIncidentStore.getIncidentDetails()
                                    .isPublic && (
                                    <TextField
                                      variant='outlined'
                                      color='primary'
                                      value={
                                        window.location.origin +
                                        '/' +
                                        'incident' +
                                        '/' +
                                        'shares' +
                                        '/' +
                                        store.exceptionIncidentStore.getIncidentDetails()
                                          .publicAccessToken
                                      }
                                      size='small'
                                      inputProps={{
                                        style: { cursor: 'not-allowed' }
                                      }}
                                      InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                          <InputAdornment
                                            position='end'
                                            sx={{ paddingTop: '6px' }}
                                          >
                                            {!loading && (
                                              <CopyOnClick
                                                value={
                                                  window.location.origin +
                                                  '/' +
                                                  'incident' +
                                                  '/' +
                                                  'shares' +
                                                  '/' +
                                                  store.exceptionIncidentStore.getIncidentDetails()
                                                    .publicAccessToken
                                                }
                                              />
                                            )}
                                          </InputAdornment>
                                        )
                                      }}
                                    />
                                  )}
                                  {loading && <LinearProgressBar />}
                                </div>
                              }
                              placement='bottom-end'
                              PopperProps={{
                                sx: {
                                  '& .MuiTooltip-tooltip': {
                                    backgroundColor: `${palette.colors.white}`,
                                    boxShadow: `0 1em 1.5625em ${palette.colors.tooltipShadow}`,
                                    fontSize: 11
                                  }
                                }
                              }}
                            >
                              <div style={{ alignSelf: 'flex-end' }}>
                                <img
                                  src={LinkIcon}
                                  height='20px'
                                  style={{
                                    marginRight: '.25em',
                                    marginBottom: '-5px'
                                  }}
                                />
                                <span style={{ fontSize: '14px' }}>Share</span>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </ClickAwayListener>
                    </CardContent>
                    <CardContent
                      style={{
                        display: 'flex',
                        alignContent: 'flex-end'
                      }}
                    >
                      <Button
                        width='200px'
                        color='error'
                        variant='contained'
                        textColor={palette.colors.white}
                        startIcon={
                          <img
                            src={TrashIcon}
                            style={{ filter: palette.colors.whiteSvgFilter }}
                          />
                        }
                        onClick={() => {
                          setDeleteIncidentPopupOpen(true)
                        }}
                        disabled={loading}
                      >
                        Delete
                      </Button>
                    </CardContent>
                  </Box>
                </Grid>
              </Grid>
              <Divider />
              <Grid container direction={'row'}>
                <Grid margin={'1em 0 1em 0'} container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <TypographyDDS.Title type='h4' variant='semiBold'>
                      Saved Links
                    </TypographyDDS.Title>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {displayAddSavedLinkInput ? (
                      <Grid container item xs={12} direction={'row'}>
                        <Grid
                          container
                          margin={'1em 0 1em 0'}
                          item
                          md={6}
                          xs={6}
                          spacing={1}
                        >
                          <Grid item xs={12}>
                            <InputField
                              label='Paste URL here'
                              onChange={(e: any) => {
                                setSavedUrl(e.target.value)
                              }}
                              width='100%'
                              error={getValidationStatus(
                                validationErrorItems,
                                'url'
                              )}
                              helperText={
                                getValidationStatus(validationErrorItems, 'url')
                                  ? 'Invalid URL'
                                  : ''
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          margin={'1em 0 1em 0'}
                          item
                          md={2}
                          xs={2}
                          spacing={1}
                        >
                          <Grid item xs={12} mt={1}>
                            <Button
                              variant='contained'
                              boxShadow={false}
                              onClick={() => {
                                // Validate the URL before saving
                                setIsTooltipOpen(false)
                                const validationResult = schema.validate(
                                  { url: savedUrl },
                                  { abortEarly: false }
                                )
                                const newValidationErrorItems =
                                  validationResult.error
                                    ? validationResult.error.details
                                    : []
                                if (newValidationErrorItems.length === 0) {
                                  if (!isEmpty(savedUrl)) {
                                    const savedLinks =
                                      store.exceptionIncidentStore
                                        .getSavedLinks()
                                        .concat([savedUrl])
                                    updateAnIncident_({
                                      savedLinks
                                    })
                                  }
                                  setDisplayAddSavedLinkInput(false)
                                  setValidationErrorItems([])
                                } else {
                                  setValidationErrorItems(
                                    newValidationErrorItems
                                  )
                                }
                              }}
                            >
                              Save Link
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          margin={'1em 0 1em 0'}
                          item
                          md={2}
                          xs={2}
                          spacing={1}
                        >
                          <Grid item xs={12} mt={1}>
                            <Button
                              variant='contained'
                              boxShadow={false}
                              color='error'
                              onClick={() => {
                                setSavedUrl('')
                                setDisplayAddSavedLinkInput(false)
                              }}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Button
                        variant='contained'
                        textColor={palette.colors.white}
                        startIcon={<img src={PlusIcon} />}
                        iconSize='12px'
                        disabled={loading}
                        width='150px'
                        onClick={() => {
                          setDisplayAddSavedLinkInput(true)
                        }}
                      >
                        Add Link
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {store.exceptionIncidentStore
                      .getSavedLinks()
                      .map((link, index) => (
                        <FilterChip
                          key={index}
                          variant='text'
                          value={link}
                          displayOnlyValue={true}
                          onDeleteMainChip={() => {
                            setIsTooltipOpen(false)
                            onDeleteSavedLink(link)
                          }}
                        />
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <Spinner />
          )}
        </DashboardSectionContainer>
        <ErrorActivity>
          <IncidentActivitySection />
        </ErrorActivity>
      </div>
    </>
  )
}

export default SecureComponent(
  observer(ViewAnIncidentPage),
  AccessRoleConstants.MEMBER_LEVEL
)

{
  /* <Grid margin={'1em 0 1em 0'} container md={6} xs={6} spacing={2}>
<Grid item xs={5} />
<Box
  sx={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }}
>
  <CardContent
    style={{
      display: 'flex',
      alignContent: 'flex-end',
      cursor: 'pointer'
    }}
  >
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '5px'
          }}
        >
          <Tooltip
            open={isTooltipOpen}
            onClick={() => {
              setIsTooltipOpen(!isTooltipOpen)
            }}
            title={
              <div
                style={{
                  display: 'flex',
                  width: 'fit-content',
                  flexDirection: 'column',
                  gap: '5px',
                  padding: '2px',
                  fontSize: '12px',
                  alignItems: 'flex-start'
                }}
              >
                <Row
                  style={{
                    width: '100%'
                  }}
                >
                  <Typography
                    variant='h6'
                    fontWeight={palette.fontWeight.semiBold}
                    align='left'
                    style={{ margin: 'auto' }}
                  >
                    Enable public sharing link
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <ToggleButton
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        handleCopyLink(e.target.checked)
                      }}
                      checked={
                        store.exceptionIncidentStore.getIncidentDetails()
                          .isPublic
                      }
                      disabled={loading}
                    />
                  </div>
                </Row>

                {store.exceptionIncidentStore.getIncidentDetails()
                  .isPublic && (
                  <TextField
                    variant='outlined'
                    color='primary'
                    value={
                      window.location.origin +
                      '/' +
                      'incident' +
                      '/' +
                      'shares' +
                      '/' +
                      store.exceptionIncidentStore.getIncidentDetails()
                        .publicAccessToken
                    }
                    size='small'
                    inputProps={{
                      style: { cursor: 'not-allowed' }
                    }}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position='end'>
                          {!loading && (
                            <CopyOnClick
                              value={
                                window.location.origin +
                                '/' +
                                'incident' +
                                '/' +
                                'shares' +
                                '/' +
                                store.exceptionIncidentStore.getIncidentDetails()
                                  .publicAccessToken
                              }
                              size={'20px'}
                            />
                          )}
                        </InputAdornment>
                      )
                    }}
                  />
                )}
                {loading && <LinearProgressBar />}
              </div>
            }
            placement='bottom-end'
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  backgroundColor: `${palette.colors.white}`,
                  boxShadow: `0 1em 1.5625em ${palette.colors.tooltipShadow}`,
                  fontSize: 11
                }
              }
            }}
          >
            <div style={{ alignSelf: 'flex-end' }}>
              <img
                src={LinkIcon}
                height='20px'
                style={{
                  marginRight: '.25em',
                  marginBottom: '-5px'
                }}
              />
              <span style={{ fontSize: '14px' }}>Share</span>
            </div>
          </Tooltip>
        </div>
      </div>
    </ClickAwayListener>
  </CardContent>

  <CardContent
    style={{
      display: 'flex',
      alignContent: 'flex-end'
    }}
  >
    <Button
      width='200px'
      color='error'
      variant='contained'
      textColor={palette.colors.white}
      startIcon={
        <img
          src={TrashIcon}
          style={{ filter: palette.colors.whiteSvgFilter }}
        />
      }
      onClick={() => {
        console.log('deleting incident')
        setDeleteIncidentPopupOpen(true)
      }}
      disabled={loading}
    >
      Delete
    </Button>
  </CardContent>
</Box>
</Grid> */
}
