import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'
import { isUndefined, toLower } from 'lodash'

import Button from '../../common/Button/Button'
import DeleteIcon from '../../../assets/icons/trash.svg'
import Popup from '../../common/Popup/Popup'
import React from 'react'
import StringConstants from '../../../constants/StringConstants'
import { TypographyDDS } from '../../common/Typography/TypographyDDS'
import closeIcon from '../../../assets/icons/close.svg'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  gap: 30px;
`
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
const ColorText = styled.div`
  color: ${palette.colors.error};
  display: inline;
`
const ButtonWrapper = styled.div`
  display: flex;
`
const Text = styled.div`
  white-space: nowrap;
  text-align: center;
`

const DeletePopup: React.FC<{
  variant?: 'single' | 'multi'
  quantity?: number
  open: boolean
  handleClose: () => void
  onDelete: () => void
  onMultiDeleteMode?: () => void
  type?: string
  loading?: boolean
  record?: string
}> = ({
  variant = 'single',
  quantity,
  open,
  handleClose,
  onDelete,
  onMultiDeleteMode,
  type,
  loading,
  record
}) => {
  const displayText: string =
    type === 'API Record' ? 'API record' : toLower(type)
  const gaEventTracker = useAnalyticsEventTracker(gaPageConstants.DELETE_POPUP)
  const variantMap = {
    single: {
      text: (
        <>
          {!isUndefined(record) ? (
            record.length > StringConstants.DELETE_POPUP_NAME_FIELD ? (
              <Text>
                {`The ${displayText} "${
                  record.substring(0, StringConstants.DELETE_POPUP_NAME_FIELD) +
                  '...'
                }"`}
                <br />
                {` will be`}
                <ColorText> deleted permanently</ColorText>
              </Text>
            ) : (
              <>
                {`The ${displayText} "${record}" will be`}
                <ColorText> deleted permanently</ColorText>
              </>
            )
          ) : (
            <>
              {`The ${displayText} will be `}
              <ColorText>deleted permanently</ColorText>{' '}
            </>
          )}
        </>
      ),
      button: (
        <>
          {/* <Button
            color='primary'
            variant='contained'
            startIcon={<img
                src={DeleteIcon}
                style={{
                  filter: palette.colors.whiteSvgFilter,
                }}
              />}
            onClick={onMultiDeleteMode}
          >
            Delete Multiple Jobs
          </Button> */}
          <Button
            id='delete'
            color='error'
            variant='contained'
            startIcon={
              <img
                src={DeleteIcon}
                style={{
                  filter: palette.colors.whiteSvgFilter
                }}
              />
            }
            onClick={onDelete}
            margin={'0 20px 0 0 '}
            loading={loading}
          >
            Delete
          </Button>
        </>
      )
    },
    multi: {
      text: (
        <>
          {quantity} Job Logs will be deleted permanently
          <ColorText> deleted permanently</ColorText>
        </>
      ),
      button: (
        <Button
          color='error'
          variant='contained'
          startIcon={
            <img
              src={DeleteIcon}
              style={{ filter: palette.colors.whiteSvgFilter }}
            />
          }
          onClick={() => {
            if (type === 'health check')
              gaEventTracker(gaEventConstants.DELETE_CHECK)
            else if (type === 'API Record')
              gaEventTracker(gaEventConstants.DELETE_API_RECORD)
            onDelete()
          }}
          margin={'0 20px 0 0 '}
        >
          Delete
        </Button>
      )
    }
  }
  return (
    <Popup
      open={open}
      handleClose={handleClose}
      headerText={`Delete ${type}`}
      headerIcon={
        <img
          src={DeleteIcon}
          style={{
            filter: palette.colors.redSvgFilter,
            height: '18px',
            width: '18px'
          }}
        />
      }
      height='300px'
      disabled={loading}
    >
      <Container>
        <TypographyDDS.Title type='h3' variant='medium'>
          {variantMap[variant].text}
        </TypographyDDS.Title>

        <ButtonWrapper>
          <ButtonsContainer>{variantMap[variant].button}</ButtonsContainer>
          <ButtonsContainer>
            <Button
              startIcon={
                <img
                  src={closeIcon}
                  style={{
                    filter: palette.colors.whiteSvgFilter
                  }}
                />
              }
              variant='contained'
              onClick={handleClose}
              disabled={loading}
            >
              Cancel
            </Button>
          </ButtonsContainer>
        </ButtonWrapper>
      </Container>
    </Popup>
  )
}

export default DeletePopup
