import { Grid, Stack } from '@mui/material'
import {
  availabilityToColor,
  formatTextForTooltip
} from '../../utils/OverviewUtils'

import { ChevronRight } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import { IoIosArrowForward } from 'react-icons/io'
import JMStringConstants from '../../constants/JMStringConstants'
import OverviewStringConstants from '../../constants/OverviewStringConstants'
import React from 'react'
import Table from '../common/DDS/Table/Table'
import Tooltip from '../New/Tooltip/Tooltip'
import { TypographyDDS } from '../common/Typography/TypographyDDS'
import { observer } from 'mobx-react-lite'
import palette from '../../global/pallete'
import pallete from '../../global/newPallete'
import { replaceUrl } from '../../utils/UrlUtils'
import routeConstants from '../../constants/RouteConstants'
import { timeMetric } from '../../utils/DateTimeUtils'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../store/StoreContext'

const OverviewViewMoreTable: React.FC = () => {
  const { overviewStore, scopeStore } = useStoreContext()
  const navigate = useNavigate()
  const navigateToAHC = (id = '') => {
    navigate(
      replaceUrl(
        routeConstants.AHC_PROJECTS_DASHBOARD,
        scopeStore.getScope()['organization-id'] as string,
        scopeStore.getScope()['project-id'] as string,
        scopeStore.getScope()['environment-id'] as string
      ) + (id === '' ? '' : `/${id}`)
    )
  }
  const navigateToJobMonitoring = (id = '') => {
    navigate(
      replaceUrl(
        routeConstants.JOB_MONITORING,
        scopeStore.getScope()['organization-id'] as string,
        scopeStore.getScope()['project-id'] as string,
        scopeStore.getScope()['environment-id'] as string
      ) +
        `/${id}` +
        '?page=1&tab=1&previousTab=1'
    )
  }
  const jobColumnsMap = [
    [
      {
        columnHeaderStyle: { width: '246px' },
        columnDataStyle: { width: '246px' },
        heading: (
          <div className='flex justify-center text-center row items-center '>
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark5}
            >
              {OverviewStringConstants.CRON_JOB}
            </DDSTypography.Paragraph>
          </div>
        ),
        dataLabel: 'jobName',
        render: (text: any, fullItem: any) => (
          <div className='flex justify-start text-left'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate max-w-[230px]'
              color={pallete.colors.textDark4}
            >
              {text ? text : '-'}
            </DDSTypography.Paragraph>
          </div>
        )
      },
      {
        columnHeaderStyle: {},
        columnDataStyle: {},
        heading: (
          <div className='flex justify-center text-center row items-center '>
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark5}
            >
              {OverviewStringConstants.EXECUTIONS}
            </DDSTypography.Paragraph>
          </div>
        ),
        dataLabel: 'executionCount',
        render: (text: any) => (
          <div className='flex justify-center text-center'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
            >
              {text}
            </DDSTypography.Paragraph>
          </div>
        )
      },
      {
        columnHeaderStyle: { width: '246px' },
        columnDataStyle: { width: '246px' },
        heading: (
          <div className='flex justify-center text-center row items-center '>
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark5}
            >
              {JMStringConstants.JOB_DESCRIPTION}
            </DDSTypography.Paragraph>
          </div>
        ),
        dataLabel: 'jobDescription',
        render: (text: any, fullItem: any) => (
          <div className='flex justify-center text-center'>
            <Tooltip
              id={`tooltip-job-desc-${fullItem.id}`}
              label={text ? text : '-'}
              className={`max-w-[300px] ${typeof text === 'string' && text.length > 30 ? text : '!hidden'}`}
            >
              <DDSTypography.Paragraph
                size='para'
                variant='medium'
                className='truncate max-w-[230px]'
                color={pallete.colors.textDark4}
              >
                {text ? text : '-'}
              </DDSTypography.Paragraph>
            </Tooltip>
          </div>
        )
      },
      {
        columnHeaderStyle: { width: '60px' },
        columnDataStyle: { width: '60px' },
        heading: '',
        dataLabel: 'id',
        render: (id: any) => (
          <div className='flex justify-center items-center'>
            <ChevronRight
              className='cursor-pointer'
              size={16}
              color={pallete.colors.textDark2}
              onClick={() => navigateToJobMonitoring(id)}
            />
          </div>
        )
      }
    ],
    [
      {
        columnHeaderStyle: { width: '246px' },
        columnDataStyle: { width: '246px' },
        heading: (
          <div className='flex justify-center text-center row items-center '>
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark5}
            >
              {OverviewStringConstants.CRON_JOB}
            </DDSTypography.Paragraph>
          </div>
        ),
        dataLabel: 'jobName',
        render: (text: any, fullItem: any) => (
          <div className='flex justify-start text-left'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate'
              color={pallete.colors.textDark4}
            >
              {text ? text : '-'}
            </DDSTypography.Paragraph>
          </div>
        )
      },
      {
        columnHeaderStyle: {},
        columnDataStyle: {},
        heading: (
          <div className='flex justify-center text-center row items-center '>
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark5}
            >
              {OverviewStringConstants.EXECUTIONS}
            </DDSTypography.Paragraph>
          </div>
        ),
        dataLabel: 'executionCount',
        render: (text: any) => (
          <div className='flex justify-center text-center'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
            >
              {text}
            </DDSTypography.Paragraph>
          </div>
        )
      },
      {
        columnHeaderStyle: { width: '246px' },
        columnDataStyle: { width: '246px' },
        heading: (
          <div className='flex justify-center text-center row items-center '>
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark5}
            >
              {JMStringConstants.JOB_DESCRIPTION}
            </DDSTypography.Paragraph>
          </div>
        ),
        dataLabel: 'jobDescription',
        render: (text: any, fullItem: any) => (
          <div className='flex justify-center text-center'>
            <Tooltip
              id={`tooltip-job-desc-${fullItem.id}`}
              label={text ? text : '-'}
              className={`max-w-[300px] ${typeof text === 'string' && text.length > 30 ? text : '!hidden'}`}
            >
              <DDSTypography.Paragraph
                size='para'
                variant='medium'
                className='truncate max-w-[230px]'
                color={pallete.colors.textDark4}
              >
                {text ? text : '-'}
              </DDSTypography.Paragraph>
            </Tooltip>
          </div>
        )
      },
      {
        columnHeaderStyle: { width: '60px' },
        columnDataStyle: { width: '60px' },
        heading: '',
        dataLabel: 'id',
        render: (id: any) => (
          <div className='flex justify-center items-center'>
            <ChevronRight
              className='cursor-pointer'
              size={16}
              color={pallete.colors.textDark2}
              onClick={() => navigateToJobMonitoring(id)}
            />
          </div>
        )
      }
    ],
    [
      {
        columnHeaderStyle: { width: '246px' },
        columnDataStyle: { width: '246px' },
        heading: (
          <div className='flex justify-center text-center row items-center '>
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark5}
            >
              {OverviewStringConstants.CRON_JOB}
            </DDSTypography.Paragraph>
          </div>
        ),
        dataLabel: 'jobName',
        render: (text: any, fullItem: any) => (
          <div className='flex justify-start text-left'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate max-w-[230px]'
              color={pallete.colors.textDark4}
            >
              {text ? text : '-'}
            </DDSTypography.Paragraph>
          </div>
        )
      },
      {
        columnHeaderStyle: {},
        columnDataStyle: {},
        heading: (
          <div className='flex justify-center text-center row items-center '>
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark5}
            >
              {OverviewStringConstants.RUN_TIME}
            </DDSTypography.Paragraph>
          </div>
        ),
        dataLabel: 'averageRunTimeInMinutes',
        render: (text: any) => (
          <div className='flex justify-center text-center'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
            >
              {text ? timeMetric(text, 'm') : '-'}
            </DDSTypography.Paragraph>
          </div>
        )
      },
      {
        columnHeaderStyle: { width: '246px' },
        columnDataStyle: { width: '246px' },
        heading: (
          <div className='flex justify-center text-center row items-center '>
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark5}
            >
              {JMStringConstants.JOB_DESCRIPTION}
            </DDSTypography.Paragraph>
          </div>
        ),
        dataLabel: 'jobDescription',
        render: (text: any, fullItem: any) => (
          <div className='flex justify-center text-center'>
            <Tooltip
              id={`tooltip-job-desc-${fullItem.id}`}
              label={text ? text : '-'}
              className={`max-w-[300px] ${typeof text === 'string' && text.length > 30 ? text : '!hidden'}`}
            >
              <DDSTypography.Paragraph
                size='para'
                variant='medium'
                className='truncate max-w-[230px]'
                color={pallete.colors.textDark4}
              >
                {text ? text : '-'}
              </DDSTypography.Paragraph>
            </Tooltip>
          </div>
        )
      },
      {
        columnHeaderStyle: { width: '60px' },
        columnDataStyle: { width: '60px' },
        heading: '',
        dataLabel: 'id',
        render: (id: any) => (
          <div className='flex justify-center items-center'>
            <ChevronRight
              className='cursor-pointer'
              size={16}
              color={pallete.colors.textDark2}
              onClick={() => navigateToJobMonitoring(id)}
            />
          </div>
        )
      }
    ]
  ]
  const healthColumnsMap = [
    [
      {
        columnHeaderStyle: { width: '246px' },
        columnDataStyle: { width: '246px' },
        heading: (
          <div className='flex justify-center text-center row items-center '>
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark5}
            >
              {OverviewStringConstants.CHECK_NAME}
            </DDSTypography.Paragraph>
          </div>
        ),
        dataLabel: 'checkName',
        render: (text: any, fullItem: any) => (
          <div className='flex justify-start text-left'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate max-w-[220px]'
              color={pallete.colors.textDark4}
            >
              {text ? text : '-'}
            </DDSTypography.Paragraph>
          </div>
        )
      },
      {
        columnHeaderStyle: {},
        columnDataStyle: {},
        heading: (
          <div className='flex justify-center text-center row items-center '>
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark5}
            >
              {OverviewStringConstants.AVAILABILITY}
            </DDSTypography.Paragraph>
          </div>
        ),
        dataLabel: 'availability',
        render: (text: any) => (
          <div className='flex justify-center text-center'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={availabilityToColor(text.toString())}
            >
              {text + '%'}
            </DDSTypography.Paragraph>
          </div>
        )
      },
      {
        columnHeaderStyle: { width: '246px' },
        columnDataStyle: { width: '246px' },
        heading: (
          <div className='flex justify-center text-center row items-center '>
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark5}
            >
              {OverviewStringConstants.URL}
            </DDSTypography.Paragraph>
          </div>
        ),
        dataLabel: 'url',
        render: (text: any, fullItem: any) => (
          <div className='flex justify-center text-center'>
            <Tooltip
              id={`tooltip-url-${fullItem['id']}`}
              label={
                text && text.length > 30 ? formatTextForTooltip(text) : '-'
              }
              className={`max-w-[400px] ${typeof text === 'string' && text.length > 30 ? text : '!hidden'}`}
            >
              <DDSTypography.Paragraph
                size='para'
                variant='medium'
                className={`truncate max-w-[230px] `}
                color={pallete.colors.textDark4}
              >
                {text ? text : '-'}
              </DDSTypography.Paragraph>
            </Tooltip>
          </div>
        )
      },
      {
        columnHeaderStyle: { width: '60px' },
        columnDataStyle: { width: '60px' },
        heading: '',
        dataLabel: 'id',
        render: (id: any) => (
          <div className='flex justify-center items-center'>
            <ChevronRight
              className='cursor-pointer'
              size={16}
              color={pallete.colors.textDark2}
              onClick={() => navigateToAHC(id)}
            />
          </div>
        )
      }
    ],
    [
      {
        columnHeaderStyle: { width: '246px' },
        columnDataStyle: { width: '246px' },
        heading: (
          <div className='flex justify-center text-center row items-center '>
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark5}
            >
              {OverviewStringConstants.CHECK_NAME}
            </DDSTypography.Paragraph>
          </div>
        ),
        dataLabel: 'checkName',
        render: (text: any, fullItem: any) => (
          <div className='flex justify-start text-left'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate max-w-[220px]'
              color={pallete.colors.textDark4}
            >
              {text ? text : '-'}
            </DDSTypography.Paragraph>
          </div>
        )
      },
      {
        columnHeaderStyle: {},
        columnDataStyle: {},
        heading: (
          <div className='flex justify-center text-center row items-center '>
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark5}
            >
              {OverviewStringConstants.DOWN_TIME}
            </DDSTypography.Paragraph>
          </div>
        ),
        dataLabel: 'downTime',
        render: (text: any) => (
          <div className='flex justify-center text-center'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
            >
              {text ? timeMetric(text, 'm') : '-'}
            </DDSTypography.Paragraph>
          </div>
        )
      },
      {
        columnHeaderStyle: { width: '246px' },
        columnDataStyle: { width: '246px' },
        heading: (
          <div className='flex justify-center text-center row items-center '>
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark5}
            >
              {OverviewStringConstants.URL}
            </DDSTypography.Paragraph>
          </div>
        ),
        dataLabel: 'url',
        render: (text: any, fullItem: any) => (
          <div className='flex justify-center text-center'>
            <Tooltip
              id={`tooltip-url-${fullItem['id']}`}
              label={
                text && text.length > 30 ? formatTextForTooltip(text) : '-'
              }
              className={`max-w-[400px] ${typeof text === 'string' && text.length > 30 ? text : '!hidden'}`}
            >
              <DDSTypography.Paragraph
                size='para'
                variant='medium'
                className={`truncate max-w-[230px] `}
                color={pallete.colors.textDark4}
              >
                {text ? text : '-'}
              </DDSTypography.Paragraph>
            </Tooltip>
          </div>
        )
      },
      {
        columnHeaderStyle: { width: '60px' },
        columnDataStyle: { width: '60px' },
        heading: '',
        dataLabel: 'id',
        render: (id: any) => (
          <div className='flex justify-center items-center'>
            <ChevronRight
              className='cursor-pointer'
              size={16}
              color={pallete.colors.textDark2}
              onClick={() => navigateToAHC(id)}
            />
          </div>
        )
      }
    ],
    [
      {
        columnHeaderStyle: { width: '246px' },
        columnDataStyle: { width: '246px' },
        heading: (
          <div className='flex justify-center text-center row items-center '>
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark5}
            >
              {OverviewStringConstants.CHECK_NAME}
            </DDSTypography.Paragraph>
          </div>
        ),
        dataLabel: 'checkName',
        render: (text: any, fullItem: any) => (
          <div className='flex justify-start text-left'>
            <Tooltip
              id={`tooltip-name-${fullItem.id}`}
              label={text ? text : '-'}
              className={`${typeof text === 'string' && text.length > 10 ? text : '!hidden'}`}
            >
              <DDSTypography.Paragraph
                size='para'
                variant='medium'
                className='truncate max-w-[220px]'
                color={pallete.colors.textDark4}
              >
                {text ? text : '-'}
              </DDSTypography.Paragraph>
            </Tooltip>
          </div>
        )
      },
      {
        columnHeaderStyle: {},
        columnDataStyle: {},
        heading: (
          <div className='flex justify-center text-center row items-center '>
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark5}
            >
              {OverviewStringConstants.RESPONSE_TIME}
            </DDSTypography.Paragraph>
          </div>
        ),
        dataLabel: 'averageResponseTime',
        render: (averageResponseTime: any) => (
          <div className='flex justify-center text-center'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
            >
              {averageResponseTime
                ? timeMetric(averageResponseTime, 'ms')
                : '-'}
            </DDSTypography.Paragraph>
          </div>
        )
      },
      {
        columnHeaderStyle: { width: '246px' },
        columnDataStyle: { width: '246px' },
        heading: (
          <div className='flex justify-center text-center row items-center '>
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark5}
            >
              {OverviewStringConstants.URL}
            </DDSTypography.Paragraph>
          </div>
        ),
        dataLabel: 'url',
        render: (text: any, fullItem: any) => (
          <div className='flex justify-center text-center'>
            <Tooltip
              id={`tooltip-url-${fullItem['id']}`}
              label={
                text && text.length > 30 ? formatTextForTooltip(text) : '-'
              }
              className={`max-w-[400px] ${typeof text === 'string' && text.length > 30 ? text : '!hidden'}`}
            >
              <DDSTypography.Paragraph
                size='para'
                variant='medium'
                className={`truncate max-w-[230px] `}
                color={pallete.colors.textDark4}
              >
                {text ? text : '-'}
              </DDSTypography.Paragraph>
            </Tooltip>
          </div>
        )
      },
      {
        columnHeaderStyle: { width: '60px' },
        columnDataStyle: { width: '60px' },
        heading: '',
        dataLabel: 'id',
        render: (id: any) => (
          <div className='flex justify-center items-center'>
            <ChevronRight
              className='cursor-pointer'
              size={16}
              color={pallete.colors.textDark2}
              onClick={() => navigateToAHC(id)}
            />
          </div>
        )
      }
    ]
  ]
  const apiColumnsMap = [
    [
      {
        columnHeaderStyle: {},
        columnDataStyle: {},
        heading: (
          <div className='flex justify-center text-center row items-center '>
            <DDSTypography.Paragraph
              size='caption'
              className=''
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              {OverviewStringConstants.URL_TEMPLATE}
            </DDSTypography.Paragraph>
          </div>
        ),
        dataLabel: 'urlTemplate',
        render: (text: any) => (
          <div className='flex justify-start text-left max-w-[100px]'>
            <Tooltip
              id={`tooltip-urlTemplate-${text}`}
              label={text ? text : '-'}
              className={`${typeof text === 'string' && text.length > 10 ? text : '!hidden'}`}
            >
              <DDSTypography.Paragraph
                size='para'
                variant='medium'
                className='truncate max-w-[100px]'
                color={pallete.colors.textDark4}
              >
                {text ? text : '-'}
              </DDSTypography.Paragraph>
            </Tooltip>
          </div>
        )
      },
      {
        columnHeaderStyle: {},
        columnDataStyle: {},
        heading: (
          <div className='flex justify-center text-center row items-center '>
            <DDSTypography.Paragraph
              size='caption'
              className=''
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              {OverviewStringConstants.TOTAL_REQUESTS}
            </DDSTypography.Paragraph>
          </div>
        ),
        dataLabel: 'noOfLogs',
        render: (text: any) => (
          <div className='flex justify-center text-center'>
            <Tooltip
              id={`tooltip-requests-${text}`}
              label={text ? text : '-'}
              className={`${typeof text === 'string' && text.length > 10 ? text : '!hidden'}`}
            >
              <DDSTypography.Paragraph
                size='para'
                variant='medium'
                color={pallete.colors.textDark4}
              >
                {text ? text + '%' : '-'}
              </DDSTypography.Paragraph>
            </Tooltip>
          </div>
        )
      },
      {
        columnHeaderStyle: {},
        columnDataStyle: {},
        heading: (
          <div className='flex justify-center text-center row items-center '>
            <DDSTypography.Paragraph
              size='caption'
              className=''
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              {OverviewStringConstants.HTTP_METHOD}
            </DDSTypography.Paragraph>
          </div>
        ),
        dataLabel: 'httpMethod',
        render: (text: any) => (
          <div className='flex justify-center text-center'>
            <Tooltip
              id={`tooltip-http-${text}`}
              label={text ? text : '-'}
              className={`${typeof text === 'string' && text.length > 10 ? text : '!hidden'}`}
            >
              <DDSTypography.Paragraph
                size='para'
                variant='medium'
                color={pallete.colors.textDark4}
              >
                {text ? text : '-'}
              </DDSTypography.Paragraph>
            </Tooltip>
          </div>
        )
      },
      {
        columnHeaderStyle: {},
        columnDataStyle: {},
        heading: '',
        dataLabel: 'id',
        render: (text: any) => (
          <div className='flex justify-center items-center'>
            <ChevronRight size={16} color={pallete.colors.textDark2} />
          </div>
        )
      }
    ]
  ]

  const moduleColumnMap = {
    job: jobColumnsMap,
    api: apiColumnsMap,
    health: healthColumnsMap
  }

  const columns =
    moduleColumnMap[overviewStore.getActiveModule()][
      overviewStore.getActiveTab()
    ]
  const data = overviewStore.getActiveData()

  return data.length !== 0 ? (
    <Table
      totalCount={data.length}
      data={data}
      columns={columns}
      showPagination={false}
    />
  ) : (
    <Grid container alignItems='center' mt={2}>
      <Grid item xs={12} textAlign='center'>
        <TypographyDDS.Title type='h5' variant='medium' color='textDark2'>
          No Data
        </TypographyDDS.Title>
      </Grid>
    </Grid>
  )
}

export default observer(OverviewViewMoreTable)
