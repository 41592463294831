import Joi, { ValidationErrorItem } from 'joi'
import React, { useState } from 'react'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'
import {
  getValidationError,
  getValidationStatus
} from '../../../utils/ValidationUtils'

import Button from '../Button/Button'
import { RiDeleteBin6Line as DeleteIcon } from 'react-icons/ri'
import GoBack from '../../AHCPage/AddCheckPopupFlow/GoBack'
import InputField from '../InputField/InputField'
import { TypographyDDS } from '../Typography/TypographyDDS'
import ValidationConstants from '../../../constants/ValidationConstants'
import breakpoints from '../../../global/breakpoints'
import { isUndefined } from 'lodash'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'

interface TextConfirmationSectionProps {
  confirmationText?: string
  handleSubmit: () => void
  context: string
  heading: string
  buttonIcon?: React.ReactElement
  buttonText?: string
  loading: boolean
  goBack: () => void
}
const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 750px;
  width: 90%;
  margin: 80px;
`
const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.85rem;
  }
  @media (max-width: ${breakpoints.md}) {
    font-size: 0.7rem;
  }
  width: 40%;
`
const Right = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 50%;
  margin-left: 20px;
`

const ItemContainer = styled.div<ItemContainerProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`

interface ItemContainerProps {
  width?: string
  height?: string
  padding?: string
  margin?: string
}

const TextConfirmationSection: React.FC<TextConfirmationSectionProps> = ({
  context,
  confirmationText = 'Confirm',
  handleSubmit,
  buttonIcon,
  buttonText,
  loading,
  goBack
}) => {
  const [validationText, setValidationText] = useState('')
  const [validationErrorResult, setValidationErrorResult] = useState<
    ValidationErrorItem[]
  >([])

  const schema = Joi.object({
    confirmationText: Joi.string().required(),
    validationText: Joi.string()
      .required()
      .messages({
        'string.empty':
          ValidationConstants.DELETE_CONFIRMATION.CONFIRMATION_TEXT_EMPTY,
        '*': ValidationConstants.DELETE_CONFIRMATION.CONFIRMATION_TEXT
      })
      .equal(Joi.ref('confirmationText'))
  })
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.TRANSFER_OWNERSHIP
  )

  const validate = () => {
    const validationResult = schema.validate(
      {
        confirmationText,
        validationText
      },
      { abortEarly: false }
    )

    if (isUndefined(validationResult.error)) {
      setValidationErrorResult([])
      handleSubmit()
      gaEventTracker(gaEventConstants.TRANSFERRED_OWNERSHIP)
    } else setValidationErrorResult(validationResult.error.details)
  }

  return (
    <SectionWrapper>
      <Left>
        <TypographyDDS.Title
          type='h3'
          variant='medium'
          style={{
            paddingBottom: '10px',
            textAlign: 'left'
          }}
        >
          Type
          <span
            style={{
              fontWeight: palette.fontWeight.bold,
              color: palette.colors.primary
            }}
          >
            {' '}
            {`"${confirmationText}"`}{' '}
          </span>
          to proceed
        </TypographyDDS.Title>
        <TypographyDDS.Title type='h4' variant='medium' color='textDark2'>
          <span
            style={{
              color: palette.colors.error,
              fontWeight: palette.fontWeight.semiBold
            }}
          >
            Note:{' '}
          </span>
          {context}
        </TypographyDDS.Title>
        <ActionWrapper onClick={goBack}>
          <GoBack />
        </ActionWrapper>
      </Left>
      <Right>
        <ItemContainer>
          <InputField
            error={getValidationStatus(validationErrorResult, 'validationText')}
            placeholder='Type here'
            label='Text Confirmation'
            type='string'
            fullWidth
            onChange={(e) => setValidationText(e.target.value)}
            helperText={getValidationError(
              validationErrorResult,
              'validationText'
            )}
          />
        </ItemContainer>
        <ItemContainer>
          <Button
            background={
              buttonText
                ? ''
                : `linear-gradient(270deg, ${palette.colors.error} 0%, ${palette.colors.error} 100%)`
            }
            endIcon={buttonIcon ? buttonIcon : <></>}
            startIcon={buttonIcon ? <></> : <DeleteIcon />}
            height='56px'
            variant='contained'
            iconSize='16px'
            size='large'
            fullWidth
            margin='32px 0px 0px'
            onClick={validate}
            loading={loading}
          >
            {buttonText ? buttonText : `Delete`}
          </Button>
        </ItemContainer>
      </Right>
    </SectionWrapper>
  )
}

export default TextConfirmationSection
