import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'

import AddCheckPopup from '../AddCheckPopupFlow/AddCheckPopup'
import Button from '../../common/Button/Button'
import ChecksStatusFilter from './ChecksStausFilter'
import DashboardSectionContainer from '../../common/DashboardSectionContainer/DashboardSectionContainer'
import DividerLine from '../../common/DividerLine/DividerLine'
import React from 'react'
import Spinner from '../../common/Spinner/Spinner'
import { observer } from 'mobx-react-lite'
import plusIcon from '../../../assets/icons/plus.svg'
import styled from '@emotion/styled'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useSearchParams } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`
const DividerWrapper = styled.div`
  height: 2em;
`

export interface Summary {
  totalChecks: number
  totalUp: number
  totalDown: number
  totalPaused: number
}
const AHCSummary: React.FC = () => {
  const gaEventTracker = useAnalyticsEventTracker(gaPageConstants.AHC_SUMMARY)
  const { ahcSummaryStore, ahcReportsStore } = useStoreContext()
  const [query, setQuery] = useSearchParams()
  const [addCheckPopupOpen, setAddCheckPopupOpen] = React.useState(false)
  const store = useStoreContext()
  const getLabels = (key: string) => {
    switch (key) {
      case 'totalChecks':
        return {
          countLabel: 'Total Checks',
          actionLabel: 'View Checks'
        }
      case 'totalUp':
        return {
          countLabel: 'Total Up',
          actionLabel: 'View Ups'
        }
      case 'totalDown':
        return {
          countLabel: 'Total Down',
          actionLabel: 'View Downs'
        }
      case 'totalPaused':
        return {
          countLabel: 'Total Paused',
          actionLabel: 'View Paused'
        }
    }
  }

  const handleStatusFilterClick = (key: string) => {
    query.delete('page')
    query.delete('previous-check-status')
    switch (key) {
      case 'totalUp':
        gaEventTracker(gaEventConstants.TOTAL_UP_CHECKS)
        query.append('previous-check-status', 'Up')
        return setQuery(query)

      case 'totalDown':
        gaEventTracker(gaEventConstants.TOTAL_DOWN_CHECKS)
        query.append('previous-check-status', 'Down')
        return setQuery(query)

      case 'totalPaused':
        query.append('previous-check-status', 'Paused')
        return setQuery(query)
      default:
        gaEventTracker(gaEventConstants.TOTAL_CHECKS)
        return setQuery(query)
    }
  }

  const getIsActive = (key: string) => {
    if (
      (query.get('previous-check-status') === 'Up' && key === 'totalUp') ||
      (query.get('previous-check-status') === 'Down' && key === 'totalDown') ||
      (query.get('previous-check-status') === 'Paused' &&
        key === 'totalPaused') ||
      (!query.get('previous-check-status') && key === 'totalChecks')
    )
      return true
    return false
  }
  const renderTopRightNavbarElements = () => {
    return (
      <Button
        id='Add Health Check'
        variant='contained'
        startIcon={<img src={plusIcon} />}
        iconSize='12px'
        onClick={() => {
          setAddCheckPopupOpen(true)
          gaEventTracker(gaEventConstants.INITIATE_ADD_CHECK)
        }}
        size='large'
        disabled={
          store.userStore.getNoActiveSubscription() ||
          store.ahcReportsStore.getLoading() ||
          store.ahcLogsStore.getLoading()
        }
      >
        Add Check
      </Button>
    )
  }

  return (
    <DashboardSectionContainer
      headerText='Summary'
      paddingTop={'1.875em'}
      rightHeaderItem={renderTopRightNavbarElements()}
    >
      <AddCheckPopup
        handleClose={() => setAddCheckPopupOpen(false)}
        open={addCheckPopupOpen}
      />
      <Container>
        {ahcSummaryStore.getLoading() ? (
          <Spinner />
        ) : (
          Object.keys(ahcSummaryStore.getSummary()).map((key, index) => (
            <React.Fragment key={key}>
              <ChecksStatusFilter
                {...getLabels(key)}
                count={ahcSummaryStore.getSummary()[key as keyof Summary]}
                disabled={
                  ahcSummaryStore.getSummary()[key as keyof Summary] === 0 ||
                  ahcSummaryStore.getLoading() ||
                  ahcReportsStore.getLoading()
                }
                onClick={() => handleStatusFilterClick(key)}
                active={getIsActive(key)}
              />
              {index !== Object.keys(key).length - 1 && (
                <DividerWrapper>
                  <DividerLine variant='vertical' />
                </DividerWrapper>
              )}
            </React.Fragment>
          ))
        )}
      </Container>
    </DashboardSectionContainer>
  )
}

export default observer(AHCSummary)
