export default {
  //Grouped API

  GROUPED_API: 'Grouped APIs',
  ALL_API: 'All APIs',
  URL_TEMPLATE: 'URL Template',
  HTTP_METHOD: 'HTTP Method',
  API_COUNT: 'API Count',
  AVERAGE_RESPONSE_TIME: 'Average Response Time',
  LAST_CALL_DATE: 'Last Called',
  STATUS_CODE_GRAPH: 'Status Code Graph',
  END_POINT: 'End Point',
  END_POINTS: 'Endpoint',
  CLIENT_VERSION: 'Client Version',
  RESPONSE_STATUS: '  Response Status',
  START_TIME: ' Start Time',
  END_TIME: ' End Time',
  RESPONSE_TIME: 'Response Time',
  OVER_VIEW: 'Overview',
  STATUS_MESSAGE: 'Status Message',
  USER_AGENT: 'User Agent',
  IP: 'IP Address',
  HTTP_VERSION: 'HTTP Version',
  ORIGINAL_URL: 'Original URL',
  BASE_URL: 'Base URL',
  PATH: 'Path',
  ATTRIBUTE: 'Attribute',
  VALUE: 'Value',
  REQUEST_RESPONSE: 'Request & Response',
  REQUEST_BODY: 'Request Body',
  RESPONSE_BODY: 'Response Body',
  ATTRIBUTES: 'Attributes',
  HEADERS: 'Headers',
  QUERY: 'Query',
  PARAMS: 'Params'
}
