import { Bookmark, BookmarkFilled, ChevronRight } from '@carbon/icons-react'
import { DDSTooltip, DDSTypography } from 'den-design-system'
import {
  ExceptionStatusTypes,
  SeverityTypes
} from '../../../../api/exception/Exception.types'
import React, { useEffect, useRef, useState } from 'react'
import { createSearchParams, useNavigate, useParams } from 'react-router-dom'
import { generateRandomId, paginate } from '../../../../utils/CommonUtils'
import {
  getAllParamsAsObjectsFromUrl,
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../../utils/UrlUtils'
import {
  getExceptionFilters,
  getExceptionLogsOfGroup,
  updateAnException,
  updateSelectedExceptions
} from '../../../../api/exception/Exception.service'
import { isNull, isUndefined, size } from 'lodash'

import ConfirmationPopup from '../../../../components/common/Exceptions/ConfirmationPopup'
import Filter from '../../../../components/Filter/Filter'
import { ProjectType } from '../../../../enum/ProjectType.enum'
import SeverityDropDown from '../../../../components/common/Exceptions/SeverityDropdown'
import Spinner from '../../../../components/common/Spinner/Spinner'
import StatusDropDown from '../../../../components/common/Exceptions/StatusDropdown'
import StringConstants from '../../../../constants/StringConstants'
import Table from '../../../../components/common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../../components/New/Tooltip/Tooltip'
import emptyAvatar from '../../../../assets/images/EmptyAvatar.svg'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../global/newPallete'
import routeConstants from '../../../../constants/RouteConstants'
import { select } from 'react-cookies'
import { severityColorMap } from '../../../../constants/ExceptionsPageConstants'
import { timeFormats } from '../../../../enum/TIME'
import { timeZone } from '../../../../utils/DateTimeUtils'
import { toast } from 'react-toastify'
import { useSearchParams } from 'react-router-dom'
import { useStoreContext } from '../../../../store/StoreContext'

const GroupedExceptionsLogs: React.FC = () => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const [currentGroupId, setCurrentGroupId] = useState<any>('')
  const [preference, setPreference] = React.useState<string>('')
  const [tableData, setTableData] = useState<any>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const tableRef = useRef<HTMLDivElement>(null)
  const [tableHeight, setTableHeight] = useState<number | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const store = useStoreContext()
  const [selectedRows, setSelectedRows] = useState<any>([])
  const { id } = useParams()
  const navigate = useNavigate()

  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY
  const [filterOptions, setFilterOptions] = useState<any>([])
  const fetchAllExceptionLogs = () => {
    setLoading(true)
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    getExceptionLogsOfGroup({
      exceptionGroupId: id,
      ...allQueryParams,
      size: StringConstants.DEFAULT_TABLE_SIZE
    }).then((response: any) => {
      if (response.data.length > 0 || store.filterStore.getFiltersApplied()) {
        store.filterStore.setFilterDisabled(false)
      } else {
        store.filterStore.setFilterDisabled(true)
      }
      setTableData(response.data)
      setTotalCount(response.totalCount)
      store.exceptionStore.setIsRefreshed(false)
      if (response.filters) {
        {
          store.filterStore.setFilters(response.filters)
        }
      } else {
        store.filterStore.setFilters({})
      }

      setLoading(false)
    })
  }

  useEffect(() => {
    if (tableRef.current) {
      setTableHeight(tableRef.current.clientHeight)
    }
  }, [tableData, loading])

  const fetchExceptionFilters = () => {
    getExceptionFilters()
      .then((response: any) => {
        const statusFilters = response.data[2]
        statusFilters.values = statusFilters.values.filter(
          (value: any) =>
            value.displayText !== ExceptionStatusTypes.SNOOZED &&
            value.displayText !== ExceptionStatusTypes.IGNORED
        )
        response.data[2] = statusFilters
        setFilterOptions(response.data)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: isFullStackProject
            ? ToastMessageConstants.EXCEPTIONS.EXCEPTION_FILTERS.ERROR
            : ToastMessageConstants.ERROR.ERROR_FILTERS.ERROR
        })
      })
  }
  useEffect(() => {
    fetchAllExceptionLogs()
    fetchExceptionFilters()
  }, [store.exceptionStore.getIsRefreshed(), currentPage, location.search])

  const routeExceptionLog = (id: number, type: string) => {
    store.exceptionStore.setId(id.toString())
    store.exceptionStore.setType(type)

    const route = replaceUrl(
      routeConstants.ERROR_DETAILS,
      store.scopeStore.getScope()['organization-id'] as string,
      store.scopeStore.getScope()['project-id'] as string,
      store.scopeStore.getScope()['environment-id'] as string
    ).replace(':id', String(id))
    const queryParams = {
      tab: searchParams.get('tab') as string,
      innerTab: searchParams.get('innerTab') as string
    }
    navigate({ pathname: route, search: `?${createSearchParams(queryParams)}` })
  }

  const updateSeverity = () => {
    if (selectedRows.length === 0) {
      const isSameSeverity =
        tableData.filter((data: any) => data.id === currentGroupId)[0]
          .severity === preference
      setLoading(true)
      if (!isSameSeverity) {
        updateAnException({
          severity: preference as SeverityTypes,
          $exceptionLogId: currentGroupId
        })
          .then(() => {
            fetchAllExceptionLogs()
            ToastNotification({
              type: 'success',
              message: isFullStackProject
                ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.SUCCESS
                : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.SUCCESS
            })
            setCurrentGroupId('')
            setPreference('')
            setModalOpen(false)
          })
          .catch(() => {
            ToastNotification({
              type: 'error',
              message: isFullStackProject
                ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.ERROR
                : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.ERROR
            })
            setCurrentGroupId('')
            setPreference('')
            setModalOpen(false)
            setLoading(false)
          })
      }
    } else {
      const exceptionIds: string[] = []
      selectedRows.forEach((exception: any) => exceptionIds.push(exception.id))
      setLoading(true)

      updateSelectedExceptions({
        ids: exceptionIds,
        severity: preference
      })
        .then(() => {
          ToastNotification({
            type: 'success',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.SUCCESS
              : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.SUCCESS
          })
          setCurrentGroupId('')
          setPreference('')
          setModalOpen(false)
          setSelectedRows([])
          fetchAllExceptionLogs()
        })

        .catch((err: any) => {
          ToastNotification({
            type: 'error',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.ERROR
              : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.ERROR
          })
          setModalOpen(false)
          setLoading(false)
        })
    }
  }
  const updateStatus = (id: string, status: ExceptionStatusTypes) => {
    if (selectedRows.length === 0) {
      const isSameStatus =
        tableData.filter((data: any) => data.id === id)[0].exceptionStatus ===
        status
      if (!isSameStatus) {
        setLoading(true)

        updateAnException({
          exceptionStatus: status,
          $exceptionLogId: id
        })
          .then(() => {
            ToastNotification({
              type: 'success',
              message: isFullStackProject
                ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.SUCCESS
                : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.SUCCESS
            })
            fetchAllExceptionLogs()
          })
          .catch(() => {
            ToastNotification({
              type: 'error',
              message: isFullStackProject
                ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.ERROR
                : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.ERROR
            })
          })
      }
    } else {
      const exceptionIds: string[] = []

      selectedRows.forEach((exception: any) => exceptionIds.push(exception.id))

      setLoading(true)

      updateSelectedExceptions({
        ids: exceptionIds,
        exceptionStatus: status
      })
        .then(() => {
          ToastNotification({
            type: 'success',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.SUCCESS
              : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.SUCCESS
          })

          fetchAllExceptionLogs()
        })
        .catch(() => {
          ToastNotification({
            type: 'error',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.ERROR
              : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.ERROR
          })
          setLoading(false)
        })
    }
  }
  const updateBookmark = (id: string, bookmarkState: boolean) => {
    setLoading(true)
    if (selectedRows.length === 0) {
      const isSameBookmarkState =
        tableData.filter((data: any) => data.id === id)[0].isBookmarked ===
        bookmarkState
      if (!isSameBookmarkState) {
        updateAnException({
          isBookmarked: bookmarkState,
          $exceptionLogId: id
        })
          .then(() => {
            ToastNotification({
              type: 'success',
              message: isFullStackProject
                ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.SUCCESS
                : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.SUCCESS
            })
            fetchAllExceptionLogs()
          })
          .catch(() => {
            ToastNotification({
              type: 'error',
              message: isFullStackProject
                ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.ERROR
                : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.ERROR
            })
          })
      }
    } else {
      const exceptionIds: string[] = []
      selectedRows.forEach((exception: any) => exceptionIds.push(exception.id))
      setLoading(true)
      updateSelectedExceptions({
        ids: exceptionIds,
        isBookmarked: bookmarkState
      })
        .then(() => {
          ToastNotification({
            type: 'success',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.SUCCESS
              : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.SUCCESS
          })

          fetchAllExceptionLogs()
        })
        .catch((err: any) => {
          ToastNotification({
            type: 'error',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.ERROR
              : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.ERROR
          })
        })
    }
  }

  const LogTableColumns = [
    {
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => {
        return (
          <Tooltip
            id={generateRandomId()}
            label={fullItem['isBookmarked'] ? 'Bookmarked' : 'Bookmark'}
          >
            <div
              onClick={() =>
                updateBookmark(fullItem['id'], !fullItem['isBookmarked'])
              }
              className='cursor-pointer'
            >
              {fullItem['isBookmarked'] ? (
                <BookmarkFilled color={pallete.colors.primary2} />
              ) : (
                <Bookmark color={pallete.colors.stroke4} />
              )}
            </div>
          </Tooltip>
        )
      }
    },

    {
      heading: (
        <DDSTypography.Paragraph
          variant='semiBold'
          size='caption'
          className='text-center truncate flex justify-center '
          color={pallete.colors.textDark6}
        >
          {isFullStackProject ? 'Exception Message' : 'Error Message'}
        </DDSTypography.Paragraph>
      ),
      dataLabel: 'exceptionMessage',
      render: (text: any) => (
        <DDSTooltip
          id={generateRandomId()}
          label={text}
          position='top'
          shape='roundedRectangle'
          theme='light'
          className={`border override-tooltip-arrow `}
          style={{
            borderColor: pallete.colors.stroke2,
            zIndex: 99999,
            maxWidth: '90%'
          }}
        >
          <div className='flex justify-center'>
            <DDSTypography.Paragraph
              variant='medium'
              size='para'
              className='text-center truncate  w-[140px] ml-[10%] cursor-default'
            >
              {text}
            </DDSTypography.Paragraph>
          </div>
        </DDSTooltip>
      )
    },
    {
      heading: (
        <DDSTypography.Paragraph
          variant='semiBold'
          size='caption'
          className='text-center whitespace-nowrap flex justify-center'
          color={pallete.colors.textDark6}
        >
          Client Version
        </DDSTypography.Paragraph>
      ),
      dataLabel: 'clientVersion',
      render: (text: any) => (
        <div className='flex justify-center'>
          <DDSTypography.Paragraph
            variant='medium'
            size='para'
            className='text-center whitespace-nowrap'
          >
            {text ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      heading: (
        <DDSTypography.Paragraph
          variant='semiBold'
          size='caption'
          className='text-center whitespace-nowrap flex justify-center'
          color={pallete.colors.textDark6}
        >
          Reported At
        </DDSTypography.Paragraph>
      ),
      dataLabel: 'reportedAt',
      render: (text: any) => (
        <DDSTooltip
          id={`${text}`}
          label={
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark4}
            >
              {timeZone(
                store.userStore.getUserPreference().timeZone,
                text,
                timeFormats.fullMonthDateYearTime
              )}
            </DDSTypography.Paragraph>
          }
          shape='roundedRectangle'
          position='top'
          className={`override-tooltip-arrow ${
            typeof text === 'string' && text.length > 24 ? text : '!hidden'
          }`}
          style={{
            backgroundColor: pallete.colors.surface1,
            zIndex: 999999
          }}
        >
          <div className='flex justify-center text-center row items-center'>
            <DDSTypography.Paragraph
              variant='medium'
              size='para'
              className='tooltip-container sm:block overflow-hidden truncate max-w-[160px]'
            >
              {timeZone(
                store.userStore.getUserPreference().timeZone,
                text,
                timeFormats.fullMonthDateYearTime
              )}
            </DDSTypography.Paragraph>
          </div>
        </DDSTooltip>
      )
    },
    {
      heading: (
        <DDSTypography.Paragraph
          variant='semiBold'
          size='caption'
          className='text-center whitespace-nowrap flex justify-center'
          color={pallete.colors.textDark6}
        >
          Assigned To
        </DDSTypography.Paragraph>
      ),
      dataLabel: 'assignedTo',
      render: (text: any, fullItem: any) => (
        <div className='flex flex-row justify-center w-full'>
          {fullItem.assignedTo?.user?.avatar ? (
            <DDSTooltip
              label={
                fullItem.assignedTo?.user?.firstName +
                ' ' +
                fullItem.assignedTo?.user?.lastName
              }
              position='top'
              shape='roundedRectangle'
              id={generateRandomId()}
              theme='light'
              className={`border override-tooltip-arrow `}
              style={{ borderColor: pallete.colors.stroke2 }}
            >
              <div className='flex justify-center w-full'>
                <img
                  src={fullItem.assignedTo?.user?.avatar}
                  className='rounded-full h-[24px] w-[24px]'
                />
              </div>
            </DDSTooltip>
          ) : (
            <img src={emptyAvatar} className='rounded-full h-[24px] w-[24px]' />
          )}
        </div>
      )
    },
    {
      heading: (
        <DDSTypography.Paragraph
          variant='semiBold'
          size='caption'
          className='relative text-center whitespace-nowrap flex justify-center'
          color={pallete.colors.textDark6}
        >
          Severity
        </DDSTypography.Paragraph>
      ),
      dataLabel: 'severity',
      render: (text: any, fullItem: any) => {
        const setSeverity = (severity: SeverityTypes) => {
          setCurrentGroupId(fullItem['id'])
          setPreference(severity)
          if (selectedRows.length === 0 && fullItem['severity'] !== severity) {
            setModalOpen(true)
          } else if (selectedRows.length > 0) {
            setModalOpen(true)
          }
        }
        return (
          <div className=' flex justify-center items-center '>
            <SeverityDropDown
              label={text}
              onSelect={setSeverity}
              disabled={fullItem.exceptionStatus === 'Resolved'}
              disableNoSubscription={store.userStore.getNoActiveSubscription()}
            />
          </div>
        )
      }
    },
    {
      heading: (
        <DDSTypography.Paragraph
          variant='semiBold'
          size='caption'
          className='text-center whitespace-nowrap flex justify-center'
          color={pallete.colors.textDark6}
        >
          Status
        </DDSTypography.Paragraph>
      ),
      dataLabel: 'exceptionStatus',
      render: (text: any, fullItem: any) => {
        const setStatus = (status: ExceptionStatusTypes) => {
          updateStatus(fullItem['id'], status)
        }
        return (
          <div className='flex justify-center items-center '>
            <StatusDropDown label={text} onSelect={setStatus} />
          </div>
        )
      }
    },
    {
      columnHeaderStyle: { width: '20px' },
      columnDataStyle: { width: '20px' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div>
          <ChevronRight
            size={16}
            color={pallete.colors.primary2}
            className='cursor-pointer'
            onClick={() => {
              routeExceptionLog(fullItem['id'], fullItem['exceptionType'])
            }}
          />
        </div>
      )
    }
  ]
  if (loading) return <Spinner />

  return (
    <div className='my-[24px]' ref={tableRef}>
      {/* TODO Not needed in this release*/}
      {/* <div className='mb-[24px] flex flex-row custom-filter'>
        <Filter
          options={filterOptions}
          disabled={false}
          primaryText='Add Filters'
        />
      </div> */}
      <Table
        data={tableData}
        columns={LogTableColumns}
        totalCount={totalCount}
        rowsPerPage={10}
        currentPage={currentPage}
        onPageChange={(page) => {
          const allQueryParams = getAllQueryParamsAsObjectFromUrl(
            location.search
          )
          setSearchParams({ ...allQueryParams, page: page.toString() })
          setCurrentPage(page)
          setLoading(true)
        }}
        selectable
        tableStyle={{ overflowX: 'scroll' }}
        loading={loading}
        selectionType='checkbox'
        onSelect={(row: any) => {
          setSelectedRows(row)
        }}
      />
      <ConfirmationPopup
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title='Severity Type'
        loading={loading}
        primaryButtonDisabled={loading}
        secondaryButtonDisabled={loading}
        primaryButtonClick={() => updateSeverity()}
        secondaryButtonClick={() => {
          setModalOpen(false)
        }}
        primaryButtonText='Yes'
        secondaryButtonText='No'
        content={
          <div className='flex flex-col w-full gap-y-[24px] items-center justify-center '>
            <div>
              <DDSTypography.Title
                variant='semiBold'
                type='h4'
                className='text-center flex flex-row whitespace-nowrap gap-x-[4px]'
              >
                {isFullStackProject
                  ? 'This will change severity type for selected exceptions to'
                  : 'This will change severity type for selected errors to'}
                <DDSTypography.Title
                  type='h4'
                  variant='semiBold'
                  color={severityColorMap[preference]}
                >
                  {preference}
                </DDSTypography.Title>
              </DDSTypography.Title>
            </div>
          </div>
        }
      />
    </div>
  )
}
export default observer(GroupedExceptionsLogs)
