import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import {
  OrganizationType,
  OrganizationTypeDisplayNames
} from '../../../enum/OrganizationType.enum'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'
import {
  getValidationError,
  getValidationStatus
} from '../../../utils/ValidationUtils'
import { isEmpty, isUndefined } from 'lodash'

import { Button } from '../../../components/New/Button/Button'
import CryptoJS from 'crypto-js'
import { DDSTypography } from 'den-design-system'
import DocumentHeader from '../../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import Input from '../../../components/New/Input/Input'
import Joi from 'joi'
import LeftContent from '../../../components/SignIn/LeftContent'
import ReCaptcha from '../../../components/common/ReCaptcha/ReCaptcha'
import RegexConstants from '../../../constants/RegexConstants'
import SalesforceIcon from '../../../components/common/Icon/SalesforceIcon'
import Select from '../../../components/New/Select/Select'
import SigninSignupStringConstants from '../../../constants/SigninSignupStringConstants'
import StringConstants from '../../../constants/StringConstants'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../components/New/Tooltip/Tooltip'
import { UrlForSalesforceLogin } from '../../../utils/UrlUtils'
import ValidationConstants from '../../../constants/ValidationConstants'
import { getIconForProjectType } from '../../../utils/OverviewUtils'
import { isJSON } from '../../../utils/CommonUtils/StringUtils'
import { joinUserToRoom } from '../../../gateway'
import { observer } from 'mobx-react-lite'
import palette from '../../../global/pallete'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { signupUser } from '../../../api/user/User.service'
import { updatePartnerInvite } from '../../../api/partner/Partner.service'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'

interface RefObject {
  resetCaptcha: () => void
}
interface SignupForm {
  firstName: string
  lastName: string
  email: string
  password: string
  organizationName: string
  organizationType: string
  isTermsAccepted: boolean
  isNewsletterSubscribed: boolean
}
const OrganizationTypeDropdownOptions = [
  {
    id: 1,
    label: (
      <div id='hybrid-new-tooltip' title={OrganizationTypeDisplayNames.HYBRID}>
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          className='truncate max-[285px]'
          color={pallete.colors.textDark4}
        >
          {OrganizationTypeDisplayNames.HYBRID}
        </DDSTypography.Paragraph>
      </div>
    ),
    value: OrganizationType.HYBRID
  },
  {
    id: 2,
    label: (
      <DDSTypography.Paragraph
        size='para'
        variant='medium'
        color={pallete.colors.textDark4}
      >
        {OrganizationTypeDisplayNames.SALESFORCE_ECOSYSTEM_ONLY}
      </DDSTypography.Paragraph>
    ),
    value: OrganizationType.SALESFORCE_ECOSYSTEM_ONLY
  },
  {
    id: 3,
    label: (
      <DDSTypography.Paragraph
        size='para'
        variant='medium'
        color={pallete.colors.textDark4}
      >
        {OrganizationTypeDisplayNames.FULL_STACK_ONLY}
      </DDSTypography.Paragraph>
    ),
    value: OrganizationType.FULL_STACK_ONLY
  }
]

const SignUp: React.FC<{ showRecaptcha?: boolean }> = ({ showRecaptcha }) => {
  let Email = ''
  let firstname = ''
  let lastname = ''
  let organization = ''

  const navigate = useNavigate()
  const gaEventTracker = useAnalyticsEventTracker(gaPageConstants.SIGN_IN)
  const [validationErrorResult, setValidationErrorResult] = useState<any[]>([])
  const [errors, setErrors] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true)
  const [captcha, setCaptcha] = useState<string | null>('')
  const [clearedPasswordOnError, setClearedPasswordOnError] =
    useState<boolean>(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    const token = await executeRecaptcha('signUp')
    setCaptcha(token)
  }, [executeRecaptcha])

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])
  const [searchParams, setSearchParams] = useSearchParams()

  const [fieldStates, setFieldStates] = useState({
    email: { isFocused: false, isBlurred: false },
    password: { isFocused: false, isBlurred: false },
    firstName: { isFocused: false, isBlurred: false },
    lastName: { isFocused: false, isBlurred: false },
    organizationName: { isFocused: false, isBlurred: false },
    organizationType: { isFocused: false, isBlurred: false },
    captcha: { isFocused: false, isBlurred: false }
  })

  const decryptedQueryParamString = (searchParams.get('t') as string)
    ? CryptoJS.AES.decrypt(
        searchParams.get('t') as string,
        process.env.REACT_APP_ENCRYPTION_KEY
          ? process.env.REACT_APP_ENCRYPTION_KEY
          : 'secret_key'
      ).toString(CryptoJS.enc.Utf8)
    : ''
  const decryptedQueryParam = isJSON(decryptedQueryParamString)
    ? JSON.parse(decryptedQueryParamString)
    : {}
  const token = decryptedQueryParam['invite-token']
  const partner = (searchParams.get('partner') as string)
    ? CryptoJS.AES.decrypt(
        searchParams.get('partner') as string,
        process.env.REACT_APP_ENCRYPTION_KEY
          ? process.env.REACT_APP_ENCRYPTION_KEY
          : 'secret_key'
      ).toString(CryptoJS.enc.Utf8)
    : ''
  const decryptedQueryPartner = isJSON(partner) ? JSON.parse(partner) : {}
  const partnerToken = searchParams.get('token') as string

  const organizationIsPartner = searchParams.get('organizationIsPartner')
    ? true
    : false

  if (organizationIsPartner) {
    Email = decryptedQueryPartner['email']
    firstname = decryptedQueryPartner['firstName']
    lastname = decryptedQueryPartner['lastName']
    organization = decryptedQueryPartner['organizationName']
  }

  const defaultSignupForm = {
    firstName: (organizationIsPartner && firstname) || '',
    lastName: (organizationIsPartner && lastname) || '',
    email:
      (decryptedQueryParam['email'] as string) ||
      (organizationIsPartner && Email) ||
      '',
    password: '',
    organizationName:
      (decryptedQueryParam['organization-name'] as string) ||
      (organizationIsPartner && organization) ||
      '',
    organizationType:
      (decryptedQueryParam['organization-type'] as string) ||
      (organizationIsPartner && OrganizationType.SALESFORCE_ECOSYSTEM_ONLY) ||
      '',
    isTermsAccepted: true,
    isNewsletterSubscribed: false
  }
  const [signupForm, setSignupForm] = useState<SignupForm>(defaultSignupForm)
  const schema = Joi.object({
    firstName: Joi.string()
      .pattern(RegexConstants.NAME_REGEX)
      .required()
      .messages({
        'string.empty': ValidationConstants.SIGN_IN.FIRST_NAME,
        'string.pattern.base': ValidationConstants.GENERIC.INVALID_NAME_REGEX
      }),
    lastName: Joi.string()
      .pattern(RegexConstants.NAME_REGEX)
      .required()
      .messages({
        'string.empty': ValidationConstants.SIGN_IN.LAST_NAME,
        'string.pattern.base': ValidationConstants.GENERIC.INVALID_NAME_REGEX
      }),
    password: Joi.string()
      .pattern(RegexConstants.PASSWORD)
      .min(8)
      .required()
      .messages({
        'string.min': ValidationConstants.PASSWORD.INVALID_PASSWORD_REGEX,
        'string.empty': ValidationConstants.SIGN_IN.PASSWORD,
        'string.pattern.base':
          ValidationConstants.PASSWORD.INVALID_PASSWORD_REGEX
      }),
    email: !token
      ? Joi.string().pattern(RegexConstants.EMAIL_REGEX).required().messages({
          'string.pattern.base': ValidationConstants.SIGN_IN.VALID_EMAIL,
          'string.empty': ValidationConstants.SIGN_IN.EMAIL,
          'string.email': ValidationConstants.SIGN_IN.VALID_EMAIL
        })
      : {},
    organizationName: !token
      ? Joi.string().pattern(RegexConstants.NAME_REGEX).required().messages({
          'string.empty': ValidationConstants.SIGN_IN.ORGANIZATION_NAME,
          'string.pattern.base': ValidationConstants.GENERIC.INVALID_NAME_REGEX
        })
      : {},
    organizationType: !token
      ? Joi.string().required().messages({
          'string.empty': ValidationConstants.SIGN_IN.ORGANIZATION_TYPE,
          'any.required': ValidationConstants.SIGN_IN.ORGANIZATION_TYPE
        })
      : {},
    isTermsAccepted: Joi.boolean().invalid(false).messages({
      'string.empty': ValidationConstants.INVALID_CAPTCHA
    }),
    captcha: Joi.string().min(1).required().messages({
      'string.empty': ValidationConstants.INVALID_CAPTCHA,
      'any.required': ValidationConstants.INVALID_CAPTCHA
    })
  })
  useEffect(() => {
    const validateForm = () => {
      const { error } = schema.validate(
        {
          firstName: signupForm.firstName,
          lastName: signupForm.lastName,
          email: signupForm.email,
          password: signupForm.password,
          organizationName: signupForm.organizationName,
          organizationType: signupForm.organizationType,
          isTermsAccepted: signupForm.isTermsAccepted,
          captcha
        },
        {
          abortEarly: false
        }
      )

      if (!error) {
        setErrors({})
        setButtonDisabled(false)
      } else {
        setButtonDisabled(true)

        const formattedErrors = error.details.reduce((acc: any, error: any) => {
          if (error.path.length > 0) {
            acc[error.path[0]] = error.message
          }
          return acc
        }, {})

        console.log(formattedErrors)
        setErrors(formattedErrors)
      }
    }

    validateForm()
  }, [signupForm, captcha, token])
  const handleFormChange = (field: string, value: any) => {
    setSignupForm({
      ...signupForm,
      [field]: value
    })
  }
  const resetPassword = () => {
    setSignupForm({
      ...signupForm,
      password: ''
    })
  }
  const handleFieldInteraction = (field: string, eventType: string) => {
    setFieldStates((prev: any) => ({
      ...prev,
      [field]: {
        ...prev[field],
        isFocused: eventType === 'focus',
        isBlurred: eventType === 'blur'
      }
    }))
    if (field === 'password') setClearedPasswordOnError(false)
  }
  const handleFormSubmit = () => {
    setLoading(true)
    let fieldsToBeValidated: any = {
      firstName: signupForm.firstName,
      lastName: signupForm.lastName,
      password: signupForm.password,
      isTermsAccepted: signupForm.isTermsAccepted,
      captcha
    }

    if (!token) {
      fieldsToBeValidated = {
        ...fieldsToBeValidated,
        email: signupForm.email,
        organizationName: signupForm.organizationName,
        organizationType: signupForm.organizationType
      }
    }

    const validationResult = schema.validate(fieldsToBeValidated, {
      abortEarly: false
    })

    let userDetails: any = {
      firstName: signupForm.firstName,
      lastName: signupForm.lastName,
      email: signupForm.email,
      password: signupForm.password,
      inviteToken: token as string,
      organizationName: signupForm.organizationName,
      organizationType: signupForm.organizationType,
      captcha,
      organizationIsPartner,
      //TODO: will need add user metadata based on query
      isTermsAccepted: signupForm.isTermsAccepted,
      isNewsletterSubscribed: signupForm.isNewsletterSubscribed
    }

    if (!userDetails.inviteToken) {
      userDetails = {
        ...userDetails,
        chargebeePlanId: (searchParams.get('plan-id') ||
          process.env
            .REACT_APP_DEFAULT_SUBSCRIPTION_PLAN_CHARGEBEE_ID) as string
      }
    }

    if (isUndefined(validationResult.error)) {
      setValidationErrorResult([])
      signupUser(userDetails)
        .then((userDetail: any) => {
          if (partnerToken) {
            updatePartnerInvite({
              $token: partnerToken,
              signedIn: false
            }).catch((err) => {
              ToastNotification({
                type: 'error',
                message: ToastMessageConstants.PARTNER.PARTNER_INVITE.ERROR
              })
            })
          }
          ToastNotification({
            type: 'success',
            message: ToastMessageConstants.USER.SIGN_UP.SUCCESS
          })
          setLoading(false)
          setSignupForm(defaultSignupForm)
          joinUserToRoom(userDetail.data.id)
          navigate(routeConstants.SIGN_IN)
          gaEventTracker(gaEventConstants.CREATE_ACCOUNT)
        })
        .catch((err) => {
          setLoading(false)
          setClearedPasswordOnError(true)
          resetPassword()
          ToastNotification({
            type: 'error',
            message: err
          })
          handleReCaptchaVerify()
        })
    } else {
      setValidationErrorResult(validationResult.error.details)
      setSignupForm(defaultSignupForm)
      handleReCaptchaVerify()
      setLoading(false)
    }
  }

  return (
    <>
      <DocumentHeader header={HeaderConstants.SIGN_UP} />
      <div className='flex justify-center items-start'>
        <LeftContent signIn={false} />
        <div className='w-[60%] bg-white flex flex-col justify-center items-center px-[144px] py-[44px] gap-[24px] self-stretch h-[100vh]'>
          <div className='flex w-[602px] flex-col items-center gap-[32px] self-stretch'>
            <div className='flex flex-col items-center gap-[4px]'>
              <div className='flex items-center'>
                <DDSTypography.Title
                  type='h1'
                  style={{ fontSize: '32px', lineHeight: '40px' }}
                  color={pallete.colors.textDark3}
                >
                  {SigninSignupStringConstants.SIGNUP_TITLE}
                </DDSTypography.Title>
              </div>
            </div>
            <div className='flex flex-col items-start gap-[24px] self-stretch'>
              <div className='flex justify-center items-start gap-[24px] self-stretch'>
                <div className='relative'>
                  <Input
                    id='first-name-input'
                    name={SigninSignupStringConstants.FIRST_NAME}
                    label={SigninSignupStringConstants.FIRST_NAME}
                    value={signupForm.firstName}
                    required
                    labelPosition='top'
                    disabled={loading || organizationIsPartner}
                    onFocus={() => handleFieldInteraction('firstName', 'focus')}
                    onBlur={() => handleFieldInteraction('firstName', 'blur')}
                    maxLength={StringConstants.NAME_FIELD_MAX_LENGTH}
                    onChange={(e) =>
                      handleFormChange('firstName', e.target.value)
                    }
                  />
                  {!fieldStates.firstName.isBlurred ||
                    (!fieldStates.firstName.isFocused && (
                      <DDSTypography.Paragraph
                        size='caption'
                        variant='regular'
                        className='absolute -bottom-5'
                        color={pallete.colors.warningRed2}
                      >
                        {errors.firstName}
                      </DDSTypography.Paragraph>
                    ))}
                </div>
                <div className='relative'>
                  <Input
                    id='last-name-input'
                    name={SigninSignupStringConstants.LAST_NAME}
                    label={SigninSignupStringConstants.LAST_NAME}
                    value={signupForm.lastName}
                    labelPosition='top'
                    required
                    disabled={loading || organizationIsPartner}
                    maxLength={StringConstants.NAME_FIELD_MAX_LENGTH}
                    onFocus={() => handleFieldInteraction('lastName', 'focus')}
                    onBlur={() => handleFieldInteraction('lastName', 'blur')}
                    onChange={(e) =>
                      handleFormChange('lastName', e.target.value)
                    }
                  />
                  {!fieldStates.lastName.isBlurred ||
                    (!fieldStates.lastName.isFocused && (
                      <DDSTypography.Paragraph
                        size='caption'
                        variant='regular'
                        className='absolute -bottom-5'
                        color={pallete.colors.warningRed2}
                      >
                        {errors.lastName}
                      </DDSTypography.Paragraph>
                    ))}
                </div>
              </div>
              <div className='relative'>
                <Input
                  id='email-input'
                  name={SigninSignupStringConstants.EMAIL}
                  label={SigninSignupStringConstants.EMAIL}
                  value={signupForm.email}
                  required
                  type='email'
                  labelPosition='top'
                  width='602px'
                  onFocus={() => handleFieldInteraction('email', 'focus')}
                  onBlur={() => handleFieldInteraction('email', 'blur')}
                  disabled={!isEmpty(token) || loading || organizationIsPartner}
                  onChange={(e) => handleFormChange('email', e.target.value)}
                />
                {!fieldStates.email.isBlurred ||
                  (!fieldStates.email.isFocused && (
                    <DDSTypography.Paragraph
                      size='caption'
                      variant='regular'
                      className='absolute -bottom-5'
                      color={pallete.colors.warningRed2}
                    >
                      {errors.email}
                    </DDSTypography.Paragraph>
                  ))}
              </div>
              <div className='relative'>
                <Input
                  type='password'
                  id='password-input'
                  name={SigninSignupStringConstants.PASSWORD}
                  label={SigninSignupStringConstants.PASSWORD}
                  value={signupForm.password}
                  required
                  labelPosition='top'
                  width='602px'
                  autoComplete={'on'}
                  disabled={loading}
                  onFocus={() => handleFieldInteraction('password', 'focus')}
                  onBlur={() => handleFieldInteraction('password', 'blur')}
                  onChange={(e) => handleFormChange('password', e.target.value)}
                />
                {!fieldStates.password.isBlurred ||
                  (!clearedPasswordOnError &&
                    !fieldStates.password.isFocused && (
                      <DDSTypography.Paragraph
                        size='caption'
                        variant='regular'
                        className='absolute -bottom-5'
                        color={pallete.colors.warningRed2}
                      >
                        {errors.password}
                      </DDSTypography.Paragraph>
                    ))}
              </div>
              <div className='flex justify-center items-start gap-[24px] self-stretch'>
                <div className='relative'>
                  <Input
                    id='org-input'
                    name={SigninSignupStringConstants.ORGANIZATION}
                    label={SigninSignupStringConstants.ORGANIZATION}
                    value={signupForm.organizationName}
                    required
                    labelPosition='top'
                    disabled={
                      !isEmpty(token) || loading || organizationIsPartner
                    }
                    onFocus={() =>
                      handleFieldInteraction('organizationName', 'focus')
                    }
                    onBlur={() =>
                      handleFieldInteraction('organizationName', 'blur')
                    }
                    maxLength={StringConstants.NAME_FIELD_MAX_LENGTH}
                    onChange={(e) =>
                      handleFormChange('organizationName', e.target.value)
                    }
                  />
                  {!fieldStates.organizationName.isBlurred ||
                    (!fieldStates.organizationName.isFocused && (
                      <DDSTypography.Paragraph
                        size='caption'
                        variant='regular'
                        className='absolute -bottom-5'
                        color={pallete.colors.warningRed2}
                      >
                        {errors.organizationName}
                      </DDSTypography.Paragraph>
                    ))}
                </div>
                <div
                  className='relative override-select-validation'
                  onFocus={() =>
                    handleFieldInteraction('organizationType', 'focus')
                  }
                  onBlur={() =>
                    handleFieldInteraction('organizationType', 'blur')
                  }
                >
                  <Select
                    id='monitor-select'
                    variant='top'
                    disabled={
                      !isEmpty(token) || loading || organizationIsPartner
                    }
                    label={
                      <div className='flex'>
                        <div>
                          <DDSTypography.Paragraph
                            size='para'
                            variant='medium'
                            className='whitespace-nowrap'
                            color={pallete.colors.textDark3}
                          >
                            {SigninSignupStringConstants.ORGANIZATION_TYPE}
                          </DDSTypography.Paragraph>
                        </div>
                        <span
                          style={{
                            color: pallete.colors.warningRed2,
                            marginTop: '-5px'
                          }}
                        >
                          *
                        </span>
                      </div>
                    }
                    value={signupForm.organizationType}
                    searchable={false}
                    name={'Organization type'}
                    options={OrganizationTypeDropdownOptions}
                    onSelect={(value) =>
                      handleFormChange('organizationType', value)
                    }
                  />
                  {!fieldStates.organizationType.isBlurred ||
                    (!fieldStates.organizationType.isFocused && (
                      <DDSTypography.Paragraph
                        size='caption'
                        variant='regular'
                        className='absolute -bottom-5'
                        color={pallete.colors.warningRed2}
                      >
                        {errors.organizationType}
                      </DDSTypography.Paragraph>
                    ))}
                </div>
              </div>
            </div>
            <div className='flex flex-col items-center gap-[8px] self-stretch'>
              <div className='override-login-btn-width'>
                <Button
                  label={SigninSignupStringConstants.GET_STARTED}
                  size='large'
                  id='login-btn'
                  type='filled'
                  loading={loading}
                  disabled={buttonDisabled}
                  onClick={handleFormSubmit}
                />
              </div>
              <div>
                <DDSTypography.Paragraph
                  size='para'
                  variant='medium'
                  color={pallete.colors.textDark3}
                >
                  {SigninSignupStringConstants.OR}
                </DDSTypography.Paragraph>
              </div>
              <div className='override-login-btn-width'>
                <Button
                  id='login-sf-btn'
                  type='outline'
                  size='large'
                  disabled={loading}
                  startIcon={getIconForProjectType('SALESFORCE_ONLY')}
                  label={SigninSignupStringConstants.SIGNUP_SF}
                  onClick={() => {
                    window.location.href = UrlForSalesforceLogin(false, token)
                  }}
                />
              </div>
            </div>
            <div className='flex flex-col items-start gap-[12px] self-stretch'>
              <div className='flex items-center gap-[4px]'>
                <div>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark6}
                  >
                    {SigninSignupStringConstants.ALREADY_HAVE_ACC}
                  </DDSTypography.Paragraph>
                </div>
                <div
                  className={`${loading && 'pointer-events-none'} `}
                  onClick={() => navigate(routeConstants.SIGN_IN)}
                >
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark2}
                    className='underline cursor-pointer'
                  >
                    {SigninSignupStringConstants.LOGIN}
                  </DDSTypography.Paragraph>
                </div>
              </div>
              <div className='flex items-center gap-[4px]'>
                <div>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark6}
                  >
                    {SigninSignupStringConstants.ACKNOWLEDGE}
                  </DDSTypography.Paragraph>
                </div>
                <div className={`${loading && 'pointer-events-none'} `}>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark2}
                    className='underline cursor-pointer'
                  >
                    <Link
                      to={
                        StringConstants.VIGIL_WEBSITE_LINK +
                        StringConstants.URL_PATH_TERMS_OF_SERVICE
                      }
                      onClick={() =>
                        gaEventTracker(gaEventConstants.TERMS_OF_SERVICE)
                      }
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{ color: palette.colors.primary }}
                    >
                      {SigninSignupStringConstants.TERMS_OF_SERVICE}
                    </Link>
                  </DDSTypography.Paragraph>
                </div>
                <div>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark6}
                  >
                    {SigninSignupStringConstants.AND}
                  </DDSTypography.Paragraph>
                </div>
                <div className={`${loading && 'pointer-events-none'} `}>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark2}
                    className='underline cursor-pointer'
                  >
                    <Link
                      to={
                        StringConstants.VIGIL_WEBSITE_LINK +
                        StringConstants.URL_PATH_PRIVACY_POLICY
                      }
                      onClick={() =>
                        gaEventTracker(gaEventConstants.PRIVACY_POLICY)
                      }
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{ color: palette.colors.primary }}
                    >
                      {SigninSignupStringConstants.PRIVACY_POLICY}
                    </Link>
                  </DDSTypography.Paragraph>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(SignUp)
