import React, { useEffect } from 'react'

import DashboardSectionContainer from '../../../components/common/DashboardSectionContainer/DashboardSectionContainer'
import Table from '../../../components/common/Table/Table'
import successIcon from '../../../assets/icons/successIcon.svg'
import { useStoreContext } from '../../../store/StoreContext'

const RolesAndActions = () => {
  const rolesAndActionsData = [
    {
      actions: 'Can see/edit billing information and subscription details',
      //billing: true,
      owner: true,
    },
    {
      actions: 'Can see/edit legal and compliance details',
      //billing: true,
      owner: true,
    },
    {
      actions: 'Can view and act on issues, such as assigning/resolving/etc.',
      member: true,
      owner: true,
    },
    {
      actions: 'Can change Project Settings',
      owner: true,
    },
    {
      actions: 'Can add/remove projects',
      owner: true,
    },
    {
      actions: 'Can add/remove/change members',
      owner: true,
    },
    {
      actions: 'Can add/remove/change project members',
      owner: true,
    },
    {
      actions: 'Can change Organization Settings',
      owner: true,
    },
  ]
  const tableColumns = [
    {
      label: 'actions',
      title: 'Actions',

      customStyles: {
        width: 300,
      },
      render: (item: any) => <div style={{ textAlign: 'center' }}>{item}</div>,
    },
    // {
    //   title: 'Billing',
    //   label: 'billing',

    //   customStyles: {
    //     width: 200,
    //   },
    //   render: (item: any) => (item ? <img src={successIcon} /> : <></>),
    // },
    {
      title: 'Member',
      label: 'member',

      customStyles: {
        width: 300,
      },
      render: (item: any) => (item ? <img src={successIcon} /> : <></>),
    },
    {
      title: 'Owner',
      label: 'owner',
      customStyles: {
        width: 300,
      },
      render: (item: any) => (item ? <img src={successIcon} /> : <></>),
    },
  ]
  const { uiStore } = useStoreContext()
  useEffect(() => {
    uiStore.setGlobalLoader(false)
  }, [])
  return (
    <DashboardSectionContainer headerText='Roles And Actions'>
      <Table
        columns={tableColumns}
        data={rolesAndActionsData}
        showPagination={false}
        currentPage={1}
      />
    </DashboardSectionContainer>
  )
}
export default RolesAndActions
