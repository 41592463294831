export default {
  LOGIN_TITLE: 'Login to your account',
  SIGNUP_TITLE: 'Create your account',
  LOGIN_ONBOARD: 'Onboard and Manage your Applications',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  FORGOT_PASSWORD: 'Forgot Password?',
  LOGIN: 'Login',
  GET_STARTED: 'Get Started',
  OR: 'or',
  LOGIN_SF: 'Sign in with Salesforce',
  SIGNUP_SF: 'Sign up with Salesforce',
  ACKNOWLEDGE: 'You acknowledge that you read, and agree to our',
  TERMS_OF_SERVICE: 'Terms of Service',
  AND: '&',
  PRIVACY_POLICY: 'Privacy Policy',
  FIRST_TIME: 'First time here?',
  ALREADY_HAVE_ACC: 'Already have an account?',
  CREATE_ACCOUNT: 'Create Account',
  WELCOME_BACK: 'Welcome Back!',
  SIGNUP_FREE: 'Sign up for Free',
  NO_CREDIT_CARD: 'No credit card required',
  MONITOR_MANAGE: 'Monitor & Manage',
  FULL_STACK_APPS: 'Full-stack Applications',
  SALESFORCE_ORGS: 'Salesforce Orgs',
  MULESOFT_APPS: 'Mulesoft Applications',
  BOTH: 'Both',
  ACCESS_EVERYTHING: 'Access to Everything',
  EXCEPTIONS: 'Exceptions',
  JOBS: 'Jobs',
  AUDIT: 'Audit',
  UPTIME: 'Uptime',
  ORGANIZATION: 'Organization',
  ORGANIZATION_TYPE: 'Organization Type'
}
