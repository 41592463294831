import React, { useEffect, useState } from 'react'
import {
  AiOutlineCheckCircle as VerifiedIcon,
  AiOutlineWarning as WarningIcon
} from 'react-icons/ai'
import { gaEventConstants, gaPageConstants } from '../../constants/GAConstants'
import {
  getValidationError,
  getValidationStatus
} from '../../utils/ValidationUtils'
import {
  resendVerification,
  updateUser,
  uploadUserAvatar
} from '../../api/user/User.service'

import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import Button from '../../components/common/Button/Button'
import DashboardSectionContainer from '../../components/common/DashboardSectionContainer/DashboardSectionContainer'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import { FileUploader } from 'react-drag-drop-files'
import { HeaderConstants } from '../../constants/HeaderConstants'
import InputField from '../../components/common/InputField/InputField'
import { IoIosArrowForward } from 'react-icons/io'
import Joi from 'joi'
import { OnBoardingType } from '../../api/user/User.types'
import RegexConstants from '../../../src/constants/RegexConstants'
import SecureComponent from '../../components/Layout/SecureComponent'
import { SettingBottomBar } from '../../components/common/SettingBottomBar/SettingBottomBar'
import SettingsBar from '../../components/common/DashboardTopNavbar/bottomBars/Settings'
import Spinner from '../../components/common/Spinner/Spinner'
import StringConstants from '../../constants/StringConstants'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../../components/common/DDS/Toast/Toast'
import { Tooltip } from '@mui/material'
import { TypographyDDS } from '../../components/common/Typography/TypographyDDS'
import UpdatePassword from '../../components/AccountSettings/PopUps/UpdatePassword.PopUp'
import ValidationConstants from '../../constants/ValidationConstants'
import breakpoints from '../../global/breakpoints'
import { isUndefined } from 'lodash'
import palette from '../../global/pallete'
import pictureIcon from '../../assets/icons/draganddropicon.svg'
import styled from '@emotion/styled'
import { useAnalyticsEventTracker } from '../../utils/GAUtils'
import { useObserver } from 'mobx-react-lite'
import { useStoreContext } from '../../store/StoreContext'

// interface AccountSettingsProps {
//   accountVerified?: boolean
//   profileName?: string
//   profile?: string //Link
//   userEmail?: string
// }

interface ParaProps {
  padding?: string
  lineHeight?: number
  fontSize?: string
  margin?: string
  maxWidth?: string
}

interface ItemContainerProps {
  width?: string
  height?: string
  padding?: string
  margin?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.md}) {
    font-size: 0.9rem;
  }
`
const IndividualSettingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em 0.3125em;

  @media screen and (max-width: ${breakpoints.sm}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
const SettingsLeft = styled.div`
  width: 40%;
`
const ProfileName = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
  }
`
const SettingsRight = styled.div`
  max-width: 352px;
  width: 100%;
  padding-right: 0em;
  direction: ltr;

  @media screen and (max-width: ${breakpoints.lg}) {
    padding-right: 0;
  }
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${breakpoints.sm}) {
    margin-bottom: 1.5em;
  }
`

const ItemContainer = styled.div<ItemContainerProps>`
  max-width: ${(props) => (props.width ? props.width : '22.875em')};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`

const IconTextContianer = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 1em;
  margin: 2em 0;
`
const ContainedImg = styled.img`
  object-fit: fill;
  width: 100%;
  height: 100%;
  border-radius: 8px;
`
const MarginDiv = styled.div`
  margin: 2.5em 0;
`
const ContainerInput = styled.div<{ $loading?: boolean }>`
  cursor: ${(props) => (props.$loading ? 'not-allowed' : 'pointer')};
  max-width: 23em;
  padding: 4em 0;
  border: 2px dashed ${palette.colors.borderColor};

  input[type='file'] {
    display: none;
  }
  .custom-file-upload {
    display: inline-block;
    padding: 0.375em 3.125em;
    margin-left: 1.25em;
  }
  label {
    max-width: 23em;
    padding: 4em 0.75em;
    border-radius: 12px;
    text-align: right;
    pointer-events: ${(props) => (props.$loading ? 'none' : '')};
    cursor: inherit;
  }
  border-radius: 12px;
`

// const DeleteButtonWrapper = styled.div`
//   max-width: 321.3px;
// `
const FirstName = styled.div`
  width: 23em;
  margin: 0 5px 0 0;
  @media (max-width: ${breakpoints.lg}) {
    margin: 0 0 5px 0;
  }
`
const LastName = styled.div`
  width: 23em;
  margin: 0 0 0 5px;
  @media (max-width: ${breakpoints.lg}) {
    margin: 5px 0 0 0;
  }
`
const ImageWrapper = styled.div`
  height: 106px;
  width: 106px;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid ${palette.colors.borderColor};
`

const AccountSettings = () => {
  // const [deletePopUp, setDeletePopUp] = useState(false)
  const [updatePasswordPopUp, setUpdatePasswordPopUp] = useState(false)
  // const [userName, setUserName] = useState('profileName')
  const [loading, setLoading] = useState(false)
  const fileTypes = ['JPG', 'PNG', 'JPEG']
  const [resendVerificationMailLoader, setResendVerificationMailLoader] =
    useState<any>(false)
  const [avatar, setAvatar] = useState<any>()
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const storeContext = useStoreContext()
  const referenceForFirstName = React.useRef<any>(null)
  const referenceForLastName = React.useRef<any>(null)
  const referenceForEmailInput = React.useRef<any>(null)
  const [validationErrorResult, setValidationErrorResult] = useState<any[]>([])
  const [firstNameValidation, setFirstNameValidation] = useState<any[]>([])
  const [lastNameValidation, setLastNameValidation] = useState<any[]>([])
  const [emailFocus, setEmailFocus] = useState<boolean>(false)
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.ACCOUNT_SETTINGS
  )
  const [imageLoading, setImageLoading] = useState<boolean>(false)
  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.SETTINGS
    )
  }, [])
  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
  })
  const schemaForFirstName = Joi.object({
    firstName: Joi.string()
      .pattern(RegexConstants.NAME_REGEX)
      .required()
      .messages({
        'string.empty': ValidationConstants.GENERIC.REQUIRED_VALUE,
        'string.pattern.base': ValidationConstants.GENERIC.INVALID_NAME_REGEX
      })
  })
  const schemaForLastName = Joi.object({
    lastName: Joi.string()
      .pattern(RegexConstants.NAME_REGEX)
      .required()
      .messages({
        'string.empty': ValidationConstants.GENERIC.REQUIRED_VALUE,
        'string.pattern.base': ValidationConstants.GENERIC.INVALID_NAME_REGEX
      })
  })
  const updateEmail = (email: string) => {
    const validationResult = schema.validate({ email })
    if (isUndefined(validationResult.error)) {
      setValidationErrorResult([])
      referenceForEmailInput.current?.startLoading()
      setLoading(true)
      updateUser({
        email: email
      })
        .then((response: any) => {
          storeContext.userStore.setEmail(response.data.email)
          storeContext.userStore.setIsVerified(response.data.isVerified)
          referenceForEmailInput.current?.stopLoading()
          ToastNotification({
            type: 'success',
            message: ToastMessageConstants.USER.UPDATED_EMAIL.SUCCESS
          })
          setLoading(false)
          setEmailFocus(false)
        })
        .catch((err: string) => {
          referenceForEmailInput.current?.stopLoading()
          setLoading(false)
          setEmail(storeContext.userStore.getEmail())
          setEmailFocus(false)

          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.USER.UPDATED_EMAIL.ERROR
          })
        })
    } else {
      setValidationErrorResult(validationResult.error.details)
      setLoading(false)
    }
  }

  const handleSubmit = (file: any) => {
    if (storeContext.uiStore.getGlobalLoader() || loading) return
    setLoading(true)
    storeContext.uiStore.setGlobalLoader(true)
    setImageLoading(true)
    const formData = new FormData()
    formData.append('file', file)
    uploadUserAvatar(formData)
      .then((response: any) => {
        storeContext.userStore.setAvatar(response.data.avatar)
        setAvatar(response.data.avatar)
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.SETTINGS.UPLOAD_USER_AVATAR.SUCCESS
        })
        setLoading(false)
        setImageLoading(false)
        storeContext.uiStore.setGlobalLoader(false)
      })
      .catch((err: string) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SETTINGS.UPLOAD_USER_AVATAR.ERROR
        })
        setLoading(false)
        setImageLoading(false)
        storeContext.uiStore.setGlobalLoader(false)
      })
  }
  const updateFirstName = (firstName: string) => {
    const validationResult = schemaForFirstName.validate({ firstName })
    if (isUndefined(validationResult.error)) {
      setFirstNameValidation([])
      referenceForFirstName.current?.startLoading()
      setLoading(true)
      updateUser({
        firstName: firstName
      })
        .then((response: any) => {
          storeContext.userStore.setFirstName(response.data.firstName)
          setFirstName(response.data.firstName)
          storeContext.userStore.setAvatar(response.data.avatar)
          setAvatar(response.data.avatar)
          ToastNotification({
            type: 'success',
            message: ToastMessageConstants.USER.UPDATED_USERNAME.SUCCESS
          })
          referenceForFirstName.current?.stopLoading()
          setLoading(false)
        })
        .catch((err: string) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.USER.UPDATED_USERNAME.ERROR
          })
          setFirstName(storeContext.userStore.getFirstName())
          setLastName(storeContext.userStore.getLastName())
          referenceForFirstName.current?.stopLoading()
          setLoading(false)
        })
    } else {
      setFirstNameValidation(validationResult.error.details)
      setLoading(false)
    }
  }
  const updateLastName = (lastName: string) => {
    const validationResult = schemaForLastName.validate({ lastName })
    if (isUndefined(validationResult.error)) {
      setLastNameValidation([])
      referenceForLastName.current?.startLoading()
      setLoading(true)
      updateUser({
        lastName: lastName
      })
        .then((response: any) => {
          storeContext.userStore.setLastName(response.data.lastName)
          setLastName(response.data.lastName)
          ToastNotification({
            type: 'success',
            message: ToastMessageConstants.USER.UPDATED_USERNAME.SUCCESS
          })
          referenceForLastName.current?.stopLoading()
          setLoading(false)
        })
        .catch((err: string) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.USER.UPDATED_USERNAME.ERROR
          })
          setFirstName(storeContext.userStore.getFirstName())
          setLastName(storeContext.userStore.getLastName())
          referenceForLastName.current?.stopLoading()
          setLoading(false)
        })
    } else {
      setLastNameValidation(validationResult.error.details)
      setLoading(false)
    }
  }

  const sendVerificationMail = () => {
    setResendVerificationMailLoader(true)
    setLoading(true)
    resendVerification()
      .then(() => {
        setResendVerificationMailLoader(false)
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.USER.RESEND_VERIFICATION.SUCCESS
        })
        setLoading(false)
      })
      .catch((err: string) => {
        setResendVerificationMailLoader(false)
        setLoading(false)
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.USER.RESEND_VERIFICATION.ERROR
        })
      })
  }

  const updatePasswordButton = () => {
    return (
      <SettingsRight>
        <Button
          variant='contained'
          endIcon={<IoIosArrowForward />}
          fullWidth
          background={palette.colors.primary}
          iconSize='16px'
          onClick={() => {
            gaEventTracker(gaEventConstants.UPDATE_PASSWORD)
            setUpdatePasswordPopUp(true)
          }}
          disabled={
            loading ||
            storeContext.userStore.getNoActiveSubscription() ||
            storeContext.userStore.getOnBoardedVia() === OnBoardingType.SSO
              ? true
              : false
          }
        >
          Update Password
        </Button>
      </SettingsRight>
    )
  }

  React.useEffect(() => {
    setLoading(true)
    setFirstName(storeContext.userStore.getFirstName())
    setLastName(storeContext.userStore.getLastName())
    setAvatar(storeContext.userStore.getAvatar())
    setEmail(storeContext.userStore.getEmail())
    setLoading(false)
    storeContext.uiStore.setGlobalLoader(false)
  }, [storeContext.userStore.getAvatar()])
  React.useEffect(() => {
    storeContext.uiStore.setGlobalLoader(false)
  }, [storeContext.scopeStore.getScope()])

  React.useEffect(() => {
    storeContext.uiStore.setGlobalLoader(false)
  }, [
    storeContext.scopeStore.getEnvironmentId(),
    storeContext.uiStore.getGlobalLoader()
  ])

  return useObserver(() => (
    <Container>
      <>
        {/* <DashboardTopNavbar
          variant='accountSettings'
          // noOrg={storeContext.userStore.getOrganizations().length === 0}
          showBottomBar
        /> */}
        <DocumentHeader header={HeaderConstants.ACCOUNT_SETTINGS} />
        <SettingBottomBar>
          <SettingsBar showButtons={false} />
        </SettingBottomBar>

        {/* Account Settings */}
        <DashboardSectionContainer headerText='Account Settings'>
          <>
            <IndividualSettingContainer>
              <SettingsLeft>
                <TypographyDDS.Title
                  type='h5'
                  variant='bold'
                  style={{ marginBottom: '16px', maxWidth: '450px' }}
                >
                  Profile Name
                </TypographyDDS.Title>
                <TypographyDDS.Title
                  type='h5'
                  variant='medium'
                  color='textDark3'
                  style={{ maxWidth: '450px' }}
                >
                  Edit your profile name
                </TypographyDDS.Title>
              </SettingsLeft>
              <SettingsRight>
                <ItemContainer>
                  <ProfileName>
                    <FirstName>
                      <InputField
                        label='First Name'
                        value={firstName}
                        onChange={(e: any) => {
                          setFirstName(e.target.value)
                          setFirstNameValidation([])
                        }}
                        onBlur={(e: any) => {
                          if (
                            storeContext.userStore.getFirstName() !==
                            e.target.value
                          ) {
                            updateFirstName(e.target.value)
                          }
                        }}
                        fullWidth
                        ref={referenceForFirstName}
                        maxLength={StringConstants.NAME_FIELD_MAX_LENGTH}
                        disabled={
                          loading ||
                          storeContext.userStore.getNoActiveSubscription()
                        }
                        helperText={
                          firstName !== storeContext.userStore.getFirstName()
                            ? getValidationError(
                                firstNameValidation,
                                'firstName'
                              )
                            : ''
                        }
                        error={
                          firstName !== storeContext.userStore.getFirstName() &&
                          getValidationStatus(firstNameValidation, 'firstName')
                        }
                      />
                    </FirstName>
                    <LastName>
                      <InputField
                        label='Last Name'
                        value={lastName}
                        onChange={(e: any) => {
                          setLastName(e.target.value)
                          setLastNameValidation([])
                        }}
                        onBlur={(e: any) => {
                          if (
                            storeContext.userStore.getLastName() !==
                            e.target.value
                          ) {
                            updateLastName(e.target.value)
                          }
                        }}
                        fullWidth
                        ref={referenceForLastName}
                        maxLength={StringConstants.NAME_FIELD_MAX_LENGTH}
                        disabled={
                          loading ||
                          storeContext.userStore.getNoActiveSubscription()
                        }
                        helperText={
                          lastName !== storeContext.userStore.getLastName()
                            ? getValidationError(lastNameValidation, 'lastName')
                            : ''
                        }
                        error={
                          lastName !== storeContext.userStore.getLastName() &&
                          getValidationStatus(lastNameValidation, 'lastName')
                        }
                      />
                    </LastName>
                  </ProfileName>
                </ItemContainer>
              </SettingsRight>
            </IndividualSettingContainer>

            <IndividualSettingContainer>
              <SettingsLeft>
                <TypographyDDS.Title
                  type='h5'
                  variant='bold'
                  style={{ marginBottom: '16px', maxWidth: '450px' }}
                >
                  Display Picture
                </TypographyDDS.Title>
                <TypographyDDS.Title
                  type='h5'
                  variant='medium'
                  color='textDark3'
                  style={{ maxWidth: '450px' }}
                >
                  Assign an avatar for your profile name
                </TypographyDDS.Title>
                <ImageContainer>
                  <ItemContainer margin='1.5em 0 0'>
                    <ImageWrapper>
                      {imageLoading ? (
                        <Spinner noMarginTop />
                      ) : (
                        <ContainedImg
                          src={avatar}
                          height={'106px'}
                          width={'106px'}
                        />
                      )}
                    </ImageWrapper>
                  </ItemContainer>

                  <>
                    <TypographyDDS.Paragraph
                      size='caption'
                      color='textDark2'
                      style={{
                        marginLeft: '24px',
                        fontWeight: `${palette.fontWeight.light}`
                      }}
                    >
                      <i>
                        Note:
                        <br />
                        <li>
                          JPG, JPEG and PNG are allowed <br />
                        </li>
                        <li>Maximum upload file size - 1MB</li>
                      </i>
                    </TypographyDDS.Paragraph>
                  </>
                </ImageContainer>
              </SettingsLeft>
              <SettingsRight>
                {/* Input type = file styling */}

                <FileUploader
                  handleChange={handleSubmit}
                  name='file'
                  types={fileTypes}
                  maxSize={1}
                  onTypeError={(error: any) =>
                    ToastNotification({
                      type: 'error',
                      message:
                        ToastMessageConstants.SETTINGS.UPLOAD_USER_AVATAR.ERROR
                    })
                  }
                  disabled={
                    loading ||
                    storeContext.uiStore.getGlobalLoader() ||
                    storeContext.userStore.getNoActiveSubscription()
                  }
                  onSizeError={(error: any) =>
                    ToastNotification({
                      type: 'error',
                      message:
                        ToastMessageConstants.SETTINGS.UPLOAD_USER_AVATAR.ERROR
                    })
                  }
                  hoverTitle=' '
                >
                  <ContainerInput>
                    <label htmlFor='file-upload' className='custom-file-upload'>
                      <i className='fa fa-cloud-upload'></i>
                      <div
                        style={{
                          display: 'flex',
                          gridGap: '10px',
                          width: 'max-content'
                        }}
                      >
                        <img
                          src={pictureIcon}
                          style={{
                            fontSize: '20px',
                            color: `${palette.colors.textDark2}`
                          }}
                        />{' '}
                        <TypographyDDS.Paragraph
                          size='para'
                          color='textDark3'
                          style={{ maxWidth: '450px', alignSelf: 'center' }}
                        >
                          Drag and drop or
                          <span
                            style={{
                              fontWeight: palette.fontWeight.bold,
                              color: palette.colors.primary
                            }}
                          >
                            {' '}
                            browse files
                          </span>
                        </TypographyDDS.Paragraph>
                      </div>
                    </label>{' '}
                  </ContainerInput>
                </FileUploader>
              </SettingsRight>
            </IndividualSettingContainer>
          </>
        </DashboardSectionContainer>

        {/* Security Settings Section */}
        <DashboardSectionContainer headerText='Security Settings' paddingBottom>
          <>
            <div>
              <IndividualSettingContainer>
                <SettingsLeft>
                  <TypographyDDS.Title
                    type='h5'
                    variant='bold'
                    style={{ marginBottom: '16px', maxWidth: '450px' }}
                  >
                    Update Email Address
                  </TypographyDDS.Title>
                  <TypographyDDS.Title
                    type='h5'
                    variant='medium'
                    color='textDark3'
                    style={{ maxWidth: '450px' }}
                  >
                    Update and verify your email
                  </TypographyDDS.Title>
                  {!storeContext.userStore.getIsVerified() && (
                    <IconTextContianer>
                      <WarningIcon
                        style={{
                          fontSize: '1.5em',
                          color: `${palette.colors.borderRed}`
                        }}
                      />{' '}
                      <TypographyDDS.Title
                        type='h5'
                        variant='bold'
                        style={{ maxWidth: '450px' }}
                      >
                        Your account is not verified
                      </TypographyDDS.Title>
                    </IconTextContianer>
                  )}
                  {storeContext.userStore.getIsVerified() && (
                    <IconTextContianer
                      style={{
                        color: `${palette.colors.primary}`
                      }}
                    >
                      <VerifiedIcon
                        style={{
                          fontSize: '1.5em',
                          color: `${palette.colors.primary}`
                        }}
                      />{' '}
                      <TypographyDDS.Title
                        type='h5'
                        variant='bold'
                        style={{ maxWidth: '450px' }}
                      >
                        Your account is verified
                      </TypographyDDS.Title>
                    </IconTextContianer>
                  )}
                </SettingsLeft>
                <SettingsRight>
                  <ItemContainer>
                    <InputField
                      label='Email Address'
                      placeholder='Enter Your Email'
                      value={email}
                      onChange={(e: any) => {
                        setEmail(e.target.value)
                      }}
                      onBlur={(e: any) => {
                        if (
                          e.target.value !== storeContext.userStore.getEmail()
                        ) {
                          updateEmail(e.target.value)
                        } else {
                          setEmailFocus(false)
                          setValidationErrorResult([])
                        }
                      }}
                      fullWidth
                      ref={referenceForEmailInput}
                      disabled={
                        loading ||
                        storeContext.userStore.getNoActiveSubscription()
                      }
                      helperText={
                        getValidationStatus(validationErrorResult, 'email')
                          ? ValidationConstants.UPDATE_EMAIL.EMAIL
                          : ''
                      }
                      error={getValidationStatus(
                        validationErrorResult,
                        'email'
                      )}
                      onFocus={() => {
                        setEmailFocus(true)
                      }}
                    />
                  </ItemContainer>
                  <ItemContainer margin='1em 0 0'>
                    <Button
                      variant='contained'
                      endIcon={<IoIosArrowForward />}
                      background={palette.colors.primary}
                      disabled={
                        storeContext.userStore.getNoActiveSubscription() ||
                        storeContext.userStore.getIsVerified() ||
                        loading ||
                        emailFocus
                      }
                      loading={resendVerificationMailLoader}
                      onClick={() => {
                        gaEventTracker(gaEventConstants.RESEND_VERIFICATION)
                        if (!storeContext.userStore.getIsVerified()) {
                          sendVerificationMail()
                        }
                      }}
                      fullWidth
                    >
                      Resend Verification
                    </Button>
                  </ItemContainer>
                </SettingsRight>
              </IndividualSettingContainer>

              {/* Update Password */}

              <IndividualSettingContainer>
                <SettingsLeft>
                  <TypographyDDS.Title
                    type='h5'
                    variant='bold'
                    style={{ marginBottom: '16px', maxWidth: '450px' }}
                  >
                    Update Password
                  </TypographyDDS.Title>
                  <TypographyDDS.Title
                    type='h5'
                    variant='medium'
                    color='textDark3'
                    style={{ maxWidth: '450px' }}
                  >
                    Enter and confirm your new password
                  </TypographyDDS.Title>
                </SettingsLeft>
                {storeContext.userStore.getOnBoardedVia() ===
                OnBoardingType.SSO ? (
                  <Tooltip
                    style={{ height: '0px' }}
                    title={'Please update password on the salesforce platform'}
                    placement='bottom'
                    PopperProps={{
                      popperOptions: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [-10, 40]
                            }
                          }
                        ]
                      }
                    }}
                  >
                    {updatePasswordButton()}
                  </Tooltip>
                ) : (
                  updatePasswordButton()
                )}
              </IndividualSettingContainer>
            </div>
          </>
        </DashboardSectionContainer>
        {/* Account Deletion Section */}
        {/* <DashboardSectionContainer headerText='Account Deletion' paddingBottom>
          <div style={{ padding: '2em 0' }}>
            <ParaDark>Delete your account</ParaDark>
            <ParaLight maxWidth='37.5em'>
              Deleting account doesn’t mean your organizations will be deleted.
              Your account will be removed and ownerships will be automatically
              transfered to next owner.
            </ParaLight>
            <Button
              background={`linear-gradient(270deg, ${palette.colors.error} 0%, ${palette.colors.error} 100%)`}
              startIcon={
                <img
                  src={DeleteIcon}
                  style={{
                    filter: palette.colors.whiteSvgFilter,
                  }}
                />
              }
              height='3.5em'
              variant='contained'
              size='large'
              width='22.875em'
              margin='1em 0 0'
              onClick={() => setDeletePopUp(true)}
            >
              Delete Account
            </Button>
          </div>
        </DashboardSectionContainer> */}

        {/* POPUP SECTION */}

        {/* Update Password */}
        <UpdatePassword
          open={updatePasswordPopUp}
          handleClose={() => {
            setUpdatePasswordPopUp(false)
          }}
        />

        {/* PopUp Delete Account */}
        {/* <DeleteConfirmationPopUp
            open={deletePopUp}
            handleClose={() => setDeletePopUp(false)}
            handleSubmit={() => {
              console.log('submited')
            }}
            context='Account'
          /> */}
      </>
    </Container>
  ))
}

export default SecureComponent(AccountSettings, AccessRoleConstants.ALL)
