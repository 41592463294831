import { EnvironmentType } from '../enum/environment.enum'
import { capitalize } from 'lodash'

/**
 * Util function to get selected organization's subscription
 * @param {any} organizations - user organizations
 * @param {string} selectedOrganizationId - selected organization id
 */
export const getSelectedOrganizationsSubscription = (
  organizations: any,
  selectedOrganizationId: string
) =>
  organizations.find(
    (organization: any) => organization.id === selectedOrganizationId
  ).subscription

/**
 * Util function to get environment labels for organization
 * @param {any} organizations - user organizations
 * @param {string} selectedOrganizationId - selected organization id
 */
export const getEnvironmentLabelsForOrganization = (
  organizations: any,
  selectedOrganizationId: string
) => {
  const environmentLimit = getSelectedOrganizationsSubscription(
    organizations,
    selectedOrganizationId
  ).environment

  const tabLabels = []

  const ProductionEnvironment = {
    id: 3,
    label: capitalize(EnvironmentType.PRODUCTION),
    type: EnvironmentType.PRODUCTION
  }

  const StagingEnvironment = {
    id: 2,
    label: capitalize(EnvironmentType.STAGING),
    type: EnvironmentType.STAGING
  }

  const DevelopmentEnvironment = {
    id: 1,
    label: capitalize(EnvironmentType.DEVELOPMENT),
    type: EnvironmentType.DEVELOPMENT
  }

  switch (true) {
    case environmentLimit >= 3 || environmentLimit === -1:
      tabLabels.push(
        ProductionEnvironment,
        StagingEnvironment,
        DevelopmentEnvironment
      )
      break

    case environmentLimit === 2:
      tabLabels.push(ProductionEnvironment, StagingEnvironment)
      break

    case environmentLimit === 1:
      tabLabels.push(ProductionEnvironment)
      break

    default:
      break
  }

  return tabLabels
}
