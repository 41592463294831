import React, { useState } from 'react'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'

import { FiCopy } from 'react-icons/fi'
import StringConstants from '../../../constants/StringConstants'
import { Tooltip } from '@mui/material'
import copy from 'copy-to-clipboard'
import successIcon from '../../../assets/icons/successIcon.svg'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useStoreContext } from '../../../store/StoreContext'

const CopyOnClick: React.FC<{
  value: string
  label?: string
  color?: string
  event?: boolean
}> = ({ value, color, event }) => {
  const store = useStoreContext()
  const gaEventTracker = useAnalyticsEventTracker(gaPageConstants.COPY_ON_CLICK)
  const [copied, setCopied] = useState(false)
  const handleCopy = () => {
    copy(value)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }
  return copied ? (
    <Tooltip
      title={StringConstants.COPIED_MESSAGE}
      arrow
      placement='top'
      style={{ alignSelf: 'center' }}
    >
      <img
        id='copy'
        src={successIcon}
        style={{
          height: '16px'
        }}
      />
    </Tooltip>
  ) : (
    <Tooltip
      title={StringConstants.COPY_MESSAGE}
      arrow
      placement='top'
      style={{
        alignSelf: 'center'
      }}
    >
      <div>
        <FiCopy
          size='16px'
          onClick={() => {
            event && gaEventTracker(gaEventConstants.COPY_ON_CLICK)
            handleCopy()
          }}
          color={color}
          style={{
            cursor: store.userStore.getNoActiveSubscription()
              ? 'not-allowed'
              : 'pointer',
            pointerEvents: store.userStore.getNoActiveSubscription()
              ? 'none'
              : 'auto'
          }}
        />
      </div>
    </Tooltip>
  )
}

export default CopyOnClick
