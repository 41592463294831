import Button from '../Button/Button'
import DeleteIcon from '../../../assets/icons/trash.svg'
import React from 'react'
import { TypographyDDS } from '../Typography/TypographyDDS'
import { observer } from 'mobx-react-lite'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'
import { useStoreContext } from '../../../store/StoreContext'

interface DeleteDomainProps {
  domainData?: any
  handleCancel: () => void
  handleDelete: (data: any) => void
  loading?: boolean
}

const DeleteDomainContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 45px;
`

const ButtonWrapper = styled.div`
  display: flex;
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
const DeleteDomainCard: React.FC<DeleteDomainProps> = ({
  domainData,
  handleCancel,
  handleDelete,
  loading
}) => {
  const store = useStoreContext()
  return (
    <DeleteDomainContainer>
      <TypographyDDS.Title
        type='h3'
        variant='medium'
        style={{ textAlign: 'center' }}
      >
        The host {domainData.host} will be deleted
      </TypographyDDS.Title>
      <ButtonWrapper>
        <ButtonContainer>
          <Button
            size='large'
            color='error'
            variant='contained'
            loading={loading}
            startIcon={
              <img
                src={DeleteIcon}
                style={{
                  filter: palette.colors.whiteSvgFilter
                }}
              />
            }
            onClick={() => {
              handleDelete(domainData)
            }}
            margin='20px 0px 0px 0px '
            disabled={store.userStore.getNoActiveSubscription()}
          >
            Delete Host
          </Button>
        </ButtonContainer>
        <ButtonContainer>
          <Button
            size='large'
            variant='contained'
            margin='20px 0 0 20px '
            onClick={() => {
              handleCancel()
            }}
            disabled={loading}
          >
            Cancel
          </Button>
        </ButtonContainer>
      </ButtonWrapper>
    </DeleteDomainContainer>
  )
}
export default observer(DeleteDomainCard)
