import { DDSButton, DDSChips, DDSMenu, DDSTypography } from 'den-design-system'
import {
  DocumentMultiple_01,
  Edit,
  OverflowMenuHorizontal,
  OverflowMenuVertical,
  Share,
  TrashCan
} from '@carbon/icons-react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '../../../New/Button/Button'
import React from 'react'
import pallete from '../../../../global/newPallete'
import { replaceUrl } from '../../../../utils/UrlUtils'
import routeConstants from '../../../../constants/RouteConstants'
import { useStoreContext } from '../../../../store/StoreContext'

interface ThreeDotMenuProps {
  disableEdit?: boolean
  onShareClick: () => void
  onDeleteClick: () => void
  onEditClick: () => void
  disableNoSubscription?: boolean
}

const ThreeDotMenu: React.FC<ThreeDotMenuProps> = ({
  disableEdit = false,
  disableNoSubscription = false,
  onShareClick,
  onDeleteClick,
  onEditClick
}) => {
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false)
  const navigate = useNavigate()
  const store = useStoreContext()
  const { incidentId } = useParams()
  const handleShareClick = () => {
    onShareClick()
    setMenuOpen(false)
  }
  const handleDeleteClick = () => {
    onDeleteClick()
    setMenuOpen(false)
  }

  return (
    <div className='relative'>
      <Button
        id='three-dot'
        type='neutral'
        onClick={() => setMenuOpen(!menuOpen)}
        startIcon={
          <OverflowMenuHorizontal color={pallete.colors.neuButtonText} />
        }
        size='small'
      />
      <div className={` flex pt-[8px]`}>
        <DDSMenu
          open={menuOpen}
          setOpen={setMenuOpen}
          shape='roundedRectangle'
          className=' absolute z-50 !min-w-[116px] border-2 shadow-sm right-0'
        >
          <div
            className={`${disableEdit && 'cursor-not-allowed'}  ${disableNoSubscription && 'cursor-not-allowed pointer-events-none opacity-50'}`}
            onClick={disableEdit ? undefined : onEditClick}
          >
            <div
              className={`${disableEdit && 'pointer-events-none opacity-50'} `}
            >
              <DDSChips
                type='soft'
                size='medium'
                label={
                  <DDSTypography.Paragraph
                    size='para'
                    color={pallete.colors.neuButtonText}
                    variant='semiBold'
                  >
                    Edit
                  </DDSTypography.Paragraph>
                }
                shape='rounded'
                className={`flex flex-row gap-[8px] !px-[12px] items-center justify-start !w-auto whitespace-nowrap custom-label cursor-pointer !bg-white`}
                style={{
                  backgroundColor: 'white',
                  color: pallete.colors.neuButtonText
                }}
                startIcon={
                  <Edit
                    color={pallete.colors.textDark10}
                    size={16}
                    className='h-[16px] w-[16px]'
                  />
                }
              />
            </div>
          </div>

          <div onClick={handleShareClick}>
            <DDSChips
              type='soft'
              label={
                <DDSTypography.Paragraph
                  size='para'
                  color={pallete.colors.neuButtonText}
                  variant='semiBold'
                >
                  Share
                </DDSTypography.Paragraph>
              }
              shape='rounded'
              className={`flex flex-row gap-[8px] items-center !justify-start !w-auto  whitespace-nowrap custom-label cursor-pointer !bg-white`}
              style={{
                color: pallete.colors.neuButtonText
              }}
              startIcon={
                <Share
                  color={pallete.colors.neuButtonText}
                  size={16}
                  className='h-[16px] w-[16px]'
                />
              }
            />
          </div>
          <div
            onClick={() => {
              const route = replaceUrl(
                routeConstants.INCIDENT_OVERVIEW,
                store.scopeStore.getScope()['organization-id'] as string,
                store.scopeStore.getScope()['project-id'] as string,
                store.scopeStore.getScope()['environment-id'] as string
              ).replace(':incidentId', incidentId as string)
              navigate(route)
            }}
          >
            <DDSChips
              type='soft'
              label={
                <DDSTypography.Paragraph
                  size='para'
                  variant='semiBold'
                  color={pallete.colors.neuButtonText}
                >
                  Post-mortem
                </DDSTypography.Paragraph>
              }
              shape='rounded'
              className={`flex flex-row gap-[8px] items-center !justify-start !w-auto  whitespace-nowrap custom-label cursor-pointer !bg-white`}
              style={{
                color: pallete.colors.neuButtonText
              }}
              startIcon={
                <DocumentMultiple_01
                  color={pallete.colors.textDark10}
                  size={16}
                  className='h-[16px] w-[16px]'
                />
              }
            />
          </div>
          <div
            className={` ${disableNoSubscription && 'cursor-not-allowed pointer-events-none opacity-50'}`}
            onClick={handleDeleteClick}
          >
            <DDSChips
              type='soft'
              label={
                <DDSTypography.Paragraph
                  size='para'
                  variant='semiBold'
                  color={pallete.colors.warningRed2}
                >
                  Delete
                </DDSTypography.Paragraph>
              }
              shape='rounded'
              className={`flex flex-row gap-[8px] items-center !justify-start !w-auto whitespace-nowrap custom-label cursor-pointer !bg-white`}
              startIcon={
                <TrashCan
                  color={pallete.colors.warningRed2}
                  size={16}
                  className='h-[16px] w-[16px]'
                />
              }
            />
          </div>
        </DDSMenu>
      </div>
    </div>
  )
}

export default ThreeDotMenu
