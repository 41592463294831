import {
  BsChevronDown as DownArrow,
  BsChevronUp as UpArrow
} from 'react-icons/bs'
import { Menu, Tooltip } from '@mui/material'
import React, { useEffect, useImperativeHandle } from 'react'
import { createStyles, makeStyles } from '@material-ui/core'

import LinearProgressBar from '../ProgressBar/ProgressBar'
import { RefObject } from '../InputField/Interface/Interface'
import SearchField from '../InputField/SearchField'
import { TypographyDDS } from '../Typography/TypographyDDS'
import { css } from '@emotion/react'
import { isUndefined } from 'lodash'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'
import { useState } from 'react'

interface CustomDropDownProps extends ContainerProps, DropdownItemProps {
  handleDropdown?: any
  upperChildrenComponents?: React.ReactNode
  lowerChildrenComponents?: React.ReactNode
  children?: React.ReactNode
  title: string | JSX.Element
  dropdownOptions: {
    id: number
    name: string
    secondaryText?: string
    onClickFunction?: () => void
    element?: JSX.Element
  }[]
  dropdownWidth?: string
  UpArrowIcon?: JSX.Element
  DownArrowIcon?: JSX.Element
  isSearchable?: boolean
  disableDropdown?: boolean
  fontWeight?: string
  tooltip?: string
}
interface ContainerProps {
  width?: string
  padding?: string
  borderRadius?: string
  open?: boolean
  dropdownWidth?: string
  dropdownLabel?: string
  backgroundColor?: string
  border?: string
  height?: string //%value will not work
  dropdownHeight?: string
  containerGap?: string
  disabled?: boolean
}

interface DropdownItemProps {
  optionOrientation?: string
}

const Styles = {
  IconStyles: {
    color: `${palette.colors.textDark2}`,
    cursor: 'pointer',
    width: '14px',
    height: '14px'
  },
  TextStyles: {
    fontSize: '14px',
    fontWeight: '500',
    color: `${palette.colors.textDark}`,
    display: 'flex',
    justifyContent: 'center'
  }
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : '200px')};
  grid-gap: ${(props) => (props.containerGap ? props.containerGap : '8px')};
  position: relative;
`

const MainContainer = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: 9fr 1fr;
  align-items: center;
  width: 100%;
  align-items: center;
  text-wrap: anywhere;
  padding: ${(props) => (props.padding ? props.padding : '8px 12px 8px 12px')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '8px'};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : `${palette.colors.white}`};
  border: ${(props) =>
    props.border ? props.border : `1px solid ${palette.colors.borderColor}`};
  height: ${(props) => props.height};
  ${(props) =>
    props.disabled
      ? `pointer-events: none;
  opacity: 0.4;`
      : ''}
`

const DropdownContainer = styled.div<ContainerProps>`
  position: absolute;
  top: calc(100% + 5px);
  z-index: 10;
  min-width: 100%;
  min-height: 100px;
  max-height: 360px;
  overflow-x: auto;

  width: ${(props) => props.dropdownWidth};
  padding: ${(props) => (props.padding ? props.padding : '8px 12px 12px 12px')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '8px'};
  background-color: ${palette.colors.white};
  border: ${(props) =>
    props.border ? props.border : `1px solid ${palette.colors.borderColor}`};
  height: ${(props) => props.height};
  box-shadow: 0px 5px 20px rgba(71, 78, 93, 0.1);
`
const DropdownItemWrapper = styled.div<DropdownItemProps>`
  display: flex;
  flex-direction: ${(props) => props.optionOrientation};
  grid-gap: 8px;
  margin: 8px 0px;

  ${(props) => {
    switch (props.optionOrientation) {
      case 'row':
        return css`
          max-width: 320px;
          overflow-y: hidden;
        `
      case 'column':
        return css`
          max-height: 260px;
          overflow-x: hidden;
        `
    }
  }}
`
const DropdownElement = styled.div<DropdownItemProps>`
  cursor: pointer;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  justify-content: center;
  &:hover {
    background-color: ${palette.colors.borderColor};
  }
`

const CustomDropdown = React.forwardRef<RefObject, CustomDropDownProps>(
  (
    {
      children,
      handleDropdown,
      upperChildrenComponents,
      lowerChildrenComponents,
      title,
      width,
      padding,
      borderRadius,
      UpArrowIcon,
      DownArrowIcon,
      dropdownOptions,
      dropdownWidth,
      dropdownLabel,
      optionOrientation = 'column',
      height,
      dropdownHeight,
      border,
      containerGap,
      isSearchable,
      disabled,
      fontWeight = '500',
      tooltip
    },
    ref
  ) => {
    const [selectedOption, setSelectedOption] = React.useState<any>(title)
    useEffect(() => {
      setSelectedOption(title)
    }, [title])
    const [searchData, setSearchData] = useState('')
    const [loading, setLoading] = useState<boolean>(false)

    useImperativeHandle(ref, () => ({ startLoading, stopLoading }))
    const startLoading = () => {
      setLoading(true)
    }
    const stopLoading = () => {
      setLoading(false)
    }

    const dropdownOptionHandler = (id: number) => {
      !isUndefined(dropdownOptions[id - 1].element)
        ? setSelectedOption(dropdownOptions[id - 1]?.element as JSX.Element)
        : setSelectedOption(dropdownOptions[id - 1].name)
      handleDropdown(dropdownOptions[id - 1].name)
    }
    const [dropdownAnchor, setDropdownAnchor] =
      React.useState<null | HTMLElement>(null)
    const openMenu = Boolean(dropdownAnchor)
    const handleClick = (event: any) => {
      setDropdownAnchor(event.currentTarget)
    }
    const closeDropdown = (e: any) => {
      setDropdownAnchor(null)
    }

    const useStyles = makeStyles(() =>
      createStyles({
        list: {
          padding: '0'
        }
      })
    )
    const classes = useStyles()

    return (
      <div>
        <Container
          width={width}
          padding={padding}
          borderRadius={borderRadius}
          containerGap={containerGap}
          border={border}
          tabIndex={-1}
          style={{ pointerEvents: disabled || loading ? 'none' : 'initial' }}
        >
          <Tooltip
            title={!isUndefined(tooltip) ? tooltip : ''}
            arrow
            placement='top'
            disableInteractive
          >
            <MainContainer
              height={height}
              border={border}
              onClick={(e) => handleClick(e)}
              disabled={loading || disabled}
            >
              {children ? (
                children
              ) : (
                <div
                  style={{
                    ...Styles.TextStyles,
                    fontWeight: fontWeight,
                    cursor: 'pointer'
                  }}
                >
                  {selectedOption}
                </div>
              )}
              {openMenu ? (
                UpArrowIcon ? (
                  <div style={Styles.IconStyles}>{UpArrowIcon}</div>
                ) : (
                  <UpArrow style={Styles.IconStyles} />
                )
              ) : DownArrowIcon ? (
                <div style={Styles.IconStyles}>{DownArrowIcon}</div>
              ) : (
                <DownArrow style={Styles.IconStyles} />
              )}
            </MainContainer>
          </Tooltip>
          {openMenu && (
            <Menu
              open={openMenu}
              anchorEl={dropdownAnchor}
              onClose={closeDropdown}
              id='basic-menu'
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              elevation={0}
              sx={{ marginTop: '15px' }}
              classes={{ list: classes.list }}
              PaperProps={{
                style: {
                  minWidth: width ? width : '200px',
                  borderRadius: 6,
                  minHeight: 250,
                  marginTop: -15,
                  background: 'transparent'
                }
              }}
            >
              <DropdownContainer
                border={border}
                dropdownWidth={dropdownWidth}
                dropdownHeight={dropdownHeight}
                borderRadius={borderRadius}
              >
                {isSearchable && (
                  <SearchField
                    onChange={(e: any) => {
                      e.stopPropagation()
                      setSearchData(e.target.value)
                    }}
                  />
                )}
                <div>
                  <p style={{ ...Styles.TextStyles, fontWeight: 500 }}>
                    {dropdownLabel}
                  </p>
                  {upperChildrenComponents}
                  <DropdownItemWrapper optionOrientation={optionOrientation}>
                    {dropdownOptions
                      .filter((search: any) => {
                        if (searchData == '') {
                          return search
                        } else if (search.name.includes(searchData)) {
                          return search
                        }
                      })
                      .map((item, index) =>
                        item.element ? (
                          <DropdownElement
                            key={index + 1}
                            onClick={(e: any) => {
                              dropdownOptionHandler(index + 1)
                              closeDropdown(e)
                            }}
                          >
                            {item.element}
                          </DropdownElement>
                        ) : (
                          <DropdownElement
                            key={index + 1}
                            onClick={(e: any) => {
                              dropdownOptionHandler(index + 1)
                              closeDropdown(e)
                            }}
                          >
                            <TypographyDDS.Paragraph
                              size='para'
                              variant='medium'
                              style={{ justifyContent: 'center' }}
                            >
                              {item.name}
                            </TypographyDDS.Paragraph>
                          </DropdownElement>
                        )
                      )}
                  </DropdownItemWrapper>
                </div>
                {lowerChildrenComponents}
              </DropdownContainer>
            </Menu>
          )}
        </Container>
        {loading && <LinearProgressBar />}
      </div>
    )
  }
)
CustomDropdown.displayName = 'CustomDropdown'
export default CustomDropdown
