import { ChevronRight, Renew } from '@carbon/icons-react'
import { DDSChips, DDSTooltip, DDSTypography } from 'den-design-system'
import React, { useEffect, useState } from 'react'
import { color, mapStatusCodeToColor } from '../../../utils/ApiStatusColorCode'
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import {
  fetchHeaderGroupedData,
  findRecords
} from '../../../api/apiMonitoring/ApiMonitoring.service'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'
import { isEmpty, isNull, isUndefined } from 'lodash'
import { msToTimeUnits, timeZone } from '../../../utils/DateTimeUtils'

import ApiGroupingStringConstants from '../../../constants/ApiGroupingStringConstants'
import { Button } from '../../../components/New/Button/Button'
import DocumentHeader from '../../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import Spinner from '../../../components/common/Spinner/Spinner'
import StatusCodeGraph from '../../../components/common/StatusCodeGraph/StatusCodeGraph'
import StringConstants from '../../../constants/StringConstants'
import Table from '../../../components/common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { timeFormats } from '../../../enum/TIME'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useStoreContext } from '../../../store/StoreContext'

type ApiRecord = { [key: string]: string }

const ViewGroupedAPIDetails: React.FC = () => {
  const [tableData, setTableData] = useState<any>([])
  const [headerData, setHeaderData] = useState<any>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const [headerLoading, setHeaderLoading] = useState<boolean>(true)
  const navigate = useNavigate()
  const { id: group_id } = useParams()
  const store = useStoreContext()
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.API_MONITORING_TABLE
  )
  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.API_MONITORING
    )
  }, [])
  const routeGroupedDetails = (id: number) => {
    const route = replaceUrl(
      routeConstants.VIEW_ALL_API,
      store.scopeStore.getScope()['organization-id'] as string,
      store.scopeStore.getScope()['project-id'] as string,
      store.scopeStore.getScope()['environment-id'] as string
    ).replace(':id', String(id))
    const queryParams = { tab: '1' }
    navigate({
      pathname: route,
      search: `?${createSearchParams(queryParams)}`
    })
  }

  const viewHeaderGroupedApiData = async () => {
    setLoading(true)
    fetchHeaderGroupedData({ $id: group_id as string })
      .then((response: any) => {
        setHeaderData(response)
        setHeaderLoading(false)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
  }

  const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
  const pageSize = StringConstants.DEFAULT_TABLE_SIZE

  const fetchGroupApiRecord = async () => {
    store.apiDetailsStore.reset()
    store.uiStore.setGlobalLoader(true)
    if (group_id) {
      setLoading(true)
      try {
        const { data, totalCount } = (await findRecords({
          'api-group-id': group_id,
          ...queryParams,
          size: pageSize
        })) as {
          data: ApiRecord
          totalCount: number
        }
        setTableData(data)
        setTotalCount(totalCount)
      } catch (e) {
        store.apiDetailsStore.setIsError(true)
        let errorMessage = 'An unknown error occurred'
        if (e instanceof Error) {
          errorMessage = e.message
        }
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      } finally {
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    const pageFromQueryParams = searchParams.get('page')

    if (!isNull(pageFromQueryParams)) {
      setCurrentPage(Number(pageFromQueryParams))
    } else {
      setCurrentPage(1)
    }
    setHeaderLoading(true)
    viewHeaderGroupedApiData()
    fetchGroupApiRecord()
  }, [location.search])

  useEffect(() => {
    if (store.apiDetailsStore.getRefresh() === true) {
      fetchGroupApiRecord()
      viewHeaderGroupedApiData()
    }
  }, [store.apiDetailsStore.getRefresh()])

  useEffect(() => {
    if (!isUndefined(headerData.urlTemplate)) {
      store.breadcrumbStore.setMultipleBreadcrumbsOptions([
        {
          label: ApiGroupingStringConstants.GROUPED_API,
          link:
            replaceUrl(
              routeConstants.API_MONITORING,
              store.scopeStore.getScope()['organization-id'] as string,
              store.scopeStore.getScope()['project-id'] as string,
              store.scopeStore.getScope()['environment-id'] as string
            ) + '?tab=1'
        },
        {
          label: headerData.urlTemplate,
          link: routeConstants.VIEW_GROUPED_API.replace(
            ':id',
            group_id as string
          )
        }
      ])
    }
  }, [store.breadcrumbStore.getBreadcrumbsOptions(), headerData])

  const columns = [
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.END_POINTS}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'url',
      render: (text: any) => (
        <div className='flex row justify-center w-[220px] items-center '>
          <DDSTooltip
            id={`${text}`}
            label={
              <DDSTypography.Paragraph
                size='para'
                variant='regular'
                color={pallete.colors.textDark4}
              >
                {text}
              </DDSTypography.Paragraph>
            }
            shape='roundedRectangle'
            position='top'
            className={`override-tooltip-arrow ${
              typeof text === 'string' && text.length > 30 ? text : '!hidden'
            }`}
            style={{ backgroundColor: pallete.colors.surface1, zIndex: 999999 }}
          >
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className='tooltip-container  sm:block overflow-hidden truncate w-[220px]'
            >
              {text || text === 0 ? text : '-'}
            </DDSTypography.Paragraph>
          </DDSTooltip>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row items-center justify-center text-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.CLIENT_VERSION}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'clientVersion',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text || text === 0 ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },

    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.RESPONSE_STATUS}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'responseStatusCode',
      render: (text: any) => (
        <div
          className={`flex justify-center override-statusCode-center override-status-chip`}
        >
          <DDSChips
            label={text}
            type='filled'
            shape='rounded'
            size='small'
            className='override-status-chip'
            style={{
              backgroundColor: mapStatusCodeToColor(text),
              borderColor: 'transparent',
              height: '24px',
              width: '60px',
              justifyContent: 'center'
            }}
          />
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.START_TIME}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'startTime',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{ justifyContent: 'center' }}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {timeZone(
              store.userStore.getUserPreference().timeZone,
              text,
              timeFormats.fullMonthDateYearTimeWithSeconds
            )}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.RESPONSE_TIME}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'responseTimeInMilliseconds',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{ justifyContent: 'center' }}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text ? msToTimeUnits(text) : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },

    {
      columnHeaderStyle: { width: '20px' },
      columnDataStyle: { width: '20px' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='cursor-pointer'>
          <ChevronRight
            size={16}
            onClick={() => {
              gaEventTracker(gaEventConstants.VIEW_SINGLE_API_RECORD)
              routeGroupedDetails(fullItem.id)
            }}
            color={pallete.colors.FillButton}
          />
        </div>
      )
    }
  ]

  return (
    <>
      <DocumentHeader header={HeaderConstants.API_MONITORING} />
      {!loading ? (
        <div className='h-full w-full'>
          <div className='w-full py-[24px]'>
            <div className='relative'>
              <div className='flex items-center justify-between'>
                <div>
                  <div className='flex justify-start items-start'>
                    <DDSTooltip
                      id='overview-tooltip'
                      position='top'
                      shape='roundedRectangle'
                      label={
                        <DDSTypography.Paragraph
                          size='para'
                          variant='regular'
                          color={pallete.colors.textDark4}
                        >
                          {headerData.urlTemplate}
                        </DDSTypography.Paragraph>
                      }
                      className={`override-tooltip-arrow ${
                        typeof headerData.urlTemplate === 'string' &&
                        headerData.urlTemplate.length > 40
                          ? headerData.urlTemplate
                          : '!hidden'
                      }`}
                      style={{
                        backgroundColor: pallete.colors.surface1,
                        zIndex: 999999
                      }}
                    >
                      <DDSTypography.Title
                        type='h3'
                        variant='semiBold'
                        color={pallete.colors.textDark3}
                        className='truncate w-[600px]'
                      >
                        {headerData.urlTemplate || headerData.urlTemplate === 0
                          ? headerData.urlTemplate
                          : '-'}
                      </DDSTypography.Title>
                    </DDSTooltip>
                  </div>

                  <div className='flex justify-start gap-[4px] items-baseline override-version'>
                    <DDSTypography.Paragraph
                      size='caption'
                      variant='medium'
                      className='whitespace-nowrap'
                      color={pallete.colors.textDark6}
                    >
                      {ApiGroupingStringConstants.HTTP_METHOD}:
                    </DDSTypography.Paragraph>
                    <DDSTypography.Paragraph
                      size='para'
                      variant='semiBold'
                      className='whitespace-nowrap'
                      color={pallete.colors.textDark3}
                    >
                      {headerData.httpMethod || headerData.httpMethod === 0
                        ? headerData.httpMethod
                        : '-'}
                    </DDSTypography.Paragraph>
                  </div>
                </div>
                <div className='flex items-center justify-end'>
                  <div className='mr-[8px]'>
                    <DDSTypography.Paragraph
                      size='caption'
                      variant='medium'
                      color={pallete.colors.textDark6}
                    >
                      {ApiGroupingStringConstants.STATUS_CODE_GRAPH}:
                    </DDSTypography.Paragraph>
                  </div>
                  <div className='flex flex-row gap-x-[24px] items-center'>
                    {!headerLoading ? (
                      <StatusCodeGraph
                        tableHeight={''}
                        width='144px'
                        totalCount={totalCount}
                        data={headerData.statusCodeGraph[headerData.id]}
                        color={color}
                      />
                    ) : null}
                    <Button
                      id='outline-button'
                      label=' Refresh'
                      type='neutral'
                      onClick={() => store.apiDetailsStore.setRefresh(true)}
                      startIcon={<Renew color={pallete.colors.neuButtonText} />}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='mt-[24px] flex w-full '>
              <Table
                tableStyle={{
                  borderColor: pallete.colors.stroke2
                }}
                data={tableData}
                columns={columns}
                totalCount={totalCount}
                rowsPerPage={10}
                currentPage={currentPage}
                onPageChange={(page: number) => {
                  const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                    location.search
                  )
                  setSearchParams({ ...allQueryParams, page: page.toString() })
                  setCurrentPage(page)
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  )
}

export default observer(ViewGroupedAPIDetails)
