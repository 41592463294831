export default {
  // Sandbox
  MEMBER: 'Member',
  OWNER: 'Owner',
  VIGIL_DEMO: 'VIGIL Demo',
  WORK_EMAIL: 'Work Email',
  MEMBER_ROLE: 'Member Role',
  TEXT1: 'Curious if this is worth your time.',
  TEXT2: 'Pop your email in below and get ready for some serious Productivity!'
}
