import React, { useEffect, useState } from 'react'
import {
  getAllNotifications,
  getNotificationFilters,
  markAllNotificationsAsRead
} from '../../api/notification/notification.service'

import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import DashboardSectionContainer from '../../components/common/DashboardSectionContainer/DashboardSectionContainer'
import DashboardTopNavbar from '../../components/common/DashboardTopNavbar/DashboardTopNavbar'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../constants/HeaderConstants'
import NotificationBar from '../../components/common/DashboardTopNavbar/bottomBars/NotificationBar'
import NotificationHeader from '../../components/Notifications/NotificationHeader'
import NotificationTable from '../../components/Notifications/NotificationTable'
import SecureComponent from '../../components/Layout/SecureComponent'
import Spinner from '../../components/common/Spinner/Spinner'
import StringConstants from '../../constants/StringConstants'
import { TypographyDDS } from '../../components/common/Typography/TypographyDDS'
import { getAllQueryParamsAsObjectFromUrl } from '../../utils/UrlUtils'
import { isEmpty } from 'lodash'
import palette from '../../global/pallete'
import styled from '@emotion/styled'
import successIcon from '../../assets/icons/successIcon.svg'
import { useSearchParams } from 'react-router-dom'
import { useStoreContext } from '../../store/StoreContext'

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`

const Container = styled.div`
  display: grid;
  flex-grow: 1;
`

const Notifications: React.FC = () => {
  const store = useStoreContext()
  const [totalCount, setTotalCount] = useState<number>(0)
  const allSearchParams = getAllQueryParamsAsObjectFromUrl(location.search)
  const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
  const [searchParams, setSearchParams] = useSearchParams()
  const [dataTabMap, setDataTabMap] = useState<any>([])
  const [currentTab, setCurrentTab] = useState<number>(
    parseInt(searchParams.get('tab') as string) || StringConstants.DEFAULT_TAB
  )
  const [page, setPage] = useState<number>(
    parseInt(searchParams.get('page') as string) || StringConstants.DEFAULT_PAGE
  )
  const pageSize = StringConstants.DEFAULT_TABLE_SIZE
  const [options, setOptions] = React.useState<any>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [disabled, setDisabled] = React.useState<boolean>(false)
  const [filterLoading, setFilterLoading] = React.useState<boolean>(false)
  const TabLabels = [
    { id: 0, label: 'All' },
    { id: 1, label: 'Read' },
    { id: 2, label: 'Unread' }
  ]
  const handleTabChange = async (selectedTab: number) => {
    if (selectedTab === 0) {
      setSearchParams(
        { ...allSearchParams, page: 1, tab: 0 },
        { replace: true }
      )
      setCurrentTab(0)
    }
    if (selectedTab === 1) {
      setSearchParams(
        { ...allSearchParams, page: 1, tab: 1 },
        { replace: true }
      )
      setCurrentTab(1)
    }
    if (selectedTab === 2) {
      setSearchParams(
        { ...allSearchParams, page: 1, tab: 2 },
        { replace: true }
      )
      setCurrentTab(2)
    }
    setPage(1)
  }

  const getNotifications = () => {
    setDataTabMap([])
    setLoading(true)
    setFilterLoading(true)
    store.filterStore.setFilterDisabled(true)
    getNotificationFilters()
      .then((resp: any) => {
        setOptions(resp.data)
        setFilterLoading(false)
      })
      .catch((err: any) => {
        setFilterLoading(false)
        console.log(err)
      })
    if (currentTab === 0) {
      setDisabled(true)
      getAllNotifications({
        ...queryParams,
        size: pageSize
      }).then((allNotifications: any) => {
        if (
          allNotifications.data.length > 0 ||
          store.filterStore.getFiltersApplied()
        ) {
          store.filterStore.setFilterDisabled(false)
        }
        setDataTabMap(allNotifications.data)
        setTotalCount(allNotifications.totalCount)
        setPage(allNotifications.page)
        store.filterStore.setFilters(allNotifications.filters)
        setLoading(false)
        store.uiStore.setGlobalLoader(false)

        const unReadNotifications = allNotifications.data.filter(
          (eachNotification: any) => eachNotification['readAt'] === null
        )

        if (unReadNotifications.length !== 0) setDisabled(false)
      })
    } else if (currentTab === 1) {
      getAllNotifications({
        status: 'READ',
        ...queryParams,
        size: pageSize
      })
        .then((readNotification: any) => {
          if (
            readNotification.data.length > 0 ||
            store.filterStore.getFiltersApplied()
          ) {
            store.filterStore.setFilterDisabled(false)
          }
          setDataTabMap(readNotification.data)
          setTotalCount(readNotification.totalCount)
          setPage(readNotification.page)
          store.filterStore.setFilters(readNotification.filters)
          setLoading(false)
        })
        .catch((err: any) => console.log(err))
    } else {
      setDisabled(true)
      getAllNotifications({
        status: 'UNREAD',
        ...queryParams,
        size: pageSize
      }).then((unreadNotification: any) => {
        if (
          unreadNotification.data.length > 0 ||
          store.filterStore.getFiltersApplied()
        ) {
          store.filterStore.setFilterDisabled(false)
        }
        setDataTabMap(unreadNotification.data)
        setTotalCount(unreadNotification.totalCount)
        setPage(unreadNotification.page)
        store.filterStore.setFilters(unreadNotification.filters)
        setLoading(false)
        if (unreadNotification.totalCount !== 0) {
          setDisabled(false)
        }
      })
    }
    store.uiStore.setGlobalLoader(false)
  }

  const markAllAsRead = () => {
    markAllNotificationsAsRead()
      .then(() => {
        getNotifications()
      })
      .catch((err: any) => console.log(err))
  }

  useEffect(() => {
    setSearchParams(searchParams, { replace: true })
    getNotifications()
  }, [location.search, currentTab])
  return (
    <Container>
      {/* <DashboardTopNavbar variant='notifications' /> */}
      <NotificationBar />
      <DocumentHeader header={HeaderConstants.NOTIFICATIONS} />
      <DashboardSectionContainer
        headerText='Your Notifications'
        customHeader={
          <NotificationHeader
            tabLabels={TabLabels}
            currentTab={currentTab}
            setCurrentTab={(tab) => {
              handleTabChange(tab)
            }}
            options={options}
            disabled={filterLoading}
            markAllAsRead={markAllAsRead}
            buttonDisabled={disabled}
          />
        }
        noDivider
        noDividerPadding
      >
        {!loading || !isEmpty(dataTabMap) ? (
          dataTabMap.length === 0 ? (
            <EmptyContainer>
              <img
                src={successIcon}
                style={{
                  height: '50px',
                  filter: palette.colors.primarySvgFilter
                }}
              />
              <TypographyDDS.Title type='h3' variant='bold'>
                You{`'`}re all caught up
              </TypographyDDS.Title>
            </EmptyContainer>
          ) : (
            <NotificationTable
              data={dataTabMap}
              totalCount={totalCount}
              currentPage={page}
              onPageChange={(number) => {
                setSearchParams(
                  {
                    ...queryParams,
                    page: number.toString()
                  },
                  { replace: true }
                )
                setPage(number)
              }}
              rowsPerPage={pageSize}
              getNotifications={getNotifications}
              setLoading={setLoading}
            />
          )
        ) : (
          <Spinner />
        )}
      </DashboardSectionContainer>
    </Container>
  )
}

export default SecureComponent(Notifications, AccessRoleConstants.ALL)
