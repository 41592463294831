import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import AllProjects from '../../components/common/ProjectSettings/AllProjects'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../constants/HeaderConstants'
import IndividualProjects from '../../components/common/ProjectSettings/IndividualProjects'
import { OrganizationUserRoles } from '../../enum/OrganizationUserRoles.enum'
import SecureComponent from '../../components/Layout/SecureComponent'
import { SettingBottomBar } from '../../components/common/SettingBottomBar/SettingBottomBar'
import SettingsBar from '../../components/common/DashboardTopNavbar/bottomBars/Settings'
import StringConstants from '../../constants/StringConstants'
import { UrlWithOnlyOrgId } from '../../utils/UrlUtils'
import { getUserDetails } from '../../api/user/User.service'
import palette from '../../global/pallete'
import routeConstants from '../../constants/RouteConstants'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import { useStoreContext } from '../../store/StoreContext'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Wrapper = styled.div`
  // overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
    height: 0px;
    transition: height 0.4s;
  }
  &:hover {
    ::-webkit-scrollbar {
      height: 2px;
      transition: height 0.4s;
    }
  }
`

const ProjectSettings = () => {
  const store = useStoreContext()
  const navigate = useNavigate()
  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.SETTINGS
    )
    getUserDetails().then((response: any) => {
      const organizationId = store.scopeStore.getScope()['organization-id']
      const selectedOrganization = response.data.organizations.find(
        (organization: any) => organization.id === organizationId
      )

      store.userStore.setUserRole(selectedOrganization.membership.role)
      if (
        selectedOrganization.membership.role === OrganizationUserRoles.BILLING
      ) {
        navigate(routeConstants.UNAUTHORIZED_PAGE)
      }
    })
  }, [])

  return (
    <Container>
      {/* <DashboardTopNavbar
        variant='settings'
        key='settings'
        breadcrumbs={
          window.location.pathname.split('/').at(-1) !==
          routeConstants.PROJECT_SETTINGS.split('/').at(-1)
        }
        showBottomBar
      /> */}
      <SettingBottomBar>
        <SettingsBar showButtons={false} />
      </SettingBottomBar>
      <DocumentHeader header={HeaderConstants.PROJECT_SETTINGS} />
      <Wrapper>
        <Routes>
          <Route index element={<AllProjects />} />
          <Route path={':projectId' + '/*'} element={<IndividualProjects />} />
        </Routes>
      </Wrapper>
    </Container>
  )
}

export default SecureComponent(
  ProjectSettings,
  AccessRoleConstants.MEMBER_LEVEL
)
