import React, { useState } from 'react'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'

import DashboardSectionContainer from '../../common/DashboardSectionContainer/DashboardSectionContainer'
import TransferOwnershipButton from './TransferOwnershipButton'
import TransferOwnershipPopup from '../../GeneralSettings/TransferOwnershipPopup'
import { TypographyDDS } from '../../common/Typography/TypographyDDS'
import palette from '../../../global/pallete'
import routeConstants from '../../../constants/RouteConstants'
import styled from '@emotion/styled'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useNavigate } from 'react-router-dom'

const Layout = styled.div`
  margin-bottom: 16px;
`
const Container = styled.div`
  display: flex;
`
const LeftSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const RightSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  max-width: 352px;
  width: 100%;
`
const Spacer = styled.div`
  flex-grow: 1;
`

const OwnershipSection: React.FC<{
  loading: boolean;
}> = ({loading}) => {
  const [transferPopupOpen, setTransferPopupOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.GENERAL_SETTINGS
  )

  return (
    <>
      <TransferOwnershipPopup
        open={transferPopupOpen}
        handleClose={() => setTransferPopupOpen(false)}
      />
      <Layout>
        <DashboardSectionContainer headerText='Ownership'>
          <Container>
            <LeftSectionContainer>
              <TypographyDDS.Title type='h5' variant='bold'>
                Transfer Ownership
              </TypographyDDS.Title>
              <TypographyDDS.Title
                type='h5'
                variant='medium'
                color='textDark3'
                style={{ marginTop: '16px' }}
              >
                Transferring your organization{`'`}s ownership will remove you
                as
                <br />
                an owner of this organization. Learn more about{' '}
                <b
                  style={{ color: palette.colors.primary2, cursor: 'pointer' }}
                  onClick={() => {
                    if (!loading) {
                      navigate('.' + routeConstants.ROLES_AND_ACTIONS);
                    }
                  }}
                >
                  Roles & Actions
                </b>
              </TypographyDDS.Title>
              <TypographyDDS.Title
                type='h5'
                variant='medium'
                color='textDark3'
                style={{ marginTop: '20px', marginBottom: '100px' }}
              >
                <span style={{ color: palette.colors.error }}>{`Note: `}</span>
                <span
                  style={{
                    display: 'inline-block'
                  }}
                >
                  The person should be a member of this organization
                </span>
              </TypographyDDS.Title>
            </LeftSectionContainer>
            <Spacer />
            <RightSectionContainer>
              <TransferOwnershipButton
                margin='16px 0 0 0'
                onClick={() => {
                  gaEventTracker(gaEventConstants.OPEN_TRANSFER_OWNERSHIP_POPUP)
                  setTransferPopupOpen(true)
                }}
                loading={loading}
              />
            </RightSectionContainer>
          </Container>
        </DashboardSectionContainer>
      </Layout>
    </>
  )
}

export default OwnershipSection
