import React from 'react'
import Typography from '../Typography/Typography'
import { TypographyDDS } from '../Typography/TypographyDDS'
import logo from '../../../assets/images/loading.gif'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'
const Container = styled.div`
  display: flex;
  height: 100vh;
  background: ${palette.colors.overlayBackground};
  align-items: center;
  justify-content: center;
  z-index: 999;
  position: absolute;
  width: 100%;
  flex-direction: column;
`
const ImgWrapper = styled.div`
  width: 200px;
  height: 200px;
`
const PageLoadingOverlay: React.FC<{ loadingTitle?: string }> = ({
  loadingTitle
}) => {
  return (
    <Container>
      <ImgWrapper>
        {/* <Spinner /> */}
        <img
          src={logo}
          alt='loading...'
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
      </ImgWrapper>
      {loadingTitle && (
        <TypographyDDS.Title
          type='h4'
          variant='medium'
          style={{ paddingBottom: '10px', textAlign: 'left' }}
        >
          {loadingTitle}
        </TypographyDDS.Title>
      )}
    </Container>
  )
}

export default PageLoadingOverlay
