import ComingSoon from '../../components/common/ComingSoon/ComingSoon'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../constants/HeaderConstants'
import React from 'react'

const Issues = () => {
  return (
    <>
      <DocumentHeader header={HeaderConstants.ISSUES} />
      <ComingSoon />
    </>
  )
}

export default Issues
