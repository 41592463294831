import React, { useRef } from 'react'
import {
  getValidationError,
  getValidationStatus
} from '../../../utils/ValidationUtils'

import Button from '../../common/Button/Button'
import InputField from '../../common/InputField/InputField'
import { IoIosArrowForward } from 'react-icons/io'
import Joi from 'joi'
import Popup from '../../common/Popup/Popup'
import RegexConstants from '../../../constants/RegexConstants'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import { TypographyDDS } from '../../common/Typography/TypographyDDS'
import { VscRefresh as UpdateIcon } from 'react-icons/vsc'
import ValidationConstants from '../../../constants/ValidationConstants'
import { changePassword } from '../../../api/user/User.service'
import { isUndefined } from 'lodash'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

interface UpdatePasswordPopupProps {
  open: boolean
  handleClose: () => void
}

interface ItemContainerProps {
  width?: string
  height?: string
  padding?: string
  margin?: string
}

const PopUpSectionWrapper = styled.div`
  min-height: 300px;
  margin-top: 57px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const Left = styled.div`
  margin: 20px 20px 20px 0;
`

const Right = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 285px;
  grid-gap: 20px;
`

const ItemContainer = styled.div<ItemContainerProps>`
  width: ${(props) => (props.width ? props.width : '300px')};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`

const UpdatePassword: React.FC<UpdatePasswordPopupProps> = ({
  open,
  handleClose
}) => {
  const [currentPassword, setCurrentPassword] = React.useState('')
  const currentPasswordRef = useRef<any>()
  const [newPassword, setNewPassword] = React.useState('')
  const newPasswordRef = useRef<any>()
  const [retypeNewPassword, setRetypeNewPassword] = React.useState('')
  const retypeNewPasswordRef = useRef<any>()
  const [helperText, setHelperText] = React.useState('')
  const [error, setError] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const schema = Joi.object({
    currentPassword: Joi.string().required().messages({
      'string.empty': ValidationConstants.GENERIC.REQUIRED_VALUE
    }),
    newPassword: Joi.string()
      .pattern(RegexConstants.PASSWORD)
      .min(8)
      .invalid(Joi.ref('currentPassword'))
      .required()
      .messages({
        'any.invalid': ValidationConstants.PASSWORD.SAME_OLD_PASSWORD,
        'string.pattern.base':
          ValidationConstants.PASSWORD.INVALID_PASSWORD_REGEX,
        'string.empty': ValidationConstants.GENERIC.REQUIRED_VALUE,
        'string.min': ValidationConstants.GENERIC.MIN_LENGTH.replace(
          '{length}',
          '8'
        )
      }),
    retypeNewPassword: Joi.string()
      .required()
      .equal(Joi.ref('newPassword'))
      .messages({
        'string.min': ValidationConstants.GENERIC.MIN_LENGTH.replace(
          '{length}',
          '8'
        ),
        'string.empty': ValidationConstants.GENERIC.REQUIRED_VALUE,
        'any.only': ValidationConstants.PASSWORD.PASSWORDS_MISMATCH
      })
  })

  const [validationErrorResult, setValidationErrorResult] = React.useState<
    any[]
  >([])
  React.useEffect(() => {
    resetStates()
  }, [])

  const resetStates = () => {
    setCurrentPassword('')
    setNewPassword('')
    setValidationErrorResult([])
    setRetypeNewPassword('')
    setError(false)
    setHelperText('')
    currentPasswordRef.current?.handleTypeChange('password')
    newPasswordRef.current?.handleTypeChange('password')
    retypeNewPasswordRef.current?.handleTypeChange('password')
  }
  const handleUpdatePassword = () => {
    setLoading(true)
    const validationResult = schema.validate({
      currentPassword,
      newPassword,
      retypeNewPassword
    })

    if (isUndefined(validationResult.error)) {
      setValidationErrorResult([])
      if (!error) {
        changePassword({
          oldPassword: currentPassword,
          newPassword: newPassword
        })
          .then(() => {
            ToastNotification({
              type: 'success',
              message: ToastMessageConstants.AUTH.UPDATE_PASSWORD.SUCCESS
            })

            handleClose()
          })
          .catch((err) => {
            ToastNotification({
              type: 'error',
              message: ToastMessageConstants.AUTH.UPDATE_PASSWORD.ERROR
            })
          })
          .finally(() => {
            setLoading(false)
            resetStates()
          })
      } else {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.AUTH.UPDATE_PASSWORD.VALIDATE
        })
        resetStates()

        setLoading(false)
      }
    } else {
      setLoading(false)
      setValidationErrorResult(validationResult.error.details)
      currentPasswordRef.current?.handleTypeChange('password')
      newPasswordRef.current?.handleTypeChange('password')
      retypeNewPasswordRef.current?.handleTypeChange('password')
    }
  }

  return (
    <Popup
      open={open}
      headerText='Update Password'
      handleClose={() => {
        resetStates()
        handleClose()
      }}
      width='700px'
      height='500px'
      disabled={loading}
      headerIcon={<UpdateIcon />}
    >
      <PopUpSectionWrapper>
        <Left>
          <TypographyDDS.Title
            type='h3'
            variant='medium'
            style={{
              paddingBottom: '10px',
              maxWidth: '350px',
              textAlign: 'left'
            }}
          >
            Update your account
            <br />
            <span
              style={{
                fontWeight: palette.fontWeight.bold,
                color: palette.colors.primary
              }}
            >
              Password
            </span>
          </TypographyDDS.Title>
          <TypographyDDS.Title
            type='h4'
            variant='medium'
            color='textDark2'
            style={{ maxWidth: '350px' }}
          >
            Enter and confirm your new password
          </TypographyDDS.Title>
        </Left>
        <Right>
          <ItemContainer>
            <InputField
              ref={currentPasswordRef}
              label='Current Password'
              type='password'
              value={currentPassword}
              onChange={(e: any) => {
                setCurrentPassword(e.target.value)
              }}
              onBlur={(e: any) => {
                setCurrentPassword(e.target.value)
              }}
              fullWidth
              name='password'
              helperText={getValidationError(
                validationErrorResult,
                'currentPassword'
              )}
              error={getValidationStatus(
                validationErrorResult,
                'currentPassword'
              )}
            />
          </ItemContainer>

          <ItemContainer>
            <InputField
              ref={newPasswordRef}
              label='New Password'
              type='password'
              value={newPassword}
              onChange={(e: any) => {
                setNewPassword(e.target.value)
              }}
              onBlur={(e: any) => {
                setNewPassword(e.target.value)
              }}
              fullWidth
              name='password'
              helperText={
                getValidationStatus(validationErrorResult, 'newPassword')
                  ? validationErrorResult[0].message
                  : ''
              }
              error={getValidationStatus(validationErrorResult, 'newPassword')}
            />
          </ItemContainer>

          <ItemContainer>
            <InputField
              ref={retypeNewPasswordRef}
              label='Confirm New Password'
              type='password'
              fullWidth
              value={retypeNewPassword}
              onChange={(e: any) => {
                setRetypeNewPassword(e.target.value)
              }}
              name='password'
              error={getValidationStatus(
                validationErrorResult,
                'retypeNewPassword'
              )}
              helperText={getValidationError(
                validationErrorResult,
                'retypeNewPassword'
              )}
            />
          </ItemContainer>

          <ItemContainer>
            <Button
              variant='contained'
              fullWidth
              iconSize='16px'
              endIcon={
                <IoIosArrowForward
                  style={{
                    fontSize: 16
                  }}
                />
              }
              height='56px'
              onClick={handleUpdatePassword}
              loading={loading}
            >
              Update Password
            </Button>
          </ItemContainer>
        </Right>
      </PopUpSectionWrapper>
    </Popup>
  )
}

export default UpdatePassword
