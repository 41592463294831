import Button from '../common/Button/Button'
import React from 'react'
import breakpoints from '../../global/breakpoints'
import palette from '../../global/pallete'
import routeConstants from '../../constants/RouteConstants'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import vigilLogo from '../../assets/icons/vigilTMLogo.svg'

interface NavbarProps {
  withLinks?: boolean
  links?: { to: string; label: string }[]
  buttonText?: string
  onClick?: () => void
  buttonLink?: string
  padding?: string
  margin?: string
  width?: string
  hideButton?: boolean
  loading?: boolean
}

interface ContainerProps {
  width?: string
  padding?: string
  margin?: string
}

const Container = styled.div<ContainerProps>`
  width: ${(props) => props.width};
  min-width: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`
const IconContainer = styled.div`
  width: 7em;
  height: 3.125em;
  margin-top: -90px;
  @media (max-width: ${breakpoints.md}) {
    margin-top: -110px;
  }
`

const NavigationLinks = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 3em;
`

const Navbar: React.FC<NavbarProps> = ({
  withLinks = true,
  buttonText = 'Sign in',
  onClick,
  buttonLink,
  padding = '0 0',
  margin = '0',
  width = '100%',
  hideButton = false,
  loading
}) => {
  const navigate = useNavigate()

  return (
    <Container padding={padding} margin={margin} width={width}>
      <IconContainer>
        <img
          src={vigilLogo}
          alt='logo'
          style={{
            alignItems: 'center',
            marginTop: '35px',
            height: '70px',
            width: '150px',
            cursor: 'pointer'
          }}
          onClick={() =>
            loading ? undefined : navigate(routeConstants.SIGN_IN)
          }
        />
      </IconContainer>
      {withLinks && (
        <NavigationLinks data-testid={'navigation-links'}>
          {/* Pricing section not in release 1 */}
          {/* <LinkTypography variant='body2'>Pricing</LinkTypography> */}
          {/* <StyledLink href={StringConstants.VIGIL_WEBSITE_LINK}>
            <LinkTypography variant='body2'>About Us</LinkTypography>
          </StyledLink>
          <StyledLink href={StringConstants.VIGIL_WEBSITE_LINK}>
            <LinkTypography variant='body2'>Contact Us</LinkTypography>
          </StyledLink> */}
        </NavigationLinks>
      )}
      {!hideButton && (
        <Button
          variant='contained'
          type='button'
          size='large'
          textTransform='none'
          width='12.5em'
          height='3.5em'
          background={`${palette.colors.gradientBlue}`}
          onClick={onClick}
          href={buttonLink}
          disabled={loading}
        >
          {buttonText}
        </Button>
      )}
    </Container>
  )
}

export default Navbar
