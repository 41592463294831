import { AtoB, BtoA, replaceUrl } from '../../../../utils/UrlUtils'
import {
  default as DeleteIcon,
  default as trashIcon
} from '../../../../assets/icons/trash.svg'
import React, { useEffect, useRef } from 'react'
import { cloneDeep, isUndefined } from 'lodash'
import {
  deleteEnvironment,
  findEnvironment,
  updateEnvironment
} from '../../../../api/environment/Environment.service'
import { observer, useObserver } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'

import AddDomainsCard from '../../DomainCards/AddDomainsCard'
import { BsInfoCircle } from 'react-icons/bs'
import Button from '../../Button/Button'
import DashboardSectionContainer from '../../DashboardSectionContainer/DashboardSectionContainer'
import DeleteDomainsCard from '../../DomainCards/DeleteDomainsCard'
import FilledTabGroup from '../../TabGroup/FilledTabGroup'
import InputField from '../../InputField/InputField'
import Joi from 'joi'
import { OrganizationUserRoles } from '../../../../enum/OrganizationUserRoles.enum'
import Popup from '../../Popup/Popup'
import { RefObject } from '../../InputField/Interface/Interface'
import RegexConstants from '../../../../constants/RegexConstants'
import Spinner from '../../Spinner/Spinner'
import StringConstants from '../../../../constants/StringConstants'
import Table from '../../Table/Table'
import TextConfirmationPopup from '../../Popup/TextConfirmationPopup'
import { TextContentConstants } from '../../../../constants/TextContentConstants'
import { ToastMessageConstants } from '../../../../constants/ToastMessageConstants'
import ToastNotification from '../../DDS/Toast/Toast'
import { Tooltip } from '@mui/material'
import { TypographyDDS } from '../../Typography/TypographyDDS'
import UrlConstants from '../../../../constants/UrlConstants'
import ValidationConstants from '../../../../constants/ValidationConstants'
import breakpoints from '../../../../global/breakpoints'
import { checkNameAvailability } from '../../../../api/common/Common.service'
import { getEnvironmentLabelsForOrganization } from '../../../../utils/OrganizationUtils'
import { paginate } from '../../../../utils/CommonUtils'
import palette from '../../../../global/pallete'
import plusIcon from '../../../../assets/icons/plus.svg'
import routeConstants from '../../../../constants/RouteConstants'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import { useStoreContext } from '../../../../store/StoreContext'

enum EnvironmentType {
  DEVELOPMENT = 'DEVELOPMENT',
  STAGING = 'STAGING',
  PRODUCTION = 'PRODUCTION'
}

interface ProjectEnvironmentProps {
  environmentName?: string
  environmentType?: EnvironmentType
}

interface ParaProps {
  padding?: string
  lineHeight?: number
  fontSize?: string
  maxWidth?: string
}

const IndividualSettingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em 0.3125em 4em;
  grid-gap: 2.5em;

  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const HostTableContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em 0.3125em;
  grid-gap: 2.5em;

  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const Divider = styled.div`
  border-bottom: 1px solid ${palette.colors.borderColor};
`

const SettingsLeft = styled.div`
  @media screen and (max-width: ${breakpoints.md}) {
    width: 80%;
    margin-bottom: 1em;
    justify-content: center;
  }
`

const HostsContainerLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${breakpoints.md}) {
    width: 80%;
    margin-bottom: 1em;
    justify-content: center;
  }
`

const ToolTipContainer = styled.div`
  margin-bottom: 5px;
`

const SettingsRight = styled.div`
  min-width: 30%;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 40%;
    display: flex;
    justify-content: center;
  }
`

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const TableHostNameContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  margin-right: auto;
`

const TableDeleteButtonContainer = styled.div`
  margin-left: auto;
`

const TabLabels = [
  { id: 0, label: 'Development', type: EnvironmentType.DEVELOPMENT },
  { id: 1, label: 'Staging', type: EnvironmentType.STAGING },
  { id: 2, label: 'Production', type: EnvironmentType.PRODUCTION }
]

const ProjectEnvironmentCard: React.FC<ProjectEnvironmentProps> = () => {
  const [envPopUp, setEnvPopUp] = React.useState(false)
  const [addDomainPopUp, setAddDomainPopUp] = React.useState(false)
  const [deleteDomainPopUp, setDeleteDomainPopUp] = React.useState(false)
  const [environmentName, setEnvironmentName] = React.useState<string>('')
  const [name, setName] = React.useState<string>('')
  const [currentPage, setCurrentPage] = React.useState(1)
  const [environmentTypeDisable, setEnvironmentTypeDisable] =
    React.useState<boolean>(false)
  const [currentTab, setCurrentTab] = React.useState<number>(0)

  const [helperText, setHelperText] = React.useState<string>('')

  const [loading, setLoading] = React.useState<boolean>(true)
  const { pathIds } = useParams()
  const { environmentId, projectId } = useParams()
  const navigate = useNavigate()
  const store = useStoreContext()
  const reference1 = useRef<RefObject>(null)
  const [deleteDomainDetails, setDeleteDomainDetails] = React.useState<any>({})
  const [whiteListedHost, setWhiteListedHost] = React.useState<any[]>([])
  const tableData = whiteListedHost.map((host) => {
    return { host }
  })

  const nameSchema = Joi.object({
    name: Joi.string().pattern(RegexConstants.NAME_REGEX).required().messages({
      'string.empty': ValidationConstants.GENERIC.REQUIRED_VALUE,
      'string.pattern.base': ValidationConstants.GENERIC.INVALID_NAME_REGEX
    })
  })
  const [tabLabels, setTabLabels] = React.useState<any>([])

  const getData = () => {
    if (
      paginate(tableData, currentPage, StringConstants.HOST_TABLE_PAGE_SIZE)
        .length === 0 &&
      currentPage > 1
    ) {
      setCurrentPage(currentPage - 1)
      return paginate(
        tableData,
        currentPage - 1,
        StringConstants.HOST_TABLE_PAGE_SIZE
      )
    } else {
      return paginate(
        tableData,
        currentPage,
        StringConstants.HOST_TABLE_PAGE_SIZE
      )
    }
  }

  useEffect(() => {
    if (!store.userStore.getNoActiveSubscription())
      setTabLabels(
        getEnvironmentLabelsForOrganization(
          store.userStore.getOrganizations(),
          store.scopeStore.getOrganizationId() as string
        )
      )
    setLoading(true)
    store.scopeStore.setOrganizationId(
      AtoB(pathIds as string).split('/')[0] as string
    )
    findEnvironment(projectId as string, environmentId as string)
      .then((environment: any) => {
        setWhiteListedHost(environment.whitelistedHosts)
        setLoading(false)
        setEnvironmentName(
          environment.type == StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
            ? StringConstants.ENVIRONMENT_NAMES.NON_PROD
            : StringConstants.ENVIRONMENT_NAMES.PROD
        )
        setName(environment.type)
        setCurrentTab(
          TabLabels.findIndex(
            (tabLabel: any) => tabLabel.type === environment.type
          ) || 0
        )
      })
      .catch((error) => {
        setLoading(false)
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SOMETHING_WENT_WRONG
        })
        navigate(
          replaceUrl(
            routeConstants.PROJECT_SETTINGS_ENV,
            store.scopeStore.getOrganizationId() as string,
            '',
            ''
          ).replace(':projectId', projectId as string)
        )
      })
      .finally(() => store.uiStore.setGlobalLoader(false))
  }, [])

  const handleAddHosts = (tableData: any[]) => {
    setLoading(true)
    updateEnvironment({
      whitelistedHosts: tableData,
      projectId,
      environmentId
    })
      .then((updatedEnvironment: any) => {
        setLoading(false)
        setAddDomainPopUp(false)
        setWhiteListedHost(updatedEnvironment.whitelistedHosts)
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.ENVIRONMENT.CREATE.WHITELIST_HOSTS
        })
      })
      .catch((error) => {
        setLoading(false)
        setAddDomainPopUp(false)
        ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.ENVIRONMENT.CREATE.WHITELIST_HOSTS_ERROR
        })
      })
  }

  const updateEnvironmentName = (reference: any) => {
    if (name === environmentName) {
      setHelperText('')
      return
    }

    const validationResult = nameSchema.validate(
      { name: name.trim() },
      { abortEarly: true }
    )
    if (isUndefined(validationResult.error)) {
      setHelperText('')
      reference.current?.startLoading()
      checkNameAvailability(
        UrlConstants.ENVIRONMENT_NAME_AVAILABILITY.USECASE,
        name.trim(),
        undefined,
        { projectId }
      )
        .then(() => {
          updateEnvironment({ name: name.trim(), projectId, environmentId })
            .then((updatedEnvironment: any) => {
              setEnvironmentName(updatedEnvironment.name)

              // update only environment name and slug in the scope store, since there no other fields are affected if project name is updated
              const projects = cloneDeep(store.scopeStore.getProjects())

              const selectedProject = projects.findIndex(
                (project: any) => project.id === projectId
              )

              const environment_ = (
                projects[selectedProject] as any
              ).environments.find(
                (environment: any) => environment.id === updatedEnvironment.id
              )
              if (!isUndefined(environment_)) {
                environment_.name = updatedEnvironment.name
                environment_.slug = updatedEnvironment.slug
              }

              store.scopeStore.setProjects(projects)
              store.scopeStore.setEnvironments(
                (projects[selectedProject] as any).environments
              )

              setLoading(false)
              ToastNotification({
                type: 'success',
                message: ToastMessageConstants.ENVIRONMENT.UPDATE.NAME.SUCCESS
              })
              reference.current?.stopLoading()
            })
            .catch((error) => {
              ToastNotification({
                type: 'error',
                message: ToastMessageConstants.ENVIRONMENT.UPDATE.NAME.ERROR
              })
              reference.current?.stopLoading()
            })
        })
        .catch((error) => {
          reference.current?.stopLoading()
          if (error === StringConstants.NAME_ALREADY_TAKEN) {
            setHelperText(
              ValidationConstants.ENVIRONMENT.ENVIRONMENT_NAME_NOT_AVAILABLE
            )
          } else {
            ToastNotification({
              type: 'error',
              message: ToastMessageConstants.SOMETHING_WENT_WRONG
            })
          }
        })
    } else {
      setHelperText(validationResult.error.details[0].message)
    }
  }

  const tableColumns = [
    {
      title: 'Allowed Hosts',
      label: 'host',
      sortable: false,
      render: (item: any, fullItem: any) => {
        return (
          <TableHostNameContainer>
            <TypographyDDS.Paragraph
              variant='medium'
              size='para'
              color='textDark'
            >
              {tableData.length === 0 ? '*' : item}
            </TypographyDDS.Paragraph>
            {item === '*' && (
              <Tooltip
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: `${palette.colors.white}`,
                      boxShadow: `0 16px 25px ${palette.colors.tooltipShadow}`
                    }
                  }
                }}
                title={
                  <TypographyDDS.Paragraph size='para'>
                    (*) indicates that all hosts will be allowed
                  </TypographyDDS.Paragraph>
                }
                placement='right'
              >
                <div style={{ marginTop: '3px', marginLeft: '3px' }}>
                  <BsInfoCircle />
                </div>
              </Tooltip>
            )}
          </TableHostNameContainer>
        )
      }
    },
    {
      title: '',
      label: 'deleteButton',
      sortKey: 'actions',
      sortable: false,
      render: (item: any, fullItem: any) => (
        <TableDeleteButtonContainer>
          {(fullItem?.host !== '*' || tableData.length !== 1) && (
            <Button
              color='error'
              size='x-small'
              variant='contained'
              startIcon={
                <img
                  src={DeleteIcon}
                  style={{
                    width: '20px',
                    filter: palette.colors.whiteSvgFilter
                  }}
                />
              }
              onClick={() => {
                setDeleteDomainPopUp(true)
                setDeleteDomainDetails(fullItem)
              }}
              disabled={store.userStore.getNoActiveSubscription()}
            >
              Delete
            </Button>
          )}
        </TableDeleteButtonContainer>
      )
    }
  ]

  const deleteDomain = (data: any) => {
    setLoading(true)
    if (whiteListedHost.length == 1) whiteListedHost.push('*')
    const indexToDelete = whiteListedHost.findIndex(
      (item) => item === data.host
    )
    whiteListedHost.splice(indexToDelete, 1)
    updateEnvironment({
      whitelistedHosts: whiteListedHost,
      projectId,
      environmentId
    })
      .then((updatedEnvironment: any) => {
        setLoading(false)
        setDeleteDomainPopUp(false)
        setWhiteListedHost(updatedEnvironment.whitelistedHosts)
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.ENVIRONMENT.DELETE.WHITELIST_HOSTS
        })
      })
      .catch((error) => {
        ToastNotification({
          type: 'success',
          message:
            ToastMessageConstants.ENVIRONMENT.DELETE.WHITELIST_HOSTS_ERROR
        })
      })
  }

  const permanentlyDeleteEnvironment = () => {
    store.environmentStore.setLoading(true)
    setLoading(true)
    deleteEnvironment(projectId as string, environmentId as string)
      .then(() => {
        const newEnvs = store.environmentStore
          .getEnvironments()
          .filter((env) => env['id'] !== environmentId)
        store.environmentStore.setEnvironments([])

        setLoading(false)
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.ENVIRONMENT.DELETE.SUCCESS
        })
        if (newEnvs.length !== 0) {
          store.scopeStore.setEnvironmentId(newEnvs[0]['id'] as string)
          store.scopeStore.setSelectedEnvironment(
            newEnvs[0] as Record<string, string>
          )
          store.scopeStore.setEnvironments(newEnvs)

          //removing the deleted environment from scope store in selectedProject
          const selectedProject = store.scopeStore.getSelectedProject()
          const newEnvironments: any =
            store.scopeStore.getSelectedProject().environments
          const filteredEnvironments: any = newEnvironments?.filter(
            (item: any) => item.id != environmentId
          )

          store.scopeStore.setSelectedProject({
            ...selectedProject,
            environments: filteredEnvironments
          })

          //removing the deleted environment from scope store in allProject

          const newEnvironmentsAfterDeletion: any = store.scopeStore
            .getProjects()
            .find((project: any) => project.id === projectId)
            .environments.filter(
              (environment: any) => environment.id !== environmentId
            )
          const updatedProjectAfterDeletion = {
            ...store.scopeStore
              .getProjects()
              .find((project: any) => project.id === projectId),
            environments: newEnvironmentsAfterDeletion
          }

          store.scopeStore.setProjects([
            ...store.scopeStore
              .getProjects()
              .filter((project: any) => projectId !== project.id),
            updatedProjectAfterDeletion
          ])

          navigate(
            replaceUrl(
              routeConstants.PROJECT_SETTINGS_ENV,
              store.scopeStore.getOrganizationId() as string,
              '',
              ''
            ).replace(':projectId', projectId as string)
          )
        } else {
          store.scopeStore.setProjectId('')
          navigate(
            replaceUrl(
              routeConstants.PROJECT_SETTINGS,
              store.scopeStore.getOrganizationId() as string,
              '',
              ''
            )
          )
        }
      })
      .catch((error) => {
        setLoading(false)
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SOMETHING_WENT_WRONG
        })
        navigate(
          replaceUrl(
            routeConstants.PROJECT_SETTINGS_ENV,
            store.scopeStore.getOrganizationId() as string,
            '',
            ''
          ).replace(':projectId', projectId as string)
        )
      })
      .finally(() => {
        setEnvPopUp(false)
      })
  }

  useEffect(() => {
    if (store.breadcrumbStore.getBreadcrumbsOptions().length === 0) {
      store.breadcrumbStore.addMultipleBreadCrumbOptions([
        { label: 'Projects', link: routeConstants.PROJECT_SETTINGS },
        {
          label: store.scopeStore
            .getProjects()
            .filter((project) => project.id === projectId)[0]?.name as string,
          link: routeConstants.PROJECT_SETTINGS_GENERAL.replace(
            ':projectId',
            projectId as string
          )
        },
        {
          label: 'Environments',
          link: routeConstants.PROJECT_SETTINGS_ENV.replace(
            ':projectId',
            projectId as string
          )
        },
        {
          label: environmentName,
          link: '/'
        }
      ])
    }
    store.breadcrumbStore.setGoBackLink(
      routeConstants.PROJECT_SETTINGS_ENV.replace(
        ':pathIds',
        ('/' + BtoA(store.scopeStore.getOrganizationId() as string)) as string
      ).replace(':projectId', projectId as string)
    )
    return () => {
      store.breadcrumbStore.reset()
    }
  }, [
    store.breadcrumbStore.getBreadcrumbsOptions(),
    store.scopeStore.getSelectedProject(),
    store.scopeStore.getEnvironments()
  ])

  // TODO integrate delete confirmation popup
  return useObserver(() => (
    <>
      <DashboardSectionContainer
        headerText={
          name == StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
            ? StringConstants.ENVIRONMENT_NAMES.NON_PROD
            : StringConstants.ENVIRONMENT_NAMES.PROD
        }
        $loading={environmentTypeDisable}
      >
        {!loading ? (
          <>
            <HostTableContainer>
              <HostsContainerLeft>
                <TypographyDDS.Title
                  type='h5'
                  variant='bold'
                  color='textDark'
                  style={{ padding: '10px 0px 16px 0px' }}
                >
                  Hosts
                </TypographyDDS.Title>
                <ToolTipContainer>
                  <Tooltip
                    sx={{
                      maxWidth: '60%'
                    }}
                    PopperProps={{
                      sx: {
                        '& .MuiTooltip-tooltip': {
                          maxWidth: `60%`,
                          backgroundColor: `${palette.colors.white}`,
                          boxShadow: `0 16px 25px ${palette.colors.tooltipShadow}`
                        }
                      }
                    }}
                    title={
                      <TypographyDDS.Paragraph size='para'>
                        Specify origin URLs/IP addresses for Vigil to accept
                        events from, limiting requests from unauthorized
                        sources. Also configuring your backend server to prevent
                        IP address masking when using a proxy server ensures
                        Vigil can whitelist the correct IP address
                      </TypographyDDS.Paragraph>
                    }
                    placement='right'
                  >
                    <div style={{ marginTop: '5px', marginLeft: '3px' }}>
                      <BsInfoCircle />
                    </div>
                  </Tooltip>
                </ToolTipContainer>
              </HostsContainerLeft>
              <Button
                variant='contained'
                iconSize='12px'
                startIcon={
                  <img
                    src={plusIcon}
                    style={{
                      filter: palette.colors.whiteSvgFilter
                    }}
                  />
                }
                onClick={() => {
                  setAddDomainPopUp(true)
                }}
                disabled={store.userStore.getNoActiveSubscription()}
              >
                Add Hosts
              </Button>
            </HostTableContainer>
            <Divider />
            <Table
              currentPage={currentPage}
              rowsPerPage={5}
              totalCount={tableData.length}
              noPadding
              data={getData()}
              columns={tableColumns.map((column) => ({
                ...column
              }))}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </>
        ) : (
          <SpinnerWrapper className='spinner-wrapper'>
            <Spinner />
          </SpinnerWrapper>
        )}
      </DashboardSectionContainer>
      <TextConfirmationPopup
        open={envPopUp}
        handleClose={() => setEnvPopUp(false)}
        confirmationText={`Delete Environment ${environmentName}`}
        handleSubmit={permanentlyDeleteEnvironment}
        context='This environment will be deleted permanently'
        heading='Delete Environment'
        loading={loading}
      />
      {addDomainPopUp && (
        <Popup
          open={addDomainPopUp}
          handleClose={() => setAddDomainPopUp(false)}
          headerText='Add Hosts'
          width='60%'
          paddingTop='15px'
          loading={loading}
        >
          <AddDomainsCard
            whiteListedHost={whiteListedHost}
            onArrayGenerated={handleAddHosts}
            loading={loading}
          />
        </Popup>
      )}
      {deleteDomainPopUp && (
        <Popup
          open={deleteDomainPopUp}
          handleClose={() => setDeleteDomainPopUp(false)}
          headerText='Delete Host'
          width='50%'
          paddingTop='15px'
          height='250px'
          loading={loading}
          headerIcon={
            <img
              src={trashIcon}
              style={{ filter: palette.colors.redSvgFilter }}
            />
          }
        >
          <DeleteDomainsCard
            loading={loading}
            domainData={deleteDomainDetails}
            handleCancel={() => setDeleteDomainPopUp(false)}
            handleDelete={(data) => deleteDomain(data)}
          />
        </Popup>
      )}
    </>
  ))
}

export default observer(ProjectEnvironmentCard)
