export default {
  COOKIE_TOKEN: 'token',
  NAME_ALREADY_TAKEN: 'Name already taken',
  SLUG_ALREADY_TAKEN: 'Slug already taken',
  INSTANCE_ALREADY_CONNECTED: 'Instance already connected',
  COPY_MESSAGE: 'Copy',
  COPIED_MESSAGE: 'Copied',
  ORGANIZATION: 'Organizations',

  DATE_TIME_FORMAT: {
    DATE_ALONE: 'YYYY-MM-DD',
    DATE_TIME: 'YYYY-MM-DD hh:mm:ss'
  },
  DEFAULT_TABLE_SIZE: 10,
  SECONDARY_TABLE_SIZE: 7,
  AHC_TABLE_SIZE: 5,
  DEFAULT_PAGE: 1,
  DEFAULT_TAB: 0,
  AHC_DATE_RANGE_LIMIT: -90,
  HOST_TABLE_PAGE_SIZE: 5,

  NAME_FIELD_MAX_LENGTH: 30,
  SLUG_FIELD_MAX_LENGTH: 60,
  DELETE_POPUP_NAME_FIELD: 30,
  DESCRIPTION_FIELD_MAX_LENGTH: 250,
  URL_MAX_LENGTH_TABLE_DISPLAY: 30,
  URL_MAX_LENGTH_FRAGMENT_DISPLAY: 80,
  VIGIL_WEBSITE_LINK: 'https://vigilforsalesforce.com/',
  URL_PATH_PRIVACY_POLICY: 'privacy-policy',
  URL_PATH_TERMS_OF_SERVICE: 'terms-of-service',
  VIGIL_SDK_URL:
    'https://www.npmjs.com/package/@foxsenseinnovations/vigil-sdk-js',
  VIGIL_SDK_REFERENCE_URL:
    'https://dvackjd4qepg8.cloudfront.net/things-to-do/Vigil+-+SDK.pdf',
  VIGIL_SALESFORCE_URL:
    'https://vigilforsalesforce.com/guide-get-started-with-vigil/',
  VIGIL_MULESOFT_URL:
    'https://dvackjd4qepg8.cloudfront.net/things-to-do/Vigil+-+Mulesoft+Integration+Doc+1.0.pdf',
  OTHERS: 'Others',

  LOCAL_STORAGE: {
    CURRENT_PAGE_DETAILS: 'current_page_details',
    BANNER_CLOSED_FLAG: 'bannerClosed',
    NEW_DESIGN_SYSTEM_FLAG: 'newDesignSystem'
  },

  OVERVIEW_HEADINGS: {
    SALESFORCE_OVERVIEW_HEADING: {
      ERROR_MANAGEMENT: 'Error Management',
      UPTIME_STATUS: 'Uptime Status',
      APEX_JOB_STATUS: 'APEX Job Status'
    },
    FULL_STACK_OVERVIEW_HEADING: {
      EXCEPTION_MANAGEMENT: 'Exception Management',
      JOB_STATUS: 'Job Status',
      UPTIME_STATUS: 'Uptime Status',
      API_STATUS: 'API Status'
    }
  },

  SIDE_NAV_BAR: {
    SALESFORCE_PROJECTS: {
      OVERVIEW: 'Overview',
      ERROR: 'Errors',
      UPTIME_CHECKS: 'Uptime Checks',
      APEX_JOBS: 'APEX Jobs',
      ESCALATIONS: 'Escalations',
      GROUPED_ERRORS: 'Grouped Errors',
      SETTINGS: 'Settings',
      APEX_JOB: 'APEX Job'
    },
    FULL_STACK_PROJECTS: {
      OVERVIEW: 'Overview',
      EXCEPTIONS: 'Exceptions',
      UPTIME_CHECKS: 'Uptime Checks',
      SCHEDULED_JOBS: 'Scheduled Jobs',
      API_MONITORING: 'API Monitoring',
      ESCALATIONS: 'Escalations',
      SETTINGS: 'Settings',
      PROJECT_SETTINGS: 'Project Settings',
      GET_STARTED: 'Get Started',
      NOTIFICATIONS: 'Notifications',
      INVITATIONS: 'Invitations',
      GROUPED_EXCEPTION: 'Grouped Exceptions',
      INCIDENT: 'Incidents',
      VIGIL_PARTNER: 'My Vigil Partner',
      ACCOUNT_SETTING: 'Account Setting',
      SCHEDULED_JOB: 'Scheduled Job'
    }
  },
  NO_PROJECT: {
    NOT_IN_PROJECT: 'You are not part of any project',
    CONTACT_OWNER:
      'Contact your organization owner or  check your notification for any invitations'
  },

  THINGS_TO_DO: {
    SALESFORCE_ECOSYSTEM_PROJECT_PROGRESS_VALUE: 7, //15 for org
    FULLSTACK_PROJECT_PROGRESS_VALUE: 31 //63 for org
  },

  SUPPORT_EMAIL: 'support@vigilnow.freshdesk.com',
  ACTIVE_TAB: 'activeTab',
  ENVIRONMENT_TYPE: 'EnvironmentType',
  ENVIRONMENT_TYPE_VALUES: {
    NON_PROD: 'NON_PROD',
    PROD: 'PROD'
  },
  ENVIRONMENT_NAMES: {
    NON_PROD: 'Non Prod',
    PROD: 'Prod'
  },

  TAB_VALUES: {
    OVERVIEW: '1',
    EXCEPTION: '2',
    API_MONITORING: '3',
    UPTIME_CHECKS: '4',
    ESCALATIONS: '5',
    INCIDENT: '6',
    JOB_MONITORING: '7',
    SALESFORCE_AUDIT: '8',
    SETTINGS: '9',
    GETTING_STARTED: '10'
  },
  NO_RECORDS_FILTER: 'No results found for applied filter.',
  NO_RECORDS: 'No records found',
  COLLAPSE: 'Collapse',

  NO_DATA: {
    FULL_STACK: {
      SCHEDULED_JOBS: 'Start monitoring cron jobs'
    },
    SALES_FORCE: {
      APEX_JOBS: 'Start monitoring APEX jobs'
    }
  },
  NEED_HELP_SECTION: {
    USER_DOCUMENTATION: 'User Documentation',
    CONTACT_SUPPORT: 'Contact Support',
    NEED_HELP: 'Need Help'
  },
  NOTIFICATION_PREFERENCE_DELETE_POPUP: {
    DISABLE_TOGGLE:
      ' Disabling the toggle prevents notification messages from being sent in the'
  }
}
