import React, { useEffect } from 'react'
import {
  addComment,
  getAllCommentsOfIncident,
  getAllCommentsOfIncidentWithoutAuthorization
} from '../../api/incident/incident.service'
import { isArray, isEmpty } from 'lodash'

import Button from '../common/Button/Button'
import DividerLine from '../common/DividerLine/DividerLine'
import IncidentCommentSection from './IncidentCommentSection'
import Spinner from '../common/Spinner/Spinner'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../common/DDS/Toast/Toast'
import { observer } from 'mobx-react-lite'
import palette from '../../global/pallete'
import styled from '@emotion/styled'
import { timeFormats } from '../../enum/TIME'
import { timeZone } from '../../utils/DateTimeUtils'
import { useParams } from 'react-router-dom'
import { useStoreContext } from '../../store/StoreContext'

interface ParaProps {
  padding?: string
  lineHeight?: number
  fontSize?: string
}

const Container = styled.div`
  padding: 25px 30px 15px 30px;
  margin-top: ${palette.padding.size};
  margin-left: ${palette.padding.halfSize};
  margin-bottom: ${palette.padding.size};
  border-radius: 12px;
  border: 1px solid ${palette.colors.borderColor};
  transition: box-shadow 0.2s;
  background-color: ${palette.colors.lightBack4};
  &:hover {
    box-shadow: 0px 4px 25px rgba(71, 78, 93, 0.05);
    transition: box-shadow 0.2s;
  }
  height: 600px;
`
const Header = styled.div``

const PostCommentSection = styled.div`
  background-color: ${palette.colors.lightBack};
  border: 1px solid ${palette.colors.borderColor};
  border-radius: 8px;
  padding: 8px 8px;
  textarea {
    outline: none;
    width: 100%;
    font-size: 16px;
    border: none;
    resize: none;
    padding: 8px 8px;
    background-color: ${palette.colors.lightBack};
  }
`
const PostCommentSectionBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
`

const ReplySection = styled.div`
  padding-top: 10px;
`

const ParaDark = styled.div<ParaProps>`
  padding: ${(props) => props.padding};
  line-height: ${(props) => props.lineHeight};
  font-weight: 600;
  font-size: ${(props) => props.fontSize};
  color: ${palette.colors.textDark};
`
const ActivitySectionWrapper = styled.div`
  height: 500px;
  overflow: scroll;
`
const ActivityWrapper = styled.div`
  margin: 0.2em;
`

const IncidentActivitySection = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [comment, setComment] = React.useState('')
  const { exceptionLogId, incidentId } = useParams()
  const store = useStoreContext()

  useEffect(() => {
    store.exceptionIncidentStore.getIsPublic()
      ? getAllActivityForIncidentWithoutAuthorization_()
      : getAllActivityForIncident_()
  }, [])

  const addCommentForIncident_ = () => {
    // store.exceptionIncidentStore.setLoading(true)
    setLoading(true)
    addComment({
      $exceptionLogId: exceptionLogId,
      $incidentId: incidentId,
      comment
    })
      .then(() => {
        setLoading(false)
        getAllActivityForIncident_()
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.EXCEPTIONS.ADD_A_COMMENT.SUCCESS
        })
      })
      .catch((err: any) => {
        setLoading(false)
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.EXCEPTIONS.ADD_A_COMMENT.ERROR
        })
      })
  }

  const getAllActivityForIncident_ = () => {
    // store.exceptionIncidentStore.setLoading(true)
    setLoading(true)
    getAllCommentsOfIncident({
      $exceptionLogId: exceptionLogId,
      $incidentId: incidentId
    })
      .then((response: any) => {
        store.exceptionIncidentStore.setActivityLogs(response.data)
      })
      .catch((err) => {
        console.log('Error while fetching incident comments :: ', err)
      })
      .finally(() => {
        // store.exceptionIncidentStore.setLoading(false)
        setLoading(false)
      })
  }

  const getAllActivityForIncidentWithoutAuthorization_ = () => {
    setLoading(true)
    getAllCommentsOfIncidentWithoutAuthorization({
      $exceptionLogId: store.exceptionIncidentStore.getExceptionLog().id,
      $incidentId: store.exceptionIncidentStore.getId()
    })
      .then((response: any) => {
        store.exceptionIncidentStore.setActivityLogs(response.data)
      })
      .catch((err) => {
        console.log('Error while fetching incident comments :: ', err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Container>
      <Header>
        <ParaDark>Activity</ParaDark>
      </Header>
      <DividerLine margin='20px 0px' />
      <ActivitySectionWrapper>
        {!loading ? (
          <ActivityWrapper>
            {store.exceptionIncidentStore.getIsPublic() &&
            store.exceptionIncidentStore.getActivityLogs().length === 0 ? (
              <PostCommentSection>
                <textarea
                  style={{ fontSize: '16px' }}
                  placeholder={'No comments available at the moment.'}
                  rows={4}
                  disabled={true}
                />
              </PostCommentSection>
            ) : (
              !store.exceptionIncidentStore.getIsPublic() && (
                <PostCommentSection>
                  <textarea
                    style={{ fontSize: '16px' }}
                    placeholder={'Post a comment'}
                    rows={4}
                    onChange={(e: any) => {
                      setComment(e.target.value)
                    }}
                    onBlur={(e: any) => {
                      setComment(e.target.value)
                    }}
                  />
                  <DividerLine margin='10px 0px' />
                  <PostCommentSectionBottom>
                    <span>
                      <Button
                        variant='contained'
                        boxShadow={false}
                        width='140px'
                        onClick={() => {
                          if (!isEmpty(comment)) {
                            addCommentForIncident_()
                          } else {
                            ToastNotification({
                              type: 'error',
                              message:
                                ToastMessageConstants.EXCEPTIONS.ADD_A_COMMENT
                                  .ERROR
                            })
                          }
                        }}
                        disabled={isEmpty(comment)}
                      >
                        Post Comment
                      </Button>
                    </span>
                  </PostCommentSectionBottom>
                </PostCommentSection>
              )
            )}
            <ReplySection>
              {isArray(store.exceptionIncidentStore.getActivityLogs()) &&
                store.exceptionIncidentStore
                  .getActivityLogs()
                  .map((item: any, index: any) => (
                    <div key={index}>
                      <>
                        <IncidentCommentSection
                          userName={
                            item.actionedByUser.firstName +
                            ' ' +
                            item.actionedByUser.lastName
                          }
                          avatar={item.actionedByUser.avatar}
                          replies={item.threads.map((reply: any) => ({
                            userName:
                              reply.actionedByUser.firstName +
                              ' ' +
                              reply.actionedByUser.lastName,
                            date: timeZone(
                              store.exceptionIncidentStore.getIsPublic()
                                ? Intl.DateTimeFormat().resolvedOptions()
                                    .timeZone
                                : store.userStore.getUserPreference().timeZone,
                              item.createdAt,
                              timeFormats.monthDateYear
                            ),
                            reply: reply.message,
                            id: reply.id,
                            avatar: reply.actionedByUser.avatar,
                            email: reply.actionedByUser.email
                          }))}
                          commentId={item.id}
                          date={item.createdAt}
                          comment={item.message}
                          email={item.actionedByUser.email}
                          fetchAllActivities={
                            store.exceptionIncidentStore.getIsPublic()
                              ? getAllActivityForIncidentWithoutAuthorization_
                              : getAllActivityForIncident_
                          }
                          addLoading={(load: boolean) => setLoading(load)}
                        />
                        <DividerLine margin='0px 20px' width='96%' />
                        <br />
                      </>
                    </div>
                  ))}
            </ReplySection>
          </ActivityWrapper>
        ) : (
          <Spinner />
        )}
      </ActivitySectionWrapper>
    </Container>
  )
}

export default observer(IncidentActivitySection)
