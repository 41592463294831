import { Autocomplete, Grid, TextField, Tooltip } from '@mui/material'
import React, { createRef, useImperativeHandle, useRef, useState } from 'react'

import Chip from '../Chip/Chip'
import { RefObject } from './Interface/Interface'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

interface MultiTextInputFieldProps extends TextFieldProps {
  onChange?: (value: any) => void
  value: any
  onDeleteSelection?: (option: any) => void
  fullWidth?: boolean
  onBlur?: (value: any) => void
  placeholder?: string
  label?: string
  onTextChange?: (value: string) => void
  validationError?: false | string
  singleSelect?: boolean
  disableClearable?: boolean
  variant?: 'normal' | 'small'
  loading?: boolean
  blurOnSelect?: boolean
  loadingOption?: boolean
  width?: string
  backgroundColor?: string
  hoverBackgroundColor?: string
  disableDelete?: boolean
  helperText?: string
  error?: boolean
  disabled?: boolean
}

interface TextFieldProps {
  paddingtop?: string
  paddingbottom?: string
  border?: string
}

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop: string) => !prop.startsWith('$'),
})(({ hoverBackground, backgroundColorForTextField, ...rest }: any) => ({
  '&.MuiFilledInput-input': {
    paddingTop: '1.6em',
    paddingBottom: '0.625em',

    '&.Mui-disabled': {
      WebkitTextFillColor: 'black',
      cursor: 'not-allowed',
    },
  },
  '.MuiFilledInput-root': {
    borderRadius: '8px',
    border: `1px solid ${palette.colors.borderColor}`,
    background: backgroundColorForTextField,
    '&.Mui-focused': {
      background: hoverBackground,
    },
    '&:hover': {
      background: hoverBackground,
    },
    paddingBottom: '8px',
    paddingTop: '20px',

    '&.Mui-disabled': {
      background: ` ${palette.colors.disabledInputBackground} !important`,
      '&:hover': {
        backgroundColor: ` ${palette.colors.disabledInputBackground} !important`,
      },
      WebkitTextFillColor: 'black',
      cursor: 'not-allowed',
    },
  },
}))

const StyledAutoComplete = styled(Autocomplete)((props: any) => ({
  '& input::placeholder': {
    fontSize: '0.9em',
  },
  '&.MuiFilledInput-input': {
    paddingTop: '1.6em',
  },
  '&.MuiAutocomplete-root': {
    border: `0px`,
  },
  '.MuiFilledInput-root': {
    minHeight: '61.5px',

    ':before': {
      border: '0px',
    },
    '&:hover': {
      ':before': {
        border: '0px',
      },
    },
  },
  '.MuiInputLabel-root': {
    fontSize: '0.9em',
    fontWeight: palette.fontWeight.medium,
    lineHeight: '17px',
    color: `${palette.colors.textDark2}`,
    '&.Mui-error': {
      color: `${palette.colors.error} !important`,
    },
  },
  '.MuiAutocomplete-endAdornment': {
    pointerEvents: props.options.length > 0 ? 'all' : 'none',
    opacity: props.options.length > 0 ? 1 : 0.4,
  },
  '.MuiAutocomplete-clearIndicator': {
    pointerEvents: 'all',
  },
}))

const MultiTextInputField = React.forwardRef<
  RefObject,
  MultiTextInputFieldProps
>(
  (
    {
      onChange = () => null,
      value,
      onDeleteSelection = () => null,
      placeholder = '',
      label = '',
      onBlur = () => null,
      onTextChange = () => null,
      variant = 'normal',
      width = '100%',
      backgroundColor = palette.colors.lightBack,
      hoverBackgroundColor = palette.colors.backgroundLight,
      helperText = '',
      error = false,
      disabled = false,
    },
    ref
  ) => {
    const [loading, setLoading] = useState<boolean>(false)

    const inputRef = createRef<any>()
    const autoCompleteRef = createRef<any>()

    useImperativeHandle(ref, () => ({ startLoading, stopLoading }))
    const startLoading = () => {
      setLoading(true)
    }
    const stopLoading = () => {
      setLoading(false)
    }

    return (
      <StyledAutoComplete
        width={width}
        multiple
        id='tags-filled'
        options={[]}
        freeSolo
        value={value}
        onBlur={onBlur}
        clearOnBlur
        onChange={(event: any, value: any) => {
          onChange(value)
        }}
        isOptionEqualToValue={(option: any, value: any) => {
          return option.toLowerCase() === value.toLowerCase()
        }}
        ref={autoCompleteRef}
        tabIndex={0}
        disabled={disabled}
        sx={
          variant === 'small'
            ? {
                '.MuiFilledInput-root': {
                  height: '40px !important',
                  paddingTop: '10px !important',
                  paddingBottom: '0px !important',
                },
              }
            : {}
        }
        renderTags={(value: any) => (
          <Grid container spacing={1} mt={1}>
            {value.map((option: any, index: number) => (
              <Tooltip title={option} key={index}>
                <Grid item xs={6}>
                  <Chip
                    size='small'
                    variant='outlined'
                    label={option}
                    onDelete={() => {
                      autoCompleteRef.current.focus()
                      onDeleteSelection(option)
                    }}
                  />
                </Grid>
              </Tooltip>
            ))}
          </Grid>
        )}
        renderInput={(params: any) => (
          <StyledTextField
            {...params}
            variant='filled'
            hoverBackground={hoverBackgroundColor}
            backgroundColorForTextField={backgroundColor}
            label={label}
            placeholder={placeholder}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target }: any) => {
              onTextChange(target.value)
            }}
            helperText={helperText}
            error={error}
            ref={inputRef}
          />
        )}
      />
    )
  }
)
MultiTextInputField.displayName = 'MultiTextInputField'

export default MultiTextInputField
