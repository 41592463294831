import {
  getUserDetails,
  updateUserPreference
} from '../../api/user/User.service'

import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import { BsInfoCircle } from 'react-icons/bs'
import DashboardSectionContainer from '../../components/common/DashboardSectionContainer/DashboardSectionContainer'
import DashboardTopNavbar from '../../components/common/DashboardTopNavbar/DashboardTopNavbar'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import Dropdown from '../../components/common/Dropdown/Dropdown'
import { HeaderConstants } from '../../constants/HeaderConstants'
import React from 'react'
import { RefObject } from '../../components/common/InputField/Interface/Interface'
import SecureComponent from '../../components/Layout/SecureComponent'
import { SettingBottomBar } from '../../components/common/SettingBottomBar/SettingBottomBar'
import SettingsBar from '../../components/common/DashboardTopNavbar/bottomBars/Settings'
import StringConstants from '../../constants/StringConstants'
import TimezoneConstants from '../../constants/TimezoneConstants'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../../components/common/DDS/Toast/Toast'
import { Tooltip } from '@mui/material'
import { TypographyDDS } from '../../components/common/Typography/TypographyDDS'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import palette from '../../global/pallete'
import routeConstants from '../../constants/RouteConstants'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../store/StoreContext'

interface ParaProps {
  padding?: string
  lineHeight?: number
  fontSize?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const IndividualSettingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 5px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const SettingsLeft = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  @media screen and (max-width: 600px) {
    width: 80%;
    margin-bottom: 15px;
  }
`

const SettingsRight = styled.div`
  min-width: 30%;
  direction: ltr;
  @media screen and (max-width: 600px) {
    width: 80%;
  }
`

const DropdownOptions = [
  { id: 1, name: 'English-India', value: 'en_IN' },
  { id: 2, name: 'English-United States', value: 'en_US' },
  { id: 3, name: 'English-United Kingdom', value: 'en_GB' },
  { id: 4, name: 'English-Australia', value: 'en_AU' }
]

const TimezoneDropdownOptions = TimezoneConstants.map(
  (timezoneConstant, index) => {
    return {
      id: index,
      name:
        timezoneConstant.tzCode.replaceAll('_', ' ') +
        timezoneConstant.label.substring(0, 11),

      value: timezoneConstant.tzCode
    }
  }
)

export const languageValueToName = (userPreference: any) => {
  for (let i = 0; i < DropdownOptions.length; i++) {
    if (userPreference.language === DropdownOptions[i].value)
      userPreference.language = DropdownOptions[i].name
  }
  return userPreference.language
}

const PreferenceSettingsV1 = () => {
  const referenceForTimezoneDropdown = React.useRef<RefObject>(null)
  const store = useStoreContext()
  const [getPreferences, setPreferences] = React.useState<any>({})

  const navigate = useNavigate()
  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.SETTINGS
    )
    getUserDetails().then((userResponse: any) => {
      const organizationIndex = userResponse.data.organizations.findIndex(
        (org: any) => {
          return org.id === store.userStore.getSelectedOrganization().id
        }
      )

      if (organizationIndex === -1) {
        navigate(routeConstants.UNAUTHORIZED_PAGE)
      }
    })
  }, [])

  useEffect(() => {
    store.uiStore.setGlobalLoader(false)
  }, [store.uiStore.getGlobalLoader()])

  useEffect(() => {
    setPreferences(store.userStore.getUserPreference())
  }, [store.userStore.getUserPreference()])

  const handleTimezoneChange = (selectedTimezoneSelection: any) => {
    const timezoneSelection = TimezoneDropdownOptions.find(
      (item) => item.id === selectedTimezoneSelection
    )
    if (timezoneSelection) {
      referenceForTimezoneDropdown.current?.startLoading()
      updateUserPreference({
        timeZone: timezoneSelection.value
      })
        .then((userPreference: any) => {
          setPreferences({
            ...getPreferences,
            timeZone: timezoneSelection.value
          })
          store.userStore.setUserPreference(userPreference.data)
          ToastNotification({
            type: 'success',
            message: ToastMessageConstants.PREFERENCE.UPDATE_PREFERENCE.SUCCESS
          })
        })
        .catch((err) => {
          setPreferences(store.userStore.getUserPreference())
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.PREFERENCE.UPDATE_PREFERENCE.ERROR
          })
        })
        .finally(() => referenceForTimezoneDropdown.current?.stopLoading())
    }
  }

  return (
    <Container>
      <DocumentHeader header={HeaderConstants.PREFERENCE_SETTINGS} />
      {!isEmpty(store.userStore.getUserPreference()) && (
        <>
          {/* <DashboardTopNavbar
            variant='accountSettings'
            showBottomBar
            isPreferenceSettings
          /> */}
          <SettingBottomBar>
            <SettingsBar showButtons={false} />
          </SettingBottomBar>
          <DashboardSectionContainer headerText='Language and Time'>
            <IndividualSettingContainer>
              <SettingsLeft>
                <TypographyDDS.Title variant='bold' type='h5'>
                  Language
                </TypographyDDS.Title>
                <Tooltip
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: `${palette.colors.white}`,
                        boxShadow: `0 1em 1.5625em ${palette.colors.tooltipShadow}`
                      }
                    }
                  }}
                  title={
                    <TypographyDDS.Paragraph
                      size='caption'
                      variant='semiBold'
                      style={{ textAlign: 'center' }}
                    >
                      Select your preferred language
                    </TypographyDDS.Paragraph>
                  }
                  placement='right'
                >
                  <div style={{ marginTop: '5px' }}>
                    <BsInfoCircle />
                  </div>
                </Tooltip>
              </SettingsLeft>
              <SettingsRight>
                <Dropdown
                  title='Select Language'
                  disabled={true}
                  defaultSelected={{
                    name: languageValueToName(
                      store.userStore.getUserPreference()
                    )
                  }}
                  // height='40px'
                  dropdownOptions={DropdownOptions}
                  variant='default'
                  handleChange={(languageSelection) => {
                    languageSelection = DropdownOptions.find(
                      (item) => item.id === languageSelection
                    )
                    updateUserPreference({
                      language: languageSelection.value
                    }).then((userPreference: any) => {
                      store.userStore.setUserPreference(userPreference.data)
                    })
                  }}
                />
              </SettingsRight>
            </IndividualSettingContainer>
            <IndividualSettingContainer>
              <SettingsLeft>
                <TypographyDDS.Title type='h5' variant='bold'>
                  Timezone
                </TypographyDDS.Title>
                <Tooltip
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: `${palette.colors.white}`,
                        boxShadow: `0 1em 1.5625em ${palette.colors.tooltipShadow}`,
                        fontSize: 11
                      }
                    }
                  }}
                  title={
                    <TypographyDDS.Paragraph
                      size='caption'
                      variant='semiBold'
                      style={{ textAlign: 'center' }}
                    >
                      Select your preferred timezone
                    </TypographyDDS.Paragraph>
                  }
                  placement='right'
                >
                  <div style={{ marginTop: '5px' }}>
                    <BsInfoCircle />
                  </div>
                </Tooltip>
              </SettingsLeft>
              <SettingsRight>
                <Dropdown
                  title='Timezone'
                  // height='40px'
                  disabled={store.userStore.getNoActiveSubscription()}
                  defaultSelected={{
                    name:
                      !isEmpty(getPreferences.timeZone) &&
                      getPreferences.timeZone.replaceAll('_', ' ') +
                        TimezoneConstants.find(
                          (timeZone) =>
                            timeZone.tzCode === getPreferences.timeZone
                        )?.label.substring(0, 11)
                  }}
                  dropdownOptions={TimezoneDropdownOptions}
                  isSearchable
                  variant='default'
                  handleChange={(timezoneSelection) =>
                    handleTimezoneChange(timezoneSelection)
                  }
                  ref={referenceForTimezoneDropdown}
                />
              </SettingsRight>
            </IndividualSettingContainer>
          </DashboardSectionContainer>
        </>
      )}
    </Container>
  )
}

export default SecureComponent(
  observer(PreferenceSettingsV1),
  AccessRoleConstants.ALL
)
