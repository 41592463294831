export enum OrganizationType {
  SALESFORCE_ECOSYSTEM_ONLY = 'SALESFORCE_ECOSYSTEM_ONLY',
  FULL_STACK_ONLY = 'FULL_STACK_ONLY',
  HYBRID = 'HYBRID'
}

export enum OrganizationTypeDisplayNames {
  SALESFORCE_ECOSYSTEM_ONLY = 'Only Salesforce ecosystem',
  FULL_STACK_ONLY = 'Only Full-stack apps',
  HYBRID = 'Both Salesforce ecosystem and Full-stack apps'
}
