import 'react-toastify/dist/ReactToastify.css'

import { AiFillStar, AiOutlineCloseCircle } from 'react-icons/ai'

import Button from '../../components/common/Button/Button'
import ButtonGroup from '../../components/common/ButtonGroup/ButtonGroup'
import CheckboxGroup from '../../components/common/Checkboxes/Checkbox'
import Chip from '../../components/common/Chip/Chip'
import CustomDropdown from '../../components/common/Dropdown/CustomDropdown'
import CustomErrorToast from '../../components/common/Toast/CustomErrorToast'
import CustomToggle from '../../components/common/ToggleButtons/ToggleButtons'
import { DDSToastNotification } from 'den-design-system'
import DashboardSectionContainer from '../../components/common/DashboardSectionContainer/DashboardSectionContainer'
import DateRangePicker from '../../components/common/DateRangeSelector/DateRangePicker/DateRangePicker'
import DateRangeSelector from '../../components/common/DateRangeSelector/DateRangeSelector'
import DividerLine from '../../components/common/DividerLine/DividerLine'
import Dropdown from '../../components/common/Dropdown/Dropdown'
import Filter from '../../components/Filter/Filter'
import { Grid } from '@mui/material'
import InputField from '../../components/common/InputField/InputField'
import Notification from '../../components/common/Notification/Notification'
import Popup from '../../components/common/Popup/Popup'
import PricingCard from '../../components/common/PricingCard/PricingCard'
import React from 'react'
import SearchField from '../../components/common/InputField/SearchField'
import TabGroup from '../../components/common/TabGroup/TabGroup'
import Table from '../../components/common/Table/Table'
import TimeInputStepper from '../../components/common/DateRangeSelector/TimeInputStepper/TimeInputStepper'
import ToastNotification from '../../components/common/DDS/Toast/Toast'
import Typography from '../../components/common/Typography/Typography'
import { TypographyDDS } from '../../components/common/Typography/TypographyDDS'
import UnsecureComponent from '../../components/Layout/UnsecureComponent'
import palette from '../../global/pallete'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'

interface ParaProps {
  padding?: string
  lineHeight?: number
  fontSize?: string
  smallFontSize?: string
  weight?: number
}

const PageSection = styled.div`
  background-color: ${palette.colors.white};
  overflow: overlay;
  height: 100vh;
`

const Container = styled.div`
  padding: 20px 40px;

  border-bottom: 1px solid black;
`

const AttachmentFailed = styled.div`
  color: #e92828;
  font-weight: 700;
`

const ElementContainer = styled.div``
const Item = styled.div`
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  padding: 20px;
`

const ParaLight = styled.p<ParaProps>`
  padding: ${(props) => props.padding};
  line-height: ${(props) => props.lineHeight};
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.fontSize};
  color: ${palette.colors.textDark2};
  &:hover {
    color: ${palette.colors.textDark};
  }
`

const ParaDark = styled.div<ParaProps>`
  padding: ${(props) => props.padding};
  line-height: ${(props) => props.lineHeight};
  font-weight: ${(props) => props.weight};

  font-size: ${(props) => props.fontSize};
  color: ${palette.colors.textDark};
`
const data = [
  {
    url: 'http: localhost300/api1',
    responseStatus: '400',
    createdBy: 'Aman',
    startTime: '19/04/2022 22:00',
    endTime: '19/04/2022 22:00',
    recordDetails: '/'
  },
  {
    url: 'http: localhost300/api2',
    responseStatus: '402',
    createdBy: 'Shivam',
    startTime: '19/04/2022 22:00',
    endTime: '19/04/2022 22:00',
    recordDetails: '/'
  },
  {
    url: 'http: localhost300/api3',
    responseStatus: '400',
    createdBy: 'Aman',
    startTime: '19/04/2022 22:00',
    endTime: '19/04/2022 22:00',
    recordDetails: '/'
  },
  {
    url: 'http: localhost300/api4',
    responseStatus: '400',
    createdBy: 'Aman',
    startTime: '19/04/2022 22:00',
    endTime: '19/04/2022 22:00',
    recordDetails: '/'
  },
  {
    url: 'http: localhost300/api5',
    responseStatus: '400',
    createdBy: 'Aman',
    startTime: '19/04/2022 22:00',
    endTime: '19/04/2022 22:00',
    recordDetails: '/'
  },
  {
    url: 'http: localhost300/api6',
    responseStatus: '400',
    createdBy: 'Aman',
    startTime: '19/04/2022 22:00',
    endTime: '19/04/2022 22:00',
    recordDetails: '/'
  }
]

const tableColumns = [
  {
    title: 'URL',
    label: 'url',
    render: (item: any) => item
  },
  {
    title: 'Response Status',
    label: 'responseStatus',
    render: (item: any) => item
  },
  {
    title: 'Created By',
    label: 'createdBy',
    render: (item: any) => item
  },
  {
    title: 'Start Time',
    label: 'startTime',
    render: (item: any) => item
  },
  {
    title: 'End Time',
    label: 'endTime',
    render: (item: any) => item
  },
  {
    title: '',
    label: 'recordDetails',
    render: (item: any) => (
      <Button
        variant='contained'
        fontSize='12px'
        onClick={() => {
          console.log('Click Action')
        }}
      >
        View Record
      </Button>
    )
  }
]

const CustomDropdownOption = [
  { id: 1, name: 'Intern 1' },
  { id: 2, name: 'Intern 2' },
  { id: 3, name: 'Intern 3' }
]

const PricingCardBenefits = [
  '24/7 Coverage',
  'Value for Money',
  'High Performance'
]

const DropdownOptions = [
  {
    id: 1,
    name: 'Admin',
    secondaryText:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, ex assumenda iste inventore quaerat commodi alias? Consectetur repellendus inventore dignissimos.'
  },
  {
    id: 2,
    name: 'Manager',
    secondaryText:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, ex assumenda iste inventore quaerat commodi alias? Consectetur repellendus inventore dignissimos.'
  },
  { id: 3, name: 'Super Admin' }
]
const AddFilterOptions = [
  {
    id: 1,
    item: 'Tags',
    label: 'Tags',
    isExpandable: true,
    secondaryBoxComponent: <h2>Tags</h2>,
    onClick: () => console.log('Harsh Rana')
  },
  {
    id: 2,
    item: 'Status',
    label: 'Status',
    isExpandable: true,
    secondaryBoxComponent: <h2>Status</h2>
  },
  {
    id: 3,
    item: 'Is Bookmarked',
    label: 'Is Bookmarked',
    isExpandable: true,
    secondaryBoxComponent: <h2>Is Bookmarked</h2>
  },
  {
    id: 4,
    item: 'Is Public',
    label: 'Is Public',
    isExpandable: false,
    secondaryBoxComponent: <h2>Is Public</h2>
  },
  {
    id: 5,
    item: 'Notification Frequency',
    label: 'Notification Frequency',
    isExpandable: true,
    secondaryBoxComponent: (
      <>
        <h1>Option</h1>
        <h2>Notification Frequencyyyyyy</h2>
      </>
    )
  },
  {
    id: 6,
    item: 'Severity',
    label: 'Severity',
    isExpandable: true,
    secondaryBoxComponent: <h2>Severity</h2>
  }
]

const ButtonGroupItems = [
  { id: 1, title: 'General Settings' },
  { id: 2, title: 'Members' },
  { id: 3, title: 'Environments' }
]

const CurrentNotifications = [
  {
    id: 1,
    iconUrl: '',
    title: 'Harsh marked Type1 excpetion as resolved.',
    date: '12th May 22'
  },
  {
    id: 2,
    iconUrl: '',
    title: 'Aman marked Type1 excpetion as resolved.',
    date: '12th May 22'
  },
  {
    id: 3,
    iconUrl: '',
    title: 'Hridesh marked Type1 excpetion as resolved.',
    date: '12th May 22'
  },
  {
    id: 4,
    iconUrl: '',
    title: 'Shivam marked Type1 excpetion as resolved.',
    date: '12th May 22'
  },
  {
    id: 5,
    iconUrl: '',
    title: 'Nagendra marked Type1 excpetion as resolved.',
    date: '12th May 22'
  },
  {
    id: 6,
    iconUrl: '',
    title: 'Nagendra marked Type1 excpetion as resolved.',
    date: '12th May 22'
  },
  {
    id: 7,
    iconUrl: '',
    title: 'Nagendra marked Type1 excpetion as resolved.',
    date: '12th May 22'
  },
  {
    id: 8,
    iconUrl: '',
    title: 'Nagendra marked Type1 excpetion as resolved.',
    date: '12th May 22'
  }
]

const Stylesheet = () => {
  const [popupOpen, setPopupOpen] = React.useState(false)
  const [confirmationPopupOpen, setConfirmationPopupOpen] =
    React.useState(false)

  const [chipExtra, setChipExtra] = React.useState<
    { email: null | string; role: string | null }[]
  >([
    { email: 'chinmay@foxsense.io', role: 'Admin' },
    { email: 'varun@foxsense.io', role: 'member' }
  ])

  const [currentTab, setCurrentTab] = React.useState(0)
  const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const successToast = () => {
    ToastNotification({
      type: 'success',
      message: 'Your comment has been posted'
    })
  }

  const warningToast = () => {
    toast.warning('Your comment has been posted')
  }

  const errorToast = () => {
    DDSToastNotification.error(
      <CustomErrorToast heading='Attachment Failed' message='Lorem Ipsum ...' />
    )
  }

  const ExceptionPrerenceTextWrapper = styled.span`
    font-size: 14px;
    display: inline-block;
    text-wrap: nowrap;
  `

  return (
    <PageSection>
      <Container>
        <ParaDark fontSize='24px'>Buttons</ParaDark>
        <Item>
          <Button onClick={successToast} variant='contained'>
            Success Toast
          </Button>
          <Button onClick={warningToast} variant='outlined' color='warning'>
            Warning Toast
          </Button>
          <Button onClick={errorToast} variant='text' color='error'>
            Error Toast
          </Button>
        </Item>
      </Container>

      <Container>
        <ParaDark fontSize='24px'>Tab Group</ParaDark>
        <Item>
          <TabGroup
            onChange={handleTabChange}
            currentTab={currentTab}
            tabLabels={[{ label: 'Tab 1' }, { label: 'Tab 2' }]}
            tabChildren={[
              <h1 key={1} style={{ color: 'red' }}>
                Rendering Component 1
              </h1>,
              <h1 key={2} style={{ color: 'blue' }}>
                Rendering Component 2
              </h1>
            ]}
          />
        </Item>
      </Container>

      <Container>
        <ParaDark fontSize='24px'>Notification Button</ParaDark>
        <Item style={{ display: 'flex', justifyContent: 'center' }}>
          {/* <Notification
            notificationCount={17}
            notifications={CurrentNotifications}
          /> */}
        </Item>
      </Container>

      <Container>
        <ParaDark fontSize='24px'>Add Filter Dropdown</ParaDark>

        <Item>
          <Filter options={[]} primaryText='Add Filter' />
        </Item>
      </Container>

      <Container>
        <ParaDark fontSize='24px'>Button Group</ParaDark>
        <Item
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gridGap: '20px'
          }}
        >
          <ParaLight fontSize='20px'>Single Select: </ParaLight>
          <ButtonGroup groupItems={ButtonGroupItems} />
        </Item>

        <Item
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gridGap: '20px'
          }}
        >
          <ParaLight fontSize='20px'>Multi Select: </ParaLight>
          <ButtonGroup groupItems={ButtonGroupItems} variant='multiSelect' />
        </Item>
      </Container>

      <Container>
        <ParaDark fontSize='24px'>Input Field</ParaDark>
        <Item>
          <InputField label='Input Field' placeholder='Start typing...' />
          <SearchField placeholder='Search' width='500px' />
        </Item>
      </Container>

      <Container>
        <ParaDark fontSize='24px'>Dropdown</ParaDark>
        <Item>
          <Dropdown
            title='Select Member'
            secondaryTitleText='Admin, Manager, etc'
            dropdownOptions={DropdownOptions}
            variant='default'
            width='500px'
          />
          <Dropdown
            title='Select Member'
            secondaryTitleText='Admin, Manager, etc'
            variant='secondary'
            dropdownOptions={DropdownOptions}
            width='400px'
          />
        </Item>
      </Container>

      <Container>
        <ParaDark fontSize='24px'>Cutomizable Dropdown</ParaDark>
        <Item>
          <CustomDropdown
            title='Interns'
            dropdownOptions={CustomDropdownOption}
            upperChildrenComponents={<h3>Orientation column</h3>}
          />

          <CustomDropdown
            title='Interns'
            dropdownOptions={CustomDropdownOption}
            optionOrientation='row'
            upperChildrenComponents={<h3>Orientation Row</h3>}
          />

          <CustomDropdown
            title='Interns'
            dropdownOptions={CustomDropdownOption}
            optionOrientation='row'
            dropdownWidth='140%'
            upperChildrenComponents={<h3>Upper Component here</h3>}
            lowerChildrenComponents={<h3>Lower Component here</h3>}
          />
        </Item>
      </Container>

      <Container>
        <ParaDark fontSize='24px'>Date Range Picker</ParaDark>
        <Item>
          <DateRangePicker onChange={() => console.log('Date Range')} />
          <TimeInputStepper time={{ hours: 2, minutes: 40 }} />
          <DateRangeSelector timePicker={true} />
        </Item>
      </Container>

      <Container>
        <ParaDark fontSize='24px'>Divider Line</ParaDark>
        <Item>
          <DividerLine margin='10px 0px' color='red' />
        </Item>
      </Container>

      <Container>
        <ParaDark fontSize='24px'>Chip</ParaDark>
        <Item>
          <Chip label='foxsense.io' />
          <Chip
            label='foxsense.io'
            onDelete={() => console.log('Delete Function')}
          />
          {chipExtra.map((item, index) => (
            <Chip
              key={index + 1}
              label={
                <>
                  <span>{item.email}, </span>
                  <span style={{ fontSize: 12 }}>{item.role}</span>
                </>
              }
              onDelete={() => console.log('Write delete function')}
            />
          ))}
        </Item>
      </Container>

      <Container>
        <ParaDark fontSize='24px'>Popup</ParaDark>
        <Item style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => setPopupOpen(!popupOpen)}>
            click me to Open Popup
          </Button>
          <Popup open={popupOpen} handleClose={() => setPopupOpen(!popupOpen)}>
            <h2>This is a popup</h2>
          </Popup>

          <Button
            onClick={() => setConfirmationPopupOpen(!confirmationPopupOpen)}
          >
            Confirmation Popup
          </Button>
          <Popup
            open={confirmationPopupOpen}
            handleClose={() => setConfirmationPopupOpen(!confirmationPopupOpen)}
            confirmToClose={true}
          >
            <h2>This is a Confirmation Popup</h2>
          </Popup>
        </Item>
      </Container>

      <Container>
        <ParaDark fontSize='24px'>Pricing Card</ParaDark>
        <Item>
          <PricingCard
            headerText='Firebolt Racing'
            benefits={PricingCardBenefits}
            variant='light'
          />
          <PricingCard
            headerText='Firebolt Racing'
            benefits={PricingCardBenefits}
            variant='dark'
          />
        </Item>
      </Container>

      <Container>
        <ParaDark fontSize='24px'>Dashboard Section Container</ParaDark>
        <Item>
          <DashboardSectionContainer
            headerText='Dashboard Section Container'
            rightHeaderItem={<AiOutlineCloseCircle />}
          >
            <h3>This is Dashboard Section Container</h3>
          </DashboardSectionContainer>
          <DashboardSectionContainer>
            <h3>This is Dashboard Section Container</h3>
          </DashboardSectionContainer>
        </Item>
      </Container>

      <Container>
        <ParaDark fontSize='24px'>Table Component</ParaDark>
        <Item style={{ flexDirection: 'column' }}>
          <Table columns={tableColumns} data={data} />
        </Item>
      </Container>

      <Container>
        <Item>
          <ParaDark fontSize='24px'>Toggle Button</ParaDark>
          <CustomToggle />
        </Item>
      </Container>

      <Container>
        <ParaDark fontSize='24px'>Checkbox</ParaDark>
        <Item>
          <CheckboxGroup>
            <ParaLight>Checkboxes</ParaLight>
          </CheckboxGroup>
        </Item>
      </Container>

      <Container>
        <ParaDark fontSize='24px'>Matching Area</ParaDark>
        <Item>
          {/* Old */}
          <Typography
            variant='h6'
            style={{ color: `${palette.colors.textDark2}` }}
          >
            Enable this to ensure the website content is displayed to your users
          </Typography>
          {/* New */}
          <TypographyDDS.Paragraph
            size='para'
            variant='medium'
            color={'textDark5'}
          >
            Enable this to ensure the website content is displayed to your users
          </TypographyDDS.Paragraph>
        </Item>
      </Container>
      <Container>
        <ParaDark fontSize='24px'>Default Section</ParaDark>
        <Item>
          {/* h1 bold  */}
          <TypographyDDS.Title type='h1' variant='bold'>
            Text Dark
          </TypographyDDS.Title>
          {/* Para */}
          <TypographyDDS.Paragraph size='para' variant='semiBold'>
            Incident Number
          </TypographyDDS.Paragraph>
          {/* */}
          <TypographyDDS.Title type='h5' variant='semiBold'>
            Incident Number
          </TypographyDDS.Title>
          {/* Default Typography(textDark2), h6, */}
          <TypographyDDS.Title type='h4' variant='semiBold' color={'textDark2'}>
            Incident Number
          </TypographyDDS.Title>

          {/* h5 (lineHeight: 28px, color: textDark) */}
          <TypographyDDS.Title type='h3' variant='semiBold'>
            Incident Number
          </TypographyDDS.Title>

          {/* subtitle1 */}
          <TypographyDDS.Paragraph color='black' size='para' variant='medium'>
            Subtitle1
          </TypographyDDS.Paragraph>
          {/* H4 */}
          <TypographyDDS.Title
            type='h1'
            variant='bold'
            color='primary'
            style={{ fontSize: 32, lineHeight: '38px' }}
          >
            H4 Typography
          </TypographyDDS.Title>
        </Item>
      </Container>
      <Container>
        <ParaDark>Mapping</ParaDark>
        <Item>
          {/* subtitle1 */}
          <TypographyDDS.Paragraph color='black' size='para' variant='medium'>
            Hello
          </TypographyDDS.Paragraph>
          <Typography variant='h6' fontWeight={palette.fontWeight.semiBold}>
            Default typo
          </Typography>
        </Item>
      </Container>

      <Container>
        <ParaDark fontSize='24px'>Typogarphy</ParaDark>
        <Item style={{ flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 12
            }}
          >
            <TypographyDDS.Title color='red' type='h1' variant='bold' ellipsis>
              h1.bold: Blue fox jumped over the wall
            </TypographyDDS.Title>

            <TypographyDDS.Title
              color='textDark'
              type='h1'
              variant='semiBold'
              ellipsis
            >
              h1.semiBold: Blue fox jumped over the wall
            </TypographyDDS.Title>

            <TypographyDDS.Title
              color='textDark'
              type='h2'
              variant='bold'
              ellipsis
            >
              h2.bold: Blue fox jumped over the wall
            </TypographyDDS.Title>

            <TypographyDDS.Title
              color='textDark'
              type='h2'
              variant='bold'
              ellipsis
            >
              h2.semiBold: Blue fox jumped over the wall
            </TypographyDDS.Title>

            <TypographyDDS.Title
              color='textDark'
              type='h3'
              variant='bold'
              ellipsis
            >
              h3.bold: Blue fox jumped over the wall
            </TypographyDDS.Title>

            <TypographyDDS.Title
              color='textDark'
              type='h3'
              variant='semiBold'
              ellipsis
            >
              h3.semiBold: Blue fox jumped over the wall
            </TypographyDDS.Title>

            <TypographyDDS.Title
              color='textDark'
              type='h3'
              variant='medium'
              ellipsis
            >
              h3.medium: Blue fox jumped over the wall
            </TypographyDDS.Title>

            <TypographyDDS.Title
              color='textDark'
              type='h4'
              variant='bold'
              ellipsis
            >
              h4.bold: Blue fox jumped over the wall
            </TypographyDDS.Title>

            <TypographyDDS.Title
              color='textDark'
              type='h4'
              variant='semiBold'
              ellipsis
            >
              h4.semiBold: Blue fox jumped over the wall
            </TypographyDDS.Title>

            <TypographyDDS.Title
              color='textDark'
              type='h4'
              variant='medium'
              ellipsis
            >
              h4.medium: Blue fox jumped over the wall
            </TypographyDDS.Title>

            {/* h5 */}
            <TypographyDDS.Title
              color='textDark'
              type='h5'
              variant='bold'
              ellipsis
            >
              h5.bold: Blue fox jumped over the wall
            </TypographyDDS.Title>

            <TypographyDDS.Title
              color='textDark'
              type='h5'
              variant='semiBold'
              ellipsis
            >
              h5.semiBold: Blue fox jumped over the wall
            </TypographyDDS.Title>

            <TypographyDDS.Title
              color='textDark'
              type='h5'
              variant='medium'
              ellipsis
            >
              h5.medium: Blue fox jumped over the wall
            </TypographyDDS.Title>

            {/* Para */}
            <TypographyDDS.Paragraph
              color='borderRed'
              size='para'
              variant='bold'
              ellipsis
            >
              Para.bold: Blue fox jumped over the wall
            </TypographyDDS.Paragraph>

            <TypographyDDS.Paragraph
              color='borderRed'
              size='para'
              variant='semiBold'
              ellipsis
            >
              Para.semiBold: Blue fox jumped over the wall
            </TypographyDDS.Paragraph>

            <TypographyDDS.Paragraph
              color='borderRed'
              size='para'
              variant='medium'
              ellipsis
            >
              Para.medium: Blue fox jumped over the wall
            </TypographyDDS.Paragraph>

            {/* Caption */}

            <TypographyDDS.Paragraph
              color='borderRed'
              size='caption'
              variant='bold'
              ellipsis
            >
              Caption.bold: Blue fox jumped over the wall
            </TypographyDDS.Paragraph>

            <TypographyDDS.Paragraph
              color='borderRed'
              size='caption'
              variant='semiBold'
              ellipsis
            >
              Caption.semiBold: Blue fox jumped over the wall
            </TypographyDDS.Paragraph>

            <TypographyDDS.Paragraph size='caption' variant='medium' ellipsis>
              Caption.medium: Blue fox jumped over the wall
            </TypographyDDS.Paragraph>
          </div>
        </Item>
      </Container>
    </PageSection>
  )
}

export default Stylesheet
