import { Route, Routes } from 'react-router-dom'

import GeneralSettingsCard from './Subcomponents/GeneralSettingsCard.component'
import ProjectEnvironmentCard from './Subcomponents/ViewEnvironmentCard'
import ProjectEnvironments from './ProjectEnvironments'
import ProjectMember from './ProjectMembers'
import ProjectSettingsLayout from '../../Layout/ProjectSettingsLayout'
import React from 'react'
import routeConstants from '../../../constants/RouteConstants'

// TODO get project details and send it to sub routes, if selected project is empty then make api call
const IndividualProjects = () => {
  return (
    <Routes>
      <Route element={<ProjectSettingsLayout />}>
        {/* <Route path='/' element={<Navigate to='general' />} /> */}
        <Route path='/' element={<GeneralSettingsCard />} />

        <Route path='members' element={<ProjectMember />} />
        <Route
          path={routeConstants.ENVIRONMENTS}
          element={<ProjectEnvironments />}
        />
        {/* <Route path='teams' element={<h3>Coming Soon</h3>} /> */}
      </Route>
      <Route
        path={routeConstants.ENVIRONMENTS + '/:environmentId'}
        element={<ProjectEnvironmentCard />}
      />
    </Routes>
  )
}

export default IndividualProjects
