import { ChevronDown, ChevronUp } from '@carbon/icons-react'
import { DDSSelectV2, DDSTypography } from 'den-design-system'
import React, { useEffect, useRef, useState } from 'react'

import { SelectProps } from './Select.types'
import pallete from '../../../global/newPallete'

const Select: React.FC<SelectProps> = ({
  label,
  variant = 'top',
  size = 'small',
  required = false,
  id,
  value,
  defaultValue,
  placeholder,
  name,
  disabled,
  options,
  onChange,
  onSelect,
  searchable,
  filterOption,
  errorMessage
}) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setMenuOpen(false)
    }
  }

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('click', handleClickOutside)
    } else {
      document.removeEventListener('click', handleClickOutside)
    }

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [menuOpen])

  return searchable ? (
    variant === 'side' ? (
      <div className='flex items-center override-select w-fit'>
        <div
          style={{
            height: size === 'large' ? '36px' : '32px',
            backgroundColor: pallete.colors.surface5,
            borderColor: pallete.colors.stroke2
          }}
          className='flex rounded-l-[6px] items-center justify-center border px-[16px] py-[8px]'
        >
          <DDSTypography.Paragraph
            size='para'
            variant='semiBold'
            color={pallete.colors.textDark3}
          >
            {label}
          </DDSTypography.Paragraph>
        </div>
        <div ref={containerRef}>
          <DDSSelectV2
            id={id}
            name={name}
            openSelectIcon={
              <ChevronUp size={16} color={pallete.colors.textDark4} />
            }
            closeSelectIcon={
              <ChevronDown size={16} color={pallete.colors.textDark4} />
            }
            required={required}
            value={value}
            defaultValue={defaultValue}
            placeholder={placeholder}
            disabled={disabled}
            options={options}
            searchable={searchable}
            filterOption={filterOption}
            onChange={onChange}
            onSelect={(option?: any) => {
              setMenuOpen(false)
              if (onSelect) {
                onSelect(option)
              }
            }}
            selectBoxStyle={{
              height: size === 'large' ? '36px' : '',
              borderColor: pallete.colors.stroke2
            }}
            errorMessage={errorMessage}
          />
        </div>
      </div>
    ) : (
      <div ref={containerRef} className='override-select-top'>
        <DDSSelectV2
          id={id}
          name={name}
          label={label}
          openSelectIcon={
            <ChevronUp size={16} color={pallete.colors.textDark4} />
          }
          closeSelectIcon={
            <ChevronDown size={16} color={pallete.colors.textDark4} />
          }
          required={required}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          options={options}
          searchable={searchable}
          filterOption={filterOption}
          onChange={onChange}
          onSelect={(option?: any) => {
            setMenuOpen(false)
            if (onSelect) {
              onSelect(option)
            }
          }}
          selectBoxStyle={{
            height: size === 'large' ? '36px' : '',
            border: `1px solid ${pallete.colors.stroke2}`
          }}
          errorMessage={errorMessage}
        />
      </div>
    )
  ) : variant === 'side' ? (
    <div className='flex items-center override-select w-fit'>
      <div
        style={{
          height: size === 'large' ? '36px' : '32px',
          backgroundColor: pallete.colors.surface5,
          borderColor: pallete.colors.stroke2
        }}
        className='flex rounded-l-[6px] items-center justify-center border h-[32px] px-[16px] py-[8px]'
      >
        <DDSTypography.Paragraph
          size='para'
          variant='semiBold'
          color={pallete.colors.textDark3}
        >
          {label}
        </DDSTypography.Paragraph>
      </div>
      <div ref={containerRef}>
        <DDSSelectV2
          id={id}
          name={name}
          openSelectIcon={
            <ChevronUp size={16} color={pallete.colors.textDark4} />
          }
          closeSelectIcon={
            <ChevronDown size={16} color={pallete.colors.textDark4} />
          }
          required={required}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          options={options}
          searchable={searchable}
          onChange={onChange}
          onSelect={(option?: any) => {
            setMenuOpen(false)
            if (onSelect) {
              onSelect(option)
            }
          }}
          selectBoxStyle={{
            height: size === 'large' ? '36px' : '',
            borderColor: pallete.colors.stroke2
          }}
          errorMessage={errorMessage}
        />
      </div>
    </div>
  ) : (
    <div ref={containerRef} className='override-select-top'>
      <DDSSelectV2
        id={id}
        name={name}
        label={label}
        openSelectIcon={
          <ChevronUp size={16} color={pallete.colors.textDark4} />
        }
        closeSelectIcon={
          <ChevronDown size={16} color={pallete.colors.textDark4} />
        }
        required={required}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        disabled={disabled}
        options={options}
        searchable={searchable}
        onChange={onChange}
        onSelect={(option?: any) => {
          setMenuOpen(false)
          if (onSelect) {
            onSelect(option)
          }
        }}
        selectBoxStyle={{
          height: size === 'large' ? '36px' : '',
          width: size === 'large' ? '100%' : ''
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default Select
