import { Api, Code, Debug, PillsAdd } from '@carbon/icons-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { UrlWithOnlyOrgId, replaceUrl } from '../../../utils/UrlUtils'

import APIMonitoringOverview from './APIMonitoring/APIMonitoringOverview'
import { AccessRoleConstants } from '../../../constants/AccessRoleConstants'
import AddCheckPopup from '../../../components/AHCPage/AddCheckPopupFlow/AddCheckPopup'
import AddJobPopup from '../../../components/OverviewPage/AddJobPopup'
import DocumentHeader from '../../../components/Helmet/DocumentHeader'
import ExceptionsOverview from './Exceptions/ExceptionsOverview'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import JobsOverview from './JobMonitoring/JobsOverview'
import OverviewCard from '../../../components/OverviewPage/OverviewCard'
import OverviewHeader from '../../../components/OverviewPage/OverviewHeader'
import OverviewStringConstants from '../../../constants/OverviewStringConstants'
import { ProjectType } from '../../../enum/ProjectType.enum'
import SecureComponent from '../../../components/Layout/SecureComponent'
import StringConstants from '../../../constants/StringConstants'
import UptimeOverview from './UptimeChecks/UptimeOverview'
import ViewMorePopup from '../../../components/OverviewPage/ViewMorePopup'
import { getDataForProjectType } from '../../../utils/OverviewUtils'
import { getUserDetails } from '../../../api/user/User.service'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'

const OverviewPage = () => {
  const store = useStoreContext()
  const [currentData, setCurrentData] = useState<any>([])
  const [popupOpen, setPopupOpen] = useState(false)
  const [newCheckOpen, setNewCheckOpen] = useState(false)
  const [newJobOpen, setNewJobOpen] = useState(false)
  const exceptionRefreshRef = useRef<() => void>(() => {
    null
  })
  const apiRefreshRef = useRef<() => void>(() => {
    null
  })
  const uptimeRefreshRef = useRef<() => void>(() => {
    null
  })
  const jobsRefreshRef = useRef<() => void>(() => {
    null
  })

  const navigate = useNavigate()

  useEffect(() => {
    if (store.userStore.getSelectedOrganization().projectCount === 0) {
      navigate(UrlWithOnlyOrgId(routeConstants.NO_PROJECT, store))
    }
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.OVERVIEW
    )
    getUserDetails().then((userResponse: any) => {
      const organizationIndex = userResponse.data.organizations.findIndex(
        (org: any) => {
          return org.id === store.userStore.getSelectedOrganization().id
        }
      )
      if (organizationIndex === -1) {
        navigate(routeConstants.UNAUTHORIZED_PAGE)
      }
      if (
        userResponse.data.organizations[organizationIndex].projectCount === 0
      ) {
        navigate(UrlWithOnlyOrgId(routeConstants.NO_PROJECT, store))
      }
    })
  }, [])

  useEffect(() => {
    setCurrentData(
      getDataForProjectType(
        overviewCardData,
        store.scopeStore.getSelectedProject().type
      )
    )
  }, [store.scopeStore.getSelectedProject(), store.scopeStore.getScope()])

  const handleViewMore = (module: 'job' | 'health' | 'api', tab: number) => {
    store.overviewStore.setActiveModule(module)
    store.overviewStore.setActiveTab(tab)
    setPopupOpen(true)
  }

  //navigation functions
  const navigateToAHC = (arg?: { id?: string; status?: string }) => {
    let extras = ''
    if (arg?.id) extras = `/${arg.id}`
    else if (arg?.status) {
      extras = `?previous-check-status=${arg.status}`
    }

    navigate(
      replaceUrl(
        routeConstants.AHC_PROJECTS_DASHBOARD,
        store.scopeStore.getScope()['organization-id'] as string,
        store.scopeStore.getScope()['project-id'] as string,
        store.scopeStore.getScope()['environment-id'] as string
      ) + extras
    )
  }

  const navigateToExceptions = (arg?: {
    id?: string
    tab?: string
    group?: boolean
  }) => {
    let extras = ''
    if (arg?.id && arg?.tab) {
      extras = `/${arg.id}` + `?tab=${arg.tab}`
    } else if (arg?.id) {
      extras = `/${arg.id}`
    }
    if (arg?.group) {
      const route =
        replaceUrl(
          routeConstants.ERROR_MONITORING,
          store.scopeStore.getScope()['organization-id'] as string,
          store.scopeStore.getScope()['project-id'] as string,
          store.scopeStore.getScope()['environment-id'] as string
        ).replace(':id', arg?.id as string) + `?tab=${arg?.tab}`
      navigate(route)
    } else {
      navigate(
        replaceUrl(
          routeConstants.SINGLE_ERROR_MONITORING,
          store.scopeStore.getScope()['organization-id'] as string,
          store.scopeStore.getScope()['project-id'] as string,
          store.scopeStore.getScope()['environment-id'] as string
        ) + extras
      )
    }
  }

  const navigateToAPIMonitoring = (arg?: {
    id?: string
    template?: string
    queries?: string
    home: boolean
    group?: boolean
    tab?: string
  }) => {
    let extras = ''
    if (arg?.id) extras = `/${arg.id}`
    else if (arg?.template) {
      extras = `?url-template=${arg.template}`
    } else if (arg?.queries) {
      extras = `?${arg.queries}`
    }
    if (arg?.home) {
      navigate(
        replaceUrl(
          routeConstants.API_MONITORING,
          store.scopeStore.getScope()['organization-id'] as string,
          store.scopeStore.getScope()['project-id'] as string,
          store.scopeStore.getScope()['environment-id'] as string
        ) +
          extras +
          `${arg?.queries ? 'tab=' + arg?.tab : '?tab=' + arg?.tab}`
      )
    } else {
      if (arg?.group) {
        navigate(
          replaceUrl(
            routeConstants.VIEW_GROUPED_API,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ).replace(':id', arg?.id as string) + `?tab=1&innerTab=1`
        )
      } else {
        navigate(
          replaceUrl(
            routeConstants.VIEW_ALL_API,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ).replace(':id', arg?.id as string) + `?tab=2&innerTab=1`
        )
      }
    }
  }

  const navigateToJobMonitoring = (arg?: {
    id?: string
    status?: string
    tab?: string
  }) => {
    let extras = ''
    if (arg?.id) {
      extras = `/${arg.id}`
    } else if (arg?.status && arg?.tab) {
      extras = `?tab=${arg.tab}&previous-run-status=${arg.status}`
    }
    navigate(
      replaceUrl(
        routeConstants.JOB_MONITORING,
        store.scopeStore.getScope()['organization-id'] as string,
        store.scopeStore.getScope()['project-id'] as string,
        store.scopeStore.getScope()['environment-id'] as string
      ) + extras
    )
  }

  const overviewCardData = [
    {
      id: 1,
      title:
        store.scopeStore.getSelectedProject().type ===
        ProjectType.FULL_STACK_ONLY
          ? OverviewStringConstants.EXCEPTIONS_TITLE
          : OverviewStringConstants.ERRORS_TITLE,
      logo: <Debug size={20} color={pallete.colors.textDark3} />,
      content: (
        <ExceptionsOverview
          navigateToExceptions={navigateToExceptions}
          setFindExceptionOverview={(func: () => void) => {
            exceptionRefreshRef.current = func
          }}
        />
      )
    },
    {
      id: 2,
      title: OverviewStringConstants.API_MONITORING_TITLE,
      logo: <Api size={20} color={pallete.colors.textDark3} />,
      content: (
        <APIMonitoringOverview
          setFindAPIOverview={(func: () => void) => {
            apiRefreshRef.current = func
          }}
          navigateToAPI={navigateToAPIMonitoring}
        />
      )
    },
    {
      id: 3,
      title: OverviewStringConstants.UPTIME_CHECKS_TITLE,
      logo: <PillsAdd size={20} color={pallete.colors.textDark3} />,
      content: (
        <UptimeOverview
          setFindUptimeOverview={(func: () => void) => {
            uptimeRefreshRef.current = func
          }}
          navigateToAHC={navigateToAHC}
          handleViewMore={handleViewMore}
        />
      ),
      setOpen: setNewCheckOpen
    },
    {
      id: 4,
      title:
        store.scopeStore.getSelectedProject().type ===
        ProjectType.FULL_STACK_ONLY
          ? OverviewStringConstants.JOBS_TITLE
          : OverviewStringConstants.APEX_JOBS_TITLE,
      logo: <Code size={20} color={pallete.colors.textDark3} />,
      content: (
        <JobsOverview
          setFindJobsOverview={(func: () => void) => {
            jobsRefreshRef.current = func
          }}
          navigateToJobs={navigateToJobMonitoring}
          handleViewMore={handleViewMore}
        />
      ),
      setOpen: setNewJobOpen
    }
  ]

  const getOnRefresh = useCallback((id: number) => {
    switch (id) {
      case 1:
        return () => exceptionRefreshRef.current()
      case 2:
        return () => apiRefreshRef.current()
      case 3:
        return () => uptimeRefreshRef.current()
      case 4:
        return () => jobsRefreshRef.current()
      default:
        return () => null
    }
  }, [])
  return (
    <>
      <DocumentHeader header={HeaderConstants.OVERVIEW} />
      <div className='flex flex-col py-[24px]'>
        <div className='flex flex-col gap-[32px]'>
          <OverviewHeader />
          {currentData.map((feature: any) => (
            <OverviewCard
              key={feature.id}
              title={feature.title}
              logo={feature.logo}
              content={feature.content}
              setOpen={feature.setOpen}
              onRefresh={getOnRefresh(feature.id)}
            />
          ))}
        </div>
        {/*View More Pop up */}
        <ViewMorePopup open={popupOpen} setOpen={setPopupOpen} />
        {/*Add Job Pop up */}
        <AddJobPopup
          newJobOpen={newJobOpen}
          setNewJobOpen={setNewJobOpen}
          navigateToJobMonitoring={navigateToJobMonitoring}
        />
        {/* Add Check Popup*/}
        <AddCheckPopup
          handleClose={() => setNewCheckOpen(false)}
          open={newCheckOpen}
          onComplete={() => navigateToAHC()}
        />
      </div>
    </>
  )
}

export default SecureComponent(
  observer(OverviewPage),
  AccessRoleConstants.MEMBER_LEVEL
)
