import { AtoB } from '../../utils/UrlUtils'
import StringConstants from '../../constants/StringConstants'
import routeConstants from '../../constants/RouteConstants'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const PageTracker = () => {
  const location = useLocation()

  // Update session storage with the current page URL
  const updateSessionStorage = () => {
    const currentUrl = location.pathname
    try {
      // Store org details for pages other than signup , sign in , home and redirect
      if (
        ![
          routeConstants.HOME,
          routeConstants.SIGN_IN,
          routeConstants.SIGN_UP,
          routeConstants.REDIRECT,
          routeConstants.ACCOUNT_SETTINGS,
          routeConstants.PREFERENCES_SETTINGS,
          routeConstants.UNAUTHORIZED_PAGE,
          routeConstants.NO_ORGANIZATION,
          routeConstants.NOTIFICATIONS,
          routeConstants.INVITES
        ].includes(currentUrl)
      ) {
        const decodedUrlSplit = AtoB(currentUrl.split('/')[1]).split('/')
        localStorage.setItem(
          StringConstants.LOCAL_STORAGE.CURRENT_PAGE_DETAILS,
          JSON.stringify({
            'organization-id': decodedUrlSplit[0],
            'project-id': decodedUrlSplit[1],
            'environment-id': decodedUrlSplit[2]
          })
        )
      }
    } catch (err) {
      console.log(err)
    }
  }

  // Call updateSessionStorage whenever the route changes
  useEffect(() => {
    updateSessionStorage()
  }, [location.pathname])

  return null // Since this component doesn't render anything
}

export default PageTracker
