import {
  Code,
  Debug,
  IbmCloudSecurityComplianceCenter,
  PillsAdd
} from '@carbon/icons-react'

import CheckCard from './CheckCard'
import { DDSTypography } from 'den-design-system'
import IconCard from './IconCard'
import React from 'react'
import SigninSignupStringConstants from '../../constants/SigninSignupStringConstants'
import pallete from '../../global/newPallete'
import radialObject from '../../assets/images/sigin-radial-object.png'
import vigilTMLogo from '../../assets/icons/vigilTMLogoWhite.svg'

const LeftContent: React.FC<{ signIn: boolean }> = ({ signIn }) => {
  const tickCardData = [
    {
      id: 1,
      text: SigninSignupStringConstants.FULL_STACK_APPS
    },
    {
      id: 2,
      text: SigninSignupStringConstants.SALESFORCE_ORGS
    },
    {
      id: 3,
      text: SigninSignupStringConstants.MULESOFT_APPS
    }
  ]
  const iconCardData = [
    {
      id: 1,
      icon: <Debug color={pallete.colors.textDark1} size={28} />,
      text: SigninSignupStringConstants.EXCEPTIONS
    },
    {
      id: 2,
      icon: <Code color={pallete.colors.textDark1} size={28} />,
      text: SigninSignupStringConstants.JOBS
    },
    {
      id: 3,
      icon: (
        <IbmCloudSecurityComplianceCenter
          color={pallete.colors.textDark1}
          size={28}
        />
      ),
      text: SigninSignupStringConstants.AUDIT
    },
    {
      id: 4,
      icon: <PillsAdd color={pallete.colors.textDark1} size={28} />,
      text: SigninSignupStringConstants.UPTIME
    }
  ]
  return (
    <div
      className='w-[40%] px-[44px] py-[64px] flex flex-col items-start justify-center gap-[32px]  h-[100vh]'
      style={{
        background: `url(${radialObject}) no-repeat top left`,
        backgroundSize: 'cover',
        backgroundColor: pallete.colors.surface3
      }}
    >
      <div>
        <img src={vigilTMLogo} />
      </div>
      <div className='flex flex-col items-start gap-[48px] h-[100vh] w-full'>
        {signIn ? (
          <DDSTypography.Title
            type='h1'
            variant='bold'
            color={pallete.colors.textDark1}
            style={{ fontSize: '32px', lineHeight: '40px' }}
          >
            {SigninSignupStringConstants.WELCOME_BACK}
          </DDSTypography.Title>
        ) : (
          <div className='flex flex-col items-start gap-[8px] self-stretch'>
            <DDSTypography.Title
              type='h1'
              variant='bold'
              color={pallete.colors.textDark1}
              style={{ fontSize: '32px', lineHeight: '40px' }}
            >
              {SigninSignupStringConstants.SIGNUP_FREE}
            </DDSTypography.Title>
            <div>
              <DDSTypography.Title
                type='h5'
                variant='medium'
                color={pallete.colors.textDark7}
              >
                {SigninSignupStringConstants.NO_CREDIT_CARD}
              </DDSTypography.Title>
            </div>
          </div>
        )}

        <div className='flex flex-col py-[24px] gap-[24px]'>
          <div>
            <DDSTypography.Title
              type='h4'
              variant='bold'
              color={pallete.colors.textDark1}
            >
              {SigninSignupStringConstants.MONITOR_MANAGE}
            </DDSTypography.Title>
          </div>
          <div className='flex flex-col items-start gap-[24px] self-stretch'>
            {tickCardData.map((item) => (
              <CheckCard key={item.id} text={item.text} />
            ))}
          </div>
        </div>
        <div className='mt-auto flex flex-col items-start gap-[24px] self-stretch'>
          <div>
            <DDSTypography.Title
              type='h4'
              variant='bold'
              color={pallete.colors.textDark1}
            >
              {SigninSignupStringConstants.ACCESS_EVERYTHING}
            </DDSTypography.Title>
          </div>
          <div className='flex w-full items-start gap-[24px] self-stretch'>
            {iconCardData.map((item) => (
              <div className='w-full' key={item.id}>
                <IconCard icon={item.icon} text={item.text} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeftContent
