import Joi, { ValidationResult } from 'joi'
import React, { useEffect, useState } from 'react'

import { CreateProject } from '../../../api/project/Project.service'
import { EnvironmentType } from '../../../enum/environment.enum'
import EnvironmentsStep from './EnvironmentsStep/EnvironmentsStep'
import { OrganizationType } from '../../../enum/OrganizationType.enum'
import Popup from '../../common/Popup/Popup'
import ProjectNameStep from './CreateProjectSteps/ProjectNameStep'
import RegexConstants from '../../../constants/RegexConstants'
import StringConstants from '../../../constants/StringConstants'
import TabGroup from '../../common/TabGroup/TabGroup'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import UrlConstants from '../../../constants/UrlConstants'
import ValidationConstants from '../../../constants/ValidationConstants'
import { checkNameAvailability } from '../../../api/common/Common.service'
import { isEmpty } from 'lodash'
import { replaceUrl } from '../../../utils/UrlUtils'
import routeConstants from '../../../constants/RouteConstants'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'

interface CreateProjectFlowProps {
  open: boolean
  handleClose: () => void
}

const tabLabels = [{ label: 'Project' }, { label: 'Environments' }]

const validateProjectNameSchema = Joi.string()
  .pattern(RegexConstants.NAME_REGEX)
  .required()

const validateProjectTypeSchema = Joi.string().required()

const CreateProjectFlow: React.FC<CreateProjectFlowProps> = ({
  open,
  handleClose
}) => {
  const [currentStep, setCurrentStep] = useState<boolean | number>(false)
  const store = useStoreContext()
  // project step state variables
  const [projectName, setProjectName] = useState<string>('')
  const [projectType, setProjectType] = useState<string>('')
  const [isInvalidProjectName, setIsInvalidProjectName] = useState(false)
  const [isInvalidProjectType, setIsInvalidProjectType] = useState(false)
  const [projectStepLoading, setProjectStepLoading] = useState(false)
  const [projectStepHelperText, setProjectStepHelperText] = useState('')
  // environment step state variables
  const [isDevelopmentEnvironmentChecked, setIsDevelopmentEnvironmentChecked] =
    useState(false)
  const [isStagingEnvironmentChecked, setIsStagingEnvironmentChecked] =
    useState(false)
  const [environmentStepLoading, setEnvironmentStepLoading] =
    useState<boolean>(false)

  const navigate = useNavigate()

  const onClose = () => {
    setProjectName('')
    setProjectType('')
    setIsDevelopmentEnvironmentChecked(false)
    setIsStagingEnvironmentChecked(false)
    setCurrentStep(false)
    setProjectStepHelperText('')
    setIsInvalidProjectName(false)
    setIsInvalidProjectType(false)
    handleClose()
  }

  useEffect(() => {
    if (open) setCurrentStep(0)
  }, [open])

  // check project name availability
  const checkProjectNameAvailability = () => {
    setProjectStepHelperText('')
    const projectValidationResult: ValidationResult =
      validateProjectNameSchema.validate(projectName.trim())
    const projectTypeValidationResult: ValidationResult =
      validateProjectTypeSchema.validate(projectType)
    // if validation error, display error
    // else check name availability
    if (
      projectValidationResult.error ||
      (store.userStore.getSelectedOrganization().type ===
        OrganizationType.HYBRID &&
        projectTypeValidationResult.error)
    ) {
      if (projectValidationResult.error) {
        setProjectStepLoading(false)
        setIsInvalidProjectName(true)
        setIsInvalidProjectType(false)
        setProjectStepHelperText(ValidationConstants.PROJECT.PROJECT_NAME)
      } else {
        setIsInvalidProjectName(false)
      }

      if (projectTypeValidationResult.error) {
        setIsInvalidProjectType(true)
        setCurrentStep(0)
      } else {
        setIsInvalidProjectType(false)
      }
    } else {
      setIsInvalidProjectName(false)
      setProjectStepLoading(true)
      checkNameAvailability(
        UrlConstants.CHECK_PROJECT_NAME_AVAILABILITY.USECASE,
        projectName.trim()
      )
        .then(() => {
          setProjectStepLoading(false)
          setProjectStepHelperText('')
          setIsInvalidProjectType(false)
          createProject()
        })
        .catch((error) => {
          // if name already taken, display error
          // if some other error, display toast and close popup
          if (error === StringConstants.NAME_ALREADY_TAKEN) {
            setProjectStepLoading(false)
            setIsInvalidProjectName(true)
            setProjectStepHelperText(
              ValidationConstants.PROJECT.PROJECT_NAME_NOT_AVAILABLE
            )
          } else {
            setProjectStepLoading(false)
            ToastNotification({
              type: 'error',
              message: ToastMessageConstants.SOMETHING_WENT_WRONG
            })
            onClose()
          }
        })
    }
  }

  // TODO check name availability, user may switch tabs directly which doesn't have name availability check
  const createProject = () => {
    const projectValidationResult: ValidationResult =
      validateProjectNameSchema.validate(projectName)

    const type: string = isEmpty(projectType)
      ? store.userStore.getSelectedOrganization().type
      : projectType

    setProjectType(type)

    if (projectValidationResult.error) {
      setIsInvalidProjectName(true)
      setProjectStepHelperText(ValidationConstants.PROJECT.PROJECT_NAME)
      setCurrentStep(0)
    } else {
      setEnvironmentStepLoading(true)
      CreateProject({
        name: projectName,
        projectType: type
      })
        .then(() => {
          setEnvironmentStepLoading(false)
          store.projectStore.setProjects([])
          ToastNotification({
            type: 'success',
            message: ToastMessageConstants.PROJECT.CREATE.SUCCESS
          })
          onClose()
          navigate(
            replaceUrl(
              routeConstants.PROJECT_SETTINGS,
              store.scopeStore.getScope()['organization-id'] as string,
              store.scopeStore.getScope()['project-id'] as string,
              store.scopeStore.getScope()['environment-id'] as string
            )
          )
        })
        .catch((error) => {
          setEnvironmentStepLoading(false)

          const toastHeading = error.includes('Subscribe to get more projects')
            ? ToastMessageConstants.PROJECT.CREATE.UPGRADE_SUBSCRIPTION
            : ToastMessageConstants.PROJECT.CREATE.ERROR

          ToastNotification({
            type: 'error',
            message: toastHeading
          })
          onClose()
        })
    }
  }

  const renderTabs = () => {
    return (
      <ProjectNameStep
        projectName={projectName}
        projectType={projectType}
        handleNext={checkProjectNameAvailability}
        onClose={onClose}
        isInvalidName={isInvalidProjectName}
        helperText={projectStepHelperText}
        isInvalidType={isInvalidProjectType}
        typeHelperText={ValidationConstants.PROJECT.PROJECT_TYPE_EMPTY}
        onNameChange={(projectName: string) => {
          setProjectName(projectName)
        }}
        onTypeChange={(projectType: string) => {
          setProjectType(projectType)
        }}
        loading={projectStepLoading}
      />
    )
  }

  return (
    <Popup
      headerText='Create Project'
      open={open}
      handleClose={onClose}
      width='920px'
      height='500px'
    >
      {/* <TabGroup
        currentTab={currentStep as number}
        onChange={() => null}
        tabLabels={tabLabels}
        $disableSwitch
      /> */}
      {renderTabs()}
    </Popup>
  )
}

export default CreateProjectFlow
