import CustomDropdown from '../common/Dropdown/CustomDropdown'
import React from 'react'
import { TypographyDDS } from '../common/Typography/TypographyDDS'
import { css } from '@emotion/react'
import palette from '../../global/pallete'
import styled from '@emotion/styled'

const DivButton = styled(TypographyDDS.Paragraph)`
  display: flex;
`
interface DropdownChipProps {
  color?: string
}
export const DropdownChip = styled(DivButton)<DropdownChipProps>`
  ${(props) => {
    switch (props.color) {
      case 'red': {
        return css`
          color: ${palette.colors.borderRed};
        `
      }
      case 'orange': {
        return css`
          color: ${palette.colors.warning};
        `
      }
      case 'blue': {
        return css`
          color: ${palette.colors.primary};
        `
      }
      default: {
        return css`
          color: ${palette.colors.textDark};
        `
      }
    }
  }}
`
const severityDropdown = [
  {
    id: 1,
    name: 'CRITICAL',
    element: (
      <DropdownChip size='para' variant='semiBold' color='red'>
        CRITICAL
      </DropdownChip>
    )
  },
  {
    id: 2,
    name: 'MAJOR',
    element: (
      <DropdownChip size='para' variant='semiBold' color='orange'>
        MAJOR
      </DropdownChip>
    )
  },
  {
    id: 3,
    name: 'MODERATE',
    element: (
      <DropdownChip size='para' variant='semiBold' color='blue'>
        MODERATE
      </DropdownChip>
    )
  },
  {
    id: 4,
    name: 'MINOR',
    element: (
      <DropdownChip size='para' variant='semiBold'>
        MINOR
      </DropdownChip>
    )
  }
]
const ErrorSeverity = () => {
  return (
    <CustomDropdown
      title={'Severity'}
      dropdownOptions={severityDropdown}
      width={'200px'}
      dropdownWidth={'200px'}
    />
  )
}
export default ErrorSeverity
