import {
  AtoB,
  BtoA,
  UrlWithOnlyOrgId,
  replaceUrl
} from '../../../../utils/UrlUtils'
import Joi, { ValidationResult } from 'joi'
import React, { useEffect, useRef, useState } from 'react'
import { cloneDeep, isArray, isEmpty, isEqual, isUndefined } from 'lodash'
import {
  deleteProject,
  disconnectSlackFromProject,
  getProjectById,
  updateProject
} from '../../../../api/project/Project.service'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../../constants/GAConstants'
import {
  getValidationError,
  getValidationStatus
} from '../../../../utils/ValidationUtils'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import Button from '../../Button/Button'
import DashboardSectionContainer from '../../DashboardSectionContainer/DashboardSectionContainer'
import DeleteIcon from '../../../../assets/icons/trash.svg'
import InputField from '../../InputField/InputField'
import { LinkTooltip } from '../../LinkTooltip/LinkTooltip'
import MultiTextInputField from '../../InputField/MultiTextInputField'
import { OrganizationUserRoles } from '../../../../enum/OrganizationUserRoles.enum'
import { ProjectTypeDisplayNames } from '../../../../enum/ProjectType.enum'
import { RefObject } from '../../InputField/Interface/Interface'
import RegexConstants from '../../../../constants/RegexConstants'
import Slack from '../../../../assets/icons/slack.png'
import Spinner from '../../Spinner/Spinner'
import StringConstants from '../../../../constants/StringConstants'
import TextConfirmationPopup from '../../Popup/TextConfirmationPopup'
import { TextContentConstants } from '../../../../constants/TextContentConstants'
import { ToastMessageConstants } from '../../../../constants/ToastMessageConstants'
import ToastNotification from '../../DDS/Toast/Toast'
import { Tooltip } from '@mui/material'
import { TypographyDDS } from '../../Typography/TypographyDDS'
import UrlConstants from '../../../../constants/UrlConstants'
import ValidationConstants from '../../../../constants/ValidationConstants'
import breakpoints from '../../../../global/breakpoints'
import { checkNameAvailability } from '../../../../api/common/Common.service'
import { getAddToSlackUrl } from '../../../../utils/ProjectUtils'
import infoIcon from '../../../../assets/icons/infoIcon.svg'
import linkIcon from '../../../../assets/icons/link.svg'
import { observer } from 'mobx-react-lite'
import palette from '../../../../global/pallete'
import routeConstants from '../../../../constants/RouteConstants'
import styled from '@emotion/styled'
import { useAnalyticsEventTracker } from '../../../../utils/GAUtils'
import { useStoreContext } from '../../../../store/StoreContext'

interface ParaProps {
  padding?: string
  lineHeight?: number
  fontSize?: string
  maxWidth?: string
}

const IndividualSettingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em 0.3125em 4em;
  grid-gap: 2.5em;

  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .Input,
    .Button {
      align-self: center;
    }
  }
`

const SettingsLeft = styled.div`
  flex-basis: 33.33%;
  @media screen and (max-width: ${breakpoints.sm}) {
    width: 80%;
    margin-bottom: 1em;
  }
`

const SettingsRight = styled.div`
  flex-basis: 66.66%;
  min-width: 30%;
  max-width: 300px;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 80%;
    align-items: center;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: row wrap;
`
const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  transform: translate(0, -1.25em);
`

const GeneralSettingsCard = () => {
  const { projectId } = useParams()
  const location = useLocation()
  const storeContext = useStoreContext()
  const [projectName, setProjectName] = useState<string>('')
  const [projectType, setProjectType] = useState<string>('')
  const [additionalEmails, setAdditionalEmails] = useState<any[]>([])
  const [initialAdditionalEmails, setInitialAdditionalEmails] = useState<any[]>(
    []
  )
  const [teamsWebhookURL, setTeamsWebhookURL] = useState<string | null>(null)

  const [deleteProjectName, setDeleteProjectName] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [deletePopUpOpen, setDeletePopUpOpen] = useState(false)
  const navigate = useNavigate()
  const { pathIds } = useParams()
  const [projectHelperText, setProjectHelperText] = React.useState<string>('')
  const store = useStoreContext()
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.GENERAL_SETTINGS_CARD_PROJECT
  )
  const [projectDetails, setProjectDetails] = useState<any>(undefined)
  const [isDisconnecting, setIsDisconnecting] = useState(false)
  const [validationErrorResult, setValidationErrorResult] = useState<any[]>([])
  const [disableInputField, setDisableInputField] = useState<boolean>(false)
  const [consecutiveSpaceError, setConsecutiveSpaceError] =
    useState<boolean>(false)
  const nameReference = useRef<RefObject>(null)
  const targetSlackRef = useRef<HTMLDivElement>(null)
  const teamsWebhookUrlReference = useRef<RefObject>(null)
  const slackWebhookUrlReference = useRef<RefObject>(null)
  const additionalEmailsReference = useRef<RefObject>(null)
  const queryParams = new URLSearchParams(location.search)
  const scrollToBottom = queryParams.get('scrollToBottom')
  const [deleteButtonDisabled, setDeleteButtonDisabled] =
    useState<boolean>(false)

  const schema = Joi.object({
    name: Joi.string().pattern(RegexConstants.NAME_REGEX).required().messages({
      'string.empty': ValidationConstants.GENERIC.REQUIRED_VALUE,
      'string.pattern.base': ValidationConstants.GENERIC.INVALID_NAME_REGEX
    }),
    teamsWebhookURL: Joi.string()
      .uri({
        scheme: ['https', 'http']
      })
      .optional()
      .allow('')
      .messages({
        'string.uri': ValidationConstants.GENERIC.INVALID_URL,
        'string.uriCustomScheme': ValidationConstants.GENERIC.INVALID_URL
      }),
    additionalEmails: Joi.array().items(
      Joi.string()
        .email({ tlds: { allow: false } })
        .optional()
        .messages({
          'string.empty': ValidationConstants.GENERIC.REQUIRED_VALUE,
          'string.email': ValidationConstants.GENERIC.INVALID_EMAIL
        })
    )
  })

  useEffect(() => {
    if (scrollToBottom && targetSlackRef.current && !loading) {
      targetSlackRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [scrollToBottom, loading])

  useEffect(() => {
    setLoading(true)
    storeContext.scopeStore.setOrganizationId(
      AtoB(pathIds as string).split('/')[0] as string
    )
    getProjectById(projectId as string)
      .then((response: any) => {
        setLoading(false)
        setProjectName(response.data.name)
        setProjectType(response.data.type)
        setTeamsWebhookURL(response.data.teamsWebhookURL)
        if (response.data.additionalEmails) {
          setInitialAdditionalEmails(response.data.additionalEmails)
          setAdditionalEmails(response.data.additionalEmails)
        } else {
          setInitialAdditionalEmails([])
          setAdditionalEmails([])
        }
        setDeleteProjectName(response.data.name)
        setProjectDetails(response.data)
      })
      .catch((err: string) => {
        setLoading(false)
        navigate(
          replaceUrl(
            routeConstants.PROJECT_SETTINGS,
            storeContext.scopeStore.getScope()['organization-id'] as string,
            '',
            ''
          )
        )
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.PROJECT.FIND_PROJECT.ERROR
        })
      })
      .finally(() => storeContext.uiStore.setGlobalLoader(false))
    const projectCount = store.projectStore.getProjects().length
    if (projectCount === 1) setDeleteButtonDisabled(true)
  }, [storeContext.scopeStore.getProjects()])

  useEffect(() => {
    if (
      store.breadcrumbStore.getBreadcrumbsOptions().length === 0 &&
      !isEmpty(projectName)
    ) {
      storeContext.breadcrumbStore.addMultipleBreadCrumbOptions([
        { label: 'Projects', link: routeConstants.PROJECT_SETTINGS },
        { label: projectDetails.name, link: '/' }
      ])
    }

    store.breadcrumbStore.setGoBackLink(
      routeConstants.PROJECT_SETTINGS.replace(
        ':pathIds',
        ('/' + BtoA(store.scopeStore.getOrganizationId() as string)) as string
      ).replace(':projectId', projectId as string)
    )

    return () => {
      store.breadcrumbStore.reset()
    }
  }, [
    store.breadcrumbStore.getBreadcrumbsOptions(),
    store.scopeStore.getSelectedProject(),
    projectDetails
  ])
  /**
   * Function returns the object thats needs to be updated and the reference object of the respective input fields
   * @returns
   */
  const getUpdateObjectAndReference = (objectCanBeUpdated: any) => {
    if (
      objectCanBeUpdated.name &&
      objectCanBeUpdated.name !== projectDetails.name
    )
      return {
        updateObject: {
          name: objectCanBeUpdated.name.trim()
        },
        reference: nameReference
      }
    else if (
      (objectCanBeUpdated.teamsWebhookURL || projectDetails.teamsWebhookURL) &&
      objectCanBeUpdated.teamsWebhookURL !== projectDetails.teamsWebhookURL
    )
      return {
        updateObject: {
          teamsWebhookURL: isEmpty(objectCanBeUpdated.teamsWebhookURL)
            ? null
            : objectCanBeUpdated.teamsWebhookURL
        },
        reference: teamsWebhookUrlReference
      }
    else if (
      (objectCanBeUpdated.slackWebhookURL || projectDetails.slackWebhookURL) &&
      objectCanBeUpdated.slackWebhookURL !== projectDetails.slackWebhookURL
    )
      return {
        updateObject: {
          slackWebhookURL: isEmpty(objectCanBeUpdated.slackWebhookURL)
            ? null
            : objectCanBeUpdated.slackWebhookURL
        },
        reference: slackWebhookUrlReference
      }
    else if (
      (projectDetails.additionalEmails ||
        objectCanBeUpdated.additionalEmails.length > 0) &&
      objectCanBeUpdated.additionalEmails !== projectDetails.additionalEmails
    )
      return {
        updateObject: {
          additionalEmails: isEmpty(objectCanBeUpdated.additionalEmails)
            ? null
            : objectCanBeUpdated.additionalEmails
        },
        reference: additionalEmailsReference
      }
    else return null
  }

  const updateSingleProject = async () => {
    const projectValidationResult: ValidationResult = schema.validate(
      {
        name: projectName.trim(),
        teamsWebhookURL:
          teamsWebhookURL && !isEmpty(teamsWebhookURL)
            ? teamsWebhookURL.trim()
            : '',
        additionalEmails: additionalEmails
      },
      { abortEarly: false }
    )

    let objectCanBeUpdated = {}
    if (projectValidationResult.error) {
      setValidationErrorResult(projectValidationResult.error.details)
      objectCanBeUpdated = {
        ...(!getValidationStatus(projectValidationResult.error.details, 'name')
          ? { name }
          : {}),
        ...(!getValidationStatus(
          projectValidationResult.error.details,
          'teamsWebhookURL'
        )
          ? { teamsWebhookURL }
          : { teamsWebhookURL: projectDetails.teamsWebhookURL }),
        ...(!getValidationStatus(
          projectValidationResult.error.details,
          'additionalEmails'
        )
          ? { additionalEmails }
          : { additionalEmails: [] })
      }
    } else {
      setValidationErrorResult([])
      objectCanBeUpdated = {
        name: projectName,
        teamsWebhookURL,
        additionalEmails
      }
    }
    if (!isEqual(objectCanBeUpdated, {})) {
      setDisableInputField(true)

      const updateObjectAndReference =
        getUpdateObjectAndReference(objectCanBeUpdated)
      if (!updateObjectAndReference) {
        setDisableInputField(false)
        return
      }

      const { updateObject, reference } = updateObjectAndReference

      let isNameAvailable = true
      // check for name availability only if project name is changed
      if (updateObject.name) {
        reference.current?.startLoading()

        await checkNameAvailability(
          UrlConstants.CHECK_PROJECT_NAME_AVAILABILITY.USECASE,
          projectName.trim()
        )
          .then(() => {
            setProjectHelperText('')
          })
          .catch((error) => {
            isNameAvailable = false
            setDisableInputField(false)
            if (error === StringConstants.NAME_ALREADY_TAKEN) {
              setProjectHelperText(
                ValidationConstants.PROJECT.PROJECT_NAME_NOT_AVAILABLE
              )
            } else {
              ToastNotification({
                type: 'error',
                message: ToastMessageConstants.SOMETHING_WENT_WRONG
              })
            }
          })

        reference.current?.stopLoading()
        // exit function, if name is not available
        if (!isNameAvailable) return
      }

      reference.current?.startLoading()
      updateProject({
        ...updateObject,
        projectId: projectId as string
      })
        .then((response: any) => {
          ToastNotification({
            type: 'success',
            message: ToastMessageConstants.PROJECT.UPDATE_PROJECT.SUCCESS
          })
          setProjectName(response.data.name)
          if (!projectValidationResult.error) {
            setTeamsWebhookURL(response.data.teamsWebhookURL)
          }
          if (
            response.data.additionalEmails &&
            !projectValidationResult.error
          ) {
            setInitialAdditionalEmails(response.data.additionalEmails)
            setAdditionalEmails(response.data.additionalEmails)
          } else {
            setInitialAdditionalEmails([])
            setAdditionalEmails([])
          }
          setProjectDetails(response.data)
          setDisableInputField(false)

          // update only project name and slug in the scope store, since there no other fields are affected if project name is updated
          const projects = cloneDeep(
            storeContext.projectAndEnvironmentStore.getProjectsAndEnvironments()
          )

          const project_ = projects.find(
            (project: any) => project.id === response.data.id
          )
          if (!isUndefined(project_)) {
            project_.name = response.data.name
            project_.slug = response.data.slug
          }
          storeContext.projectAndEnvironmentStore.setProjectsAndEnvironments(
            projects
          )

          storeContext.scopeStore.setProjects(projects)
        })
        .catch((err: string) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.PROJECT.UPDATE_PROJECT.ERROR
          })
          setTeamsWebhookURL(null)
          setAdditionalEmails([])
          setDisableInputField(false)
        })
        .finally(() => {
          reference.current?.stopLoading()
        })
    }
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    // Check for consecutive spaces
    if (inputValue.includes('  ')) {
      setConsecutiveSpaceError(true)
    } else {
      setConsecutiveSpaceError(false)
    }
    setProjectName(inputValue)
  }

  const handleDeleteProject = () => {
    setLoading(true)
    deleteProject(projectId as string)
      .then(() => {
        gaEventTracker(gaEventConstants.DELETE_PROJECT)
        setDeletePopUpOpen(false)
        storeContext.projectStore.setProjects([])
        if (projectId === storeContext.scopeStore.getProjectId())
          storeContext.scopeStore.setProjectId('')
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.PROJECT.DELETE_PROJECT.SUCCESS
        })
        navigate(
          replaceUrl(
            routeConstants.PROJECT_SETTINGS,
            storeContext.scopeStore.getOrganizationId() as string,
            '',
            ''
          )
        )
      })
      .catch((err: string) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.PROJECT.DELETE_PROJECT.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
        setDeletePopUpOpen(false)
      })
  }
  const handleDisconnectSlack = () => {
    setIsDisconnecting(true)
    disconnectSlackFromProject(projectId as string)
      .then(() => {
        setProjectDetails((prevDetails: any) => ({
          ...prevDetails,
          isSlackConfigured: false
        }))
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.PROJECT.SLACK_DISCONNECTION.SUCCESS
        })
      })
      .catch((err: string) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.PROJECT.SLACK_DISCONNECTION.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
        setIsDisconnecting(false)
      })
  }

  const getDeleteButtonTooltip = () => {
    if (store.userStore.getNoActiveSubscription()) {
      return 'Uh-oh! Your subscription has ended. Please renew it to delete this project.'
    } else if (store.userStore.getUserRole() !== OrganizationUserRoles.OWNER) {
      return `Oops! It looks like you don't have permission to delete this project. Please contact your admin for help.`
    } else if (store.scopeStore.getProjects().length === 1) {
      return 'Hey, you need at least one project in your organisation. Please add another before deleting this one.'
    }
  }

  return (
    <>
      <DashboardSectionContainer
        headerText='General Settings'
        minHeight='33.5em'
      >
        {!loading ? (
          <>
            {' '}
            <IndividualSettingContainer>
              <SettingsLeft>
                <TypographyDDS.Title
                  type='h5'
                  variant='bold'
                  style={{ padding: '0 0 16px 0' }}
                >
                  Project Name
                </TypographyDDS.Title>
                <TypographyDDS.Title
                  type='h5'
                  variant='medium'
                  color='textDark3'
                >
                  Customize each project with a unique name
                </TypographyDDS.Title>
              </SettingsLeft>
              <SettingsRight>
                <InputField
                  width='100%'
                  label='Project Name'
                  size='medium'
                  value={projectName}
                  onChange={handleInputChange}
                  onBlur={() => {
                    if (!consecutiveSpaceError) {
                      if (projectName.trim() !== projectDetails.name) {
                        updateSingleProject()
                        setDeleteProjectName(projectName)
                      } else if (projectName.trim() === projectDetails.name) {
                        setProjectHelperText('')
                        setValidationErrorResult([])
                      }
                      setProjectName(projectName.trim())
                    }
                    setProjectName(projectName.trim())
                  }}
                  maxLength={StringConstants.NAME_FIELD_MAX_LENGTH}
                  ref={nameReference}
                  helperText={
                    consecutiveSpaceError
                      ? ValidationConstants.PROJECT
                          .PROJECT_NAME_CONSECUTIVE_SPACE
                      : getValidationError(validationErrorResult, 'name') ||
                        projectHelperText
                  }
                  error={
                    getValidationStatus(validationErrorResult, 'name') ||
                    !isEmpty(projectHelperText) ||
                    consecutiveSpaceError
                  }
                  disabled={
                    storeContext.userStore.getNoActiveSubscription() ||
                    store.userStore.getUserRole() !==
                      OrganizationUserRoles.OWNER ||
                    disableInputField
                  }
                />
              </SettingsRight>
            </IndividualSettingContainer>
            <IndividualSettingContainer>
              <SettingsLeft>
                <TypographyDDS.Title
                  type='h5'
                  variant='bold'
                  style={{ padding: '0 0 16px 0' }}
                >
                  Project Type
                </TypographyDDS.Title>
                <TypographyDDS.Title
                  type='h5'
                  variant='medium'
                  color='textDark3'
                >
                  Salesforce / Mulesoft / Full-stack
                </TypographyDDS.Title>
              </SettingsLeft>
              <SettingsRight>
                <InputField
                  width='100%'
                  label='Project Type'
                  size='medium'
                  value={
                    projectType
                      ? ProjectTypeDisplayNames[
                          projectType as keyof typeof ProjectTypeDisplayNames
                        ]
                      : '-'
                  }
                  disabled={true}
                />
              </SettingsRight>
            </IndividualSettingContainer>
            <IndividualSettingContainer>
              <SettingsLeft>
                <TypographyDDS.Title
                  type='h5'
                  variant='bold'
                  style={{ padding: '0 0 16px 0' }}
                >
                  Delete Project
                </TypographyDDS.Title>
                <TypographyDDS.Title
                  type='h5'
                  variant='medium'
                  color='textDark3'
                >
                  {TextContentConstants.DELETE_PROJECT}
                </TypographyDDS.Title>
              </SettingsLeft>
              <SettingsRight>
                {deleteButtonDisabled ? (
                  <Tooltip
                    title={getDeleteButtonTooltip() as string}
                    arrow
                    PopperProps={{
                      sx: {
                        '& .MuiTooltip-tooltip': {
                          backgroundColor: `${palette.colors.white}`,
                          boxShadow: `0 16px 25px ${palette.colors.tooltipShadow}`,
                          color: `${palette.colors.black}`,
                          fontSize: '12px'
                        },
                        '& .MuiTooltip-arrow': {
                          color: `${palette.colors.white}`
                        }
                      }
                    }}
                    placement='top'
                  >
                    <ButtonWrapper>
                      <Button
                        // background={`linear-gradient(270deg, ${palette.colors.error} 0%, ${palette.colors.error} 100%)`}
                        startIcon={
                          <img
                            src={DeleteIcon}
                            style={{ filter: palette.colors.whiteSvgFilter }}
                          />
                        }
                        variant='contained'
                        size='medium'
                        fullWidth
                        color='error'
                        disabled={
                          storeContext.userStore.getNoActiveSubscription() ||
                          store.userStore.getUserRole() !==
                            OrganizationUserRoles.OWNER ||
                          deleteButtonDisabled
                        }
                      >
                        Delete Project
                      </Button>
                    </ButtonWrapper>
                  </Tooltip>
                ) : (
                  <ButtonWrapper>
                    <Button
                      // background={`linear-gradient(270deg, ${palette.colors.error} 0%, ${palette.colors.error} 100%)`}
                      startIcon={
                        <img
                          src={DeleteIcon}
                          style={{ filter: palette.colors.whiteSvgFilter }}
                        />
                      }
                      onClick={() => {
                        gaEventTracker(
                          gaEventConstants.INITIATING_DELETE_PROJECT
                        )
                        setDeletePopUpOpen(true)
                      }}
                      variant='contained'
                      size='medium'
                      fullWidth
                      color='error'
                      disabled={
                        storeContext.userStore.getNoActiveSubscription() ||
                        store.userStore.getUserRole() !==
                          OrganizationUserRoles.OWNER ||
                        disableInputField ||
                        deleteButtonDisabled
                      }
                    >
                      Delete Project
                    </Button>
                  </ButtonWrapper>
                )}
              </SettingsRight>
            </IndividualSettingContainer>
            <hr />
            <TypographyDDS.Title
              type='h4'
              variant='bold'
              style={{
                paddingTop: '20px',
                paddingBottom: '20px',
                display: 'flex',
                gap: '5px',
                flexDirection: 'row'
              }}
            >
              Notifications
            </TypographyDDS.Title>
            <div ref={targetSlackRef}>
              <IndividualSettingContainer>
                <SettingsLeft>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <TypographyDDS.Title
                      type='h5'
                      variant='bold'
                      style={{ padding: '0 0 16px 0' }}
                    >
                      Slack Notification
                    </TypographyDDS.Title>
                  </div>
                  <TypographyDDS.Title
                    type='h5'
                    variant='medium'
                    color='textDark3'
                  >
                    Get notified instantly on Slack
                  </TypographyDDS.Title>
                </SettingsLeft>

                <SettingsRight>
                  {process.env.REACT_APP_SLACK_INTEGRATION_URL && (
                    <>
                      {projectDetails?.isSlackConfigured ? (
                        <Tooltip
                          title={
                            !storeContext.userStore.getNoActiveSubscription() &&
                            store.userStore.getUserRole() ===
                              OrganizationUserRoles.OWNER
                              ? ''
                              : storeContext.userStore.getNoActiveSubscription()
                                ? 'Please upgrade your plan to add a Slack channel'
                                : 'Access to modify restricted to owners only'
                          }
                          arrow
                        >
                          <span>
                            <Button
                              onClick={handleDisconnectSlack}
                              iconSize='24px'
                              startIcon={<img src={Slack} />}
                              variant='outlined'
                              size='medium'
                              color='error'
                              disabled={
                                storeContext.userStore.getNoActiveSubscription() ||
                                store.userStore.getUserRole() !==
                                  OrganizationUserRoles.OWNER
                              }
                            >
                              Disconnect Slack
                            </Button>
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={
                            !storeContext.userStore.getNoActiveSubscription() &&
                            store.userStore.getUserRole() ===
                              OrganizationUserRoles.OWNER
                              ? ''
                              : storeContext.userStore.getNoActiveSubscription()
                                ? 'Please upgrade your plan to add a Slack channel'
                                : 'Access to modify restricted to owners only'
                          }
                          arrow
                        >
                          <a
                            href={
                              !storeContext.userStore.getNoActiveSubscription() &&
                              store.userStore.getUserRole() ===
                                OrganizationUserRoles.OWNER
                                ? getAddToSlackUrl(
                                    storeContext.scopeStore.getOrganizationId() as string,
                                    projectId as string
                                  )
                                : '#'
                            }
                            style={{
                              cursor:
                                !storeContext.userStore.getNoActiveSubscription() &&
                                store.userStore.getUserRole() ===
                                  OrganizationUserRoles.OWNER
                                  ? 'pointer'
                                  : 'not-allowed',
                              opacity:
                                !storeContext.userStore.getNoActiveSubscription() &&
                                store.userStore.getUserRole() ===
                                  OrganizationUserRoles.OWNER
                                  ? 1
                                  : 0.5,
                              textDecoration: 'none'
                            }}
                          >
                            <img
                              alt='Connect Slack'
                              height='40'
                              width='150'
                              src='https://platform.slack-edge.com/img/add_to_slack.png'
                              srcSet='https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x'
                            />
                          </a>
                        </Tooltip>
                      )}
                    </>
                  )}
                  {projectDetails?.isSlackConfigured && (
                    <TypographyDDS.Title
                      type='h5'
                      variant='medium'
                      color='textDark3'
                    >
                      Connected
                      {projectDetails.slackChannelName &&
                        ` to ${projectDetails.slackChannelName}`}
                    </TypographyDDS.Title>
                  )}
                </SettingsRight>
              </IndividualSettingContainer>
            </div>
            <IndividualSettingContainer>
              <SettingsLeft>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <TypographyDDS.Title
                    type='h5'
                    variant='bold'
                    style={{ padding: '0 0 16px 0' }}
                  >
                    Teams Webhook URL
                  </TypographyDDS.Title>
                  <LinkTooltip
                    title={
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <img
                          src={linkIcon}
                          style={{ marginRight: '8px', cursor: 'pointer' }}
                        />
                        <a
                          href={process.env.REACT_APP_TEAMS_INTEGRATION_S3_URL}
                          style={{ textDecoration: 'none' }}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <TypographyDDS.Paragraph
                            size='para'
                            variant='medium'
                            color='primary'
                          >
                            Read User Documentation
                          </TypographyDDS.Paragraph>
                        </a>
                      </div>
                    }
                  >
                    <img
                      src={infoIcon}
                      style={{ marginLeft: '0.5em', alignSelf: 'start' }}
                    />
                  </LinkTooltip>
                </div>
                <TypographyDDS.Title
                  type='h5'
                  variant='medium'
                  color='textDark3'
                >
                  Get notified instantly on Microsoft Teams
                </TypographyDDS.Title>
              </SettingsLeft>
              <SettingsRight>
                <InputField
                  width='100%'
                  label='Webhook URL'
                  size='medium'
                  value={teamsWebhookURL ?? undefined}
                  placeholder='Enter Teams Webhook URL'
                  onChange={(e) =>
                    setTeamsWebhookURL(
                      isEmpty(e.target.value) ? null : e.target.value
                    )
                  }
                  onBlur={() => {
                    updateSingleProject()
                  }}
                  ref={teamsWebhookUrlReference}
                  helperText={getValidationError(
                    validationErrorResult,
                    'teamsWebhookURL'
                  )}
                  error={getValidationStatus(
                    validationErrorResult,
                    'teamsWebhookURL'
                  )}
                  disabled={
                    storeContext.userStore.getNoActiveSubscription() ||
                    disableInputField ||
                    store.userStore.getUserRole() !==
                      OrganizationUserRoles.OWNER
                  }
                />
              </SettingsRight>
            </IndividualSettingContainer>
            <IndividualSettingContainer>
              <SettingsLeft>
                <TypographyDDS.Title
                  type='h5'
                  variant='bold'
                  style={{ padding: '0 0 16px 0' }}
                >
                  Additional Emails
                </TypographyDDS.Title>
                <TypographyDDS.Title
                  type='h5'
                  variant='medium'
                  color='textDark3'
                >
                  Get notified on emails which are not part of Vigil
                </TypographyDDS.Title>
              </SettingsLeft>
              <SettingsRight>
                <MultiTextInputField
                  label='Additional Emails'
                  placeholder='Enter additional emails'
                  value={additionalEmails}
                  onChange={(value: any) => {
                    setAdditionalEmails([
                      ...(isArray(value) ? [...value] : [value])
                    ])
                  }}
                  onDeleteSelection={(value) => {
                    setAdditionalEmails(
                      additionalEmails.filter((option: any) => option !== value)
                    )
                  }}
                  onBlur={() => {
                    if (!isEqual(additionalEmails, initialAdditionalEmails)) {
                      updateSingleProject()
                    }
                  }}
                  ref={additionalEmailsReference}
                  helperText={getValidationError(
                    validationErrorResult,
                    'additionalEmails'
                  )}
                  error={getValidationStatus(
                    validationErrorResult,
                    'additionalEmails'
                  )}
                  disabled={
                    storeContext.userStore.getNoActiveSubscription() ||
                    disableInputField ||
                    store.userStore.getUserRole() !==
                      OrganizationUserRoles.OWNER
                  }
                />
              </SettingsRight>
            </IndividualSettingContainer>
          </>
        ) : (
          <SpinnerWrapper className='spinner-wrapper'>
            <Spinner />
          </SpinnerWrapper>
        )}
      </DashboardSectionContainer>

      {/* PopUp Delete Account */}
      <TextConfirmationPopup
        open={deletePopUpOpen}
        handleClose={() => setDeletePopUpOpen(false)}
        confirmationText={`Delete Project ${projectName}`}
        handleSubmit={handleDeleteProject}
        context='This project will be deleted permanently'
        heading='Delete Project'
        loading={loading}
      />
    </>
  )
}

export default observer(GeneralSettingsCard)
