import {
  gaEventConstants,
  gaPageConstants
} from '../../../../constants/GAConstants'
import { useNavigate, useParams } from 'react-router-dom'

import DashboardSectionContainer from '../../DashboardSectionContainer/DashboardSectionContainer'
import React from 'react'
import { TypographyDDS } from '../../Typography/TypographyDDS'
import breakpoints from '../../../../global/breakpoints'
import { css } from '@emotion/react'
import palette from '../../../../global/pallete'
import styled from '@emotion/styled'
import { useAnalyticsEventTracker } from '../../../../utils/GAUtils'
import { useObserver } from 'mobx-react-lite'
import { useStoreContext } from '../../../../store/StoreContext'

interface ProjectPageNavigationProps {
  title?: string
}

interface ItemProps {
  selected?: boolean
}

const Items = [
  { id: 1, title: 'General Settings', link: './' },
  {
    id: 2,
    title: 'Members',
    link: './members'
  },
  { id: 3, title: 'Environments', link: './environments' }
  // {
  //   id: 4,
  //   title: (
  //     <div
  //       style={{
  //         width: '100%',
  //         display: 'flex',
  //         gridGap: '0.625em',
  //         justifyContent: 'space-between',
  //         alignItems: 'center',
  //       }}
  //     >
  //       <div>Teams </div>
  //       <Chip
  //         fontSize='0.5em'
  //         size='small'
  //         label='Coming soon'
  //         highlighted={true}
  //       />
  //     </div>
  //   ),
  //   link: '/comingSoon',
  // },
]

const ItemsContainer = styled.div`
  display: flex;
  width: 50%;
  gap: 1em;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  max-height: 32.5em;
`
const Item = styled.div<ItemProps>`
  padding: 1em 2em 1em 2em;
  flex-basis: auto;
  width: 100%;
  border-radius: 0.5em;
  &:hover {
    background-color: ${palette.colors.borderColor};
  }
  ${(props) =>
    props.selected &&
    css`
      background-color: ${palette.colors.primary};
      color: ${palette.colors.white};
      box-shadow: 0 1em 1.5625em rgba(71, 78, 93, 0.3);
      &:hover {
        background-color: ${palette.colors.primary};
      }
    `}
`
const Container = styled.div`
  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.85rem;
  }
`

const ProjectPageNavigation: React.FC<ProjectPageNavigationProps> = () => {
  const navigate = useNavigate()
  const [selectedOption, setSelectedOption] = React.useState(1)
  const { projectId } = useParams()
  const TabItemHandler = () => {
    Items.map((item, index) => {
      if (window.location.pathname.includes(item.link.split('/')[1]))
        setSelectedOption(index + 1)
    })
  }
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.PROJECT_PAGE_NAVIGATION
  )
  const store = useStoreContext()
  //If direct access through URL or through browser navigation then for highlighting the correct tab
  React.useEffect(() => {
    TabItemHandler()
  }, [window.location.pathname])
  return useObserver(() => (
    <Container>
      <DashboardSectionContainer
        padding='1.25em 1.875em'
        headerText={store.scopeStore.getProjectName(projectId)}
      >
        <ItemsContainer>
          {Items.map((item, index) => (
            <Item
              key={index}
              onClick={() => {
                if (item.id !== selectedOption) {
                  gaEventTracker(
                    gaEventConstants.SWITCH_BETWEEN_PROJECT_COMPONENTS
                  )
                  navigate(item.link)
                  TabItemHandler()
                }
              }}
              selected={item.id === selectedOption ? true : false}
              style={{
                cursor: 'pointer'
              }}
            >
              <TypographyDDS.Paragraph
                size='para'
                variant='semiBold'
                color={item.id === selectedOption ? 'white' : 'textDark2'}
              >
                {item.title}
              </TypographyDDS.Paragraph>
            </Item>
          ))}
        </ItemsContainer>
      </DashboardSectionContainer>
    </Container>
  ))
}

export default ProjectPageNavigation
