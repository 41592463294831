import React, { CSSProperties } from 'react'

import { DDSContextSwitcherTab } from 'den-design-system'

interface ContextSwitcherTabsProps {
  tabOptions: any[]
  shape: any
  id?: string
  className?: string
  style?: CSSProperties
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  onBlur?: (e: React.FocusEvent<HTMLButtonElement>) => void
  tabStyle?: CSSProperties
  tabClassName?: string
  tabTitleStyles?: CSSProperties
  tabTitleClassName?: string
  activeTab?: string
  onChange?: (activeTab: string) => void
  disabled?: boolean
}

const ContextSwitcherTabs: React.FC<ContextSwitcherTabsProps> = ({
  tabOptions,
  shape,
  id,
  className,
  style,
  onClick,
  onBlur,
  tabStyle,
  tabClassName,
  tabTitleStyles,
  tabTitleClassName,
  activeTab,
  onChange,
  disabled
}) => {
  const disabledStyle: CSSProperties = disabled
    ? {
        cursor: 'not-allowed',
        pointerEvents: 'none',
        opacity: 0.8
      }
    : { cursor: 'pointer', pointerEvents: 'auto' }

  return (
    <div style={disabledStyle}>
      <DDSContextSwitcherTab
        tabOptions={tabOptions}
        shape={shape}
        id={id}
        className={className}
        style={{ ...style, ...disabledStyle }}
        onClick={disabled ? undefined : onClick}
        onBlur={onBlur}
        tabStyle={{ ...tabStyle, ...disabledStyle }}
        tabClassName={tabClassName}
        tabTitleStyles={{ ...tabTitleStyles, ...disabledStyle }}
        tabTitleClassName={tabTitleClassName}
        activeTab={activeTab}
        onChange={onChange}
      />
    </div>
  )
}

export default ContextSwitcherTabs
