import React from 'react'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

// Define the styled component
const BottomSectionWrapper = styled.div`
  background-color: ${palette.colors.lightBack4};
  ::-webkit-scrollbar {
    height: 0;
    transition: height 0.4s;
  }
  &:hover {
    ::-webkit-scrollbar {
      height: 2px;
      transition: height 0.4s;
    }
  }
`
interface SettingBottomBarProps {
  children: any
}

export const SettingBottomBar: React.FC<SettingBottomBarProps> = ({
  children
}) => {
  return <BottomSectionWrapper>{children}</BottomSectionWrapper>
}
