import {
  AtoB,
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../utils/UrlUtils'
import { DDSChips, DDSTooltip, DDSTypography } from 'den-design-system'
import React, { useEffect, useRef, useState } from 'react'
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import {
  findRecords,
  getAPIFilters
} from '../../api/apiMonitoring/ApiMonitoring.service'
import { gaEventConstants, gaPageConstants } from '../../constants/GAConstants'
import { isEmpty, isNull } from 'lodash'
import { msToTimeUnits, timeZone } from '../../utils/DateTimeUtils'

import ApiGroupingStringConstants from '../../constants/ApiGroupingStringConstants'
import { ChevronRight } from '@carbon/icons-react'
import Filter from '../Filter/Filter'
import { Link } from '@mui/material'
import NoDataImage from '../Overview/NoDataImage'
import NoFilterImage from '../../assets/images/folder-not-found 1.svg'
import NoRecord from '../common/NoContent/NoRecord'
import NoRecordImage from '../../assets/images/setting.svg'
import { OrganizationType } from '../../enum/OrganizationType.enum'
import { ProjectType } from '../../enum/ProjectType.enum'
import StringConstants from '../../constants/StringConstants'
import Table from '../common/DDS/Table/Table'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../common/DDS/Toast/Toast'
import { mapStatusCodeToColor } from '../../utils/ApiStatusColorCode'
import { observer } from 'mobx-react-lite'
import pallete from '../../global/newPallete'
import routeConstants from '../../constants/RouteConstants'
import { timeFormats } from '../../enum/TIME'
import { useAnalyticsEventTracker } from '../../utils/GAUtils'
import { useStoreContext } from '../../store/StoreContext'

const AllAPI: React.FC = () => {
  const store = useStoreContext()
  const [options, setOptions] = useState<any>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = React.useState(false)
  const [totalCount, setTotalCount] = useState<number>()
  const [data, setData] = useState<any>([])
  const typographyRef = useRef<HTMLDivElement | null>(null)
  const [showTooltip, setShowTooltip] = useState(false)

  const { pathIds } = useParams()
  const pageSize = StringConstants.DEFAULT_TABLE_SIZE
  const [currentPage, setCurrentPage] = useState<number>(1)
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.API_MONITORING_TABLE
  )

  const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)

  function fetchAllRecords() {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    setData([])
    findRecords({ ...queryParams, size: pageSize })
      .then((apiReport: any) => {
        if (
          apiReport.data.length > 0 ||
          store.filterStore.getFiltersApplied()
        ) {
          store.filterStore.setFilterDisabled(false)
        } else {
          store.filterStore.setFilterDisabled(true)
        }
        setData(apiReport.data)
        store.apiDetailsStore.setRefresh(false)
        setTotalCount(apiReport.totalCount)
        setLoading(false)

        store.filterStore.setFilters(apiReport.filters)
      })
      .catch((err) => {
        setLoading(false)
      })
      .finally(() => {
        store.uiStore.setGlobalLoader(false)
      })
  }
  const navigate = useNavigate()

  useEffect(() => {
    if (!isNull(searchParams.get('response-status-code'))) fetchAllRecords()
    const checkWidth = () => {
      if (typographyRef.current) {
        const width = typographyRef.current.getBoundingClientRect().width
        setShowTooltip(width === 190)
      }
    }

    checkWidth()
    window.addEventListener('resize', checkWidth)

    return () => window.removeEventListener('resize', checkWidth)
  }, [])

  useEffect(() => {
    const pageFromQueryParams = searchParams.get('page')
    if (!isNull(pageFromQueryParams)) {
      setCurrentPage(Number(pageFromQueryParams))
    } else {
      setCurrentPage(1)
    }
    if (
      !isEmpty(AtoB(pathIds as string).split('/')[1]) &&
      !isEmpty(AtoB(pathIds as string).split('/')[2])
    ) {
      store.scopeStore.setProjectId(
        AtoB(pathIds as string).split('/')[1] as string
      )
      store.scopeStore.setEnvironmentId(
        AtoB(pathIds as string).split('/')[2] as string
      )
    }
    const getPage = searchParams.get('page')
    if (!getPage) {
      setSearchParams(
        {
          ...queryParams
        },
        { replace: true }
      )
    }
    const projectId = store.scopeStore.getScope()['project-id']
    const environmentId = store.scopeStore.getScope()['environment-id']
    if (
      !loading &&
      projectId != '' &&
      environmentId != '' &&
      store.userStore.getSelectedOrganization().type !==
        OrganizationType.SALESFORCE_ECOSYSTEM_ONLY &&
      store.scopeStore.getSelectedProject().type !== ProjectType.SALESFORCE_ONLY
    ) {
      fetchAllRecords()
    }
  }, [
    location.search,
    store.scopeStore.getScope(),
    store.scopeStore.getSelectedProject(),
    store.scopeStore.getSelectedEnvironment()
  ])
  useEffect(() => {
    if (store.apiDetailsStore.getRefresh() === true) {
      fetchAllRecords()
    }
  }, [store.apiDetailsStore.getRefresh()])

  useEffect(() => {
    store.filterStore.setFilterDisabled(true)
    getAPIFilters()
      .then((response: any) => {
        setOptions(response.data)
        store.filterStore.setFilters(response.data)
      })
      .catch((err) => {
        return ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.API_MONITORING.API_MONITORING_FILTERS.ERROR
        })
      })
  }, [
    store.scopeStore.getEnvironmentId(),
    store.scopeStore.getSelectedProject(),
    store.scopeStore.getSelectedEnvironment()
  ])

  const routeGroupedDetails = (id: number) => {
    const route = replaceUrl(
      routeConstants.VIEW_ALL_API,
      store.scopeStore.getScope()['organization-id'] as string,
      store.scopeStore.getScope()['project-id'] as string,
      store.scopeStore.getScope()['environment-id'] as string
    ).replace(':id', String(id))
    const queryParams = { tab: '2' }
    navigate({
      pathname: route,
      search: `?${createSearchParams(queryParams)}`
    })
  }

  const allColumn = [
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.URL_TEMPLATE}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'urlTemplate',
      render: (text: any) => (
        <DDSTooltip
          id={`${text}`}
          label={
            <DDSTypography.Paragraph
              reference={typographyRef}
              size='para'
              variant='regular'
              color={pallete.colors.textDark4}
            >
              {text}
            </DDSTypography.Paragraph>
          }
          shape='roundedRectangle'
          position='top'
          className={`override-tooltip-arrow ${
            typeof text === 'string' && text.length > 15 ? text : '!hidden'
          }`}
          style={{
            backgroundColor: pallete.colors.surface1,
            zIndex: 999999
          }}
        >
          <div className='flex justify-start text-start row items-start'>
            <DDSTypography.Paragraph
              size='para'
              reference={typographyRef}
              variant='medium'
              color={pallete.colors.textDark4}
              className='tooltip-container sm:block overflow-hidden truncate w-[120px]'
            >
              {text || text === 0 ? text : '-'}
            </DDSTypography.Paragraph>
          </div>
        </DDSTooltip>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.HTTP_METHOD}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'httpMethod',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
          >
            {text || text === 0 ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },

    {
      columnHeaderStyle: {},
      columnDataStyle: { width: '120px' },
      heading: (
        <div className='flex justify-center text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.END_POINTS}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'url',
      render: (text: any) => (
        <DDSTooltip
          id={`${text}`}
          label={
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark4}
            >
              {text}
            </DDSTypography.Paragraph>
          }
          shape='roundedRectangle'
          position='top'
          className={`override-tooltip-arrow ${
            typeof text === 'string' && text.length > 20 ? text : '!hidden'
          }`}
          style={{ backgroundColor: pallete.colors.surface1, zIndex: 999999 }}
        >
          <div className='flex justify-center text-center row items-center w-[120px] override-tooltip-arrow'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className='tooltip-container sm:block overflow-hidden truncate w-[120px]'
            >
              {text || text === 0 ? text : '-'}
            </DDSTypography.Paragraph>
          </div>
        </DDSTooltip>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: { width: '120px' },
      heading: (
        <div className='flex row justify-center text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.START_TIME}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'startTime',
      render: (text: any) => (
        <DDSTooltip
          id={`${text}`}
          label={
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark4}
            >
              {timeZone(
                store.userStore.getUserPreference().timeZone,
                text,
                timeFormats.fullMonthDateYearTimeWithSeconds
              )}
            </DDSTypography.Paragraph>
          }
          shape='roundedRectangle'
          position='top'
          className={`override-tooltip-arrow ${
            typeof text === 'string' && text.length > 20 ? text : '!hidden'
          }`}
          style={{ backgroundColor: pallete.colors.surface1, zIndex: 999999 }}
        >
          <div className='flex justify-center text-center row items-center w-[120px] '>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className='tooltip-container sm:block overflow-hidden truncate'
            >
              {timeZone(
                store.userStore.getUserPreference().timeZone,
                text,
                timeFormats.fullMonthDateYearTimeWithSeconds
              )}
            </DDSTypography.Paragraph>
          </div>
        </DDSTooltip>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center items-center text-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.RESPONSE_STATUS}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'responseStatusCode',
      render: (text: any) => (
        <div className='flex row justify-center items-center text-center override-statusCode-center override-status-chip'>
          <DDSChips
            label={text}
            type='filled'
            shape='rounded'
            size='small'
            style={{
              backgroundColor: mapStatusCodeToColor(text),
              borderColor: 'transparent',
              height: '24px',
              width: '60px',
              justifyContent: 'center'
            }}
          />
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: { width: '120px' },
      heading: (
        <div className='flex row items-center justify-center text-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.CLIENT_VERSION}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'clientVersion',
      render: (text: any) => (
        <DDSTooltip
          id={`${text}`}
          label={
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark4}
            >
              {text}
            </DDSTypography.Paragraph>
          }
          shape='roundedRectangle'
          position='top'
          className={` override-tooltip-arrow ${
            typeof text === 'string' && text.length > 20 ? text : '!hidden'
          }`}
          style={{ backgroundColor: pallete.colors.surface1, zIndex: 999999 }}
        >
          <div className='flex justify-center text-center row items-center'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className='tooltip-container sm:block overflow-hidden truncate w-[120px]'
            >
              {text || text === 0 ? text : '-'}
            </DDSTypography.Paragraph>
          </div>
        </DDSTooltip>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.RESPONSE_TIME}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'responseTimeInMilliseconds',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
          >
            {text ? msToTimeUnits(text) : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='cursor-pointer'>
          <ChevronRight
            size={16}
            onClick={() => {
              gaEventTracker(gaEventConstants.VIEW_SINGLE_API_RECORD)
              routeGroupedDetails(fullItem.id)
            }}
            color={pallete.colors.FillButton}
          />
        </div>
      )
    }
  ]

  return (
    <>
      <div>
        <div className='w-[100%] relative h-[36px] mt-[16px] mr-[12px]  flex top-[0px] right-[0px]'>
          <Filter
            dropdownLabel='Filter'
            options={options}
            disabled={
              (totalCount === 0 && !store.filterStore.getFiltersApplied()) ||
              loading
            }
          />
        </div>
        {loading || (Array.isArray(data) && data.length !== 0) ? (
          <>
            <div className='py-[24px]'>
              <Table
                tableStyle={{
                  borderColor: pallete.colors.stroke2
                }}
                data={data}
                columns={allColumn}
                totalCount={totalCount as number}
                rowsPerPage={10}
                currentPage={currentPage}
                onPageChange={(page: number) => {
                  const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                    location.search
                  )
                  setSearchParams({
                    ...allQueryParams,
                    page: page.toString()
                  })
                  setCurrentPage(page)
                }}
                loading={loading}
              />
            </div>
          </>
        ) : (
          <>
            <NoRecord
              imageSrc={
                store.filterStore.getFiltersApplied()
                  ? NoFilterImage
                  : NoRecordImage
              }
              style={{ height: '74vh' }}
              text={
                store.filterStore.getFiltersApplied() ? (
                  StringConstants.NO_RECORDS_FILTER
                ) : (
                  <>
                    Configure API Monitoring.
                    <Link
                      href={StringConstants.VIGIL_SDK_URL}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{ marginLeft: '4px' }}
                    >
                      Learn More
                    </Link>
                  </>
                )
              }
            />
          </>
        )}
      </div>
    </>
  )
}

export default observer(AllAPI)
