import Joi, { ValidationErrorItem, ValidationResult } from 'joi'
import React, { useState } from 'react'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../../constants/GAConstants'

import AddDomainsCard from '../../DomainCards/AddDomainsCard'
import Button from '../../Button/Button'
import { EnvironmentType } from '../../../../enum/environment.enum'
import FilledTabGroup from '../../TabGroup/FilledTabGroup'
import InputField from '../../InputField/InputField'
import { IoIosArrowForward } from 'react-icons/io'
import Popup from '../../Popup/Popup'
import RegexConstants from '../../../../constants/RegexConstants'
import StringConstants from '../../../../constants/StringConstants'
import TabGroup from '../../TabGroup/TabGroup'
import { ToastMessageConstants } from '../../../../constants/ToastMessageConstants'
import ToastNotification from '../../DDS/Toast/Toast'
import { Tooltip } from '@material-ui/core'
import Typography from '../../Typography/Typography'
import UrlConstants from '../../../../constants/UrlConstants'
import ValidationConstants from '../../../../constants/ValidationConstants'
import { checkNameAvailability } from '../../../../api/common/Common.service'
import { createEnvironment } from '../../../../api/environment/Environment.service'
import { getEnvironmentLabelsForOrganization } from '../../../../utils/OrganizationUtils'
import { getValidationStatus } from '../../../../utils/ValidationUtils'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import palette from '../../../../global/pallete'
import styled from '@emotion/styled'
import { useAnalyticsEventTracker } from '../../../../utils/GAUtils'
import { useParams } from 'react-router-dom'
import { useStoreContext } from '../../../../store/StoreContext'

interface CreateEnvironmentPopupProps {
  open: boolean
  handleClose: () => void
  refreshData: () => void
  projectID?: string
}

interface ItemContainerProps {
  width?: string
  height?: string
  padding?: string
  margin?: string
}

const PopUpSectionWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 16px 48px;
`

const Left = styled.div`
  width: 50%;
  overflow: hidden;
`

const Right = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
  grid-gap: 20px;
  width: 50%;
`

const ItemContainer = styled.div<ItemContainerProps>`
  width: 31em;
  max-width: 100%;
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`
const ProjectNameWrapper = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

// validation schema
const validationSchema = Joi.object({
  environmentName: Joi.string().pattern(RegexConstants.NAME_REGEX).required(),
  environmentType: Joi.string().valid(...Object.values(EnvironmentType))
})

const CreateEnvironmentPopUp: React.FC<CreateEnvironmentPopupProps> = ({
  open,
  handleClose,
  refreshData,
  projectID
}) => {
  const storeContext = useStoreContext()
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.CREATE_ENVIRONMENT
  )
  const [tabLabels, setTabLabels] = useState<any>([])
  React.useEffect(() => {
    if (!storeContext.userStore.getNoActiveSubscription())
      setTabLabels(
        getEnvironmentLabelsForOrganization(
          storeContext.userStore.getOrganizations(),
          storeContext.scopeStore.getOrganizationId() as string
        )
      )
  }, [])

  let projectId: string
  if (!projectID) {
    projectId = useParams().projectId || ''
  } else {
    projectId = projectID
  }

  const [currentStep, setCurrentStep] = useState<number>(0)
  const [environmentName, setEnvironmentName] = useState<string>('')
  const [selectedEnvironmentIndex, setSelectedEnvironmentIndex] =
    useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [validationErrorItems, setValidationErrorItems] = useState<
    ValidationErrorItem[]
  >([])
  const [projectName, setProjectProjectName] = useState<string>(
    storeContext.scopeStore.getProjectName(projectId)
  )

  const [nameHelperText, setNameHelperText] = useState<string>('')
  const tabTitles = [{ label: 'Environment' }, { label: 'Hosts' }]
  // reset state attribute back to initial values
  const resetState = () => {
    setEnvironmentName('')
    setSelectedEnvironmentIndex(0)
    setLoading(false)
    setValidationErrorItems([])
  }

  const handleAddHosts = (hostLists: any[]) => {
    setLoading(true)
    const environmentType: EnvironmentType =
      tabLabels[selectedEnvironmentIndex].type
    createEnvironment({
      name: environmentName,
      type: environmentType,
      projectId: projectId as string,
      whitelistedHosts: hostLists
    })
      .then((response: any) => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.ENVIRONMENT.CREATE.SUCCESS
        })
        setLoading(false)
        const projectsAndEnvironments = storeContext.scopeStore.getProjects()

        const newProjectWithEnvironments = {
          ...storeContext.projectStore
            .getProjects()
            .find((project: any) => project.id === projectId),
          environments: [response.data]
        }

        const projectToBeUpdated = storeContext.scopeStore
          .getProjects()
          .find((project: any) => project.id === projectId)

        if (!isEmpty(projectToBeUpdated)) {
          const newEnvironments = [
            ...projectToBeUpdated.environments,
            response.data
          ]
          const updatedProjects = [
            ...storeContext.scopeStore
              .getProjects()
              .filter((project: any) => project.id !== projectId),
            { ...projectToBeUpdated, environments: newEnvironments }
          ]
          storeContext.scopeStore.setProjects(updatedProjects)
        } else {
          const newProjectsAndEnvironments: any = [
            ...storeContext.scopeStore
              .getProjects()
              .filter((project: any) => project.id !== projectID),
            newProjectWithEnvironments
          ]
          storeContext.scopeStore.setProjects(newProjectsAndEnvironments)
        }

        const projectWithEnvironments: any = [
          ...storeContext.projectStore
            .getProjects()
            .filter((project: any) => project.id !== projectID),
          newProjectWithEnvironments
        ]

        storeContext.projectStore.setProjects(projectWithEnvironments)

        if (storeContext.scopeStore.getSelectedProject().environments) {
          const selectedProjectEnvironments = [
            ...storeContext.scopeStore.getSelectedProject().environments,
            response.data
          ]
          storeContext.scopeStore.setEnvironments(selectedProjectEnvironments)
        } else {
          storeContext.scopeStore.setEnvironments([response.data])
        }

        storeContext.scopeStore.setProjects(projectsAndEnvironments)
        setLoading(false)
        closePopup()
        refreshData()
      })
      .catch((error) => {
        const toastHeading = error.includes(
          'Subscribe to get more environments'
        )
          ? ToastMessageConstants.ENVIRONMENT.CREATE.UPGRADE_SUBSCRIPTION
          : ToastMessageConstants.ENVIRONMENT.CREATE.ERROR

        ToastNotification({ type: 'error', message: toastHeading })
        setLoading(false)
        closePopup()
      })
  }

  // on close
  const closePopup = () => {
    // resetState()
    handleClose()
  }

  // on create environment
  const handleOnCreate = () => {
    setLoading(true)

    // set environment type from index
    const environmentType: EnvironmentType =
      tabLabels[selectedEnvironmentIndex].type

    // do validation
    const validationResult: ValidationResult = validationSchema.validate(
      {
        environmentName: environmentName.trim(),
        environmentType
      },
      { abortEarly: true }
    )

    // in case of validation errors, display them and no api call is made
    if (validationResult.error) {
      setValidationErrorItems(validationResult.error.details)
      setLoading(false)
    } else {
      setValidationErrorItems([])
      setNameHelperText('')
      checkNameAvailability(
        UrlConstants.ENVIRONMENT_NAME_AVAILABILITY.USECASE,
        environmentName.trim(),
        undefined,
        { projectId }
      )
        .then(() => {
          setCurrentStep(1)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          if (error === StringConstants.NAME_ALREADY_TAKEN) {
            setNameHelperText(
              ValidationConstants.ENVIRONMENT.ENVIRONMENT_NAME_NOT_AVAILABLE
            )
          } else {
            ToastNotification({
              type: 'error',
              message: ToastMessageConstants.SOMETHING_WENT_WRONG
            })
          }
        })
    }
  }

  const renderTabs = () => {
    switch (currentStep) {
      case 0:
        return (
          <PopUpSectionWrapper>
            <Left>
              <span>
                <Typography
                  align='left'
                  variant='h4'
                  fontWeight={palette.fontWeight.medium}
                  style={{
                    paddingBottom: '10px',
                    lineHeight: '35px',
                    maxWidth: '350px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    fontSize: '1.4em'
                  }}
                >
                  Add
                  <span
                    style={{
                      fontWeight: palette.fontWeight.bold,
                      color: palette.colors.primary
                    }}
                  >
                    {' '}
                    Environments{' '}
                  </span>
                  for{' '}
                  <Tooltip title={projectName ? projectName : ''}>
                    <ProjectNameWrapper>{projectName}</ProjectNameWrapper>
                  </Tooltip>
                </Typography>
                <Typography style={{ maxWidth: '350px' }}>
                  Add an environment in seconds!{' '}
                </Typography>
              </span>
            </Left>
            <Right>
              <ItemContainer>
                <InputField
                  width='100%'
                  label='Environment Name'
                  fullWidth
                  error={
                    getValidationStatus(
                      validationErrorItems,
                      'environmentName'
                    ) || Boolean(nameHelperText)
                  }
                  onChange={(e) => {
                    setEnvironmentName(e.target.value)
                  }}
                  helperText={
                    getValidationStatus(validationErrorItems, 'environmentName')
                      ? ValidationConstants.ENVIRONMENT.ENVIRONMENT_NAME
                      : nameHelperText
                  }
                  maxLength={StringConstants.NAME_FIELD_MAX_LENGTH}
                />
              </ItemContainer>
              <ItemContainer>
                <Typography variant='body1' style={{ marginBottom: '20px' }}>
                  Select Environment Type
                </Typography>
                <FilledTabGroup
                  onChange={setSelectedEnvironmentIndex}
                  currentTab={selectedEnvironmentIndex}
                  tabLabels={tabLabels}
                />
              </ItemContainer>
              <ItemContainer>
                <Button
                  variant='contained'
                  endIcon={<IoIosArrowForward />}
                  onClick={() => {
                    gaEventTracker(gaEventConstants.ADDED_ENVIRONMENT)
                    handleOnCreate()
                  }}
                  loading={loading}
                  fullWidth
                >
                  Add Environment
                </Button>
              </ItemContainer>
            </Right>
          </PopUpSectionWrapper>
        )
      case 1:
        return (
          <AddDomainsCard onArrayGenerated={handleAddHosts} loading={loading} />
        )
    }
  }

  return (
    <Popup
      open={open}
      loading={loading}
      headerText='Add Environment'
      handleClose={closePopup}
      width='70%'
    >
      <TabGroup
        currentTab={currentStep as number}
        onChange={() => null}
        tabLabels={tabTitles}
        $disableSwitch
      />
      {renderTabs()}
    </Popup>
  )
}

export default observer(CreateEnvironmentPopUp)
