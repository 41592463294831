import React, { useEffect, useState } from 'react'
import {
  UrlWithOnlyOrgId,
  getAllQueryParamsAsObjectFromUrl
} from '../../utils/UrlUtils'
import {
  acceptUserInvite,
  findAllMyUserInvites,
  rejectUserInvite
} from '../../api/userInvites/UserInvites.service'

import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import AllInvitesTable from '../../components/AllInvites/AllInvitesTable'
import DashboardSectionContainer from '../../components/common/DashboardSectionContainer/DashboardSectionContainer'
import DashboardTopNavbar from '../../components/common/DashboardTopNavbar/DashboardTopNavbar'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../constants/HeaderConstants'
import HttpConstants from '../../constants/HttpConstants'
import NotificationBar from '../../components/common/DashboardTopNavbar/bottomBars/NotificationBar'
import { OrganizationUserRoles } from '../../enum/OrganizationUserRoles.enum'
import SecureComponent from '../../components/Layout/SecureComponent'
import Spinner from '../../components/common/Spinner/Spinner'
import StringConstants from '../../constants/StringConstants'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../../components/common/DDS/Toast/Toast'
import { TypographyDDS } from '../../components/common/Typography/TypographyDDS'
import { addQueryParamsToUrl } from '../../utils/UrlGenerator'
import { isEmpty } from 'lodash'
import palette from '../../global/pallete'
import routeConstants from '../../constants/RouteConstants'
import styled from '@emotion/styled'
import successIcon from '../../assets/icons/successIcon.svg'
import { useNavigate } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'
import { useStoreContext } from '../../store/StoreContext'

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`
const Container = styled.div`
  display: grid;
  flex-grow: 1;
`
const AllInvites: React.FC = () => {
  const [totalCount, setTotalCount] = useState<number>()

  const [searchParams, setSearchParams] = useSearchParams()
  const [data, setData] = useState<any>([])
  const pageSize = StringConstants.DEFAULT_TABLE_SIZE
  const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
  const [loading, setLoading] = useState<boolean>(true)
  const [page, setPage] = useState<number>(
    parseInt(searchParams.get('page') as string) || StringConstants.DEFAULT_PAGE
  )
  const [acceptLoading, setAcceptLoading] = React.useState<boolean>(false)
  const [rejectLoading, setRejectLoading] = React.useState<boolean>(false)
  const store = useStoreContext()
  const navigate = useNavigate()
  useEffect(() => {
    searchParams.set('page', page.toString())
    setSearchParams(searchParams)
    fetchAllMyUserInvites()
  }, [location.search])

  function fetchAllMyUserInvites() {
    setLoading(true)
    findAllMyUserInvites(page)
      .then((userInvitesData: any) => {
        setData(userInvitesData.data)
        setTotalCount(userInvitesData.totalCount)
        setPage(userInvitesData.page)
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
      .catch((err: any) => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
        ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.USER_INVITE.GET_ALL_MY_USER_INVITES.ERROR
        })
      })
  }
  const handleRejectUserInvite = (token: any) => {
    setRejectLoading(true)
    rejectUserInvite({ $token: token as string })
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.USER_INVITE.REJECT_USER_INVITE.SUCCESS
        })
        fetchAllMyUserInvites()
        setRejectLoading(false)
      })
      .catch((err: string) => {
        setRejectLoading(false)
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.USER_INVITE.REJECT_USER_INVITE.ERROR
        })
      })
  }
  const handleAcceptUserInvite = (token: any, organizationId = '') => {
    setAcceptLoading(true)

    acceptUserInvite({ $token: token as string })
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.USER_INVITE.ACCEPT_USER_INVITE.SUCCESS
        })
        localStorage.removeItem(
          StringConstants.LOCAL_STORAGE.CURRENT_PAGE_DETAILS
        )
        localStorage.removeItem(
          StringConstants.LOCAL_STORAGE.CURRENT_PAGE_DETAILS
        )
        fetchAllMyUserInvites()
        setAcceptLoading(false)
        store.scopeStore.setOrganizationId(organizationId)
        store.scopeStore.setProjectId('')
        store.scopeStore.setEnvironmentId('')
        navigate(
          addQueryParamsToUrl(
            routeConstants.REDIRECT,
            token.role !== OrganizationUserRoles.BILLING
              ? {
                  redirect: UrlWithOnlyOrgId(
                    routeConstants.MEMBER_SETTINGS,
                    store
                  )
                }
              : {
                  redirect: UrlWithOnlyOrgId(
                    routeConstants.SUBSCRIPTION_SETTINGS,
                    store
                  )
                },
            HttpConstants.GET_METHOD
          )
        )
      })
      .catch((err: string) => {
        setAcceptLoading(false)
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.USER_INVITE.ACCEPT_USER_INVITE.ERROR
        })
      })
  }

  return (
    <Container>
      <DocumentHeader header={HeaderConstants.INVITES} />
      {/* <DashboardTopNavbar variant='invitations' />  */}
      <NotificationBar />
      <DashboardSectionContainer
        noDivider
        headerText='Your Invitations'
        padding='1.875em 1.25em'
      >
        {!loading ? (
          data.length === 0 ? (
            <EmptyContainer>
              <img
                src={successIcon}
                style={{
                  height: '50px',
                  filter: palette.colors.primarySvgFilter
                }}
              />
              <TypographyDDS.Title type='h3' variant='bold' color='textDark'>
                You{`'`}re all caught up
              </TypographyDDS.Title>{' '}
            </EmptyContainer>
          ) : (
            !isEmpty(data) && (
              <AllInvitesTable
                data={data}
                totalCount={totalCount}
                currentPage={page}
                onPageChange={(number) => {
                  setSearchParams({
                    ...queryParams,
                    page: number.toString()
                  })
                  setPage(number)
                }}
                rowsPerPage={pageSize}
                handleRejectUserInvite={handleRejectUserInvite}
                handleAcceptUserInvite={handleAcceptUserInvite}
                acceptLoading={acceptLoading}
                rejectLoading={rejectLoading}
              />
            )
          )
        ) : (
          <Spinner />
        )}
      </DashboardSectionContainer>
    </Container>
  )
}
export default SecureComponent(AllInvites, AccessRoleConstants.ALL)
