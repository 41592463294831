import { AccordionDetails, AccordionSummary } from '@mui/material'
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp
} from 'react-icons/md'
import React, { useEffect, useState } from 'react'
import {
  getColorOfFeature,
  getIsMonthlyLimit,
  mapFeatureLabels
} from '../../utils/SubscriptionUtils'

import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import { BsCodeSlash } from 'react-icons/bs'
import DashboardSectionContainer from '../../components/common/DashboardSectionContainer/DashboardSectionContainer'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import ExceptionIcon from '../../assets/icons/Exception.svg'
import { Feature } from '../../enum/Feature.enum'
import { GiMedicines } from 'react-icons/gi'
import { HeaderConstants } from '../../constants/HeaderConstants'
import { OrganizationUserRoles } from '../../enum/OrganizationUserRoles.enum'
import SecureComponent from '../../components/Layout/SecureComponent'
import { SettingBottomBar } from '../../components/common/SettingBottomBar/SettingBottomBar'
import SettingsBar from '../../components/common/DashboardTopNavbar/bottomBars/Settings'
import Spinner from '../../components/common/Spinner/Spinner'
import StringConstants from '../../constants/StringConstants'
import Table from '../../components/common/Table/Table'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../../components/common/DDS/Toast/Toast'
import { TypographyDDS } from '../../components/common/Typography/TypographyDDS'
import apiMonitoringImg from '../../assets/icons/api-monitoring.svg'
import breakpoints from '../../global/breakpoints'
import { getOrganizationUsageReport } from '../../api/subscription/Subscription.service'
import { getUserDetails } from '../../api/user/User.service'
import { observer } from 'mobx-react-lite'
import palette from '../../global/pallete'
import routeConstants from '../../constants/RouteConstants'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import { useStoreContext } from '../../store/StoreContext'

type SubscriptionDetails = {
  id: string
  plan: any
  planId: string
  status: any
  jobMonitoring: number
  apiMonitoring: number
  applicationHealthCheck: number
  errorManagement: number
  project: number
  environment: number
  user: number
  dataRetention: number
  nextBillingAt: Date
  additionalBenefits?: any
  billingPeriodUnit: string
  billingPeriod: number
  subscriptionAmount: string
}

const Container = styled.div<{ changePlan?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  gap: ${palette.padding.size};
  @media (max-width: ${breakpoints.md}) {
    flex-wrap: wrap;
    .payment-history {
      flex: 1;
    }
    .current-plan {
      flex-direction: row;
      align-items: stretch;
      margin-bottom: 0.625em;
      & > * {
        flex: 1;
      }
    }
  }
  ${({ changePlan }) =>
    changePlan &&
    `flex-wrap: wrap;
  .payment-history {
    flex: 1;
  }
  .current-plan {
    flex-direction: row;
    align-items: stretch;
    margin-bottom: 0.625em;
    & > * {
      flex: 1;
    }
  }`}
`
const PaymentHistoryWrapper = styled.div`
  flex-grow: 2;
`
const PlansWrapper = styled.div`
  flex-grow: 2;
`
const FeatureUtilizationWrapper = styled.div`
  flex-grow: 2;
`

const PlanContainer = styled.div<{ changePlan?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
const List = styled.ul`
  list-style-type: none;
  margin-bottom: 2em;
`
const ListItem = styled.li`
  margin-bottom: 0.625em;
  display: flex;
  align-items: center;
  gap: 0.625em;
`
const StyledAccordionSummary = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    padding: 0;
  }
`
const StyledAccordionDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 0;
  }
`
const BackIconWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
  color: ${palette.colors.primary};
`
const CurrentSubscriptionPlan = styled.div`
  margin-top: ${palette.padding.size};
`
const SubscriptionSettings: React.FC = () => {
  const storeContext = useStoreContext()

  const getAdditionalBenefits = () => {
    const additionalBenefits = subscriptionDetails?.plan
      ?.additionalBenefits || [
      'Multiple environments',
      'Multi-user access controls'
    ]

    return additionalBenefits.map((benefit: any) => ({ text: benefit }))
  }

  const getBenefits = () => [
    {
      text: `${
        subscriptionDetails?.user === -1
          ? 'Unlimited'
          : subscriptionDetails?.user
      } full-platform user`
    },
    {
      text: `${
        subscriptionDetails?.project === -1
          ? 'Unlimited'
          : subscriptionDetails?.project
      } projects`
    },
    {
      text: `${
        subscriptionDetails?.environment === -1
          ? 'Unlimited'
          : subscriptionDetails?.environment
      }  environments`
    },
    {
      text: `${
        subscriptionDetails?.apiMonitoring === -1
          ? 'Unlimited'
          : subscriptionDetails?.apiMonitoring
      }  requests/month`,
      icon: (
        <img
          src={apiMonitoringImg}
          alt='api-monitoring-icon'
          style={{
            filter: palette.colors.primarySvgFilter
          }}
        />
      )
    },
    {
      text: `${
        subscriptionDetails?.jobMonitoring === -1
          ? 'Unlimited'
          : subscriptionDetails?.jobMonitoring
      } monitors`,
      icon: <BsCodeSlash color={palette.colors.primary} size='1.5em' />
    },
    {
      text: `${
        subscriptionDetails?.applicationHealthCheck === -1
          ? 'Unlimited'
          : subscriptionDetails?.applicationHealthCheck
      } checks at fixed intervals`,
      icon: <GiMedicines color={palette.colors.primary} size='1.5em' />
    },
    {
      text: `${
        subscriptionDetails?.errorManagement === -1
          ? 'Unlimited'
          : subscriptionDetails?.errorManagement
      } error events`,
      icon: (
        <img
          src={ExceptionIcon}
          style={{ height: '1.5em', filter: palette.colors.primarySvgFilter }}
        />
      )
    },
    {
      text: `${
        subscriptionDetails?.dataRetention === -1
          ? 'Unlimited'
          : subscriptionDetails?.dataRetention
      } month data retention`
    }
  ]

  const [accordionOpen, setAccordionOpen] = useState(false)
  const [changePlan, setChangePlan] = useState(false)
  const [subscriptionDetails, setSubscriptionDetails] =
    useState<SubscriptionDetails>()
  const [featureUsageData, setFeatureUsageData] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const navigate = useNavigate()
  const renderAccordionIcon = () =>
    accordionOpen ? (
      <MdOutlineKeyboardArrowUp color={palette.colors.textDark} />
    ) : (
      <MdOutlineKeyboardArrowDown color={palette.colors.textDark} />
    )
  const renderPlansAndPricingHeader = () => {
    return (
      <>
        <TypographyDDS.Title
          color='primary'
          type='h1'
          variant='bold'
          style={{
            marginBottom: '10px'
          }}
        >
          Plans and Pricing
        </TypographyDDS.Title>

        <TypographyDDS.Title
          color='textDark3'
          type='h3'
          variant='medium'
          style={{ marginBottom: 10 }}
        >
          Simple Pricing. No hidden Fees.
        </TypographyDDS.Title>
      </>
    )
  }

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.SETTINGS
    )
    setIsLoading(true)
    getUserDetails()
      .then((userResponse: any) => {
        const organizationIndex = userResponse.data.organizations.findIndex(
          (org: any) => {
            return (
              org.id === storeContext.userStore.getSelectedOrganization().id &&
              org.membership.role !== OrganizationUserRoles.MEMBER
            )
          }
        )
        storeContext.userStore.setOrganizations([
          ...userResponse.data.organizations
        ])

        if (organizationIndex === -1) {
          navigate(routeConstants.UNAUTHORIZED_PAGE)
        }

        const subscriptionDetails = storeContext.userStore
          .getOrganizations()
          .find(
            (organization: any) =>
              organization.id === storeContext.scopeStore.getOrganizationId()
          ).subscription

        setSubscriptionDetails(subscriptionDetails)
        storeContext.uiStore.setGlobalLoader(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    if (subscriptionDetails) {
      setIsLoading(true)
      getOrganizationUsageReport()
        .then((response: any) => {
          const featureArray = [
            Feature.USER,
            Feature.PROJECT,
            Feature.ENVIRONMENT
          ]
          const filteredData = response.data.usageData.filter(
            (element: any) => {
              if (!featureArray.includes(element['feature'])) return element
            }
          )
          setFeatureUsageData(filteredData)
        })
        .catch((error) => {
          console.log(
            'Error while fetching organization usage report :: ',
            error
          )
          if (error.data.statusCode !== 400)
            ToastNotification({
              type: 'error',
              message: ToastMessageConstants.SUBSCRIPTION.GET_ORGANIZATION_USAGE
            })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [storeContext.userStore.getOrganizations(), subscriptionDetails])

  const tableColumns = [
    {
      label: 'feature',
      title: 'Features',
      render: (item: any) => mapFeatureLabels(item)
    },
    {
      label: 'active',
      title: 'Total Utilised',
      render: (item: any, fullItem: any) => {
        return (
          <TypographyDDS.Paragraph
            size='para'
            variant='medium'
            color={getColorOfFeature(fullItem)}
          >
            {getIsMonthlyLimit(fullItem) ? `${item} / month` : item}
          </TypographyDDS.Paragraph>
        )
      }
    },
    {
      label: 'maxLimit',
      title: 'Maximum Limit',
      render: (item: any, fullItem: any) => {
        return (
          <TypographyDDS.Paragraph
            size='para'
            variant='medium'
            color='textDark'
          >
            {getIsMonthlyLimit(fullItem) ? `${item} / month` : item}
          </TypographyDDS.Paragraph>
        )
      }
    }
  ]

  return (
    <>
      <DocumentHeader header={HeaderConstants.BILLING_SETTINGS} />
      {/* <DashboardTopNavbar variant='settings' showBottomBar /> */}
      <SettingBottomBar>
        <SettingsBar showButtons={false} />
      </SettingBottomBar>
      {/* changePlan variable not used - As that specific section is commented out */}
      {subscriptionDetails ? (
        <Container>
          <FeatureUtilizationWrapper>
            <DashboardSectionContainer headerText='Billing'>
              <Table
                columns={tableColumns}
                data={featureUsageData}
                showPagination={false}
                isLoading={isLoading}
              />
            </DashboardSectionContainer>
          </FeatureUtilizationWrapper>
        </Container>
      ) : !isLoading ? (
        <PaymentHistoryWrapper className='payment-history'>
          <DashboardSectionContainer headerText='Payment History'>
            <TypographyDDS.Paragraph size='para'>
              Reach out to{' '}
              <a href={`mailto:${StringConstants.SUPPORT_EMAIL}`}>
                {StringConstants.SUPPORT_EMAIL}
              </a>{' '}
              to get payment information and past invoices
            </TypographyDDS.Paragraph>
          </DashboardSectionContainer>
        </PaymentHistoryWrapper>
      ) : (
        <PaymentHistoryWrapper>
          <DashboardSectionContainer noHeader>
            <Spinner />
          </DashboardSectionContainer>
        </PaymentHistoryWrapper>
      )}
    </>
  )
}

export default SecureComponent(
  observer(SubscriptionSettings),
  AccessRoleConstants.BILLING_LEVEL
)
