import Joi, { ValidationErrorItem } from 'joi'
import React, { useState } from 'react'

import Button from '../Button/Button'
import InputField from '../InputField/InputField'
import Popup from './Popup'
import { TypographyDDS } from '../Typography/TypographyDDS'
import ValidationConstants from '../../../constants/ValidationConstants'
import breakpoints from '../../../global/breakpoints'
import { getValidationStatus } from '../../../utils/ValidationUtils'
import { isUndefined } from 'lodash'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'
import trashIcon from '../../../assets/icons/trash.svg'

interface TextConfirmationPopUpProps {
  open: boolean
  handleClose: () => void
  confirmationText?: string
  handleSubmit: () => void
  context: string
  heading: string
  buttonIcon?: React.ReactElement
  buttonText?: string
  loading?: boolean
}

interface ItemContainerProps {
  width?: string
  height?: string
  padding?: string
  margin?: string
}

const PopUpSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 340px;
  gap: 20px;
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.85rem;
  }
  @media (max-width: ${breakpoints.md}) {
    font-size: 0.7rem;
  }
  height: 100%;
  justify-content: center;
`
const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  height: 100%;
`

const ItemContainer = styled.div<ItemContainerProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`

const TextConfirmationPopUp: React.FC<TextConfirmationPopUpProps> = ({
  open,
  handleClose,
  context,
  confirmationText = 'Confirm',
  handleSubmit,
  buttonIcon,
  buttonText,
  heading,
  loading
}) => {
  const [validationText, setValidationText] = useState('')
  const [validationErrorResult, setValidationErrorResult] = useState<
    ValidationErrorItem[]
  >([])
  const schema = Joi.object({
    confirmationText: Joi.string().required(),
    validationText: Joi.string().required().equal(Joi.ref('confirmationText'))
  })

  const validate = () => {
    const validationResult = schema.validate({
      validationText,
      confirmationText
    })
    if (isUndefined(validationResult.error)) {
      setValidationErrorResult([])
      handleSubmit()
    } else setValidationErrorResult(validationResult.error.details)
  }
  return (
    <Popup
      open={open}
      headerText={heading}
      handleClose={handleClose}
      loading={loading}
      headerIcon={
        buttonIcon ? (
          <div
            style={{
              filter:
                'invert(33%) sepia(70%) saturate(4265%) hue-rotate(208deg) brightness(99%) contrast(108%)'
            }}
          >
            {buttonIcon}
          </div>
        ) : (
          <img
            src={trashIcon}
            style={{ filter: palette.colors.redSvgFilter }}
          />
        )
      }
    >
      <PopUpSectionWrapper>
        <Left>
          <TypographyDDS.Title
            type='h4'
            variant='medium'
            style={{
              paddingBottom: '10px',
              textAlign: 'left'
            }}
          >
            Type
            <span
              style={{
                fontWeight: palette.fontWeight.bold,
                color: palette.colors.primary
              }}
            >
              {' '}
              {`"${confirmationText}"`}{' '}
            </span>
            to proceed
          </TypographyDDS.Title>

          <TypographyDDS.Title type='h5' variant='medium' color='textDark2'>
            <span
              style={{
                color: palette.colors.error,
                fontWeight: palette.fontWeight.semiBold
              }}
            >
              Note:{' '}
            </span>
            {context}
          </TypographyDDS.Title>
        </Left>
        <Right>
          <ItemContainer>
            <InputField
              error={getValidationStatus(
                validationErrorResult,
                'validationText'
              )}
              placeholder='Type here'
              label='Text Confirmation'
              type='string'
              fullWidth
              onChange={(e) => setValidationText(e.target.value)}
              helperText={
                getValidationStatus(validationErrorResult, 'validationText')
                  ? ValidationConstants.DELETE_CONFIRMATION.CONFIRMATION_TEXT
                  : ''
              }
            />
          </ItemContainer>
          <ItemContainer>
            <Button
              background={
                buttonText
                  ? ''
                  : `linear-gradient(270deg, ${palette.colors.error} 0%, ${palette.colors.error} 100%)`
              }
              endIcon={buttonIcon ? buttonIcon : <></>}
              startIcon={
                buttonIcon ? (
                  <></>
                ) : (
                  <img
                    src={trashIcon}
                    style={{ filter: palette.colors.whiteSvgFilter }}
                  />
                )
              }
              variant='contained'
              margin='32px 0px 0px'
              onClick={validate}
              loading={loading}
              fullWidth
            >
              {buttonText ? buttonText : `Delete`}
            </Button>
          </ItemContainer>
        </Right>
      </PopUpSectionWrapper>
    </Popup>
  )
}

export default TextConfirmationPopUp
