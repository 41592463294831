import React, { useEffect, useState } from 'react'

import APIRequestResponseCard from '../../components/APIMonitoring/APIRequestResponseCard'
import ApiGroupingStringConstants from '../../constants/ApiGroupingStringConstants'
import { DDSTypography } from 'den-design-system'
import Spinner from '../common/Spinner/Spinner'
import { isJSON } from '../../utils/CommonUtils/StringUtils'
import { isUndefined } from 'lodash'
import { observer } from 'mobx-react-lite'
import pallete from '../../global/newPallete'
import { replaceUrl } from '../../utils/UrlUtils'
import routeConstants from '../../constants/RouteConstants'
import { useSearchParams } from 'react-router-dom'
import { useStoreContext } from '../../store/StoreContext'

const ViewRequestResponse: React.FC = () => {
  const store = useStoreContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState<boolean>(false)
  const apiDetails = store.apiDetailsStore.getApiData()
  const responseBody = store.apiDetailsStore.getApiData().responseBody
  const requestBody = { ...store.apiDetailsStore.getApiData().requestBody }

  useEffect(() => {
    setLoading(store.apiDetailsStore.getAPILoading())
  }, [store.apiDetailsStore.apiLoading])

  useEffect(() => {
    const tabFromParams = searchParams.get('tab')
    if (!isUndefined(store.apiDetailsStore.getApiData().urlTemplate)) {
      store.breadcrumbStore.setMultipleBreadcrumbsOptions([
        {
          label:
            tabFromParams === '1'
              ? ApiGroupingStringConstants.GROUPED_API
              : ApiGroupingStringConstants.ALL_API,
          link:
            replaceUrl(
              routeConstants.API_MONITORING,
              store.scopeStore.getScope()['organization-id'] as string,
              store.scopeStore.getScope()['project-id'] as string,
              store.scopeStore.getScope()['environment-id'] as string
            ) + `${tabFromParams === '1' ? '?tab=1' : '?tab=2'}`
        },
        {
          label: store.apiDetailsStore.getApiData()?.urlTemplate as string,
          link: routeConstants.VIEW_GROUPED_API.replace(
            ':id',
            apiDetails.apiGroupId
          )
        },
        {
          label: ApiGroupingStringConstants.REQUEST_RESPONSE,
          link: '/'
        }
      ])
    }
  }, [store.breadcrumbStore.getBreadcrumbsOptions(), apiDetails])

  const requestResponseData = [
    {
      id: '1',
      description: (
        <pre>
          <code>
            <DDSTypography.Paragraph
              size='para'
              color={pallete.colors.textDark5}
              variant='regular'
              className='overflow-x-scroll overflow-y-scroll h-[65vh] 2xl:h-[70vh]'
            >
              {JSON.stringify(requestBody, null, 4)}
            </DDSTypography.Paragraph>
          </code>
        </pre>
      ),
      bodyText: ApiGroupingStringConstants.REQUEST_BODY,
      copyText: JSON.stringify(requestBody, null, 4)
    },
    {
      id: '2',
      description: (
        <pre>
          <code>
            {!isUndefined(responseBody) ? (
              <DDSTypography.Paragraph
                size='para'
                color={pallete.colors.textDark5}
                variant='regular'
                className='overflow-x-scroll  h-[65vh] 2xl:h-[70vh]'
              >
                {!isJSON(responseBody)
                  ? responseBody
                  : JSON.stringify(JSON.parse(responseBody), null, 4)}
              </DDSTypography.Paragraph>
            ) : (
              <>{'{}'}</>
            )}
          </code>
        </pre>
      ),
      bodyText: ApiGroupingStringConstants.RESPONSE_BODY,
      copyText: JSON.stringify(responseBody, null, 4)
    }
  ]

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className='flex h-full w-full'>
          <div className='flex justify-between items-center h-full w-full'>
            <div className='py-[24px] flex flex-row gap-[24px] h-full w-full'>
              {requestResponseData.map((item) => (
                <APIRequestResponseCard
                  key={item.id}
                  id={item.id}
                  description={item.description}
                  bodyText={item.bodyText}
                  copyText={item.copyText}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default observer(ViewRequestResponse)
