import {
  gaEventConstants,
  gaPageConstants
} from '../../../../constants/GAConstants'

import Button from '../../Button/Button'
import FilledTabGroup from '../../TabGroup/FilledTabGroup'
import { OrganizationUserRoles } from '../../../../enum/OrganizationUserRoles.enum'
import React from 'react'
import { TypographyDDS } from '../../Typography/TypographyDDS'
import breakpoints from '../../../../global/breakpoints'
import { observer } from 'mobx-react-lite'
import palette from '../../../../global/pallete'
import plusIcon from '../../../../assets/icons/plus.svg'
import styled from '@emotion/styled'
import { useAnalyticsEventTracker } from '../../../../utils/GAUtils'
import { useStoreContext } from '../../../../store/StoreContext'

interface ProjectEnvHeaderProps {
  currentTab: number
  tabLabels: any[]
  onChange?: (newValue: number) => void
  setCurrentTab: (tab: number) => void
  setCreateEnvPopUp: React.Dispatch<React.SetStateAction<boolean>>
  loading: boolean
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  @media (max-width: ${breakpoints.xl}) {
    flex-wrap: wrap;
    justify-content: center;
    & > * {
      flex: 1 100%;
    }
    .Tab {
      order: -1;
    }
    .Title {
      flex: 1;
    }
  }
  .button-text {
    @media (max-width: ${breakpoints.lg}) {
      font-size: 0.85rem;
    }
    @media (max-width: ${breakpoints.md}) {
      font-size: 0.7rem;
    }
  }
  grid-gap: 1.25em;
  padding-bottom: 1.25em;
  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.85rem;
  }
  @media (max-width: ${breakpoints.md}) {
    font-size: 0.7rem;
  }
`

const TabWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: center;
  width: 100%;
  justify-content: space-between;
  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;
  }
`

const ProjectEnvHeader: React.FC<ProjectEnvHeaderProps> = ({
  currentTab,
  tabLabels,
  setCurrentTab,
  setCreateEnvPopUp,
  loading
}) => {
  const { projectStore } = useStoreContext()
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.PROJECT_ENVIRONMENT_HEADER
  )
  const store = useStoreContext()

  return (
    <Container
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <div className='Title' style={{ alignSelf: 'flex-start' }}>
        <TypographyDDS.Title
          type='h4'
          variant='bold'
          style={{
            paddingRight: '20px'
          }}
        >
          Environments
        </TypographyDDS.Title>
      </div>
    </Container>
  )
}

export default observer(ProjectEnvHeader)
