import {
  BtoA,
  UrlWithOnlyOrgId,
  getAllQueryParamsAsObjectFromUrl
} from '../../utils/UrlUtils'
import { createSearchParams, useNavigate } from 'react-router-dom'

import React from 'react'
import StringConstants from '../../constants/StringConstants'
import Table from '../common/Table/Table'
import Typography from '../common/Typography/Typography'
import { TypographyDDS } from '../common/Typography/TypographyDDS'
import { gaEventConstants } from '../../constants/GAConstants'
import { isEmpty } from 'lodash'
import { markNotificationAsRead } from '../../api/notification/notification.service'
import palette from '../../global/pallete'
import { redirectFunction } from '../../utils/NotificationUtils'
import routeConstants from '../../constants/RouteConstants'
import styled from '@emotion/styled'
import { timeFormats } from '../../enum/TIME'
import { timeZone } from '../../utils/DateTimeUtils'
import unreadNotificationIcon from '../../assets/icons/unreadNotification.svg'
import { useStoreContext } from '../../store/StoreContext'

interface Notification {
  content: string
  referenceModule: string
  createdAt: string
  readAt: string
}

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: initial;
`

const NotificationTable: React.FC<{
  data: Notification[]
  totalCount?: number
  rowsPerPage?: number
  currentPage?: number
  onPageChange?: (page: number) => void
  getNotifications?: () => void
  setLoading?: (loading: boolean) => void
}> = ({
  data,
  totalCount,
  rowsPerPage,
  currentPage = 0,
  onPageChange = () => null,
  getNotifications = () => null,
  setLoading = (loading: boolean) => null
}) => {
  const store = useStoreContext()
  const navigate = useNavigate()
  const MarkNotificationAsRead = (Notification: any) => {
    if (store.userStore.getSelectedOrganization().projectCount !== 0) {
      setLoading(true)
      markNotificationAsRead({ $id: Notification.id }).then(() => {
        {
          Notification.eventName !== 'DELETED' &&
          Notification.eventName !== 'REJECTED' &&
          Notification.eventName !== 'WELCOME'
            ? navigate(
                redirectFunction(Notification, store.uiStore, store.scopeStore)
              )
            : getNotifications()
        }
      })
    }
  }

  const handleEnvironmentChange = (id: string) => {
    if (id === store.scopeStore.getSelectedEnvironment().id) {
      return
    }
    store.uiStore.setGlobalLoader(true)
    store.scopeStore.setEnvironmentId(id)
    const environment = store.scopeStore
      .getEnvironments()
      .filter((record: any) => record.id == id)
    store.scopeStore.setSelectedEnvironment(environment[0])
    store.filterStore.setFiltersApplied(false)
    let url = BtoA(
      store.scopeStore.getOrganizationId() +
        '/' +
        store.scopeStore.getScope()['project-id'] +
        '/' +
        id
    )
    url += '/' + window.location.pathname.split('/')[2]
    if (
      ['dashboard', 'report'].includes(window.location.pathname.split('/')[3])
    ) {
      url += '/' + window.location.pathname.split('/')[3]
    }
    const pathName = '../' + '../' + url
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    navigate({
      pathname: pathName,
      search: `?${createSearchParams(queryParams)}`
    })
    store.breadcrumbStore.reset()
  }

  const environmentSwitch = (fullItem: any) => {
    if (
      fullItem?.parentId?.environmentId !== store.scopeStore.getEnvironmentId()
    ) {
      const environments = store.scopeStore.getEnvironments()
      const matchingEnvironment = environments.filter(
        (env) => fullItem?.parentId?.environmentId === env?.id
      )

      if (!isEmpty(environments)) {
        if (
          matchingEnvironment[0]?.type ===
          StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
        ) {
          localStorage.setItem(
            StringConstants.ENVIRONMENT_TYPE,
            StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
          )
          handleEnvironmentChange(environments[0]?.id)
        } else {
          localStorage.setItem(
            StringConstants.ENVIRONMENT_TYPE,
            StringConstants.ENVIRONMENT_TYPE_VALUES.PROD
          )
          handleEnvironmentChange(environments[1]?.id)
        }
      }
    }
  }

  const columns = [
    {
      title: '',
      label: 'content',
      render: (item: any, fullItem: any) => (
        <NotificationContainer
          style={{
            alignItems: 'flex-start',
            cursor: 'pointer'
          }}
        >
          {fullItem.readAt !== null ? (
            <TypographyDDS.Paragraph
              size='para'
              variant='medium'
              color='textDark'
            >
              {fullItem.eventName !== 'DELETED' &&
              fullItem.eventName !== 'REJECTED' &&
              fullItem.eventName !== 'WELCOME' ? (
                <div
                  onClick={() => {
                    environmentSwitch(fullItem)
                    if (
                      store.userStore.getSelectedOrganization().projectCount !==
                      0
                    ) {
                      navigate(
                        redirectFunction(
                          fullItem,
                          store.uiStore,
                          store.scopeStore
                        )
                      )
                    }
                  }}
                >
                  {item}
                </div>
              ) : (
                <div>{item}</div>
              )}
            </TypographyDDS.Paragraph>
          ) : (
            <div
              style={{
                display: 'flex',
                gap: '10px',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <img
                src={unreadNotificationIcon}
                style={{ width: '8px', height: '8px' }}
              />
              <TypographyDDS.Paragraph
                size='para'
                variant='medium'
                color='textDark'
              >
                <div
                  onClick={() => {
                    environmentSwitch(fullItem)
                    MarkNotificationAsRead(fullItem)
                  }}
                >
                  {item}
                </div>
              </TypographyDDS.Paragraph>
            </div>
          )}
        </NotificationContainer>
      )
    },
    {
      title: '',
      label: 'createdAt',
      customStyles: {
        minWidth: 160
      },
      render: (item: any, fullItem: any) => (
        <NotificationContainer>
          {fullItem.readAt !== null ? (
            <TypographyDDS.Paragraph
              size='para'
              variant='medium'
              color='textDark'
            >
              {timeZone(
                store.userStore.getUserPreference().timeZone,
                item,
                timeFormats.monthDateYear
              )}
            </TypographyDDS.Paragraph>
          ) : (
            <TypographyDDS.Paragraph
              size='para'
              variant='medium'
              color='textDark'
            >
              {timeZone(
                store.userStore.getUserPreference().timeZone,
                item,
                timeFormats.monthDateYear
              )}
            </TypographyDDS.Paragraph>
          )}
        </NotificationContainer>
      )
    }
  ]
  return (
    <Table
      data={data}
      columns={columns}
      totalCount={totalCount}
      rowsPerPage={rowsPerPage}
      currentPage={currentPage}
      onPageChange={onPageChange}
      dangerouslySetInnerHTMLColumns={['content']}
      justifyContent='start'
    />
  )
}

export default NotificationTable
