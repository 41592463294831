export const color = {
  '500Series': '#D71414',
  '400Series': '#FF8585',
  '300Series': '#5EA1FF',
  '200Series': '#56B320',
  '100Series': '#FFFF00'
}

export const mapStatusCodeToColor = (statusCode: any): string => {
  if (statusCode == null) {
    return 'black'
  }

  const firstCharacter = statusCode.toString().charAt(0)
  switch (firstCharacter) {
    case '1':
      return color['100Series'] || 'black'
    case '2':
      return color['200Series'] || 'black'
    case '3':
      return color['300Series'] || 'black'
    case '4':
      return color['400Series'] || 'black'
    case '5':
      return color['500Series'] || 'black'
    default:
      return 'black'
  }
}
