import React, { useEffect, useState } from 'react'

import FilledTabGroup from '../common/TabGroup/FilledTabGroup'
import KeyValueTable from './KeyValueTable'
import Popup from '../common/Popup/Popup'
import SearchField from '../common/InputField/SearchField'
import { TypographyDDS } from '../common/Typography/TypographyDDS'
import { getLowerCase } from '../../utils/CommonUtils/StringUtils'
import palette from '../../global/pallete'
import { pick } from 'lodash'
import styled from '@emotion/styled'

const CustomHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 85px;
`

const TableWrapper = styled.div`
  max-height: 100%;
  height: 300px;
  overflow: scroll;
`
// const Spacer = styled.div`
//   flex-grow: 1;
// `

const HeaderPopup: React.FC<{
  left?: string
  open?: boolean
  heading?: string
  handleClose?: () => void
  data?: Record<string, string>[]
  activeTab?: number
  onTabChange?: (tab: number) => void
  disableTab?: boolean
  destroyOnClose?: boolean
}> = ({
  open = false,
  left,
  handleClose = () => null,
  data = [],
  activeTab = 0,
  onTabChange = () => null,
  destroyOnClose = false,
  heading
}) => {
  const [tabData, setTabData] = useState<Record<string, string>>(
    data[activeTab]
  )
  const [searchText, setSearchText] = useState('')
  const headerTabs = [
    { id: 0, label: 'Headers' },
    { id: 1, label: 'Params' },
    { id: 2, label: 'Query' }
  ]
  const setFilteredData = () => {
    const filteredData = pick(
      data[activeTab],
      Object.keys(data[activeTab]).filter(
        (key) =>
          getLowerCase(key).includes(getLowerCase(searchText)) ||
          getLowerCase(data[activeTab][key]).includes(getLowerCase(searchText))
      )
    )

    setTabData(filteredData)
  }

  useEffect(() => {
    setFilteredData()
  }, [data, activeTab, searchText])

  const onSearch = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const text = target.value
    setSearchText(text)

    const filteredData = pick(
      data[activeTab],
      Object.keys(data[activeTab]).filter(
        (key) =>
          getLowerCase(key).includes(getLowerCase(text)) ||
          getLowerCase(data[activeTab][key]).includes(getLowerCase(text))
      )
    )
    setTabData(filteredData)
  }
  const renderCustomHeader = () => {
    return (
      <CustomHeaderContainer>
        <TypographyDDS.Title
          type='h4'
          variant='bold'
          style={{ whiteSpace: 'nowrap' }}
        >
          {heading}
        </TypographyDDS.Title>
        <span>
          {data.length < 1 || data.length === 1 ? (
            <></>
          ) : (
            <FilledTabGroup
              tabLabels={headerTabs}
              currentTab={activeTab}
              onChange={onTabChange}
              tabHeight='30px'
              tabWrapperProps={{ useMediaQuery: true }}
            />
          )}
        </span>
        <SearchField
          useMediaQuery={true}
          value={searchText}
          onChange={onSearch}
          margin='0'
          left={left}
        />
      </CustomHeaderContainer>
    )
  }
  return (
    <Popup
      open={open}
      handleClose={() => {
        setSearchText('')
        setFilteredData()
        handleClose()
      }}
      customHeader={renderCustomHeader()}
      headerPadding='0 0 30px 0'
      destroyOnClose={destroyOnClose}
    >
      <TableWrapper>
        <KeyValueTable data={tabData} />
      </TableWrapper>
    </Popup>
  )
}

export default HeaderPopup
