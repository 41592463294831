import React, { useState } from 'react'
import {
  deleteComment,
  updateComment
} from '../../api/incident/incident.service'

import Button from '../common/Button/Button'
import DeletePopup from '../JobMonitoringPage/DeleteLogPopup/DeletePopup'
import DividerLine from '../common/DividerLine/DividerLine'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../common/DDS/Toast/Toast'
import { isEmpty } from 'lodash'
import palette from '../../global/pallete'
import styled from '@emotion/styled'
import { useParams } from 'react-router-dom'
import { useStoreContext } from '../../store/StoreContext'

interface ParaProps {
  padding?: string
  lineHeight?: number
  fontSize?: string
}

interface IconTextProps {
  color?: string
  size?: string
  gridGap?: string
}
interface ReplySectionProps {
  userName?: string
  date?: string
  reply: string | number
  avatar?: string
  email?: string
  replyId: string
  fetchActivities?: any
  addLoading?: any
}

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 12px 0px;
`

const Comment = styled.div`
  margin: 0 0 0 2.5em;
`

const Reply = styled.div``

const ViewReplyBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
  padding: 12px 0 0 0;
`

const IconText = styled.div<IconTextProps>`
  display: flex;
  align-items: center;
  grid-gap: ${(props) => props.gridGap};
  font-size: ${(props) => props.size};
  color: ${(props) => props.color};
`

const ParaLight = styled.p<ParaProps>`
  padding: ${(props) => props.padding};
  line-height: ${(props) => props.lineHeight};
  font-weight: 500;
  font-size: ${(props) => props.fontSize};
  color: ${palette.colors.textDark2};
`
const ParaDark = styled.div<ParaProps>`
  padding: ${(props) => props.padding};
  line-height: ${(props) => props.lineHeight};
  font-weight: 500;
  font-size: ${(props) => props.fontSize};
  color: ${palette.colors.textDark};
`
const PostCommentSection = styled.div`
  background-color: ${palette.colors.lightBack};
  border: 1px solid ${palette.colors.borderColor};
  border-radius: 8px;
  padding: 8px 8px;
  textarea {
    outline: none;
    width: 100%;
    font-size: 16px;
    border: none;
    resize: none;
    padding: 8px 0px;
    background-color: ${palette.colors.lightBack};
  }
`
const RoundedImage = styled.img`
  border-radius: 50%;
  border: 1px solid ${palette.colors.borderColor};
`
const PostCommentSectionBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
`

const IncidentReplySection: React.FC<ReplySectionProps> = ({
  userName,
  date,
  reply,
  avatar,
  replyId,
  ...props
}) => {
  const { exceptionLogId, incidentId } = useParams()
  const [editReply, setEditReply] = useState(false)
  const [editReplyData, setEditReplyData] = useState('')
  const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false)
  const [deleteReplyPopupOpen, setReplyCommentPopupOpen] = useState(false)
  const [replyDeleted, setReplyDeleted] = useState(false)
  const store = useStoreContext()

  const updateReplyForIncident = () => {
    props.addLoading(true)
    updateComment({
      comment: editReplyData as string,
      $exceptionLogId: exceptionLogId as string,
      $incidentId: incidentId,
      $exceptionCommentId: replyId
    })
      .then((response: any) => {
        props.fetchActivities()
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.EXCEPTIONS.UPDATE_A_REPLY.SUCCESS
        })
      })
      .catch((err: any) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.EXCEPTIONS.UPDATE_A_REPLY.ERROR
        })
      })
  }

  const deleteReplyForIncident = () => {
    setDeleteLoading(true)
    deleteComment({
      $exceptionLogId: exceptionLogId,
      $incidentId: incidentId,
      $commentId: replyId
    })
      .then((response: any) => {
        props.fetchActivities()
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.EXCEPTIONS.DELETE_REPLY.SUCCESS
        })
      })
      .catch((err: any) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.EXCEPTIONS.DELETE_REPLY.ERROR
        })
      })
      .finally(() => {
        setDeleteLoading(false)
      })
  }

  React.useEffect(() => {
    if (reply === 'Comment Deleted') {
      setReplyDeleted(true)
    }
  }, [reply])

  return (
    <>
      <DeletePopup
        variant='single'
        open={deleteReplyPopupOpen}
        handleClose={() => {
          setReplyCommentPopupOpen(false)
        }}
        onDelete={deleteReplyForIncident}
        type='Reply'
        loading={deleteLoading}
      />
      <>
        <Reply>
          <TopSection>
            <IconText gridGap='5px'>
              <RoundedImage src={avatar} height='32px' width='32px' />
              <ParaDark fontSize='14px'>{userName}</ParaDark>
            </IconText>
            <ParaLight fontSize='12px'>Posted on {date}</ParaLight>
          </TopSection>

          <Comment>
            {editReply ? (
              <>
                <PostCommentSection>
                  <textarea
                    rows={2}
                    onChange={(e: any) => setEditReplyData(e.target.value)}
                  >
                    {reply}
                  </textarea>
                  <DividerLine margin='10px 0px' />

                  <PostCommentSectionBottom>
                    <Button
                      variant='contained'
                      boxShadow={false}
                      onClick={updateReplyForIncident}
                      disabled={isEmpty(editReplyData)}
                    >
                      Update Reply
                    </Button>
                  </PostCommentSectionBottom>
                </PostCommentSection>
              </>
            ) : (
              <ParaLight
                lineHeight={1.6}
                style={
                  reply === 'Comment Deleted' ? { fontStyle: 'italic' } : {}
                }
                fontSize='14px'
              >
                <div
                  style={{
                    display: ' flex',
                    maxWidth: '350px',
                    wordBreak: 'break-all'
                  }}
                >
                  {reply}
                </div>
              </ParaLight>
            )}
          </Comment>
          {!replyDeleted && store.userStore.getEmail() === props.email ? (
            <ViewReplyBottom>
              {/* Add Auth verification, for delete, only own reply can be edited */}
              <ParaLight
                fontSize='12px'
                onClick={() => {
                  setEditReply(!editReply)
                }}
                style={{ cursor: 'pointer' }}
              >
                {!editReply ? 'Edit' : 'Cancel'}
              </ParaLight>
              {/* Add Auth verification, for delete, only own reply can be deleted */}
              <ParaLight
                fontSize='12px'
                onClick={() => {
                  setReplyCommentPopupOpen(true)
                }}
                style={{ cursor: 'pointer' }}
              >
                Delete
              </ParaLight>
            </ViewReplyBottom>
          ) : (
            <></>
          )}
        </Reply>
      </>
    </>
  )
}

export default IncidentReplySection
