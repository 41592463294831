import Button from '../common/Button/Button'
import DeleteIcon from '../../assets/icons/trash.svg'
import Popup from '../common/Popup/Popup'
import React from 'react'
import { TypographyDDS } from '../common/Typography/TypographyDDS'
import closeIcon from '../../assets/icons/close.svg'
import palette from '../../global/pallete'
import resendIcon from '../../assets/icons/renew.svg'
import styled from '@emotion/styled'
import trashIcon from '../../assets/icons/trash-can.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 65px;
  gap: 10px;
`
const ColorText = styled.span`
  color: ${palette.colors.primary};
  display: inline;
`
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`
const MemberSettingsPopup: React.FC<{
  open: boolean
  name: string
  invitee?: string
  invitedUser?: boolean
  resendInvite?: boolean
  handleClose?: () => void
  handleConfirmRemove?: () => void
  handleConfirmRevoke?: () => void
  handleConfirmResendInvite?: () => void
  loading?: boolean
  isProjectMember?: boolean
}> = ({
  open,
  handleClose = () => null,
  name,
  invitee,
  invitedUser,
  resendInvite,
  handleConfirmRemove = () => null,
  handleConfirmRevoke = () => null,
  handleConfirmResendInvite = () => null,
  loading,
  isProjectMember
}) => {
  return (
    <Popup
      headerText={
        resendInvite
          ? 'Resend Invite'
          : !invitedUser
          ? 'Remove Member'
          : 'Revoke Invite'
      }
      headerIcon={
        <img
          src={resendInvite ? resendIcon : DeleteIcon}
          style={{
            filter: resendInvite
              ? palette.colors.primarySvgFilter
              : palette.colors.redSvgFilter
          }}
        />
      }
      height='330px'
      open={open}
      handleClose={handleClose}
      loading={loading}
    >
      <Container>
        <TypographyDDS.Title type='h3' variant='medium' color='textDark2'>
          {resendInvite ? (
            <>
              Do you want to resend invite to <ColorText>{name}</ColorText>?
            </>
          ) : !invitedUser ? (
            <>
              Do you want to remove <ColorText>{name}</ColorText> from the{' '}
              {`${isProjectMember ? 'project' : 'organization'}`}?
            </>
          ) : (
            <>
              Do you want to revoke the invite for{' '}
              <ColorText>{invitee}</ColorText>?
            </>
          )}
        </TypographyDDS.Title>
        <ButtonWrapper>
          <span style={{ minWidth: '99.03px' }}>
            <Button
              startIcon={<img src={resendInvite ? resendIcon : trashIcon} />}
              color={resendInvite ? 'primary' : 'error'}
              variant='contained'
              margin='20px 0 0 0'
              onClick={() => {
                {
                  resendInvite
                    ? handleConfirmResendInvite()
                    : handleConfirmRemove()
                }
              }}
              loading={loading}
              fullWidth
            >
              {'Yes'}
            </Button>
          </span>
          <Button
            startIcon={
              <img
                src={closeIcon}
                style={{
                  filter: palette.colors.whiteSvgFilter
                }}
              />
            }
            color={resendInvite ? 'error' : 'primary'}
            variant='contained'
            margin='20px 0 0 0'
            onClick={() => {
              handleClose()
            }}
            disabled={loading}
            fullWidth
          >
            {'Cancel'}
          </Button>
        </ButtonWrapper>
      </Container>
    </Popup>
  )
}

export default MemberSettingsPopup
