import {
  ProjectType,
  ProjectTypeDisplayNames
} from '../../../../enum/ProjectType.enum'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../../constants/GAConstants'

import Button from '../../../common/Button/Button'
import Dropdown from '../../../common/Dropdown/Dropdown'
import InputField from '../../../common/InputField/InputField'
import { IoIosArrowForward } from 'react-icons/io'
import { OrganizationType } from '../../../../enum/OrganizationType.enum'
import React from 'react'
import StringConstants from '../../../../constants/StringConstants'
import { TypographyDDS } from '../../../common/Typography/TypographyDDS'
import breakpoints from '../../../../global/breakpoints'
import palette from '../../../../global/pallete'
import styled from '@emotion/styled'
import { useAnalyticsEventTracker } from '../../../../utils/GAUtils'
import { useStoreContext } from '../../../../store/StoreContext'

const ProjectTypeDropdownOptions = [
  {
    name: ProjectTypeDisplayNames.SALESFORCE_ONLY,
    id: ProjectType.SALESFORCE_ONLY
  },
  {
    name: ProjectTypeDisplayNames.FULL_STACK_ONLY,
    id: ProjectType.FULL_STACK_ONLY
  },
  {
    name: ProjectTypeDisplayNames.MULESOFT_ONLY,
    id: ProjectType.MULESOFT_ONLY
  }
]

const SFecosystemProjectDropdownOptions = [
  {
    name: ProjectTypeDisplayNames.SALESFORCE_ONLY,
    id: ProjectType.SALESFORCE_ONLY
  },
  {
    name: ProjectTypeDisplayNames.MULESOFT_ONLY,
    id: ProjectType.MULESOFT_ONLY
  }
]

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 18.75em;
  align-items: center;
  justify-content: space-around;
  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.85rem;
  }
  @media (max-width: ${breakpoints.md}) {
    font-size: 0.7rem;
  }
`
const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
`
const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 50%;
`
const ColorText = styled.div`
  color: ${palette.colors.primary};
  display: inline;
  font-weight: ${palette.fontWeight.bold};
`

interface ProjectNameStepProps {
  handleNext: () => void
  onClose: () => void
  projectName: string
  projectType: string
  isInvalidName: boolean
  isInvalidType: boolean
  helperText: string
  typeHelperText: string
  onNameChange: (projectName: string) => void
  onTypeChange: (projectType: string) => void
  loading: boolean
}

const ProjectNameStep: React.FC<ProjectNameStepProps> = (props) => {
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.PROJECT_NAME_STEP
  )
  const { userStore } = useStoreContext()

  const dropdownOptionsMap: Record<string, any> = {
    [ProjectType.SALESFORCE_ONLY]: {
      name: ProjectTypeDisplayNames.SALESFORCE_ONLY
    },
    [ProjectType.FULL_STACK_ONLY]: {
      name: ProjectTypeDisplayNames.FULL_STACK_ONLY
    },
    [ProjectType.MULESOFT_ONLY]: {
      name: ProjectTypeDisplayNames.MULESOFT_ONLY
    }
  }
  const SFecosystemProjectdropdownOptionsMap: Record<string, any> = {
    [ProjectType.SALESFORCE_ONLY]: {
      name: ProjectTypeDisplayNames.SALESFORCE_ONLY
    },
    [ProjectType.MULESOFT_ONLY]: {
      name: ProjectTypeDisplayNames.MULESOFT_ONLY
    }
  }
  return (
    <Container>
      <LeftContainer>
        <TypographyDDS.Title type='h2' variant='medium'>
          Create a new <ColorText>Project</ColorText>
        </TypographyDDS.Title>
        <TypographyDDS.Title type='h4' color='textDark2' variant='medium'>
          Manage applications and modules individually as projects!
        </TypographyDDS.Title>
      </LeftContainer>
      <RightContainer>
        <InputField
          error={props.isInvalidName}
          label='Project Name'
          // placeholder='Ex: Paloma'
          value={props.projectName}
          onChange={(e) => props.onNameChange(e.target.value)}
          helperText={props.helperText}
          maxLength={StringConstants.NAME_FIELD_MAX_LENGTH}
          minWidth='64px'
          width='100%'
        />
        {userStore.getSelectedOrganization().type ===
          OrganizationType.HYBRID && (
          <Dropdown
            title={`Looking to monitor`}
            variant='default'
            handleChange={(e) => props.onTypeChange(e)}
            width='100%'
            dropdownOptions={ProjectTypeDropdownOptions}
            helperText={props.isInvalidType ? props.typeHelperText : ''}
            error={props.isInvalidType}
            defaultSelected={dropdownOptionsMap[props.projectType]}
          />
        )}
        {userStore.getSelectedOrganization().type ===
          OrganizationType.SALESFORCE_ECOSYSTEM_ONLY && (
          <Dropdown
            title={`Looking to monitor`}
            variant='default'
            handleChange={(e) => props.onTypeChange(e)}
            width='100%'
            dropdownOptions={SFecosystemProjectDropdownOptions}
            helperText={props.isInvalidType ? props.typeHelperText : ''}
            error={props.isInvalidType}
            defaultSelected={
              SFecosystemProjectdropdownOptionsMap[props.projectType]
            }
          />
        )}
        <Button
          variant='contained'
          size='small'
          endIcon={<IoIosArrowForward />}
          onClick={() => {
            props.handleNext()
            gaEventTracker(gaEventConstants.NEW_PROJECT_NAME_CONFIRMED)
          }}
          loading={props.loading}
          disabled={props.loading}
          fullWidth
        >
          Next
        </Button>
      </RightContainer>
    </Container>
  )
}

export default ProjectNameStep
