import {
  BtoA,
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'
import React, { useEffect } from 'react'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'
import {
  getAllProjectMembers,
  removeProjectMember,
  updateProjectMember
} from '../../../api/project/ProjectMember.service'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import AssignMembersPopup from './PopUps/AssignMembers'
import Button from '../Button/Button'
import CustomDropdown from '../Dropdown/CustomDropdown'
import DashboardSectionContainer from '../DashboardSectionContainer/DashboardSectionContainer'
import DeleteIcon from '../../../../src/assets/icons/trash.svg'
import MemberSettingsPopup from '../../MemberSettingsPage/MemberSettingsPopup'
import NoData from '../../NoData/NoData'
import { OrganizationUserRoles } from '../../../enum/OrganizationUserRoles.enum'
import ProjectMemberRoles from '../../../constants/ProjectMemberRoles'
import StringConstants from '../../../constants/StringConstants'
import Table from '../Table/Table'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../DDS/Toast/Toast'
import { Tooltip } from '@mui/material'
import { TypographyDDS } from '../Typography/TypographyDDS'
import palette from '../../../global/pallete'
import plusIcon from '../../../../src/assets/icons/plus.svg'
import routeConstants from '../../../constants/RouteConstants'
import styled from '@emotion/styled'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useObserver } from 'mobx-react-lite'
import { useStoreContext } from '../../../store/StoreContext'

interface ParaProps {
  padding?: string
  lineHeight?: number
  fontSize?: string
}

const RoleDropdownContainer = styled.div`
  display: flex;
  justify-content: center;
  cursor: not-allowed;
`

const ProjectMember = () => {
  const [assignMembersPopUp, setAssignMembersPopUp] = React.useState(false)
  const [deletePopUp, setDeletePopUp] = React.useState(false)
  const [memberTobeRemoved, setMemberTobeRemoved] = React.useState<any>({})
  const [totalCount, setTotalCount] = React.useState<number>(0)
  const store = useStoreContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = React.useState(false)
  const [page, setPage] = React.useState<number>(
    parseInt(searchParams.get('page') as string) || StringConstants.DEFAULT_PAGE
  )
  const CustomDropdownOption = ProjectMemberRoles.map((role, index) => ({
    id: index,
    name: role.toUpperCase()
  }))
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.MANAGE_PROJECT_MEMBERS
  )
  const { projectId } = useParams()
  const navigate = useNavigate()
  const getProjectMembers = () => {
    setLoading(true)
    getAllProjectMembers({
      ...queryParams,
      projectId
    })
      .then((response: any) => {
        setLoading(false)
        store.projectMemberStore.setProjectMembersDetails(response.data)
        setPage(response.page)
        setTotalCount(response.totalCount)
        store.filterStore.setFilters(response.filters)
      })
      .catch((err) => {
        setLoading(false)
        if (err !== 'Forbidden Exception') {
          ToastNotification({
            type: 'error',
            message:
              ToastMessageConstants.PROJECT_MEMBER.FIND_ALL_PROJECT_MEMBERS
                .ERROR
          })
        }
        navigate(
          replaceUrl(
            routeConstants.PROJECT_SETTINGS,
            store.scopeStore.getScope()['organization-id'] as string,
            '',
            ''
          )
        )
      })
      .finally(() => store.uiStore.setGlobalLoader(false))
  }
  const handleDropdown = (role: string, _userId: string) => {
    role = role.toUpperCase()

    updateProjectMember(role, _userId, projectId as string)
      .then(() => {
        ToastNotification({
          type: 'success',
          message:
            ToastMessageConstants.PROJECT_MEMBER.UPDATE_PROJECT_MEMBER.SUCCESS
        })
      })

      .catch((err: any) => {
        ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.PROJECT_MEMBER.UPDATE_PROJECT_MEMBER.ERROR
        })
      })
  }
  const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
  useEffect(() => {
    // getProjectMemberFilters(projectId as string)
    //   .then((response: any) => {
    //     setProjectMemberFilters(response.data)
    //   })
    //   .catch((err) =>
    //     DDSToastNotification.error(
    //       <CustomErrorToast
    //         heading={
    //           ToastMessageConstants.PROJECT_MEMBER.PROJECT_MEMBER_FILTERS.ERROR
    //         }
    //         message={err}
    //       />
    //     )
    //   )
    getProjectMembers()
    if (
      store.projectMemberStore.getProjectMembersDetails().length > 0 ||
      store.filterStore.getFiltersApplied() === true
    ) {
      store.filterStore.setFilterDisabled(false)
    } else {
      store.filterStore.setFilterDisabled(true)
    }
    store.breadcrumbStore.appendBreadCrumbOptions({
      label: store.scopeStore.getSelectedProject().name,
      link: '/'
    })
  }, [location.search])

  useEffect(() => {
    if (store.breadcrumbStore.getBreadcrumbsOptions().length === 0) {
      store.breadcrumbStore.addMultipleBreadCrumbOptions([
        { label: 'Projects', link: routeConstants.PROJECT_SETTINGS },
        {
          label: store.scopeStore
            .getProjects()
            .filter((project) => project.id === projectId)[0]?.name,
          link: routeConstants.PROJECT_SETTINGS_GENERAL.replace(
            ':projectId',
            projectId as string
          )
        },
        { label: 'Members', link: '/' }
      ])
    }
    store.breadcrumbStore.setGoBackLink(
      routeConstants.PROJECT_SETTINGS_GENERAL.replace(
        ':pathIds',
        ('/' + BtoA(store.scopeStore.getOrganizationId() as string)) as string
      ).replace(':projectId', projectId as string)
    )

    return () => {
      store.breadcrumbStore.reset()
    }
  }, [
    store.breadcrumbStore.getBreadcrumbsOptions(),
    store.scopeStore.getSelectedProject(),
    store.scopeStore.getProjects()
  ])

  const handleDelete = () => {
    setLoading(true)
    removeProjectMember(memberTobeRemoved.user.id, projectId as string)
      .then(() => {
        setPage(StringConstants.DEFAULT_PAGE)
        setSearchParams({
          ...queryParams,
          page: StringConstants.DEFAULT_PAGE.toString()
        })
        getProjectMembers()
        ToastNotification({
          type: 'success',
          message:
            ToastMessageConstants.PROJECT_MEMBER.REMOVE_PROJECT_MEMBER.SUCCESS
        })
        setDeletePopUp(false)
      })
      .catch((err) => {
        const memberRole =
          memberTobeRemoved.role?.charAt(0).toUpperCase() +
          memberTobeRemoved.role?.slice(1).toLowerCase()
        ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.PROJECT_MEMBER.REMOVE_PROJECT_MEMBER.ERROR.replace(
              '{RoleName}',
              memberRole
            )
        })
        setDeletePopUp(false)
        setLoading(false)
      })
  }
  const [nameWidths, setNameWidths] = React.useState<number[]>([])
  const [emailWidths, setEmailWidths] = React.useState<number[]>([])
  const [nameMaxWidth, setNameMaxWidth] = React.useState<number>(0)
  const [emailMaxWidth, setEmailMaxWidth] = React.useState<number>(0)

  const nameRef = React.useCallback(
    (node: any) => {
      if (node !== null) {
        nameWidths.push(node.getBoundingClientRect().width)
        if (nameMaxWidth < node.getBoundingClientRect().width)
          setNameMaxWidth(node.getBoundingClientRect().width)
      }
    },
    [nameMaxWidth]
  )

  const emailRef = React.useCallback(
    (node: any) => {
      if (node !== null) {
        emailWidths.push(node.getBoundingClientRect().width)
        if (emailMaxWidth < node.getBoundingClientRect().width)
          setEmailMaxWidth(node.getBoundingClientRect().width)
      }
    },
    [emailMaxWidth]
  )

  const getEllipsisWidth = (maxWidth: number, tableWidth: number) => {
    if (maxWidth > 0 && tableColumns.length > 1 && tableWidth > 0) {
      const width = tableWidth - maxWidth
      const widthLimit = width / (tableColumns.length - 1)

      if (widthLimit < maxWidth) {
        return widthLimit
      }
    }
    return 0
  }

  const tableColumns = [
    {
      title: 'User',
      label: 'user',
      render: (
        item: any,
        fullItem: any,
        page: number,
        tableRef: any,
        index: number
      ) => {
        return (
          <TypographyDDS.Paragraph
            size='para'
            variant='medium'
            color='textDark'
          >
            <Tooltip
              title={
                nameWidths[index] &&
                nameWidths[index] >
                  getEllipsisWidth(nameMaxWidth, tableRef?.current?.offsetWidth)
                  ? `${item.firstName + ' ' + item.lastName}`
                  : ''
              }
            >
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width:
                    getEllipsisWidth(
                      nameMaxWidth,
                      tableRef?.current?.offsetWidth
                    ) > 0
                      ? getEllipsisWidth(
                          nameMaxWidth,
                          tableRef?.current?.offsetWidth
                        ) + 'px'
                      : 'inherit',
                  display: 'inline-block'
                }}
                ref={nameRef}
              >
                {item.firstName + ' ' + item.lastName}
              </span>
            </Tooltip>
          </TypographyDDS.Paragraph>
        )
      }
    },
    {
      title: 'Role',
      label: 'role',
      render: (item: any, fullItem: any) => (
        <RoleDropdownContainer>
          <CustomDropdown
            title={item}
            dropdownOptions={CustomDropdownOption}
            handleDropdown={(id: string) => {
              handleDropdown(id, fullItem.user.id)
            }}
            border={`1px solid ${palette.colors.borderColor}`}
            disabled={true}
            width='150px'
          />
        </RoleDropdownContainer>
      )
    },
    {
      title: 'Email',
      label: 'user',
      render: (
        item: any,
        fullItem: any,
        page: number,
        tableRef: any,
        index: number
      ) => (
        <TypographyDDS.Paragraph size='para' variant='medium' color='textDark'>
          <Tooltip
            title={
              emailWidths[index] &&
              emailWidths[index] >
                getEllipsisWidth(emailMaxWidth, tableRef?.current?.offsetWidth)
                ? item.email
                : ''
            }
          >
            <span
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width:
                  getEllipsisWidth(
                    emailMaxWidth,
                    tableRef?.current?.offsetWidth
                  ) > 0
                    ? getEllipsisWidth(
                        emailMaxWidth,
                        tableRef?.current?.offsetWidth
                      ) + 'px'
                    : 'inherit',
                display: 'inline-block'
              }}
              ref={emailRef}
            >
              {item.email}
            </span>
          </Tooltip>
        </TypographyDDS.Paragraph>
      )
    },
    {
      title: '',
      label: 'recordDetails',
      render: (item: any, fullItem: any) =>
        store.userStore.getUserRole() === OrganizationUserRoles.OWNER && (
          <Button
            startIcon={
              <img
                src={DeleteIcon}
                style={{
                  filter: palette.colors.whiteSvgFilter
                }}
              />
            }
            color='error'
            variant='contained'
            size='small'
            onClick={() => {
              setMemberTobeRemoved(fullItem)
              setDeletePopUp(true)
            }}
            disabled={store.userStore.getNoActiveSubscription()}
          >
            Remove
          </Button>
        )
    }
  ]

  return useObserver(() => (
    <>
      <DashboardSectionContainer
        headerText='Manage Project Members'
        width='100%'
        rightHeaderItem={
          store.userStore.getUserRole() === OrganizationUserRoles.OWNER && (
            <Button
              variant='contained'
              onClick={() => {
                gaEventTracker(gaEventConstants.INITIATE_ADDING_PROJECT_MEMBERS)
                setAssignMembersPopUp(true)
              }}
              size='large'
              startIcon={<img src={plusIcon} />}
              iconSize='12px'
              disabled={store.userStore.getNoActiveSubscription()}
            >
              Add Members
            </Button>
          )
        }
        noDividerPadding
      >
        {/* <Filter options={projectMemberFilters} /> */}
        {store.projectMemberStore.getProjectMembersDetails().length > 0 ||
        loading ? (
          <Table
            columns={tableColumns}
            data={store.projectMemberStore.getProjectMembersDetails()}
            currentPage={page}
            totalCount={totalCount}
            onPageChange={(page: number) => {
              setSearchParams({ ...queryParams, page: page.toString() })
              setPage(page)
              setNameWidths([])
              setEmailWidths([])
            }}
            rowsPerPage={StringConstants.DEFAULT_TABLE_SIZE}
            isLoading={loading}
          />
        ) : (
          <>
            <NoData />
          </>
        )}
      </DashboardSectionContainer>
      {/* PopUp section */}

      <AssignMembersPopup
        projectId={projectId}
        open={assignMembersPopUp}
        handleClose={() => setAssignMembersPopUp(false)}
        getProjectMembers={getProjectMembers}
      />

      {/* Remove Member PopUp */}
      {deletePopUp && (
        <MemberSettingsPopup
          name={
            memberTobeRemoved.user.firstName +
            ' ' +
            memberTobeRemoved.user.lastName
          }
          open={deletePopUp}
          handleConfirmRemove={handleDelete}
          handleClose={() => {
            setDeletePopUp(false)
          }}
          loading={loading}
          isProjectMember={true}
        />
      )}
    </>
  ))
}

export default ProjectMember
