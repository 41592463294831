import Chip from '../Chip/Chip'
import React from 'react'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

interface ButtonGroupProps {
  label?: string
  groupItems: { id: number; title: string }[]
  variant?: 'multiSelect' | 'singleSelect'
  size?: 'small' | 'medium'
  onChange?: (value: any) => void
}

const Label = styled.p`
  font-size: 14px;
  color: ${palette.colors.textDark2};
  font-weight: 500;
  padding: 0px 0px 8px 4px;
`

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  label,
  variant = 'singleSelect',
  size = 'medium',
  groupItems,
  onChange = () => null,
}) => {
  const [selected, setSelected] = React.useState(
    groupItems.map((item) => ({ ...item, isSelected: false }))
  )

  const handleButtonGroup = (id: number) => {
    if (variant === 'singleSelect') {
      setSelected((selected) =>
        selected.map((item) =>
          item.id === id
            ? { ...item, isSelected: true }
            : { ...item, isSelected: false }
        )
      )
      onChange(groupItems.find((item) => item.id === id))
    }
    if (variant === 'multiSelect') {
      setSelected(
        selected.map((item) =>
          item.id === id
            ? { ...item, isSelected: !item.isSelected }
            : { ...item }
        )
      )
    }
  }

  return (
    <div>
      <Label>{label}</Label>
      {selected.map((item, index) => (
        <Chip
          key={item.id}
          label={item.title}
          size={size}
          margin='0px 8px 8px 0px '
          color={
            selected[index].isSelected
              ? `${palette.colors.textLight}`
              : `${palette.colors.textDark2}`
          }
          backgroundColor={
            selected[index].isSelected
              ? `${palette.colors.primary}`
              : `${palette.colors.lightBack}`
          }
          onClick={() => handleButtonGroup(index + 1)}
        />
      ))}
    </div>
  )
}

export default ButtonGroup
