import { ChevronDown, ChevronUp, Settings } from '@carbon/icons-react'
import { DDSButton, DDSMenu, DDSTypography } from 'den-design-system'
import React, { useEffect, useState } from 'react'
import { UrlWithOnlyOrgId, replaceUrl } from '../../../utils/UrlUtils'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { deleteToken, getDataFromCookie } from '../../../utils/AuthUtils'

import { Button } from '../../../components/New/Button/Button'
import { FiLogOut as LogOutIcon } from 'react-icons/fi'
import Notification from '../../common/Notification/Notification'
import StringConstants from '../../../constants/StringConstants'
import { leaveRoom } from '../../../gateway'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { useStoreContext } from '../../../store/StoreContext'

const TopBarProfileSection = () => {
  const [profileMenuOpen, setProfileMenuOpen] = useState(false)
  const store = useStoreContext()
  const isDemo = window.location.host.includes('demo.vigilnow.com')
  const toggleDropdown = () => {
    setProfileMenuOpen(!profileMenuOpen)
  }
  const navigate = useNavigate()

  const navigateToAccountSettings = () => {
    const route = replaceUrl(
      routeConstants.ACCOUNT_SETTING,
      store.scopeStore.getScope()['organization-id'] as string,
      store.scopeStore.getScope()['project-id'] as string,
      store.scopeStore.getScope()['environment-id'] as string
    )

    const queryParams = { tab: '1' }
    navigate({
      pathname: route,
      search: `?${createSearchParams(queryParams)}`
    })
  }

  return (
    <div className='flex row xl:gap-[20px] gap-[2px]'>
      <div className='flex items-center'>
        {!window.location.pathname.split('/').includes('allNotifications') && (
          <Notification
            notificationCount={
              store.userStore.getNotificationCount() +
              store.userStore.getInvitationCount()
            }
          />
        )}
      </div>

      <button
        id='dropdown-avatar'
        onClick={toggleDropdown}
        className='relative flex items-center pe-[1px] rounded'
        type='button'
      >
        <img
          id='dropdown-avatar'
          className='rounded-full border object-fill
          w-[32px] h-[32px]'
          src={store.userStore.avatar}
          alt='user photo'
          style={{ borderColor: pallete.colors.stroke2 }}
        />
        {!profileMenuOpen && (
          <ChevronDown
            size={16}
            color={pallete.colors.textDark4}
            style={{ paddingLeft: '4px' }}
          />
        )}
        {profileMenuOpen && (
          <ChevronUp
            size={16}
            color={pallete.colors.textDark4}
            style={{ paddingLeft: '4px' }}
          />
        )}
        <div className='flex pt-[42px]'>
          {profileMenuOpen && (
            <DDSMenu
              id='profile-menu'
              open={profileMenuOpen}
              setOpen={setProfileMenuOpen}
              parentId='dropdown-avatar'
              shape='roundedRectangle'
              style={{ minWidth: 'fit-content' }}
              className='absolute z-50 right-[0px] !w-auto border-2 shadow-sm max-h-[380px] override-profile-menu'
            >
              {/* <DDSButton
                label={
                  <DDSTypography.Paragraph
                    size='para'
                    variant='semiBold'
                    color={pallete.colors.neuButtonText}
                  >
                    Settings
                  </DDSTypography.Paragraph>
                }
                type='ghost'
                shape='roundedRectangle'
                startIcon={
                  <Settings size={16} color={pallete.colors.neuButtonText} />
                }
                onClick={() => navigateToAccountSettings()}
              /> */}
              <Button
                id='logout'
                type='ghost'
                size='small'
                startIcon={
                  <LogOutIcon size={16} color={pallete.colors.warningRed2} />
                }
                onClick={async () => {
                  localStorage.removeItem(
                    StringConstants.LOCAL_STORAGE.BANNER_CLOSED_FLAG
                  )
                  localStorage.removeItem(
                    StringConstants.LOCAL_STORAGE.CURRENT_PAGE_DETAILS
                  )
                  localStorage.removeItem(StringConstants.ACTIVE_TAB)
                  localStorage.removeItem(StringConstants.ENVIRONMENT_TYPE)
                  const userData: any = getDataFromCookie()
                  await deleteToken()
                  leaveRoom(userData.sub)
                  store.clearStore()
                  navigate(routeConstants.SIGN_IN)
                }}
                label={
                  <DDSTypography.Paragraph
                    size='para'
                    variant='semiBold'
                    color={pallete.colors.warningRed2}
                  >
                    Logout
                  </DDSTypography.Paragraph>
                }
                disabled={store.uiStore.getGlobalLoader() || isDemo}
              />
            </DDSMenu>
          )}
        </div>
      </button>
    </div>
  )
}

export default observer(TopBarProfileSection)
