import HeaderPopup from '../../APIMonitoringPage/HeaderPopup'
import React from 'react'
import { isUndefined } from 'lodash'

const ExceptionContextPopup: React.FC<{
  context: any
  open: boolean
  handleClose: () => void
  destroyOnClose?: boolean
}> = ({ context, open, handleClose, destroyOnClose }) => {
  return (
    <HeaderPopup
      open={open as boolean}
      handleClose={handleClose}
      data={[context]}
      heading='Exception Context'
      disableTab={true}
      left=''
      destroyOnClose={!isUndefined(destroyOnClose) ? destroyOnClose : false}
    />
  )
}

export default ExceptionContextPopup
