import {
  Api,
  CheckmarkFilled,
  Code,
  Debug,
  LogoSlack,
  PillsAdd,
  UserAvatar
} from '@carbon/icons-react'
import LinearProgress, {
  LinearProgressProps
} from '@mui/material/LinearProgress'
import React, { ReactElement, useEffect, useState } from 'react'
import { UrlWithOnlyOrgId, replaceUrl } from '../../utils/UrlUtils'
import { checkNameAvailabilityFromServer, schema } from '../../utils/JobUtils'
import {
  getOrganizationProjectsThingsToDo,
  updateOrganization
} from '../../api/organization/Organization.service'

import AddCheckPopup from '../../components/AHCPage/AddCheckPopupFlow/AddCheckPopup'
import AddEditJobPopUp from '../../components/JobMonitoring/JMDashboard/AddEditPopUp'
import { Box } from '@mui/material'
import { Button } from '../../components/New/Button/Button'
import { DDSTypography } from 'den-design-system'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import EditJobsPopup from '../../components/JobMonitoringPage/EditJobPopup/EditJobsPopup'
import { GettingStartedConstants } from '../../constants/GettingStartedConstants'
import { HeaderConstants } from '../../constants/HeaderConstants'
import HttpConstants from '../../constants/HttpConstants'
import Modal from '../../components/common/DDS/Modal/Modal'
import { OrganizationType } from '../../enum/OrganizationType.enum'
import { OrganizationUserRoles } from '../../enum/OrganizationUserRoles.enum'
import { ProjectType } from '../../enum/ProjectType.enum'
import Spinner from '../../components/common/Spinner/Spinner'
import StringConstants from '../../constants/StringConstants'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../../components/common/DDS/Toast/Toast'
import { TypographyDDS } from '../../components/common/Typography/TypographyDDS'
import { ValidationResult } from 'joi'
import { addQueryParamsToUrl } from '../../utils/UrlGenerator'
import { configureJob } from '../../api/jobs/Jobs.service'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import palette from '../../global/pallete'
import pallete from '../../global/newPallete'
import routeConstants from '../../constants/RouteConstants'
import { thingsToDoTypes } from '../../store/ThingsToDoStore'
import { updateProject } from '../../api/project/Project.service'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../store/StoreContext'

interface JobFormData {
  name: string
  slug: string
  description: string
  cronExpression: string
  monitorJob: boolean
}

const ThingsToDoSection = () => {
  const [dismissSidebarPopUp, setDismissSidebarPopUp] = React.useState(false)
  const [addCheckPopupOpen, setAddCheckPopupOpen] = React.useState(false)
  const [jobPopupOpen, setJobPopupOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const store = useStoreContext()
  const navigate = useNavigate()

  //Job states
  const defaultFormData: JobFormData = {
    name: '',
    slug: '',
    description: '',
    cronExpression: '',
    monitorJob: true
  }
  const [jobFormData, setJobFormData] = useState<any>(defaultFormData)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [isInvalidJobName, setIsInvalidJobName] = useState(false)
  const [isInvalidJobSlug, setIsInvalidJobSlug] = useState(false)

  useEffect(() => {
    const validated: ValidationResult = schema.validate(
      {
        name: jobFormData.name.trim(),
        slug: jobFormData.slug.trim(),
        cronExpression: jobFormData.cronExpression,
        description: jobFormData.description
      },
      {
        abortEarly: false
      }
    )
    const requiredFields = Object.keys(jobFormData).filter(
      (field) => field !== 'description'
    )
    const areAllRequiredFieldsFilled = requiredFields.every(
      (field) => jobFormData[field] !== '' && jobFormData[field] !== null
    )
    if (validated.error) {
      setIsButtonDisabled(true)
    } else {
      setIsButtonDisabled(!areAllRequiredFieldsFilled)
    }
  }, [jobFormData])

  interface ThingToDo {
    id: number
    key: string
    icon: ReactElement
    headerText: string
    bodyText: string
    buttonTitle: string
    onButtonClick?: () => void
  }

  const navigateToDashboard = () => {
    if (window.location.href.includes('things-to-do')) {
      navigate(
        replaceUrl(
          routeConstants.OVERVIEW,
          store.scopeStore.getScope()['organization-id'] as string,
          store.scopeStore.getScope()['project-id'] as string,
          store.scopeStore.getScope()['environment-id'] as string
        )
      )
      localStorage.setItem(
        StringConstants.ACTIVE_TAB,
        StringConstants.TAB_VALUES.OVERVIEW
      )
    }
  }

  const handleThingsToDo = () => {
    const currentProject = store.scopeStore.getSelectedProject()
    if (
      currentProject &&
      (currentProject.type === ProjectType.SALESFORCE_ONLY ||
        currentProject.type === ProjectType.MULESOFT_ONLY) &&
      Number(currentProject.thingsToDo) ===
        StringConstants.THINGS_TO_DO.SALESFORCE_ECOSYSTEM_PROJECT_PROGRESS_VALUE
    ) {
      store.thingsToDoStore.setShowThingsToDo(false)
      navigateToDashboard()
    } else if (
      currentProject &&
      currentProject.type === ProjectType.FULL_STACK_ONLY &&
      Number(currentProject.thingsToDo) ===
        StringConstants.THINGS_TO_DO.FULLSTACK_PROJECT_PROGRESS_VALUE
    ) {
      store.thingsToDoStore.setShowThingsToDo(false)
      navigateToDashboard()
    } else if (
      store.userStore.getUserRole() === OrganizationUserRoles.BILLING
    ) {
      store.thingsToDoStore.setShowThingsToDo(false)
    } else {
      if (
        store.scopeStore.getScope()['organization-id']?.length &&
        store.scopeStore.getScope()['project-id']?.length
      )
        getOrganizationProjectsThingsToDo()
          .then((response: any) => {
            store.thingsToDoStore.setProgressData(response.data)
            store.thingsToDoStore.setShowThingsToDo(true)
          })
          .catch((error) => console.log(error))
          .finally(() => store.uiStore.setGlobalLoader(false))
      if (
        currentProject &&
        (currentProject.type === ProjectType.SALESFORCE_ONLY ||
          currentProject.type === ProjectType.MULESOFT_ONLY)
      ) {
        store.thingsToDoStore.setTotalProgressCount(3)
      } else {
        store.thingsToDoStore.setTotalProgressCount(5)
      }
    }
  }

  useEffect(() => {
    handleThingsToDo()
  }, [store.scopeStore.getSelectedProject().id])

  const navigateToOverview = () => {
    if (!loading) {
      navigate(
        addQueryParamsToUrl(
          routeConstants.REDIRECT,
          {
            redirect: replaceUrl(
              routeConstants.OVERVIEW,
              store.scopeStore.getScope()['organization-id'] as string,
              store.scopeStore.getScope()['project-id'] as string,
              store.scopeStore.getScope()['environment-id'] as string
            )
          },
          HttpConstants.GET_METHOD
        )
      )
      localStorage.setItem(
        StringConstants.ACTIVE_TAB,
        StringConstants.TAB_VALUES.OVERVIEW
      )
    }
  }

  const dismissSideBar = () => {
    setLoading(true)
    updateProject({
      dismissThingsToDo: true,
      projectId: store.scopeStore.getProjectId() as string
    })
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.THINGS_TO_DO_DISMISSED
        })
        navigateToOverview()
      })
      .finally(() => {
        setLoading(false)
        setDismissSidebarPopUp(false)
      })
  }

  const navigateToJobMonitoring = (envId: string) => {
    navigate(
      replaceUrl(
        routeConstants.JOB_MONITORING,
        store.scopeStore.getScope()['organization-id'] as string,
        store.scopeStore.getScope()['project-id'] as string,
        envId
      )
    )
  }

  const navigateToAHC = () => {
    navigate(
      addQueryParamsToUrl(
        routeConstants.REDIRECT,
        {
          redirect: replaceUrl(
            routeConstants.AHC_PROJECTS_DASHBOARD,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          )
        },
        HttpConstants.GET_METHOD
      )
    )
  }

  const navigateToConnectSlack = () => {
    navigate(
      addQueryParamsToUrl(
        routeConstants.REDIRECT,
        {
          redirect:
            UrlWithOnlyOrgId(
              routeConstants.PROJECT_SETTINGS_GENERAL,
              store
            ).replace(
              ':projectId',
              store.scopeStore.getScope()['project-id'] as string
            ) + `?scrollToBottom=true`
        },
        HttpConstants.GET_METHOD
      )
    )
  }

  //Add job functions
  const resetJobForm = () => {
    setJobFormData(defaultFormData)
  }
  const resetCheckNameValidation = () => {
    setIsInvalidJobSlug(false)
    setIsInvalidJobName(false)
  }

  const cancelAddJob = () => {
    resetCheckNameValidation()
    setJobPopupOpen(false)
    resetJobForm()
  }
  const handleJobDataChange = (field: string, value: string) => {
    resetCheckNameValidation()
    setJobFormData((prevData: any) => {
      const updatedData = { ...prevData, [field]: value }

      if (
        field === 'name' &&
        prevData.slug ===
          prevData.name
            .toLowerCase()
            .replace(/-/g, '')
            .replace(/_/g, '-')
            .replace(/[^a-z0-9\s-]/g, '')
            .replace(/\s+/g, '-')
      ) {
        updatedData.slug = value
          .toLowerCase()
          .replace(/-/g, '')
          .replace(/_/g, '-')
          .replace(/[^a-z0-9\s-]/g, '')
          .replace(/\s+/g, '-')
      }

      return updatedData
    })
  }
  const handleAddMonitorSubmit = () => {
    setButtonLoading(true)
    const nameCheckPromises = []

    if (!isEmpty(jobFormData.name)) {
      nameCheckPromises.push(
        checkNameAvailabilityFromServer(jobFormData.name.trim())
      )
    }
    if (!isEmpty(jobFormData.slug)) {
      nameCheckPromises.push(
        checkNameAvailabilityFromServer(jobFormData.slug.trim(), true)
      )
    }
    if (nameCheckPromises.length > 0) {
      Promise.allSettled(nameCheckPromises)
        .then((results) => {
          let success = true
          results.forEach((result, index) => {
            if (result.status !== 'fulfilled') {
              success = false
              if (!isEmpty(jobFormData.name) && index === 0) {
                if (result.reason === StringConstants.NAME_ALREADY_TAKEN) {
                  setIsInvalidJobName(true)
                } else {
                  ToastNotification({
                    type: 'error',
                    message: ToastMessageConstants.SOMETHING_WENT_WRONG
                  })
                }
              } else {
                if (result.reason === StringConstants.SLUG_ALREADY_TAKEN) {
                  setIsInvalidJobSlug(true)
                } else {
                  ToastNotification({
                    type: 'error',
                    message: ToastMessageConstants.SOMETHING_WENT_WRONG
                  })
                }
              }
            }
          })
          if (success) {
            addJob()
          } else {
            setButtonLoading(false)
          }
        })
        .catch((err) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.SOMETHING_WENT_WRONG
          })
          setButtonLoading(false)
        })
    } else {
      addJob()
    }
  }

  const addJob = () => {
    store.uiStore.setGlobalLoader(true)
    configureJob(jobFormData)
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.JOB_MONITORING.CREATE_JOB.SUCCESS
        })
        getOrganizationProjectsThingsToDo()
          .then((response: any) => {
            store.thingsToDoStore.setProgressData(response.data)
            if (
              (response?.data?.progress == 3 &&
                (store.scopeStore.getSelectedProject().type ==
                  ProjectType.SALESFORCE_ONLY ||
                  store.scopeStore.getSelectedProject().type ==
                    ProjectType.MULESOFT_ONLY)) ||
              (response?.data?.progress == 5 &&
                store.scopeStore.getSelectedProject().type ==
                  ProjectType.FULL_STACK_ONLY)
            ) {
              store.thingsToDoStore.setShowThingsToDo(false)
            } else {
              store.thingsToDoStore.setShowThingsToDo(true)
            }
          })
          .catch((error) => console.log(error))
          .finally(() => {
            store.uiStore.setGlobalLoader(false)
          })
        resetCheckNameValidation()
        const selectedEnvType = store.scopeStore.getSelectedEnvironment().type
        if (
          selectedEnvType == StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
        ) {
          localStorage.setItem(
            StringConstants.ENVIRONMENT_TYPE,
            StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
          )
        } else {
          localStorage.setItem(
            StringConstants.ENVIRONMENT_TYPE,
            StringConstants.ENVIRONMENT_TYPE_VALUES.PROD
          )
        }
        navigateToJobMonitoring(store.scopeStore.getEnvironmentId() as string)
      })
      .catch((err) => {
        setButtonLoading(false)
        store.uiStore.setGlobalLoader(false)
        const quotaErrorRegex =
          /Job quota in the organization \(id\)=\([0-9a-fA-F-]{36}\) is full\. Subscribe to get more jobs\./
        if (quotaErrorRegex.test(err)) {
          ToastNotification({
            type: 'error',
            message:
              ToastMessageConstants.JOB_MONITORING.CREATE_JOB.ERROR
                .LIMIT_EXCEEDED
          })
        } else {
          ToastNotification({
            type: 'error',
            message:
              ToastMessageConstants.JOB_MONITORING.CREATE_JOB.ERROR.DEFAULT
          })
        }
      })
      .finally(() => {
        resetJobForm()
        setJobPopupOpen(false)
        setButtonLoading(false)
      })
  }

  //Things to do data
  let data: ThingToDo[] = [
    {
      id: 1,
      key: 'exception',
      icon: <Debug color={pallete.colors.textDark3} size={20} />,
      headerText:
        store.scopeStore.getSelectedProject().type ==
        ProjectType.FULL_STACK_ONLY
          ? 'Configure exception monitoring'
          : 'Configure error monitoring',
      bodyText:
        store.scopeStore.getSelectedProject().type ==
        ProjectType.FULL_STACK_ONLY
          ? GettingStartedConstants.CONFIGURE_EXCEPTION
          : GettingStartedConstants.CONFIGURE_ERROR,
      buttonTitle: 'Learn more',
      onButtonClick: () => {
        if (
          store.scopeStore.getSelectedProject().type ==
          ProjectType.FULL_STACK_ONLY
        )
          window.open(
            process.env.REACT_APP_VIGIL_FULLSTACK_SDK_REFERENCE_URL,
            '_blank'
          )
        else if (
          store.scopeStore.getSelectedProject().type ==
          ProjectType.SALESFORCE_ONLY
        ) {
          window.open(process.env.REACT_APP_VIGIL_SALESFORCE_SDK_URL, '_blank')
        } else {
          window.open(process.env.REACT_APP_VIGIL_MULESOFT_SDK_URL, '_blank')
        }
      }
    },
    {
      id: 2,
      key: 'applicationHealthCheck',
      icon: <PillsAdd color={pallete.colors.textDark3} size={20} />,
      headerText: 'Add health check',
      bodyText: GettingStartedConstants.HEALTH_CHECK,
      buttonTitle: 'Add Check',
      onButtonClick: () => {
        setAddCheckPopupOpen(true)
      }
    },
    {
      id: 3,
      key: 'jobMonitoring',
      icon: <Code color={pallete.colors.textDark3} size={20} />,
      headerText: 'Add Monitor',
      bodyText: GettingStartedConstants.JOB_MONITORING,

      buttonTitle: 'Add Monitor',
      onButtonClick: () => {
        setJobPopupOpen(true)
      }
    },
    {
      id: 4,
      key: 'apiMonitoring',
      icon: <Api color={pallete.colors.textDark3} size={20} />,
      headerText: 'Configure APIs',
      bodyText: GettingStartedConstants.API_MONITORING,
      buttonTitle: 'Learn more',
      onButtonClick: () => {
        window.open(
          process.env.REACT_APP_VIGIL_FULLSTACK_SDK_REFERENCE_URL,
          '_blank'
        )
      }
    },
    //TODO Incase we need to switch to organization level afterwards we can enable this
    // {
    //   id: 5,
    //   key: 'userInvite',
    //   icon: <UserAvatar color={pallete.colors.textDark3} size={20} />,
    //   headerText: 'Invite your colleagues',
    // bodyText:GettingStartedConstants.INVITE_MEMBERS
    //   buttonTitle: 'Send Invite',
    //   onButtonClick: () => {
    //     navigate(routeConstants.INVITE_MEMBERS)
    //   }
    // },
    {
      id: 6,
      key: 'slack',
      icon: <LogoSlack color={pallete.colors.textDark3} size={20} />,
      headerText: 'Connect Slack',
      bodyText:
        store.scopeStore.getSelectedProject().type ==
        ProjectType.FULL_STACK_ONLY
          ? GettingStartedConstants.SLACK_EXCEPTIONS
          : GettingStartedConstants.SLACK_INTEGRATION,
      buttonTitle: 'Add to Slack',
      onButtonClick: () => {
        navigateToConnectSlack()
      }
    }
  ]
  if (
    store.scopeStore.getSelectedProject().type ===
      ProjectType.SALESFORCE_ONLY ||
    store.scopeStore.getSelectedProject().type === ProjectType.MULESOFT_ONLY
  ) {
    data = data.filter((item) => item.id !== 3 && item.id !== 4)
  }
  //TODO this function alone I am using previoulsy mui box and linear progress, considering smoothness and responsiveness
  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
  ) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress
            variant='determinate'
            value={
              store.scopeStore.getSelectedProject().type ==
              ProjectType.FULL_STACK_ONLY
                ? props.value * 20
                : props.value * 33.33
            }
            sx={{
              bgcolor: palette.colors.borderColor2,
              '& .MuiLinearProgress-bar': {
                backgroundColor: palette.colors.progressColor
              }
            }}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <DDSTypography.Paragraph
            color={pallete.colors.textDark5}
            variant='semiBold'
            size='para'
          >{`${Math.round(
            props.value
          )}/${store.thingsToDoStore.getTotalProgressCount()}`}</DDSTypography.Paragraph>
        </Box>
      </Box>
    )
  }

  const Footer: React.FC = () => {
    return (
      <div className='flex override-btn-min-width justify-end py-[16px] px-[24px] gap-[10px]'>
        <Button
          id='no-button'
          disabled={loading}
          label='No'
          onClick={() => setDismissSidebarPopUp(false)}
          type='neutral'
        />
        <Button
          id='yes-button'
          disabled={loading}
          label='Yes'
          onClick={dismissSideBar}
          type='filled'
        />
      </div>
    )
  }

  return (
    <>
      <DocumentHeader header={HeaderConstants.GET_STARTED} />
      <div className='pt-[32px]'>
        <div className=' flex flex-col !gap-y-[8px] '>
          <div className='flex justify-between items-center'>
            <DDSTypography.Title
              type='h1'
              variant='bold'
              color={pallete.colors.textDark3}
              className='!text-[32px]'
            >
              {GettingStartedConstants.WELCOME_TO_VIGIL}
            </DDSTypography.Title>
            <Button
              id='disable-getting-started'
              size='small'
              type='neutral'
              onClick={() => {
                setDismissSidebarPopUp(true)
              }}
              label='Dismiss from Sidebar'
            />
          </div>
          <div>
            <DDSTypography.Paragraph
              color={pallete.colors.textDark4}
              variant='regular'
              size='para'
            >
              {GettingStartedConstants.GET_FAMILIAR_WITH}
            </DDSTypography.Paragraph>
          </div>
        </div>
        {store.uiStore.getGlobalLoader() ? (
          <Spinner />
        ) : (
          <>
            <div className='mt-8 flex justify-between items-center'>
              <DDSTypography.Title
                variant='semiBold'
                type='h3'
                color={pallete.colors.textDark3}
              >
                {GettingStartedConstants.READY_TO_GO}
              </DDSTypography.Title>
              <div className='w-1/3'>
                <LinearProgressWithLabel
                  value={store.thingsToDoStore.getProgressData().progress}
                />
              </div>
            </div>
            <div className='mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[20px]'>
              {data.map((item) => {
                const isCompleted: any =
                  store.thingsToDoStore.getProgressData()[
                    item.key as keyof thingsToDoTypes
                  ]
                return (
                  <div
                    key={item.key}
                    className={`flex flex-col border rounded-lg !p-[20px] h-full
                   gap-[8px]`}
                    style={{
                      border: `1px solid ${isCompleted ? pallete.colors.warningGreen : pallete.colors.stroke2}`
                    }}
                  >
                    <div className='flex items-center'>
                      <div className='flex flex-row !gap-[8px]'>
                        {isCompleted ? (
                          <CheckmarkFilled
                            size={20}
                            color={pallete.colors.warningGreen}
                          />
                        ) : (
                          item.icon
                        )}

                        <DDSTypography.Title
                          color={pallete.colors.textDark3}
                          variant='bold'
                          type='h5'
                        >
                          {item.headerText}
                        </DDSTypography.Title>
                      </div>
                    </div>
                    <div className='flex-grow my-2'>
                      <DDSTypography.Paragraph
                        size='para'
                        variant='regular'
                        color={pallete.colors.textDark5}
                      >
                        {item.bodyText}
                      </DDSTypography.Paragraph>
                    </div>
                    <div className='flex justify-end'>
                      <Button
                        id={`${item.id}-action-click`}
                        size='small'
                        type='filled'
                        onClick={item.onButtonClick}
                        label={item.buttonTitle}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </div>
      {dismissSidebarPopUp && (
        <Modal
          open={dismissSidebarPopUp}
          setOpen={() => {
            setDismissSidebarPopUp(true)
          }}
          onClosePress={() => {
            setDismissSidebarPopUp(false)
          }}
          primaryButtonText='Yes'
          secondaryButtonText='No'
          className=''
          showHeader
          hideCloseIcon={false}
          footer={<Footer />}
          title='Dismiss from sidebar'
        >
          <div className='flex flex-col'>
            <div className='flex flex-col p-[24px] overflow-y-scroll'>
              <div className='flex flex-row w-full items-center justify-center'>
                <DDSTypography.Title
                  color={pallete.colors.textDark4}
                  variant='medium'
                  type='h3'
                  className='text-center flex flex-row gap-x-[4px] whitespace-nowrap'
                >
                  {GettingStartedConstants.REMOVE_TEXT}
                  <DDSTypography.Title
                    color={pallete.colors.textDark2}
                    variant='medium'
                    type='h3'
                    className='text-center whitespace-nowrap'
                  >
                    <span>{`"Get Started"`}</span>
                  </DDSTypography.Title>
                  {GettingStartedConstants.FROM_SIDEBAR}
                </DDSTypography.Title>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {
        <AddCheckPopup
          handleClose={() => setAddCheckPopupOpen(false)}
          open={addCheckPopupOpen}
          onComplete={() => navigateToAHC()}
          fromThingsToDo
        />
      }
      {
        <AddEditJobPopUp
          isAdd
          open={jobPopupOpen}
          fromThingsToDo
          setOpen={setJobPopupOpen}
          onCancel={cancelAddJob}
          onSubmit={handleAddMonitorSubmit}
          loading={store.uiStore.getGlobalLoader()}
          buttonLoading={buttonLoading}
          buttonDisabled={isButtonDisabled}
          values={jobFormData}
          isInvalidJobName={isInvalidJobName}
          isInvalidJobSlug={isInvalidJobSlug}
          handleInputChange={handleJobDataChange}
        />
      }
    </>
  )
}

export default observer(ThingsToDoSection)
