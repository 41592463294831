import 'dayjs/locale/en'

import { CheckmarkOutline, PauseOutline, Warning } from '@carbon/icons-react'

import Joi from 'joi'
import MulesoftIcon from '../components/common/Icon/MulesoftIcon'
import OverviewStringConstants from '../constants/OverviewStringConstants'
import React from 'react'
import SalesforceIcon from '../components/common/Icon/SalesforceIcon'
import ValidationConstants from '../constants/ValidationConstants'
import dayjs from 'dayjs'
import pallete from '../global/newPallete'
import salesforceLogo from '../assets/icons/SalesforceLogo.svg'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

export const inviteSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'string.empty': ValidationConstants.INVITE_MEMBER.MEMBER_EMAIL,
      'string.email': ValidationConstants.INVITE_MEMBER.VALID_EMAIL
    }),
  role: Joi.string().required().messages({
    'any.required': ValidationConstants.INVITE_MEMBER.MEMBER_ROLE,
    'string.empty': ValidationConstants.INVITE_MEMBER.MEMBER_ROLE
  })
})

export const getAddLabelForTitle = (title: string) => {
  switch (title) {
    case OverviewStringConstants.UPTIME_CHECKS_TITLE:
      return OverviewStringConstants.ADD_CHECK_LABEL
    case OverviewStringConstants.JOBS_TITLE:
      return OverviewStringConstants.ADD_JOB_LABEL
    default:
      return ''
  }
}

export const getIconForProjectType = (projectType: string) => {
  switch (projectType) {
    case 'SALESFORCE_ONLY':
      return <img src={salesforceLogo} width={20} height={14} />
    case 'MULESOFT_ONLY':
      return <MulesoftIcon width={20} height={20} />
    default:
      return null
  }
}

export const getDataForProjectType = (data: any, projectType: string) => {
  switch (projectType) {
    case 'FULL_STACK_ONLY':
      return data
    case 'SALESFORCE_ONLY':
      return data.filter((feature: any) => feature.id !== 2 && feature.id !== 4)
    case 'MULESOFT_ONLY':
      return data.filter((feature: any) => feature.id !== 2 && feature.id !== 4)
    default:
      return []
  }
}

const severityMapping: { [key: string]: string } = {
  criticalCount: 'Critical',
  majorCount: 'Major',
  moderateCount: 'Moderate',
  minorCount: 'Minor'
}

export const SeverityColorMapping = {
  Critical: pallete.colors.warningRed1,
  Major: pallete.colors.warningOrange,
  Moderate: pallete.colors.warningBlue,
  Minor: pallete.colors.textDark6
}

export const StatusCodeGraphMapping = {
  Unresolved: '#B9B9B9',
  Resolved: '#56B320'
}
export const statusCountMapping: { [key: string]: string } = {
  unresolvedExceptions: 'Unresolved',
  resolvedExceptions: 'Resolved'
}

export const statusToIcon = (status: string) => {
  switch (status) {
    case 'Up':
      return (
        <CheckmarkOutline
          width='20px'
          height='20px'
          color={pallete.colors.warningGreen}
        />
      )

    case 'Paused':
      return (
        <PauseOutline
          width='20px'
          height='20px'
          color={pallete.colors.warningOrange}
        />
      )

    case 'Down':
      return (
        <Warning
          width='20px'
          height='20px'
          color={pallete.colors.warningRed2}
        />
      )

    default:
      return null
  }
}

export function transformSeverityData(data: any): any {
  // Calculate the total count
  const totalCount = Object.values(data).reduce(
    (total: number, count: any) =>
      total + (typeof count === 'number' ? count : 0),
    0
  )

  const transformedData: any = {}

  Object.keys(data).forEach((key) => {
    const severityName = severityMapping[key]
    const count = data[key]

    transformedData[severityName] = {
      count: count,
      percentage:
        totalCount > 0 ? parseFloat(((count / totalCount) * 100).toFixed(2)) : 0
    }
  })

  return transformedData
}

export function transformStatusData(data: any): any {
  const totalCount = Object.values(data).reduce(
    (total: number, count: any) =>
      total + (typeof count === 'number' ? count : 0),
    0
  )

  const transformedData: any = {}

  // Include only resolvedExceptions and unresolvedExceptions
  Object.keys(data).forEach((key) => {
    if (key === 'resolvedExceptions' || key === 'unresolvedExceptions') {
      const statusName = statusCountMapping[key]
      const count = data[key]

      transformedData[statusName] = {
        count: count,
        percentage:
          totalCount > 0
            ? parseFloat(((count / totalCount) * 100).toFixed(2))
            : 0
      }
    }
  })

  return transformedData
}

export const transformIntervalData = (
  overviewData: any,
  graphTab: string,
  type: 'jobs' | 'health'
) => {
  const data: { x: string; y: number }[] = []
  const dateSet: Set<string> = new Set()

  Object.keys(overviewData.responsesByInterval).forEach((key: string) => {
    const dateStr = key.split(' ')[0]
    const dateParts = dateStr.split('/')
    const formattedDate = new Date(
      parseInt(dateParts[2], 10),
      parseInt(dateParts[0], 10) - 1,
      parseInt(dateParts[1], 10)
    )

    const options = { day: 'numeric', month: 'short' } as const
    const dateFormatter = new Intl.DateTimeFormat('en-US', options)
    const dateLabel = dateFormatter.format(formattedDate)

    if (!dateSet.has(dateLabel)) {
      dateSet.add(dateLabel)

      data.push({
        x: dateLabel,
        y:
          type === 'jobs'
            ? graphTab === '1'
              ? (overviewData.responsesByInterval[key].successfulJobs ?? 0)
              : (overviewData.responsesByInterval[key].failedJobs ?? 0)
            : graphTab === '1'
              ? (overviewData.responsesByInterval[key].totalDown ?? 0)
              : (overviewData.responsesByInterval[key].totalUp ?? 0)
      })
    } else {
      data.push({
        x: '',
        y:
          type === 'jobs'
            ? graphTab === '1'
              ? (overviewData.responsesByInterval[key].successfulJobs ?? 0)
              : (overviewData.responsesByInterval[key].failedJobs ?? 0)
            : graphTab === '1'
              ? (overviewData.responsesByInterval[key].totalDown ?? 0)
              : (overviewData.responsesByInterval[key].totalUp ?? 0)
      })
    }
  })
  return data
}

export const dateRange = (timeZone: string) => {
  const currentDate = dayjs().tz(timeZone).startOf('day')
  const startDate = currentDate.subtract(6, 'day')
  const formattedStartDate = startDate.format('MMM DD, YYYY')
  const formattedEndDate = currentDate.format('MMM DD, YYYY')
  return `${formattedStartDate} - ${formattedEndDate}`
}

export const availabilityToColor = (availability: string) => {
  if (availability === '0') {
    return pallete.colors.warningRed2
  } else if (availability === '-') {
    return pallete.colors.textDark4
  } else {
    return pallete.colors.warningGreen
  }
}

export const formatTextForTooltip = (text: string) => {
  const chunkSize = 30
  const chunks = []

  for (let i = 0; i < text.length; i += chunkSize) {
    chunks.push(text.slice(i, i + chunkSize))
  }

  return chunks.join(' / ')
}
