import React, { useState } from 'react'
import {
  getValidationError,
  getValidationStatus
} from '../../utils/ValidationUtils'
import { useNavigate, useParams } from 'react-router-dom'

import { BsChevronRight } from 'react-icons/bs'
import Button from '../common/Button/Button'
import DocumentHeader from '../Helmet/DocumentHeader'
import { HeaderConstants } from '../../constants/HeaderConstants'
import InputField from '../common/InputField/InputField'
import Joi from 'joi'
import Navbar from '../Navbar/Navbar'
import RegexConstants from '../../constants/RegexConstants'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../common/DDS/Toast/Toast'
import { TypographyDDS } from '../common/Typography/TypographyDDS'
import UnsecureComponent from '../Layout/UnsecureComponent'
import ValidationConstants from '../../constants/ValidationConstants'
import { isUndefined } from 'lodash'
import palette from '../../global/pallete'
import { resetPassword } from '../../api/Auth.service'
import styled from '@emotion/styled'

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${palette.colors.white};
`
const NavbarContainer = styled.div`
  margin: 3em 0;
  width: 1536px;

  @media screen and (max-width: 1600px) {
    width: 100%;
    padding: 0px 25px;
  }
`

const PasswordResetContainer = styled.div`
  padding-top: 150px;
  flex-grow: 1;
  width: 757px;
`
const InputFieldContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 6px 0px;
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0px;
`

const ResetPassword = () => {
  const [password, setPassword] = React.useState<string>('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [validationErrorResult, setValidationErrorResult] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const { token } = useParams()
  const resetSchema = Joi.object({
    password: Joi.string()
      .pattern(RegexConstants.PASSWORD)
      .min(8)
      .required()
      .messages({
        'string.min': ValidationConstants.GENERIC.MIN_LENGTH.replace(
          '{length}',
          '8'
        ),
        'string.empty': ValidationConstants.GENERIC.REQUIRED_VALUE,
        'string.pattern.base':
          ValidationConstants.PASSWORD.INVALID_PASSWORD_REGEX
      }),
    confirmPassword: Joi.any()
      .required()
      .equal(Joi.ref('password'))
      .messages({
        'string.min': ValidationConstants.GENERIC.MIN_LENGTH.replace(
          '{length}',
          '8'
        ),
        'string.empty': ValidationConstants.GENERIC.REQUIRED_VALUE,
        'any.only': ValidationConstants.PASSWORD.PASSWORDS_MISMATCH
      })
  })
  const handleNext = () => {
    setLoading(true)

    const validationResult = resetSchema.validate(
      {
        password,
        confirmPassword
      },
      { abortEarly: false }
    )
    if (isUndefined(validationResult.error)) {
      setValidationErrorResult([])
      resetPassword({ password, $token: token as string })
        .then(() => {
          setLoading(false)
          ToastNotification({
            type: 'success',
            message: ToastMessageConstants.AUTH.RESET_PASSWORD.SUCCESS
          })
          navigate('/signIn')
        })
        .catch((err) => {
          setLoading(false)
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.AUTH.RESET_PASSWORD.ERROR
          })
        })
    } else {
      setValidationErrorResult(validationResult.error.details)
    }
    setLoading(false)
  }

  return (
    <Container>
      <DocumentHeader header={HeaderConstants.RESET_PASSWORD} />
      <NavbarContainer>
        <Navbar buttonLink='/signIn' withLinks={false} />
      </NavbarContainer>
      <PasswordResetContainer>
        <TypographyDDS.Title
          type='h1'
          variant='bold'
          color='primary'
          style={{
            paddingBottom: '10px',
            textAlign: 'center',
            fontSize: '32px',
            lineHeight: '38px'
          }}
        >
          Create New Password
        </TypographyDDS.Title>
        <TypographyDDS.Title
          type='h3'
          variant='semiBold'
          style={{
            textAlign: 'center',
            paddingBottom: '10px',
            margin: '0px 0px 16px 0px'
          }}
        >
          You won’t be able to join using your old password
        </TypographyDDS.Title>
        <InputFieldContainer>
          <InputField
            error={getValidationStatus(validationErrorResult, 'password')}
            type='password'
            width='100%'
            label='New Password'
            name='password'
            // placeholder='Ex: xyz@pqr.com'
            margin='8px 0px'
            onChange={(e) => setPassword(e.target.value)}
            helperText={getValidationError(validationErrorResult, 'password')}
          />

          <InputField
            error={getValidationStatus(
              validationErrorResult,
              'confirmPassword'
            )}
            type='password'
            name='password'
            width='100%'
            label='Confirm New Password'
            // placeholder='Same as above'
            margin='8px 0px'
            onChange={(e) => setConfirmPassword(e.target.value)}
            helperText={getValidationError(
              validationErrorResult,
              'confirmPassword'
            )}
          />
        </InputFieldContainer>
        <ButtonContainer>
          <Button
            variant='contained'
            endIcon={<BsChevronRight />}
            background='linear-gradient(270deg, #066CFF 0%, #05A0FA 100%);'
            boxShadow
            onClick={() => handleNext()}
            fullWidth
            size='large'
          >
            Reset Password
          </Button>
        </ButtonContainer>
      </PasswordResetContainer>
    </Container>
  )
}

export default UnsecureComponent(ResetPassword)
