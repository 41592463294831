import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'

import React from 'react'
import { TypographyDDS } from '../Typography/TypographyDDS'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'

interface FilledTabGroup {
  currentTab: number
  tabLabels: { id: number | string; label: string }[]
  onChange?: (newValue: number) => void
  tabChildren?: React.ReactNode[]
  tabWidth?: string
  tabHeight?: string
  disabled?: boolean
  tabWrapperProps?: Partial<TabWrapperProps>
}

interface TabWrapperProps {
  isActive: boolean
  isFirst: boolean
  isLast: boolean
  width?: string
  height?: string
  useMediaQuery?: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
const TabWrapper = styled.div<TabWrapperProps>`
  display: flex;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  border: 1px solid ${palette.colors.borderColor};
  border-top-left-radius: ${({ isFirst }) => (isFirst ? '6px' : '0px')};
  border-bottom-left-radius: ${({ isFirst }) => (isFirst ? '6px' : '0px')};
  border-top-right-radius: ${({ isLast }) => (isLast ? '6px' : '0px')};
  border-bottom-right-radius: ${({ isLast }) => (isLast ? '6px' : '0px')};
  background: ${({ isActive }) =>
    isActive ? palette.colors.primary : 'transparent'};
  &:hover {
    cursor: pointer;
  }
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  ${(props: TabWrapperProps) =>
    props.useMediaQuery &&
    `
        @media (max-width: 820px) {
          width: auto;
        height: auto;
        padding: 5px;
        }
      `}
`
const FilledTabGroup: React.FC<FilledTabGroup> = ({
  currentTab,
  tabLabels,
  onChange = () => null,
  tabChildren,
  tabWidth = '7.5em',
  tabHeight = '3.0625em',
  disabled,
  tabWrapperProps
}) => {
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.NOTIFICATION_DROPDOWN
  )
  const tabLength = tabLabels.length
  return (
    <>
      <Container style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
        {tabLabels.map((item, index) => (
          <TabWrapper
            width={tabWidth}
            height={tabHeight}
            isActive={index === currentTab}
            key={item.id}
            isFirst={index === 0}
            isLast={index === tabLength - 1}
            onClick={() => {
              if (item.label === 'Notifications' || item.label === 'Invites')
                gaEventTracker(
                  gaEventConstants.SWITCH_TAB_NOTIFICATION_DROPDOWN
                )
              else if (
                item.label === 'Headers' ||
                item.label === 'Params' ||
                item.label === 'Query'
              )
                gaEventTracker(gaEventConstants.SWITCH_BETWEEN_PARAMS)
              else if (item.label === 'Raw')
                gaEventTracker(gaEventConstants.RAW_EXCEPTION_STACK)
              else if (item.label === 'Full')
                gaEventTracker(gaEventConstants.FULL_EXCEPTION_STACK)
              onChange(index)
            }}
            style={{
              pointerEvents: disabled ? 'none' : 'initial'
            }}
            {...tabWrapperProps}
          >
            <TypographyDDS.Paragraph
              variant='semiBold'
              size='para'
              color={index === currentTab ? 'white' : 'textDark5'}
            >
              {item.label}
            </TypographyDDS.Paragraph>
          </TabWrapper>
        ))}
      </Container>
      {tabChildren && tabChildren[currentTab]}
    </>
  )
}

export default FilledTabGroup
