import { IoIosArrowForward } from 'react-icons/io'
import React from 'react'
import { TypographyDDS } from '../../common/Typography/TypographyDDS'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 15%;
`

const CountContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;
  align-items: flex-start;
`
const ActionContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
`
const CountLabelTypography = styled(TypographyDDS.Paragraph)<{
  disabled: boolean
  active: boolean
}>`
  color: ${({ disabled, active }) => {
    if (active) return 'black'
    if (disabled) return palette.colors.textDark4
    return palette.colors.textDark2
  }};
`

const CountTypography = styled(TypographyDDS.Title)<{
  disabled: boolean
  active: boolean
}>`
  color: ${({ disabled, active }) => {
    if (active) return palette.colors.primary
    if (disabled) return palette.colors.textDark4
    return 'black'
  }};
`

const ActionIconWrapper = styled.div<{
  disabled: boolean
}>`
  color: ${({ disabled }) => {
    if (disabled) return palette.colors.textDark4
    return palette.colors.primary
  }};
`

const ActionLabelTypography = styled(TypographyDDS.Paragraph)<{
  disabled: boolean
}>`
  color: ${({ disabled }) => {
    if (disabled) return palette.colors.textDark4
    return palette.colors.textDark2
  }};
`

export interface CheckStatusFilterProps {
  count?: number
  countLabel?: string
  actionLabel?: string
  onClick?: () => void
  active?: boolean
  disabled?: boolean
}
const ChecksStatusFilter: React.FC<CheckStatusFilterProps> = ({
  count = 5,
  countLabel = 'Total Checks',
  actionLabel = 'View Checks',
  onClick,
  active = false,
  disabled = false
}) => {
  return (
    <Container>
      <CountContainer>
        <CountLabelTypography
          size='para'
          variant='semiBold'
          disabled={disabled}
          active={active}
        >
          {countLabel}
        </CountLabelTypography>
        <CountTypography
          type='h3'
          variant='bold'
          disabled={disabled}
          active={active}
        >
          {count.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })}
        </CountTypography>
      </CountContainer>
      <ActionContainer
        disabled={disabled}
        onClick={() => !disabled && onClick && onClick()}
      >
        <ActionIconWrapper disabled={disabled}>
          <IoIosArrowForward fontSize={'24px'} />
        </ActionIconWrapper>
        <ActionLabelTypography
          size='caption'
          variant='semiBold'
          disabled={disabled}
        >
          {actionLabel}
        </ActionLabelTypography>
      </ActionContainer>
    </Container>
  )
}

export default ChecksStatusFilter
