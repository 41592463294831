import { DDSInput, DDSTypography } from 'den-design-system'

import { InputProps } from './Input.types'
import React from 'react'
import pallete from '../../../global/newPallete'

interface labelProps {
  label: string | React.ReactNode
}
const Input: React.FC<InputProps> = ({
  name,
  size = 'small',
  reference,
  tabIndex,
  type = 'text',
  label,
  labelPosition = 'side',
  labelIcon,
  width,
  height,
  showLabelIcon = false,
  suffixIcon,
  showSuffixIcon,
  validateInput = false,
  autoComplete = 'on',
  validationRegex,
  errorMessage,
  validate,
  id,
  value,
  placeholder,
  showPlaceHolder = true,
  required = false,
  maxLength,
  minLength,
  disabled = false,
  textAlign = 'left',
  onBlur,
  onFocus,
  onChange,
  onError
}) => {
  const baseStyles = {
    width: width ? width : '290px',
    height:
      size === 'small'
        ? '28px'
        : size === 'large'
          ? '32px'
          : size === 'extraLarge'
            ? '36px'
            : '',
    textAlign: textAlign
  }
  const Label: React.FC<labelProps> = ({ label }) => {
    return (
      <div className='flex gap-[8px] items-center justify-center'>
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark3}
          className=' text-left'
        >
          {label}
        </DDSTypography.Paragraph>
        <div>{showLabelIcon && labelIcon}</div>
      </div>
    )
  }
  return (
    <div className='override-input'>
      <DDSInput
        variant='additionalLabelAttached'
        name={name}
        type={type}
        shape='roundedRectangle'
        reference={reference}
        label={labelPosition === 'top' ? <Label label={label} /> : undefined}
        additionalLabel={labelPosition === 'side' ? label : undefined}
        tabIndex={tabIndex}
        suffixIcon={showSuffixIcon ? suffixIcon : null}
        validateInput={validateInput}
        autoComplete={autoComplete}
        validationRegex={validationRegex}
        errorMessage={errorMessage}
        validate={validate}
        id={id}
        onError={onError}
        value={value}
        placeholder={showPlaceHolder ? placeholder : ''}
        required={labelPosition === 'side' ? false : required}
        maxLength={maxLength}
        minLength={minLength}
        disabled={disabled}
        onChange={onChange}
        className='input-vigil'
        onBlur={onBlur}
        onFocus={onFocus}
        style={baseStyles}
        width={width}
        height={height}
      />
    </div>
  )
}
export default Input
