import { Chat, ChevronRight } from '@carbon/icons-react'
import { DDSChips, DDSRangePicker, DDSTypography } from 'den-design-system'
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import { formatMonth, timeZone } from '../../utils/DateTimeUtils'
import { gaEventConstants, gaPageConstants } from '../../constants/GAConstants'
import {
  getAllIncidents,
  getIncidentFilters,
  updateAnIncident
} from '../../api/incident/incident.service'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../utils/UrlUtils'
import { isArray, isEmpty, isNull } from 'lodash'
import { useEffect, useRef, useState } from 'react'

import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import { Button } from '../../components/New/Button/Button'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import Filter from '../../components/Filter/Filter'
import { HeaderConstants } from '../../constants/HeaderConstants'
import IncidentMenuList from '../NewVersion/IncidentManagement/IncidentMenuList'
import IncidentPriorityDropDown from '../../components/common/IncidentPriorityDropdown/IncidentPriorityDropDown'
import { IncidentStatusTypes } from '../../api/exception/Exception.types'
import NoDataImage from '../../components/Overview/NoDataImage'
import NoFilterImage from '../../assets/images/folder-not-found 1.svg'
import NoRecord from '../../components/common/NoContent/NoRecord'
import PriorityChangePopup from '../NewVersion/IncidentManagement/IncidentPopup/PriorityChangePopup'
import React from 'react'
import ReportAnIncident from '../../assets/images/add.svg'
import SecureComponent from '../../components/Layout/SecureComponent'
import Spinner from '../../components/common/Spinner/Spinner'
import StringConstants from '../../constants/StringConstants'
import Table from '../../components/common/DDS/Table/Table'
import Tabs from '../../components/common/DDS/Tabs/Tabs'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../../components/common/DDS/Toast/Toast'
import Tooltip from '../../components/New/Tooltip/Tooltip'
import dayjs from 'dayjs'
import { getAllProjectMembers } from '../../api/project/Project.service'
import { observer } from 'mobx-react-lite'
import pallete from '../../global/newPallete'
import relativeTime from 'dayjs/plugin/relativeTime'
import routeConstants from '../../constants/RouteConstants'
import { timeFormats } from '../../enum/TIME'
import updateLocale from 'dayjs/plugin/updateLocale'
import { useAnalyticsEventTracker } from '../../utils/GAUtils'
import { useStoreContext } from '../../store/StoreContext'

const Incident = () => {
  const [unresolvedTableData, setUnresolvedTableData] = useState<any>([])
  const [resolvedTableData, setResolvedTableData] = useState<any>([])
  const [unresolvedCurrentPage, setUnresolvedCurrentPage] = useState<number>(1)
  const [resolvedCurrentPage, setResolvedCurrentPage] = useState<number>(1)
  const [resolvedTotalCount, setResolvedTotalCount] = useState<number>(0)
  const [unresolvedTotalCount, setUnresolvedTotalCount] = useState<number>(0)
  const [assignMemberOptions, setAssignMemberOptions] = useState<any>([])

  const [options, setOptions] = useState<any>([])
  const [dateRange, setDateRange] = useState<any>([null, null])
  const [startDate, endDate] = dateRange
  const [modalOpen, setModalOpen] = useState(false)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const currTab = searchParams.get('tab')
  const [activeTab, setActiveTab] = useState<string>(currTab ?? '1')
  const tableRef = useRef<HTMLDivElement>(null)

  const store = useStoreContext()
  const [changeToPriority, setChangeToPriority] = useState<any>({
    id: '',
    fromPriority: '',
    toPriority: ''
  })
  const { incidentId } = useParams()
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.INCIDENT_TABLE
  )
  const [loading, setLoading] = useState<boolean>(false)
  const updateUnresolvedPriority = (incidentId: string, priority: string) => {
    // TODO call backend
  }

  const routeGroupedDetails = (id: number) => {
    const route = replaceUrl(
      routeConstants.MANAGE_INCIDENTS,
      store.scopeStore.getScope()['organization-id'] as string,
      store.scopeStore.getScope()['project-id'] as string,
      store.scopeStore.getScope()['environment-id'] as string
    ).replace(':incidentId', String(id))

    const queryParams = { subTab: '1' }
    navigate({
      pathname: route,
      search: `?${createSearchParams(queryParams)}`
    })
  }

  dayjs.extend(relativeTime)
  dayjs.extend(updateLocale)

  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'a few seconds',
      m: 'a minute',
      mm: '%d minutes',
      h: 'an hour',
      hh: '%d hours',
      d: 'a day',
      dd: '%d days',
      w: 'a week',
      ww: '%d weeks',
      M: 'a month',
      MM: '%d months',
      y: 'a year',
      yy: '%d years'
    }
  })

  const convertToRelativeTime = (dateString: string): string => {
    return dayjs(dateString).fromNow()
  }
  const filteredOptions = options.filter(
    (option: any) => option.heading !== 'Status'
  )

  useEffect(() => {
    if (store.filterStore.getFilterDisabled()) {
      store.filterStore.setFilterDisabled(false)
    }
  }, [store.filterStore.getFilterDisabled()])

  const columns = [
    {
      columnHeaderStyle: {},
      columnDataStyle: { width: '200px' },
      heading: (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Brief Description
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'briefDescription',
      render: (text: any) => (
        <Tooltip
          id={`${text}`}
          label={text}
          position='top'
          className={`override-tooltip-arrow max-w-fit ${
            typeof text === 'string' && text.length > 15 ? '' : '!hidden'
          }`}
        >
          <div className='flex justify-start text-start row items-start w-[200px]'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className='tooltip-container sm:block overflow-hidden truncate max-w-[200px]'
            >
              {text || text === 0 ? text : '-'}
            </DDSTypography.Paragraph>
          </div>
        </Tooltip>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Activity
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'activityCount',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          {text === '0' ? (
            <Chat size={16} className='' />
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '8px'
                }}
              >
                <DDSTypography.Paragraph
                  size='para'
                  variant='medium'
                  color={pallete.colors.textDark4}
                >
                  {text}
                </DDSTypography.Paragraph>
                <div>
                  <Chat size={16} className='' />
                </div>
              </div>
            </>
          )}
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: { width: '120px' },
      heading: (
        <div className='flex justify-center text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Created On
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'createdAt',
      render: (text: any) => (
        <Tooltip
          id={`${text}`}
          label={
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark4}
            >
              {formatMonth(
                timeZone(
                  store.userStore.getUserPreference().timeZone,
                  text,
                  timeFormats.fullMonthDateYear
                )
              )}
            </DDSTypography.Paragraph>
          }
          position='top'
          className={`override-tooltip-arrow ${
            typeof text === 'string' && text.length > 20 ? text : '!hidden'
          }`}
        >
          <div className='flex justify-center text-center row items-center max-w-[120px] '>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className='tooltip-container sm:block overflow-hidden truncate max-w-[120px]'
            >
              {convertToRelativeTime(text)}
            </DDSTypography.Paragraph>
          </div>
        </Tooltip>
      )
    },
    {
      columnHeaderStyle: { 'text-align': 'center' },
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Assigned To
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'assignee',
      render: (text: any, fullItem: any) => {
        return (
          <div className='flex justify-center text-center  row items-center'>
            <IncidentMenuList
              id={`${incidentId}`}
              label='Assign Members'
              menuPosition='center'
              left={false}
              disabled={fullItem.status === 'Resolved' ? true : false}
              disableNoSubscription={store.userStore.getNoActiveSubscription()}
              userOptions={assignMemberOptions}
              selectedUsers={text}
              setSelectedUsers={(value: any) => {
                handleAssignMembers(fullItem['id'], value)
              }}
            />
          </div>
        )
      }
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {activeTab === '1' ? 'Status' : 'Resolved On'}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: activeTab === '1' ? 'status' : 'resolvedAt',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
          >
            {activeTab === '2'
              ? text
                ? formatMonth(
                    timeZone(
                      store.userStore.getUserPreference().timeZone,
                      text,
                      timeFormats.fullMonthDateYear
                    )
                  )
                : '-'
              : text ?? '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Priority
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'priority',
      render: (text: any, fullItem: any) => {
        const setPriority = (priority: string) => {
          setModalOpen(true)
          updateUnresolvedPriority(fullItem['key'], priority)
        }
        return (
          <div className='flex justify-center text-center items-center'>
            <IncidentPriorityDropDown
              disabled={fullItem.status === 'Resolved' ? true : false}
              label={text}
              onSelect={(value: string) => {
                if (text !== value) {
                  setModalOpen(true)
                  setChangeToPriority({
                    id: fullItem['id'],
                    fromPriority: text,
                    toPriority: value
                  })
                }
              }}
            />
          </div>
        )
      }
    },
    {
      columnHeaderStyle: { width: '20px' },
      columnDataStyle: { width: '20px', cursor: 'pointer' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div>
          <ChevronRight
            size={16}
            color={pallete.colors.FillButton}
            onClick={() => {
              //TODO navigate to view incident page
              gaEventTracker(gaEventConstants.VIEW_SINGLE_INCIDENT_RECORD)
              routeGroupedDetails(fullItem.id)
            }}
          />
        </div>
      )
    }
  ]

  const tabs = [
    {
      label:
        activeTab === '1' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            Unresolved Incidents
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            Unresolved Incidents
          </DDSTypography.Title>
        ),
      children: (
        <>
          {loading ? (
            <Spinner />
          ) : (
            <div className='flex flex-col py-[24px] gap-y-[24px]'>
              <div className='flex gap-[12px] w-full items-center justify-end'>
                <div className='flex w-full items-center custom-filter'>
                  <Filter
                    dropdownLabel='Filter'
                    options={options}
                    disabled={
                      (unresolvedTableData.length === 0 &&
                        !store.filterStore.getFiltersApplied()) ||
                      loading
                    }
                  />
                </div>
                <div>
                  <DDSRangePicker
                    name='Range Picker'
                    inputWidth='300px'
                    format='MM/dd/YYYY'
                    placeholder={['Start Date', 'End Date']}
                    placement='bottom-start'
                    maxDate={dayjs()}
                    value={[startDate, endDate]}
                    onChange={(dateObj: any, dateString: any) => {
                      let [startDate, endDate] = dateObj
                      if (startDate && endDate && startDate.isAfter(endDate)) {
                        ;[startDate, endDate] = [endDate, startDate]
                      }
                      handleDateRangeUnresolvedChange(startDate, endDate)
                    }}
                    disabled={
                      loading ||
                      store.uiStore.getGlobalLoader() ||
                      unresolvedTableData.length === 0
                    }
                  />
                </div>
              </div>

              {isArray(unresolvedTableData) &&
              unresolvedTableData.length !== 0 ? (
                <div ref={tableRef}>
                  <Table
                    data={unresolvedTableData}
                    columns={columns}
                    totalCount={unresolvedTotalCount}
                    rowsPerPage={10}
                    currentPage={unresolvedCurrentPage}
                    onPageChange={(page: any) => {
                      setUnresolvedCurrentPage(page)
                      const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                        location.search
                      )
                      setSearchParams({
                        ...allQueryParams,
                        page: page.toString(),
                        tab: '1'
                      })
                    }}
                    loading={loading}
                  />
                </div>
              ) : (
                <>
                  <NoRecord
                    style={{ height: '70vh' }}
                    imageSrc={
                      store.filterStore.getFiltersApplied()
                        ? NoFilterImage
                        : ReportAnIncident
                    }
                    text={
                      store.filterStore.getFiltersApplied()
                        ? StringConstants.NO_RECORDS_FILTER
                        : 'No Incident reported yet.'
                    }
                    button={
                      <>
                        {!store.filterStore.getFiltersApplied() && (
                          <Button
                            id='createEsc'
                            size='small'
                            type='filled'
                            label='Report an Incident'
                            disabled={store.userStore.getNoActiveSubscription()}
                            onClick={() => {
                              const route = replaceUrl(
                                routeConstants.REPORT_INCIDENT,
                                store.scopeStore.getScope()[
                                  'organization-id'
                                ] as string,
                                store.scopeStore.getScope()[
                                  'project-id'
                                ] as string,
                                store.scopeStore.getScope()[
                                  'environment-id'
                                ] as string
                              )
                              navigate(route)
                            }}
                          />
                        )}
                      </>
                    }
                  />
                </>
              )}
            </div>
          )}
        </>
      )
    },
    {
      label:
        activeTab === '2' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            Resolved Incidents
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            Resolved Incidents
          </DDSTypography.Title>
        ),
      children: (
        <>
          {loading ? (
            <Spinner />
          ) : (
            <div className='flex flex-col py-[24px] gap-y-[24px]'>
              <div className='flex gap-[12px] w-full items-center justify-end'>
                <div className='flex w-full items-center custom-filter'>
                  <Filter
                    dropdownLabel='Filter'
                    options={filteredOptions}
                    disabled={
                      (resolvedTotalCount === 0 &&
                        !store.filterStore.getFiltersApplied()) ||
                      loading
                    }
                  />
                </div>
                <div>
                  <DDSRangePicker
                    name='Range Picker'
                    inputWidth='300px'
                    placeholder={['Start Date', 'End Date']}
                    placement='bottom-start'
                    format='MM/dd/YYYY'
                    maxDate={dayjs()}
                    value={[startDate, endDate]}
                    onChange={(dateObj: any, dateString: any) => {
                      let [startDate, endDate] = dateObj
                      if (startDate && endDate && startDate.isAfter(endDate)) {
                        ;[startDate, endDate] = [endDate, startDate]
                      }

                      handleDateRangeResolvedChange(startDate, endDate)
                    }}
                    disabled={
                      loading ||
                      store.uiStore.getGlobalLoader() ||
                      resolvedTableData.length === 0
                    }
                  />
                </div>
              </div>

              {isArray(resolvedTableData) && resolvedTotalCount !== 0 ? (
                <div ref={tableRef}>
                  <Table
                    data={resolvedTableData}
                    columns={columns}
                    totalCount={resolvedTotalCount}
                    rowsPerPage={10}
                    currentPage={resolvedCurrentPage}
                    onPageChange={(page: any) => {
                      setResolvedCurrentPage(page)
                      const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                        location.search
                      )
                      setSearchParams({
                        ...allQueryParams,
                        page: page.toString(),
                        tab: '2'
                      })
                    }}
                    loading={loading}
                  />
                </div>
              ) : unresolvedTotalCount > 0 || unresolvedTotalCount === 0 ? (
                <NoRecord
                  style={{ height: '70vh' }}
                  imageSrc={
                    store.filterStore.getFiltersApplied()
                      ? NoFilterImage
                      : ReportAnIncident
                  }
                  text={
                    store.filterStore.getFiltersApplied()
                      ? StringConstants.NO_RECORDS_FILTER
                      : 'No Incident reported yet.'
                  }
                />
              ) : (
                <NoRecord
                  style={{ height: '70vh' }}
                  imageSrc={
                    store.filterStore.getFiltersApplied()
                      ? NoFilterImage
                      : ReportAnIncident
                  }
                  text={
                    store.filterStore.getFiltersApplied()
                      ? StringConstants.NO_RECORDS_FILTER
                      : 'No Incident reported yet.'
                  }
                  button={
                    <>
                      {!store.filterStore.getFiltersApplied() && (
                        <Button
                          id='createEsc'
                          size='small'
                          type='filled'
                          label='Report an Incident'
                          disabled={store.userStore.getNoActiveSubscription()}
                          onClick={() => {
                            const route = replaceUrl(
                              routeConstants.REPORT_INCIDENT,
                              store.scopeStore.getScope()[
                                'organization-id'
                              ] as string,
                              store.scopeStore.getScope()[
                                'project-id'
                              ] as string,
                              store.scopeStore.getScope()[
                                'environment-id'
                              ] as string
                            )
                            navigate(route)
                          }}
                        />
                      )}
                    </>
                  }
                />
              )}
            </div>
          )}
        </>
      )
    }
  ]

  const handleDateRangeUnresolvedChange = (startDate: any, endDate: any) => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const formattedEndDate = new Date(new Date(endDate).toISOString())
    formattedEndDate.setHours(23, 59, 59, 999)

    setDateRange([startDate, endDate])

    if (startDate && endDate) {
      if (
        new Date(startDate).toISOString() === new Date(endDate).toISOString()
      ) {
        setSearchParams({
          ...allQueryParams,
          'created-from': new Date(startDate).getTime(),
          'created-to': formattedEndDate.getTime(),
          page: '1'
        })
      } else {
        setSearchParams({
          ...allQueryParams,
          'created-from': new Date(startDate).getTime(),
          'created-to': formattedEndDate.getTime(),
          page: '1'
        })
      }
    } else {
      delete allQueryParams['created-from']
      delete allQueryParams['created-to']
      setSearchParams({
        ...allQueryParams,
        page: '1'
      })
    }
  }

  const handleDateRangeResolvedChange = (startDate: any, endDate: any) => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const formattedEndDate = new Date(new Date(endDate).toISOString())
    formattedEndDate.setHours(23, 59, 59, 999)

    setDateRange([startDate, endDate])

    if (startDate && endDate) {
      if (
        new Date(startDate).toISOString() === new Date(endDate).toISOString()
      ) {
        setSearchParams({
          ...allQueryParams,
          'created-from': new Date(startDate).getTime(),
          'created-to': formattedEndDate.getTime(),
          page: '1'
        })
      } else {
        setSearchParams({
          ...allQueryParams,
          'created-from': new Date(startDate).getTime(),
          'created-to': formattedEndDate.getTime(),
          page: '1'
        })
      }
    } else {
      delete allQueryParams['created-from']
      delete allQueryParams['created-to']
      setSearchParams({
        ...allQueryParams,
        page: '1'
      })
    }
  }

  const fetchAllUnresolvedIncidents = (queryParams: any, dateRange?: any) => {
    fetchProjectMembers()
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    getAllIncidents(queryParams)
      .then((response: any) => {
        setUnresolvedTableData(response.data)
        setUnresolvedTotalCount(response.totalCount)
        store.filterStore.setFilters(response.filters)
        store.filterStore.setFilterDisabled(false)
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.INCIDENT.GET_ALL_INCIDENTS.ERROR
        })
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }
  const fetchAllResolvedIncidents = (queryParams: any, dateRange?: any) => {
    fetchProjectMembers()
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    getAllIncidents(queryParams)
      .then((response: any) => {
        setResolvedTableData(response.data)
        setResolvedTotalCount(response.totalCount)
        store.filterStore.setFilters(response.filters)
        setLoading(false)
        store.filterStore.setFilterDisabled(false)
        store.uiStore.setGlobalLoader(false)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.INCIDENT.GET_ALL_INCIDENTS.ERROR
        })
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }
  const fetchProjectMembers = () => {
    const projectId = store.scopeStore.getSelectedProject().id
    getAllProjectMembers(projectId as string)
      .then((response: any) => {
        const allMembers = response.data.map((member: any) => {
          return {
            id: member.id,
            name: member.user.firstName + ' ' + member.user.lastName,
            imgUrl: member.user.avatar,
            role: member.role
          }
        })
        setAssignMemberOptions(allMembers)
      })
      .catch(() => {
        ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.PROJECT_MEMBER.FIND_ALL_PROJECT_MEMBERS.ERROR
        })
      })
  }

  const handleAssignMembers = (incidentId: string, selectedUser: any) => {
    const selectedUserId = selectedUser.map((item: any, index: any) => {
      return item.id
    })
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)

    setLoading(true)
    store.uiStore.setGlobalLoader(true)

    updateAnIncident({
      $incidentId: incidentId,
      assignee: selectedUserId
    })
      .then((response: any) => {
        if (activeTab === '1') {
          let payload
          if (allQueryParams['status']) {
            payload = {
              ...allQueryParams
            }
          } else {
            payload = {
              ...allQueryParams,
              status: 'Unresolved'
            }
          }

          fetchAllUnresolvedIncidents({
            ...payload,
            size: StringConstants.DEFAULT_TABLE_SIZE
          })
        } else if (activeTab === '2') {
          fetchAllResolvedIncidents({
            ...allQueryParams,
            status: 'Resolved',
            size: StringConstants.DEFAULT_TABLE_SIZE
          })
        }
        fetchProjectMembers()
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.INCIDENT.UPDATE_AN_INCIDENT.SUCCESS
        })
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.INCIDENT.UPDATE_AN_INCIDENT.ERROR
        })
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const updateIncident = () => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    setLoading(true)
    updateAnIncident({
      $incidentId: changeToPriority.id,
      priority: changeToPriority.toPriority
    })
      .then((incident: any) => {
        setUnresolvedCurrentPage(1)
        setResolvedCurrentPage(1)
        setSearchParams({ ...allQueryParams, page: '1' })
        const oldIncidentIndex = unresolvedTableData.findIndex(
          (item: any) => item.id === incident.data.id
        )
        const updatedIncidents = [...unresolvedTableData]
        updatedIncidents[oldIncidentIndex] = { ...incident.data }
        setUnresolvedTableData(updatedIncidents)

        setModalOpen(false)
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.INCIDENT.UPDATE_AN_INCIDENT.SUCCESS
        })

        if (routeConstants.INCIDENT) {
          if (activeTab === '1') {
            let payload
            if (allQueryParams['status']) {
              payload = {
                ...allQueryParams
              }
            } else {
              payload = {
                ...allQueryParams,
                status: 'Unresolved'
              }
            }

            fetchAllUnresolvedIncidents({
              ...payload,
              page: '1',
              size: StringConstants.DEFAULT_TABLE_SIZE
            })
          } else if (activeTab === '2') {
            fetchAllResolvedIncidents({
              ...allQueryParams,
              status: 'Resolved',
              page: '1',
              size: StringConstants.DEFAULT_TABLE_SIZE
            })
          }
        }
      })
      .catch((error: any) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.INCIDENT.UPDATE_AN_INCIDENT.ERROR
        })
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const getPriorityColor = (value: string) => {
    return value === 'P0'
      ? '!bg-warning1'
      : value === 'P1'
        ? '!bg-warning2'
        : value === 'P2'
          ? '!bg-warning3'
          : value === 'P3'
            ? '!bg-warning4'
            : ''
  }

  const fetchIncidentFilters = () => {
    getIncidentFilters()
      .then((response: any) => {
        const statusFilters = response.data[1]
        statusFilters.values = statusFilters.values.filter(
          (value: any) =>
            value.displayText !== IncidentStatusTypes.UNRESOLVED &&
            value.displayText !== IncidentStatusTypes.RESOLVED
        )
        response.data[1] = statusFilters

        setOptions(response.data)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.INCIDENT.INCIDENT_FILTERS.ERROR
        })
      })
  }

  useEffect(() => {
    store.breadcrumbStore.setBreadcrumbsOptions({
      label: 'Incidents',
      link: replaceUrl(
        routeConstants.INCIDENT,
        store.scopeStore.getScope()['organization-id'] as string,
        store.scopeStore.getScope()['project-id'] as string,
        store.scopeStore.getScope()['environment-id'] as string
      )
    })
  }, [store.breadcrumbStore.getBreadcrumbsOptions()])

  useEffect(() => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const pageFromQueryParams = searchParams.get('page')
    if (!isNull(pageFromQueryParams)) {
      setUnresolvedCurrentPage(Number(pageFromQueryParams))
    } else {
      delete allQueryParams['subTab']
      setSearchParams({ ...allQueryParams, page: '1', tab: activeTab })
      setUnresolvedCurrentPage(1)
    }
    const tabFromParams = searchParams.get('tab')

    if (tabFromParams) {
      setActiveTab(tabFromParams.toString())
    } else {
      setSearchParams({ ...allQueryParams, page: '1', tab: activeTab })
    }
    if (
      !isEmpty(store.scopeStore.getScope()['environment-id']) &&
      !isEmpty(store.scopeStore.getScope()['project-id'])
    ) {
      if (activeTab === '1') {
        fetchIncidentFilters()
        let payload
        if (allQueryParams['status']) {
          payload = {
            ...allQueryParams
          }
        } else {
          payload = {
            ...allQueryParams,
            status: 'Unresolved'
          }
        }

        fetchAllUnresolvedIncidents({
          ...payload,
          tab: '1',
          page: pageFromQueryParams ?? '1',
          size: StringConstants.DEFAULT_TABLE_SIZE
        })
      } else if (activeTab === '2') {
        fetchIncidentFilters()
        fetchAllResolvedIncidents({
          ...allQueryParams,
          tab: '2',
          page: pageFromQueryParams ?? '1',
          status: 'Resolved',
          size: StringConstants.DEFAULT_TABLE_SIZE
        })
      }
    }
  }, [store.scopeStore.getScope(), searchParams])

  return (
    <>
      <DocumentHeader header={HeaderConstants.INCIDENT} />
      <div className='flex flex-col !w-full'>
        <div className='flex flex-row w-full'>
          <div
            style={{ width: '100%' }}
            className={`${loading && 'cursor-not-allowed'}`}
          >
            {unresolvedTableData.length === 0 &&
            resolvedTableData.length === 0 &&
            !store.filterStore.getFiltersApplied() ? (
              <Tabs
                tabOptionsStyle={{
                  minWidth: '150px',
                  width: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '8px 12px',
                  marginRight: '8px'
                }}
                disabled={loading}
                tabOptions={tabs}
                id='tabs-grouped-api'
                activeTab={activeTab}
                onChange={(tabIndex: string) => {
                  setLoading(true)
                  setActiveTab(tabIndex.toString())
                  store.filterStore.setFiltersApplied(false)
                  setDateRange([null, null])
                  setSearchParams({
                    ...searchParams,
                    page: '1',
                    tab: tabIndex.toString()
                  })
                }}
              />
            ) : (
              <Tabs
                tabOptionsStyle={{
                  minWidth: '150px',
                  width: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '8px 12px',
                  marginRight: '8px'
                }}
                disabled={loading}
                tabOptions={tabs}
                id='tabs-grouped-api'
                showActionButton
                actionButtonLabel='Report an Incident'
                onActionButtonPress={() => {
                  const route = replaceUrl(
                    routeConstants.REPORT_INCIDENT,
                    store.scopeStore.getScope()['organization-id'] as string,
                    store.scopeStore.getScope()['project-id'] as string,
                    store.scopeStore.getScope()['environment-id'] as string
                  )
                  navigate(route)
                }}
                activeTab={activeTab}
                onChange={(tabIndex: string) => {
                  setLoading(true)
                  store.filterStore.setFiltersApplied(false)
                  setActiveTab(tabIndex.toString())
                  setDateRange([null, null])
                  setSearchParams({
                    ...searchParams,
                    page: '1',
                    tab: tabIndex.toString()
                  })
                }}
              />
            )}
          </div>
        </div>
      </div>

      <PriorityChangePopup
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title='Priority Change'
        primaryButtonClick={() => updateIncident()}
        secondaryButtonClick={() => {
          setModalOpen(false)
        }}
        loading={loading}
        primaryButtonText='Yes'
        secondaryButtonText='No'
        primaryButtonDisabled={loading}
        secondaryButtonDisabled={loading}
        content={
          <div className=' flex flex-col gap-[24px] justify-center items-center'>
            <DDSTypography.Title
              variant='semiBold'
              type='h4'
              color={pallete.colors.textDark3}
            >
              Are you sure to change the priority from
            </DDSTypography.Title>
            <div className='flex flex-row justify-center items-center gap-x-[8px] override-chip-tailwind-provider'>
              <DDSChips
                type='soft'
                label={
                  <DDSTypography.Title
                    variant='semiBold'
                    type='h4'
                    color={pallete.colors.white}
                  >
                    {changeToPriority.fromPriority}
                  </DDSTypography.Title>
                }
                className={`flex items-center justify-center  !w-[80px] h-[80px] min-w-[20px] !text-white ${getPriorityColor(
                  changeToPriority.fromPriority
                )}`}
              />
              <ChevronRight size={24} />
              <DDSChips
                type='soft'
                label={
                  <DDSTypography.Title
                    variant='semiBold'
                    type='h4'
                    color={pallete.colors.white}
                  >
                    {changeToPriority.toPriority}
                  </DDSTypography.Title>
                }
                className={`flex items-center justify-center  !w-[80px] h-[80px] min-w-[20px] !text-white ${getPriorityColor(
                  changeToPriority.toPriority
                )} `}
              />
            </div>
          </div>
        }
      />
    </>
  )
}

export default SecureComponent(
  observer(Incident),
  AccessRoleConstants.MEMBER_LEVEL
)
