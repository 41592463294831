import { CategoryScale, ChartArea, ChartData } from 'chart.js'
import React, { useEffect, useRef, useState } from 'react'

import { Chart } from 'react-chartjs-2'
import ChartJS from 'chart.js/auto'
import annotationPlugin from 'chartjs-plugin-annotation'
import palette from '../../../global/pallete'

ChartJS.register(CategoryScale)
ChartJS.register(annotationPlugin)

interface Data {
  x: string
  y: number
}
interface PerformanceGraphProps {
  uptimeData?: Data[]
  responseTimeData?: Data[]
  downtimeData?: Data[]
}
const PerformanceGraph: React.FC<PerformanceGraphProps> = ({
  responseTimeData = [],
  uptimeData = [],
  downtimeData = [],
}) => {
  const createGradient = (ctx: CanvasRenderingContext2D, area: ChartArea) => {
    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top)
    gradient.addColorStop(0, palette.colors.white)
    gradient.addColorStop(1, palette.colors.lightBack6)
    return gradient
  }
  const chartRef = useRef<ChartJS>(null)
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    datasets: [],
  })

  useEffect(() => {
    const chart = chartRef.current
    if (!chart) {
      return
    }
    const chartData = {
      labels: responseTimeData.map((item) => item.x),
      datasets: [
        {
          label: 'Downtime',
          data: downtimeData.map((item) => item.y),
          tension: 0.5,
          borderColor: palette.colors.error,
          pointRadius: 0,
          borderWidth: 2,
          yAxisID: 'y1',
        },
        {
          label: 'Uptime',
          data: uptimeData.map((item) => item.y),
          tension: 0.5,
          borderColor: palette.colors.lightBack5,
          pointRadius: 0,
          borderWidth: 2,
          borderDash: [9],
          yAxisID: 'y1',
        },
        {
          label: 'Response Time',
          data: responseTimeData.map((item) => item.y),
          tension: 0.5,
          borderColor: palette.colors.lightBack5,
          pointBackgroundColor: palette.colors.white,
          pointRadius: 4,
          borderWidth: 2,
          fill: 'origin',
          backgroundColor: createGradient(chart.ctx, chart.chartArea),
        },
      ],
    }

    setChartData(chartData)
  }, [])

  const graphOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          position: 'bottom',
          align: 'end',
          text: 'Date in UTC',
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Average Response Time (ms)',
        },
        beginAtZero: true,
        ticks: {
          display: true,
          autoSkip: true,
          maxTicksLimit: 5,
        },
        grid: {
          display: true,
          drawBorder: false,
          color: palette.colors.borderColor,
        },
      },
      y1: {
        title: {
          display: true,
          text: 'Uptime and Downtime (%)',
        },
        beginAtZero: true,
        ticks: {
          display: true,
          autoSkip: true,
          maxTicksLimit: 5,
        },
        grid: {
          display: true,
          drawBorder: false,
          color: palette.colors.borderColor,
        },
        position: 'right',
      },
    },

    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
      },
    },
  }
  return (
    <div style={{ height: '100%', width: '100%', marginLeft: '-20px' }}>
      <Chart
        type='line'
        data={chartData}
        options={graphOptions}
        ref={chartRef}
      />
    </div>
  )
}

export default PerformanceGraph
