import * as UserTypes from './User.types'

import ApiManager from '../Api.manager'
import HttpConstants from '../../constants/HttpConstants'
import UrlConstants from '../../constants/UrlConstants'

export const signupUser = async (payload: UserTypes.SignupPayload) => {
  const usecase = UrlConstants.REGISTER_USER.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCall(usecase, HttpConstants.POST_METHOD, payload)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const deleteUser = async () => {
  const usecase = UrlConstants.DELETE_USER.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.DELETE_METHOD,
      {}
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}

export const updateUser = async (payload: UserTypes.UpdatePayload) => {
  const usecase = UrlConstants.UPDATE_USER.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const verifyEmail = async (payload: { $token: string }) => {
  const usecase = UrlConstants.VERIFY_EMAIL.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const changePassword = async (payload: {
  oldPassword: string
  newPassword: string
}) => {
  const usecase = UrlConstants.CHANGE_PASSWORD.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}

export const resendVerification = async () => {
  const usecase = UrlConstants.RESEND_VERIFICATION.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      {}
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const getUserDetails = (payload: void) => {
  const usecase = UrlConstants.GET_USER_DETAILS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response.data
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        if (error.status === 400) {
          reject(error.data.error.message)
        } else if (error.status === 401 || error.status === 403) {
          reject(error.data.message)
        } else {
          reject(error.data)
        }
      })
  })
}

export const getUserPreference = (payload: void) => {
  const usecase = UrlConstants.GET_USER_PREFERENCE.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}

export const updateUserPreference = (
  payload: UserTypes.UpdatePreferencePayload
) => {
  const usecase = UrlConstants.UPDATE_USER_PREFERENCE.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}

/**
 *
 * @param
 * @returns return a string which is the link to the image.
 */
export const uploadUserAvatar = (payload: any) => {
  const usecase = UrlConstants.UPLOAD_USER_AVATAR.USECASE

  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        if (error.status === 0) reject('')
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const findUserByMail = ($email: string) => {
  const usecase = UrlConstants.GET_USER_BY_EMAIL.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCall(usecase, HttpConstants.GET_METHOD, { $email })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else throw response
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.error)
      })
  })
}

export const onBoardUserViaSalesforceSSO = (
  code: string,
  isLogin: boolean,
  token?: string
) => {
  const usecase = UrlConstants.ON_BOARD_USER_VIA_SSO.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCall(usecase, HttpConstants.POST_METHOD, {
      code,
      isLogin,
      token
    })
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}
