import React from 'react'
import themePalette from '../../global/pallete'

export const containerStyles = {
  width: '700px',
  height: '500px',
  background: themePalette.colors.white,
  borderRadius: '20px',
  position: 'absolute',
  left: '25%',
  alignSelf: 'center',
} as React.CSSProperties

export const topWrapperStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px 20px',
  position: 'relative',
} as React.CSSProperties

export const topTextStyles = { color: themePalette.colors.textDark }

export const topIconStyles = {
  color: themePalette.colors.textDark2,
}

export const contentWrapperStyles = {
  position: 'absolute',
  top: '40%',
  left: '15%',
  textAlign: 'center',
} as React.CSSProperties

export const contentSpanStyle = {
  color: themePalette.colors.primary,
  fontWeight: themePalette.fontWeight.semiBold,
}

export const contentTextStyle = { color: themePalette.colors.textDark2 }

export const buttonWrapperStyles = { marginTop: '20px' }
