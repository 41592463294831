import { DDSButton, DDSModal, DDSTypography } from 'den-design-system'

import { Button } from '../../../../components/New/Button/Button'
import React from 'react'
import { SetStateAction } from 'react'
import pallete from '../../../../global/newPallete'

interface ModalFooterProps {
  primaryButtonText?: string
  secondaryButtonText?: string
  onYes?: () => any
  onNo?: () => any
  primaryButtonDisabled?: boolean
  secondaryButtonDisabled?: boolean
  loading?: boolean
}
const ModalFooter: React.FC<ModalFooterProps> = ({
  onYes,
  onNo,
  primaryButtonText = '',
  secondaryButtonText = '',
  primaryButtonDisabled,
  secondaryButtonDisabled,
  loading
}) => {
  return (
    <div className='flex flex-row gap-x-[8px] px-[24px] py-[16px] override-btn-min-width justify-end'>
      {secondaryButtonText !== '' && (
        <Button
          id='secondary-btn'
          label={
            <DDSTypography.Paragraph
              variant='semiBold'
              size='para'
              className='text-center whitespace-nowrap flex justify-center !min-w-0'
              color={pallete.colors.textDark10}
            >
              {secondaryButtonText}
            </DDSTypography.Paragraph>
          }
          type='neutral'
          size='small'
          disabled={loading}
          onClick={() => (onNo ? onNo() : undefined)}
        />
      )}
      {primaryButtonText !== '' && (
        <div className='override-btn-min-width'>
          <Button
            id='primary-button'
            size='small'
            disabled={loading}
            loading={loading}
            type='filled'
            label={primaryButtonText}
            onClick={() => (onYes ? onYes() : undefined)}
          />
        </div>
      )}
    </div>
  )
}

interface CodeSandBoxPopupProp {
  modalOpen: boolean
  setModalOpen: React.Dispatch<SetStateAction<boolean>>

  content: React.ReactNode
  showFooter?: boolean
  primaryButtonClick?: any
  secondaryButtonClick?: any
  primaryButtonText?: string
  secondaryButtonText?: string
  primaryButtonDisabled?: boolean
  secondaryButtonDisabled?: boolean
  loading?: boolean
  title?: string
  showHeader?: boolean
}
export const CodeSandBoxPopup: React.FC<CodeSandBoxPopupProp> = ({
  modalOpen,
  setModalOpen,
  content,
  primaryButtonClick,
  secondaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
  primaryButtonDisabled,
  secondaryButtonDisabled,
  showFooter = false,
  showHeader,
  title = '',
  loading
}) => {
  return (
    <DDSModal
      open={modalOpen}
      setOpen={setModalOpen}
      defaultFooter={false}
      onIconClose={() => setModalOpen(false)}
      hideCloseIcon
      style={{ width: '118vh' }}
    >
      <div>
        {showHeader && (
          <div
            className='flex justify-between items-center px-[24px] py-[16px] border-b'
            style={{ borderBottomColor: pallete.colors.stroke2 }}
          >
            <DDSTypography.Title
              variant='bold'
              type='h4'
              className='!leading-6'
              color={pallete.colors.textDark4}
            >
              {title}
            </DDSTypography.Title>
          </div>
        )}
        <div
          className='flex flex-col border-b'
          style={{ borderBottomColor: pallete.colors.stroke2 }}
        >
          {content}
        </div>

        {showFooter && (
          <ModalFooter
            onYes={primaryButtonClick}
            onNo={secondaryButtonClick}
            loading={loading}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={secondaryButtonText}
            primaryButtonDisabled={primaryButtonDisabled}
            secondaryButtonDisabled={secondaryButtonDisabled}
          />
        )}
      </div>
    </DDSModal>
  )
}
