export enum ProjectType {
  SALESFORCE_ONLY = 'SALESFORCE_ONLY',
  FULL_STACK_ONLY = 'FULL_STACK_ONLY',
  MULESOFT_ONLY = 'MULESOFT_ONLY'
}

export enum ProjectTypeDisplayNames {
  SALESFORCE_ONLY = 'Only Salesforce',
  FULL_STACK_ONLY = 'Only Full-stack app',
  MULESOFT_ONLY = 'Only Mulesoft app'
}
