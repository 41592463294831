import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material'

import React from 'react'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

export const LinkTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    placement='top'
    classes={{ popper: className }}
    enterTouchDelay={0}
    leaveTouchDelay={5000}
  />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: palette.colors.borderColor,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: palette.colors.white,
    border: `1.5px solid ${palette.colors.borderColor}`,
    boxShadow: `0px 4px 15px rgba(0, 0, 0, 0.25)`,
  },
}))
