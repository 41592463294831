import { DDSTooltip, DDSTypography } from 'den-design-system'

import React from 'react'
import Tooltip from '../New/Tooltip/Tooltip'
import pallete from '../../global/newPallete'

interface APIOverviewCardData {
  id: string
  heading: string
  content: string | React.ReactNode
}

const APIOverviewCard: React.FC<APIOverviewCardData> = ({
  id,
  heading,
  content
}) => {
  return (
    <div
      id={id}
      className='relative flex flex-col rounded-md border  px-[20px] py-[16px]'
      style={{ borderColor: pallete.colors.stroke2 }}
    >
      <DDSTypography.Paragraph
        size='para'
        variant='regular'
        color={pallete.colors.textDark5}
      >
        {heading}
      </DDSTypography.Paragraph>
      <div className='pt-[8px] '>
        <DDSTooltip
          id={`${content}`}
          position='top'
          shape='roundedRectangle'
          className={`override-tooltip-arrow ${
            typeof content === 'string' && content.length > 30
              ? content
              : '!hidden'
          }`}
          label={
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark4}
            >
              {content}
            </DDSTypography.Paragraph>
          }
          style={{ backgroundColor: pallete.colors.surface1, zIndex: 999999 }}
        >
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.textDark3}
            className='truncate w-[96%]'
          >
            {content}
          </DDSTypography.Title>
        </DDSTooltip>
      </div>
    </div>
  )
}

export default APIOverviewCard
