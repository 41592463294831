import { DDSChips, DDSTooltip, DDSTypography } from 'den-design-system'
import React, { useEffect, useState } from 'react'
import { msToTimeUnits, timeZone } from '../../utils/DateTimeUtils'

import APIOverviewCard from './APIOverviewCard'
import APIOverviewTable from './APIOverviewTable'
import ApiGroupingStringConstants from '../../constants/ApiGroupingStringConstants'
import Spinner from '../common/Spinner/Spinner'
import { isUndefined } from 'lodash'
import { mapStatusCodeToColor } from '../../utils/ApiStatusColorCode'
import { observer } from 'mobx-react-lite'
import pallete from '../../global/newPallete'
import { replaceUrl } from '../../utils/UrlUtils'
import routeConstants from '../../constants/RouteConstants'
import { timeFormats } from '../../enum/TIME'
import { useSearchParams } from 'react-router-dom'
import { useStoreContext } from '../../store/StoreContext'

const ViewOverview: React.FC = () => {
  const store = useStoreContext()

  const [loading, setLoading] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const apiDetails = store.apiDetailsStore.getApiData()

  useEffect(() => {
    setLoading(store.apiDetailsStore.getAPILoading())
  }, [store.apiDetailsStore.apiLoading])

  useEffect(() => {
    const tabFromParams = searchParams.get('tab')

    if (!isUndefined(store.apiDetailsStore.getApiData().urlTemplate)) {
      store.breadcrumbStore.setMultipleBreadcrumbsOptions([
        {
          label:
            tabFromParams === '1'
              ? ApiGroupingStringConstants.GROUPED_API
              : ApiGroupingStringConstants.ALL_API,
          link:
            replaceUrl(
              routeConstants.API_MONITORING,
              store.scopeStore.getScope()['organization-id'] as string,
              store.scopeStore.getScope()['project-id'] as string,
              store.scopeStore.getScope()['environment-id'] as string
            ) + `${tabFromParams === '1' ? '?tab=1' : '?tab=2'}`
        },
        {
          label: store.apiDetailsStore.getApiData()?.urlTemplate as string,
          link: routeConstants.VIEW_GROUPED_API.replace(
            ':id',
            apiDetails.apiGroupId
          )
        },
        {
          label: ApiGroupingStringConstants.OVER_VIEW,
          link: '/'
        }
      ])
    }
    // if (store.breadcrumbStore.getBreadcrumbsOptions().length === 2) {
    //   store.breadcrumbStore.appendBreadCrumbOptions({
    //     label: ApiGroupingStringConstants.OVER_VIEW,
    //     link: '/'
    //   })
    // }
    // return () => {
    //   store.breadcrumbStore.sliceBreadcrumbOptions(0, 2)
    // }
  }, [store.apiDetailsStore.getApiData()])

  const apiData = [
    {
      id: '1',
      heading: ApiGroupingStringConstants.RESPONSE_TIME,
      content: apiDetails.responseTimeInMilliseconds
        ? `${msToTimeUnits(apiDetails.responseTimeInMilliseconds)}`
        : '-'
    },
    {
      id: '2',
      heading: ApiGroupingStringConstants.HTTP_METHOD,
      content: apiDetails.httpMethod ? apiDetails.httpMethod : '-'
    },
    {
      id: '3',
      heading: ApiGroupingStringConstants.START_TIME,
      content: apiDetails.startTime
        ? timeZone(
            store.userStore.getUserPreference().timeZone,
            apiDetails.startTime,
            timeFormats.fullMonthDateYearTimeWithSeconds
          )
        : '-'
    },

    {
      id: '4',
      heading: ApiGroupingStringConstants.END_TIME,
      content: apiDetails.endTime
        ? timeZone(
            store.userStore.getUserPreference().timeZone,
            apiDetails.endTime,
            timeFormats.fullMonthDateYearTimeWithSeconds
          )
        : '-'
    },
    {
      id: '5',
      heading: ApiGroupingStringConstants.STATUS_MESSAGE,
      content: apiDetails.responseStatusMessage
        ? apiDetails.responseStatusMessage
        : '-'
    },
    {
      id: '6',
      heading: ApiGroupingStringConstants.USER_AGENT,
      content: apiDetails.userAgent ? apiDetails.userAgent : '-'
    },
    {
      id: '7',
      heading: ApiGroupingStringConstants.IP,
      content: apiDetails.ip ? apiDetails.ip : '-'
    },
    {
      id: '8',
      heading: ApiGroupingStringConstants.HTTP_VERSION,
      content: apiDetails.httpVersion ? apiDetails.httpVersion : '-'
    }
  ]

  const pathDetails = [
    {
      id: '1',
      heading: ApiGroupingStringConstants.ORIGINAL_URL,
      content: apiDetails.originalUrl
    },
    {
      id: '2',
      heading: ApiGroupingStringConstants.BASE_URL,
      content: apiDetails.baseUrl
    },
    {
      id: '3',
      heading: ApiGroupingStringConstants.PATH,
      content: apiDetails.path
    }
  ]

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div className='flex h-full w-full'>
          <div className='py-[24px] w-full'>
            <div className='pb-[16px]'>
              <div className='flex justify-between pb-[12px]'>
                <div>
                  <DDSTooltip
                    id='tooltip-overview'
                    position='top'
                    shape='roundedRectangle'
                    className={`override-tooltip-arrow ${
                      typeof apiDetails.url === 'string' &&
                      apiDetails.url.length > 30
                        ? apiDetails.url
                        : '!hidden'
                    }`}
                    style={{
                      backgroundColor: pallete.colors.surface1,
                      zIndex: 999999
                    }}
                    label={
                      <DDSTypography.Paragraph
                        size='para'
                        variant='regular'
                        color={pallete.colors.textDark4}
                      >
                        {apiDetails.url}
                      </DDSTypography.Paragraph>
                    }
                  >
                    <DDSTypography.Title
                      type='h3'
                      variant='semiBold'
                      className='truncate w-[600px] overflow-hidden '
                      style={{
                        display: 'inline-block'
                      }}
                      color={pallete.colors.textDark3}
                    >
                      {apiDetails.url || apiDetails.url === 0
                        ? apiDetails.url
                        : '-'}
                    </DDSTypography.Title>
                  </DDSTooltip>
                </div>

                <div className='flex justify-end items-center'>
                  <div className='pr-[8px] flex'>
                    <DDSTypography.Title
                      type='h5'
                      variant='semiBold'
                      color={pallete.colors.textDark3}
                    >
                      {ApiGroupingStringConstants.RESPONSE_STATUS}:
                    </DDSTypography.Title>
                  </div>
                  <div className='override-statusCode-center override-status-chip'>
                    <DDSChips
                      label={apiDetails.responseStatusCode}
                      type='filled'
                      shape='rounded'
                      size='small'
                      style={{
                        backgroundColor: mapStatusCodeToColor(
                          apiDetails.responseStatusCode
                        ),
                        borderColor: 'transparent',
                        height: '24px',
                        width: '60px',
                        justifyContent: 'center'
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='flex justify-start items-start'>
                <div className='flex justify-between items-center'>
                  <DDSTypography.Paragraph
                    size='caption'
                    variant='medium'
                    color={pallete.colors.textDark6}
                    className='w-[100%] whitespace-nowrap'
                  >
                    {ApiGroupingStringConstants.CLIENT_VERSION}:
                  </DDSTypography.Paragraph>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='semiBold'
                    color={pallete.colors.textDark3}
                    className='ml-[4px] whitespace-nowrap'
                  >
                    {apiDetails.clientVersion || apiDetails.clientVersion === 0
                      ? apiDetails.clientVersion
                      : '-'}
                  </DDSTypography.Paragraph>
                </div>
                <div className='mx-[12px] border-r border-gray-300 h-[20px] '></div>{' '}
                {/* Vertical line */}
                <div className='flex justify-between items-center'>
                  <DDSTypography.Paragraph
                    size='caption'
                    variant='medium'
                    color={pallete.colors.textDark6}
                    className='w-[100%] whitespace-nowrap'
                  >
                    {ApiGroupingStringConstants.URL_TEMPLATE}:
                  </DDSTypography.Paragraph>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='semiBold'
                    color={pallete.colors.textDark3}
                    className='ml-[4px] whitespace-nowrap'
                  >
                    {apiDetails.urlTemplate || apiDetails.urlTemplate === 0
                      ? apiDetails.urlTemplate
                      : '-'}
                  </DDSTypography.Paragraph>
                </div>
              </div>
            </div>
            <div className=''>
              <hr style={{ color: pallete.colors.stroke2 }} />
            </div>
            <div className='pt-[32px]'>
              <div className='grid grid-cols-4 gap-[16px]'>
                {apiData.map((item) => (
                  <div key={item.id}>
                    <APIOverviewCard
                      id={item.id}
                      heading={item.heading}
                      content={item.content}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div className='pt-[32px]'>
                <APIOverviewTable tableData={pathDetails} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default observer(ViewOverview)
