import { ChevronRight, Edit } from '@carbon/icons-react'
import React, { useEffect, useState } from 'react'
import {
  checkNameAvailabilityFromServer,
  getNoDataMessageForStatus,
  schema,
  statusToColor,
  toTitleCase
} from '../../../utils/JobUtils'
import {
  getJobFilters,
  updateConfiguredJob
} from '../../../api/jobs/Jobs.service'
import { isEmpty, isNull } from 'lodash'
import { useNavigate, useSearchParams } from 'react-router-dom'

import AddEditJobPopUp from './AddEditPopUp'
import CopyButton from './CopyButton'
import { DDSTypography } from 'den-design-system'
import Filter from '../../Filter/Filter'
import HttpConstants from '../../../constants/HttpConstants'
import JMStringConstants from '../../../constants/JMStringConstants'
import { Link } from '@mui/material'
import NoData from '../../NoData/NoData'
import NoFilterImage from '../../../assets/images/folder-not-found 1.svg'
import NoRecord from '../../common/NoContent/NoRecord'
import NoRecordImage from '../../../assets/images/setting.svg'
import { ProjectType } from '../../../enum/ProjectType.enum'
import Spinner from '../../common/Spinner/Spinner'
import StringConstants from '../../../constants/StringConstants'
import Switch from '../../New/Switch/Switch'
import Table from '../../common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import Tooltip from '../../New/Tooltip/Tooltip'
import { addQueryParamsToUrl } from '../../../utils/UrlGenerator'
import { getAllQueryParamsAsObjectFromUrl } from '../../../utils/UrlUtils'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import { timeFormats } from '../../../enum/TIME'
import { timeZone } from '../../../utils/DateTimeUtils'
import { useStoreContext } from '../../../store/StoreContext'

interface jobsProps {
  jobs: any
  totalCount: number
  recordsPresent: boolean
  status?: string
  isLoading: boolean
  findAllJobs: (page: number | undefined, queryFilters: any) => void
}
const JMJobsPage: React.FC<jobsProps> = ({
  jobs,
  totalCount,
  recordsPresent,
  status,
  findAllJobs,
  isLoading
}) => {
  const [queryParams, setQueryParams] = useSearchParams()
  const initialPage = parseInt(
    queryParams.get('page') ? (queryParams.get('page') as string) : '1'
  )
  const [totalDataCount, setTotalDataCount] = useState<number>(totalCount)

  const [currentPage, setCurrentPage] = useState<number>(initialPage)
  const store = useStoreContext()
  const [editPopupOpen, setEditPopupOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(isLoading)
  const navigate = useNavigate()
  const [filterOptions, setFilterOptions] = useState([])
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [originalJob, setOriginalJob] = useState<any>({})
  const [selectedJob, setSelectedJob] = useState<any>({})
  const [isInvalidJobName, setIsInvalidJobName] = useState(false)
  const [isInvalidJobSlug, setIsInvalidJobSlug] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)

  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY

  useEffect(() => {
    if (
      selectedJob &&
      selectedJob.name &&
      selectedJob.slug &&
      selectedJob.cronExpression
    ) {
      const validated = schema.validate(
        {
          name: selectedJob.name.trim(),
          slug: selectedJob.slug.trim(),
          cronExpression: selectedJob.cronExpression,
          description: selectedJob.description
        },
        {
          abortEarly: false
        }
      )

      const areJobsEqual =
        originalJob &&
        selectedJob &&
        originalJob.name === selectedJob.name &&
        originalJob.slug === selectedJob.slug &&
        originalJob.cronExpression === selectedJob.cronExpression &&
        originalJob.description === selectedJob.description &&
        originalJob.monitorJob === selectedJob.monitorJob

      if (validated.error || areJobsEqual) {
        setIsButtonDisabled(true)
      } else {
        setIsButtonDisabled(false)
      }
    } else {
      setIsButtonDisabled(true)
    }
  }, [selectedJob, originalJob, schema])

  useEffect(() => {
    setLoading(isLoading)
    setTotalDataCount(totalCount)
  }, [totalCount, isLoading])

  useEffect(() => {
    if (store.filterStore.getFilterDisabled()) {
      store.filterStore.setFilterDisabled(false)
    }
  }, [store.filterStore.getFilterDisabled()])

  useEffect(() => {
    if (store.scopeStore.getScope()['environment-id']) {
      getJobFilters()
        .then((response: any) => {
          store.jobStore.setCallJobLogFilter(false)
          setFilterOptions(response.data)
        })
        .catch((err) => {
          store.jobStore.setCallJobLogFilter(false)
          return ToastNotification({
            type: 'error',
            message: ToastMessageConstants.JOB_MONITORING.JOB_FILTERS.ERROR
          })
        })
      const tab = queryParams.get('tab')
      setQueryParams({ page: '1', tab: tab as string })
    }
  }, [store.scopeStore.getScope()['environment-id']])

  useEffect(() => {
    const pageFromQueryParams = queryParams.get('page')
    if (!isNull(pageFromQueryParams)) {
      setCurrentPage(Number(pageFromQueryParams))
    } else {
      const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
      setQueryParams({ ...allQueryParams, page: '1' })
      setCurrentPage(1)
    }
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (status) {
      findAllJobs(currentPage, {
        ...allQueryParams,
        ['previous-run-status']: status
      })
    } else {
      findAllJobs(currentPage, allQueryParams)
    }
  }, [store.scopeStore.getScope(), queryParams])

  useEffect(() => {
    if (!initialLoad) {
      if (selectedJob && selectedJob.name !== undefined) {
        if (selectedJob.name === '') {
          setSelectedJob((prevState: any) => ({ ...prevState, slug: '' }))
        } else {
          setSelectedJob((prevState: any) => ({
            ...prevState,
            slug: prevState.name
              .toLowerCase()
              .replace(/-/g, '')
              .replace(/_/g, '-')
              .replace(/[^a-z0-9\s-]/g, '')
              .replace(/\s+/g, '-')
          }))
        }
      }
    } else {
      setInitialLoad(false)
    }
  }, [selectedJob.name])

  const handleMonitorToggle = (value: boolean, id: string) => {
    const payload = {
      monitorJob: value,
      $jobId: id as string
    }
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    updateConfiguredJob(payload)
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.JOB_MONITORING.UPDATE_JOB.SUCCESS
        })
        store.exceptionStore.setRefresh(false)
        if (status) {
          findAllJobs(currentPage, {
            ...allQueryParams,
            ['previous-run-status']: status
          })
        } else {
          findAllJobs(currentPage, allQueryParams)
        }
      })
      .catch(() =>
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.JOB_MONITORING.UPDATE_JOB.ERROR
        })
      )
      .finally(() => {
        setLoading(false)
      })
  }

  const handleSubmitEditedJob = () => {
    setButtonLoading(true)
    const nameCheckPromises = []

    if (!isEmpty(selectedJob.name) && originalJob.name !== selectedJob.name) {
      nameCheckPromises.push(
        checkNameAvailabilityFromServer(selectedJob.name.trim())
      )
    }

    if (!isEmpty(selectedJob.slug) && originalJob.slug !== selectedJob.slug) {
      nameCheckPromises.push(
        checkNameAvailabilityFromServer(selectedJob.slug.trim(), true)
      )
    }

    if (nameCheckPromises.length > 0) {
      Promise.allSettled(nameCheckPromises)
        .then((results) => {
          let success = true

          results.forEach((result, index) => {
            if (result.status !== 'fulfilled') {
              success = false
              const isNameCheck =
                index === 0 &&
                !isEmpty(selectedJob.name) &&
                originalJob.name !== selectedJob.name
              const isSlugCheck =
                index === 0 &&
                !isEmpty(selectedJob.slug) &&
                originalJob.slug !== selectedJob.slug

              if (isNameCheck) {
                if (result.reason === StringConstants.NAME_ALREADY_TAKEN) {
                  setIsInvalidJobName(true)
                } else {
                  ToastNotification({
                    type: 'error',
                    message: ToastMessageConstants.SOMETHING_WENT_WRONG
                  })
                }
              } else if (isSlugCheck) {
                if (result.reason === StringConstants.SLUG_ALREADY_TAKEN) {
                  setIsInvalidJobSlug(true)
                } else {
                  ToastNotification({
                    type: 'error',
                    message: ToastMessageConstants.SOMETHING_WENT_WRONG
                  })
                }
              }
            }
          })

          if (success) {
            if (selectedJob) {
              editJob()
            }
          } else {
            setButtonLoading(false)
          }
        })
        .catch((err) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.SOMETHING_WENT_WRONG
          })
          setButtonLoading(false)
        })
    } else {
      editJob()
    }
  }

  const resetCheckNameValidation = () => {
    setIsInvalidJobSlug(false)
    setIsInvalidJobName(false)
  }
  const editJob = () => {
    setEditPopupOpen(false)
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    const payload = {
      name: selectedJob.name.trim(),
      slug: selectedJob.slug.trim(),
      description: selectedJob.description,
      cronExpression: selectedJob.cronExpression,
      monitorJob: selectedJob.monitorJob,
      $jobId: selectedJob.id as string
    }
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    updateConfiguredJob(payload)
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.JOB_MONITORING.UPDATE_JOB.SUCCESS
        })
        resetCheckNameValidation()
        if (status) {
          findAllJobs(currentPage, {
            ...allQueryParams,
            ['previous-run-status']: status
          })
        } else {
          findAllJobs(currentPage, allQueryParams)
        }
      })
      .catch(() => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.JOB_MONITORING.UPDATE_JOB.ERROR
        })
        setButtonLoading(false)
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      })
      .finally(() => {
        setButtonLoading(false)
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      })
  }

  const onJobPageChange = (page: number) => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    setQueryParams({
      ...allQueryParams,
      page: page.toString()
    })
  }

  const handleStartClick = () => {
    setSelectedJob((prevJob: any) => ({
      ...prevJob,
      monitorJob: true
    }))
  }

  const handleStopClick = () => {
    setSelectedJob((prevJob: any) => ({
      ...prevJob,
      monitorJob: false
    }))
  }

  const cancelEdit = () => {
    resetCheckNameValidation()
    setEditPopupOpen(false)
  }

  const handleInputChange = (field: string, value: string) => {
    resetCheckNameValidation()
    setSelectedJob({ ...selectedJob, [field]: value })
  }

  const columns = [
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center '>
          <DDSTypography.Paragraph
            size='caption'
            className=''
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {JMStringConstants.JOB_NAME_HEADER}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'name',
      render: (text: any) => (
        <div className='flex justify-start text-left max-w-[100px]'>
          <Tooltip
            id={`tooltip-name-${text}`}
            label={text ? text : '-'}
            className={`${
              typeof text === 'string' && text.length > 10 ? text : '!hidden'
            }`}
          >
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate max-w-[100px]'
              color={pallete.colors.textDark4}
            >
              {text ? text : '-'}
            </DDSTypography.Paragraph>
          </Tooltip>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            className='whitespace-nowrap'
            color={pallete.colors.textDark6}
          >
            {JMStringConstants.JOB_ID_HEADER}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'id',
      render: (text: any) => (
        <div className='flex justify-center text-center gap-[12px] max-w-[80px]'>
          <Tooltip
            id={`'tooltip-id-${text}`}
            label={text ? text : '-'}
            className={`${
              typeof text === 'string' && text.length > 10 ? text : '!hidden'
            }`}
          >
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate max-w-[80px]'
              color={pallete.colors.textDark4}
            >
              {text ? text : '-'}
            </DDSTypography.Paragraph>
          </Tooltip>
          <CopyButton copyText={text} />
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            className='whitespace-nowrap'
            color={pallete.colors.textDark6}
          >
            {JMStringConstants.JOB_SLUG_HEADER}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'slug',
      render: (text: any) => (
        <div className='flex justify-center text-center gap-[12px] max-w-[80px]'>
          <Tooltip
            id={`'tooltip-id-${text}`}
            label={text ? text : '-'}
            className={`${
              typeof text === 'string' && text.length > 10 ? text : '!hidden'
            }`}
          >
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate max-w-[80px]'
              color={pallete.colors.textDark4}
            >
              {text ? text : '-'}
            </DDSTypography.Paragraph>
          </Tooltip>
          <CopyButton copyText={text} />
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center  text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            className=''
            color={pallete.colors.textDark6}
          >
            {JMStringConstants.LATEST_CLIENT_VERSION_HEADER}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'latestClientVersion',
      render: (text: any) => (
        <div className='flex justify-center text-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            className=''
            color={pallete.colors.textDark4}
          >
            {text ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },

    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center  text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {JMStringConstants.CREATED_BY_HEADER}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'createdByUser',
      render: (item: any) => (
        <div className='flex justify-center text-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            className='truncate'
            color={pallete.colors.textDark4}
          >
            {item.firstName}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center  text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {JMStringConstants.CREATED_ON_HEADER}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'createdAt',
      render: (text: any) => (
        <div className='flex justify-center text-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            className='truncate'
            color={pallete.colors.textDark4}
          >
            {timeZone(
              store.userStore.getUserPreference().timeZone,
              text ? text : '-',
              timeFormats.fullMonthDateYearTime
            )}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center  text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {JMStringConstants.MONITORING_HEADER}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'monitorJob',
      render: (active: any, fullItem: any) => (
        <div className='flex justify-center text-center'>
          <Switch
            id='monitor-switch'
            value={active}
            disabled={store.userStore.getNoActiveSubscription()}
            onChange={(value) => {
              handleMonitorToggle(value, fullItem['id'])
            }}
          />
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center  text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {JMStringConstants.RECENT_STATUS_HEADER}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'previousRunStatus',
      render: (text: any) =>
        text ? (
          <div
            className='flex justify-center rounded-[4px] text-center px-[12px] py-[4px] items-center'
            style={{ backgroundColor: statusToColor(text) }}
          >
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate'
              color={pallete.colors.surface1}
            >
              {toTitleCase(text)}
            </DDSTypography.Paragraph>
          </div>
        ) : (
          <div className='flex justify-center  text-center px-[12px] py-[4px] items-center'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate'
              color={pallete.colors.textDark4}
            >
              {'-'}
            </DDSTypography.Paragraph>
          </div>
        )
    },

    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div
          className={`flex justify-center cursor-pointer ${
            store.userStore.getNoActiveSubscription() &&
            'cursor-not-allowed pointer-events-none opacity-50'
          }`}
        >
          <Edit
            size={16}
            color={pallete.colors.textDark4}
            onClick={() => {
              setSelectedJob(fullItem)
              setOriginalJob(fullItem)
              setInitialLoad(true)
              setEditPopupOpen(true)
            }}
          />
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='flex justify-center cursor-pointer'>
          <ChevronRight
            size={16}
            color={pallete.colors.primary2}
            onClick={() => {
              store.jobStore.setSelectedJob([fullItem['id']])
              const url = addQueryParamsToUrl(
                './' + fullItem['id'],
                {
                  page: '1',
                  tab: '1',
                  previousTab: (queryParams.get('tab') as string) ?? '1'
                },
                HttpConstants.GET_METHOD
              )
              navigate(url)
            }}
          />
        </div>
      )
    }
  ]
  if (isLoading || store.uiStore.getGlobalLoader()) return <Spinner />
  return (
    <div className='flex flex-col gap-[16px] pt-[16px] pb-[20px]'>
      <div className='flex items-center custom-filter'>
        <Filter
          dropdownLabel='Filter'
          disableDeleteChip={false}
          options={filterOptions}
          disabled={
            (totalDataCount === 0 && !store.filterStore.getFiltersApplied()) ||
            loading
          }
        />
      </div>
      {jobs && totalDataCount > 0 ? (
        <Table
          id='all-logs-table'
          tableStyle={{
            borderColor: pallete.colors.stroke2
          }}
          data={jobs}
          columns={columns}
          totalCount={totalDataCount}
          rowsPerPage={10}
          currentPage={currentPage}
          onPageChange={(page) => {
            onJobPageChange(page)
            setCurrentPage(page)
          }}
          loading={store.uiStore.getGlobalLoader()}
        />
      ) : (
        <NoRecord
          style={{ height: '70vh' }}
          imageSrc={
            !recordsPresent
              ? NoRecordImage
              : store.filterStore.getFiltersApplied()
                ? NoFilterImage
                : NoFilterImage
          }
          text={
            !recordsPresent ? (
              <>
                Configure{''}
                {isFullStackProject ? ' Scheduled Job' : ' Apex Job'}{' '}
                Monitoring.
                <Link
                  href={StringConstants.VIGIL_SDK_URL}
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ marginLeft: '4px' }}
                >
                  Learn more
                </Link>{' '}
              </>
            ) : store.filterStore.getFiltersApplied() ? (
              StringConstants.NO_RECORDS_FILTER
            ) : (
              <>{getNoDataMessageForStatus(status || '')}</>
            )
          }
        />
      )}
      <AddEditJobPopUp
        open={editPopupOpen}
        setOpen={setEditPopupOpen}
        isAdd={false}
        loading={loading}
        onSubmit={handleSubmitEditedJob}
        onCancel={cancelEdit}
        buttonLoading={buttonLoading}
        buttonDisabled={isButtonDisabled}
        values={{
          name: selectedJob.name,
          slug: selectedJob.slug,
          cronExpression: selectedJob.cronExpression,
          description: selectedJob.description,
          monitorJob: selectedJob.monitorJob
        }}
        isInvalidJobName={isInvalidJobName}
        isInvalidJobSlug={isInvalidJobSlug}
        handleInputChange={handleInputChange}
        handleMonitorStart={handleStartClick}
        handleMonitorStop={handleStopClick}
      />
    </div>
  )
}

export default observer(JMJobsPage)
