import React, { useState } from 'react'
import {
  organizationMemberLookUp,
  transferOwnership
} from '../../api/organization/Organization.service'

import HttpConstants from '../../constants/HttpConstants'
import Popup from '../common/Popup/Popup'
import SelectMemberStep from './TransferOwnershipPopupFlow/SelectMemberStep'
import TabGroup from '../common/TabGroup/TabGroup'
import TextConfirmationSection from '../common/ConfirmationSection/TextConfirmationSection'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../common/DDS/Toast/Toast'
import { addQueryParamsToUrl } from '../../utils/UrlGenerator'
import breakpoints from '../../global/breakpoints'
import palette from '../../global/pallete'
import routeConstants from '../../constants/RouteConstants'
import styled from '@emotion/styled'
import transferOwnershipIcon from '../../assets/icons/transferOwnership.svg'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../store/StoreContext'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.9rem;
  }
`

const TransferOwnershipPopup: React.FC<{
  handleClose: () => void
  open: boolean
}> = ({ handleClose, open }) => {
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [transferOwnershipRequest, setTransferOwnershipRequest] = useState<any>(
    {}
  )
  const store = useStoreContext()
  const [loading, setLoading] = useState<boolean>(false)
  const [userOptions, setUserOptions] = useState<any>([])
  const [selectedUser, setSelectedUser] = useState<any>(null)
  const [internalStep, setInternalStep] = useState<number>(0)
  const [openMultiSelect, setOpenMultiSelect] = useState<boolean>(false)
  const [loadOptions, setLoadOptions] = React.useState<boolean>(false)
  const navigate = useNavigate()

  const setTransferOwnershipRequestData = (data: any) => {
    setTransferOwnershipRequest({ ...transferOwnershipRequest, ...data })
  }
  const contextMessage = transferOwnershipRequest.retainMembership
    ? 'You will be removed as an owner of this organization'
    : 'You will be removed from the organization and will not have any access to this organization'

  const tabLabels = [{ label: 'Select Member' }, { label: 'Verify Text' }]

  const handleNextStep = () => {
    if (currentStep === 0) setCurrentStep(currentStep + 1)
    else {
      handleTransferOwnership()
    }
  }

  const handleTransferOwnership = () => {
    setLoading(true)
    transferOwnership({
      organizationMemberId: transferOwnershipRequest.id,
      retainMembership: transferOwnershipRequest.retainMembership
    })
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.ORGANIZATION.TRANSFER_OWNERSHIP.SUCCESS
        })
        store.uiStore.setSidebarItem({})
        navigate(
          addQueryParamsToUrl(
            routeConstants.REDIRECT,
            {
              redirect: routeConstants.ACCOUNT_SETTINGS
            },
            HttpConstants.GET_METHOD
          )
        )
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SOMETHING_WENT_WRONG
        })
      })
      .finally(() => {
        setLoading(false)
        handleClose()
        setCurrentStep(0)
      })
  }

  const handleSearch = async (value: any) => {
    setLoadOptions(true)
    setUserOptions([])
    if (value.length >= 3) {
      setOpenMultiSelect(true)

      const response: any = await organizationMemberLookUp({
        'name-or-email': value
      })
      const data = response.data
      if (data.length === 0) setOpenMultiSelect(false)
      setUserOptions(
        data.map(({ user, role, id }: any) => {
          return {
            id,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            position: role
          }
        })
      )
    } else setOpenMultiSelect(false)
    setLoadOptions(false)
  }

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <SelectMemberStep
            handleNext={handleNextStep}
            setData={setTransferOwnershipRequestData}
            selectedUser={selectedUser}
            handleSearch={handleSearch}
            setSelectedUser={setSelectedUser}
            userOptions={userOptions}
            memberSelectInternalStep={internalStep}
            openMultiSelect={openMultiSelect}
            setOpenMultiSelect={setOpenMultiSelect}
            loadOptions={loadOptions}
          />
        )
      case 1:
        return (
          <TextConfirmationSection
            handleSubmit={handleNextStep}
            context={contextMessage}
            buttonIcon={<img src={transferOwnershipIcon} />}
            buttonText='Transfer Ownership'
            confirmationText={`Transfer Ownership to ${transferOwnershipRequest.name}`}
            heading='Transfer Ownership'
            loading={loading}
            goBack={() => {
              setCurrentStep(0)
              setInternalStep(1)
            }}
          />
        )
    }
  }
  return (
    <Popup
      open={open}
      handleClose={() => {
        handleClose()
        setCurrentStep(0)
        setSelectedUser(null)
        setUserOptions([])
        setOpenMultiSelect(false)
        setInternalStep(0)
      }}
      headerIcon={
        <img
          src={transferOwnershipIcon}
          style={{
            filter: palette.colors.primarySvgFilter,
            height: '18px',
            width: '18px'
          }}
        />
      }
      headerText='Transfer Ownership'
      height='500px'
      width='850px'
      destroyOnClose
    >
      <Container>
        <TabGroup
          currentTab={currentStep}
          onChange={() => null}
          tabLabels={tabLabels}
          $disableSwitch={true}
        />
        {renderStep()}
      </Container>
    </Popup>
  )
}

export default TransferOwnershipPopup
