import React, { useState } from 'react'

import { AccessRoleConstants } from '../../../constants/AccessRoleConstants'
import DocumentHeader from '../../Helmet/DocumentHeader'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import Navbar from '../Navbar/Navbar'
import SecureComponent from '../../Layout/SecureComponent'
import { TypographyDDS } from '../../common/Typography/TypographyDDS'
import breakpoints from '../../../global/breakpoints'
import { gaPageConstants } from '../../../constants/GAConstants'
import palette from '../../../global/pallete'
import routeConstants from '../../../constants/RouteConstants'
import styled from '@emotion/styled'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${palette.colors.white};
  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.85rem;
  }

  @media (max-width: ${breakpoints.md}) {
    font-size: 0.7rem;
  }
`

const DescriptionSection = styled.div`
  padding-bottom: 150px;
`

const NavbarWrapper = styled.div`
  width: 80%;
  padding-top: 3em;
  display: flex;
  align-items: center;
  margin: auto;
  @media screen and (max-width: ${breakpoints.xl}) {
    width: 80%;
    padding: 3em 1.25em 0;
  }
`
const ChildrenWrapper = styled.div`
  width: 96em;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: ${breakpoints.xl}) {
    width: 100%;
    padding: 0 1.25em;
  }
`

const NoOrganizationJoined: React.FC = () => {
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.NO_ORGANIZATION
  )
  const store = useStoreContext()
  const navigate = useNavigate()
  const [noOrg, setNoOrg] = useState<boolean>(false)

  React.useEffect(() => {
    if (store.userStore.getOrganizations().length > 0) {
      navigate(routeConstants.REDIRECT)
    } else {
      setNoOrg(true)
      store.uiStore.setSidebarItem({})
    }
    store.uiStore.setGlobalLoader(false)
  }, [])
  return (
    <>
      {noOrg && (
        <>
          <DocumentHeader header={HeaderConstants.NO_ORGANIZATION} />
          <Container>
            <NavbarWrapper>
              <Navbar
                onChange={() => null}
                currentTab={0}
                inviteMembers
                showNotifications
              />
            </NavbarWrapper>

            <ChildrenWrapper>
              <DescriptionSection>
                <TypographyDDS.Title
                  type='h1'
                  variant='semiBold'
                  style={{
                    paddingBottom: '16px',
                    textAlign: 'left'
                  }}
                >
                  You have not joined any
                  <span
                    style={{
                      fontWeight: palette.fontWeight.bold,
                      color: palette.colors.primary
                    }}
                  >
                    {' '}
                    Organization
                  </span>
                </TypographyDDS.Title>
                <TypographyDDS.Title
                  type='h5'
                  color='textDark2'
                  style={{ textAlign: 'center' }}
                >
                  Please check your notifications for any invitations
                </TypographyDDS.Title>
              </DescriptionSection>
              {/* <ButtonWrapper>
            <Button
              variant='contained'
              fullWidth
              endIcon={<BsChevronRight />}
              background={palette.colors.gradientBlue}
              margin='1.25em 0'
              height='3.5em'
              onClick={() => {
                navigate(routeConstants.CREATE_ORGANIZATION)
                gaEventTracker(gaEventConstants.INITIATE_CREATE_ORGANIZATION)
              }}
            >
              Create An Organization
            </Button>
          </ButtonWrapper> */}
            </ChildrenWrapper>
          </Container>
        </>
      )}
    </>
  )
}

export default SecureComponent(NoOrganizationJoined, AccessRoleConstants.ALL)
