import { Link, useNavigate } from 'react-router-dom'
import { UrlWithOnlyOrgId, UrlWithOrgProjEnvIds } from '../utils/UrlUtils'

import { AccessRoleConstants } from '../constants/AccessRoleConstants'
import DocumentHeader from '../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../constants/HeaderConstants'
import HttpConstants from '../constants/HttpConstants'
import React from 'react'
import SecureComponent from '../components/Layout/SecureComponent'
import { addQueryParamsToUrl } from '../utils/UrlGenerator'
import routeConstants from '../constants/RouteConstants'
import styled from '@emotion/styled'
import { useStoreContext } from '../store/StoreContext'

const Container = styled.div`
  overflow: overlay;
  height: 100vh;
`
const LinkContainer = styled.p`
  cursor: pointer;
`
const AllPages = () => {
  const navigate = useNavigate()
  const store = useStoreContext()
  return (
    <Container>
      <DocumentHeader header={HeaderConstants.ALL_PAGES} />
      <h2>USER</h2>
      <LinkContainer>
        <Link to={routeConstants.SIGN_UP}>User SignUp</Link>
      </LinkContainer>
      <LinkContainer>
        <Link to={routeConstants.SIGN_IN}>User SignIn</Link>
      </LinkContainer>
      <LinkContainer>
        <Link to={routeConstants.SIGN_UP_INVITED}>User SignUp Invited</Link>
      </LinkContainer>
      <br></br>
      <h2>Organization</h2>
      <LinkContainer
        onClick={() => {
          navigate(
            addQueryParamsToUrl(
              routeConstants.REDIRECT,
              {
                redirect: UrlWithOnlyOrgId(
                  routeConstants.CREATE_ORGANIZATION,
                  store
                ),
              },
              HttpConstants.GET_METHOD
            )
          )
        }}
      >
        Create Organization
      </LinkContainer>
      <LinkContainer
        onClick={() => {
          navigate(routeConstants.INVITE_MEMBERS)
        }}
      >
        Invite members to Organization
      </LinkContainer>
      <LinkContainer
        onClick={() => {
          navigate(
            addQueryParamsToUrl(
              routeConstants.REDIRECT,
              {
                redirect: UrlWithOnlyOrgId(
                  routeConstants.ORGANIZATION_SETTINGS,
                  store
                ),
              },
              HttpConstants.GET_METHOD
            )
          )
        }}
      >
        Organization Settings
      </LinkContainer>
      <br></br>
      <h2>PROJECT</h2>
      <LinkContainer
        onClick={() => {
          navigate(
            addQueryParamsToUrl(
              routeConstants.REDIRECT,
              {
                redirect: UrlWithOnlyOrgId(
                  routeConstants.PROJECT_SETTINGS,
                  store
                ),
              },
              HttpConstants.GET_METHOD
            )
          )
        }}
      >
        Project Settings{' '}
      </LinkContainer>
      <br></br>
      <h2>APP HEALTH CHECK</h2>
      <LinkContainer
        onClick={() => {
          navigate(
            addQueryParamsToUrl(
              routeConstants.REDIRECT,
              {
                redirect: UrlWithOrgProjEnvIds(
                  routeConstants.AHC_PROJECTS_DASHBOARD,
                  store
                ),
              },
              HttpConstants.GET_METHOD
            )
          )
        }}
      >
        AHC Projects Dashboard
      </LinkContainer>
      <LinkContainer
        onClick={() => {
          navigate(
            addQueryParamsToUrl(
              routeConstants.REDIRECT,
              {
                redirect: UrlWithOrgProjEnvIds(
                  routeConstants.AHC_DASHBOARD,
                  store
                ),
              },
              HttpConstants.GET_METHOD
            )
          )
        }}
      >
        AHC Dashboard
      </LinkContainer>
      <LinkContainer
        onClick={() => {
          navigate(
            addQueryParamsToUrl(
              routeConstants.REDIRECT,
              {
                redirect: UrlWithOrgProjEnvIds(
                  routeConstants.AHC_STATUS,
                  store
                ),
              },
              HttpConstants.GET_METHOD
            )
          )
        }}
      >
        AHC Status
      </LinkContainer>

      <br></br>
      <h2>API-MONITORING</h2>
      <LinkContainer
        onClick={() => {
          navigate(
            addQueryParamsToUrl(
              routeConstants.REDIRECT,
              {
                redirect: UrlWithOrgProjEnvIds(
                  routeConstants.API_MONITORING,
                  store
                ),
              },
              HttpConstants.GET_METHOD
            )
          )
        }}
      >
        API Monitoring
      </LinkContainer>
      <LinkContainer
        onClick={() => {
          navigate(
            addQueryParamsToUrl(
              routeConstants.REDIRECT,
              {
                redirect: UrlWithOrgProjEnvIds(
                  routeConstants.ARCHIVED_API_RECORDS,
                  store
                ),
              },
              HttpConstants.GET_METHOD
            )
          )
        }}
      >
        Archived API Records
      </LinkContainer>
      <br></br>

      <h2>ERROR MONITORING</h2>

      <LinkContainer
        onClick={() => {
          navigate(
            addQueryParamsToUrl(
              routeConstants.REDIRECT,
              {
                redirect: UrlWithOrgProjEnvIds(
                  routeConstants.ERROR_MONITORING,
                  store
                ),
              },
              HttpConstants.GET_METHOD
            )
          )
        }}
      >
        Error Monitoring
      </LinkContainer>
      <LinkContainer>Error Details</LinkContainer>
      <br></br>

      <h2>JOB MONITORING</h2>
      <LinkContainer
        onClick={() => {
          navigate(
            addQueryParamsToUrl(
              routeConstants.REDIRECT,
              {
                redirect: UrlWithOrgProjEnvIds(routeConstants.JOB_LOGS, store),
              },
              HttpConstants.GET_METHOD
            )
          )
        }}
      >
        Job Logs
      </LinkContainer>
      <LinkContainer
        onClick={() => {
          navigate(
            addQueryParamsToUrl(
              routeConstants.REDIRECT,
              {
                redirect: UrlWithOrgProjEnvIds(
                  routeConstants.JOB_MONITORING,
                  store
                ),
              },
              HttpConstants.GET_METHOD
            )
          )
        }}
      >
        Job Monitoring
      </LinkContainer>
      <br></br>
      <h2>General</h2>
      <LinkContainer
        onClick={() => {
          navigate(
            addQueryParamsToUrl(
              routeConstants.REDIRECT,
              {
                redirect: UrlWithOnlyOrgId(
                  routeConstants.MEMBER_SETTINGS,
                  store
                ),
              },
              HttpConstants.GET_METHOD
            )
          )
        }}
      >
        Member Settings
      </LinkContainer>
      <LinkContainer
        onClick={() => {
          navigate(routeConstants.PREFERENCES_SETTINGS)
        }}
      >
        Preference Settings
      </LinkContainer>
      <LinkContainer>Error Page</LinkContainer>
      <LinkContainer
        onClick={() => {
          navigate(
            addQueryParamsToUrl(
              routeConstants.REDIRECT,
              {
                redirect: UrlWithOnlyOrgId(routeConstants.SETTINGS, store),
              },
              HttpConstants.GET_METHOD
            )
          )
        }}
      >
        Settings
      </LinkContainer>
      <LinkContainer>All Stylings Reference</LinkContainer>
      <LinkContainer
        onClick={() => {
          navigate(
            addQueryParamsToUrl(
              routeConstants.REDIRECT,
              {
                redirect: UrlWithOnlyOrgId(
                  routeConstants.SUBSCRIPTION_SETTINGS,
                  store
                ),
              },
              HttpConstants.GET_METHOD
            )
          )
        }}
      >
        Subscription Settings
      </LinkContainer>
      <LinkContainer
        onClick={() => {
          navigate(
            addQueryParamsToUrl(
              routeConstants.REDIRECT,
              {
                redirect: UrlWithOnlyOrgId(
                  routeConstants.ACCOUNT_SETTINGS,
                  store
                ),
              },
              HttpConstants.GET_METHOD
            )
          )
        }}
      >
        Account Settings
      </LinkContainer>
      <LinkContainer
        onClick={() => {
          navigate(routeConstants.FORGOT_PASSWORD)
        }}
      >
        Forgot Password
      </LinkContainer>
      <LinkContainer
        onClick={() => {
          navigate(routeConstants.FORGOT_PASSWORD)
        }}
      >
        Reset Password
      </LinkContainer>
      <LinkContainer
        onClick={() => {
          navigate(
            addQueryParamsToUrl(
              routeConstants.REDIRECT,
              {
                redirect: UrlWithOnlyOrgId(routeConstants.PLANS, store),
              },
              HttpConstants.GET_METHOD
            )
          )
        }}
      >
        Plans
      </LinkContainer>

      <LinkContainer>Overview</LinkContainer>
      <LinkContainer
        onClick={() => {
          navigate(
            addQueryParamsToUrl(
              routeConstants.REDIRECT,
              {
                redirect: UrlWithOnlyOrgId(routeConstants.OVERVIEW, store),
              },
              HttpConstants.GET_METHOD
            )
          )
        }}
      >
        Notifications
      </LinkContainer>
    </Container>
  )
}

export default SecureComponent(AllPages, AccessRoleConstants.ALL)
