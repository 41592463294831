import { Notification, UserFollow } from '@carbon/icons-react'

import React from 'react'
import routeConstants from '../../constants/RouteConstants'

const NotificationInvites = () => {
  return [
    {
      icon: <Notification size={20} />,
      label: 'Notifications',
      to: routeConstants.NOTIFICATIONS
    },
    {
      icon: <UserFollow size={20} />,
      label: 'Invitations',
      to: routeConstants.INVITES
    }
  ]
}
export default NotificationInvites
