import FilledTabGroup from '../common/TabGroup/FilledTabGroup'
import Filter from '../Filter/Filter'
import React from 'react'
import { TypographyDDS } from '../common/Typography/TypographyDDS'
import palette from '../../global/pallete'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  margin-bottom: 10px;
`

const RightContainer = styled.div`
  @media screen and (max-width: 985px) {
    right: 0;
    position: absolute;
  }
`
const Top = styled.div`
  display: flex;
  flexdirection: row;
  @media screen and (max-width: 985px) {
    display: block;
  }
`
const Wrapper = styled.div`
  @media screen and (max-width: 985px) {
    padding-right: 30px;
  }
`

const DashboardHeader: React.FC<{
  currentTab?: number
  onTabChange?: (tab: number) => void
  options?: []
  disabled?: boolean
}> = ({
  currentTab = 0,
  onTabChange = () => null,
  options,
  disabled = false
}) => {
  const TabLabels = [
    { id: 1, label: 'Existing Members' },
    { id: 2, label: 'Invited Members' }
  ]

  return (
    <Container>
      <Top>
        <TypographyDDS.Title type='h4' variant='bold'>
          Manage Members
        </TypographyDDS.Title>

        <div
          style={{
            flexGrow: '1',
            marginRight: '10%',
            paddingTop: window.innerWidth <= 985 ? '20px' : '0px',
            paddingBottom: '1%'
          }}
        >
          <FilledTabGroup
            tabLabels={TabLabels}
            currentTab={currentTab}
            onChange={(tab) => onTabChange(tab)}
            tabWidth='9em'
            disabled={disabled}
          />
        </div>
      </Top>
      <RightContainer>
        {/* <SearchField /> */}
        <Wrapper>
          <Filter
            primaryText='Add Filters'
            options={options}
            dropdownLabel=''
            disabled={disabled}
          />
        </Wrapper>
      </RightContainer>
    </Container>
  )
}

export default DashboardHeader
