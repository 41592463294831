import {
  BsCheckCircle,
  BsExclamationCircle,
  BsInfoCircle
} from 'react-icons/bs'
import { InputAdornment, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  default as RouteConstants,
  default as routeConstants
} from '../../../constants/RouteConstants'
import {
  getAhcLog,
  getAhcLogFilters,
  getApiHealth,
  updateHealthCheck
} from '../../../api/appHealthCheck/AHC.service'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceBadgeUrl
} from '../../../utils/UrlUtils'
import { isEmpty, isUndefined } from 'lodash'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'

import { AccessRoleConstants } from '../../../constants/AccessRoleConstants'
import CopyOnClick from '../../../components/common/CopyOnClick/CopyOnClick'
import CustomErrorToast from '../../../components/common/Toast/CustomErrorToast'
import { DDSToastNotification } from 'den-design-system'
import DashboardSectionContainer from '../../../components/common/DashboardSectionContainer/DashboardSectionContainer'
import DividerLine from '../../../components/common/DividerLine/DividerLine'
import Downtime from '../../../assets/icons/downtime.svg'
import Filter from '../../../components/Filter/Filter'
import FilterChip from '../../../components/common/FilterChip/FilterChip'
import { FilterTypes } from '../../../enum/Filter.enum'
import IncidentsHistoryTable from '../../../components/AHCPage/PerformanceMetricsPage/IncidentsHistoryTable'
import PauseIcon from '../../../assets/icons/pauseIcon.svg'
import PerformanceGraph from '../../../components/AHCPage/PerformanceMetricsPage/PerformanceGraph'
import ResponseTime from '../../../assets/icons/responseTime.svg'
import SecureComponent from '../../../components/Layout/SecureComponent'
import Spinner from '../../../components/common/Spinner/Spinner'
import StringConstants from '../../../constants/StringConstants'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import { TypographyDDS } from '../../../components/common/Typography/TypographyDDS'
import Uptime from '../../../assets/icons/upTime.svg'
import breakpoints from '../../../global/breakpoints'
import { ellipsis } from '../../../utils/CommonUtils/Ellipsis'
import { getFiltersForApi } from '../../../utils/FilterUtils'
import { observer } from 'mobx-react-lite'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'
import { timeMetric } from '../../../utils/DateTimeUtils'
import { toast } from 'react-toastify'
import { useStoreContext } from '../../../store/StoreContext'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
`
const InfoSection = styled.div`
  display: flex;
  margin-left: 5%;
  margin-right: 5%;
  justify-content: space-between;
  @media (max-width: ${breakpoints.md}) {
    margin-left: 0;
    margin-right: 0;
    column-gap: 0.5em;
  }
`
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const DividerWrapper = styled.div`
  height: 50%;
  margin-top: auto;
  margin-bottom: auto;
`
const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`
const SectionDivider = styled.div`
  border-bottom: 1px solid ${palette.colors.borderColor};
  margin-top: 2em;
`
const GraphSection = styled.div`
  display: flex;
  margin-left: 5%;
  justify-content: space-evenly;
  margin-top: 3em;
  margin-bottom: 1em;
`
const GraphLegendContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  margin-top: 1em;
  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
  }
`
const GraphWrapper = styled.div`
  width: 75%;
  max-height: 413px;
  display: flex;
  flex-direction: column;
  gap: 1em;
`
const ResponseInfoContainer = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 1em;
`

const OpenLink = styled.a`
  text-decoration: none;
  color: ${palette.colors.primary};
`

const Spacer = styled.div`
  flex-grow: 1;
`
const LegendImg = styled.img`
  height: 4px;
  weight: 4px;
`
const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
const UrlWrapper = styled.div`
  max-width: 300px;
`

const PerformanceMetricsPage: React.FC = () => {
  const [filterOptions, setFilterOptions] = useState()
  const store = useStoreContext()
  useEffect(() => {
    store.filterStore.setFilterDisabled(true)
    getAhcLogFilters()
      .then((response: any) => {
        if (response.data.length > 0) {
          setFilterOptions(response.data)
        }
      })
      .catch((err: any) => {
        return (
          <CustomErrorToast
            heading={ToastMessageConstants.AHC.AHC_LOG_FILTERS.ERROR}
            message={err}
          />
        )
      })
  }, [])
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [queryParams, setQueryParams] = useSearchParams()
  const allQueryParams = getAllQueryParamsAsObjectFromUrl(
    queryParams.toString()
  )
  const { ahcId } = useParams()
  const { ahcReportsStore, ahcLogsStore, filterStore, uiStore } =
    useStoreContext()

  const [encryptedId, setEncryptedId] = React.useState<string>('')
  const getTimeData = (key = 'response') => {
    const report = ahcReportsStore.getActiveReport()
    if (report)
      switch (key) {
        case 'response':
          return Object.keys(report.responsesByDate).map((key) => ({
            x: key,
            y: report.responsesByDate[key].averageResponseTimeInMilliSeconds
          }))
        case 'down':
          return Object.keys(report.responsesByDate).map((key) => ({
            x: key,
            y: report.responsesByDate[key].downTimePercentage
          }))
        case 'up':
          return Object.keys(report.responsesByDate).map((key) => ({
            x: key,
            y: report.responsesByDate[key].upTimePercentage
          }))
      }
    return []
  }
  const getResponseTimeMeasure = (key: string) => {
    const report = ahcReportsStore.getActiveReport()
    if (report && Object.keys(report.responsesByDate).length !== 0) {
      const data = Object.keys(report.responsesByDate).map(
        (k) => report.responsesByDate[k].averageResponseTimeInMilliSeconds
      )
      switch (key) {
        case 'max':
          return Math.max(...data)
        case 'min':
          return Math.min(...data)
        case 'avg':
          return (
            Math.round((data.reduce((a, b) => a + b) / data.length) * 10) / 10
          )
        default:
          return 0
      }
    }
    return 0
  }
  const fetchIncidents = async () => {
    const filters = getFiltersForApi(FilterTypes.AHC_LOGS, allQueryParams)
    ahcLogsStore.setLoading(true)
    try {
      if (ahcId) {
        ahcLogsStore.setLoading(true)
        store.uiStore.setGlobalLoader(true)
        const response: any = await getAhcLog({
          $ahcId: ahcId,
          page: ahcLogsStore.getPage(),
          size: StringConstants.DEFAULT_TABLE_SIZE,
          ...filters
        })

        ahcLogsStore.setCount(response.totalCount)
        ahcLogsStore.setLogs(response.data)
        filterStore.setFilters(response.filters)
        ahcLogsStore.setLoading(false)
        store.uiStore.setGlobalLoader(false)
      }
    } catch (e) {
      ahcLogsStore.setLoading(false)
      console.log(e)
    } finally {
      ahcLogsStore.setLoading(false)
    }
    uiStore.setGlobalLoader(false)
  }
  useEffect(() => {
    uiStore.setGlobalLoader(false)
    ahcReportsStore.setLoading(true)
    if (ahcId)
      ahcReportsStore
        .getReportDetailsFromAPI(ahcId, allQueryParams)
        .catch((err: any) => {
          ahcReportsStore.setLoading(false)
          if (
            err.exceptionName === 'RecordNotFoundException' ||
            err.exceptionName === 'GuardValidationFailedException'
          ) {
            navigate(routeConstants.NO_RECORD)
          }
        })
  }, [ahcId])

  useEffect(() => {
    fetchIncidents()
  }, [ahcId, queryParams])

  useEffect(() => {
    if (
      store.breadcrumbStore.getBreadcrumbsOptions().length === 1 &&
      store.ahcReportsStore.getActiveReport()
    )
      store.breadcrumbStore.appendBreadCrumbOptions({
        label: store.ahcReportsStore.getActiveReport()?.checkName as string,
        link: '/'
      })
  }, [store.ahcReportsStore.activeReport])

  const handlePauseStatus = (id: any, status = false) => {
    ahcReportsStore.setLoading(true)
    updateHealthCheck({
      $ahcId: id,
      monitorAppHealthCheck: !status
    })
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.AHC.UPDATE_CHECKS.SUCCESS
        })
        ahcReportsStore.setLoading(false)
        if (ahcId) {
          ahcReportsStore.getReportDetailsFromAPI(ahcId).catch((error) => {
            ToastNotification({
              type: 'error',
              message: ToastMessageConstants.AHC.FETCH_REPORT_DETAILS.ERROR
            })
          })
        }
      })
      .catch((error) => {
        ahcReportsStore.setLoading(false)
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.AHC.UPDATE_CHECKS.ERROR
        })
      })
  }

  const onViewAllChecks = () => {
    // filterStore.setFilters({})
    ahcLogsStore.setPage(1)
    const segments = pathname.split('/').filter((s) => s.length !== 0)
    segments.pop()
    const path = '/' + segments.join('/')
    ahcReportsStore.setActiveReport(null)

    navigate(path)
  }
  const statusIconMap: { [key: string]: any } = {
    Up: <BsCheckCircle color={palette.colors.primary} size='28px' />,
    Down: <BsExclamationCircle color={palette.colors.error} size='28px' />
  }
  const renderAvailability = (availability: string) => {
    if (availability === 'Not enough data%' || availability === '-%') {
      return (
        <TypographyDDS.Paragraph
          size='para'
          color='textLight2'
          variant='medium'
        >
          {availability.slice(0, -1)}
        </TypographyDDS.Paragraph>
      )
    }
    const percentage = parseInt(availability.slice(0, -1))
    let color
    if (percentage > 50) color = 'primary'
    else if (percentage > 10) color = 'warning'
    else color = 'error'
    return (
      <TypographyDDS.Title type='h4' variant='bold' color={color}>
        {availability}
      </TypographyDDS.Title>
    )
  }
  const renderStatusIcon = () => {
    const activeReport = ahcReportsStore.getActiveReport()
    const activeReportStatus = activeReport?.status

    if (activeReport?.monitorAppHealthCheck === false) {
      return <img src={PauseIcon} style={{ cursor: 'pointer' }} width={35} />
    } else if (activeReportStatus && statusIconMap[activeReportStatus]) {
      return statusIconMap[activeReportStatus]
    }

    return '-'
  }
  const renderHeader = () => {
    return (
      <HeaderContainer>
        <TypographyDDS.Title type='h4' variant='bold'>
          Performance Metrics
        </TypographyDDS.Title>

        {/* <FilterChip
          variant='text'
          name='Date'
          value={'02 Feb 2022 - 02 Feb 2022'}
        /> */}
      </HeaderContainer>
    )
  }

  useEffect(() => {
    getApiHealth({ $ahcId: ahcId as string })
      .then((response: any) => {
        setEncryptedId(response.data.encryptedId)
      })
      .catch((err) => console.log(err))
  }, [])
  return (
    <Container>
      <DashboardSectionContainer
        headerText={renderHeader()}
        headerFilter={
          <FilterChip
            variant='text'
            disableDeleteChip
            name='Date Range'
            value='Last 7 Days'
          />
        }
      >
        {store.ahcReportsStore.getLoading() ? (
          <Spinner data={50} />
        ) : (
          <>
            <InfoSection>
              <InfoWrapper>
                <TypographyDDS.Paragraph
                  size='para'
                  variant='semiBold'
                  color='textDark'
                >
                  URL Check
                </TypographyDDS.Paragraph>
                <div style={{ marginTop: '0.75em' }} />
                <TypographyDDS.Title color='textDark' type='h4' variant='bold'>
                  {ahcReportsStore.getActiveReport()?.checkName}
                </TypographyDDS.Title>
                <UrlWrapper>
                  <TypographyDDS.Paragraph
                    size='para'
                    variant='semiBold'
                    ellipsis
                  >
                    {!isUndefined(ahcReportsStore.getActiveReport()) && (
                      <OpenLink
                        href={ahcReportsStore.getActiveReport()?.url}
                        target='_blank'
                      >
                        {' '}
                        {(ahcReportsStore.getActiveReport()?.url
                          .length as number) >
                        StringConstants.URL_MAX_LENGTH_FRAGMENT_DISPLAY
                          ? (ahcReportsStore
                              .getActiveReport()
                              ?.url.substring(
                                0,
                                StringConstants.URL_MAX_LENGTH_FRAGMENT_DISPLAY -
                                  1
                              ) as string) + '...'
                          : ahcReportsStore.getActiveReport()?.url}
                      </OpenLink>
                    )}
                  </TypographyDDS.Paragraph>
                </UrlWrapper>
              </InfoWrapper>
              <InfoWrapper>
                <DividerWrapper>
                  <DividerLine variant='vertical' />
                </DividerWrapper>
              </InfoWrapper>
              <InfoWrapper>
                <div
                  style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}
                >
                  <TypographyDDS.Paragraph
                    size='para'
                    variant='semiBold'
                    color='textDark'
                  >
                    Status
                  </TypographyDDS.Paragraph>

                  <Tooltip
                    title={
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                          padding: '2px',
                          fontSize: '12px'
                        }}
                      >
                        <TypographyDDS.Paragraph
                          size='caption'
                          variant='semiBold'
                          color='textDark'
                          style={{ textAlign: 'center' }}
                        >
                          Embeddable Application Status Badge
                        </TypographyDDS.Paragraph>
                        <TextField
                          variant='outlined'
                          color='primary'
                          value={ellipsis(
                            window.location.host +
                              RouteConstants.AHC_BADGE +
                              '/' +
                              encryptedId,
                            27
                          )}
                          size='small'
                          inputProps={{ style: { cursor: 'not-allowed' } }}
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment position='end'>
                                <CopyOnClick
                                  value={
                                    window.location.host +
                                    replaceBadgeUrl(
                                      RouteConstants.AHC_BADGE,
                                      encryptedId
                                    )
                                  }
                                />
                              </InputAdornment>
                            )
                          }}
                        />
                      </div>
                    }
                    placement='right-start'
                    PopperProps={{
                      sx: {
                        '& .MuiTooltip-tooltip': {
                          backgroundColor: `${palette.colors.white}`,
                          boxShadow: `0 1em 1.5625em ${palette.colors.tooltipShadow}`,
                          fontSize: 11
                        }
                      }
                    }}
                  >
                    <div style={{ alignSelf: 'flex-end' }}>
                      <BsInfoCircle color='gray' />
                    </div>
                  </Tooltip>
                </div>

                <div style={{ marginTop: '0.75em' }} />
                <Tooltip
                  title={
                    ahcReportsStore.getActiveReport()?.monitorAppHealthCheck ===
                    false
                      ? 'Paused'
                      : ahcReportsStore.getActiveReport()?.status === 'Up' ||
                          ahcReportsStore.getActiveReport()?.status === 'Down'
                        ? (ahcReportsStore.getActiveReport()?.status as string)
                        : ''
                  }
                  style={{ cursor: 'pointer' }}
                >
                  <span
                    style={{ textAlign: 'center', cursor: 'pointer' }}
                    onClick={() => {
                      handlePauseStatus(
                        ahcReportsStore.getActiveReport()?.id,
                        ahcReportsStore.getActiveReport()?.monitorAppHealthCheck
                      )
                    }}
                  >
                    {renderStatusIcon()}
                  </span>
                </Tooltip>
              </InfoWrapper>
              <InfoWrapper>
                <DividerWrapper>
                  <DividerLine variant='vertical' />
                </DividerWrapper>
              </InfoWrapper>
              {/* <InfoWrapper>
            <Typography variant='h6' fontWeight={palette.fontWeight.semiBold}>
              APDEX
            </Typography>
            <div style={{ marginTop: '0.75em' }} />
            <Typography variant='h4' fontWeight={palette.fontWeight.bold}>
              {apdex}
            </Typography>
          </InfoWrapper>
          <InfoWrapper>
            <DividerWrapper>
              <DividerLine variant='vertical' />
            </DividerWrapper>
          </InfoWrapper> */}
              <InfoWrapper>
                <TypographyDDS.Paragraph
                  size='para'
                  variant='semiBold'
                  color='textDark'
                >
                  Availability
                </TypographyDDS.Paragraph>
                <div style={{ marginTop: '0.75em' }} />
                {renderAvailability(
                  (ahcReportsStore.getActiveReport()?.availability || 0) + '%'
                )}
              </InfoWrapper>
              <InfoWrapper>
                <DividerWrapper>
                  <DividerLine variant='vertical'></DividerLine>
                </DividerWrapper>
              </InfoWrapper>
              <InfoWrapper>
                <TypographyDDS.Paragraph
                  size='para'
                  variant='semiBold'
                  color='textDark'
                >
                  Check Interval
                </TypographyDDS.Paragraph>
                <div style={{ marginTop: '0.75em' }} />
                <TypographyDDS.Title
                  color='textDark'
                  type='h4'
                  variant='bold'
                  style={{ textAlign: 'center' }}
                >
                  {ahcReportsStore.getActiveReport()?.checkInterval +
                    ' min' +
                    (ahcReportsStore.getActiveReport()?.checkInterval === 1
                      ? ''
                      : 's')}
                </TypographyDDS.Title>
              </InfoWrapper>
            </InfoSection>
            <SectionDivider />
            <GraphSection>
              <GraphWrapper>
                <PerformanceGraph
                  responseTimeData={getTimeData('response')}
                  uptimeData={getTimeData('up')}
                  downtimeData={getTimeData('down')}
                  key={ahcReportsStore.getPerformanceGraphKey()}
                />
                <GraphLegendContainer>
                  <LegendWrapper>
                    {/* <UptimeLegend />
                     */}
                    <LegendImg
                      src={Uptime}
                      style={{ filter: palette.colors.lightBack5 }}
                    />

                    <TypographyDDS.Paragraph size='para' variant='medium'>
                      Uptime
                    </TypographyDDS.Paragraph>
                    {/* <ResponseTimeLegend /> */}
                    <LegendImg
                      src={ResponseTime}
                      style={{ filter: palette.colors.lightBack5 }}
                    />

                    <TypographyDDS.Paragraph size='para' variant='medium'>
                      Average Response Time
                    </TypographyDDS.Paragraph>
                    {/* <DowntimeLegend /> */}
                    <LegendImg
                      src={Downtime}
                      style={{ filter: palette.colors.redSvgFilter }}
                    />

                    <TypographyDDS.Paragraph size='para' variant='medium'>
                      Downtime
                    </TypographyDDS.Paragraph>
                  </LegendWrapper>
                  <Spacer />
                  {/* <MetricsWrapper>
                    {' '}
                    <Typography
                      variant='body2'
                      style={{ fontSize: '0.875em' }}
                      fontWeight={palette.fontWeight.light}
                    >
                      Max:{' '}
                      <ResponseTimeWrapper>
                        {' '}
                        {getResponseTimeMeasure('max')} ms
                      </ResponseTimeWrapper>
                    </Typography>
                    <Typography
                      variant='body2'
                      style={{ fontSize: '0.875em' }}
                      fontWeight={palette.fontWeight.light}
                    >
                      Min:{' '}
                      <ResponseTimeWrapper>
                        {getResponseTimeMeasure('min')} ms
                      </ResponseTimeWrapper>
                    </Typography>
                    <Typography
                      variant='body2'
                      style={{ fontSize: '0.875em' }}
                      fontWeight={palette.fontWeight.light}
                    >
                      Avg:{' '}
                      <ResponseTimeWrapper>
                        {' '}
                        {getResponseTimeMeasure('avg')} ms
                      </ResponseTimeWrapper>
                    </Typography>
                  </MetricsWrapper> */}
                </GraphLegendContainer>
              </GraphWrapper>
              <ResponseInfoContainer>
                <TypographyDDS.Paragraph
                  size='para'
                  variant='semiBold'
                  color='textDark'
                >
                  Incidents
                </TypographyDDS.Paragraph>

                <TypographyDDS.Title type='h3' variant='bold' color='primary'>
                  {ahcReportsStore.getActiveReport()?.numberOfIncidents !== 0
                    ? ahcReportsStore.getActiveReport()?.numberOfIncidents
                    : '-'}
                </TypographyDDS.Title>
                <DividerLine width='100%' />
                <TypographyDDS.Paragraph
                  size='para'
                  variant='semiBold'
                  color='textDark'
                >
                  Downtime
                </TypographyDDS.Paragraph>
                <TypographyDDS.Title type='h3' variant='bold'>
                  {!isEmpty(
                    timeMetric(
                      ahcReportsStore.getActiveReport()?.downTime
                        .duration as number,
                      ahcReportsStore.getActiveReport()?.downTime.unit as string
                    )
                  )
                    ? timeMetric(
                        ahcReportsStore.getActiveReport()?.downTime
                          .duration as number,
                        ahcReportsStore.getActiveReport()?.downTime
                          .unit as string
                      )
                    : '-'}
                </TypographyDDS.Title>
                <DividerLine width='100%' />

                <TypographyDDS.Paragraph
                  size='para'
                  variant='semiBold'
                  color='textDark'
                >
                  Avg. response time
                </TypographyDDS.Paragraph>

                <TypographyDDS.Title type='h3' variant='bold'>
                  {!isEmpty(
                    timeMetric(
                      ahcReportsStore.getActiveReport()?.averageResponseTime
                        .duration as number,
                      ahcReportsStore.getActiveReport()?.averageResponseTime
                        .unit as string
                    )
                  )
                    ? timeMetric(
                        ahcReportsStore.getActiveReport()?.averageResponseTime
                          .duration as number,
                        ahcReportsStore.getActiveReport()?.averageResponseTime
                          .unit as string
                      )
                    : '-'}
                </TypographyDDS.Title>
                <DividerLine width='100%' />
                <TypographyDDS.Paragraph
                  size='para'
                  variant='semiBold'
                  color='textDark'
                >
                  Longest downtime
                </TypographyDDS.Paragraph>
                <TypographyDDS.Title type='h3' variant='bold'>
                  {!isEmpty(
                    timeMetric(
                      ahcReportsStore.getActiveReport()?.longestDownTime
                        .duration as number,
                      ahcReportsStore.getActiveReport()?.longestDownTime
                        .unit as string
                    )
                  )
                    ? timeMetric(
                        ahcReportsStore.getActiveReport()?.longestDownTime
                          .duration as number,
                        ahcReportsStore.getActiveReport()?.longestDownTime
                          .unit as string
                      )
                    : '-'}
                </TypographyDDS.Title>
              </ResponseInfoContainer>
            </GraphSection>{' '}
          </>
        )}
      </DashboardSectionContainer>
      <DashboardSectionContainer headerText='Logs' paddingBottom>
        <FilterWrapper>
          <Filter
            options={!isEmpty(filterOptions) ? filterOptions : []}
            disabled={ahcLogsStore.getLoading()}
          />
        </FilterWrapper>
        <IncidentsHistoryTable />
      </DashboardSectionContainer>
    </Container>
  )
}

export default SecureComponent(
  observer(PerformanceMetricsPage),
  AccessRoleConstants.ALL
)
