import { Link, useLocation, useNavigate } from 'react-router-dom'
import React, { useState } from 'react'

import { BsChevronLeft } from 'react-icons/bs'
import NavLink from '../NavLink'
import NotificationInvites from '../../../../utils/CommonUtils/NotificationUtils'
import { observer } from 'mobx-react-lite'
import palette from '../../../../global/pallete'
import routeConstants from '../../../../constants/RouteConstants'
import styled from '@emotion/styled'
import { useStoreContext } from '../../../../store/StoreContext'

const Container = styled.div`
  display: flex;
`
const Wrapper = styled.div`
  padding-top:16px;
  display: flex;
  grid-gap: 20px;
  gap:30px
  width: 100%;
  @media screen and (max-width: 1100px) {
    grid-gap: 10px;
  }

`
const GoBackContainer = styled(Link)(() => ({
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  display: 'flex',
  alignItems: 'center'
}))
const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
const StyledLink = styled.div`
  text-decoration: none;
  font-weight: 500;
  font-size: 0.875em;
  color: ${palette.colors.textDark2};
  line-height: 17px;
  &:hover {
    text-decoration: underline;
  }
`

const IconContainer = styled.div`
  order: 0;
  flex-grow: 1;
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
`

const NotificationBar: React.FC = () => {
  const navigate = useNavigate()
  const store = useStoreContext()
  const [selectedTab, setSelectedTab] = useState<string | undefined>()

  const handleTabClick = (label: string, to: string) => {
    setSelectedTab(label)
    navigate(to)
  }
  const location = useLocation()
    .pathname.split('/')
    .filter((x) => x)
  return (
    <>
      <Container>
        <Wrapper>
          {store.userStore.getOrganizations().length === 0 && (
            <GoBackContainer
              to={routeConstants.NO_ORGANIZATION}
              color='inherit'
              key={location.length - 1}
              style={{ textDecoration: 'none', display: 'flex' }}
            >
              <IconTextContainer>
                <BsChevronLeft
                  style={{
                    marginRight: '16px',
                    color: `${palette.colors.primary}`
                  }}
                />
                <StyledLink style={{ textDecoration: 'none' }}>
                  Go Back
                </StyledLink>
              </IconTextContainer>
            </GoBackContainer>
          )}

          {NotificationInvites().map((link, index) => (
            <NavLink
              icon={link.icon}
              label={link.label}
              key={index}
              isActive={link.to === window.location.pathname ? true : false}
              onClick={() => handleTabClick(link.label, link.to)}
            />
          ))}
        </Wrapper>
      </Container>
    </>
  )
}

export default observer(NotificationBar)
