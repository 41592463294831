import React, { useEffect, useState } from 'react'
import {
  deleteOrganizationMember,
  findAllMembers,
  getRoleFilters
} from '../../api/organization/Organization.service'
import {
  findUserInvites,
  getUserInviteFilters,
  resendUserInvite,
  resendUserProjectInvite,
  revokeUserInvite
} from '../../api/userInvites/UserInvites.service'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import DashboardHeader from '../../components/MemberSettingsPage/DashboardHeader'
import DashboardSectionContainer from '../../components/common/DashboardSectionContainer/DashboardSectionContainer'
import DashboardTopNavbar from '../../components/common/DashboardTopNavbar/DashboardTopNavbar'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../constants/HeaderConstants'
import { IoEllipseSharp } from 'react-icons/io5'
import MemberSettingsPopup from '../../components/MemberSettingsPage/MemberSettingsPopup'
import MembersTable from '../../components/MemberSettingsPage/MembersTable'
import NoData from '../../components/NoData/NoData'
import { OrganizationUserRoles } from '../../enum/OrganizationUserRoles.enum'
import SecureComponent from '../../components/Layout/SecureComponent'
import { SettingBottomBar } from '../../components/common/SettingBottomBar/SettingBottomBar'
import SettingsBar from '../../components/common/DashboardTopNavbar/bottomBars/Settings'
import Spinner from '../../components/common/Spinner/Spinner'
import StringConstants from '../../constants/StringConstants'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../../components/common/DDS/Toast/Toast'
import { UrlWithOrgProjEnvIds } from '../../utils/UrlUtils'
import { getAllQueryParamsAsObjectFromUrl } from '../../utils/UrlUtils'
import { isEmpty } from 'lodash'
import palette from '../../global/pallete'
import routeConstants from '../../constants/RouteConstants'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import { useStoreContext } from '../../store/StoreContext'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const MemberSettingsPage: React.FC = () => {
  const [open, setOpen] = useState(false)
  const [activeRemoveMember, setActiveRemoveMember] = useState<any>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentTab, setCurrentTab] = useState<number>(
    parseInt(searchParams.get('tab') as string) || StringConstants.DEFAULT_TAB
  )
  const [totalCount, setTotalCount] = useState<number>()
  const [loading, setLoading] = useState(false)
  const [dataTabMap, setDataTabMap] = useState<any>([])
  const pageSize = StringConstants.DEFAULT_TABLE_SIZE
  const [updateMembersRole, setUpdateMembersRole] = useState(false)

  const [invitedUser, setInvitedUser] = useState<boolean>(false)
  const [page, setPage] = useState<number>(
    parseInt(searchParams.get('page') as string) || StringConstants.DEFAULT_PAGE
  )
  const store = useStoreContext()
  const [token, setToken] = useState<string>('')
  const [resendInvite, setResendInvite] = useState<boolean>(false)
  const [activeResendInviteMember, setActiveResendInviteMember] =
    useState<any>(null)
  const handleRemoveMember = (member: any) => {
    setToken(member.token)
    setActiveRemoveMember(member)
    setOpen(true)
  }
  const handleResendInvite = (member: any) => {
    setResendInvite(true)
    setActiveResendInviteMember(member)
    setOpen(true)
  }
  const [options, setOptions] = React.useState<any>([])
  const [buttonloading, setButtonLoading] = React.useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.SETTINGS
    )
  }, [])
  useEffect(() => {
    setLoading(true)
    store.filterStore.setFilterDisabled(true)
    setSearchParams(
      {
        page: page.toString(),
        tab: currentTab.toString()
      },
      { replace: true }
    )
    if (currentTab === 0) {
      getRoleFilters()
        .then((resp: any) => {
          setOptions(resp.data)
        })
        .catch((error) => {
          if (error === 'Unauthorized') {
            navigate(routeConstants.UNAUTHORIZED_PAGE)
          }
        })
    } else {
      getUserInviteFilters()
        .then((resp: any) => {
          setOptions(resp.data)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [currentTab])
  useEffect(() => {
    // setLoading(true)
    setDataTabMap([])
    if (currentTab === 0 && !loading) {
      fetchExistingMemberRecords()
    } else if (currentTab === 1 && !loading) {
      fetchInvitedMemberRecords()
    }
  }, [location.search, store.scopeStore.getOrganizationId()])

  const handleConfirmRemove = () => {
    setButtonLoading(true)
    !invitedUser
      ? deleteOrganizationMember(activeRemoveMember.user.id)
          .then(() => {
            ToastNotification({
              type: 'success',
              message:
                ToastMessageConstants.SETTINGS.MEMBERS.DELETE_MEMBERS.SUCCESS
            })
            if (store.userStore.getId() === activeRemoveMember.user.id) {
              navigate(routeConstants.REDIRECT)
              setActiveRemoveMember(null)
              setButtonLoading(false)
              setOpen(false)
            } else {
              setActiveRemoveMember(null)
              fetchExistingMemberRecords()
              setButtonLoading(false)
              setOpen(false)
            }
          })
          .catch((err) => {
            ToastNotification({
              type: 'error',
              message:
                ToastMessageConstants.SETTINGS.MEMBERS.DELETE_MEMBERS.ERROR
            })
            setOpen(false)
            setButtonLoading(false)
            setActiveRemoveMember(null)
            fetchExistingMemberRecords()
          })
      : revokeUserInvite({ $token: token as string })
          .then(() => {
            ToastNotification({
              type: 'success',
              message:
                ToastMessageConstants.SETTINGS.MEMBERS.REVOKE_INVITE.SUCCESS
            })
            setButtonLoading(false)
            setActiveRemoveMember(null)
            fetchInvitedMemberRecords()
            setOpen(false)
          })
          .catch((err) => {
            ToastNotification({
              type: 'error',
              message:
                ToastMessageConstants.SETTINGS.MEMBERS.REVOKE_INVITE.ERROR
            })
            setOpen(false)
            setButtonLoading(false)
            fetchInvitedMemberRecords()
            setActiveRemoveMember(null)
          })
  }
  const handleConfirmResendInvite = () => {
    setButtonLoading(true)
    if (activeResendInviteMember?.project) {
      resendUserProjectInvite({ $id: activeResendInviteMember.id })
        .then(() => {
          ToastNotification({
            type: 'success',
            message:
              ToastMessageConstants.SETTINGS.MEMBERS.RESEND_INVITE.SUCCESS
          })
          fetchInvitedMemberRecords()
          setActiveResendInviteMember(null)
          setResendInvite(false)
          setOpen(false)
          setButtonLoading(false)
        })
        .catch((err) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.SETTINGS.MEMBERS.RESEND_INVITE.ERROR
          })
          setResendInvite(false)
          setOpen(false)
          setButtonLoading(false)
        })
    } else {
      resendUserInvite({ $id: activeResendInviteMember.id })
        .then(() => {
          ToastNotification({
            type: 'success',
            message:
              ToastMessageConstants.SETTINGS.MEMBERS.RESEND_INVITE.SUCCESS
          })
          fetchInvitedMemberRecords()
          setActiveResendInviteMember(null)
          setResendInvite(false)
          setOpen(false)
          setButtonLoading(false)
        })
        .catch((err) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.SETTINGS.MEMBERS.RESEND_INVITE.ERROR
          })
          setResendInvite(false)
          setOpen(false)
          setButtonLoading(false)
        })
    }
  }

  const updatingMemberRole = (flag: boolean) => {
    setUpdateMembersRole(flag)
  }

  function fetchInvitedMemberRecords() {
    setLoading(true)
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    findUserInvites({
      ...queryParams,
      status: 'PENDING',
      size: StringConstants.DEFAULT_TABLE_SIZE
    })
      .then((invitedMembersData: any) => {
        if (
          invitedMembersData.data.length > 0 ||
          store.filterStore.getFiltersApplied()
        ) {
          store.filterStore.setFilterDisabled(false)
        } else {
          store.filterStore.setFilterDisabled(true)
        }
        setDataTabMap(invitedMembersData.data)
        setTotalCount(invitedMembersData.totalCount)
        setInvitedUser(true)
        setLoading(false)
        store.filterStore.setFilters(invitedMembersData.filters)
      })
      .catch((err) => {
        setLoading(false)
        ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.SETTINGS.MEMBERS.FIND_INVITED_MEMBERS.ERROR
        })
      })
      .finally(() => store.uiStore.setGlobalLoader(false))
  }
  function fetchExistingMemberRecords() {
    setLoading(true)
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    findAllMembers({
      ...queryParams,
      'expand-user': true,
      size: StringConstants.DEFAULT_TABLE_SIZE
    })
      .then((membersData: any) => {
        if (
          membersData.data.length > 0 ||
          store.filterStore.getFiltersApplied()
        ) {
          store.filterStore.setFilterDisabled(false)
        } else {
          store.filterStore.setFilterDisabled(true)
        }
        setDataTabMap(membersData.data)
        setTotalCount(membersData.totalCount)
        setInvitedUser(false)
        setLoading(false)
        store.filterStore.setFilters(membersData.filters)
        store.filterStore.setFilterDisabled(false)
      })
      .catch((err) => {
        setLoading(false)
        if (err === 'Unauthorized Exception') {
          navigate(routeConstants.UNAUTHORIZED_PAGE)
        } else {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.SETTINGS.MEMBERS.FIND_MEMBERS.ERROR
          })
        }
      })
      .finally(() => store.uiStore.setGlobalLoader(false))
  }
  return (
    <>
      <DocumentHeader header={HeaderConstants.MEMBER_SETTINGS} />
      {/* <DashboardTopNavbar
        variant='settings'
        breadcrumbs={
          window.location.pathname.split('/').at(-1) !==
          routeConstants.MEMBER_SETTINGS.split('/').at(-1)
        }
        disable={updateMembersRole}
        showBottomBar
      /> */}
      <SettingBottomBar>
        <SettingsBar showButtons={false} />
      </SettingBottomBar>

      <MemberSettingsPopup
        resendInvite={resendInvite}
        invitee={activeRemoveMember?.invitee}
        open={open}
        name={
          resendInvite
            ? activeResendInviteMember?.invitee
            : !invitedUser
            ? activeRemoveMember?.user.firstName +
              ' ' +
              activeRemoveMember?.user.lastName
            : activeRemoveMember?.invitee
        }
        handleClose={() => {
          setResendInvite(false)
          setOpen(false)
        }}
        handleConfirmRemove={handleConfirmRemove}
        handleConfirmResendInvite={handleConfirmResendInvite}
        invitedUser={invitedUser}
        loading={buttonloading || updateMembersRole}
      />

      <Container>
        <DashboardSectionContainer
          customHeader={
            <DashboardHeader
              onTabChange={(number) => {
                setCurrentTab(number)
                setSearchParams(
                  {
                    tab: number.toString()
                  },
                  { replace: true }
                )
                store.filterStore.setFiltersApplied(false)
              }}
              currentTab={currentTab}
              options={options}
              disabled={loading || updateMembersRole}
            />
          }
          noDividerPadding
        >
          {!loading ? (
            dataTabMap.length === 0 ? (
              <NoData
                emptyText={false}
                message={
                  'No members have been invited to this organization yet.'
                }
              />
            ) : (
              <>
                {!isEmpty(dataTabMap) && (
                  <MembersTable
                    invitedUser={invitedUser}
                    data={dataTabMap}
                    handleRemoveMember={handleRemoveMember}
                    handleResendInvite={handleResendInvite}
                    rowsPerPage={pageSize}
                    currentPage={page}
                    onPageChange={(number) => {
                      const queryParams = getAllQueryParamsAsObjectFromUrl(
                        location.search
                      )
                      setSearchParams(
                        {
                          ...queryParams,
                          page: number.toString()
                        },
                        { replace: true }
                      )
                      setPage(number)
                    }}
                    totalCount={totalCount}
                    fetchMembers={fetchExistingMemberRecords}
                    updateMembersRole={updatingMemberRole}
                  />
                )}
              </>
            )
          ) : (
            <Spinner />
          )}
        </DashboardSectionContainer>
      </Container>
    </>
  )
}

export default SecureComponent(
  MemberSettingsPage,
  AccessRoleConstants.MEMBER_LEVEL
)
