import React, { useEffect, useState } from 'react'
import { statusToColor, toTitleCase } from '../../../../utils/JobUtils'

import { ChevronRight } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import { Link } from '@mui/material'
import LinkCard from '../../../../components/OverviewPage/LinkCard'
import NoDataImage from '../../../../components/Overview/NoDataImage'
import NoFilterImage from '../../../../assets/images/folder-not-found 1.svg'
import NoRecord from '../../../../components/common/NoContent/NoRecord'
import NoRecordImage from '../../../../assets/images/setting.svg'
import OverviewGraph from '../../../../components/OverviewPage/OverviewGraph'
import OverviewStringConstants from '../../../../constants/OverviewStringConstants'
import OverviewTable from '../../../../components/OverviewPage/OverviewTable'
import { ProjectType } from '../../../../enum/ProjectType.enum'
import Spinner from '../../../../components/common/Spinner/Spinner'
import StringConstants from '../../../../constants/StringConstants'
import { ToastMessageConstants } from '../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../../components/New/Tooltip/Tooltip'
import ViewCard from '../../../../components/OverviewPage/ViewCard'
import { getJobOverviewAPI } from '../../../../api/overview/Overview.service'
import { isEmpty } from 'lodash'
import { msToTimeUnits } from '../../../../utils/DateTimeUtils'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../global/newPallete'
import { transformIntervalData } from '../../../../utils/OverviewUtils'
import { useParams } from 'react-router-dom'
import { useStoreContext } from '../../../../store/StoreContext'

interface JobsOverviewProps {
  setFindJobsOverview: any
  navigateToJobs: any
  handleViewMore: any
}
const JobsOverview: React.FC<JobsOverviewProps> = ({
  setFindJobsOverview,
  navigateToJobs,
  handleViewMore
}) => {
  const store = useStoreContext()
  const [overviewData, setOverviewData] = useState<any>()
  const [graphTab, setGraphTab] = useState<string>('1')
  const [loading, setLoading] = useState<boolean>(false)
  const { pathIds } = useParams()

  useEffect(() => {
    findJobsOverview()
  }, [store.scopeStore.getScope()])

  useEffect(() => {
    setFindJobsOverview(findJobsOverview)
  }, [setFindJobsOverview])

  const findJobsOverview = () => {
    if (!isEmpty(store.scopeStore.getScope()['environment-id'])) {
      setLoading(true)
      store.uiStore.setGlobalLoader(true)
      getJobOverviewAPI()
        .then((response) => {
          setOverviewData(response.data)
          store.overviewStore.setJobData(response.data)
        })
        .catch((e) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.OVERVIEW.FETCH_JOB.ERROR
          })
        })
        .finally(() => {
          setLoading(false)
          store.uiStore.setGlobalLoader(false)
        })
    }
  }
  const topCardData = [
    {
      id: 1,
      title: OverviewStringConstants.MOST_EXECUTED_JOB,
      content: (
        <Tooltip
          id='most-executed-job-tooltip'
          label={overviewData?.mostExecutedJob?.name}
          className={`max-w-fit ${
            typeof overviewData?.mostExecutedJob?.name === 'string' &&
            overviewData?.mostExecutedJob?.name.length > 28
              ? overviewData?.mostExecutedJob?.name
              : '!hidden'
          }`}
        >
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            className='truncate max-w-[240px]'
            color={pallete.colors.textDark3}
          >
            {overviewData?.mostExecutedJob?.name ?? '-'}
          </DDSTypography.Title>
        </Tooltip>
      ),
      secondaryContent:
        overviewData?.mostExecutedJob &&
        overviewData?.mostExecutedJob != '-' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.textDark2}
          >
            {overviewData?.mostExecutedJob?.numberOfExecutions}
          </DDSTypography.Title>
        ) : null,
      onClick: () =>
        overviewData?.mostExecutedJob?.id &&
        overviewData?.mostExecutedJob?.id != '-'
          ? navigateToJobs({ id: overviewData?.mostExecutedJob?.id })
          : null
    },
    {
      id: 2,
      title: OverviewStringConstants.MOST_FAILED_JOB,
      content: (
        <Tooltip
          id='most-failed-job-tooltip'
          label={overviewData?.mostFailedJob?.name}
          className={`max-w-fit ${
            typeof overviewData?.mostFailedJob?.name === 'string' &&
            overviewData?.mostFailedJob?.name.length > 28
              ? overviewData?.mostFailedJob?.name
              : '!hidden'
          }`}
        >
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            className='truncate max-w-[240px]'
            color={pallete.colors.textDark3}
          >
            {overviewData?.mostFailedJob?.name ?? '-'}
          </DDSTypography.Title>
        </Tooltip>
      ),
      secondaryContent:
        overviewData?.mostFailedJob && overviewData?.mostFailedJob != '-' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.warningRed2}
          >
            {overviewData?.mostFailedJob?.numberOfExecutions}
          </DDSTypography.Title>
        ) : null,
      onClick: () =>
        overviewData?.mostFailedJob?.id &&
        overviewData?.mostFailedJob?.id != '-'
          ? navigateToJobs({ id: overviewData?.mostFailedJob?.id })
          : null
    },
    {
      id: 3,
      title: OverviewStringConstants.SLOWEST_RUN_TIME,
      content: (
        <Tooltip
          id='slowest-run-time-tooltip'
          label={overviewData?.slowestJob?.name}
          className={`max-w-fit ${
            typeof overviewData?.slowestJob?.name === 'string' &&
            overviewData?.slowestJob?.name?.length > 28
              ? overviewData?.slowestJob?.name
              : '!hidden'
          }`}
        >
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.textDark3}
          >
            {overviewData?.slowestJob?.name ?? '-'}
          </DDSTypography.Title>
        </Tooltip>
      ),
      secondaryContent:
        overviewData?.slowestJob && overviewData?.slowestJob != '-' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.textDark2}
          >
            {overviewData?.slowestJob?.runningTimeInMilliSeconds
              ? msToTimeUnits(
                  overviewData?.slowestJob?.runningTimeInMilliSeconds
                )
              : '-'}
          </DDSTypography.Title>
        ) : null,
      onClick: () =>
        overviewData?.slowestJob?.id && overviewData?.slowestJob?.id != '-'
          ? navigateToJobs({ id: overviewData?.slowestJob?.id })
          : null
    }
  ]

  const sideCardData = [
    {
      id: 1,
      title: OverviewStringConstants.TOTAL_JOBS,
      content: (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark3}
        >
          {overviewData?.totalJobs ?? '-'}
        </DDSTypography.Title>
      ),
      onClick: () => navigateToJobs()
    },
    {
      id: 2,
      title: OverviewStringConstants.SUCCESSFUL_JOBS,
      content: (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.warningGreen}
        >
          {overviewData?.successfulJobs ?? '-'}
        </DDSTypography.Title>
      ),
      onClick:
        overviewData?.successfulJobs && overviewData?.successfulJobs !== '-'
          ? () => navigateToJobs({ tab: '2', status: 'SUCCESS' })
          : null
    },
    {
      id: 3,
      title: OverviewStringConstants.FAILED_JOBS,
      content: (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.warningRed2}
        >
          {overviewData?.failedJobs ?? '-'}
        </DDSTypography.Title>
      ),
      onClick:
        overviewData?.failedJobs && overviewData?.failedJobs !== '-'
          ? () => navigateToJobs({ tab: '4', status: 'FAILED' })
          : null
    }
  ]

  const tabOptions = [
    {
      label: (
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={
            graphTab === '1'
              ? pallete.colors.textDark1
              : pallete.colors.textDark5
          }
        >
          {OverviewStringConstants.SUCCESS}
        </DDSTypography.Paragraph>
      )
    },
    {
      label: (
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={
            graphTab === '2'
              ? pallete.colors.textDark1
              : pallete.colors.textDark5
          }
        >
          {OverviewStringConstants.FAILED}
        </DDSTypography.Paragraph>
      )
    }
  ]

  const columns = [
    {
      columnDataStyle: {},
      dataLabel: 'name',
      render: (text: any) => (
        <div className='flex justify-start text-left'>
          <Tooltip
            id={`${text}-tooltip`}
            label={text}
            className={`max-w-fit ${
              typeof text === 'string' && text.length > 120 ? text : '!hidden'
            }`}
          >
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate max-w-[820px]'
              color={pallete.colors.textDark4}
            >
              {text ?? '-'}
            </DDSTypography.Paragraph>
          </Tooltip>
        </div>
      )
    },
    {
      columnDataStyle: { width: '146px' },
      dataLabel: 'status',
      render: (text: any) =>
        text ? (
          <div
            className='flex h-[26px] justify-center rounded-[4px] text-center px-[12px] py-[4px] items-center'
            style={{ backgroundColor: statusToColor(text) }}
          >
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate'
              color={pallete.colors.surface1}
            >
              {toTitleCase(text)}
            </DDSTypography.Paragraph>
          </div>
        ) : (
          <div className='flex justify-center  text-center px-[12px] py-[4px] items-center'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate'
              color={pallete.colors.textDark4}
            >
              {'-'}
            </DDSTypography.Paragraph>
          </div>
        )
    },
    {
      columnDataStyle: { width: '28px' },
      dataLabel: 'id',
      render: (id: any) => (
        <div className='flex justify-center items-center'>
          <ChevronRight
            className='cursor-pointer'
            size={16}
            color={pallete.colors.textDark2}
            onClick={() => navigateToJobs({ id })}
          />
        </div>
      )
    }
  ]
  return loading || !overviewData ? (
    <Spinner />
  ) : overviewData.totalJobs !== 0 ? (
    <div className='flex flex-col gap-[40px]'>
      <div className='flex items-start gap-[32px]'>
        {topCardData.map((item) => (
          <LinkCard
            key={item.id}
            linkText={item.title}
            content={item.content}
            secondaryContent={item.secondaryContent}
            onClick={item.onClick}
          />
        ))}
        <ViewCard
          title={OverviewStringConstants.TOP_10_JOBS}
          onClick={() => handleViewMore('job', 0)}
        />
      </div>
      <div className='flex items-center self-stretch gap-[40px] py-[12px]'>
        <div className='flex flex-col items-start gap-[20px] w-[20%]'>
          {sideCardData.map((item) => (
            <LinkCard
              key={item.id}
              linkText={item.title}
              content={item.content}
              onClick={item.onClick}
            />
          ))}
        </div>
        <div className='w-[80%]'>
          <OverviewGraph
            data={transformIntervalData(overviewData, graphTab, 'jobs')}
            setTab={setGraphTab}
            tab={graphTab}
            tabOptions={tabOptions}
            title={
              graphTab === '1'
                ? OverviewStringConstants.SUCCESSFUL_JOBS
                : OverviewStringConstants.FAILED_JOBS
            }
          />
        </div>
      </div>
      {overviewData?.recentJobs.length > 0 && (
        <div className='w-full'>
          <OverviewTable
            title={OverviewStringConstants.RECENT_JOBS}
            data={overviewData?.recentJobs}
            columns={columns}
          />
        </div>
      )}
    </div>
  ) : (
    <NoRecord
      imageSrc={!overviewData.zeroJobsCount ? NoFilterImage : NoRecordImage}
      text={
        !overviewData.zeroJobsCount ? (
          <>{OverviewStringConstants.NO_RECORDS_FOUND}</>
        ) : (
          <>
            Configure{' '}
            {store.scopeStore.getSelectedProject().type ===
            ProjectType.FULL_STACK_ONLY
              ? StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.SCHEDULED_JOB
              : StringConstants.SIDE_NAV_BAR.SALESFORCE_PROJECTS.APEX_JOB}
            {''} Monitoring.
            <Link
              href={StringConstants.VIGIL_SDK_URL}
              target='_blank'
              rel='noopener noreferrer'
              style={{ marginLeft: '4px' }}
            >
              Learn more
            </Link>{' '}
          </>
        )
      }
    />
  )
}

export default observer(JobsOverview)
