import {
  BtoA,
  UrlWithOnlyOrgId,
  UrlWithOrgProjEnvIds,
  replaceUrl
} from '../../../utils/UrlUtils'
import { ChevronDown, ChevronUp, Settings } from '@carbon/icons-react'
import React, { useEffect, useRef, useState } from 'react'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'

import { DDSMenu } from 'den-design-system'
import { DDSTypography } from 'den-design-system'
import DropdownItem from '../../common/DropDownSideMenu/DropDownItem'
import HttpConstants from '../../../constants/HttpConstants'
import { OrganizationType } from '../../../enum/OrganizationType.enum'
import { OrganizationUserRoles } from '../../../enum/OrganizationUserRoles.enum'
import StringConstants from '../../../constants/StringConstants'
import { addQueryParamsToUrl } from '../../../utils/UrlGenerator'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import palette from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'

interface OrganizationSectionProps {
  isSidebarExpanded: boolean
}

const OrganizationSection: React.FC<OrganizationSectionProps> = ({
  isSidebarExpanded
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [loading, setLoading] = React.useState(false)
  const [selectedOption, setSelectedOption] = useState<number>(0)
  const [selectOption, setSelectOption] = useState<boolean>(false)
  const gaEventTracker = useAnalyticsEventTracker(gaPageConstants.SIDE_NAVBAR)

  const store = useStoreContext()
  const navigate = useNavigate()

  const handleSelectButton = (button: boolean) => {
    setSelectOption(button)
  }

  const handleBillingNavigations = () => {
    navigate(UrlWithOnlyOrgId(routeConstants.SUBSCRIPTION_SETTINGS, store))
  }

  const handleOrganizationClick = (data: any) => {
    store.uiStore.setMenuExpanded(false)
    if (store.scopeStore.getOrganizationId() !== data.id) {
      setSelectedOption(data.id)
      if (
        !window.location.pathname.includes('preferences') &&
        !window.location.pathname.includes('organization')
      ) {
        store.uiStore.setGlobalLoader(true)
      }

      store.projectAndEnvironmentStore.reset()
      store.breadcrumbStore.reset()
      store.scopeStore.setProjectId('')
      store.scopeStore.setEnvironmentId('')
      gaEventTracker(gaEventConstants.SWITCH_ORGANIZATION)
      store.userStore.setSelectedOrganization(data)
      store.userStore.setUserRole(data.membership.role)
      store.scopeStore.setOrganizationId(data.id)
      store.scopeStore.setProjects([])
      store.scopeStore.setEnvironments([])
      store.scopeStore.setSelectedProject({})
      store.scopeStore.setSelectedEnvironment({})
      store.filterStore.setFiltersApplied(false)
      localStorage.setItem(
        StringConstants.LOCAL_STORAGE.CURRENT_PAGE_DETAILS,
        JSON.stringify({
          'organization-id': data.id,
          'project-id': '',
          'environment-id': ''
        })
      )
      if (
        !['/members', '/allNotifications', '/allInvites'].some((route) =>
          window.location.pathname.includes(route)
        ) &&
        data.projectCount === 0
      ) {
        localStorage.setItem(
          StringConstants.ACTIVE_TAB,
          StringConstants.TAB_VALUES.OVERVIEW
        )
        store.uiStore.setGlobalLoader(false)
        store.uiStore.setprojectEnvironmentLoader(false)
        navigate(UrlWithOnlyOrgId(routeConstants.NO_PROJECT, store))
      }
      if (
        window.location.pathname.includes(routeConstants.NOTIFICATIONS) ||
        window.location.pathname.includes(routeConstants.INVITES)
      ) {
        navigate(UrlWithOrgProjEnvIds(routeConstants.OVERVIEW, store))
      }
      let url = ''
      url =
        '/' +
        (['account', 'preferences'].includes(
          window.location.pathname.split('/')[2]
        )
          ? window.location.pathname.split('/')[1]
          : BtoA(store.scopeStore.getOrganizationId() as string)) +
        '/'

      url +=
        (window.location.pathname.includes('apiMonitoring') &&
          data.type != OrganizationType.FULL_STACK_ONLY) ||
        (window.location.pathname.includes('salesforce-audit') &&
          data.type != OrganizationType.SALESFORCE_ECOSYSTEM_ONLY) ||
        (data.projectCount != 0 &&
          window.location.pathname.split('/')[2] === 'noProject')
          ? 'overview'
          : window.location.pathname.split('/')[2]

      if (window.location.pathname.split('/')[2] === 'settings') {
        url += '/' + window.location.pathname.split('/')[3]
      }
      if (
        window.location.pathname.split('/')[3] !== 'organization' &&
        !['account', 'preferences'].includes(
          window.location.pathname.split('/')[2]
        ) &&
        !['billing'].includes(window.location.pathname.split('/')[3])
      ) {
        store.userStore.getUserRole() === OrganizationUserRoles.BILLING
          ? handleBillingNavigations()
          : navigate(url)
      } else if (['billing'].includes(window.location.pathname.split('/')[3])) {
        store.userStore.getUserRole() === OrganizationUserRoles.MEMBER
          ? navigate(UrlWithOrgProjEnvIds(routeConstants.OVERVIEW, store))
          : navigate(
              addQueryParamsToUrl(
                routeConstants.REDIRECT,
                {
                  redirect: url
                },
                HttpConstants.GET_METHOD
              )
            )
      } else if (window.location.pathname.includes('organization')) {
        navigate(
          addQueryParamsToUrl(
            routeConstants.REDIRECT,
            {
              redirect: UrlWithOnlyOrgId(
                routeConstants.ORGANIZATION_SETTINGS,
                store
              )
            },
            HttpConstants.GET_METHOD
          )
        )
      } else {
        navigate(url)
      }
    }
    setDropdownOpen(false)
    handleSelectButton(false)
  }

  const handleToggle = () => {
    setDropdownOpen((prevState) => {
      const newState = !prevState

      return newState
    })
  }
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        handleSelectButton(false)
        store.uiStore.setMenuExpanded(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const navigateToOrganizationSettings = () => {
    store.uiStore.setMenuExpanded(false)
    if (!loading) {
      const organizationId = store.scopeStore.getScope()[
        'organization-id'
      ] as string
      localStorage.setItem(
        StringConstants.ACTIVE_TAB,
        StringConstants.TAB_VALUES.SETTINGS
      )
      store.uiStore.setMenuExpanded(false)
      setDropdownOpen(false)
      navigate(replaceUrl(routeConstants.ORGANIZATION_SETTINGS, organizationId))
    }
  }
  const OrganizationMenu = (
    <div
      className='override-dds-menu-wrapper  select-none'
      id='organization-menu'
    >
      <DDSMenu
        open={isDropdownOpen}
        parentId='organization-menu'
        onClose={() => {
          setDropdownOpen(false)
        }}
        setOpen={setDropdownOpen}
        className='!shadow-selectMenu !override-dds-menu-wrapper'
        style={{
          maxWidth: '300px'
        }}
      >
        <div className='overflow-y-auto custom-scrollbar max-h-[170px] flex flex-col'>
          {store.userStore.getOrganizations().map((data) => (
            <div
              key={data.id}
              className='flex items-center px-[12px] py-[8px] rounded cursor-pointer'
              style={{
                backgroundColor:
                  store.scopeStore.getOrganizationId() === data.id
                    ? palette.colors.primary2
                    : ''
              }}
              onClick={() => handleOrganizationClick(data)}
            >
              <div className='flex items-center justify-between w-full'>
                <div
                  className='flex-shrink-0 flex items-center justify-center  !rounded-full w-[28px] h-[28px] border overflow-hidden'
                  style={{ borderColor: palette.colors.stroke2 }}
                >
                  <img
                    src={data?.avatar}
                    alt='User Icon'
                    className='w-[28px] h-[28px] object-fill '
                  />
                </div>
                <div className='!w-[180px]'>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='semiBold'
                    color={palette.colors.textDark1}
                    className='truncate max-w-[180px] text-left pr-[12px] pl-[8px]'
                  >
                    {data.name}
                  </DDSTypography.Paragraph>
                </div>
                <div className='flex-shrink-0'>
                  {/* <Settings
                    color={palette.colors.white}
                    className='w-[16px] h-[16px]'
                    onClick={() => {
                      setDropdownOpen(false)
                      navigateToOrganizationSettings()
                    }}
                  /> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </DDSMenu>
    </div>
  )
  const selectedOrganization = store.userStore.getSelectedOrganization()

  return (
    <>
      <div
        ref={dropdownRef}
        className='relative inline-block pb-[8px] text-center '
        style={
          store.uiStore.getGlobalLoader()
            ? { cursor: 'not-allowed' }
            : { cursor: 'pointer' }
        }
      >
        {isSidebarExpanded ? (
          <>
            <div
              ref={dropdownRef}
              className='pt-[4px] pb-[8px] cursor-pointer select-none'
            >
              <DropdownItem
                key={selectedOrganization.id}
                logo={store.userStore.getSelectedOrganization()?.avatar}
                title={store.userStore.getSelectedOrganization()?.name}
                organizationClick={handleOrganizationClick}
                settingClick={navigateToOrganizationSettings}
                handleSelectButton={() => {
                  handleSelectButton(false)
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div
              ref={dropdownRef}
              onClick={(e) => {
                if (store.uiStore.getGlobalLoader()) {
                  e.preventDefault()
                }
                handleToggle()
                setDropdownOpen(!isDropdownOpen)
                handleSelectButton(true)
                handleSelectButton(!selectOption)
                e.stopPropagation()
              }}
              style={{
                backgroundColor: isDropdownOpen
                  ? palette.colors.darkPrimary
                  : 'transparent',
                ...(store.uiStore.getGlobalLoader()
                  ? { pointerEvents: 'none' }
                  : {})
              }}
            >
              <div
                id='organization-menu'
                className='focus:outline-none select select-none px-[20px] py-[8px] cursor-pointer flex items-center relative justify-center h-full w-full bg-transparent rounded'
              >
                <div className='flex items-center gap-[12px]  justify-center'>
                  <img
                    src={store.userStore.getSelectedOrganization().avatar}
                    id='organization-menu'
                    alt='User Image'
                    className='flex w-[28px] items-center justify-center cursor-pointer h-[28px] rounded-full border border-white object-fill'
                  />
                  {!isDropdownOpen ? (
                    <div>
                      <ChevronDown className='w-[16px] h-[16px] cursor-pointer' />
                    </div>
                  ) : (
                    <div>
                      <ChevronUp className='w-[16px] h-[16px] cursor-pointer' />
                    </div>
                  )}
                </div>

                {isDropdownOpen && (
                  <div
                    id='organization-menu'
                    className='flex absolute -left-[9%] ml-[16px] top-[48px]  !w-[300px] z-[9999999] max-w-[300px]'
                  >
                    {OrganizationMenu}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default observer(OrganizationSection)
