import React from 'react'
import Typography from '../Typography/Typography'
import { TypographyDDS } from '../Typography/TypographyDDS'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

interface NavLinkProps extends NavLinkContainerProps {
  icon?: React.ReactNode
  label?: string
  // isActive?: boolean
  onClick?: () => void
  disabled?: boolean
}

interface NavLinkContainerProps {
  isActive?: boolean
  disabled?: boolean
}

const NavLinkContainer = styled.div<NavLinkContainerProps>`
  padding-left: 20px;
  border-left: 1px solid ${palette.colors.borderColor};
  align-self: center;
  ${({ disabled }) => (disabled ? 'cursor: not-allowed;' : 'cursor: pointer;')}

  display: flex;
  align-items: center;
  &:hover {
    span {
      filter: ${palette.colors.primarySvgFilter};
    }
    * {
      color: ${palette.colors.textDark};
      font-weight: ${(props) =>
        props.isActive
          ? palette.fontWeight.semiBold
          : palette.fontWeight.medium};
    }
  }

  //Icon is span
  span {
    color: ${(props) =>
      props.isActive ? palette.colors.primary : palette.colors.textDark3};
    ${({ isActive }) =>
      isActive && `filter: ${palette.colors.primarySvgFilter}`}
  }

  @media screen and (max-width: 1220px) {
    padding-left: 10px;
  }
`
const IconWrapper = styled.span<NavLinkProps>`
  font-size: 20px;
  padding-right: 5px;
  width: 23px;
  height: 18px;
`

const NavLink: React.FC<NavLinkProps> = ({
  icon,
  label,
  isActive = false,
  onClick,
  disabled = false
}) => {
  return (
    <NavLinkContainer
      onClick={() => {
        !disabled && onClick && onClick()
      }}
      isActive={isActive}
      disabled={disabled}
    >
      <IconWrapper>{icon}</IconWrapper>

      <TypographyDDS.Paragraph
        size='para'
        style={{
          marginBottom: '2px',
          display: 'flex',
          alignItems: 'center'
        }}
        color={isActive ? 'textDark' : 'textDark2'}
        variant={isActive ? 'semiBold' : 'medium'}
      >
        {label}
      </TypographyDDS.Paragraph>
    </NavLinkContainer>
  )
}

export default NavLink
