import { AtoB, getAllQueryParamsAsObjectFromUrl } from '../../utils/UrlUtils'
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom'
import React, { useEffect } from 'react'

import AHCDashboardPage from './AHCDashboardPage/AHCDashboardPage'
import AHCPageLayout from './AHCPageLayout'
import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import { EventBus } from '../../eventBus/EventBus'
import PerformanceMetricsPage from './PerformanceMetricsPage/PerformanceMetricsPage'
import SecureComponent from '../../components/Layout/SecureComponent'
import StringConstants from '../../constants/StringConstants'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../../components/common/DDS/Toast/Toast'
import { observer } from 'mobx-react-lite'
import { useStoreContext } from '../../store/StoreContext'

const AHCPageRouter: React.FC = () => {
  const { ahcReportsStore, ahcStore, ahcSummaryStore, scopeStore, uiStore } =
    useStoreContext()
  const [queryParams, setQueryParams] = useSearchParams()

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.UPTIME_CHECKS
    )
    EventBus.getInstance().register('fetch-health-check-reports', async () => {
      const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
      if (
        AtoB(location.pathname.split('/')[1] as string).split('/')[2] ===
        scopeStore.getScope()['environment-id']
      ) {
        ahcSummaryStore.getSummaryFromApi()
        if (window.location.pathname.split('/').at(-1) === 'dashboard') {
          ahcReportsStore.getReportsFromApi(queryParams).catch((error) => {
            if (error) {
              ToastNotification({
                type: 'error',
                message: ToastMessageConstants.AHC.FETCH_REPORTS.ERROR
              })
              delete queryParams['created-from']
              delete queryParams['created-to']
              setQueryParams(queryParams)
            }
          })
        }
      }
    })
    return () => EventBus.getInstance().unregister('fetch-health-check-reports')
  }, [])

  useEffect(() => {
    if (window.location.pathname.split('/').at(-1) === 'dashboard') {
      EventBus.getInstance().dispatch<string>('fetch-health-check-reports')
    }
  }, [location.search, scopeStore.getSelectedEnvironment(), location.pathname])

  useEffect(() => {
    const error = ahcStore.getError()
    if (error)
      ToastNotification({
        type: 'error',
        message: ToastMessageConstants.AHC.FETCH_CHECKS.ERROR
      })
  }, [ahcStore.getError()])

  useEffect(() => {
    const error = ahcSummaryStore.getError()
    if (error)
      ToastNotification({
        type: 'error',
        message: ToastMessageConstants.AHC.FETCH_SUMMARY.ERROR
      })
  }, [ahcSummaryStore.getError()])

  return (
    <Routes>
      <Route element={<AHCPageLayout />}>
        <Route index element={<Navigate to='dashboard' />} />
        <Route path='dashboard' element={<AHCDashboardPage />} />
        <Route path='dashboard/:ahcId' element={<PerformanceMetricsPage />} />
        {/* <Route path='status' element={<AHCStatusPage />} /> */}
      </Route>
    </Routes>
  )
}

export default SecureComponent(
  observer(AHCPageRouter),
  AccessRoleConstants.MEMBER_LEVEL
)
