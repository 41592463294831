import {
  BsChevronDown as DownArrow,
  BsReply as ReplyIcon,
  BsChevronUp as UpArrow
} from 'react-icons/bs'
import React, { useEffect, useState } from 'react'
import {
  addComment,
  deleteComment,
  updateComment
} from '../../api/incident/incident.service'

import Button from '../common/Button/Button'
import DeletePopup from '../JobMonitoringPage/DeleteLogPopup/DeletePopup'
import DividerLine from '../common/DividerLine/DividerLine'
import IncidentReplySection from './IncidentReplySection'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../common/DDS/Toast/Toast'
import breakpoints from '../../global/breakpoints'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import palette from '../../global/pallete'
import styled from '@emotion/styled'
import { timeFormats } from '../../enum/TIME'
import { timeZone } from '../../utils/DateTimeUtils'
import { useParams } from 'react-router-dom'
import { useStoreContext } from '../../store/StoreContext'
import userIcon from '../../assets/icons/userIcon.svg'

interface CommentSectionProps {
  userName?: string
  date?: string
  comment: string | number
  commentId: string
  avatar: string
  email?: string
  replies?: {
    userName: string
    reply: string
    date: string
    avatar: string
    id: string
    email: string
  }[]
  fetchAllActivities?: any
  addLoading?: any
}

interface ParaProps {
  padding?: string
  lineHeight?: number
  fontSize?: string
}

interface IconTextProps {
  color?: string
  size?: string
  gridGap?: string
}

const Container = styled.div`
  width: 100%;
`

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 12px 0px;
`

const Comment = styled.div`
  margin: 0 0 0 2.5em;
`

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0px;
`

const BottomLeft = styled.div`
  display: flex;
  grid-gap: 10px;
  align-items: center;
  cursor: default;
`

const BottomRight = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 15px;
  cursor: default;
  @media screen and (max-width: ${breakpoints.lg}) {
    margin-left: 1em;
  }
`
const ViewReplySection = styled.div`
  border-left: 1px solid ${palette.colors.borderColor};
  padding: 0px 20px 0px 10px;
  margin-top: 20px;
  margin-left: 20px;
  textarea {
    outline: none;
    width: 100%;
    font-size: 14px;
    border: none;
    resize: none;
    padding: 8px 8px;
    background-color: ${palette.colors.lightBack};
    border: ${palette.colors.borderColor};
    border-radius: 8px;
  }
`

const ReplyTextArea = styled.div`
  margin-left: -10px;
  padding: 8px 8px;
  display: flex;
  grid-gap: 10px;
  align-items: center;
  background-color: ${palette.colors.lightBack};
`

const IconText = styled.div<IconTextProps>`
  display: flex;
  align-items: center;
  grid-gap: ${(props) => props.gridGap};
  font-size: ${(props) => props.size};
  color: ${(props) => props.color};
`

const ParaLight = styled.p<ParaProps>`
  padding: ${(props) => props.padding};
  line-height: ${(props) => props.lineHeight};
  font-weight: 500;
  font-size: ${(props) => props.fontSize};
  color: ${palette.colors.textDark2};
`
const ParaDark = styled.div<ParaProps>`
  padding: ${(props) => props.padding};
  line-height: ${(props) => props.lineHeight};
  font-weight: 500;
  font-size: ${(props) => props.fontSize};
  color: ${palette.colors.textDark};
`
const PostCommentSection = styled.div`
  background-color: ${palette.colors.lightBack};
  border: 1px solid ${palette.colors.borderColor};
  border-radius: 8px;
  padding: 8px 8px;
  textarea {
    outline: none;
    width: 100%;
    font-size: 16px;
    border: none;
    resize: none;
    padding: 8px 0px;
    background-color: ${palette.colors.lightBack};
  }
`
const RoundedImage = styled.img`
  border-radius: 50%;
  border: 1px solid ${palette.colors.borderColor};
`
const PostCommentSectionBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
`

const IncidentCommentSection: React.FC<CommentSectionProps> = ({
  userName = 'Anonymous',
  date = 'dd/mm/yyyy',
  avatar = userIcon,
  replies = [],
  ...props
}) => {
  const { exceptionLogId, incidentId } = useParams()
  const [commentDeleteLoading, setCommentDeleteLoading] = useState(false)
  const [deleteCommentPopupOpen, setDeleteCommentPopupOpen] = useState(false)

  const [editComment, setEditComment] = useState(false)
  const [editCommentData, setEditCommentData] = useState(props.comment)
  const [commentDeleted, setCommentDeleted] = useState(false)
  const [replySectionOpen, setReplySectionOpen] = React.useState(false)
  const [replyData, setReplyData] = useState('')
  const store = useStoreContext()

  const deleteCommentOfIncident = (commentId: string) => {
    setCommentDeleteLoading(true)
    deleteComment({
      $exceptionLogId: exceptionLogId,
      $incidentId: incidentId,
      $commentId: commentId
    })
      .then(() => {
        setCommentDeleted(true)
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.EXCEPTIONS.DELETE_COMMENT.SUCCESS
        })
        props.fetchAllActivities()
      })
      .catch((err: any) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.EXCEPTIONS.DELETE_COMMENT.ERROR
        })
      })
      .finally(() => {
        setCommentDeleteLoading(false)
      })
  }

  const updateCommentForIncident = (commentId: string) => {
    props.addLoading(true)
    updateComment({
      comment: editCommentData as string,
      $exceptionLogId: exceptionLogId,
      $incidentId: incidentId,
      $commentId: commentId
    })
      .then(() => {
        setEditComment(!editComment)
        props.fetchAllActivities()
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.EXCEPTIONS.UPDATE_A_COMMENT.SUCCESS
        })
      })
      .catch((err: any) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.EXCEPTIONS.UPDATE_A_COMMENT.ERROR
        })
      })
  }
  const addReplyForIncident = (threadId: string) => {
    props.addLoading(true)
    addComment({
      threadId: threadId,
      comment: replyData,
      $exceptionLogId: exceptionLogId,
      $incidentId: incidentId
    })
      .then(() => {
        props.fetchAllActivities()
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.EXCEPTIONS.ADD_A_REPLY.SUCCESS
        })
      })
      .catch((err: any) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.EXCEPTIONS.ADD_A_REPLY.ERROR
        })
      })
  }
  useEffect(() => {
    if (props.comment === 'Comment Deleted') {
      setCommentDeleted(true)
    } else {
      setCommentDeleted(false)
    }
  }, [props.comment])

  return (
    <Container>
      <DeletePopup
        variant='single'
        open={deleteCommentPopupOpen}
        handleClose={() => {
          setDeleteCommentPopupOpen(false)
        }}
        onDelete={() => deleteCommentOfIncident(props.commentId)}
        type='Comment'
        loading={commentDeleteLoading}
      />
      <TopSection>
        <IconText gridGap='5px'>
          <RoundedImage
            style={{ objectFit: 'fill', height: '32px', width: '32px' }}
            src={avatar}
          />
          <ParaDark>{userName}</ParaDark>
        </IconText>
        <ParaLight fontSize='12px'>
          Posted on{' '}
          {timeZone(
            store.exceptionIncidentStore.getIsPublic()
              ? Intl.DateTimeFormat().resolvedOptions().timeZone
              : store.userStore.getUserPreference().timeZone,
            date,
            timeFormats.monthDateYear
          )}
        </ParaLight>
      </TopSection>
      <Comment>
        {editComment ? (
          <PostCommentSection>
            <textarea
              rows={2}
              onChange={(e: any) => setEditCommentData(e.target.value)}
            >
              {editCommentData}
            </textarea>
            <DividerLine margin='10px 0px' />
            <PostCommentSectionBottom>
              <Button
                variant='contained'
                boxShadow={false}
                onClick={() => {
                  if (!isEmpty(editCommentData)) {
                    updateCommentForIncident(props.commentId)
                  } else {
                    ToastNotification({
                      type: 'error',
                      message:
                        ToastMessageConstants.EXCEPTIONS.UPDATE_A_COMMENT.ERROR
                    })
                  }
                }}
              >
                Update Comment
              </Button>
            </PostCommentSectionBottom>
          </PostCommentSection>
        ) : (
          <ParaLight
            lineHeight={1.6}
            style={{
              fontStyle: props.comment === 'Comment Deleted' ? 'italic' : ''
            }}
            fontSize='14px'
          >
            <div
              style={{
                display: ' flex',
                maxWidth: '350px',
                wordBreak: 'break-all',
                whiteSpace: 'pre-wrap'
              }}
            >
              {props.comment}
            </div>
          </ParaLight>
        )}
      </Comment>

      <BottomSection>
        <BottomLeft
          onClick={() => {
            setReplySectionOpen(!replySectionOpen)
          }}
        >
          {replies.length !== 0 && (
            <>
              <ParaLight
                fontSize='12px'
                style={{
                  color: replySectionOpen
                    ? palette.colors.primary
                    : palette.colors.textDark2,
                  cursor: 'pointer'
                }}
              >
                View {replies.length}{' '}
                {replies.length === 1 ? 'reply' : 'replies'}
              </ParaLight>
              {!replySectionOpen && <DownArrow style={{ fontSize: '12px' }} />}
              {replySectionOpen && (
                <UpArrow
                  style={{
                    fontSize: '12px',
                    color: `${palette.colors.primary}`
                  }}
                />
              )}
            </>
          )}
        </BottomLeft>
        <BottomRight>
          {!store.exceptionIncidentStore.getIsPublic() &&
            (!commentDeleted ? (
              <IconText
                gridGap='5px'
                onClick={() => {
                  setReplySectionOpen(!replySectionOpen)
                }}
              >
                <ReplyIcon
                  style={{
                    color: replySectionOpen
                      ? `${palette.colors.primary}`
                      : `${palette.colors.textDark2}`,
                    fontSize: '20px',
                    cursor: 'pointer'
                  }}
                />
                <ParaLight
                  style={{
                    color: replySectionOpen
                      ? `${palette.colors.primary}`
                      : `${palette.colors.textDark2}`,
                    cursor: 'pointer'
                  }}
                  fontSize='12px'
                >
                  Reply
                </ParaLight>
              </IconText>
            ) : (
              ' '
            ))}

          {!commentDeleted && store.userStore.getEmail() === props.email && (
            <ParaLight
              fontSize='12px'
              onClick={() => {
                // gaEventTracker(gaEventConstants.EXCEPTION_ACTIVITY_EDIT)
                setEditComment(!editComment)
              }}
              style={{ cursor: 'pointer' }}
            >
              {editComment ? 'Cancel' : 'Edit'}
            </ParaLight>
          )}
          {/* Add Auth verification, for delete, only own comments can be deleted */}
          {!commentDeleted && store.userStore.getEmail() === props.email && (
            <ParaLight
              fontSize='12px'
              onClick={() => {
                // gaEventTracker(gaEventConstants.EXCEPTION_ACTIVITY_DELETE)
                setDeleteCommentPopupOpen(true)
              }}
              style={{ cursor: 'pointer' }}
            >
              Delete
            </ParaLight>
          )}
        </BottomRight>
      </BottomSection>

      {replySectionOpen && (
        <ViewReplySection>
          {replies.length > 0 &&
            replies.map((reply, index) => (
              <>
                <IncidentReplySection
                  key={index}
                  reply={reply.reply}
                  date={reply.date}
                  userName={reply.userName}
                  avatar={reply.avatar}
                  replyId={reply.id}
                  fetchActivities={props.fetchAllActivities}
                  addLoading={props.addLoading}
                  email={reply.email}
                />
              </>
            ))}
          {!commentDeleted && !store.exceptionIncidentStore.getIsPublic() && (
            <>
              <DividerLine margin='10px -10px 0px -10px' />
              <ReplyTextArea>
                <textarea
                  placeholder='Post a reply'
                  rows={2}
                  onChange={(e: any) => setReplyData(e.target.value)}
                />
                <span>
                  <Button
                    variant='contained'
                    boxShadow={false}
                    width='140px'
                    startIcon={<ReplyIcon />}
                    onClick={() => {
                      addReplyForIncident(props.commentId)
                    }}
                    disabled={isEmpty(replyData)}
                  >
                    Reply
                  </Button>
                </span>
              </ReplyTextArea>
            </>
          )}
        </ViewReplySection>
      )}
    </Container>
  )
}

export default observer(IncidentCommentSection)
