import React, { useEffect, useState } from 'react'

import { AddFilled } from '@carbon/icons-react'
import Chip from '../../New/Chip/Chip'
import { DDSTypography } from 'den-design-system'
import DropdownMenu from '../../New/DropdownMenu/DropdownMenu'
import Search from '../../New/Search/Search'
import UserAvatar from '../UserAvatar/UserAvatar'
import emptyAvatar from '../../../assets/icons/emptyAvatar.svg'
import pallete from '../../../global/newPallete'

interface PriorityDropDownProps {
  id?: string
  label?: string
  editable?: boolean
  maxHeight?: string
  userOptions?: any[]
  menuPosition?: string
  arrowPosition?: 'left' | 'right' | 'center'
  selectedUsers?: any
  setSelectedUsers?: any
  disableNoSubscription?: boolean
}
// TODO, users will come from backend and may be passed as prop to this component
const users = [
  {
    id: 1,
    name: 'Webb',
    role: 'Developer',
    imgUrl:
      'https://www.shutterstock.com/image-photo/portrait-attractive-cheerful-skilled-girl-600nw-2091362674.jpg'
  },
  {
    id: 2,
    name: 'Brooklyn Simmons',
    role: 'Developer',
    imgUrl:
      'https://www.shutterstock.com/image-photo/portrait-attractive-cheerful-skilled-girl-600nw-2091362674.jpg'
  },
  {
    id: 3,
    name: 'Eleanor Pena',
    role: 'Developer',
    imgUrl:
      'https://www.shutterstock.com/image-photo/portrait-attractive-cheerful-skilled-girl-600nw-2091362674.jpg'
  },
  {
    id: 4,
    name: 'Ralph Edwards1 ',
    role: 'Developer',
    imgUrl:
      'https://www.shutterstock.com/image-photo/portrait-attractive-cheerful-skilled-girl-600nw-2091362674.jpg'
  },
  {
    id: 5,
    name: 'Ralph Edwards 2',
    role: 'Developer',
    imgUrl:
      'https://www.shutterstock.com/image-photo/portrait-attractive-cheerful-skilled-girl-600nw-2091362674.jpg'
  },
  {
    id: 6,
    name: 'Ralph Edwards 3',
    role: 'Developer',
    imgUrl:
      'https://www.shutterstock.com/image-photo/portrait-attractive-cheerful-skilled-girl-600nw-2091362674.jpg'
  }
]
const toTitleCase = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
const AssignedToMenuList: React.FC<PriorityDropDownProps> = ({
  id,
  label,
  maxHeight,
  editable = true,
  userOptions = [],
  selectedUsers = [],
  setSelectedUsers,
  menuPosition = 'right',
  arrowPosition = 'center',
  disableNoSubscription = false
}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [allUsers, setAllUsers] = useState<any>([])
  const [menuTop, setMenuTop] = useState<boolean>(false)
  useEffect(() => {
    setAllUsers(userOptions)
  }, [userOptions])

  //To get the position of the menu based on prop
  const getMenuPosition = () => {
    if (menuPosition === 'left') {
      return '-translate-x-[85%]'
    } else if (menuPosition === 'center') {
      return '-translate-x-[40%]'
    } else if (menuPosition === 'right') {
      return ''
    }
  }
  //TODO, the below array of object is converted from above selectedUsers array for rendering the user avatars
  const avatarObjects: { name: string; avatar: string }[] = []
  if (users && users.length > 0) {
    selectedUsers.forEach((assignedMember: any) => {
      const avatarObject = {
        name: assignedMember.name,
        avatar: assignedMember.imgUrl
      }
      avatarObjects.push(avatarObject)
    })
  }

  const removeSelectedUser = (itemId: string) => {
    const selectedUsers_ = selectedUsers.filter(
      (user: any) => user.id !== itemId
    )
    setSelectedUsers([...selectedUsers_])
  }

  const dropDownRef = React.useRef<HTMLDivElement>(null)
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropDownRef.current &&
      !dropDownRef.current.contains(event.target as Node)
    ) {
      setMenuOpen(false)
    }
  }

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('click', handleClickOutside)
    } else {
      document.removeEventListener('click', handleClickOutside)
    }

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [menuOpen])

  const toggleMenu = () => {
    if (dropDownRef.current) {
      const rect = dropDownRef.current.getBoundingClientRect()
      const spaceBelow = window.innerHeight - rect.bottom
      const spaceAbove = rect.top

      if (spaceBelow < 200 && spaceAbove > 200) {
        setMenuTop(true)
      } else {
        setMenuTop(false)
      }
    }
    setMenuOpen(!menuOpen)
  }
  return (
    <div ref={dropDownRef} className='relative h-full w-full'>
      <div
        id={`menu-list-btn${id && '-' + id}`}
        onClick={toggleMenu}
        className='flex items-center gap-[4px] justify-start cursor-pointer'
      >
        {selectedUsers.length === 0 && (
          <div>
            <img
              id={`menu-list-btn${id && '-' + id}`}
              src={emptyAvatar}
              onClick={() => setMenuOpen(!menuOpen)}
              className='h-[27.44px] w-[27.44px]'
            />
          </div>
        )}
        <div>
          <UserAvatar
            id={`menu-list-btn${id && '-' + id}`}
            avatarType='Multiple'
            imageObjectGroup={avatarObjects}
          />
        </div>
        <div>
          {editable && (
            <AddFilled
              id={`menu-list-btn${id && '-' + id}`}
              size={16}
              onClick={() => setMenuOpen(!menuOpen)}
              color={pallete.colors.textDark2}
            />
          )}
        </div>
      </div>
      <DropdownMenu
        id='uc-menu-list'
        open={menuOpen}
        parentId={`menu-list-btn${id && '-' + id}`}
        arrowPosition={arrowPosition}
        showArrow
        arrowTop={menuTop ? false : true}
        className={` ${disableNoSubscription && 'cursor-not-allowed pointer-events-none'}  absolute z-50 ${getMenuPosition()} ${menuTop ? 'bottom-[27px]' : ''}`}
        onClose={() => setMenuOpen(false)}
      >
        <div className='flex flex-col gap-[8px] '>
          {selectedUsers.length !== 0 && (
            <div className='flex flex-col max-h-[85px] gap-[6px]  overflow-y-auto'>
              {selectedUsers.map((item: any, index: any) => (
                <Chip
                  id='chip-menu-list'
                  key={index}
                  label={item.name}
                  startIcon={
                    <div className='rounded-full h-[16px] w-[16px]'>
                      <img
                        src={item.imgUrl}
                        className='w-full h-full rounded-full object-fill'
                      />
                    </div>
                  }
                  onClose={() => removeSelectedUser(item.id)}
                />
              ))}
            </div>
          )}
          <Search
            id='search'
            name='Search'
            placeholder='Search'
            onChange={(e) => {
              const filteredUsers = allUsers.filter((user: any) =>
                user.name.toLowerCase().includes(e.target.value.toLowerCase())
              )
              if (e.target.value) {
                setAllUsers([...filteredUsers])
              } else {
                setAllUsers(userOptions)
              }
            }}
          />
          <div
            className={`flex flex-col gap-[8px] max-h-[${maxHeight ?? '168px'}] overflow-y-auto`}
          >
            {allUsers.map((item: any, index: any) => (
              <div
                key={item.id}
                className={`flex rounded-md menu-list-hover w-full cursor-pointer items-center gap-[8px] p-[8px]`}
                onClick={() => {
                  const present = selectedUsers.filter(
                    (user: any) => item.id == user.id
                  )
                  if (present) {
                    setSelectedUsers([item, ...selectedUsers])
                  }
                }}
              >
                <img
                  src={item.imgUrl}
                  className='w-[20px] h-[20px] rounded-full object-fill'
                />
                <div className='flex justify-start gap-[8px] items-center'>
                  <DDSTypography.Paragraph
                    className='whitespace-nowrap'
                    variant='medium'
                    size='para'
                    color={pallete.colors.textDark4}
                  >
                    {item.name}
                  </DDSTypography.Paragraph>
                  <DDSTypography.Paragraph
                    variant='regular'
                    size='caption'
                    className='flex items-center'
                    color={pallete.colors.textDark9}
                  >
                    ({toTitleCase(item.role)})
                  </DDSTypography.Paragraph>
                </div>
              </div>
            ))}
          </div>
        </div>
      </DropdownMenu>
    </div>
  )
}

export default AssignedToMenuList
