import { Typography as MuiTypography } from '@mui/material'
import React from 'react'
import palette from '../../../global/pallete'

type styleType = Record<string, string>

interface TypographyProps {
  children?: React.ReactNode
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit'
    | undefined
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right'
  style?: Record<string, string | styleType>
  fontWeight?: string | number
  className?: string
  noWrap?: boolean
}
const lineHeightMap: Record<number, string> = {
  12: '20px',
  14: '22px',
  16: '24px',
  20: '28px',
  24: '32px',
  30: '38px',
  38: '46px',
  46: '54px',
  56: '64px',
  68: '76px',
}

const getLineHeight = (fontSize?: any) => {
  if (!fontSize) return
  let fontSizeValue
  if (fontSize.includes('px'))
    fontSizeValue = parseFloat(fontSize.split('px')[0])
  else if (fontSize.includes('em')) {
    fontSizeValue = parseFloat(fontSize.split('em')[0]) * 16
  } else fontSizeValue = parseFloat(fontSize)
  const fontSizes = Object.keys(lineHeightMap).map((k) => parseInt(k))
  let minDiff = 999,
    lineHeight
  for (let i = 0; i < fontSizes.length; ++i)
    if (Math.abs(fontSizeValue - fontSizes[i]) < minDiff) {
      minDiff = Math.abs(fontSizeValue - fontSizes[i])
      lineHeight = lineHeightMap[fontSizes[i]]
    }
  return { lineHeight }
}
const styleMapping = {
  h1: {
    fontSize: '4.125em',
    color: palette.colors.textDark,
    lineHeight: '76px',
  },
  h2: {
    fontSize: '3.625em',
    color: palette.colors.textDark,
    lineHeight: '64px',
  },
  h3: {
    fontSize: '2.250em',
    color: palette.colors.textDark,
    lineHeight: '46px',
  },
  h4: {
    fontSize: '2.000em',
    color: palette.colors.textDark,
    lineHeight: '38px',
  },
  h5: {
    fontSize: '1.250em',
    color: palette.colors.textDark,
    lineHeight: '28px',
  },
  h6: {
    fontSize: '1.125em',
    color: palette.colors.textDark,
    lineHeight: '24px',
  },
  subtitle1: {},
  subtitle2: {},
  body1: {
    fontSize: '1.125em',
    color: palette.colors.textDark2,
    lineHeight: '24px',
  },
  body2: {
    fontSize: '1.000em',
    color: palette.colors.textDark2,
    lineHeight: '24px',
  },
  caption: {
    fontSize: '0.750em',
    color: palette.colors.textDark3,
    lineHeight: '20px',
  },
  button: {},
  overline: {},
  inherit: {},
}

const Typography: React.FC<TypographyProps> = ({
  children,
  variant = 'body1',
  align,
  style,
  fontWeight,
  className,
  noWrap
}) => {
  return (
    <MuiTypography
      variant={variant}
      align={align}
      sx={{
        ...styleMapping[variant],
        fontWeight: `${fontWeight}`,
        ...style,
        ...getLineHeight(style?.fontSize),
      }}
      className={className}
      noWrap={noWrap}
    >
      {children}
    </MuiTypography>
  )
}

export default Typography
