import Joi, { ValidationResult } from 'joi'
import {
  getValidationError,
  getValidationStatus
} from '../../../utils/ValidationUtils'

import { BsInfoCircle } from 'react-icons/bs'
import Button from '../Button/Button'
import DeleteIcon from '../../../assets/icons/trash.svg'
import InputField from '../InputField/InputField'
import React from 'react'
import { RefObject } from '../InputField/Interface/Interface'
import RegexConstants from '../../../constants/RegexConstants'
import Table from '../Table/Table'
import { Tooltip } from '@mui/material'
import Typography from '../Typography/Typography'
import { TypographyDDS } from '../Typography/TypographyDDS'
import ValidationConstants from '../../../constants/ValidationConstants'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import palette from '../../../global/pallete'
import plusIcon from '../../../assets/icons/plus.svg'
import styled from '@emotion/styled'
import { useStoreContext } from '../../../store/StoreContext'

const PopUpSectionWrapperForDomain = styled.div`
  max-height: 70%;
  display: flex;
  flex-direction: column;
`
const Divider = styled.div`
  border-bottom: 1px solid ${palette.colors.borderColor};
`

const TableHostContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: auto;
`

const TableDeleteButtonContainer = styled.div`
  margin-left: auto;
`
const TitleHost = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`

const TableContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 20px 0;
  width: 100%;
`

const MainTableContainer = styled.div`
  max-height: 250px;
  overflow: scroll;
`

const AddButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
`
const InputFieldWrapper = styled.div`
  height: 60px;
  width: 100%;
`

interface AddDomainProps {
  whiteListedHost?: any[]
  onArrayGenerated?: (data: any[]) => void
  loading?: boolean
}

const AddDomainCard: React.FC<AddDomainProps> = ({
  whiteListedHost = [],
  onArrayGenerated,
  loading = false
}) => {
  const [domain, setDomain] = React.useState<string>('')
  const [validationErrorResult, setValidationErrorResult] = React.useState<
    any[]
  >([])
  const [tableData, setTableData] = React.useState<any[]>(
    whiteListedHost?.length !== 0
      ? whiteListedHost.map((host) => {
          return { host }
        })
      : [{ host: '*' }]
  )
  const storeContext = useStoreContext()
  const hostUrlReference = React.useRef<RefObject>(null)
  const schema = Joi.object({
    url: Joi.alternatives(
      Joi.string()
        .ip({ version: ['ipv4', 'ipv6'] })
        .pattern(RegexConstants.IP_ADDRESS_REGEX),
      Joi.string().pattern(RegexConstants.HOST_ADDRESS_REGEX),
      Joi.string().valid('*')
    )
      .required()
      .messages({
        'string.uri': ValidationConstants.GENERIC.INVALID_URL,
        'string.uriCustomScheme': ValidationConstants.GENERIC.INVALID_URL
      })
  })

  const addDomainToTable = () => {
    const projectValidationResult: ValidationResult = schema.validate(
      {
        url: domain && !isEmpty(domain) ? domain.trim() : ''
      },
      { abortEarly: false }
    )

    if (projectValidationResult.error) {
      setValidationErrorResult(projectValidationResult.error.details)
    } else {
      setValidationErrorResult([])
      const isDuplicate = tableData.some((item) => item.host === domain.trim())
      if (!isDuplicate) {
        if (tableData[0].host === '*' && tableData.length == 1) {
          tableData.shift()
        }
        tableData.unshift({ host: domain.trim() })
        setTableData([...tableData])
        setDomain('')
      } else {
        setValidationErrorResult([
          {
            message: 'The host address you entered already exists',
            type: 'alternatives.match',
            context: {
              label: 'url',
              value: 'host',
              key: 'url'
            }
          }
        ])
      }
    }
  }

  const handleAddHosts = () => {
    const data =
      tableData.length === 0
        ? ['*']
        : tableData.map((data) => {
            const { host } = data
            return host
          })
    if (onArrayGenerated) onArrayGenerated(data)
  }

  const checkHosts = () => {
    const isArrayEqual =
      whiteListedHost.length === tableData.length &&
      whiteListedHost.every((value, index) => value === tableData[index].host)
    return isArrayEqual
  }

  const tableColumns = [
    {
      title: 'Allowed Hosts',
      label: 'host',
      sortable: false,
      render: (item: any, fullItem: any) => {
        return (
          <TableHostContainer>
            <TypographyDDS.Paragraph
              variant='medium'
              size='para'
              color='textDark'
            >
              {tableData.length === 0 ? '*' : item}
            </TypographyDDS.Paragraph>
            {item === '*' && (
              <Tooltip
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: `${palette.colors.white}`,
                      boxShadow: `0 16px 25px ${palette.colors.tooltipShadow}`
                    }
                  }
                }}
                title={
                  <TypographyDDS.Paragraph size='para'>
                    (*) indicates that all hosts will be allowed
                  </TypographyDDS.Paragraph>
                }
                placement='right'
              >
                <div style={{ marginTop: '3px', marginLeft: '3px' }}>
                  <BsInfoCircle />
                </div>
              </Tooltip>
            )}
          </TableHostContainer>
        )
      }
    },
    {
      title: '',
      label: 'deleteButton',
      sortKey: 'actions',
      sortable: false,
      render: (item: any, fullItem: any) => (
        <TableDeleteButtonContainer>
          {(fullItem?.host !== '*' || tableData.length !== 1) && (
            <Button
              color='error'
              size='x-small'
              variant='contained'
              startIcon={
                <img
                  src={DeleteIcon}
                  style={{
                    width: '20px',
                    filter: palette.colors.whiteSvgFilter
                  }}
                />
              }
              onClick={() => {
                if (tableData.length == 1) tableData.push({ host: '*' })
                tableData.splice(fullItem.index, 1)
                setTableData([...tableData])
              }}
              disabled={loading}
            >
              Delete
            </Button>
          )}
        </TableDeleteButtonContainer>
      )
    }
  ]
  return (
    <PopUpSectionWrapperForDomain>
      <TitleHost>
        <TypographyDDS.Title type='h3' color='black' variant='medium'>
          Allowed Hosts
        </TypographyDDS.Title>
        <Tooltip
          sx={{
            width: '40%'
          }}
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                maxWidth: `40%`,
                backgroundColor: `${palette.colors.white}`,
                boxShadow: `0 16px 25px ${palette.colors.tooltipShadow}`
              }
            }
          }}
          title={
            <TypographyDDS.Paragraph size='para'>
              Specify origin URLs/IP addresses for Vigil to accept events from,
              limiting requests from unauthorized sources. Also configuring your
              backend server to prevent IP address masking when using a proxy
              server ensures Vigil can whitelist the correct IP address
            </TypographyDDS.Paragraph>
          }
          placement='right'
        >
          <div style={{ marginTop: '5px', marginLeft: '3px' }}>
            <BsInfoCircle />
          </div>
        </Tooltip>
      </TitleHost>

      <TypographyDDS.Paragraph size='para' variant='medium' color='textDark2'>
        Examples: https://example.com, *, 184.169.250.146.
      </TypographyDDS.Paragraph>
      <Divider style={{ marginTop: '10px' }} />
      <TableContentContainer>
        <InputFieldWrapper>
          <InputField
            width='100%'
            required
            label='Allowed Hosts'
            size='medium'
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            helperText={getValidationError(validationErrorResult, 'url')}
            error={getValidationStatus(validationErrorResult, 'url')}
            ref={hostUrlReference}
            disabled={storeContext.userStore.getNoActiveSubscription()}
          />
        </InputFieldWrapper>
        <Button
          variant='contained'
          size='medium'
          iconSize='12px'
          startIcon={
            <img
              src={plusIcon}
              style={{
                filter: palette.colors.whiteSvgFilter
              }}
            />
          }
          onClick={() => {
            addDomainToTable()
          }}
          disabled={loading || domain.trim().length == 0}
        >
          Add
        </Button>
      </TableContentContainer>
      <MainTableContainer>
        <Table
          showPagination={false}
          noPadding
          data={tableData}
          columns={tableColumns.map((column) => ({
            ...column
          }))}
        />
      </MainTableContainer>
      <AddButtonContainer>
        <Button
          loading={loading}
          variant='contained'
          size='medium'
          iconSize='12px'
          startIcon={
            <img
              src={plusIcon}
              style={{
                filter: palette.colors.whiteSvgFilter
              }}
            />
          }
          onClick={handleAddHosts}
          disabled={checkHosts()}
        >
          Save Hosts
        </Button>
      </AddButtonContainer>
    </PopUpSectionWrapperForDomain>
  )
}

export default observer(AddDomainCard)
