import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import SidebarLayout, {
  SidebarRef
} from '../../components/New/Sidenavbar/SidebarLayout'

import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import BottomSection from '../../components/New/Sidenavbar/BottomSection'
import CircularProgress from '../../components/New/Sidenavbar/CircularProgressBar'
import { DDSTypography } from 'den-design-system'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../constants/HeaderConstants'
import OrganizationSection from '../../components/New/Sidenavbar/OrganizationSection'
import SecureComponent from '../../components/Layout/SecureComponent'
import StringConstants from '../../constants/StringConstants'
import TopBarProfileSection from '../../components/New/TopBar/TobBarProfileSection'
import { Typography } from '../../components/common/DDS/Typography/Typography'
import VigilLogo from '../../assets/icons/authVigilLogoDark.svg'
import { isEmpty } from 'lodash'
import noOrgImage from '../../assets/images/NoOrgImage.svg'
import { observer } from 'mobx-react-lite'
import packageJson from '../../../package.json'
import palette from '../../global/pallete'
import pallete from '../../global/newPallete'
import { replaceUrl } from '../../utils/UrlUtils'
import routeConstants from '../../constants/RouteConstants'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../store/StoreContext'
import vigilIcon from '../../assets/icons/bottomVigilLogo.svg'
import vigilLogo from '../../assets/icons/Vigil Logo Greyed out.svg'

const NoProjectPage = () => {
  const store = useStoreContext()
  const [loading, setLoading] = useState(false)
  const shouldShowSideNavbar = store.userStore.getOrganizations().length > 0
  const sidebarRef = useRef<SidebarRef>(null)
  const toggleSidebar = () => {
    const currentExpandedState = store.uiStore.getSidebarExpanded()
    store.uiStore.setSidebarExpanded(!currentExpandedState)
  }
  const navigate = useNavigate()
  useEffect(() => {
    if (store.uiStore.getprojectEnvironmentLoader()) {
      store.uiStore.setprojectEnvironmentLoader(false)
    }
  }, [store.uiStore.getprojectEnvironmentLoader()])

  useEffect(() => {
    if (store.uiStore.getGlobalLoader()) {
      store.uiStore.setGlobalLoader(false)
    }
  }, [store.uiStore.getGlobalLoader()])

  useEffect(() => {
    if (
      store.userStore.getSelectedOrganization().projectCount !== 0 &&
      !isEmpty(store.scopeStore.getScope()['environment-id'])
    ) {
      localStorage.setItem(
        StringConstants.ACTIVE_TAB,
        StringConstants.TAB_VALUES.OVERVIEW
      )
      navigate(
        replaceUrl(
          routeConstants.OVERVIEW,
          store.scopeStore.getScope()['organization-id'] as string,
          store.scopeStore.getScope()['project-id'] as string,
          store.scopeStore.getScope()['environment-id'] as string
        )
      )
    }
  }, [])

  useLayoutEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.OVERVIEW
    )
    store.uiStore.setSidebarItem({})
    const recaptchaBadge = document.querySelector(
      '.grecaptcha-badge'
    ) as HTMLElement
    if (recaptchaBadge) {
      recaptchaBadge.style.setProperty('visibility', 'hidden')
    }
  }, [])
  return (
    <>
      <DocumentHeader header={HeaderConstants.NO_PROJECT} />
      <div className='flex'>
        <div
          className={`flex flex-col w-full ${store.uiStore.getIsAlertVisible() ? 'h-[85vh]' : 'h-[89vh]'} bg-white `}
        >
          <div
            className={`flex flex-col items-center justify-center flex-grow gap-[20px] p-[32px] w-full`}
          >
            <div className='flex flex-col justify-center items-center gap-[12px] h-full self-stretch'>
              <div>
                <img src={noOrgImage} />
              </div>
              <div className='flex flex-col space-between text-center items-center gap-[12px] self-stretch'>
                <DDSTypography.Title
                  type='h1'
                  style={{ fontWeight: 700, lineHeight: '40px' }}
                  color={pallete.colors.textDark3}
                >
                  {StringConstants.NO_PROJECT.NOT_IN_PROJECT}
                </DDSTypography.Title>
                <DDSTypography.Title
                  type='h4'
                  variant='medium'
                  color={pallete.colors.textDark4}
                >
                  {StringConstants.NO_PROJECT.CONTACT_OWNER}
                </DDSTypography.Title>
              </div>
            </div>
            <div className='flex justify-center items-center gap-[4px] p-[4px]'>
              <Typography.Paragraph
                size='caption'
                variant='medium'
                color='textDark10'
              >
                @ 2024
              </Typography.Paragraph>
              <img src={vigilLogo} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SecureComponent(observer(NoProjectPage), AccessRoleConstants.ALL)
