import Button from '../../common/Button/Button'
import React from 'react'
import transferOwnershipIcon from '../../../assets/icons/transferOwnership.svg'
import { useStoreContext } from '../../../store/StoreContext'

const TransferOwnershipButton: React.FC<{
  onClick?: () => void
  margin: string
  loading?: boolean
}> = ({ onClick = () => null, margin, loading }) => {
  const store = useStoreContext()
  return (
    <Button
      variant='contained'
      iconSize='16px'
      endIcon={<img src={transferOwnershipIcon} />}
      onClick={onClick}
      margin={margin}
      fullWidth
      disabled={loading || store.userStore.getNoActiveSubscription()}
    >
      Transfer Ownership
    </Button>
  )
}

export default TransferOwnershipButton
