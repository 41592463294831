import {
  BtoA,
  UrlWithOnlyOrgId,
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'
import React, { useEffect, useState } from 'react'
import {
  acceptUserInvite,
  findAllMyUserInvites,
  rejectUserInvite
} from '../../../api/userInvites/UserInvites.service'
import { createSearchParams, useNavigate } from 'react-router-dom'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'
import {
  getAllNotifications,
  markNotificationAsRead
} from '../../../api/notification/notification.service'

import DividerLine from '../DividerLine/DividerLine'
import FilledTabGroup from '../TabGroup/FilledTabGroup'
import HttpConstants from '../../../constants/HttpConstants'
import InvitePopup from '../../InvitePopup/InvitePopup'
import { Notification as NotificationIcon } from '@carbon/icons-react'
import { OrganizationUserRoles } from '../../../enum/OrganizationUserRoles.enum'
import Spinner from '../Spinner/Spinner'
import StringConstants from '../../../constants/StringConstants'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../DDS/Toast/Toast'
import Typography from '../Typography/Typography'
import { TypographyDDS } from '../Typography/TypographyDDS'
import { addQueryParamsToUrl } from '../../../utils/UrlGenerator'
import arrowRightIcon from '../../../assets/icons/arrowRight.svg'
import { isEmpty } from 'lodash'
import notificationIcon from '../../../assets/icons/bell.svg'
import { observer } from 'mobx-react-lite'
import palette from '../../../global/pallete'
import pallete from '../../../global/newPallete'
import { redirectFunction } from '../../../utils/NotificationUtils'
import routeConstants from '../../../constants/RouteConstants'
import styled from '@emotion/styled'
import successIcon from '../../../assets/icons/successIcon.svg'
import { timeFormats } from '../../../enum/TIME'
import { timeZone } from '../../../utils/DateTimeUtils'
import { toast } from 'react-toastify'
import unreadNotificationIcon from '../../../assets/icons/unreadNotification.svg'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useStoreContext } from '../../../store/StoreContext'

interface NotificationProps {
  notificationCount?: number
  notifications?: {
    id: number
    iconUrl?: string
    title: string
    date: string
  }[]
}

interface ContainerProps {
  open?: boolean
  currentTab?: number
  disabled?: boolean
}

const Container = styled.div<ContainerProps>`
  position: relative;
  max-width: 12em;
  padding-top: 12px;
  border-radius: 8px;
  cursor: default;
  &:hover {
    cursor: ${(props) =>
      props.currentTab === 0 && !props.disabled ? 'pointer' : 'not-allowed'};
  }
`

const Wrapper = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  grid-gap: 14px;
  color: ${(props) =>
    props.open ? `${palette.colors.white}` : `${palette.colors.textDark2}`};
`

const NotificationBadge = styled.div`
  position: absolute;
  top: -11px;
  left: 16px;
  min-width: 0px;
  border-radius: 35px;
  border: 1px solid ${palette.colors.primary};
  font-size: 12px;
  font-weight: 500;
  color: ${palette.colors.textDark};
  background-color: ${palette.colors.borderColor};
  box-shadow: 0px 15px 25px rgba(71, 78, 93, 0.15);
  height: 20px;
  display: flex;
  width: 22px;
  align-items: center;
  justify-content: center;
`

const NotificationContainer = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  width: 500px;
  right: 0;
  z-index: 100;
  background-color: ${palette.colors.white};
  border: 1px solid ${palette.colors.borderColor};
  border-radius: 8px;
  padding: 20px 32px;
  box-shadow: 0px 4px 25px rgba(71, 78, 93, 0.05);
`
const NotificationItem = styled.div<{ currentTab?: number }>`
  display: flex;
  align-items: center;
  grid-gap: 8px;
  padding: 16px 0px;
  border-bottom: 1px solid ${palette.colors.borderColor};
  flex-direction: ${(props) => (props.currentTab === 0 ? 'row' : 'column')};
`

const NotificationFooter = styled.div`
  margin-top: 15px;
  justify-content: center;
  display: flex;
  align-items: center;
`

const InviteText = styled.div`
  width: 100%;
  font-size: 12px;
  color: ${palette.colors.textDark2};
  text-align: initial;
`

const ButtonContainer = styled.div`
  text-align: center;
  align-self: flex-start;
  display: flex;
  margin-top: 5px;
  justify-content: flex-start;
  width: 28%;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`

const NotificationItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: calc(100% - 110px);
`

interface ItemWrapperProps {
  width?: string
  padding?: string
  margin?: string
}

const TabWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const NotificationWrapper = styled.div`
  cursor: pointer;
  text-align: start;
`

const NoNotificationsContainer = styled.div`
  cursor: default;
  text-align: center;
  height: 60px;
  display: flex;
  flex-direction: column;
`

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const Notification: React.FC<NotificationProps> = ({
  notificationCount = 0
}) => {
  const [containerOpen, setContainerOpen] = React.useState(false)
  const [currentTab, setCurrentTab] = React.useState<number>(0)
  const [notifications, setNotifications] = React.useState<any>([])
  const [invites, setInvites] = React.useState<any>([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const [selectedInvite, setSelectedInvite] = useState<any>()
  const [invitePopupOpen, setInvitePopupOpen] = useState<boolean>(false)
  const [acceptLoading, setAcceptLoading] = useState<boolean>(false)
  const [rejectLoading, setRejectLoading] = useState<boolean>(false)
  const store = useStoreContext()
  const navigate = useNavigate()
  const TabLabels = [
    { id: 1, label: 'Notifications' },
    { id: 2, label: 'Invites' }
  ]
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.NOTIFICATION_DROPDOWN
  )

  useEffect(() => {
    if (containerOpen) {
      if (currentTab === 0) {
        setLoading(true)
        getAllNotifications({ status: 'UNREAD' })
          .then((response: any) => {
            setNotifications(response.data)
          })
          .catch((err) => console.log(err))
          .finally(() => setLoading(false))
      } else if (currentTab === 1) {
        setLoading(true)
        findAllMyUserInvites()
          .then((response: any) => {
            store.userStore.setInvitationCount(response.data.length)
            setInvites(response.data)
          })
          .catch((err) => console.log(err))
          .finally(() => setLoading(false))
      }
    }
    store.uiStore.setGlobalLoader(false)
  }, [containerOpen, currentTab])

  const MarkNotificationAsRead = (Notification: any) => {
    if (store.userStore.getSelectedOrganization().projectCount === 0) {
      navigate(UrlWithOnlyOrgId(routeConstants.NO_PROJECT, store))
    } else {
      setLoading(true)
      setContainerOpen(false)
      markNotificationAsRead({ $id: Notification.id })
        .then(() => {
          Notification.eventName !== 'DELETED' &&
          Notification.eventName !== 'REJECTED' &&
          Notification.eventName !== 'WELCOME'
            ? navigate(
                redirectFunction(Notification, store.uiStore, store.scopeStore)
              )
            : getAllNotifications({ status: 'UNREAD' })
                .then((response: any) => {
                  setNotifications(response.data)
                })
                .catch((err) => console.log(err))
                .finally(() => setLoading(false))
        })
        .catch((err) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.SOMETHING_WENT_WRONG
          })
        })
    }
  }

  const handleAcceptUserInvite = () => {
    setAcceptLoading(true)
    setContainerOpen(false)
    acceptUserInvite({ $token: selectedInvite.token })
      .then(() => {
        store.scopeStore.setOrganizationId(selectedInvite.organization.id)
        localStorage.removeItem(
          StringConstants.LOCAL_STORAGE.CURRENT_PAGE_DETAILS
        )
        store.scopeStore.setProjectId('')
        store.scopeStore.setEnvironmentId('')
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.USER_INVITE.ACCEPT_USER_INVITE.SUCCESS
        })
        navigate(
          addQueryParamsToUrl(
            routeConstants.REDIRECT,
            selectedInvite.role !== OrganizationUserRoles.BILLING
              ? {
                  redirect: UrlWithOnlyOrgId(
                    routeConstants.MEMBER_SETTINGS,
                    store
                  )
                }
              : {
                  redirect: UrlWithOnlyOrgId(
                    routeConstants.SUBSCRIPTION_SETTINGS,
                    store
                  )
                },
            HttpConstants.GET_METHOD
          )
        )
      })
      .catch((err) =>
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SOMETHING_WENT_WRONG
        })
      )
      .finally(() => setAcceptLoading(false))
  }

  const handleRejectUserInvite = () => {
    setRejectLoading(true)
    rejectUserInvite({ $token: selectedInvite.token })
      .then(() => {
        findAllMyUserInvites()
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.USER_INVITE.REJECT_USER_INVITE.SUCCESS
        })
        setInvitePopupOpen(false)
      })
      .catch((err) =>
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SOMETHING_WENT_WRONG
        })
      )
      .finally(() => setRejectLoading(false))
  }

  const handleEnvironmentChange = (id: string) => {
    if (id === store.scopeStore.getSelectedEnvironment().id) {
      return
    }
    store.uiStore.setGlobalLoader(true)
    store.scopeStore.setEnvironmentId(id)
    const environment = store.scopeStore
      .getEnvironments()
      .filter((record: any) => record.id == id)
    store.scopeStore.setSelectedEnvironment(environment[0])
    store.filterStore.setFiltersApplied(false)
    let url = BtoA(
      store.scopeStore.getOrganizationId() +
        '/' +
        store.scopeStore.getScope()['project-id'] +
        '/' +
        id
    )
    url += '/' + window.location.pathname.split('/')[2]
    if (
      ['dashboard', 'report'].includes(window.location.pathname.split('/')[3])
    ) {
      url += '/' + window.location.pathname.split('/')[3]
    }
    const pathName = '../' + '../' + url
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    navigate({
      pathname: pathName,
      search: `?${createSearchParams(queryParams)}`
    })
    store.breadcrumbStore.reset()
  }

  const environmentSwitch = (notification: any) => {
    if (
      notification?.parentId?.environmentId !==
      store.scopeStore.getEnvironmentId()
    ) {
      const environments = store.scopeStore.getEnvironments()
      const matchingEnvironment = environments.filter(
        (env) => notification?.parentId?.environmentId === env?.id
      )

      if (!isEmpty(environments)) {
        if (
          matchingEnvironment[0]?.type ===
          StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
        ) {
          localStorage.setItem(
            StringConstants.ENVIRONMENT_TYPE,
            StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
          )
          handleEnvironmentChange(environments[0]?.id)
        } else {
          localStorage.setItem(
            StringConstants.ENVIRONMENT_TYPE,
            StringConstants.ENVIRONMENT_TYPE_VALUES.PROD
          )
          handleEnvironmentChange(environments[1]?.id)
        }
      }
    }
  }

  return (
    <Container
      open={containerOpen}
      currentTab={currentTab}
      tabIndex={0}
      onBlur={() => setContainerOpen(false)}
      onClick={() => {
        if (store.uiStore.getGlobalLoader()) return
        !containerOpen &&
          gaEventTracker(gaEventConstants.OPEN_NOTIFICATION_DROPDOWN)
        setContainerOpen(!containerOpen)
        setLoading(true)
      }}
      disabled={store.uiStore.getGlobalLoader()}
    >
      {notificationCount != 0 ? (
        notificationCount > 0 && notificationCount < 100 ? (
          <NotificationBadge>{notificationCount}</NotificationBadge>
        ) : (
          <NotificationBadge>99+</NotificationBadge>
        )
      ) : (
        <></>
      )}
      <Wrapper
        open={containerOpen}
        style={{
          cursor: store.uiStore.getGlobalLoader() ? 'not-allowed' : 'pointer',
          width: '20px',
          height: '20px'
        }}
      >
        <NotificationIcon
          size={22}
          color={pallete.colors.textDark2}
          style={{
            marginBottom: '11px',
            userSelect: 'none'
          }}
        />
      </Wrapper>

      {containerOpen && (
        <NotificationContainer
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <TabWrapper className='Tab'>
            <FilledTabGroup
              tabLabels={TabLabels}
              currentTab={currentTab}
              onChange={setCurrentTab}
              tabWidth='10.8125em'
              tabHeight='2.0625em'
              disabled={loading}
            />
          </TabWrapper>
          <DividerLine margin='15px 0px' />
          {loading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <>
              <NotificationItemContainer>
                {currentTab === 0 && notifications.length > 0
                  ? notifications
                      .slice(0, 5)
                      .map((notification: any, index: number) => (
                        <NotificationItem
                          key={index + 1}
                          currentTab={currentTab}
                        >
                          <NotificationWrapper
                            onClick={() => {
                              environmentSwitch(notification)
                              MarkNotificationAsRead(notification)
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                gap: '10px',
                                flexDirection: 'row'
                              }}
                            >
                              <img src={unreadNotificationIcon} />
                              <TypographyDDS.Paragraph
                                size='para'
                                variant='semiBold'
                                color='textDark'
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: notification.content
                                  }}
                                  style={{ overflowWrap: 'anywhere' }}
                                ></div>
                              </TypographyDDS.Paragraph>
                            </div>

                            <TypographyDDS.Paragraph
                              size='caption'
                              style={{
                                marginTop: '4px',
                                marginLeft: '20px'
                              }}
                            >
                              {timeZone(
                                store.userStore.getUserPreference().timeZone,
                                notification.notifiedAt,
                                timeFormats.fullMonthDateYearTime
                              )}
                            </TypographyDDS.Paragraph>
                          </NotificationWrapper>
                        </NotificationItem>
                      ))
                  : currentTab === 1 &&
                    invites.slice(0, 5).map((invite: any, index: number) => (
                      <NotificationItem key={index + 1} currentTab={currentTab}>
                        <TypographyDDS.Paragraph
                          size='para'
                          variant='semiBold'
                          color='textDark'
                          style={{ textAlign: 'initial', width: '100%' }}
                        >
                          {invite.project
                            ? invite.project.name
                            : invite.organization.name}
                        </TypographyDDS.Paragraph>
                        <InviteText>
                          {invite.inviter.firstName} has invited you to join as
                          a {invite.role.toLowerCase()}{' '}
                        </InviteText>
                        <ButtonContainer
                          onClick={() => {
                            setSelectedInvite(invite)
                            setContainerOpen(false)
                            setInvitePopupOpen(true)
                          }}
                        >
                          <TypographyDDS.Paragraph
                            size='caption'
                            variant='bold'
                          >
                            Review Invite
                          </TypographyDDS.Paragraph>

                          <img
                            src={arrowRightIcon}
                            style={{ height: '1.5em', marginLeft: '5px' }}
                          />
                        </ButtonContainer>
                      </NotificationItem>
                    ))}
              </NotificationItemContainer>
              {((store.userStore.getNotificationCount() === 0 &&
                currentTab === 0) ||
                (store.userStore.getInvitationCount() === 0 &&
                  currentTab === 1)) && (
                <NoNotificationsContainer>
                  <img
                    src={successIcon}
                    style={{
                      height: '30px',
                      filter: palette.colors.primarySvgFilter
                    }}
                  />

                  <TypographyDDS.Title type='h4' variant='semiBold'>
                    You{`'`}re all caught up
                  </TypographyDDS.Title>
                </NoNotificationsContainer>
              )}
              <NotificationFooter>
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '10px'
                  }}
                  onClick={() => {
                    if (currentTab === 0) {
                      navigate(routeConstants.NOTIFICATIONS)
                      gaEventTracker(gaEventConstants.VIEW_ALL_NOTIFICATIONS)
                    } else {
                      navigate(routeConstants.INVITES)
                      gaEventTracker(gaEventConstants.VIEW_ALL_INVITES)
                    }
                    store.uiStore.setNotificationSideNavbar(true)
                    setContainerOpen(false)
                  }}
                >
                  <TypographyDDS.Paragraph variant='semiBold' size='para'>
                    View all {currentTab === 0 ? `notifications` : `invites`}{' '}
                  </TypographyDDS.Paragraph>

                  <img
                    src={arrowRightIcon}
                    style={{ height: '16px', marginLeft: '8px' }}
                  />
                </div>
              </NotificationFooter>
            </>
          )}
        </NotificationContainer>
      )}

      <InvitePopup
        open={invitePopupOpen}
        role={selectedInvite?.role}
        project={
          selectedInvite?.project ? selectedInvite?.project.name : undefined
        }
        organization={selectedInvite?.organization?.name}
        handleAcceptInvite={handleAcceptUserInvite}
        handleRejectInvite={handleRejectUserInvite}
        handleClose={() => setInvitePopupOpen(false)}
        acceptLoading={acceptLoading}
        rejectLoading={rejectLoading}
      />
    </Container>
  )
}

export default observer(Notification)
