import React, { useEffect, useState } from 'react'

import UnsecureComponent from '../../components/Layout/UnsecureComponent'
import { getHealthCheckBadge } from '../../api/appHealthCheck/AHC.service'
import { useLocation } from 'react-router-dom'

const AHCBadgeView: React.FC = () => {
  const { pathname } = useLocation()
  const [badge, setBadge] = useState<any>()
  useEffect(() => {
    const segments = pathname.split('/').filter((s) => s.length !== 0)
    const encryptedId: string | undefined = segments.pop()
    getHealthCheckBadge({ $encryptedId: encryptedId as string })
      .then((response: any) => {
        setBadge(response)
      })
      .catch((err) => console.log(err))
  }, [])

  return <div dangerouslySetInnerHTML={{ __html: badge }}></div>
}

export default UnsecureComponent(AHCBadgeView)
