import React, { CSSProperties } from 'react'

import { DDSBreadcrumbs } from 'den-design-system'

export interface BreadcrumbsProps {
  breadcrumbsOption: any[]
  id?: string
  disabled?: boolean
  className?: string
  style?: CSSProperties
  onClick?: (link: string, event: React.MouseEvent<HTMLElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void
  type?: any
  maxCrumbs?: number
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  breadcrumbsOption,
  id,
  className,
  style,
  onClick,
  onBlur,
  disabled = false,
  type = 'ghost',
  maxCrumbs = 4
}) => {
  return (
    <div className={`${disabled && 'cursor-not-allowed'}`}>
      <div
        className={`${disabled && 'pointer-events-none'} override-breadcrumbs override-breadcrumbs`}
      >
        <DDSBreadcrumbs
          breadcrumbsOption={breadcrumbsOption}
          id={id}
          className={className}
          style={style}
          onClick={onClick}
          onBlur={onBlur}
          type={type}
          maxCrumbs={maxCrumbs}
        />
      </div>
    </div>
  )
}

export default Breadcrumbs
