import Filter from '../../../Filter/Filter'
import React from 'react'
import { TypographyDDS } from '../../Typography/TypographyDDS'
import breakpoints from '../../../../global/breakpoints'
import palette from '../../../../global/pallete'
import styled from '@emotion/styled'

interface AllProjectHeaderProps {
  currentTab: number
  tabLabels: any[]
  onChange?: (newValue: number) => void
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>
  options?: any
}

interface ItemWrapperProps {
  width?: string
  padding?: string
  margin?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${breakpoints.md}) {
    justify-content: flex-start;
    & > * {
      flex: 1 100%;
    }
    .CardTitle {
      flex: 1;
      order: 1;
    }
  }
`

const SearchfieldWrapper = styled.div`
  @media (max-width: ${breakpoints.md}) {
    flex: 0 15.0625em;
    order: 1;
  }
`
const TabWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`

const RightContainer = styled.div`
  margin-bottom: 1em;
  display: flex;
  margin-top: 13px;
`

const AllProjectsHeader: React.FC<AllProjectHeaderProps> = ({
  currentTab,
  tabLabels,
  setCurrentTab,
  onChange,
  options
}) => {
  return (
    <>
      <Container>
        <TypographyDDS.Title type='h4' variant='bold'>
          Projects
        </TypographyDDS.Title>

        {/* <TabWrapper>
        <FilledTabGroup
          tabLabels={tabLabels}
          currentTab={currentTab}
          onChange={setCurrentTab}
        />
      </TabWrapper> */}

        {/* <SearchField /> */}

        {/* </RightContainer> */}
        {/* <SearchfieldWrapper>
        <SearchField placeholder='Search' />
      </SearchfieldWrapper> */}

        <RightContainer>
          <Filter primaryText='Add Filters' options={options} />
        </RightContainer>
      </Container>
    </>
  )
}

export default AllProjectsHeader
