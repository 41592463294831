import React, { useEffect, useState } from 'react'

import GeneralSection from '../../../components/SettingsPage/OrganizationSettings/GeneralSection'
import { OrganizationUserRoles } from '../../../enum/OrganizationUserRoles.enum'
import OwnershipSection from '../../../components/SettingsPage/OrganizationSettings/OwnershipSection'
import Spinner from '../../../components/common/Spinner/Spinner'
import { getUserDetails } from '../../../api/user/User.service'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import routeConstants from '../../../constants/RouteConstants'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'

const GeneralSettings = () => {
  const storeContext = useStoreContext()
  const [loading, setLoading] = useState(false)
  const [generalSectionLoading, setGeneralSectionLoading] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    setLoading(true)
    getUserDetails()
      .then((response: any) => {
        if (response.data.organizations.length === 0) {
          navigate(routeConstants.NO_ORGANIZATION)
        }
        const organizationId =
          storeContext.scopeStore.getScope()['organization-id']
        const selectedOrganization = response.data.organizations.find(
          (organization: any) => organization.id === organizationId
        )

        if (!selectedOrganization) {
          navigate(routeConstants.UNAUTHORIZED_PAGE)
        }

        storeContext.userStore.setUserRole(selectedOrganization.membership.role)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    storeContext.uiStore.setGlobalLoader(false)
  }, [
    storeContext.scopeStore.getEnvironmentId(),
    storeContext.uiStore.getGlobalLoader()
  ])

  return !loading ? (
    <>
      {!isEmpty(storeContext.userStore.getSelectedOrganization()) && (
        <GeneralSection
          data={storeContext.userStore.getSelectedOrganization()}
          loading={generalSectionLoading}
          setLoading={setGeneralSectionLoading}
        />
      )}
      {storeContext.userStore.getUserRole() === OrganizationUserRoles.OWNER && (
        <OwnershipSection loading={generalSectionLoading} />
      )}
      {/* TODO Create organization is hidden for Beta */}
      {/* <OrganizationSection
        data={storeContext.userStore.getSelectedOrganization()}
      /> */}
    </>
  ) : (
    <Spinner />
  )
}

export default observer(GeneralSettings)
