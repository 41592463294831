import React, { useEffect, useState } from 'react'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'
import {
  getAllProjects,
  getProjectAndEnvironment,
  getProjectFilters
} from '../../../api/project/Project.service'
import { useNavigate, useSearchParams } from 'react-router-dom'

import AllProjectsHeader from './Subcomponents/AllProjectsHeader'
import Button from '../Button/Button'
import CreateProjectFlow from '../../popupFlows/CreateProjectFlow/CreateProjectFlow'
import DashboardSectionContainer from '../DashboardSectionContainer/DashboardSectionContainer'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import MulesoftIcon from '../Icon/MulesoftIcon'
import NoData from '../../NoData/NoData'
import { OrganizationUserRoles } from '../../../enum/OrganizationUserRoles.enum'
import { ProjectType } from '../../../enum/ProjectType.enum'
import SalesforceIcon from '../Icon/SalesforceIcon'
import StringConstants from '../../../constants/StringConstants'
import Table from '../Table/Table'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../DDS/Toast/Toast'
import { Tooltip } from '@mui/material'
import { TypographyDDS } from '../Typography/TypographyDDS'
import breakpoints from '../../../global/breakpoints'
import { getAllQueryParamsAsObjectFromUrl } from '../../../utils/UrlUtils'
import styled from '@emotion/styled'
import { timeFormats } from '../../../enum/TIME'
import { timeZone } from '../../../utils/DateTimeUtils'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useObserver } from 'mobx-react-lite'
import { useStoreContext } from '../../../store/StoreContext'

interface ButtonContainerProps {
  justifyContent?: 'flex-start' | 'flex-end' | 'center'
}

const ItemWrapper = styled.div<ButtonContainerProps>`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: ${(props) => props.justifyContent};
  cursor: pointer;
`

const Container = styled.div`
  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.85rem;
  }
`
const ProjectNameWrapper = styled.div`
  display: flex;
  align-items: center;
`

const AllProjects: React.FC = () => {
  const navigate = useNavigate()
  const [projectFilters, setProjectFilters] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [nameWidths, setNameWidths] = React.useState<number[]>([])
  const [nameMaxWidth, setNameMaxWidth] = React.useState<number>(0)
  const [page, setPage] = useState<number>(
    parseInt(searchParams.get('page') as string) || StringConstants.DEFAULT_PAGE
  )
  const store = useStoreContext()
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.PROJECT_SETTINGS
  )
  const findAllProjects = () => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const queryParams = {
      page,
      size: StringConstants.DEFAULT_TABLE_SIZE,
      ...allQueryParams,
      'expand-created-user': true
    }
    setSearchParams({ page: '1', ...allQueryParams })
    if (!store.projectStore.getLoading()) {
      store.uiStore.setGlobalLoader(true)
      store.projectStore.setLoading(true)
      getAllProjects(queryParams)
        .then((response: any) => {
          if (
            response.data.length > 0 ||
            store.filterStore.getFiltersApplied() ||
            !store.projectStore.getLoading()
          ) {
            store.filterStore.setFilterDisabled(false)
          } else {
            store.filterStore.setFilterDisabled(true)
          }

          store.projectStore.setProjects(response.data)
          store.projectStore.setCurrentPage(response.page)
          store.projectStore.setTotalCount(response.totalCount)

          store.filterStore.setFilters(response.filters)
        })
        .catch((error) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.PROJECT.FIND_ALL_PROJECTS.ERROR
          })
        })
        .finally(() => {
          store.uiStore.setGlobalLoader(false)
          store.projectStore.setLoading(false)
        })
    }
  }

  const nameRef = React.useCallback(
    (node: any) => {
      if (node !== null) {
        nameWidths.push(node.getBoundingClientRect().width)
        if (nameMaxWidth < node.getBoundingClientRect().width)
          setNameMaxWidth(node.getBoundingClientRect().width)
      }
    },
    [store.projectStore.getLoading()]
  )

  const getFilters = () => {
    store.filterStore.setFilterDisabled(true)
    getProjectFilters()
      .then((response: any) => {
        setProjectFilters(response.data)
      })
      .catch((err: any) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SOMETHING_WENT_WRONG
        })
      })
  }

  const getEllipsisWidth = (maxWidth: number, tableWidth: number) => {
    if (maxWidth > 0 && tableColumns.length > 1 && tableWidth > 0) {
      const width = tableWidth - maxWidth
      const widthLimit = width / (tableColumns.length - 1)

      if (widthLimit < maxWidth) {
        return widthLimit
      }
    }
    return 0
  }

  const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)

  const updateProjectsAndEnvironments = () => {
    getProjectAndEnvironment().then((projectResponse: any) => {
      store.scopeStore.setProjects(projectResponse.data)
    })
  }

  useEffect(() => {
    getFilters()
    findAllProjects()
    updateProjectsAndEnvironments()
  }, [location.search, store.scopeStore.getOrganizationId()])

  const handlePageChange = (page: number) => {
    setSearchParams(
      { ...allQueryParams, page: page.toString() },
      { replace: true }
    )
  }

  const TabLabels = [
    { id: 1, label: 'All Projects' },
    { id: 2, label: 'Your Projects' }
  ]

  const [currentTab, setCurrentTab] = React.useState(0)

  const [createProjectPopupVisible, setCreateProjectPopupVisibility] =
    useState<boolean>(false)

  //TODO: Use when archived projects and invite members are added
  // const handleTabChange = () => {
  //   if (currentTab === 0) {
  //     setData(AllProjectData)
  //   }
  //   if (currentTab === 1) {
  //     setData(AllProjectDataYourProjects)
  //   }
  //   if (currentTab === 2) {
  //     setData(AllProjectDataArchived)
  //   }
  // }

  // React.useEffect(() => {
  //   handleTabChange()
  // }, [currentTab])

  const tableColumns = [
    {
      label: 'name',
      title: 'Project Name',

      render: (
        item: any,
        fullItem: any,
        page: number,
        tableRef: any,
        index: number
      ) => {
        return (
          <TypographyDDS.Paragraph
            size='para'
            variant='medium'
            color='textDark'
          >
            <Tooltip
              title={
                nameWidths[index] &&
                nameWidths[index] >
                  getEllipsisWidth(nameMaxWidth, tableRef?.current?.offsetWidth)
                  ? `${item}`
                  : ''
              }
            >
              <ProjectNameWrapper>
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width:
                      getEllipsisWidth(
                        nameMaxWidth,
                        tableRef?.current?.offsetWidth
                      ) > 0
                        ? getEllipsisWidth(
                            nameMaxWidth,
                            tableRef?.current?.offsetWidth
                          ) + 'px'
                        : 'inherit',
                    display: 'block'
                  }}
                  ref={nameRef}
                >
                  {item}
                </span>
                {fullItem.type === ProjectType.SALESFORCE_ONLY && (
                  <SalesforceIcon />
                )}
                {fullItem.type === ProjectType.MULESOFT_ONLY && (
                  <MulesoftIcon />
                )}
              </ProjectNameWrapper>
            </Tooltip>
          </TypographyDDS.Paragraph>
        )
      }
    },
    {
      title: 'Created By',
      label: 'createdByUser',
      render: (item: any) => item.firstName
    },
    {
      title: 'Date Created',
      label: 'createdAt',
      render: (item: any) => {
        return timeZone(
          store.userStore.getUserPreference().timeZone,
          item,
          timeFormats.monthDateYear
        )
      }
    },

    // Not Supported in Version 01, will be in Version 2
    // {
    //   title: '',
    //   label: '',
    //   render: (item: any, fullItem: any) => (
    //     <ItemWrapper justifyContent='flex-end' style={{ gridGap: '20px' }}>
    //       {fullItem.projectArchived && (
    //         <Chip
    //           borderColor={`${palette.colors.borderRed}`}
    //           startIcon={
    //             <BiLockAlt style={{ color: palette.colors.borderRed }} />
    //           }
    //           label='Archived'
    //         />
    //       )}
    //       {(fullItem.archivedEnv || fullItem.projectArchived) && (
    //         <Chip
    //           startIcon={
    //             <BiLockAlt style={{ color: palette.colors.primary }} />
    //           }
    //           label='Archived Environments'
    //         />
    //       )}
    //     </ItemWrapper>
    //   ),
    // },

    // {
    //   title: '',
    //   label: '',
    //   render: (item: any, fullItem: any) => (
    //     <ItemWrapper
    //       onClick={() => navigate(`./${fullItem.id}/members`)}
    //       justifyContent='center'
    //     >
    //       <ParaLight>View Members</ParaLight>
    //       <BsChevronRight style={{ fontSize: '14px' }} />
    //     </ItemWrapper>
    //   ),
    // },

    {
      title: '',
      label: '',
      render: (item: any, fullItem: any) => (
        <ItemWrapper justifyContent='flex-end'>
          <Button
            size='small'
            variant='outlined'
            endIcon={<MdOutlineKeyboardArrowRight />}
            onClick={() => {
              gaEventTracker(gaEventConstants.VIEW_ENVIRONMENTS)
              navigate(`./${fullItem.id}/environments`)
            }}
          >
            View Environments
          </Button>
          <Button
            size='small'
            variant='contained'
            endIcon={<MdOutlineKeyboardArrowRight />}
            onClick={() => {
              gaEventTracker(gaEventConstants.VIEW_PROJECT)
              navigate(`./${fullItem.id}/`)
            }}
          >
            View Project
          </Button>
        </ItemWrapper>
      )
    }
  ]
  return useObserver(() => (
    <Container>
      <DashboardSectionContainer
        headerText='Projects'
        customHeader={
          <AllProjectsHeader
            tabLabels={TabLabels}
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            options={projectFilters}
          />
        }
        noDividerPadding
      >
        {store.projectStore.getProjects().length !== 0 ||
        store.projectStore.getLoading() ? (
          <Table
            columns={tableColumns}
            data={store.projectStore.getProjects()}
            onPageChange={handlePageChange}
            currentPage={store.projectStore.getCurrentPage()}
            totalCount={store.projectStore.getTotalCount()}
            isLoading={store.projectStore.getLoading()}
          />
        ) : (
          <NoData
            onClick={() => setCreateProjectPopupVisibility(true)}
            emptyText={false}
            message={
              store.userStore.getUserRole() === OrganizationUserRoles.OWNER
                ? 'Add projects you want to monitor!'
                : 'No projects to monitor'
            }
          />
        )}
        <CreateProjectFlow
          open={createProjectPopupVisible}
          handleClose={() => setCreateProjectPopupVisibility(false)}
        />
      </DashboardSectionContainer>
    </Container>
  ))
}

export default AllProjects
