import AccountSettingsIcon from '../../../assets/icons/accountSettings.svg'
import Notification from '../../common/Notification/Notification'
import React from 'react'
import TabGroup from '../../common/TabGroup/TabGroup'
import breakpoints from '../../../global/breakpoints'
import inviteMembersIcon from '../../../assets/icons/inviteMembersIcon.svg'
import { observer } from 'mobx-react-lite'
import organizationIcon from '../../../assets/icons/organizationIcon.svg'
import palette from '../../../global/pallete'
import routeConstants from '../../../constants/RouteConstants'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'
import vigilIcon from '../../../assets/icons/vigilTMLogo.svg'

interface CreateOrgNavbarProps {
  onClick?: () => void
  padding?: string
  margin?: string
  width?: string

  onChange: (event: React.SyntheticEvent, newValue: number) => void
  currentTab: number
  inviteMembers: boolean
  showNotifications?: boolean
  // setCurrentTab: React.Dispatch<React.SetStateAction<number>>
}

interface ParaProps {
  padding?: string
  lineHeight?: number
  fontSize?: string
}

interface ContainerProps {
  width?: string
  padding?: string
  margin?: string
}

const IconStyles = {
  fontSize: '1.5em',
  filter: palette.colors.primarySvgFilter
}

const TopSectionWrapper = styled.div<ContainerProps>`
  width: ${(props) => props.width};
  min-width: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`
const IconContainer = styled.div`
  order: 0;
`

const RightContainer = styled.div`
  display: flex;
  grid-gap: 2.5em;
  @media (max-width: ${breakpoints.md}) {
  }
`

const NavItemsContainer = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 1em;
  &:hover {
    color: ${palette.colors.textDark};
  }
  cursor: pointer;
`

const TabGroupContainer = styled.div`
  padding-left: 3.125em;
`

const ParaLight = styled.p<ParaProps>`
  padding: ${(props) => props.padding};
  line-height: ${(props) => props.lineHeight};
  font-weight: 500;
  font-size: ${(props) => props.fontSize};
  color: ${palette.colors.textDark2};
  &:hover {
    color: ${palette.colors.textDark};
  }
  cursor: pointer;
`

const Navbar: React.FC<CreateOrgNavbarProps> = ({
  padding = '0',
  margin = '0',
  width = '100%',
  onChange = () => null,
  currentTab,
  inviteMembers,
  showNotifications = false
}) => {
  const navigate = useNavigate()
  const store = useStoreContext()
  return (
    <TopSectionWrapper padding={padding} margin={margin} width={width}>
      <IconContainer>
        <img src={vigilIcon} alt='logo' />
      </IconContainer>

      {!inviteMembers && (
        <TabGroupContainer>
          <TabGroup
            onChange={onChange}
            currentTab={currentTab}
            tabLabels={[
              {
                label: 'Create Organization',
                icon: <img src={organizationIcon} alt='organization-icon' />
              },
              {
                label: 'Invite Members',
                icon: <img src={inviteMembersIcon} alt='invite-members-icon' />
              }
            ]}
            iconPosition='start'
            tabChildren={[undefined, undefined]}
          />
        </TabGroupContainer>
      )}

      <RightContainer>
        <NavItemsContainer
          onClick={() => {
            navigate(routeConstants.ACCOUNT_SETTINGS)
          }}
        >
          <img src={AccountSettingsIcon} style={IconStyles} />
          <ParaLight fontSize='1.125em'>Account Settings</ParaLight>
        </NavItemsContainer>
        {showNotifications && (
          <Notification
            notificationCount={
              store.userStore.getNotificationCount() +
              store.userStore.getInvitationCount()
            }
          />
        )}
      </RightContainer>
    </TopSectionWrapper>
  )
}

export default observer(Navbar)
