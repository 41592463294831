import { Button } from '@mui/material'
import { AiOutlineCheckCircle as Check } from 'react-icons/ai'
import DividerLine from '../DividerLine/DividerLine'
import React from 'react'
import { TypographyDDS } from '../Typography/TypographyDDS'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'

interface PricingCardProps {
  variant?: 'light' | 'dark'
  recommended?: boolean
  headerText?: string
  subHeaderText?: string
  pricing?: string
  benefits: string[]
}

const Main = styled.div``

const Container = styled.div<{ variant: 'light' | 'dark' }>`
  width: 300px;
  background: ${({ variant }) =>
    variant === 'light'
      ? palette.colors.lightBack
      : palette.colors.gradientBlack};
  border: 1px solid ${palette.colors.borderColor};
  border-radius: 12px;
  transition: box-shadow 0.4s;
  &:hover {
    box-shadow: 0px 11px 25px rgba(0, 0, 0, 0.08);

    background: ${({ variant }) =>
      variant === 'light'
        ? palette.colors.gradientWhite
        : palette.colors.gradientBlack};
    transition: box-shadow 0.4s;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`
const Highlight = styled.div`
  padding: 8px;
  border-radius: 12px 12px 0px 0px;
  border: 1px solid ${palette.colors.primary};
  background-color: ${palette.colors.primary};
`
const ContentWrapper = styled.div`
  padding: 20px;
`
const List = styled.ul`
  list-style: none;
  padding-bottom: 30px;
  li {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    padding: 5px 0px;
  }
`

const PricingCard: React.FC<PricingCardProps> = ({
  variant = 'light',
  recommended,
  headerText,
  subHeaderText,
  pricing,
  benefits
}) => {
  return (
    <Main>
      <Container variant={variant}>
        {recommended && (
          <Highlight>
            <TypographyDDS.Title
              type='h5'
              variant='medium'
              color='textLight'
              style={{
                textAlign: 'center'
              }}
            >
              Recommended
            </TypographyDDS.Title>
          </Highlight>
        )}
        <ContentWrapper>
          <TypographyDDS.Title
            type='h3'
            color={variant === 'light' ? 'primary' : 'textLight'}
            style={{
              paddingBottom: '10px',
              paddingTop: recommended ? '0px' : '20px'
            }}
          >
            {headerText}
          </TypographyDDS.Title>

          <TypographyDDS.Paragraph
            size='para'
            variant='medium'
            color={variant === 'light' ? 'textDark3' : 'textLight2'}
            style={{
              paddingBottom: '10px'
            }}
          >
            {subHeaderText}
          </TypographyDDS.Paragraph>

          <TypographyDDS.Heading
            variant='bold'
            color={variant === 'light' ? '' : 'textLight'}
            style={{
              paddingBottom: '5px'
            }}
          >
            {pricing}
          </TypographyDDS.Heading>

          <TypographyDDS.Paragraph
            size='caption'
            style={{
              paddingBottom: '10px'
            }}
            color={variant === 'light' ? '' : 'textLight2'}
          >
            /month, billed annually. $52 if billed monthly
          </TypographyDDS.Paragraph>

          {/* this has to be changed with custome button component */}
          <Button fullWidth variant='contained'>
            Get Started
          </Button>
          <DividerLine margin='20px 0px' />

          <TypographyDDS.Paragraph
            variant='semiBold'
            size='caption'
            color={variant === 'light' ? 'textDark' : 'textLight'}
            style={{
              paddingBottom: '20px'
            }}
          >
            All the benefits of Develop and:
          </TypographyDDS.Paragraph>

          <List>
            {benefits &&
              benefits.map((benefit, index) => (
                <li key={index}>
                  <Check
                    style={{
                      color:
                        variant === 'light'
                          ? palette.colors.primary
                          : palette.colors.textLight,
                      fontSize: '18px'
                    }}
                  />

                  <TypographyDDS.Paragraph
                    variant='semiBold'
                    size='caption'
                    color={variant === 'light' ? '' : 'textLight2'}
                    style={{
                      paddingBottom: '20px'
                    }}
                  >
                    {benefit}
                  </TypographyDDS.Paragraph>
                </li>
              ))}
          </List>
          {/* change this button with custom button component */}
          <Button variant='outlined' fullWidth>
            Learn More
          </Button>
        </ContentWrapper>
      </Container>
    </Main>
  )
}

export default PricingCard
