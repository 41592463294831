import {
  IoIosArrowDown as DownArrow,
  IoIosArrowForward,
  IoIosArrowUp as UpArrow
} from 'react-icons/io'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../../constants/GAConstants'

import Button from '../../Button/Button'
import Dropdown from '../../Dropdown/Dropdown'
import MultiSelect from '../../MultiSelect/MultiSelect'
import Popup from '../../Popup/Popup'
import React from 'react'
import { ToastMessageConstants } from '../../../../constants/ToastMessageConstants'
import ToastNotification from '../../DDS/Toast/Toast'
import Typography from '../../Typography/Typography'
import ValidationConstants from '../../../../constants/ValidationConstants'
import { addProjectMember } from '../../../../api/project/ProjectMember.service'
import addProjectMemberIcon from '../../../../assets/icons/addProjectMemberIcon.svg'
import { isEmpty } from 'lodash'
import palette from '../../../../global/pallete'
import styled from '@emotion/styled'
import { unassignedOrganizationMemberLookUpForProject } from '../../../../api/organization/Organization.service'
import { useAnalyticsEventTracker } from '../../../../utils/GAUtils'
import { useStoreContext } from '../../../../store/StoreContext'

interface AssignMembersPopupProps {
  open: boolean
  handleClose: () => void
  projectId?: string
  getProjectMembers: () => void
}

interface ItemContainerProps {
  width?: string
  height?: string
  padding?: string
  margin?: string
}

const PopUpSectionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 5em 0;
`

const Left = styled.div`
  width: 50%;
`

const Right = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  width: 50%;
`

const ItemContainer = styled.div<ItemContainerProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  max-width: ${(props) => (props.width ? props.width : '495px')};
`

const RoleDropdownContainer = styled.div`
  display: flex;
  justify-content: start;
`
const AssignMembersPopup: React.FC<AssignMembersPopupProps> = ({
  projectId,
  open,
  handleClose,
  getProjectMembers
}) => {
  const CustomDropdownOptions = [
    {
      id: 0,
      name: 'Member',
      secondaryText: 'Part of the Project added by Manager'
    }
    // {
    //   id: 1,
    //   name: 'Manager',
    //   secondaryText: 'Manages all Members in the Project',
    // },
  ]
  const store = useStoreContext()
  const [loading, setLoading] = React.useState(false)
  const [options, setOptions] = React.useState<any>([])
  const [selectedUsers, setSelectedUsers] = React.useState<any>([])
  const [role, setRole] = React.useState<string | null>('')
  const handleDropdown = (id: string) => {
    const Role = CustomDropdownOptions.find((item: any) => item.id === id)?.name
    setRole(Role || '')
  }
  const [noMembersAdded, setNoMembersAdded] = React.useState<boolean>(false)
  const [noRoleAdded, setNoRoleAdded] = React.useState<boolean>(false)
  const [openMultiSelect, setOpenMultiSelect] = React.useState<boolean>(false)
  const [loadOptions, setLoadOptions] = React.useState<boolean>(false)
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.ASSIGN_MEMBERS
  )
  React.useEffect(() => {
    if (selectedUsers.length !== 0) setNoMembersAdded(false)
    if (!isEmpty(role)) setNoRoleAdded(false)
  }, [selectedUsers, role])

  const memberLookUpFunction = (value: any) => {
    setOptions([])
    if (value.length >= 3) {
      setOpenMultiSelect(true)
      setLoadOptions(true)
      unassignedOrganizationMemberLookUpForProject({
        'name-or-email': value,
        self: true,
        projectId
      })
        .then((response: any) => {
          let data = response.data

          data = data.filter(
            (item: any) =>
              !selectedUsers.map((user: any) => user.id).includes(item.id)
          )
          if (data.length === 0) setOpenMultiSelect(false)
          setOptions(
            data.map(({ id, user }: any) => {
              return {
                id: id,
                name: `${user.firstName}`,
                email: `${user.email}`
              }
            })
          )
        })
        .finally(() => setLoadOptions(false))
    } else setOpenMultiSelect(false)
  }
  const handleAddMembers = () => {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    const projectMemberDetails = {
      addProjectMemberDetails: selectedUsers.map(({ id }: any) => ({
        organizationMemberId: id,
        projectUserRole: role?.toUpperCase()
      }))
    }
    if (
      projectMemberDetails.addProjectMemberDetails.length > 0 &&
      !isEmpty(role)
    ) {
      addProjectMember({ ...projectMemberDetails, projectId })
        .then(() => {
          gaEventTracker(gaEventConstants.ADDED_PROJECT_MEMBERS)
          setSelectedUsers([])
          setRole('')
          ToastNotification({
            type: 'success',
            message:
              ToastMessageConstants.PROJECT_MEMBER.ADD_PROJECT_MEMBER.SUCCESS
          })
          getProjectMembers()
        })
        .catch((err) => {
          ToastNotification({
            type: 'error',
            message:
              ToastMessageConstants.PROJECT_MEMBER.ADD_PROJECT_MEMBER.ERROR
          })
          setLoading(false)
          store.uiStore.setGlobalLoader(false)
          handleClose()
          setSelectedUsers([])
        })
        .finally(() => {
          setLoading(false)
          store.uiStore.setGlobalLoader(false)
          handleClose()
        })
    } else {
      if (
        isEmpty(role) &&
        projectMemberDetails.addProjectMemberDetails.length === 0
      ) {
        setNoMembersAdded(true)
        setNoRoleAdded(true)
      } else if (!isEmpty(role)) {
        setNoMembersAdded(true)
      } else setNoRoleAdded(true)
      setLoading(false)
      store.uiStore.setGlobalLoader(false)
    }
  }

  return (
    <Popup
      open={open}
      headerText={`Assign Members to ${store.scopeStore.getProjectName(
        projectId
      )}`}
      handleClose={() => {
        if (!loading) {
          handleClose()
          setSelectedUsers([])
        }
        setRole('')
        setNoMembersAdded(false)
        setNoRoleAdded(false)
      }}
      headerIcon={<img src={addProjectMemberIcon} alt='projectMemberIcon' />}
      width='750px'
      height='300px'
      disabled={loading}
      destroyOnClose={true}
    >
      <PopUpSectionWrapper>
        <Left>
          <Typography
            align='left'
            variant='h5'
            fontWeight={palette.fontWeight.medium}
            style={{
              paddingBottom: '10px',
              lineHeight: '51px',
              fontSize: '1.4em'
            }}
          >
            Assign{' '}
            <span
              style={{
                fontWeight: palette.fontWeight.bold,
                color: palette.colors.primary
              }}
            >
              Members
            </span>
          </Typography>
          <Typography
            style={{ maxWidth: '320px', fontSize: '1em' }}
            variant='body1'
          >
            Assign Members from your organization to be part of your project
          </Typography>
        </Left>
        <Right>
          <ItemContainer>
            {/* <ChipInputField
              placeholder='Enter Member Name/Email'
              label='Add Member'
              fullWidth
            /> */}
            <MultiSelect
              options={options}
              value={selectedUsers}
              optionsLabelAccessor={(user: any) => user.name}
              onChange={(users) => {
                setSelectedUsers(users)
                setOpenMultiSelect(false)
                setOptions([])
              }}
              onDeleteSelection={({ id }) =>
                !loading
                  ? setSelectedUsers(
                      selectedUsers.filter((option: any) => option.id !== id)
                    )
                  : ''
              }
              validationError={
                noMembersAdded &&
                ValidationConstants.NO_MEMBERS_ADDED.PROJECT_MEMBERS
              }
              placeholder="Enter member's name/email"
              label='Add Member'
              onTextChange={(value) => {
                if (value === '') return
                memberLookUpFunction(value)
              }}
              openDropdown={openMultiSelect}
              setOpenDropdown={setOpenMultiSelect}
              loadingOption={loadOptions}
              disabled={loading}
            />
          </ItemContainer>
          <ItemContainer>
            <Dropdown
              title={'Role'}
              variant='default'
              dropdownOptions={CustomDropdownOptions}
              handleChange={handleDropdown}
              width='100%'
              UpArrowIcon={
                <UpArrow
                  style={{
                    height: '21px',
                    width: '21px',
                    color: '#757575'
                  }}
                />
              }
              DownArrowIcon={
                <DownArrow
                  style={{
                    height: '21px',
                    width: '21px',
                    color: '#757575'
                  }}
                />
              }
              helperText={noRoleAdded ? ValidationConstants.NO_ROLE_ADDED : ''}
              error={noRoleAdded}
              disabled={loading}
            />
            {/* <CustomDropdown
                title={ProjectMemberRoles[1] || 'role'}
                dropdownOptions={CustomDropdownOption}
                handleDropdown={(id: string) => {
                  handleDropdown(id)
                }}
                border={`1px solid ${palette.colors.borderColor}`}
              /> */}
            {/* <div>
              <p
                style={{
                  fontSize: '0.67rem',
                  color: palette.colors.error,
                  margin: '3px 14px',
                  fontWeight: '400px',
                }}
              >
                {noRoleAdded && ValidationConstants.NO_ROLE_ADDED}
              </p>
            </div> */}
          </ItemContainer>
          <ItemContainer>
            <Button
              variant='contained'
              endIcon={<IoIosArrowForward size={'20px'} />}
              loading={loading}
              onClick={handleAddMembers}
              fullWidth
            >
              Assign Members
            </Button>
          </ItemContainer>
        </Right>
      </PopUpSectionWrapper>
    </Popup>
  )
}

export default AssignMembersPopup
