import {
  acceptUserInvite,
  findAllMyUserInvites,
  rejectUserInvite
} from '../api/userInvites/UserInvites.service'
import { useEffect, useState } from 'react'

import HttpConstants from '../constants/HttpConstants'
import InvitePopup from '../components/InvitePopup/InvitePopup'
import NoData from '../components/NoData/NoData'
import PageLoadingOverlay from '../components/common/PageLoadingOverlay/PageLoadingOverlay'
import React from 'react'
import StringConstants from '../constants/StringConstants'
import { ToastMessageConstants } from '../constants/ToastMessageConstants'
import ToastNotification from '../components/common/DDS/Toast/Toast'
import { UrlWithOnlyOrgId } from '../utils/UrlUtils'
import { addQueryParamsToUrl } from '../utils/UrlGenerator'
import { isEmpty } from 'lodash'
import routeConstants from '../constants/RouteConstants'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../store/StoreContext'

export const UserInvite = () => {
  const [recentInvite, setRecentInvite] = useState<any>()
  const [loading, setLoading] = useState(false)
  const store = useStoreContext()
  const navigate = useNavigate()
  useEffect(() => {
    setLoading(true)
    findAllMyUserInvites()
      .then((response: any) => {
        if (response.data.length > 0) {
          setRecentInvite(response.data[0])
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SOMETHING_WENT_WRONG
        })
      })
  }, [])

  const handleAcceptUserInvite = () => {
    acceptUserInvite({ $token: recentInvite.token })
      .then(() => {
        store.scopeStore.setOrganizationId(recentInvite.organization.id)
        localStorage.removeItem(
          StringConstants.LOCAL_STORAGE.CURRENT_PAGE_DETAILS
        )
        store.scopeStore.setProjectId('')
        store.scopeStore.setEnvironmentId('')
        navigate(
          addQueryParamsToUrl(
            routeConstants.REDIRECT,
            {
              redirect: UrlWithOnlyOrgId(routeConstants.MEMBER_SETTINGS, store)
            },
            HttpConstants.GET_METHOD
          )
        )
      })
      .catch((err) =>
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SOMETHING_WENT_WRONG
        })
      )
  }

  const handleRejectUserInvite = () => {
    rejectUserInvite({ $token: recentInvite.token })
      .then(() => {
        navigate(routeConstants.REDIRECT)
      })
      .catch((err) =>
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SOMETHING_WENT_WRONG
        })
      )
  }

  return loading ? (
    <PageLoadingOverlay />
  ) : isEmpty(recentInvite) ? (
    <>
      <NoData emptyText={false} />
    </>
  ) : (
    <InvitePopup
      open
      role={recentInvite.role}
      project={recentInvite.project ? recentInvite.project.name : undefined}
      organization={recentInvite.organization.name}
      handleAcceptInvite={handleAcceptUserInvite}
      handleRejectInvite={handleRejectUserInvite}
      handleClose={() => navigate(routeConstants.REDIRECT)}
    />
  )
}
