const FilterConstants = {
  JOB: [
    'page',
    'monitor-job',
    'sort-job-name',
    'sort-created-at',
    'created-from',
    'created-by',
    'created-till',
    'name-like',
    'description-like',
    'cron-like',
    'created-to',
    'previous-run-status',
    'sort',
    'time-zone'
  ],
  JOB_LOG: [
    'status',
    'sort-start-time',
    'sort-stop-time',
    'end-date',
    'start-date',
    'started-till',
    'started-from',
    'stopped-from',
    'stopped-till',
    'time-zone',
    'page'
  ],
  API_MONITORING: [
    'sort-start-time',
    'sort-end-time',
    'created-from',
    'created-to',
    'response-status-code',
    'page',
    'size',
    'url-like',
    'url-template',
    'http-method',
    'sort',
    'time-zone',
    'api-group-id'
  ],
  PROJECT: [
    'sort-by-name',
    'sort-created-at',
    'created-from',
    'created-to',
    'page',
    'size',
    'expand-created-user',
    'created-by',
    'time-zone'
  ],
  ENVIRONMENT: [
    'sort-by-name',
    'sort-created-at',
    'created-from',
    'created-to',
    'created-by',
    'page',
    'size',
    'type',
    'expand-created-user',
    'time-zone'
  ],
  EXISTING_MEMBERS: ['role', 'page', 'size', 'expand-user', 'time-zone'],
  ERROR_MONITORING: [
    'status',
    'is-bookmarked',
    'exception-status',
    'exception-type',
    'is-public',
    'notify-frequency',
    'severity',
    'sort-created-at',
    'assigned-to',
    'created-from',
    'created-to',
    'updated-from',
    'updated-to',
    'expand-activity',
    'page',
    'size',
    'exception-type-like',
    'exception-message-like',
    'sort',
    'time-zone'
  ],
  INVITED_MEMBERS: [
    'sort-by-inviter',
    'sort-by-invitee',
    'role',
    'page',
    'size',
    'status',
    'time-zone'
  ],
  INCIDENT_MANAGEMENT: ['page', 'size'],
  AHC: [
    'page',
    'created-from',
    'created-to',
    'previous-check-status',
    'name-like',
    'url-like',
    'sort',
    'time-zone'
  ],
  AHC_LOGS: [
    'created-from',
    'created-to',
    'app-health-status',
    'sort-created-at',
    'time-zone'
  ],
  NOTIFICATIONS: [
    'page',
    'size',
    'created-from',
    'created-to',
    'status',
    'time-zone'
  ],
  SEARCH_QUERY: [
    'name-like',
    'description-like',
    'cron-like',
    'url-like',
    'exception-type-like',
    'exception-message-like',
    'name-like'
  ]
}

export default FilterConstants
