import React, { useEffect, useState } from 'react'
import {
  getValidationError,
  getValidationStatus
} from '../../../utils/ValidationUtils'
import {
  updateOrganization,
  uploadOrganizationAvatar
} from '../../../api/organization/Organization.service'

import { BsInfoCircle } from 'react-icons/bs'
import DashboardSectionContainer from '../../common/DashboardSectionContainer/DashboardSectionContainer'
import { FileUploader } from 'react-drag-drop-files'
import InputField from '../../common/InputField/InputField'
import Joi from 'joi'
import { OrganizationTypeDisplayNames } from '../../../enum/OrganizationType.enum'
import { OrganizationUserRoles } from '../../../enum/OrganizationUserRoles.enum'
import RegexConstants from '../../../constants/RegexConstants'
import Spinner from '../../common/Spinner/Spinner'
import StringConstants from '../../../constants/StringConstants'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import { Tooltip } from '@mui/material'
import { TypographyDDS } from '../../common/Typography/TypographyDDS'
import UrlConstants from '../../../constants/UrlConstants'
import ValidationConstants from '../../../constants/ValidationConstants'
import breakpoints from '../../../global/breakpoints'
import { checkNameAvailability } from '../../../api/common/Common.service'
import { isUndefined } from 'lodash'
import palette from '../../../global/pallete'
import pictureIcon from '../../../assets/icons/draganddropicon.svg'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import { uploadUserAvatar } from '../../../api/user/User.service'
import { useStoreContext } from '../../../store/StoreContext'

interface ParaProps {
  padding?: string
  lineHeight?: number
  fontSize?: string
  margin?: string
  maxWidth?: string
}

const Layout = styled.div`
  margin-bottom: 16px;
`
const Container = styled.div`
  display: flex;
`

const LeftSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const RightSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  max-width: 352px;
  width: 100%;
`
const Spacer = styled.div`
  flex-grow: 1;
`
const AvatarWrapper = styled.div`
  width: 106px;
  height: 106px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ContainedImg = styled.img`
  object-fit: fill;
  max-height: 104px;
  max-width: 104px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
`

const MarginDiv = styled.div`
  margin: 2.5em 0;

  @media screen and (max-width: ${breakpoints.lg}) {
    margin: 1.5em 0;
  }
`
const ContainerInput = styled.div<{ $loading?: boolean; disabled?: boolean }>`
  cursor: ${(props) =>
    props.$loading || props.disabled ? 'not-allowed' : 'pointer'};
  padding: 4em 0.75em;
  border: 2px dashed ${palette.colors.borderColor};
  input[type='file'] {
    display: none;
  }
  .custom-file-upload {
    display: inline-block;
    padding: 0.375em 3.125em;
  }
  label {
    padding: 4em 0.75em;
    border-radius: 12px;

    pointer-events: ${(props) => (props.$loading ? 'none' : '')};
    cursor: inherit;
  }
  border-radius: 12px;
`

const SettingsRight = styled.div`
  min-width: 30%;
  padding-right: 0em;
  direction: ltr;

  @media screen and (max-width: ${breakpoints.lg}) {
    padding-right: 0;
  }
`
const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
`

const GeneralSection: React.FC<{
  data: any
  loading: boolean
  setLoading: any
}> = ({ data, loading, setLoading }) => {
  const fileTypes = ['JPG', 'PNG', 'JPEG']
  const [organizationName, setOrganizationName] = useState<string>('')
  const [organizationType, setOrganizationType] = useState<string>('')
  useEffect(() => {
    setOrganizationName(data.name)
    setOrganizationType(data.type)
    storeContext.uiStore.setGlobalLoader(false)
    setIsInvalidOrganizationName(false)
    setOrganizationErrorText('')
  }, [data])

  const ResponsiveFileUploader = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
  `
  const [avatar, setAvatar] = useState<any>()
  const [validationErrorResult, setValidationErrorResult] = useState<any[]>([])
  const [uploadAvatarLoader, setUploadAvatarLoader] = useState<boolean>(false)
  const storeContext = useStoreContext()
  const [organizationErrorText, setOrganizationErrorText] = useState<string>('')
  const [isInvalidOrganizationName, setIsInvalidOrganizationName] =
    useState(false)
  const referenceForNameInput = React.useRef<any>(null)
  const nameSchema = Joi.object({
    organizationName: Joi.string()
      .pattern(RegexConstants.NAME_REGEX)
      .required()
      .messages({
        'string.pattern.base': ValidationConstants.GENERIC.INVALID_NAME_REGEX
      })
  })
  const updateOrganizationName = (e: any) => {
    const organization = storeContext.userStore
      .getOrganizations()
      .filter((org) => org.id === storeContext.scopeStore.getOrganizationId())
    if (organization[0].name === organizationName) {
      setOrganizationErrorText('')
      setValidationErrorResult([])
      setIsInvalidOrganizationName(false)
      return
    }
    const validationResult = nameSchema.validate(
      { organizationName: organizationName.trim() },
      { abortEarly: false }
    )
    if (isUndefined(validationResult.error)) {
      setOrganizationErrorText('')
      referenceForNameInput.current?.startLoading()
      setLoading(true)
      checkNameAvailability(
        UrlConstants.CHECK_ORGANIZATION_NAME.USECASE,
        organizationName.trim(),
        { type: organizationType }
      )
        .then(() => {
          updateOrganization({ name: organizationName })
            .then((response: any) => {
              setOrganizationName(response.data.name)
              const updatedOrganization = storeContext.userStore
                .getOrganizations()
                .map((organization: any) => {
                  if (organization.id === response.data.id)
                    organization.name = response.data.name
                  return organization
                })
              storeContext.userStore.setOrganizations(updatedOrganization)
              storeContext.userStore.setSelectedOrganization({
                ...storeContext.userStore.getSelectedOrganization(),
                name: response.data.name
              })
              ToastNotification({
                type: 'success',
                message:
                  ToastMessageConstants.SETTINGS.UPDATE_ORGANIZATION.SUCCESS
              })
            })
            .catch((err: any) => {
              ToastNotification({
                type: 'error',
                message:
                  ToastMessageConstants.SETTINGS.UPDATE_ORGANIZATION.ERROR
              })
              setOrganizationName(
                storeContext.userStore.getSelectedOrganization().name
              )
            })
            .finally(() => {
              setLoading(false)
              referenceForNameInput.current?.stopLoading()
            })
        })
        .catch((error) => {
          referenceForNameInput.current?.stopLoading()
          setLoading(false)
          if (error === StringConstants.NAME_ALREADY_TAKEN) {
            setIsInvalidOrganizationName(true)
            setOrganizationErrorText(
              ValidationConstants.ORGANIZATION.ORGANIZATION_NAME_ERROR
            )
          } else {
            ToastNotification({
              type: 'error',
              message: ToastMessageConstants.SOMETHING_WENT_WRONG
            })
          }
        })
    } else {
      if (validationResult.error.details[0].type === 'string.pattern.base')
        setOrganizationErrorText(
          ValidationConstants.ORGANIZATION.ORGANIZATION_NAME_INVALID
        )
      else
        setOrganizationErrorText(
          ValidationConstants.ORGANIZATION.ORGANIZATION_NAME_EMPTY
        )

      setLoading(false)
    }
  }
  const handleSubmit = (file: any) => {
    if (storeContext.userStore.getUserRole() === OrganizationUserRoles.OWNER) {
      if (loading || storeContext.uiStore.getGlobalLoader()) return
      const formData = new FormData()
      formData.append('file', file)
      setLoading(true)
      storeContext.uiStore.setGlobalLoader(true)
      setUploadAvatarLoader(true)
      uploadOrganizationAvatar(formData)
        .then((orgData: any) => {
          ToastNotification({
            type: 'success',
            message: ToastMessageConstants.SETTINGS.UPLOAD_AVATAR.SUCCESS
          })
          storeContext.userStore.setSelectedOrganization({
            ...storeContext.userStore.getSelectedOrganization(),
            avatar: orgData.data.avatar
          })
          storeContext.userStore.setAvatarById(
            orgData.data.id,
            orgData.data.avatar
          )
        })
        .catch((err: string) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.SETTINGS.UPLOAD_AVATAR.ERROR
          })
        })
        .finally(() => {
          setUploadAvatarLoader(false)
          storeContext.uiStore.setGlobalLoader(false)
          setLoading(false)
        })
    }
  }
  return (
    <Layout>
      <DashboardSectionContainer headerText='General'>
        <Container>
          <LeftSectionContainer>
            <TypographyDDS.Title type='h5' variant='bold'>
              Organization Name
            </TypographyDDS.Title>
            <TypographyDDS.Title
              type='h5'
              variant='medium'
              color='textDark3'
              style={{ marginTop: '16px' }}
            >
              Customize each organization with a unique name
            </TypographyDDS.Title>

            {/* Organization Type */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px'
              }}
            >
              <TypographyDDS.Title
                type='h5'
                variant='bold'
                style={{ marginTop: '50px' }}
              >
                Organization Type
              </TypographyDDS.Title>
              <Tooltip
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: `${palette.colors.white}`,
                      boxShadow: `0px 16px 25px ${palette.colors.tooltipShadow}`
                    }
                  }
                }}
                title={
                  <TypographyDDS.Paragraph
                    size='caption'
                    variant='semiBold'
                    color='textDark2'
                    style={{ textAlign: 'center' }}
                  >
                    <a
                      style={{
                        color: '#066CFF'
                      }}
                      href={`mailto:${StringConstants.SUPPORT_EMAIL}`}
                    >
                      Request support to change type
                    </a>
                  </TypographyDDS.Paragraph>
                }
                placement='right'
              >
                <div style={{ alignSelf: 'flex-end' }}>
                  <BsInfoCircle />
                </div>
              </Tooltip>
            </div>
            <TypographyDDS.Title
              type='h5'
              variant='medium'
              color='textDark3'
              style={{ marginTop: '16px' }}
            >
              Salesforce Ecosystem Only / Full-Stack Only / Both
            </TypographyDDS.Title>

            {/* Avatar */}
            <TypographyDDS.Title
              type='h5'
              variant='bold'
              style={{ marginTop: '50px' }}
            >
              Organization Avatar
            </TypographyDDS.Title>
            <TypographyDDS.Title
              type='h5'
              variant='medium'
              color='textDark3'
              style={{ marginTop: '16px' }}
            >
              Assign an avatar for your organization
            </TypographyDDS.Title>
            <AvatarContainer>
              <AvatarWrapper>
                {uploadAvatarLoader ? (
                  <Spinner noMarginTop />
                ) : (
                  <ContainedImg
                    src={data.avatar}
                    height={'106px'}
                    width={'106px'}
                  />
                )}
              </AvatarWrapper>
              <TypographyDDS.Paragraph
                size='caption'
                style={{
                  marginLeft: '24px'
                }}
              >
                <i>
                  Note:
                  <br />
                  <li>
                    JPG, JPEG and PNG are allowed <br />
                  </li>
                  <li>Maximum upload file size - 1MB</li>
                </i>
              </TypographyDDS.Paragraph>
            </AvatarContainer>
          </LeftSectionContainer>
          <Spacer />
          <RightSectionContainer>
            <InputField
              error={
                getValidationStatus(
                  validationErrorResult,
                  'organizationName'
                ) || isInvalidOrganizationName
              }
              width='100%'
              label='Organization Name'
              size='medium'
              onChange={(e) => {
                setOrganizationName(e.target.value)
              }}
              helperText={organizationErrorText}
              value={organizationName}
              onBlur={updateOrganizationName}
              disabled={
                storeContext.userStore.getNoActiveSubscription() ||
                loading ||
                storeContext.userStore.getUserRole() !==
                  OrganizationUserRoles.OWNER
              }
              maxLength={StringConstants.NAME_FIELD_MAX_LENGTH}
              ref={referenceForNameInput}
            />
            <div style={{ marginTop: '64px' }}>
              <InputField
                width='100%'
                label='Organization Type'
                size='medium'
                value={
                  organizationType
                    ? OrganizationTypeDisplayNames[
                        organizationType as keyof typeof OrganizationTypeDisplayNames
                      ]
                    : '-'
                }
                disabled={true}
              />
            </div>

            <SettingsRight>
              {/* Input type = file styling */}
              <MarginDiv />
              <ResponsiveFileUploader>
                <FileUploader
                  disabled={
                    storeContext.userStore.getUserRole() !==
                      OrganizationUserRoles.OWNER ||
                    loading ||
                    storeContext.uiStore.getGlobalLoader() ||
                    storeContext.userStore.getNoActiveSubscription()
                  }
                  hoverTitle=' '
                  handleChange={handleSubmit}
                  name='file'
                  types={fileTypes}
                  maxSize={1}
                  onTypeError={(error: any) =>
                    ToastNotification({
                      type: 'error',
                      message:
                        ToastMessageConstants.SETTINGS.UPLOAD_AVATAR.ERROR
                    })
                  }
                  onSizeError={(error: any) =>
                    ToastNotification({
                      type: 'error',
                      message:
                        ToastMessageConstants.SETTINGS.UPLOAD_AVATAR.ERROR
                    })
                  }
                >
                  <ContainerInput
                    disabled={
                      storeContext.userStore.getUserRole() !==
                        OrganizationUserRoles.OWNER ||
                      storeContext.userStore.getNoActiveSubscription()
                    }
                  >
                    <label htmlFor='file-upload' className='custom-file-upload'>
                      <i className='fa fa-cloud-upload'></i>
                      <div
                        style={{
                          display: 'flex',
                          gridGap: '10px',
                          width: 'max-content'
                        }}
                      >
                        <img
                          src={pictureIcon}
                          style={{
                            fontSize: '20px',
                            color: `${palette.colors.textDark2}`
                          }}
                        />{' '}
                        <TypographyDDS.Paragraph
                          size='para'
                          color='textDark3'
                          style={{ alignSelf: 'center' }}
                        >
                          Drag and drop or
                          <span
                            style={{
                              fontWeight: palette.fontWeight.bold,
                              color: palette.colors.primary
                            }}
                          >
                            {' '}
                            browse files
                          </span>
                        </TypographyDDS.Paragraph>
                      </div>
                    </label>
                  </ContainerInput>
                </FileUploader>
              </ResponsiveFileUploader>
            </SettingsRight>
          </RightSectionContainer>
        </Container>
      </DashboardSectionContainer>
    </Layout>
  )
}

export default GeneralSection
