import Button from '../common/Button/Button'
import CustomDropdown from '../common/Dropdown/CustomDropdown'
import CustomErrorToast from '../common/Toast/CustomErrorToast'
import { DDSToastNotification } from 'den-design-system'
import { OrganizationUserRoles } from '../../enum/OrganizationUserRoles.enum'
import React from 'react'
import { RefObject } from '../common/InputField/Interface/Interface'
import StringConstants from '../../constants/StringConstants'
import Table from '../common/Table/Table'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../common/DDS/Toast/Toast'
import { TypographyDDS } from '../common/Typography/TypographyDDS'
import UserRoles from '../../constants/UserRoles'
import palette from '../../global/pallete'
import resendIcon from '../../assets/icons/renew.svg'
import routeConstants from '../../constants/RouteConstants'
import styled from '@emotion/styled'
import { timeFormats } from '../../enum/TIME'
import { timeZone } from '../../utils/DateTimeUtils'
import { toast } from 'react-toastify'
import trashIcon from '../../assets/icons/trash.svg'
import { updateOrganizationMember } from '../../api/organization/Organization.service'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../store/StoreContext'

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const RoleDropdownContainer = styled.div`
  display: flex;
  justify-content: center;
  // z-index: 99;
  overflow: visible;
`

const MembersTable: React.FC<{
  data: []
  invitedUser?: boolean
  rowsPerPage?: number
  currentPage?: number
  onPageChange?: (page: number) => void
  totalCount?: number
  handleRemoveMember?: (member: any) => void
  handleResendInvite?: (member: any) => void
  fetchMembers: any
  updateMembersRole?: (flag: boolean) => void
}> = ({
  data,
  handleRemoveMember = () => null,
  handleResendInvite = () => null,
  updateMembersRole = () => null,
  invitedUser,
  rowsPerPage = StringConstants.DEFAULT_TABLE_SIZE,
  currentPage = 0,
  onPageChange = () => null,
  totalCount,
  fetchMembers
}) => {
  const store = useStoreContext()
  if (data.length > 0 || store.filterStore.getFiltersApplied() === true) {
    store.filterStore.setFilterDisabled(false)
  } else {
    store.filterStore.setFilterDisabled(true)
  }

  // TODO Varun - Use OrganizationUserRoles to render the drop down items
  // TODO Billing settings hidden for beta
  const CustomDropdownOption = UserRoles.map((role, index) => ({
    id: index,
    name: role
  }))
  const [loading, setLoading] = React.useState<boolean>(false)
  const [dropdownLoading, setDropdownLoading] = React.useState<boolean>(false)

  const roleDropdownReference = React.useRef<RefObject>(null)
  const navigate = useNavigate()

  const handleDropdown = (role: string, _userId: string) => {
    // TODO Varun - Do not convert the value to upper case, instead have an attribute called 'value' in CustomDropdownOption which holds the actual role value (like OWNER, MEMBER)
    role = role.toUpperCase()
    setLoading(true)
    updateMembersRole(true)
    roleDropdownReference.current?.startLoading()
    setDropdownLoading(true)
    updateOrganizationMember(role, _userId)
      .then(() => {
        fetchMembers()
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.SETTINGS.MEMBERS.UPDATE_MEMBERS.SUCCESS
        })

        if (storeContext.userStore.id === _userId) {
          storeContext.userStore.setUserRole(role)
          navigate(routeConstants.REDIRECT)
        }
        setLoading(false)
        updateMembersRole(false)
        roleDropdownReference.current?.stopLoading()
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SETTINGS.MEMBERS.UPDATE_MEMBERS.ERROR
        })
        setLoading(false)
        updateMembersRole(false)
        roleDropdownReference.current?.stopLoading()
        fetchMembers()
      })
  }
  const RoleDropdownTitle = () => (
    <>
      <TypographyDDS.Paragraph
        size='para'
        variant='semiBold'
        color={palette.colors.textDark}
      >
        Assign User Role
      </TypographyDDS.Paragraph>
    </>
  )
  const storeContext = useStoreContext()
  const column = [
    invitedUser
      ? {
          title: 'Inviter',
          label: 'inviter',
          render: (inviter: any) => {
            return (
              <UserContainer>
                <TypographyDDS.Paragraph
                  size='para'
                  variant='medium'
                  color='textDark'
                >
                  {inviter.firstName + ' ' + inviter.lastName}
                </TypographyDDS.Paragraph>
                <TypographyDDS.Paragraph size='para'>
                  {inviter.email}
                </TypographyDDS.Paragraph>
              </UserContainer>
            )
          }
        }
      : {
          title: '',
          label: '',
          render: (item: any) => {
            return <></>
          }
        },

    !invitedUser
      ? {
          title: 'User',
          label: 'user',
          render: (user: any) => {
            return (
              <UserContainer>
                <TypographyDDS.Paragraph
                  size='para'
                  variant='medium'
                  color='textDark'
                >
                  {user.firstName + ' ' + user.lastName}
                </TypographyDDS.Paragraph>
                <TypographyDDS.Paragraph
                  size='para'
                  variant='medium'
                  color='textDark2'
                >
                  {user.email}
                </TypographyDDS.Paragraph>
              </UserContainer>
            )
          }
        }
      : {
          title: 'Invitee',
          label: 'invitee',
          render: (invitee: any) => {
            return (
              <UserContainer>
                <TypographyDDS.Paragraph size='para'>
                  {invitee}
                </TypographyDDS.Paragraph>
              </UserContainer>
            )
          }
        },

    {
      title: 'Role',
      label: 'role',
      render: (role: any) => {
        return (
          <RoleDropdownContainer>
            <TypographyDDS.Paragraph size='para' color='textDark'>
              {role[0] + role.slice(1).toLowerCase()}
            </TypographyDDS.Paragraph>
          </RoleDropdownContainer>
        )
      }
    },
    !invitedUser
      ? {
          title: 'Member since',
          label: 'createdAt',
          render: (item: any) => {
            return timeZone(
              storeContext.userStore.getUserPreference().timeZone,
              item,
              timeFormats.monthDateYear
            )
          }
        }
      : {
          title: 'Invited on',
          label: 'createdAt',

          render: (item: any, fullItem: any) => {
            if (fullItem.resendCount > 0) {
              return timeZone(
                storeContext.userStore.getUserPreference().timeZone,
                fullItem.updatedAt,
                timeFormats.fullMonthDateYearTime
              )
            } else {
              return timeZone(
                storeContext.userStore.getUserPreference().timeZone,
                item,
                timeFormats.fullMonthDateYearTime
              )
            }
          }
        }
  ]
  const ownerColumn = [
    invitedUser
      ? {
          title: 'Inviter',
          label: 'inviter',
          render: (inviter: any) => {
            return (
              <UserContainer>
                <TypographyDDS.Paragraph
                  size='para'
                  variant='medium'
                  color='textDark'
                >
                  {inviter.firstName + ' ' + inviter.lastName}
                </TypographyDDS.Paragraph>
                <TypographyDDS.Paragraph size='para'>
                  {inviter.email}
                </TypographyDDS.Paragraph>
              </UserContainer>
            )
          }
        }
      : {
          title: '',
          label: '',
          render: (item: any) => {
            return <></>
          }
        },
    !invitedUser
      ? {
          title: 'User',
          label: 'user',
          render: (user: any) => {
            return (
              <UserContainer>
                <TypographyDDS.Paragraph
                  size='para'
                  variant='medium'
                  color='textDark'
                >
                  {user.firstName + ' ' + user.lastName}
                </TypographyDDS.Paragraph>
                <TypographyDDS.Paragraph size='para'>
                  {user.email}
                </TypographyDDS.Paragraph>
              </UserContainer>
            )
          }
        }
      : {
          title: 'Invitee',
          label: 'invitee',
          render: (invitee: any) => {
            return (
              <UserContainer>
                <TypographyDDS.Paragraph size='para'>
                  {invitee}
                </TypographyDDS.Paragraph>
              </UserContainer>
            )
          }
        },
    {
      title: 'Role',
      label: 'role',
      render: (item: any, fullItem: any) => {
        return (
          <>
            {!invitedUser ? (
              <RoleDropdownContainer>
                <CustomDropdown
                  title={
                    fullItem.role[0] + fullItem.role.slice(1).toLowerCase() || // TODO Varun - Use lodash startCase with lowerCase instead of this.
                    'role'
                  }
                  dropdownOptions={CustomDropdownOption}
                  // TODO Varun - handle dropdown according to the above mentioned changes
                  handleDropdown={(id: string) => {
                    handleDropdown(id, fullItem.user.id)
                  }}
                  upperChildrenComponents={<RoleDropdownTitle />}
                  border={`1px solid ${palette.colors.borderColor}`}
                  fontWeight={`${palette.fontWeight.medium}`}
                  ref={roleDropdownReference}
                  disabled={
                    dropdownLoading || store.userStore.getNoActiveSubscription()
                  }
                />
              </RoleDropdownContainer>
            ) : (
              <>
                <RoleDropdownContainer>
                  <TypographyDDS.Paragraph size='para' color='textDark'>
                    {fullItem.role[0] + fullItem.role.slice(1).toLowerCase()}
                  </TypographyDDS.Paragraph>
                </RoleDropdownContainer>
              </>
            )}
          </>
        )
      }
    },
    !invitedUser
      ? {
          title: 'Member since',
          label: 'createdAt',
          render: (item: any) => {
            return timeZone(
              storeContext.userStore.getUserPreference().timeZone,
              item,
              timeFormats.monthDateYear
            )
          }
        }
      : {
          title: 'Invited on',
          label: 'createdAt',
          render: (item: any, fullItem: any) => {
            if (fullItem.resendCount > 0) {
              return timeZone(
                storeContext.userStore.getUserPreference().timeZone,
                fullItem.updatedAt,
                timeFormats.fullMonthDateYearTime
              )
            } else {
              return timeZone(
                storeContext.userStore.getUserPreference().timeZone,
                item,
                timeFormats.fullMonthDateYearTime
              )
            }
          }
        },

    {
      title: '',
      label: 'id',
      render: (item: any, fullItem: any) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '15px'
            }}
          >
            {invitedUser && (
              <Button
                startIcon={
                  <img
                    src={resendIcon}
                    style={{
                      filter: palette.colors.whiteSvgFilter
                    }}
                  />
                }
                color='primary'
                variant='contained'
                disabled={store.userStore.getNoActiveSubscription()}
                onClick={() => handleResendInvite(fullItem)}
                size='small'
              >
                {'Resend Invite'}
              </Button>
            )}
            <Button
              startIcon={
                <img
                  src={trashIcon}
                  style={{
                    filter: palette.colors.whiteSvgFilter
                  }}
                />
              }
              color='error'
              variant='contained'
              onClick={() => handleRemoveMember(fullItem)}
              size='small'
              disabled={
                storeContext.userStore.getNoActiveSubscription() || loading
              }
            >
              {!invitedUser ? 'Remove' : 'Revoke'}
            </Button>
          </div>
        )
      }
    }
  ]

  return (
    <Table
      data={data}
      columns={
        storeContext.userStore.getUserRole() == OrganizationUserRoles.OWNER
          ? ownerColumn
          : column
      }
      rowsPerPage={rowsPerPage}
      currentPage={currentPage}
      onPageChange={onPageChange}
      totalCount={totalCount}
    />
  )
}

export default MembersTable
