import { Outlet } from 'react-router-dom'
import ProjectPageNavigation from '../common/ProjectSettings/Subcomponents/ProjectPageNavigation.component'
import React from 'react'
import palette from '../../global/pallete'
import styled from '@emotion/styled'

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
`
const Wrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  padding-bottom: 20px;
`

const ProjectSettingsLayout = () => {
  return (
    <SectionWrapper>
      <ProjectPageNavigation />
      <Wrapper>
        <Outlet />
      </Wrapper>
    </SectionWrapper>
  )
}

export default ProjectSettingsLayout
