import {
  AtoB,
  UrlWithOnlyOrgId,
  getAllParamsAsStringFromUrl,
  replaceUrl
} from '../utils/UrlUtils'
import React, { useEffect, useState } from 'react'
import {
  deleteToken,
  getDataFromCookie,
  isAuthenticatedUser,
  saveCookie
} from '../utils/AuthUtils'
import { gaEventConstants, gaPageConstants } from '../constants/GAConstants'
import {
  getUserDetails,
  getUserPreference,
  resendVerification
} from '../api/user/User.service'
import { isEmpty, isNull, isObject, isUndefined } from 'lodash'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import Button from '../components/common/Button/Button'
import HttpConstants from '../constants/HttpConstants'
import { OrganizationType } from '../enum/OrganizationType.enum'
import { OrganizationUserRoles } from '../enum/OrganizationUserRoles.enum'
import PageLoadingOverlay from '../components/common/PageLoadingOverlay/PageLoadingOverlay'
import Popup from '../components/common/Popup/Popup'
import { ProjectType } from '../enum/ProjectType.enum'
import StringConstants from '../constants/StringConstants'
import { ToastMessageConstants } from '../constants/ToastMessageConstants'
import ToastNotification from '../components/common/DDS/Toast/Toast'
import { TypographyDDS } from '../components/common/Typography/TypographyDDS'
import { addQueryParamsToUrl } from '../utils/UrlGenerator'
import closeIcon from '../assets/icons/close.svg'
import { getOrganizationProjectsThingsToDo } from '../api/organization/Organization.service'
import { joinUserToRoom } from '../gateway'
import { login } from '../api/Auth.service'
import palette from '../global/pallete'
import routeConstants from '../constants/RouteConstants'
import styled from '@emotion/styled'
import successIcon from '../assets/icons/successIcon.svg'
import { useAnalyticsEventTracker } from '../utils/GAUtils'
import { useStoreContext } from '../store/StoreContext'

const RedirectScreen: React.FC = () => {
  const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    gap: 10px;
  `
  const ColorText = styled.div`
    color: ${palette.colors.primary};
    display: inline;
  `
  const location = useLocation()
  const isDemo = window.location.host.includes('demo.vigilnow.com')
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const storeContext = useStoreContext()
  const token = searchParams.get('token')
  const [loading, setLoading] = useState(false)

  const removeSandboxUserDetails = () => {
    localStorage.removeItem('project_type')
    localStorage.removeItem('tour_disabled')
    localStorage.removeItem('sandboxuser_email')
    localStorage.removeItem('sandboxuser_role')
  }

  const callRedirect = () => {
    const redirect = searchParams.get('redirect')
    if (token) {
      saveCookie(StringConstants.COOKIE_TOKEN, token)
      redirectUser(true)
    } else if (isAuthenticatedUser()) {
      if (redirect) {
        const otherParams = getAllParamsAsStringFromUrl(location.search, [
          'redirect'
        ])
        redirectUser(false, redirect + otherParams)
      } else {
        redirectUser()
      }
    } else {
      console.log('WINDOW LOCATIONS::::', window.location.host)
      //TODO paste it below sandbox url
      if (window.location.host.includes('demo.vigilnow.com')) {
        navigate(routeConstants.SANDBOX_SCREEN)
        removeSandboxUserDetails()
      } else {
        navigate(routeConstants.SIGN_IN)
      }
    }
  }
  const gaEventTracker = useAnalyticsEventTracker(gaPageConstants.VERIFY)

  useEffect(() => {
    if (token) {
      saveCookie(StringConstants.COOKIE_TOKEN, token as string)
      callRedirect()
    } else if (isUndefined(token) || isNull(token)) {
      callRedirect()
    }
  }, [])

  const demoLogin = () => {
    const email = 'demo-vigil@gmail.com'
    const pwd = 'Password1!'
    login({ email: email, password: pwd, captcha: null })
      .then((response: any) => {
        //TODO
        // store token in cookie
        // call redirectUser(false, 'overview' + a2b(org details))
        saveCookie(
          StringConstants.COOKIE_TOKEN,
          response?.data.accessToken as string
        )
        redirectUser()
      })
      .catch((err: string) => {
        console.log('ERROR ::::::::', err)
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.AUTH.SIGN_IN.ERROR
        })
      })
  }

  const redirectUser = (reload?: boolean, redirect?: string) => {
    const authToken = getDataFromCookie()
    if (!isEmpty(authToken)) {
      storeContext.uiStore.setGlobalLoader(true)
      getUserDetails()
        .then((response: any) => {
          setOpen(!response.data.isVerified)
          getUserPreference()
            .then((userPreference: any) => {
              storeContext.userStore.setUserPreference(userPreference.data)
            })
            .catch((err: string) => {
              ToastNotification({
                type: 'error',
                message:
                  ToastMessageConstants.PREFERENCE.GET_USER_PREFERENCE.ERROR
              })
            })
          storeContext.userStore.setId(response.data.id)
          storeContext.userStore.setEmail(response.data.email)
          storeContext.userStore.setAvatar(response.data.avatar)
          storeContext.userStore.setIsVerified(response.data.isVerified)
          storeContext.userStore.setOnBoardedVia(response.data.onBoardedVia)
          // const referrer = searchParams.get('referrer')
          let lastVisitedOrgDetails = localStorage.getItem(
            StringConstants.LOCAL_STORAGE.CURRENT_PAGE_DETAILS
          )

          let organizationId =
            storeContext.scopeStore.getScope()['organization-id']
          // if referrer and details in local storage exists
          if (lastVisitedOrgDetails) {
            lastVisitedOrgDetails = JSON.parse(lastVisitedOrgDetails)

            // if parsed local storage details exists
            if (
              isObject(lastVisitedOrgDetails)
              // lastVisitedOrgDetails['organization-id'] &&
              // lastVisitedOrgDetails['project-id'] &&
              // lastVisitedOrgDetails['environment-id']
            ) {
              // assign the last accessed org, project and env id
              storeContext.scopeStore.setOrganizationId(
                lastVisitedOrgDetails['organization-id']
              )
              organizationId = lastVisitedOrgDetails?.['organization-id']
              storeContext.scopeStore.setProjectId(
                lastVisitedOrgDetails?.['project-id']
              )
              storeContext.scopeStore.setEnvironmentId(
                lastVisitedOrgDetails?.['environment-id']
              )
            }
          } else if (!isUndefined(redirect) && !isNull) {
            const decodedUrlSplit = AtoB(redirect.split('/')[1]).split('/')
            storeContext.scopeStore.setOrganizationId(decodedUrlSplit[0])
            organizationId = decodedUrlSplit[0]
            if (decodedUrlSplit[1])
              storeContext.scopeStore.setProjectId(decodedUrlSplit[1])
            if (decodedUrlSplit[2])
              storeContext.scopeStore.setEnvironmentId(decodedUrlSplit[2])
          }

          const selectedOrganization = response.data.organizations.filter(
            (record: any) => record.id === organizationId
          )
          if (selectedOrganization.length === 1) {
            storeContext.userStore.setUserRole(
              selectedOrganization[0].membership.role
            )
            storeContext.userStore.setSelectedOrganization(
              selectedOrganization[0]
            )
            if (!selectedOrganization[0].subscription) {
              storeContext.userStore.setNoActiveSubscription(true)
            }
          } else if (response.data.organizations.length > 0) {
            storeContext.userStore.setUserRole(
              response.data.organizations[0].membership.role
            )

            storeContext.scopeStore.setOrganizationId(
              response.data.organizations[0].id
            )
            if (!response.data.organizations[0].subscription) {
              storeContext.userStore.setNoActiveSubscription(true)
            }
          }
          storeContext.userStore.setFirstName(response.data.firstName)
          storeContext.userStore.setLastName(response.data.lastName)
          storeContext.userStore.setOrganizations(response.data.organizations)

          joinUserToRoom(response.data.id)
          if (open || response.data.isVerified || isNull(token)) {
            if (response.data.organizations.length === 0) {
              if (
                [
                  routeConstants.ACCOUNT_SETTINGS,
                  routeConstants.NOTIFICATIONS,
                  routeConstants.INVITES
                ].includes(redirect?.split('?')[0] as string)
              )
                navigate(redirect as string)
              else navigate(routeConstants.NO_ORGANIZATION)
            } else if (
              selectedOrganization &&
              selectedOrganization[0]?.projectCount === 0 &&
              storeContext.userStore.getUserRole() !==
                OrganizationUserRoles.BILLING
            ) {
              if (
                [routeConstants.NOTIFICATIONS, routeConstants.INVITES].includes(
                  redirect?.split('?')[0] as string
                )
              )
                navigate(redirect as string)
              else {
                storeContext.uiStore.setGlobalLoader(false)
                storeContext.uiStore.setprojectEnvironmentLoader(false)
                navigate(
                  UrlWithOnlyOrgId(routeConstants.NO_PROJECT, storeContext)
                )
              }
            } else if (redirect) {
              if (redirect === '/') {
                localStorage.setItem(
                  StringConstants.ACTIVE_TAB,
                  StringConstants.TAB_VALUES.OVERVIEW
                )
                navigate(
                  replaceUrl(
                    routeConstants.OVERVIEW,
                    storeContext.scopeStore.getScope()[
                      'organization-id'
                    ] as string,
                    storeContext.scopeStore.getScope()['project-id'] as string,
                    storeContext.scopeStore.getScope()[
                      'environment-id'
                    ] as string
                  )
                )
              } else if (
                storeContext.userStore.getUserRole() ===
                OrganizationUserRoles.BILLING
              ) {
                storeContext.uiStore.setGlobalLoader(false)
                storeContext.uiStore.setprojectEnvironmentLoader(false)
                localStorage.setItem(
                  StringConstants.ACTIVE_TAB,
                  StringConstants.TAB_VALUES.SETTINGS
                )
                navigate(
                  replaceUrl(
                    routeConstants.SUBSCRIPTION_SETTINGS,
                    storeContext.scopeStore.getOrganizationId() as string,
                    '',
                    ''
                  )
                )
              } else {
                navigate(redirect)
              }
            } else {
              if (
                storeContext.userStore.getUserRole() ===
                OrganizationUserRoles.BILLING
              ) {
                localStorage.setItem(
                  StringConstants.ACTIVE_TAB,
                  StringConstants.TAB_VALUES.SETTINGS
                )
                navigate(
                  replaceUrl(
                    routeConstants.SUBSCRIPTION_SETTINGS,
                    storeContext.scopeStore.getOrganizationId() as string,
                    '',
                    ''
                  )
                )
              } else {
                if (
                  !isEmpty(storeContext.scopeStore.getScope()['project-id']) &&
                  !isEmpty(storeContext.scopeStore.getScope()['environment-id'])
                ) {
                  localStorage.setItem(
                    StringConstants.ACTIVE_TAB,
                    StringConstants.TAB_VALUES.OVERVIEW
                  )
                  navigate(
                    replaceUrl(
                      routeConstants.OVERVIEW,
                      storeContext.scopeStore.getScope()[
                        'organization-id'
                      ] as string,
                      storeContext.scopeStore.getScope()[
                        'project-id'
                      ] as string,
                      storeContext.scopeStore.getScope()[
                        'environment-id'
                      ] as string
                    )
                  )
                } else {
                  localStorage.setItem(
                    StringConstants.ACTIVE_TAB,
                    StringConstants.TAB_VALUES.OVERVIEW
                  )
                  navigate(
                    '../' +
                      replaceUrl(
                        routeConstants.OVERVIEW,
                        storeContext.scopeStore.getOrganizationId() as string,
                        '',
                        ''
                      )
                  )
                }
              }
            }
          }
        })
        .catch(async (err: any) => {
          console.log('err', err)
          await deleteToken()
          if (window.location.host.includes('demo.vigilnow.com')) {
            navigate(routeConstants.SANDBOX_SCREEN)
            removeSandboxUserDetails()
          } else navigate(routeConstants.SIGN_IN)
        })
    } else if (reload) {
      navigate(0)
    } else {
      if (window.location.host.includes('demo.vigilnow.com')) {
        navigate(routeConstants.SANDBOX_SCREEN)
        removeSandboxUserDetails()
      } else navigate(routeConstants.SIGN_IN)
    }
  }

  return token && !isDemo && open ? (
    //TODO Refactor this pop-up into seperate function
    <Popup
      open={open}
      headerText='Verify Account'
      handleClose={() => {
        callRedirect()
      }}
      headerIcon={
        <img src={successIcon} style={{ height: '18px', width: '18px' }} />
      }
      closeOnAwayClick
      height='344px'
    >
      <Container>
        <TypographyDDS.Title type='h3' variant='semiBold'>
          Verify <ColorText>Account</ColorText>
        </TypographyDDS.Title>
        <TypographyDDS.Title type='h3'>
          You’re not verified. Verify now!
        </TypographyDDS.Title>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            gap: '20px'
          }}
        >
          <Button
            color='primary'
            variant='contained'
            margin='20px 0 0 0'
            startIcon={
              <img
                src={successIcon}
                style={{
                  filter: palette.colors.whiteSvgFilter
                }}
              />
            }
            loading={loading}
            disabled={loading}
            onClick={() => {
              setOpen(true)
              gaEventTracker(gaEventConstants.VERIFY)
              setLoading(true)
              resendVerification()
                .then(() =>
                  ToastNotification({
                    type: 'success',
                    message:
                      ToastMessageConstants.USER.RESEND_VERIFICATION.SUCCESS
                  })
                )
                .finally(() => setLoading(false))
              callRedirect()
            }}
          >
            Verify
          </Button>
          <Button
            color='error'
            variant='contained'
            iconSize='16px'
            startIcon={
              <img
                src={closeIcon}
                style={{
                  filter: palette.colors.whiteSvgFilter
                }}
              />
            }
            margin='20px 0 0 0'
            onClick={() => {
              setOpen(true)
              gaEventTracker(gaEventConstants.IGNORE)
              callRedirect()
            }}
          >
            Ignore
          </Button>
        </div>
      </Container>
    </Popup>
  ) : (
    <PageLoadingOverlay />
  )
}

export default RedirectScreen
