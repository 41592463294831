import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Copy } from '@carbon/icons-react'
import Copyright from '../../../assets/images/copyright.png'
import { DDSTypography } from 'den-design-system'
import { DDSVerticalStepper } from 'den-design-system'
import Parser from 'html-react-parser'
import { ProjectType } from '../../../enum/ProjectType.enum'
import { SeverityTypes } from '../../../constants/SeverityPriorityConstants'
import Spinner from '../../../components/common/Spinner/Spinner'
import Table from '../../../components/common/DDS/Table/Table'
import VigilGreyOut from '../../../assets/icons/Vigil Logo Greyed out.svg'
import VigilLogo from '../../../assets/icons/vigilTMLogo.svg'
import { getAnExceptionByToken } from '../../../api/exception/Exception.service'
import { isEmpty } from 'lodash'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { timeFormats } from '../../../enum/TIME'
import { timeZone } from '../../../utils/DateTimeUtils'
import { useStoreContext } from '../../../store/StoreContext'

const TextDisplayCard = ({
  heading,
  message,
  multiple = false,
  multipleMessages
}: {
  heading: string
  message?: any
  multiple?: boolean
  multipleMessages?: string[]
}) => {
  return (
    <div className='py-[16px] px-[20px] flex flex-col gap-y-[16px]'>
      <DDSTypography.Title
        type='h3'
        variant='bold'
        color={pallete.colors.textDark3}
      >
        {heading}
      </DDSTypography.Title>
      {!multiple && (
        <div
          className='p-[16px] rounded'
          style={{ backgroundColor: pallete.colors.surface6 }}
        >
          <DDSTypography.Paragraph
            size='para'
            variant='regular'
            color={pallete.colors.textDark5}
          >
            {message}
          </DDSTypography.Paragraph>
        </div>
      )}

      {multiple
        ? multipleMessages?.map((item: any, index) => (
            <div
              key={index}
              className='p-[16px] rounded flex flex-col gap-y-[16px]'
              style={{ backgroundColor: pallete.colors.surface6 }}
            >
              <DDSTypography.Paragraph
                key={index}
                size='para'
                variant='regular'
                color={pallete.colors.textDark5}
              >
                <>{item.fileFullPath}</>
                {!(item.functionName == '') && (
                  <>
                    <span> in </span>
                    {item.functionName}
                  </>
                )}
                {item.lineNo && (
                  <>
                    <span> at line </span>
                    {item.columnNo ? (
                      <>
                        {item.lineNo}:{item.columnNo}
                      </>
                    ) : (
                      <>{item.lineNo}</>
                    )}
                  </>
                )}
              </DDSTypography.Paragraph>
            </div>
          ))
        : null}
    </div>
  )
}
const PublicShare: React.FC = () => {
  const [currentPage, setCurrentPage] = React.useState<number>(1)
  const [exceptionDetails, setExceptionDetails] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  const { token } = useParams()
  const store = useStoreContext()

  const navigate = useNavigate()

  const fetchAnExceptionDetails = () => {
    setLoading(true)
    getAnExceptionByToken({ $token: token as string })
      .then((response: any) => {
        if (response.data && response.data.data) {
          setExceptionDetails(response.data.data)
          setLoading(false)
        } else {
          navigate(routeConstants.ERROR_PAGE)
        }
      })
      .catch((error) => {
        console.error('Error fetching exception details:', error)

        navigate(routeConstants.ERROR_PAGE)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    fetchAnExceptionDetails()
  }, [])
  const tableColumn = [
    {
      heading: (
        <DDSTypography.Paragraph
          size='caption'
          variant='semiBold'
          color={pallete.colors.textDark6}
        >
          Attributes
        </DDSTypography.Paragraph>
      ),
      render: (text: any) => (
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark4}
        >
          {text}
        </DDSTypography.Paragraph>
      ),
      dataLabel: 'attribute'
    },
    {
      columnHeaderStyle: { width: '65%' },
      columnDataStyle: { width: '65%' },
      heading: (
        <DDSTypography.Paragraph
          size='caption'
          variant='semiBold'
          color={pallete.colors.textDark6}
        >
          Values
        </DDSTypography.Paragraph>
      ),
      render: (text: any) => (
        <DDSTypography.Paragraph
          size='para'
          variant='medium'
          color={pallete.colors.textDark4}
        >
          {text}
        </DDSTypography.Paragraph>
      ),
      dataLabel: 'value'
    }
  ]

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className='bg-white'>
          <div className='flex w-full justify-center px-[32px] py-[16px] shadow-[rgba(0,0,0,0.04)_0px_4px_8px_0px] '>
            <img src={VigilLogo} alt='icon' />
          </div>
          <div className='p-[32px] gap-[24px] flex flex-col justify-center '>
            <div className='flex flex-col  w-full pt-[32px]'>
              <div className='flex flex-col justify-center  gap-y-[8px] pb-[8px]'>
                <DDSTypography.Title
                  type='h1'
                  style={{ fontSize: '32px' }}
                  color={pallete.colors.textDark3}
                  className='flex justify-center text-center align-middle'
                >
                  {exceptionDetails.exceptionType}
                </DDSTypography.Title>
                <DDSTypography.Title
                  type='h3'
                  variant='semiBold'
                  color={pallete.colors.textDark10}
                  className='flex justify-center'
                >
                  {`${exceptionDetails.projectType === 'FULL_STACK_ONLY' ? 'Exception' : 'Error'} for ${exceptionDetails.projectName} Project`}
                </DDSTypography.Title>
              </div>
              <div className=' flex flex-row h-[72px] items-center justify-center gap-x-[12px] w-full'>
                <div>
                  <img
                    src={exceptionDetails.organizationAvatar}
                    style={{ borderColor: pallete.colors.stroke2 }}
                    className='w-[30px] h-[30px] rounded-full object-cover'
                  />
                </div>
                <div>
                  <DDSTypography.Title
                    type='h2'
                    variant='bold'
                    color={pallete.colors.textDark3}
                    className='flex justify-center'
                  >
                    {exceptionDetails.organizationName}
                  </DDSTypography.Title>
                </div>
              </div>
            </div>
            <div className='flex flex-col justify-center gap-y-[8px]'>
              <div className='flex flex-row custom-text gap-x-[4px] justify-center'>
                <DDSTypography.Title
                  type='h5'
                  color={pallete.colors.textDark3}
                  variant='semiBold'
                >
                  First Reported:
                </DDSTypography.Title>
                <DDSTypography.Title
                  type='h5'
                  color={pallete.colors.textDark3}
                  variant='bold'
                >
                  {timeZone(
                    store.userStore.getUserPreference().timeZone,
                    exceptionDetails.reportedAt,
                    timeFormats.monthDayYear
                  )}
                </DDSTypography.Title>
                <span
                  className='w-[1px] mx-[4px]'
                  style={{ backgroundColor: pallete.colors.textDark3 }}
                ></span>
                <DDSTypography.Title
                  type='h5'
                  color={pallete.colors.textDark3}
                  variant='semiBold'
                >
                  Client Version:
                </DDSTypography.Title>
                <DDSTypography.Title
                  type='h5'
                  color={pallete.colors.textDark3}
                  variant='bold'
                >
                  {exceptionDetails.clientVersion
                    ? exceptionDetails.clientVersion
                    : '-'}
                </DDSTypography.Title>
              </div>
              <div className='flex flex-row custom-text gap-x-[4px] justify-center'>
                <DDSTypography.Title
                  type='h5'
                  color={pallete.colors.textDark3}
                  variant='medium'
                >
                  Current Severity:
                </DDSTypography.Title>

                {SeverityTypes.map((severity, index) => {
                  if (exceptionDetails.severity === severity.label) {
                    return (
                      <DDSTypography.Title
                        key={index}
                        type='h5'
                        color={severity.style.color}
                        variant='bold'
                      >
                        {exceptionDetails.severity}
                      </DDSTypography.Title>
                    )
                  }
                })}
                <span
                  className='w-[1px] mx-[4px]'
                  style={{ backgroundColor: pallete.colors.textDark3 }}
                ></span>
                <DDSTypography.Title
                  type='h5'
                  color={pallete.colors.textDark3}
                  variant='medium'
                >
                  Current Status:
                </DDSTypography.Title>
                <DDSTypography.Title
                  type='h5'
                  color={pallete.colors.textDark3}
                  variant='bold'
                >
                  {exceptionDetails.exceptionStatus}
                </DDSTypography.Title>
              </div>
            </div>
            <TextDisplayCard
              heading={
                exceptionDetails.projectType === 'FULL_STACK_ONLY'
                  ? 'Exception Message'
                  : 'Error Message'
              }
              message={exceptionDetails.exceptionMessage}
            />
            {exceptionDetails?.exceptionStack ? (
              <>
                <TextDisplayCard
                  heading={
                    exceptionDetails.projectType === 'FULL_STACK_ONLY'
                      ? 'Raw Exception Stack'
                      : 'Raw Error Stack'
                  }
                  message={Parser(exceptionDetails?.exceptionStack || '')}
                />
                {!isEmpty(exceptionDetails?.stackFrames) && (
                  <TextDisplayCard
                    heading={
                      exceptionDetails.projectType === 'FULL_STACK_ONLY'
                        ? 'Full Exception Stack'
                        : 'Full Error Stack'
                    }
                    multiple={true}
                    multipleMessages={exceptionDetails.stackFrames}
                  />
                )}
              </>
            ) : (
              <TextDisplayCard
                heading={
                  exceptionDetails.projectType === 'FULL_STACK_ONLY'
                    ? 'Raw Exception Stack'
                    : 'Raw Error Stack'
                }
                message={
                  exceptionDetails.projectType === 'FULL_STACK_ONLY'
                    ? 'No Exception Stack'
                    : 'No Error Stack'
                }
              />
            )}
            {!isEmpty(exceptionDetails?.context) && (
              <div className='py-[16px] px-[20px] flex flex-col gap-y-[16px]'>
                <DDSTypography.Title
                  type='h3'
                  variant='bold'
                  color={pallete.colors.textDark3}
                >
                  {exceptionDetails.projectType === 'FULL_STACK_ONLY'
                    ? 'Exception Context'
                    : 'Error Context'}
                </DDSTypography.Title>
                {/* {!isEmpty(exceptionDetails?.context) ? ( */}
                <Table
                  data={Object.keys(exceptionDetails?.context).map(
                    (item: any, index: number) => {
                      return {
                        attribute: item,
                        value: exceptionDetails.context[item]
                      }
                    }
                  )}
                  columns={tableColumn}
                  currentPage={currentPage}
                  totalCount={10}
                  showPagination={false}
                />
              </div>
            )}
            <div className=' flex flex-col gap-y-[32px] px-[20px] py-[16px]'>
              <DDSTypography.Title
                type='h3'
                variant='bold'
                color={pallete.colors.textDark3}
              >
                Activity
              </DDSTypography.Title>
              <DDSVerticalStepper
                className='override-stepper-title '
                stepItems={exceptionDetails.activity.map(
                  (item: any, index: any) => {
                    return {
                      icon: item?.actionedByUser?.avatar ? (
                        <img
                          src={item.actionedByUser.avatar}
                          alt='icon'
                          className='rounded-full w-[24px] h-[24px]'
                        />
                      ) : null,
                      description: (
                        <DDSTypography.Paragraph
                          size='para'
                          variant='regular'
                          color={pallete.colors.textDark10}
                        >
                          {item.message
                            .split(/\b(exception|error)\b/i)
                            .map((part: any, partIndex: any) => {
                              const lowerPart = part.toLowerCase()
                              if (
                                lowerPart === 'exception' ||
                                lowerPart === 'error'
                              ) {
                                const replacement =
                                  exceptionDetails.projectType ===
                                  'FULL_STACK_ONLY'
                                    ? 'Exception'
                                    : 'Error'
                                return (
                                  <React.Fragment key={partIndex}>
                                    {part === part.toUpperCase()
                                      ? replacement.toUpperCase()
                                      : part[0] === part[0].toUpperCase()
                                        ? replacement
                                        : replacement.toLowerCase()}
                                  </React.Fragment>
                                )
                              }
                              return part
                            })}
                        </DDSTypography.Paragraph>
                      ),
                      title: (
                        <div className='flex flex-col'>
                          <DDSTypography.Paragraph
                            size='para'
                            variant='medium'
                            color={pallete.colors.textDark6}
                          >
                            Posted on
                          </DDSTypography.Paragraph>
                          <DDSTypography.Paragraph
                            size='para'
                            variant='medium'
                            color={pallete.colors.textDark6}
                            className='whitespace-nowrap'
                          >
                            {timeZone(
                              store.userStore.getUserPreference().timeZone,
                              item.createdAt,
                              timeFormats.monthDateYear
                            )}
                          </DDSTypography.Paragraph>
                        </div>
                      )
                    }
                  }
                )}
              />
            </div>
            <div className='flex flex-row w-full items-center justify-center override-copyright gap-x-[4px]'>
              <img
                src={Copyright}
                color={pallete.colors.warningBlue}
                style={{ width: '20px', height: '20px' }}
              />
              <DDSTypography.Paragraph
                size='para'
                color={pallete.colors.textDark4}
              >
                2024
              </DDSTypography.Paragraph>
              <img src={VigilGreyOut} color={pallete.colors.textDark4} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default PublicShare
