import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { Button } from '../../components/New/Button/Button'
import { CodeSandBoxPopup } from '../NewVersion/CodeSandBox/CodeSandBoxPopup/EnterSandboxPopup'
import { DDSTypography } from 'den-design-system'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../constants/HeaderConstants'
import HttpConstants from '../../constants/HttpConstants'
import Input from '../../components/New/Input/Input'
import { OrganizationUserRoles } from '../../enum/OrganizationUserRoles.enum'
import { ProjectType } from '../../enum/ProjectType.enum'
import RegexConstants from '../../constants/RegexConstants'
import Robot from '../../assets/images/Robot.svg'
import SandboxConstants from '../../constants/SandboxConstants'
import Select from '../../components/New/Select/Select'
import ToastNotification from '../../components/common/DDS/Toast/Toast'
import UnsecureComponent from '../../components/Layout/UnsecureComponent'
import { addQueryParamsToUrl } from '../../utils/UrlGenerator'
import { deleteToken } from '../../utils/AuthUtils'
import { isEmpty } from 'lodash'
import pallete from '../../global/newPallete'
import routeConstants from '../../constants/RouteConstants'
import { sandboxUserEmailCapture } from '../../api/Auth.service'
import { useStoreContext } from '../../store/StoreContext'

const SandboxScreen = () => {
  const [params] = useSearchParams()
  const store = useStoreContext()
  const navigate = useNavigate()
  const projectType = params.get('projectType')
  const projectVariant =
    projectType == 'mulesoft'
      ? ProjectType.MULESOFT_ONLY
      : projectType == 'fullstack'
        ? ProjectType.FULL_STACK_ONLY
        : ProjectType.SALESFORCE_ONLY
  const [codeSandboxPopupOpen, setCodeSandboxPopupOpen] =
    React.useState<boolean>(true)
  const [emailAddress, setEmailAddress] = React.useState<string>('')
  const [emailInvalid, setEmailInvalid] = React.useState<boolean>(false)
  const [memberRole, setMemberRole] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)

  const removeSandboxUserDetails = () => {
    localStorage.removeItem('project_type')
    localStorage.removeItem('tour_disabled')
    localStorage.removeItem('sandboxuser_email')
    localStorage.removeItem('sandboxuser_role')
  }

  useEffect(() => {
    if (
      window.location.host.includes('demo.vigilnow.com') &&
      localStorage.getItem('project_type')
    ) {
      const projectVariant =
        projectType == 'mulesoft'
          ? ProjectType.MULESOFT_ONLY
          : projectType == 'fullstack'
            ? ProjectType.FULL_STACK_ONLY
            : ProjectType.SALESFORCE_ONLY
      if (projectVariant !== localStorage.getItem('project_type')) {
        removeSandboxUserDetails()
        deleteToken()
      }
    }
  }, [])

  const getRoles = () => {
    return [
      {
        value: 'owner',
        label: (
          <DDSTypography.Paragraph
            variant='semiBold'
            size='para'
            className='truncate flex items-start'
            color={pallete.colors.textDark4}
          >
            {SandboxConstants.OWNER}
          </DDSTypography.Paragraph>
        )
      },
      {
        value: 'member',
        label: (
          <DDSTypography.Paragraph
            variant='semiBold'
            size='para'
            className='truncate flex items-start'
            color={pallete.colors.textDark4}
          >
            {SandboxConstants.MEMBER}
          </DDSTypography.Paragraph>
        )
      }
    ]
  }
  return (
    <div>
      <DocumentHeader header={HeaderConstants.SANDBOX} />

      <CodeSandBoxPopup
        modalOpen={codeSandboxPopupOpen}
        setModalOpen={setCodeSandboxPopupOpen}
        primaryButtonText='Yes'
        secondaryButtonText='No'
        secondaryButtonClick={() => {
          setCodeSandboxPopupOpen(false)
        }}
        content={
          <div className=' w-full flex p-[48px_44px]'>
            <div className='flex flex-col pr-[64px]'>
              <div>
                <DDSTypography.Title
                  type='h4'
                  variant='bold'
                  color={pallete.colors.textDark2}
                  className='pb-[4px]'
                >
                  {SandboxConstants.VIGIL_DEMO}
                </DDSTypography.Title>

                <div>
                  <DDSTypography.Title
                    type='h5'
                    variant='medium'
                    color={pallete.colors.textDark3}
                    style={{ lineHeight: 2 }}
                  >
                    {SandboxConstants.TEXT1}
                  </DDSTypography.Title>
                  <DDSTypography.Title
                    type='h5'
                    variant='medium'
                    color={pallete.colors.textDark3}
                    className='mb-[20px]'
                  >
                    {SandboxConstants.TEXT2}
                  </DDSTypography.Title>
                </div>
              </div>

              <div className='flex flex-row gap-[16px]'>
                <div className='flex flex-col gap-[8px] flex-1 override-input-height'>
                  <DDSTypography.Paragraph size='para' variant='medium'>
                    {SandboxConstants.WORK_EMAIL}
                  </DDSTypography.Paragraph>
                  <Input
                    id='work-mail'
                    name='work-mail'
                    height='36px'
                    required
                    type='text'
                    maxLength={150}
                    onChange={(e) => {
                      setEmailAddress(e.target.value)
                    }}
                    placeholder='Enter your work email'
                    validateInput
                    validationRegex={RegexConstants.EMAIL_ADDRESS_CHECK}
                    errorMessage={'Please provide a valid email address'}
                    value={emailAddress}
                    onError={(error) => {
                      setEmailInvalid(error)
                    }}
                  />
                </div>
                <div className='flex flex-col gap-[8px] flex-1'>
                  <DDSTypography.Paragraph size='para' variant='medium'>
                    {SandboxConstants.MEMBER_ROLE}
                  </DDSTypography.Paragraph>
                  <Select
                    id='roles-select'
                    name='Roles'
                    size='large'
                    placeholder='Select your role'
                    options={getRoles()}
                    required
                    searchable={false}
                    onSelect={(val: string) => {
                      setMemberRole(val)
                    }}
                  />
                </div>
              </div>
              <div className='mt-[26px]'>
                <Button
                  id='add-links'
                  type='filled'
                  size='medium'
                  label='Enter Sandbox'
                  loading={loading}
                  disabled={
                    emailAddress.trim().length == 0 ||
                    emailInvalid ||
                    isEmpty(memberRole) ||
                    store.uiStore.getGlobalLoader() ||
                    loading
                  }
                  onClick={() => {
                    setLoading(true)
                    sandboxUserEmailCapture({
                      email: emailAddress,
                      role:
                        memberRole == 'member'
                          ? OrganizationUserRoles.MEMBER
                          : OrganizationUserRoles.OWNER,
                      projectType: projectVariant
                    })
                      .then((response: any) => {
                        const url = addQueryParamsToUrl(
                          routeConstants.REDIRECT,
                          {
                            token: response.data.accessToken
                          },
                          HttpConstants.GET_METHOD
                        )
                        setLoading(false)
                        localStorage.setItem('tour_disabled', 'false')
                        localStorage.setItem('project_type', projectVariant)
                        localStorage.setItem(
                          'sandboxuser_email',
                          response.data.email
                        )
                        localStorage.setItem(
                          'sandboxuser_role',
                          response.data.role
                        )
                        navigate(url)
                      })
                      .catch((err) => {
                        console.log('err-----', err)
                        setLoading(false)
                        ToastNotification({
                          type: 'error',
                          message: err
                        })
                      })
                  }}
                />
              </div>
            </div>
            <div className='flex justify-center items-center'>
              <img
                src={Robot}
                alt='Robot'
                className='max-w-full max-h-full object-contain'
              />
            </div>
          </div>
        }
      />
    </div>
  )
}

export default UnsecureComponent(SandboxScreen)
