import { Link, useLocation, useParams } from 'react-router-dom'
import React, { useState } from 'react'
import { UrlWithOnlyOrgId, replaceUrl } from '../../../../utils/UrlUtils'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../../constants/GAConstants'

import Breadcrumbs from '../../DDS/Breadcrumbs/Breadcrumbs'
import { BsChevronLeft } from 'react-icons/bs'
import Button from '../../Button/Button'
import CreateProjectFlow from '../../../popupFlows/CreateProjectFlow/CreateProjectFlow'
import { DDSTypography } from 'den-design-system'
import NavLink from '../NavLink'
import { OrganizationUserRoles } from '../../../../enum/OrganizationUserRoles.enum'
import breakpoints from '../../../../global/breakpoints'
import { getSettingsHeaderTabs } from '../../../../utils/CommonUtils'
import { observer } from 'mobx-react-lite'
import palette from '../../../../global/pallete'
import pallete from '../../../../global/newPallete'
import plusIcon from '../../../../assets/icons/plus.svg'
import routeConstants from '../../../../constants/RouteConstants'
import styled from '@emotion/styled'
import { useAnalyticsEventTracker } from '../../../../utils/GAUtils'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../../../store/StoreContext'

interface SettingsBarProps {
  showButtons?: boolean
}

const Container = styled.div`
  min-height: 48px;
  display: flex;
  justify-content: space-between;
  grid-gap: 50px;
  overflow-y: scroll;
  height: 100%;
  .button-text {
    @media (max-width: ${breakpoints.lg}) {
      font-size: 0.85rem;
    }
    @media (max-width: ${breakpoints.md}) {
      font-size: 0.7rem;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    grid-gap: 10px;
  }
  @media screen and (max-width: 900px) {
    display: contents;
  }
`
const Wrapper = styled.div`
  display: flex;
  grid-gap: 20px;
  width: 80%;
  @media screen and (max-width: 1100px) {
    grid-gap: 10px;
  }
`
const ButtonWrapper = styled.div`
  @media screen and (max-width: 985px) {
    padding-top: 10px;
    padding-bottom: 10px;
    float: right;
  }
`
const GoBackContainer = styled(Link)(() => ({
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  display: 'flex',

  alignItems: 'center'
}))
const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
const StyledLink = styled.div`
  text-decoration: none;
  font-weight: 500;
  font-size: 0.875em;
  color: ${palette.colors.textDark2};
  line-height: 17px;
  &:hover {
    text-decoration: underline;
  }
`

const IconContainer = styled.div`
  order: 0;
  flex-grow: 1;
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
`
const SettingsBar: React.FC<SettingsBarProps> = ({ showButtons = false }) => {
  const navigate = useNavigate()

  const store = useStoreContext()
  const gaEventTracker = useAnalyticsEventTracker(gaPageConstants.SETTINGS)
  const [createProjectPopupVisible, setCreateProjectPopupVisibility] =
    useState<boolean>(false)
  const location = useLocation()
    .pathname.split('/')
    .filter((x) => x)
  let organizationId = ''
  if (store.scopeStore.getScope()['organization-id']) {
    organizationId = store.scopeStore.getScope()['organization-id'] as string
  }

  const projectId = store.scopeStore.getScope()['project-id'] as string
  const environmentId = store.scopeStore.getScope()['environment-id'] as string
  const isIndividualProject = () => {
    const projectIndex = window.location.pathname
      .split('/')
      .findIndex((x) => x === 'projects')
    if (
      projectIndex > 0 &&
      projectIndex + 1 < window.location.pathname.split('/').length
    )
      return true
    else return false
  }
  return (
    <>
      <Container>
        <Wrapper>
          {store.userStore.getOrganizations().length === 0 && (
            <GoBackContainer
              to={routeConstants.NO_ORGANIZATION}
              color='inherit'
              key={location.length - 1}
              style={{ textDecoration: 'none', display: 'flex' }}
            >
              <IconTextContainer>
                <BsChevronLeft
                  style={{
                    marginRight: '16px',
                    color: `${palette.colors.primary}`
                  }}
                />
                <StyledLink style={{ textDecoration: 'none' }}>
                  Go Back
                </StyledLink>
              </IconTextContainer>
            </GoBackContainer>
          )}

          {store.userStore.getOrganizations().length > 0 &&
            getSettingsHeaderTabs(store.userStore.getUserRole()).map(
              (link, index) => (
                <NavLink
                  icon={link.icon}
                  label={link.label}
                  key={index}
                  onClick={() => {
                    if (
                      link.to === window.location.pathname ||
                      link.to.split('/')[2] ===
                        window.location.pathname.split('/')[3]
                    ) {
                      return
                    }
                    gaEventTracker(link.label + ' Settings Tab')
                    if (link.label === 'Account') {
                      navigate(routeConstants.ACCOUNT_SETTINGS)
                    } else if (link.label === 'Preferences') {
                      navigate(routeConstants.PREFERENCES_SETTINGS)
                    } else if (link.label === 'Members') {
                      navigate(routeConstants.MEMBER_SETTINGS)
                    }

                    navigate(
                      replaceUrl(
                        link.to,
                        store.scopeStore.getScope()[
                          'organization-id'
                        ] as string,
                        '',
                        ''
                      )
                    )
                  }}
                  isActive={
                    link.to.split('/')[2] ===
                      window.location.pathname.split('/')[3] ||
                    link.to === window.location.pathname
                      ? true
                      : false
                  }
                  disabled={store.uiStore.getGlobalLoader()}
                />
              )
            )}
        </Wrapper>
        {/* <Wrapper> */}
        {/* the active link state and conditional rendering of some elements can be
         achieved when react-router will be implemented to project */}

        {/* Preferene settings  has add members buttons so condition here */}
        <div
          style={{
            width: '100%',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            display: 'flex',
            padding: '10px 0 10px 0',
            position: window.innerWidth <= 900 ? 'absolute' : 'relative',
            paddingRight: window.innerWidth <= 900 ? 142 : 0
          }}
        >
          {/* Project settings has create Project button so condition here */}
          {window.location.pathname.split('/')[3] ===
            routeConstants.PROJECT_SETTINGS.split('/')[2] &&
            store.userStore.getUserRole() === OrganizationUserRoles.OWNER && (
              <Button
                variant='contained'
                borderRadius='6px'
                onClick={() => {
                  setCreateProjectPopupVisibility(true)
                  gaEventTracker(gaEventConstants.INITIATE_CREATE_PROJECT)
                }}
                size='large'
                startIcon={<img src={plusIcon} />}
                iconSize='12px'
                disabled={store.userStore.getNoActiveSubscription()}
              >
                Create Project
              </Button>
            )}
          {window.location.pathname.split('/')[3] ===
            routeConstants.MEMBER_SETTINGS.split('/')[2] &&
            store.userStore.getUserRole() === OrganizationUserRoles.OWNER && (
              <ButtonWrapper>
                <Button
                  startIcon={<img src={plusIcon} />}
                  variant='contained'
                  onClick={() => {
                    navigate(routeConstants.INVITE_MEMBERS)
                  }}
                  size='large'
                  iconSize='12px'
                  disabled={
                    store.userStore.getNoActiveSubscription() ||
                    store.userStore.getUserRole() !==
                      OrganizationUserRoles.OWNER
                  }
                >
                  Invite Members
                </Button>
              </ButtonWrapper>
            )}
        </div>
        {/* </Wrapper> */}
      </Container>
      {isIndividualProject() && (
        <div className='flex flex-row h-[22px] items-center mt-[30px]'>
          <div
            className='flex flex-row gap-x-[8px] items-center h-[22px] cursor-pointer'
            onClick={() => {
              navigate(store.breadcrumbStore.getGoBackLink())
            }}
          >
            <div className='flex items-center'>
              <BsChevronLeft
                style={{
                  color: `${palette.colors.primary}`,
                  height: '16px',
                  width: '16px'
                }}
                size={16}
              />
            </div>

            <DDSTypography.Paragraph
              size='para'
              color={pallete.colors.darkGrey}
            >
              Go Back
            </DDSTypography.Paragraph>
          </div>

          <span className='border mx-[20px] h-[22px]'></span>
          <Breadcrumbs
            breadcrumbsOption={store.breadcrumbStore.getBreadcrumbsOptions()}
            onClick={(link: string, e: any) => {
              let isLastIndex = false
              store.breadcrumbStore
                .getBreadcrumbsOptions()
                .forEach((value, index) => {
                  if (value.label === e.target.textContent) {
                    if (
                      index ===
                      store.breadcrumbStore.getBreadcrumbsOptions().length - 1
                    ) {
                      isLastIndex = true
                    } else
                      store.breadcrumbStore.sliceBreadcrumbOptions(0, index)

                    return
                  }
                })
              if (!isLastIndex) {
                navigate(
                  replaceUrl(link, organizationId, projectId, environmentId)
                )
              }
            }}
          />
        </div>
      )}
      <CreateProjectFlow
        open={createProjectPopupVisible}
        handleClose={() => setCreateProjectPopupVisibility(false)}
      />
    </>
  )
}

export default observer(SettingsBar)
