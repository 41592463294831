import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import { BreadcrumbsProps } from '@mui/material'
import DashboardTopNavbar from '../../components/common/DashboardTopNavbar/DashboardTopNavbar'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import GeneralSettings from './OrganizationSettings/GeneralSettings'
import { HeaderConstants } from '../../constants/HeaderConstants'
import RolesAndActions from './OrganizationSettings/RoleAndActions'
import SecureComponent from '../../components/Layout/SecureComponent'
import { SettingBottomBar } from '../../components/common/SettingBottomBar/SettingBottomBar'
import SettingsBar from '../../components/common/DashboardTopNavbar/bottomBars/Settings'
import StringConstants from '../../constants/StringConstants'
import palette from '../../global/pallete'
import routeConstants from '../../constants/RouteConstants'
import styled from '@emotion/styled'
import { useObserver } from 'mobx-react-lite'

const OrganizationSettings: React.FC = () => {
  const breadcrumbsProps = {
    segmentLabels: ['Roles And Actions', 'General']
  } as BreadcrumbsProps
  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.SETTINGS
    )
  }, [])
  return useObserver(() => (
    <>
      <DocumentHeader header={HeaderConstants.ORGANIZATION_SETTINGS} />
      {/* <DashboardTopNavbar
        variant='settings'
        breadcrumbs={
          window.location.pathname.split('/').at(-1) === 'roles-actions'
        }
        breadcrumbsProps={breadcrumbsProps}
        showBottomBar
      /> */}
      <SettingBottomBar>
        <SettingsBar showButtons={false} />
      </SettingBottomBar>
      <Routes>
        <Route index element={<GeneralSettings />} />
        <Route
          path={routeConstants.ROLES_AND_ACTIONS}
          element={<RolesAndActions />}
        />
      </Routes>
    </>
  ))
}
export default SecureComponent(OrganizationSettings, AccessRoleConstants.ALL)
