import { DDSInput, DDSTypography } from 'den-design-system'

import { IoSearch } from 'react-icons/io5'
import React from 'react'
import { SearchProps } from './Search.types'
import pallete from '../../../global/newPallete'

const Search: React.FC<SearchProps> = ({
  id,
  name,
  suffixIcon,
  label,
  errorMessage,
  value,
  placeholder,
  autoComplete = 'on',
  disabled = false,
  onBlur,
  onFocus,
  onChange
}) => {
  return (
    <div className='override-input'>
      <DDSInput
        id={id}
        name={name}
        variant='plain'
        type='search'
        shape='roundedRectangle'
        autoComplete={autoComplete}
        prefixIcon={<IoSearch size={16} color={pallete.colors.textDark7} />}
        suffixIcon={suffixIcon}
        className='overriding-search'
        label={label}
        errorMessage={errorMessage}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        validateInput={false}
        style={{
          width: '290px',
          height: '28px',
          borderColor: pallete.colors.stroke2
        }}
      />
      {errorMessage && (
        <DDSTypography.Paragraph
          size='caption'
          variant='regular'
          color={pallete.colors.warningRed2}
        >
          {errorMessage}
        </DDSTypography.Paragraph>
      )}
    </div>
  )
}

export default Search
