import React, { useEffect } from 'react'
import { timeMetric, timeZone } from '../../../utils/DateTimeUtils'

import NoData from '../../NoData/NoData'
import Table from '../../common/Table/Table'
import { observer } from 'mobx-react-lite'
import { timeFormats } from '../../../enum/TIME'
import { useSearchParams } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'

// const DateTimeContainer = styled.div`
//   display: flex;
//   flex-direction: column;
// `

// const ViewAnalysisWrapper = styled.div`
//   &:hover {
//     cursor: pointer;
//   }
// `
// const ResponseSummaryWrapper = styled.div`
//   overflow: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   max-width: 300px;
//   justify-content: center;
//   margin-left: auto;
//   margin-right: auto;
// `

const IncidentsHistoryTable: React.FC = () => {
  const [queryParams, setQueryParams] = useSearchParams()
  const { ahcLogsStore, userStore, filterStore } = useStoreContext()
  useEffect(() => {
    const page = queryParams.get('page')
    if (page) ahcLogsStore.setPage(+page)
  }, [queryParams.get('page')])
  const handlePageChange = (page: number) => {
    queryParams.set('page', page.toString())
    setQueryParams(queryParams)
  }
  const store = useStoreContext()
  const columns: any = [
    {
      title: 'Check Time',
      label: 'createdAt',
      render: (checkTime: any) => {
        return (
          <>
            {timeZone(
              store.userStore.getUserPreference().timeZone,
              checkTime,
              timeFormats.fullMonthDateYearTime
            )}
          </>
        )
      }
    },
    {
      title: 'Status',
      label: 'appHealthStatus',
      render: (status: any) => {
        return <>{status}</>
      }
    },
    {
      title: 'Response Time',
      label: 'timeTakenToCompleteInMilliSeconds',
      render: (responseTime: any) => {
        return <>{responseTime ? timeMetric(responseTime, 'ms') : '-'}</>
      }
    }
  ]

  return ahcLogsStore.getLogs().length > 0 || ahcLogsStore.getLoading() ? (
    <Table
      data={ahcLogsStore.getLogs()}
      columns={columns}
      currentPage={ahcLogsStore.getPage()}
      totalCount={ahcLogsStore.getCount()}
      rowsPerPage={ahcLogsStore.size}
      onPageChange={handlePageChange}
      isLoading={ahcLogsStore.getLoading()}
    />
  ) : (
    <NoData message={'No records found'} emptyText={false} />
  )
}

export default observer(IncidentsHistoryTable)
