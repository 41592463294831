import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { gaEventConstants, gaPageConstants } from '../../constants/GAConstants'

import AddCheckPopup from '../../components/AHCPage/AddCheckPopupFlow/AddCheckPopup'
import { BreadcrumbsProps } from '@mui/material'
import Button from '../../components/common/Button/Button'
import DashboardTopNavbar from '../../components/common/DashboardTopNavbar/DashboardTopNavbar'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../constants/HeaderConstants'
import { ProjectType } from '../../enum/ProjectType.enum'
import StringConstants from '../../constants/StringConstants'
import { getUserDetails } from '../../api/user/User.service'
import { isUndefined } from 'lodash'
import { observer } from 'mobx-react-lite'
import plusIcon from '../../../src/assets/icons/plus.svg'
import routeConstants from '../../constants/RouteConstants'
import { useAnalyticsEventTracker } from '../../utils/GAUtils'
import { useStoreContext } from '../../store/StoreContext'

const AHCPageLayout: React.FC = () => {
  const { pathname: path } = useLocation()
  const getTopNavBarVariant = () => {
    switch (path.split('/').at(-1)) {
      case 'dashboard':
        return 'ahcProjectsDashboard'
      case 'status':
        return 'ahcStatus'
      default:
        return 'ahcDashboard'
    }
  }
  const [addCheckPopupOpen, setAddCheckPopupOpen] = React.useState(false)

  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.APP_HEALTH_CHECK
  )
  const { ahcId } = useParams()
  const storeContext = useStoreContext()

  const navigate = useNavigate()
  useEffect(() => {
    getUserDetails().then((userResponse: any) => {
      const organizationIndex = userResponse.data.organizations.some(
        (org: any) => {
          return org.id === store.userStore.getSelectedOrganization().id
        }
      )

      if (organizationIndex === -1) {
        navigate(routeConstants.UNAUTHORIZED_PAGE)
      }
    })
  }, [])

  const renderTopRightNavbarElements = () => {
    return (
      <Button
        id='Add Health Check'
        variant='contained'
        startIcon={<img src={plusIcon} />}
        iconSize='12px'
        onClick={() => {
          setAddCheckPopupOpen(true)
          gaEventTracker(gaEventConstants.INITIATE_ADD_CHECK)
        }}
        size='large'
        disabled={
          storeContext.userStore.getNoActiveSubscription() ||
          storeContext.ahcReportsStore.getLoading() ||
          storeContext.ahcLogsStore.getLoading()
        }
      >
        Add Check
      </Button>
    )
  }
  const store = useStoreContext()
  return (
    <>
      <DocumentHeader
        header={
          storeContext.scopeStore.getSelectedProject().type ===
          ProjectType.FULL_STACK_ONLY
            ? HeaderConstants.UPTIME_CHECKS
            : HeaderConstants.UPTIME_CHECKS
        }
      />
      {/* <AddCheckPopup
        handleClose={() => setAddCheckPopupOpen(false)}
        open={addCheckPopupOpen}
      />
       <DocumentHeader header={HeaderConstants.AHC} />
      <DashboardTopNavbar
        variant='ahcDashboard'
        showTopDropdowns
        topRightElements={renderTopRightNavbarElements()}
        breadcrumbs={!isUndefined(ahcId)}
        breadcrumbsProps={
          {
            noGutter: true,
            segmentLabels: [
              store.ahcReportsStore.getActiveReport()?.checkName,
              '',
              StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.UPTIME_CHECKS
            ]
          } as BreadcrumbsProps
        }
        key={store.ahcReportsStore.getActiveReport()?.checkName}
      /> */}
      <Outlet />
    </>
  )
}

export default observer(AHCPageLayout)
