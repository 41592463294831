import React, { useEffect } from 'react'
import { contentSpanStyle, contentTextStyle } from './InvitePopup.styles'

import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import Button from '../common/Button/Button'
import Popup from '../common/Popup/Popup'
import SecureComponent from '../Layout/SecureComponent'
import StringConstants from '../../constants/StringConstants'
import Typography from '../common/Typography/Typography'
import { TypographyDDS } from '../common/Typography/TypographyDDS'
import closeIcon from '../../assets/icons/close.svg'
import inviteMembersIcon from '../../assets/icons/inviteMembersIcon.svg'
import styled from '@emotion/styled'
import successIcon from '../../assets/icons/successIcon.svg'
import themePalette from '../../global/pallete'
import { useStoreContext } from '../../store/StoreContext'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
`
const ButtonContainer = styled.div`
  text-align: center;
  display: flex;
  margin-top: 30px;
`

const InvitePopup: React.FC<{
  open: boolean
  handleClose: () => void
  handleNext: (data: unknown) => void
  role: string
  project: string
  organization: string
  handleAcceptInvite: () => void
  handleRejectInvite: () => void
  acceptLoading: boolean
  rejectLoading: boolean
}> = ({
  open,
  handleClose,
  role,
  project,
  organization,
  handleAcceptInvite,
  handleRejectInvite,
  acceptLoading,
  rejectLoading
}) => {
  const store = useStoreContext()

  useEffect(() => {
    localStorage.removeItem(StringConstants.LOCAL_STORAGE.CURRENT_PAGE_DETAILS)
  }, [])
  return (
    <Popup
      open={open}
      handleClose={handleClose}
      loading={acceptLoading || rejectLoading}
      headerText={project ? 'Project Invite' : 'Organization Invite'}
      headerIcon={
        <img
          src={inviteMembersIcon}
          style={{ filter: themePalette.colors.primarySvgFilter }}
        />
      }
      height='300px'
    >
      <Container>
        <TypographyDDS.Title type='h4' variant='medium' color='textDark2'>
          Do you want to join{' '}
          <span style={contentSpanStyle}>{project ?? organization}</span> as{' '}
          <span style={contentSpanStyle}>{role}</span>
        </TypographyDDS.Title>

        <ButtonContainer>
          <Button
            size='medium'
            variant='contained'
            borderColor='red'
            type='button'
            bold={false}
            margin-top='20px'
            color='error'
            height='50px'
            startIcon={
              <img
                src={closeIcon}
                alt='closeIcon'
                style={{ filter: themePalette.colors.whiteSvgFilter }}
              />
            }
            loading={rejectLoading}
            disabled={acceptLoading || rejectLoading}
            onClick={() => handleRejectInvite()}
          >
            Reject Invite
          </Button>
          <Button
            margin='0px 0px 0px 20px'
            variant='contained'
            type='button'
            bold={false}
            height='50px'
            background={themePalette.colors.primary}
            startIcon={
              <img
                src={successIcon}
                alt='successIcon'
                style={{
                  filter: themePalette.colors.whiteSvgFilter,
                  height: '16px'
                }}
              />
            }
            loading={acceptLoading}
            disabled={acceptLoading || rejectLoading}
            onClick={() => handleAcceptInvite()}
          >
            Accept Invite
          </Button>
        </ButtonContainer>
      </Container>
    </Popup>
  )
}

export default SecureComponent(InvitePopup, AccessRoleConstants.ALL)
