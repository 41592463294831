import { BsCheckCircle, BsExclamationCircle } from 'react-icons/bs'
import React, { useEffect, useState } from 'react'
import { cloneDeep, isUndefined } from 'lodash'
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import {
  deleteHealthCheck,
  updateHealthCheck
} from '../../../api/appHealthCheck/AHC.service'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'

import AHCSummary from '../../../components/AHCPage/AHCSummary/AHCSummary'
import AddCheckPopup from '../../../components/AHCPage/AddCheckPopupFlow/AddCheckPopup'
import DashboardSectionContainer from '../../../components/common/DashboardSectionContainer/DashboardSectionContainer'
import DeleteIcon from '../../../assets/icons/trash-can.svg'
import DeletePopup from '../../../components/JobMonitoringPage/DeleteLogPopup/DeletePopup'
import EditIcon from '../../../assets/icons/Edit.svg'
import { EventBus } from '../../../eventBus/EventBus'
import Filter from '../../../components/Filter/Filter'
import MultiSelect from '../../../components/common/MultiSelect/MultiSelect'
import NoData from '../../../components/NoData/NoData'
import PauseIcon from '../../../assets/icons/pauseIcon.svg'
import ResponseTimeGraph from '../../../components/AHCPage/AHCOverview/ResponseTimeGraph'
import StringConstants from '../../../constants/StringConstants'
import Table from '../../../components/common/Table/Table'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import { Tooltip } from '@mui/material'
import { TypographyDDS } from '../../../components/common/Typography/TypographyDDS'
import breakpoints from '../../../global/breakpoints'
import { getAllQueryParamsAsObjectFromUrl } from '../../../utils/UrlUtils'
import { observer } from 'mobx-react-lite'
import palette from '../../../global/pallete'
import routeConstants from '../../../constants/RouteConstants'
import styled from '@emotion/styled'
import { timeMetric } from '../../../utils/DateTimeUtils'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useStoreContext } from '../../../store/StoreContext'

const TopContainer = styled.div`
  font-size: 16px;
  @media (max-width: ${breakpoints.lg}) {
    font-size: 14px;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const UrlChecksContainer = styled.div`
  display: flex;
  flex-direction: column;
  &:hover {
    cursor: pointer;
  }
`

const ActionIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 20px;
  height: 60px;
  gap: 1.25em;
  &:hover {
    cursor: pointer;
  }
  align-items: center;
`
const GraphWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
`

const AHCDashboardPage: React.FC = () => {
  const store = useStoreContext()
  const [queryParams, setQueryParams] = useSearchParams()
  const intitalSortState: Record<string, string | null> = {
    checkName: null,
    averageResponseTime: null,
    availability: null,
    downTime: null
  }
  const [sortState, setSortState] = useState({
    ...intitalSortState
  })
  const updateSortSearchParams = (
    key: string,
    value: 'ASC' | 'DESC' | null
  ) => {
    let sortParams = queryParams.getAll('sort')
    let isParamUpdated = false
    if (value == null)
      sortParams = sortParams.filter((param) => !param.includes(key))
    else {
      sortParams = sortParams.map((param) => {
        if (param.includes(key)) {
          isParamUpdated = true
          return `${key} ${value}`
        }
        return param
      })
      if (!isParamUpdated)
        sortParams.length > 1
          ? sortParams.push(`${key} ${value}`)
          : sortParams.unshift(`${key} ${value}`)
    }
    queryParams.delete('sort')
    sortParams.forEach((param) => queryParams.append('sort', param))
  }

  const handleSort = (key?: string, state?: string | null) => {
    if (key) {
      const newState = cloneDeep(sortState)

      switch (state) {
        case null:
          updateSortSearchParams(key, 'ASC')
          newState[key] = 'ASC'
          break
        case 'DESC':
          updateSortSearchParams(key, null)
          newState[key] = null
          break
        case 'ASC':
          updateSortSearchParams(key, 'DESC')
          newState[key] = 'DESC'
      }
      setSortState(newState)
      return setQueryParams(queryParams, { replace: true })
    }
    return
  }
  const {
    ahcReportsStore,
    ahcStore,
    addCheckStore,
    uiStore,
    userStore,
    ahcSummaryStore,
    scopeStore,
    breadcrumbStore
  } = useStoreContext()

  const handlePauseStatus = (id: any, status = false) => {
    ahcReportsStore.setLoading(true)
    updateHealthCheck({
      $ahcId: id,
      monitorAppHealthCheck: !status
    })
      .then((response: any) => {
        ahcStore.setData([...ahcStore.getData(), response.data])
        EventBus.getInstance().dispatch<string>('fetch-health-check-reports')
      })
      .catch((error) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.AHC.UPDATE_CHECKS.ERROR
        })
      })
      .finally(() => {
        ahcReportsStore.setLoading(false)
      })
  }
  const statusIconMap: { [key: string]: any } = {
    Up: (
      <BsCheckCircle
        color={palette.colors.primary}
        size='20px'
        style={{ cursor: 'pointer' }}
      />
    ),
    Down: (
      <BsExclamationCircle
        color={palette.colors.error}
        size='20px'
        style={{ cursor: 'pointer' }}
      />
    ),
    Paused: <img src={PauseIcon} style={{ cursor: 'pointer' }} />
  }

  const gaEventTracker = useAnalyticsEventTracker(gaPageConstants.AHC_DASHBOARD)
  const navigate = useNavigate()
  const [searchMenuOpen, setSearchMenuOpen] = useState(false)

  const handleSearch = (contexts: any) => {
    contexts.length === 0 && ahcReportsStore.setSearchContexts([])
    const params = getAllQueryParamsAsObjectFromUrl(location.search)
    delete params['name-like']
    delete params['url-like']
    delete params['page']
    for (const c of contexts) {
      params[c.id] = c.label.split(' : ')[1]
    }
    setQueryParams({
      ...params
    })
  }

  useEffect(() => {
    EventBus.getInstance().dispatch<string>('fetch-health-check-reports')
    const newState = cloneDeep(sortState)
    const sortParams = queryParams.getAll('sort')
    sortParams.forEach((param) => {
      newState[param.split(' ')[0]] = param.split(' ')[1]
    })
    setSortState(newState)
    ahcSummaryStore.setLoading(true)
  }, [])

  useEffect(() => {
    ahcReportsStore.setSelectedContexts([])
  }, [scopeStore.getScope()])

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.UPTIME_CHECKS
    )
    //routeConstants.AHC_PROJECTS_DASHBOARD
    breadcrumbStore.setBreadcrumbsOptions({
      label: StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.UPTIME_CHECKS,
      link: routeConstants.AHC_PROJECTS_DASHBOARD
    })
  }, [])

  useEffect(() => {
    const page = queryParams.get('page')
    if (page) ahcReportsStore.setPage(+page)
    else ahcReportsStore.setPage(1)
  }, [queryParams.get('page'), ahcSummaryStore.getSummary().totalChecks])

  useEffect(() => {
    const from = queryParams.get('created-from')
    const to = queryParams.get('created-to')
    if (from && to) {
      ahcReportsStore.setDateFilter({
        'created-from': new Date(+from).toDateString(),
        'created-to': new Date(+to).toDateString()
      })
    } else {
      ahcReportsStore.setDateFilter(null)
    }
  }, [queryParams.get('created-from'), queryParams.get('created-to')])

  useEffect(() => {
    const status = queryParams.get('previous-check-status')
    if (status)
      ahcReportsStore.setStatusFilter({ 'previous-check-status': status })
    else ahcReportsStore.setStatusFilter(null)
  }, [queryParams.get('previous-check-status')])

  const menuActions = [
    () => {
      const filter = getAllQueryParamsAsObjectFromUrl(location.search)
      ahcSummaryStore.getSummaryFromApi()
      ahcReportsStore
        .getReportsFromApi(
          !isUndefined(
            ahcReportsStore.getStatusFilter()?.['previous-check-status']
          )
            ? {
                ...filter,
                'previous-check-status':
                  ahcReportsStore.getStatusFilter()?.['previous-check-status']
              }
            : filter
        )
        .catch((error) => {
          if (error) {
            ToastNotification({
              type: 'error',
              message: ToastMessageConstants.AHC.FETCH_REPORTS.ERROR
            })

            queryParams.delete('created-from')
            queryParams.delete('created-to')
            setQueryParams(queryParams)
          }
        })
    }
  ]

  const renderAvailabilityColumn = (availability: string) => {
    if (availability === 'Not enough data%' || availability === '-%') {
      return (
        <TypographyDDS.Paragraph
          size='para'
          color='textLight2'
          variant='medium'
        >
          {availability.slice(0, -1)}
        </TypographyDDS.Paragraph>
      )
    }
    const percentage = parseInt(availability.slice(0, -1))
    let color
    if (percentage > 50) color = 'primary'
    else if (percentage > 10) color = 'warning'
    else color = 'error'
    return (
      <TopContainer>
        <TypographyDDS.Paragraph color={color} size='para' variant='medium'>
          {availability}
        </TypographyDDS.Paragraph>
      </TopContainer>
    )
  }
  const getInitialDateRange = () => {
    const dateFilter: any = ahcReportsStore.getFilters()
    const start = new Date(dateFilter['created-from']).getTime()
    const end = new Date(dateFilter['created-to']).getTime()
    if (start && end)
      return {
        start,
        end
      }
  }

  const [addCheckPopupOpen, setAddCheckPopupOpen] = useState(false)
  const [confirmationPopupOpen, setConfirmationPopupOpen] = useState(false)
  const [activeItem, setActiveItem] = useState<any>()

  const filterOptions = [
    {
      id: 1,
      heading: 'Date Range',
      isExpandable: true,
      filterAttribute: {
        start: 'created-from',
        end: 'created-to'
      },
      values: [],
      type: 'DATE_RANGE',
      variant: 'DATE_ONLY',
      displayOrder: 1,
      searchable: false
    }
  ]

  const columns = [
    {
      title: 'URL',
      label: 'id',
      $sortState: sortState['checkName'],
      sortKey: 'checkName',
      sortable: false,
      render: (id: any) => {
        const item = ahcReportsStore.getReports().find((item) => item.id === id)
        return (
          <TopContainer>
            <UrlChecksContainer
              onClick={(e) => {
                e.stopPropagation()
                gaEventTracker(gaEventConstants.VIEW_SINGLE_CHECK)
                queryParams.delete('page')
                const params = getAllQueryParamsAsObjectFromUrl(queryParams)
                navigate({
                  pathname: id,
                  search: `?${createSearchParams(params)}`
                })
              }}
            >
              <TypographyDDS.Paragraph
                color='textDark'
                size='para'
                variant='medium'
              >
                {item?.checkName}
              </TypographyDDS.Paragraph>

              {!isUndefined(item) && (
                <TypographyDDS.Paragraph size='caption' variant='semiBold'>
                  {item?.url.length >
                  StringConstants.URL_MAX_LENGTH_TABLE_DISPLAY
                    ? item?.url.substring(
                        0,
                        StringConstants.URL_MAX_LENGTH_TABLE_DISPLAY
                      ) + '...'
                    : item?.url}
                </TypographyDDS.Paragraph>
              )}
            </UrlChecksContainer>
          </TopContainer>
        )
      }
    },
    {
      title: 'Status',
      label: 'status',
      render: (status: any, item: any) => {
        let updatedStatus = status
        if (!item?.monitorAppHealthCheck) updatedStatus = 'Paused'
        return (
          <Tooltip
            title={
              updatedStatus === 'Paused' ||
              updatedStatus === 'Up' ||
              updatedStatus === 'Down'
                ? updatedStatus
                : ''
            }
          >
            <TopContainer
              onClick={() => {
                handlePauseStatus(item.id, item?.monitorAppHealthCheck)
              }}
            >
              {!isUndefined(statusIconMap[updatedStatus]) ? (
                statusIconMap[updatedStatus]
              ) : (
                <span style={{ cursor: 'pointer' }}>-</span>
              )}
            </TopContainer>
          </Tooltip>
        )
      }
    },
    // {
    //   title: 'APDEX',
    //   label: 'apdex',
    //   render: (apdex: any) => (
    //     <TopContainer>
    //       <Typography
    //         variant='h6'
    //         fontWeight={palette.fontWeight.semiBold}
    //         style={{ fontSize: '0.8em' }}
    //       >
    //         {apdex}
    //       </Typography>
    //     </TopContainer>
    //   ),
    // },
    {
      title: 'Availability',
      label: 'availability',
      $sortState: sortState['availability'],
      sortKey: 'availability',
      sortable: false,
      render: (availability: any) => {
        const availabilityPercentage = availability + '%'
        return (
          <TopContainer>
            {renderAvailabilityColumn(availabilityPercentage)}
          </TopContainer>
        )
      }
    },
    {
      title: 'Avg. Response',
      label: 'averageResponseTime',
      $sortState: sortState['averageResponseTime'],
      sortKey: 'averageResponseTime',
      sortable: false,
      render: (avgResponse: any) => {
        const response = avgResponse
          ? `${timeMetric(avgResponse.duration, avgResponse.unit) || 0}`
          : '0 ms'
        return (
          <TopContainer>
            <TypographyDDS.Paragraph
              size='para'
              variant='medium'
              color='textDark'
            >
              {response}
            </TypographyDDS.Paragraph>
          </TopContainer>
        )
      }
    },
    {
      title: 'Downtime',
      label: 'downTime',
      $sortState: sortState['downTime'],
      sortKey: 'downTime',
      sortable: false,
      render: (downtime: any) => {
        return (
          <TopContainer>
            <TypographyDDS.Paragraph
              size='para'
              variant='medium'
              color='textDark'
            >
              {timeMetric(downtime?.duration, downtime?.unit)
                ? `${timeMetric(downtime?.duration, downtime?.unit)}(${
                    downtime?.percentage
                  })%`
                : '0%'}
            </TypographyDDS.Paragraph>
          </TopContainer>
        )
      }
    },
    {
      title: 'Response Time Graph',
      label: 'responsesByDate',
      render: (responsesByDate: any) => {
        const responseTimeData = Object.keys(responsesByDate).map(
          (key, index) => ({
            x: index,
            y: responsesByDate[key].averageResponseTimeInMilliSeconds
          })
        )
        return (
          <TopContainer key={JSON.stringify(responsesByDate)}>
            <GraphWrapper>
              {responseTimeData.length <= 1 ? (
                '-'
              ) : (
                <ResponseTimeGraph data={responseTimeData} />
              )}
            </GraphWrapper>
          </TopContainer>
        )
      }
    },
    {
      title: '',
      label: 'id',
      render: (id: any, item: any) => {
        return (
          <TopContainer>
            <ActionIconContainer>
              <img
                id={`${item.checkName}-ahc-edit`}
                className={`${store.userStore.getNoActiveSubscription() && 'pointer-events-none opacity-50'}`}
                style={{
                  pointerEvents: userStore.getNoActiveSubscription()
                    ? 'none'
                    : 'auto',
                  height: '20px',
                  width: '20px'
                }}
                src={EditIcon}
                onClick={(e) => {
                  e.stopPropagation()
                  gaEventTracker(gaEventConstants.EDIT_CHECK)
                  addCheckStore.setData(item)
                  addCheckStore.setEditMode(true)
                  setAddCheckPopupOpen(true)
                }}
              />
              <img
                id={`${item.checkName}-ahc-delete`}
                className={`${store.userStore.getNoActiveSubscription() && 'pointer-events-none opacity-50'}`}
                src={DeleteIcon}
                style={{
                  filter: palette.colors.redSvgFilter,
                  height: '20px',
                  width: '20px',
                  pointerEvents: userStore.getNoActiveSubscription()
                    ? 'none'
                    : 'auto'
                }}
                onClick={async (e) => {
                  e.stopPropagation()
                  setActiveItem(item)
                  setConfirmationPopupOpen(true)
                }}
              />
            </ActionIconContainer>
          </TopContainer>
        )
      }
    }
  ]

  const handleDeleteCheck = async () => {
    try {
      ahcReportsStore.setLoading(true)
      await deleteHealthCheck({ $ahcId: activeItem.id })
      queryParams.delete('page')
      setQueryParams(queryParams)
      EventBus.getInstance().dispatch<string>('fetch-health-check-reports')
      ToastNotification({
        type: 'success',
        message: ToastMessageConstants.AHC.DELETE_AHC.SUCCESS
      })
    } catch (e: any) {
      ToastNotification({
        type: 'error',
        message: ToastMessageConstants.AHC.DELETE_AHC.ERROR
      })
    } finally {
      ahcReportsStore.setLoading(false)
      setConfirmationPopupOpen(false)
    }
  }

  const handlePageChange = (page: number) => {
    queryParams.set('page', page.toString())
    setQueryParams(queryParams)
  }

  return (
    <TopContainer>
      {/* <ConfirmationPopup
        handleConfirmDialogClose={() => setConfirmationPopupOpen(false)}
        handleMainPopupClose={() => handleDeleteCheck()}
        open={confirmationPopupOpen}
        confirmationText={`Are you sure you want to delete ${activeItem?.checkName}?`}
      /> */}
      <DeletePopup
        open={confirmationPopupOpen}
        handleClose={() => {
          setConfirmationPopupOpen(false)
        }}
        onDelete={() => {
          handleDeleteCheck()
        }}
        type={'Health Check'}
        loading={ahcReportsStore.getLoading()}
        record={!isUndefined(activeItem) ? activeItem.checkName : ''}
      />
      <AddCheckPopup
        handleClose={() => setAddCheckPopupOpen(false)}
        onComplete={() => {
          console.log('On Complete')
        }}
        open={addCheckPopupOpen}
      />
      <Container>
        <AHCSummary />
        <DashboardSectionContainer
          headerText='Overview'
          paddingBottom
          menuDropDown
          menuActions={menuActions}
        >
          {(ahcReportsStore.getSelectedContexts().length >= 1 ||
            ahcReportsStore.getReports().length >= 1) &&
            !ahcReportsStore.getLoading() && (
              <MultiSelect
                id='ahc-search'
                optionsLabelAccessor={(context) => context.label}
                options={ahcReportsStore.getSearchContexts()}
                value={ahcReportsStore.getSelectedContexts()}
                openDropdown={searchMenuOpen}
                setOpenDropdown={setSearchMenuOpen}
                label='Search'
                loadingOption={ahcReportsStore.getSearchContextsLoading()}
                onTextChange={(value) => {
                  setSearchMenuOpen(true)
                  uiStore.getAxiosSource().forEach((token) => {
                    token.cancel('Request cancelled')
                  })
                  ahcReportsStore.getSearchContextsFromAPI(
                    value,
                    getAllQueryParamsAsObjectFromUrl(location.search)
                  )
                }}
                onChange={(contexts) => {
                  setSearchMenuOpen(false)
                  ahcReportsStore.setSelectedContexts(contexts)
                  handleSearch(contexts)
                }}
                onDeleteSelection={({ id }) => {
                  const contexts = ahcReportsStore
                    .getSelectedContexts()
                    .filter((option: any) => option.id !== id)
                  if (!ahcReportsStore.getLoading()) {
                    ahcReportsStore.setSelectedContexts(contexts)
                    handleSearch(contexts)
                  }
                }}
                placeholder='Search by Name or URL'
                disabled={
                  (ahcReportsStore.getSelectedContexts().length === 0 &&
                    ahcReportsStore.getReports().length === 0) ||
                  ahcReportsStore.getLoading()
                }
              />
            )}
          <FilterWrapper>
            <Filter
              options={filterOptions}
              initialDateRangeValue={getInitialDateRange()}
              validateDate={true}
              disabled={
                ahcSummaryStore.getSummary().totalChecks == 0 ? true : false
              }
              disableDeleteChip
            />
          </FilterWrapper>

          {ahcReportsStore.getReports().length !== 0 ||
          ahcReportsStore.getLoading() ||
          uiStore.getGlobalLoader() ? (
            <Table
              data={ahcReportsStore.getReports()}
              columns={columns}
              // selectable
              onPageChange={(number) => {
                handlePageChange(number)
                gaEventTracker(gaEventConstants.AHC_DASHBOARD_PAGE_CHANGE)
              }}
              currentPage={ahcReportsStore.getPage()}
              totalCount={ahcReportsStore.getCount()}
              rowsPerPage={ahcReportsStore.size}
              isLoading={ahcReportsStore.loading || uiStore.getGlobalLoader()}
              onSort={handleSort}
            />
          ) : (
            <NoData
              message={
                ahcReportsStore.getSelectedContexts().length > 0
                  ? 'No records found'
                  : 'Start monitoring your applications'
              }
              emptyText={false}
            />
          )}
        </DashboardSectionContainer>
      </Container>
    </TopContainer>
  )
}

export default observer(AHCDashboardPage)
