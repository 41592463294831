import Joi from 'joi'
import React from 'react'
import RegexConstants from '../constants/RegexConstants'
import ValidationConstants from '../constants/ValidationConstants'

export const loginSchema = Joi.object({
  email: Joi.string().pattern(RegexConstants.EMAIL_REGEX).required().messages({
    'string.pattern.base': ValidationConstants.SIGN_IN.VALID_EMAIL,
    'string.empty': ValidationConstants.SIGN_IN.EMAIL,
    'string.email': ValidationConstants.SIGN_IN.VALID_EMAIL
  }),
  password: Joi.string().required().messages({
    'string.empty': ValidationConstants.SIGN_IN.PASSWORD
  }),
  captcha: Joi.string().min(1).required().messages({
    'string.empty': ValidationConstants.INVALID_CAPTCHA
  })
})
