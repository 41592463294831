import { DDSTooltip, DDSTypography } from 'den-design-system'
import React, { useEffect, useRef, useState } from 'react'
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import { formatMonth, timeZone } from '../../../../utils/DateTimeUtils'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../../utils/UrlUtils'
import { isEmpty, isNull, isUndefined } from 'lodash'
import {
  updateExceptionGroup,
  viewAllGroupedExceptions
} from '../../../../api/exception/Exception.service'

import { ChevronRight } from '@carbon/icons-react'
import ConfirmationPopup from '../../../../components/common/Exceptions/ConfirmationPopup'
import EmptyAvatar from '../../../../assets/images/EmptyAvatar.svg'
import HorizontalStackedProgressBar from '../../../../components/common/DDS/StackedProgressBar/HorizontalStackedProgressBar'
import { Link } from '@mui/material'
import NoRecord from '../../../../components/common/NoContent/NoRecord'
import NoRecordImage from '../../../../assets/images/setting.svg'
import { ProjectType } from '../../../../enum/ProjectType.enum'
import ResponseTimeGraph from '../../../../components/AHCPage/AHCOverview/ResponseTimeGraph'
import SeverityDropDown from '../../../../components/common/Exceptions/SeverityDropdown'
import { SeverityTypes } from '../../../../api/exception/Exception.types'
import Spinner from '../../../../components/common/Spinner/Spinner'
import StringConstants from '../../../../constants/StringConstants'
import Table from '../../../../components/common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../../components/common/DDS/Toast/Toast'
import UserAvatar from '../../../../components/common/UserAvatar/UserAvatar'
import { generateRandomId } from '../../../../utils/CommonUtils'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../global/newPallete'
import routeConstants from '../../../../constants/RouteConstants'
import { severityColorMap } from '../../../../constants/ExceptionsPageConstants'
import { timeFormats } from '../../../../enum/TIME'
import { useStoreContext } from '../../../../store/StoreContext'

const StatusCodeGraphMapping = {
  Unresolved: '#B9B9B9',
  Resolved: '#56B320'
}

const SeverityColorMapping = {
  Critical: pallete.colors.warningRed2,
  Major: pallete.colors.warningOrange,
  Moderate: pallete.colors.warningBlue,
  Minor: pallete.colors.textDark6
}

const GroupedExceptions: React.FC = () => {
  const [tableData, setTableData] = useState<any>([])
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const [currentGroupId, setCurrentGroupId] = useState<any>('')
  const [preference, setPreference] = React.useState<string>('')
  const [changeToPreference, setChangeToPreference] = useState<any>('')
  const [selectedRows, setSelectedRows] = useState<any>([])
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const store = useStoreContext()
  const tableRef = useRef<HTMLDivElement>(null)
  const [tableHeight, setTableHeight] = useState<number | null>(null)

  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY

  const updatePreferences = () => {
    if (selectedRows.length === 0) {
      const isSameSeverity =
        tableData.filter(
          (data: any) => data.exceptionGroupId === currentGroupId
        )[0].preferredSeverity === preference
      if (!isSameSeverity) {
        setModalOpen(true)

        setLoading(true)
        updateExceptionGroup({
          ids: [currentGroupId as string],
          preferredSeverity: preference as SeverityTypes
        })
          .then(() => {
            ToastNotification({
              type: 'success',
              message: isFullStackProject
                ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.SUCCESS
                : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.SUCCESS
            })
            setModalOpen(false)
            setCurrentGroupId('')
            setPreference('')
            fetchGroupedExceptions()
          })
          .catch((err) => {
            ToastNotification({
              type: 'error',
              message: isFullStackProject
                ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.ERROR
                : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.ERROR
            })
            setLoading(false)

            store.filterStore.setFilterDisabled(false)
          })
      }
    } else {
      const exceptionGroupIds: string[] = []
      selectedRows.forEach((exception: any) =>
        exceptionGroupIds.push(exception.exceptionGroupId)
      )
      setLoading(true)
      updateExceptionGroup({
        ids: exceptionGroupIds,
        preferredSeverity: preference as SeverityTypes
      })
        .then(() => {
          ToastNotification({
            type: 'success',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.SUCCESS
              : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.SUCCESS
          })
          setModalOpen(false)
          setCurrentGroupId('')
          setPreference('')
          setSelectedRows([])
          fetchGroupedExceptions()
        })
        .catch((err: any) => {
          ToastNotification({
            type: 'error',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.ERROR
              : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.ERROR
          })
        })
    }
  }
  const routeException = (type: string, exceptionGroupId: string) => {
    store.exceptionStore.setType(type)
    const route = replaceUrl(
      routeConstants.ERROR_MONITORING,
      store.scopeStore.getScope()['organization-id'] as string,
      store.scopeStore.getScope()['project-id'] as string,
      store.scopeStore.getScope()['environment-id'] as string
    ).replace(':id', exceptionGroupId)
    const queryParams = { tab: '1' }
    navigate({ pathname: route, search: `?${createSearchParams(queryParams)}` })
  }

  useEffect(() => {
    if (tableRef.current) {
      setTableHeight(tableRef.current.clientHeight)
    }
  }, [tableData, loading])

  const GroupedExceptionColumns = [
    {
      heading: (
        <DDSTypography.Paragraph
          variant='semiBold'
          size='caption'
          className='flex justify-start text-start whitespace-nowrap'
          color={pallete.colors.textDark6}
        >
          {isFullStackProject ? 'Exception Type' : 'Error Type'}
        </DDSTypography.Paragraph>
      ),

      dataLabel: 'exceptionGroupType',
      render: (text: any) => (
        <DDSTooltip
          id={generateRandomId()}
          label={text}
          shape='roundedRectangle'
          position='top'
          theme='light'
          className={`border override-tooltip-arrow ${
            typeof text === 'string' && text.length > 10 ? text : '!hidden'
          }`}
          style={{
            borderColor: pallete.colors.stroke2,
            zIndex: 99999,
            maxWidth: '90%'
          }}
        >
          <div className='flex justify-start max-w-[85px]'>
            <DDSTypography.Paragraph
              variant='medium'
              size='para'
              className='truncate cursor-default w-[85px]'
              color={pallete.colors.textDark4}
            >
              {text}
            </DDSTypography.Paragraph>
          </div>
        </DDSTooltip>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: { width: '120px' },
      heading: (
        <div className='flex justify-center items-center text-center'>
          <DDSTypography.Paragraph
            variant='semiBold'
            size='caption'
            className='text-center flex justify-center text-nowrap'
            color={pallete.colors.textDark6}
          >
            Last Reported
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'latestReportedTime',
      render: (text: any) => (
        <DDSTooltip
          id={generateRandomId()}
          label={formatMonth(
            timeZone(
              store.userStore.getUserPreference().timeZone,
              text,
              timeFormats.fullMonthDateYearTime
            )
          )}
          shape='roundedRectangle'
          position='top'
          theme='light'
          className={`border override-tooltip-arrow ${
            typeof text === 'string' && text.length > 20 ? text : '!hidden'
          }`}
          style={{
            borderColor: pallete.colors.stroke2,
            zIndex: 99999,
            maxWidth: '90%'
          }}
        >
          <div className='flex justify-center items-center text-center  max-w-[120px]'>
            <DDSTypography.Paragraph
              variant='medium'
              size='para'
              className='text-center truncate cursor-default w-[85px]'
              color={pallete.colors.textDark4}
            >
              {formatMonth(
                timeZone(
                  store.userStore.getUserPreference().timeZone,
                  text,
                  timeFormats.fullMonthDateYearTime
                )
              )}
            </DDSTypography.Paragraph>
          </div>
        </DDSTooltip>
      )
    },
    {
      heading: (
        <div className='flex justify-center'>
          <DDSTypography.Paragraph
            variant='semiBold'
            size='caption'
            className='text-center flex justify-center'
            color={pallete.colors.textDark6}
          >
            Grouped Severity
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'preferredSeverity',
      render: (text: any, fullItem: any, index?: number) => {
        const setPreferences = (severity: SeverityTypes) => {
          setCurrentGroupId(fullItem['exceptionGroupId'])
          setPreference(severity)
          if (
            selectedRows.length === 0 &&
            fullItem['preferredSeverity'] !== severity
          ) {
            setModalOpen(true)
          } else if (selectedRows.length > 0) {
            setModalOpen(true)
          }
        }

        return (
          <div className='  flex justify-center'>
            <SeverityDropDown
              label={text}
              onSelect={setPreferences}
              insideTable
              disableNoSubscription={store.userStore.getNoActiveSubscription()}
            />
          </div>
        )
      }
    },
    {
      columnHeaderStyle: { width: '116px' },

      heading: (
        <DDSTypography.Paragraph
          variant='semiBold'
          size='caption'
          className='text-center text-nowrap flex justify-center  w-[90px]'
          color={pallete.colors.textDark6}
        >
          {isFullStackProject ? 'Exception Count' : 'Error Count'}
        </DDSTypography.Paragraph>
      ),
      dataLabel: 'exceptionCount',
      columnDataStyle: { paddingX: '16px', width: '116px' },
      render: (text: any) => (
        <DDSTypography.Paragraph
          variant='semiBold'
          size='para'
          className='text-center w-[90px]'
          color={pallete.colors.textDark4}
        >
          {text}
        </DDSTypography.Paragraph>
      )
    },
    {
      heading: (
        <span className='flex flex-col justify-center'>
          <DDSTypography.Paragraph
            variant='semiBold'
            size='caption'
            className='text-center flex justify-center'
            color={pallete.colors.textDark6}
          >
            Reported Time Graph
          </DDSTypography.Paragraph>
          <DDSTypography.Paragraph
            variant='semiBold'
            size='caption'
            className='text-center whitespace-nowrap flex justify-center'
            color={pallete.colors.textDark6}
          >
            (Last 7 Days)
          </DDSTypography.Paragraph>
        </span>
      ),
      dataLabel: 'reportedTimeGraph',
      render: (text: any, fullItem: any) => {
        const getResponseTimeGraphData = () => {
          if (
            !isNull(fullItem.responsesByInterval) &&
            !isUndefined(fullItem.responsesByInterval) &&
            Object.entries(fullItem.responsesByInterval).length > 1
          ) {
            const data = Object.entries(fullItem.responsesByInterval).map(
              ([key, value]: any, index: any) => {
                return {
                  x: (index as number) + 1,
                  y: fullItem.responsesByInterval[key] as number
                }
              }
            )

            return data
          } else {
            return []
          }
        }

        return (
          <div className='flex justify-center'>
            {isEmpty(getResponseTimeGraphData()) ? (
              '-'
            ) : (
              <ResponseTimeGraph data={getResponseTimeGraphData().reverse()} />
            )}
          </div>
        )
      }
    },
    {
      heading: (
        <DDSTypography.Paragraph
          variant='semiBold'
          size='caption'
          className='text-center whitespace-nowrap flex justify-center'
          color={pallete.colors.textDark6}
        >
          Assigned To
        </DDSTypography.Paragraph>
      ),
      dataLabel: 'assignedTo',
      render: (text: any, fullItem: any) => {
        const imageGroup = fullItem.assignedTo.map(
          (item: any, index: number) => {
            return {
              name: item.user.firstName + ' ' + item.user.lastName,
              avatar: item.user.avatar
            }
          }
        )

        return (
          <div className='flex flex-row justify-center w-full'>
            {fullItem.assignedTo.length === 1 ? (
              <UserAvatar
                avatarType='Single'
                imageUrl={fullItem.assignedTo[0].user.avatar}
                name={
                  fullItem.assignedTo[0].user.firstName +
                  ' ' +
                  fullItem.assignedTo[0].user.lastName
                }
              />
            ) : fullItem.assignedTo.length > 1 ? (
              <UserAvatar avatarType='Multiple' imageObjectGroup={imageGroup} />
            ) : (
              <img
                src={EmptyAvatar}
                className='rounded-full h-[24px] w-[24px]'
              />
            )}
          </div>
        )
      }
    },
    {
      heading: (
        <DDSTypography.Paragraph
          variant='semiBold'
          size='caption'
          className='text-center flex justify-center'
          color={pallete.colors.textDark6}
        >
          Severity Code Graph
        </DDSTypography.Paragraph>
      ),
      dataLabel: 'severity',
      columnHeaderStyle: { width: '127px' },
      columnDataStyle: { width: '127px' },
      render: (text: any, fullItem: any) => (
        <div className='flex justify-center w-[95px]'>
          <HorizontalStackedProgressBar
            totalCount={fullItem.exceptionCount}
            data={fullItem.severityCounts}
            color={SeverityColorMapping}
          />
        </div>
      )
    },
    {
      heading: (
        <DDSTypography.Paragraph
          variant='semiBold'
          size='caption'
          className=' text-center flex justify-center'
          color={pallete.colors.textDark6}
        >
          Status Code Graph
        </DDSTypography.Paragraph>
      ),
      columnDataStyle: { width: '110px' },
      columnHeaderStyle: { width: '110px' },
      dataLabel: 'exceptionStatusCounts',
      render: (item: any, fullItem: any) => (
        <div className='flex justify-center w-[72px]'>
          <HorizontalStackedProgressBar
            totalCount={fullItem.exceptionCount}
            data={item}
            color={StatusCodeGraphMapping}
          />
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '20px' },
      columnDataStyle: { width: '20px' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div>
          <ChevronRight
            size={16}
            color={pallete.colors.primary2}
            className='cursor-pointer'
            onClick={() => {
              routeException(
                fullItem['exceptionGroupType'],
                fullItem['exceptionGroupId']
              )
            }}
          />
        </div>
      )
    }
  ]
  const fetchGroupedExceptions = () => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    store.uiStore.setGlobalLoader(true)

    viewAllGroupedExceptions({
      ...allQueryParams,
      size: StringConstants.DEFAULT_TABLE_SIZE
    }).then((response: any) => {
      setTableData(response.data)
      setCurrentPage(response.page)
      store.exceptionsStoreV2.setGroupedExceptions(response.data)
      setTotalCount(response.totalCount)
      setLoading(false)
      store.uiStore.setGlobalLoader(false)
      store.exceptionStore.setIsRefreshed(false)
    })
  }
  useEffect(() => {
    setLoading(true)
    fetchGroupedExceptions()
  }, [
    currentPage,
    store.scopeStore.getSelectedProject(),
    store.scopeStore.getSelectedEnvironment()
  ])

  useEffect(() => {
    if (store.exceptionStore.getIsRefreshed() === true) {
      setLoading(true)
      fetchGroupedExceptions()
    }
  }, [store.exceptionStore.getIsRefreshed()])
  if (loading) return <Spinner />
  return (
    <div className='mt-[24px] mb-[24px] ' ref={tableRef}>
      {loading || tableData.length > 0 ? (
        <Table
          data={tableData}
          columns={GroupedExceptionColumns}
          totalCount={totalCount}
          onSelect={(row: any) => {
            setSelectedRows(row)
          }}
          rowsPerPage={10}
          currentPage={currentPage}
          onPageChange={(page) => {
            const allQueryParams = getAllQueryParamsAsObjectFromUrl(
              location.search
            )
            setLoading(true)
            setCurrentPage(page)
            setSearchParams({ ...allQueryParams, page: page.toString() })
          }}
          selectable
          tableStyle={{
            overflowX: 'scroll',

            borderColor: pallete.colors.stroke2
          }}
          loading={loading}
          selectionType='checkbox'
          className='override-checkbox-style'
        />
      ) : (
        <NoRecord
          imageSrc={NoRecordImage}
          style={{ height: '74vh' }}
          text={
            <>
              Configure {isFullStackProject ? 'Exception' : 'Error'}
              {''} Monitoring.
              <Link
                href={StringConstants.VIGIL_SDK_URL}
                target='_blank'
                rel='noopener noreferrer'
                style={{ marginLeft: '4px' }}
              >
                Learn more
              </Link>{' '}
            </>
          }
        />
      )}
      <ConfirmationPopup
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title='Severity Type'
        primaryButtonClick={() => updatePreferences()}
        secondaryButtonClick={() => {
          setModalOpen(false)
        }}
        loading={loading}
        primaryButtonText='Yes'
        secondaryButtonText='No'
        primaryButtonDisabled={loading}
        secondaryButtonDisabled={loading}
        content={
          <div className='flex flex-col w-full gap-y-[24px] items-center justify-center '>
            <DDSTypography.Title
              variant='semiBold'
              type='h4'
              className='text-center'
            >
              {`Upcoming ${isFullStackProject ? 'exceptions' : 'errors'} will adopt the new severity, while existing ones remain unchanged.`}
            </DDSTypography.Title>
            <div>
              <DDSTypography.Title
                variant='semiBold'
                type='h4'
                className='text-center flex flex-row whitespace-nowrap gap-x-[4px]'
              >
                {isFullStackProject
                  ? 'This will change severity type for selected exceptions to'
                  : 'This will change severity type for selected errors to'}
                <DDSTypography.Title
                  type='h4'
                  variant='semiBold'
                  color={severityColorMap[preference]}
                >
                  {preference}
                </DDSTypography.Title>
              </DDSTypography.Title>
            </div>
          </div>
        }
      />
    </div>
  )
}

export default observer(GroupedExceptions)
