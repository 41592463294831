import InvitePopup from '../InvitePopup/InvitePopup'
import React from 'react'
import StringConstants from '../../constants/StringConstants'
import Table from '../common/Table/Table'
import { TypographyDDS } from '../common/Typography/TypographyDDS'
import arrowRightIcon from '../../assets/icons/arrowRight.svg'
import palette from '../../global/pallete'
import styled from '@emotion/styled'
import { timeFormats } from '../../enum/TIME'
import { timeZone } from '../../utils/DateTimeUtils'
import { useStoreContext } from '../../store/StoreContext'

// interface Invites {
//   inviter: string
//   role: string
//   organizationName: string
// }
const InvitesContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
`
const InvitesContainerRow = styled.div`
  text-align: center;
  display: flex;
  margin-top: 5px;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`

const AllInvitesTable: React.FC<{
  data: Notification[]
  totalCount?: number
  rowsPerPage?: number
  currentPage?: number
  onPageChange?: (page: number) => void
  handleRejectUserInvite?: (token: any) => void
  handleAcceptUserInvite?: (token: any, organizationId?: string) => void
  acceptLoading?: boolean
  rejectLoading?: boolean
}> = ({
  data,
  totalCount,
  rowsPerPage = StringConstants.DEFAULT_TABLE_SIZE,
  currentPage = 0,
  onPageChange = () => null,
  handleRejectUserInvite = () => null,
  handleAcceptUserInvite = () => null,
  acceptLoading,
  rejectLoading
}) => {
  const store = useStoreContext()
  const [openInvitePopup, setOpenInvitePopup] = React.useState<boolean>(false)
  const [selectedInvite, setSelectedInvite] = React.useState<any>()

  const columns = [
    {
      title: '',
      label: '',
      render: (item: any, fullItem: any) => (
        <InvitesContainerRow style={{ alignItems: 'center' }}>
          <img
            src={fullItem.organization.avatar}
            style={{
              height: '32px',
              width: '32px',
              border: `1px solid ${palette.colors.borderColor}`,
              borderRadius: '4px'
            }}
          />
          <div style={{ flexDirection: 'column' }}>
            <TypographyDDS.Paragraph
              size='para'
              variant='medium'
              color='textDark'
              style={{
                marginLeft: '1em',
                display: 'flex'
              }}
            >
              {fullItem.organization.name}
            </TypographyDDS.Paragraph>
            <TypographyDDS.Paragraph
              size='caption'
              variant='semiBold'
              color='textDark'
              style={{
                marginLeft: '1.25em',
                display: 'flex'
              }}
            >
              {timeZone(
                store.userStore.getUserPreference().timeZone,
                fullItem.createdAt,
                timeFormats.monthDateYear
              )}
            </TypographyDDS.Paragraph>
          </div>
        </InvitesContainerRow>
      )
    },
    {
      title: '',
      label: '',
      render: (item: any, fullItem: any) => (
        <InvitesContainerColumn style={{ textAlign: 'start' }}>
          <TypographyDDS.Paragraph
            size='para'
            variant='medium'
            color='textDark'
          >
            {fullItem.inviter.firstName +
              ' ' +
              fullItem.inviter.lastName +
              ' invited you to join'}{' '}
            &nbsp;
          </TypographyDDS.Paragraph>
          <TypographyDDS.Paragraph
            size='caption'
            variant='semiBold'
            color='primary'
          >
            {fullItem.project
              ? fullItem.project.name + ' as a ' + fullItem.role
              : fullItem.organization.name + ' as a ' + fullItem.role}
          </TypographyDDS.Paragraph>
        </InvitesContainerColumn>
      )
    },
    {
      title: '',
      label: '',
      render: (item: any, fullItem: any) => (
        <InvitesContainerRow
          onClick={() => {
            setOpenInvitePopup(true)
            setSelectedInvite({
              role: fullItem.role,
              organization: fullItem.organization.name,
              token: fullItem.token,
              project: fullItem?.project?.name ?? undefined,
              organizationId: fullItem.organization.id
            })
          }}
        >
          <TypographyDDS.Paragraph size='para' variant='semiBold'>
            Review Invite
          </TypographyDDS.Paragraph>
          <img
            src={arrowRightIcon}
            style={{ height: '1.5em', marginLeft: '8px' }}
          />
        </InvitesContainerRow>
      )
    }
  ]
  return (
    <>
      <Table
        data={data}
        columns={columns}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
      <InvitePopup
        open={openInvitePopup}
        handleClose={() => setOpenInvitePopup(false)}
        project={selectedInvite?.project ?? undefined}
        organization={selectedInvite?.organization}
        role={selectedInvite?.role}
        handleAcceptInvite={() => {
          handleAcceptUserInvite(
            selectedInvite?.token,
            selectedInvite?.organizationId
          )
        }}
        handleRejectInvite={() => handleRejectUserInvite(selectedInvite?.token)}
        acceptLoading={acceptLoading}
        rejectLoading={rejectLoading}
      />
    </>
  )
}
export default AllInvitesTable
